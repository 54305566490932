import React, { useState, useEffect, useCallback } from "react";
import Header from "../../ui/Header.js";
import "./notificationStyle.css";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant";
import { getToken } from "../../../onboarding";
import { MultiSelect } from "react-multi-select-component";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;
const profileImgUUid = uuidv4();
const options = [];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
var defaultOptions = [];
function NotificationEdit() {
  const classes = useStyles();
  const [num, setNum] = useState();
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [modal, setModal] = useState(false);
  const [locationD, setlocationD] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [endDateData, setendDateData] = useState({});
  let { notificationId } = useParams();
  let { locationsId } = useParams();
  let { message } = useParams();
  let { screenName } = useParams();
  let { isEnabled } = useParams();
  let { startDate } = useParams();
  let {
    endDate,
    primary_message,
    secondary_message,
    isBlocking,
    title,
    target,
  } = useParams();
  let dataComeuseParam = useParams();
  const history = useHistory();
  const [startDate1, setStartDate1] = useState(new Date(+startDate));
  const [endDate1, setEndDate1] = useState(new Date(+endDate));
  const token = getToken();
  const config = {
    headers: { Authorization: token },
  };
  let data = {
    notificationId: notificationId,
    locationsId: locationsId,
    message: message,
    screenName: screenName,
    isEnabled: isEnabled,
    startDate: startDate,
    endDate: new Date(+endDate).toLocaleDateString("en-US"),
    primary_message: primary_message,
    secondary_message: secondary_message,
    isBlocking: isBlocking,
    title: title,
    target: target,
  };

  let LocationApi = `${ApiUrls.allLocationList}?size=50`;
  useEffect(() => {
    console.log("DATATADAT===>", data);
    console.log("dataComeuseParamdataComeuseParam=>", dataComeuseParam);
    console.log("TArget Edit Show ===>", target);
    setOriginalData(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    fetch(LocationApi, config)
      .then((response) => response.json())
      .then((data) => {
        const locations = data?.data;
        locations.forEach((element) => {
          const found = options.some((el) => el.label === element?.title);
          if (!found) {
            let locationId = element.location_id.split("#");
            options.push({
              label: element?.title,
              value: locationId[locationId.length - 1],
            });
          }
        });
        setlocationD(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [notificationId]);
  const locationsarr = locationsId.split(",");
  useEffect(() => {
    if (locationD?.length > 0) {
      console.log(locationsarr);
      defaultOptions = [];
      locationsarr.forEach((element) => {
        const itemSingle = locationD.find((el) => el.value == element);
        const exists = defaultOptions.includes(itemSingle);
        if (!exists) {
          defaultOptions.push(itemSingle);
        }
      });
      setSelectedLocation(defaultOptions);
    }
  }, [locationD?.length > 0]);

  const update = async (data, setSubmitting) => {
    let unselect = [];
    options.forEach((element) => {
      unselect.push(element.value);
    });
    let locationArr = [];
    selectedLocation.forEach((element) => {
      locationArr.push(element.value);
    });
    let unSelectLocation = unselect.filter((el) => {
      return locationArr.indexOf(el) === -1;
    });
    let startDate2 = startDate1.toLocaleString();
    let endDate2 = endDate1.toLocaleString();
    console.log(moment(startDate2).format("x"));
    let arr = { startDate: startDate1, endDate: endDate1 };
    let locationArrObj = { locationsId: locationArr };
    let unSelectLocationObj = { unselectedLocationId: unSelectLocation };
    let notification = { notificationId: notificationId };
    console.log("dataa", {
      ...data,
      ...arr,
      ...locationArrObj,
      ...unSelectLocationObj,
      ...notification,
    });
    // let notificationApi= `${ApiUrls.notificationCreate}`
    let notificationApi = `${ApiUrls.notificationUpdate}`;
    setIsLoading(true);
    fetch(notificationApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        ...arr,
        ...locationArrObj,
        ...unSelectLocationObj,
        ...notification,
      }),
    })
      .then((response) => response.json())
      .then(async(res) => {
        if (res?.data == "Notification Already Exist") {
          setIsLoading(false);
          setModal(true);
        } else {
          const notification = res?.data.Item;
          setSubmitting(false);
          setIsLoading(false);
          handleView();
          toast.success("OPS Message edited successfully");
          let action = "ops-message-edit"
            let type = "Info";
            let message = "OPS Message edited successfully.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
        }
      })
      .catch(async(err) => {
        console.log(err);
        let action = "ops-message-edit"
        let type = "Error";
        let message = "Error in OPS Message edit.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      });
  };
  const modalToggle = () => setModal(!modal);
  const handleView = () => {
    const viewPath = "/admin/notification";
    history.push(viewPath);
  };
  const handleDateOnChange = (date) => {
    setStartDate1(date);
  };
  const handleendDateOnChange = (date) => {
    setEndDate1(date);
  };
  // if (isLoading || !originalData) {
  //     return (
  //         <div className="sweet-loading card">
  //             <div className="spinner-container">
  //                 <ClipLoader
  //                     className="spinner"
  //                     sizeUnit={"px"}
  //                     size={50}
  //                     color={'#123abc'}
  //                     loading={isLoading}
  //                 />
  //             </div>
  //         </div>
  //     )
  // }
  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        backdrop="static"
        centered
      >
        <ModalHeader
          className="paddingTop5 paddingBottom0"
          toggle={modalToggle}
        ></ModalHeader>
        <ModalBody className="paddingTop5 paddingBottom0 textcenter">
          <p>A similar message already exist in the system</p>
        </ModalBody>
        <ModalFooter className="justifyCenter">
          <Button color="secondary" onClick={modalToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Edit Message"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Messages
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}
                <Formik
                  enableReinitialize
                  initialValues={originalData}
                  validate={(values) => {
                    values = { ...values, ...modifiedNonFormData };

                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.message) {
                      errors.message = "Required";
                    }
                    if (!values.isEnabled) {
                      errors.isEnabled = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    update(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Title</label>
                            <Input
                              placeholder="Enter Title"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values.title == "undefined" ? "" : values.title
                              }
                              name="title"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Screen Name&nbsp;*
                            </label>
                            <Input
                              type="select"
                              name="screenName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="home"
                                selected={
                                  values.screenName === "home" ? "selected" : ""
                                }
                              >
                                Home
                              </option>
                              <option
                                value="cart"
                                selected={
                                  values.screenName === "cart" ? "selected" : ""
                                }
                              >
                                Cart
                              </option>
                              <option
                                value="checkOut"
                                selected={
                                  values.screenName === "checkOut"
                                    ? "selected"
                                    : ""
                                }
                              >
                                CheckOut
                              </option>
                              <option
                                value="website"
                                selected={
                                  values.screenName === "website"
                                    ? "selected"
                                    : ""
                                }
                              >
                                All Pages
                              </option>
                            </Input>
                            <p className="text-danger mb-0">
                              {errors.screenName &&
                                touched.screenName &&
                                errors.screenName}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Primary Message&nbsp;*
                            </label>
                            &nbsp;
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipmessage"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipmessage"}
                            >
                              Main message to be displayed
                            </UncontrolledTooltip>
                            <Input
                              placeholder="Enter Primary Message"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.primary_message}
                              name="primary_message"
                            />
                            <p className="text-danger mb-0">
                              {errors.primary_message &&
                                touched.primary_message &&
                                errors.primary_message}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Secondary Message
                            </label>
                            &nbsp;
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipmessage2"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipmessage2"}
                            >
                              Secondary message to be diaplayed
                            </UncontrolledTooltip>
                            <Input
                              placeholder="Enter secondary_message"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values.secondary_message == "undefined"
                                  ? ""
                                  : values.secondary_message
                              }
                              name="secondary_message"
                            />
                            <p className="text-danger mb-0">
                              {errors.secondary_message &&
                                touched.secondary_message &&
                                errors.secondary_message}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Location&nbsp;*
                            </label>
                            <MultiSelect
                              options={options}
                              value={selectedLocation}
                              onChange={setSelectedLocation}
                              labelledBy="Select"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Status&nbsp;*
                            </label>
                            <Input
                              type="select"
                              name="isEnabled"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="true"
                                selected={
                                  values.isEnabled === "true" ? "selected" : ""
                                }
                              >
                                Enable
                              </option>
                              <option
                                value="false"
                                selected={
                                  values.isEnabled === "false" ? "selected" : ""
                                }
                              >
                                Disable
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Blocking Status&nbsp;*
                            </label>
                            &nbsp;{" "}
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipv3"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip delay={0} target={"tooltipv3"}>
                              message will block the user to enter the website
                            </UncontrolledTooltip>
                            <Input
                              type="select"
                              name="isBlocking"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="Blocking"
                                selected={
                                  values.isBlocking === "Blocking"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Blocking
                              </option>
                              <option
                                value="Non-Blocking"
                                selected={
                                  values.isBlocking === "Non-Blocking"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Non-Blocking
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="3" className="textAlign">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              StartDate&nbsp;*
                            </label>
                            <DatePicker
                              selected={startDate1}
                              selectsStart
                              startDate={startDate1}
                              showTimeSelect
                              dateFormat="MM/dd/yyyy"
                              onChange={handleDateOnChange}
                            />
                            <p className="text-danger mb-0">
                              {errors.startDate &&
                                touched.startDate &&
                                errors.startDate}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Target&nbsp;*
                            </label>
                            &nbsp;{" "}
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipv3"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip delay={0} target={"tooltipv3"}>
                              message will block the user to enter the website
                            </UncontrolledTooltip>
                            <Input
                              type="select"
                              name="target"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="All"
                                selected={
                                  values.target === "All" ? "selected" : ""
                                }
                              >
                                All
                              </option>
                              <option
                                value="Website"
                                selected={
                                  values.target === "Website" ? "selected" : ""
                                }
                              >
                                Website
                              </option>
                              <option
                                value="Mobile App"
                                selected={
                                  values.target === "Mobile App"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Mobile App
                              </option>
                            </Input>
                            <p className="text-danger mb-0">
                              {errors.target && touched.target && errors.target}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="3" className="textAlign">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              End Date&nbsp;*
                            </label>
                            <DatePicker
                              selected={endDate1}
                              selectsStart
                              startDate={endDate1}
                              showTimeSelect
                              dateFormat="MM/dd/yyyy"
                              onChange={handleendDateOnChange}
                            />
                            <p className="text-danger mb-0">
                              {errors.endDate &&
                                touched.endDate &&
                                errors.endDate}
                            </p>
                          </FormGroup>
                        </Col> 
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={
                                !values.primary_message ||
                                selectedLocation.length == 0 ||
                                !values.screenName
                              }
                            >
                              Update Message
                            </Button>
                          </FormGroup>
                        </Col>                      
                      </Row>                     
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default NotificationEdit;
