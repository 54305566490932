import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { Card, CardBody } from "reactstrap";

import { loginUser } from "../app/redux/auth/actions";
// core components
import AuthNavbar from "./components/navbars/AuthNavbar.js";
import AuthFooter from "./components/footers/AuthFooter.js";
import LoginForm from "./components/login";
import "./styles.css"

const LoginView = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({})
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector((state) => state.auth)

    const login = async (data, setSubmitting) => {
        setFormData(data)
        setIsLoading(true);
        dispatch(loginUser(data, history, dispatch))
        setSubmitting(false);
        setIsLoading(false);
    }

    if (isLoading) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <LoginForm />
        </>
    )
}

export default LoginView;