import React from "react";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";

const CustomModal = ({
  modal,
  toggle,
  srcStr,
  setSrcStr,
  listData,
  selectedListData,
  filteredListData,
  setSearch,
  heading,
  ddOptions,
  handleDropdown,
  handleCheckboxChange,
  setModal,
  handleClearSearch,
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      // style={{ padding: 0 }}
      backdrop="static"
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggle();
          // clearSearch();
        }}
        close={() => {
          //  clearSearch();
          toggle();
        }}
        style={{ width: "100%", display: "block" }}
      >
        <div style={{ padding: 20 }}>{heading}</div>
      </ModalHeader>
      <ModalBody
        style={{
          background: "#f1f1f1",
        }}
      >
        <div style={{ display: "grid", gap: 10 }}>
          <div>
            <InputGroup
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "-15px",
              }}
            >
              <Input
                placeholder="Search User"
                style={{
                  height: "38px",
                  borderRadius: "5px",
                  border: "1px solid #9d9d9d",
                }}
                value={srcStr}
                onChange={(e) => setSrcStr(e.target.value)}
                //   onKeyPress={(e) => handleKeyPress(e.key)}
                //autoFocus={true}
              />

              {srcStr?.length > 0 ? (
                <ButtonGroup
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    background: "#f1f1f1",
                    padding: "10px",
                    // borderRadius: "5px",
                  }}
                >
                  <Button
                    onClick={() => setSearch(srcStr)}
                    // color="secondary"
                    type="button"
                    size="sm"
                    style={{ background: "#f1f1f1", margin: 0, padding: 0 }}
                  >
                    Search
                  </Button>
                  <Button
                    //onClick={handleClearSearchKeyword}
                    // color="secondary"
                    type="button"
                    size="sm"
                    style={{ background: "#f1f1f1" }}
                    onClick={handleClearSearch}
                  >
                    Clear
                  </Button>
                </ButtonGroup>
              ) : null}
            </InputGroup>
            {srcStr.length < 3 && srcStr.length > 0 && (
              <p
                style={{
                  fontSize: "0.8rem",
                  position: "relative",
                  top: -15,
                  margin: 0,
                }}
              >
                Please type atleast 3 characters
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginTop: srcStr?.length > 0 ? "-15px" : "",
            }}
          >
            <Select
              placeholder="Search by user type"
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="categories"
              options={ddOptions}
              //isMulti={true}
              //   value={getSelectedCategoryValues()}
              onChange={handleDropdown}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  // border: "1px solid #9d9d9d",
                  // borderRadius:5,
                  height: "auto",
                  // minHeight: "45px",
                  width: "35%",
                  // maxWidth: "40%",
                  borderRadius: "5px",
                  border: "1px solid #9d9d9d",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  fontSize: 15,
                  color: "#adb5bd",
                }),
                menuList: (styles) => ({
                  ...styles,
                  fontSize: 13,
                  fontWeight: 500,
                }),
                multiValue: (styles) => ({
                  ...styles,
                  fontSize: 15,
                }),
              }}
            />

            {/* <Select
              //   placeholder={
              //     selectedCategories.length === 0
              //       ? "No sub-category selected"
              //       : "Search by subcategory"
              //   }
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="subcategories"
              //   options={subCategoryName}
              isMulti={true}
              //value={selectedSubCategoriesValue}
              // value={[{ value: "hghg", label: "rdgdgfd" }]}
              //   onChange={handleSubCategoryChange}
              //   isDisabled={selectedCategories.length === 0}
              // formatGroupLabel={formatGroupLabel}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  height: "auto",
                  width: "35%",
                  borderRadius: "5px",
                  border: "1px solid #9d9d9d",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  fontSize: 15,
                  color: "#adb5bd",
                }),
                menuList: (styles) => ({
                  ...styles,
                  fontSize: 13,
                  fontWeight: 500,
                }),
                multiValue: (styles) => ({
                  ...styles,
                  fontSize: 15,
                }),
                groupHeading: (styles) => ({
                  ...styles,
                  fontWeight: 1000,
                }),
              }}
            /> */}
            {/* <Dropdown
            isOpen={priceDropdownOpen}
            toggle={priceToggle}
            disabled={selectedCategories.length === 0}
          >
            <DropdownToggle
              caret
              size="sm"
              style={{
                background: "none",
              }}
            >
              Price
            </DropdownToggle>
            <DropdownMenu>
              {priceList.map((item) => (
                <DropdownItem
                  toggle={false}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => handlePriceSelect(item.id)}
                  />
                  {item?.value}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown> */}
            {/* {priceRange.min !== undefined && priceRange.max !== undefined ? (
            <span style={{ fontSize: "13px" }}>
              (${priceRange.min} - ${priceRange.max})
            </span>
          ) : null} */}
          </div>
        </div>
      </ModalBody>
      <ModalBody
        style={{
          // height:
          //   (filterProducts?.length || searchResults?.length) > 0
          //     ? 450
          //     : "auto",
          height: "auto",
          maxHeight: "250px",
          overflowY: "auto",
          padding: 0,
        }}
      >
        {filteredListData?.length > 0 ? (
          filteredListData?.map((item, i) => (
            <>
              <div
                key={i}
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: 10,
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  padding: "10px 15px 10px 15px",
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedListData.some(
                    (selectedItem) => selectedItem.id === item.id
                  )}
                  onChange={() => handleCheckboxChange(item)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ marginBottom: 0, fontSize: "13px" }}>
                    {item?.first_name +
                      " " +
                      item?.last_name +
                      " " +
                      item?.email}
                  </p>
                </div>
              </div>
            </>
          ))
        ) : (
          <div style={{ padding: 20, textAlign: "center" }}>
            {listData?.map((item, i) => (
              <>
                <div
                  key={i}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    gap: 10,
                    alignItems: "center",
                    borderBottom: "1px solid #ccc",
                    padding: "10px 15px 10px 15px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedListData.some(
                      (selectedItem) => selectedItem.id === item.id
                    )}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ marginBottom: 0, fontSize: "13px" }}>
                      {item?.first_name +
                        " " +
                        item?.last_name +
                        " " +
                        item?.email}
                    </p>
                    {/* {item?.price && (
                  <p style={{ marginBottom: 0, fontSize: "13px" }}>
                    ${item?.price}
                  </p>
                )} */}
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        {/* <Button
        color="primary"
        onClick={() => {
          clearSearch();
          toggle();
        }}
      >
        Add
      </Button>{" "} */}
        <Button
          color="secondary"
          //   onClick={() => {
          //     setSelectedCategories([]);
          //     setSelectedSubCategories([]);
          //     setSelectedSubCategoriesValue([]);
          //     setSelectedFilters([]);
          //     setPriceDropdownOpen(false);
          //     setPriceRange({});
          //     setSrcStr("");
          //     setSearchResults([]);
          //     setAllSubCategory([]);
          //     setFilterProducts([]);
          //     handleResetPriceList();
          //     clearSearch();
          //     toggle();
          //   }}
          onClick={() => setModal(false)}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
