import React, { useMemo, useEffect, useState } from "react";
import Header from "../../ui/Header.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Button,
  Row,
  Input,
  Media,
  ButtonGroup,
  UncontrolledTooltip,
  Badge,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { ApiUrls } from "../../../common/apiconstant.js";
import { getToken } from "../../../onboarding/index.js";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Loader from "../../../onboarding/components/Loader.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
const employeesColumns = [
  {
    header: "Title",
  },
  {
    header: "",
  },
  {
    header: "",
  },
  {
    header: "Type",
  },
  {
    header: "",
  },
  {
    header: "",
  },
  {
    header: "Is Active",
  },
  {
    header: "",
  },
  {
    header: "",
  },
  {
    header: "Actions",
  },
  {
    header: "",
  },
];

function CouponsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [nextKey, setNextKey] = useState(null);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [isDelete, setIsDelete] = useState(false);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);



  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  let {id} = useParams();

  const userLocation = localStorage.getItem("location");

  let loc_id = localStorage.getItem("selected_location_id").split("#")[1];

  const getCouponsList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getCouponsApi = `${ApiEndpoints.discountsList}?location_id=${loc_id}`;
    fetch(getCouponsApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("data",res);
        const responseCoupons = res?.data;
        console.log("ReSpinse 57", responseCoupons);
        setDiscountList(responseCoupons)
        // if (responseCoupons.length > 0) {
        //   setTenantList((prevList) => [...prevList, ...responseCoupons]);

        //   if (responseCoupons.length < 10) {
        //     setLoadMoreEnabled(false);
        //   }
        // } else {
        //   setLoadMoreEnabled(false);
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("51954949", err);
      });
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getCouponsList();
  }, [page]);

  const Menu = () => {
    const handleMenuItemClick = () => {
      history.push("/admin/create-product-discount");
    };
    const handleOrderDiscount = () => {
      history.push("/admin/create-order-discount");
    };

    return (
      <div className="menu">
        <ul style={{ paddingLeft: 0 }}>
          <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleMenuItemClick()}
          >
            <div style={{ padding: 20 }}>Amount Off Products</div>
          </li>
          <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleOrderDiscount()}
          >
            <div style={{ padding: 20 }}>Amount Off Order</div>
          </li>
          {/* <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleMenuItemClick("/page")}
          >
            Buy X Get Y
          </li>
          <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleMenuItemClick("/page")}
          >
            Free Shipping
          </li> */}
        </ul>
      </div>
    );
  };

  const columns = useMemo(() => employeesColumns, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleAdd = () => {
    const viewPath = "/admin/discount-create";
    history.push(viewPath);
  };


  const handleView = (item) => {
    if (item.discount_type === "Product Discount") {
      const viewPath = `/admin/discount-view/${item.id}`;
      history.push(viewPath);
    } else {
      const orderDiscountPath = `/admin/order-discount/${item.id}`;
      history.push(orderDiscountPath);
    }
  };
  
  const handleEdit = (item) => {
    if (item.discount_type === "Product Discount") {
      const viewPath = `/admin/discount-edit/${item.id}`;
      history.push(viewPath);
    } else {
      const orderDiscountPath = `/admin/discount-order-edit/${item.id}`;
      history.push(orderDiscountPath);
    }
  }

const handleDelete = async (item) => {
  console.log("iyashgd",item);
  let reqBody = {
    update_by: localStorage.getItem("fullname"),
  };
  let res = await axios.delete(`${ApiEndpoints.discountsList}/${item.id}`, {
    data: reqBody,
  });
  console.log("ress", res);
  if (res.status === 200) {
    getCouponsList();
    modalToggle();
    toast.success("Discount Deleted Successfully");
  }
};




  const disableCoupon = (id) => {
    console.log("hsksa",id);
    setSelectedCoupon(id);
    modalToggle();
  }
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Discount Coupons"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      type="button"
                      color="default"
                      size="sm"
                      onClick={() => setModalOpen(true)}
                    >
                      Add New Discount
                    </Button>
                    <Modal
                      isOpen={modalOpen}
                      toggle={toggleModal}
                      size="lg"
                      centered
                      backdrop="static"
                    >
                      <div className="modal-header">
                        <h3>Discount Type</h3>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={() => setModalOpen(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <ModalBody style={{ padding: 0 }}>
                        <Menu />
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {employeesColumns.map((item) => (
                      <th scope="col">{item.header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="list">
                  {discountList && discountList?.length ? (
                    discountList?.map((item) => (
                      <tr>
                        <td>{item.title}</td>
                        <td></td>
                        <td></td>
                        <td>{item.discount_type}</td>
                        <td></td>
                        <td></td>
                        <td>
                        <Badge
                            className="badge-lg"
                            color={item?.is_active ? "success" : "danger"}
                          >
                            {item?.is_active ? "Yes" : "No"}
                          </Badge>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <ButtonGroup>
                            <Button
                              className="btn-icon-only"
                              color="info"
                              onClick={() => handleView(item)}
                              id={"tooltipv" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-eye"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipv" + item?.id}
                            >
                              View Discount
                            </UncontrolledTooltip>
                            {(userRoles.includes("1") ||
                              roleActions.includes("tenants_update")) && (
                              <>
                                <Button
                                  className="btn-icon-only "
                                  color="success"
                                  onClick={() => handleEdit(item)}
                                  id={"tooltipe" + item?.id}
                                  type="button"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-edit"></i>
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltipe" + item?.id}
                                >
                                  Edit Discount
                                </UncontrolledTooltip>
                              </>
                            )}
                             <Button
                              className="btn-icon-only"
                              color="danger"
                              onClick={() => disableCoupon(item)}
                              id={"tooltipd" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-ban"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipd" + item?.id}
                            >
                              Delete Discount
                            </UncontrolledTooltip>
                          </ButtonGroup>

                          <Modal
                            isOpen={modal}
                            fade={false}
                            toggle={modalToggle}
                            size="sm"
                            centered
                          >
                            <ModalBody>
                              Are you sure you want to delete this discount?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="primary"
                                onClick={() => handleDelete(selectedCoupon)}
                              >
                                Yes
                              </Button>{" "}
                              <Button color="secondary" onClick={modalToggle}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {isLoading ? (
                        <td colSpan="8">Loading...</td>
                      ) : (
                        <td>No data found...</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
              {loadMoreEnabled && (
                <a
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  className="Link"
                  onClick={handleLoadMore}
                >
                  Load More...
                </a>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default CouponsList;
