import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  Table,
  Input,
} from "reactstrap"; 

 
import FullPageLoader from "../../../components/FullPageLoader";
import { formatDateTimeInTimeZone } from "../../helpers";
import { ApiEndpoints } from "../../constants/constant";
import { getToken } from "../../../onboarding";
import moment from "moment";
import { create } from "lodash";


function SubCategory(props) {
  const history = useHistory();
  const [dataShowPermission, setDataShowPermission] = useState([]);
  const [subMenuList, setSubMenuList] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);


  const handleCreateNew = () => {
    const create = "/admin/sub-category/create";
    console.log("33 NewSubCategoryCreate ==>",create);
    history.push(create);
  };

 const handeToShow = (item) => {
  console.log("CHeck To datta===>",item);

  // const viewPath = "/admin/inventory-update-admin/view/" + item;
  //   history.push(viewPath);
    const showDetails = "/admin/sub-category/view/"+item;
    history.push({
      pathname: showDetails,
      state: { subcategory: "view" },
    });
  };
  const handleEdit = (item) => {
    console.log("909090909==>",item);

    // const editPath = "/admin/inventory-update-admin/edit/" + item;
    // history.push(editPath);

    const editPath = `/admin/sub-category/edit/`+item;
    console.log("role-premission edit SHOW ==>",editPath);
    history.push({
      pathname: editPath,
      state: {  subcategory: "edit" },
    });
  };


  // const capitalizeFirst = str => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };


  const getSubMenus = () => {

    const token = getToken();
    const config = {
        headers: { Authorization: token },
    };

    setIsLoading(true);
    let getSubMenuApi = `${ApiEndpoints.subCategoryList}?limit=10&page=${page}`;
console.log("URL",getSubMenuApi)
    fetch(
      getSubMenuApi,
        config
    )
        .then((response) => response.json())
        .then((res) => {
          setIsLoading(false);
          console.log(res)
          const responseSubMenu = res?.data;
           console.log("ReSpinse 57",res);
           console.log("responseSubMenu,==>",responseSubMenu);
           if (responseSubMenu.length > 0) {
            setSubMenuList((prevList) => [...prevList, ...responseSubMenu]); 
          } else {
            setLoadMoreEnabled(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("61616166116", err);
        });
    };

    const handleLoadMore = () => {
      setPage((prevPage) => prevPage + 1);
    };
  


  useEffect(() => {
    getSubMenus();
  }, [page]);

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Sub Category"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      size="sm"
                      color="default"
                      onClick={() => handleCreateNew()}
                    >
                      Add New Sub Category
                    </Button>
                 
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="orderlist">
                <Table className="table" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Sub Category</th>
                      <th>Description</th>
                      <th>Actions</th>
                      
                      {/* <th>Actions</th> */}
                      {/* <th>Total Price</th>
                      <th>Status</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  { subMenuList?.map((item,index)=>(
                    console.log("122222===>",item),
                       <tbody className="list">
                   
                       <tr key={"key"}>
                         <td style={{verticalAlign: "middle"}}>{moment(item.updated_at).format("MM/DD/YYYY")}</td>
                         <td style={{verticalAlign: "middle"}}>{item.name}</td>
                         <td style={{verticalAlign: "middle"}}>{item.description}</td>
                         
                         {/* <td>{item.actions.map(paragraph => <h4>{capitalizeFirst(paragraph).replace("_"," ")}</h4>)}</td> */}
                         {/* <td>${order.total_price}</td>
                         <td>{order.status}</td> */}
                         <td style={{verticalAlign: "middle"}}>
                           <Button
                             className="btn-icon"
                             size="sm"
                             color="info"
                             type="button"
                             onClick={() => handeToShow(item.id)}
                           >
                             <i class="fa fa-eye"></i>
                           </Button>
                           <Button
                                className="btn-icon-only"
                                size="sm"
                                color="success" 
                                onClick={() => handleEdit(item.id)}
                                type="button"
                              >
                                <i className="fa fa-edit"></i>
                              </Button>
                         </td>
                       </tr>
                     
                   </tbody>
                    ))}
                 
                </Table>
                {loadMoreEnabled && (
                   <a
                      style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                       }}
                      className="Link"
                      onClick={handleLoadMore}
                    >
                      Load More...
                      </a>
                    )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default SubCategory;
