import React, { useState, useEffect } from "react";

import { MultiSelect } from "react-multi-select-component";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { ApiEndpoints } from "../../constants/constant.js";
import { Switch } from "antd";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function NewRoleCreate() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const auth = useSelector((state) => state.auth);
  const [typeChcek, setTypeCheck] = useState([
    {
        id :1,
        typeName:"LS Internal"
    },
    {
        id:2,
        typeName:"Customer",
    }
  ]);
  const [newTypeCall, setNewTypeCall] = useState("");

  const [roleTitle, setRoleTitle] = useState("");

  const [allActions, setAllActions] = useState([]);

  const [modal, setModal] = useState(false);
  const [permission, setPermission] = useState([]);
  const history = useHistory();

  const call_Permission_get_api = async () => {
    let getPermissionApi = `${ApiEndpoints.permissionGetURL}?limit=500&offset=0`;
    console.log("call 158 api ==>", getPermissionApi);
    try {
      await fetch(getPermissionApi, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("RESPONSE TO GET ==>", res);
          setPermission(res.data);
          let allActions = [];

          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].actions.length; j++) {
              // const element = array[index];
              allActions.push(res.data[i].actions[j]);
              
            }
            
          }
          setAllActions(allActions);
        });
    } catch (error) {
      console.log("160 Error Error : ", error);
    }
  };

  const create = async () => {
    console.log("handleChangeTitlehandleChangeTitle=>",roleTitle);
    console.log("Select Type ==>",newTypeCall);
    console.log("1071081-07107==>",action);

    console.log("163163 ==>", action);
   
    let callUrl = ApiEndpoints.roleGetURL;
    console.log("Call URl==>", callUrl);
    let name = localStorage.getItem("fullname");
    console.log("Datattta===>", name);
    let sendToApi = JSON.stringify({
      role: roleTitle,
      type: newTypeCall,
      permissions: action,
      updated_by: name,
    });
    console.log("170170==>", sendToApi);
    // if (window.confirm("Are You Sure You want to add Message")) {
      setIsLoading(true);
      fetch(callUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: sendToApi,
      })
        .then((response) => response.json())
        .then(async(res) => {
          console.log("186186data", res);
          if (res.data !== "") {
            setIsLoading(false);
            handleView();
            let action = "role-create"
            let type = "Info";
            let message = "Role successfully created.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
            // if (window.confirm('The Given notification is already Existing')) {
            // }
            // setSubmitting(false);
            // handleView();
          } else {
            setIsLoading(false);
            setModal(true);
          }
        })
        .catch(async(err) => {
          console.log(err);
          handleView();
          let action = "role-create"
          let type = "Error";
          let message = "Error in role create.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        });
    // }
  };
  const modalToggle = () => setModal(!modal);

  const toggler = (checked, value) => {
    console.log("130130==>", checked.target.checked, "TWO value==>", value);
    let actionOne = JSON.parse(JSON.stringify(action));
    if (checked.target.checked) {
      actionOne.push(value);
      setAction(actionOne);
    } else {
      let index = actionOne.findIndex((item) => item === value);
      actionOne.splice(index, 1);
      console.log("138138====>", index, "ACTION RODEAD==>");
      setAction(actionOne);
    }
  };
  console.log("actionsOneactionsOne=>", action);
  const handleView = () => {
    const viewPath = "/admin/Role";
    history.push(viewPath);
  };
  useEffect(() => {
    call_Permission_get_api();

    return () => {};
  }, []);
const selectAllActions = (e) => {
  console.log("SESEE==>",e.target.checked);
  if (e.target.checked) {
    setAction(allActions)
  } else {
    setAction([])
  }
};

const contains = (target, pattern) =>{
  console.log("174174",target);
 let value = true;
 for (let i = 0; i < pattern.length; i++) {
 if (!target.includes(pattern[i])) {
      value = false;
 }
  
 }
 console.log("181==>",value);
  return value
}
const selectPermission = (e,item) => {
  let tempAction = JSON.parse(JSON.stringify(action))
    if (e.target.checked) {
      
      for (let i = 0; i < item.actions.length; i++) {
       if (!action.includes(item.actions[i])) {
        tempAction.push(item.actions[i])
       }
      }
    } else {
      for (let i = 0; i < item.actions.length; i++) {
        if (action.includes(item.actions[i])) {
        let index = tempAction.findIndex(items=>items === item.actions[i])
        tempAction.splice(index,1);
        }
       }
    }
    setAction(tempAction);
}
console.log("2162162161216==>",typeChcek.includes(item => item.typeName === "LS Internal" || item.typeName === "Customer"),"NewTYPE CALL ==>",newTypeCall)
const handleChangeType = (e) => {
  console.log("Evevevevev216==>",e.target.value);
  setNewTypeCall(e.target.value);
};

const handleChangeTitle = (e) => {
  console.log("22222==>",e.target.value);
  setRoleTitle(e.target.value);
};

const onPressCreateRole = () => {
  modalToggle()
}
  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Role Create</ModalHeader>
        <ModalBody>
        
              <h2 className="fullcalendar-title h2 d-inline-block mb-0">
                Are you sure you want to add role <i class="fal fa-file-spreadsheet"></i>
              </h2>
              <Button color="primary" onClick={create}>
                Submit
              </Button>
              <Button color="secondary" onClick={
                modalToggle
              }>
                Cancel
              </Button>
            
         
          
        </ModalBody>

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Add Role"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Role
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  initialValues={{}}
                  validate={(values) => {
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    // if (!values.isBlocking) {
                    //     errors.isBlocking = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }

                    // if (!values.endDate) {
                    //     errors.endDate = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("305305===>",typeChcek)
                    // create(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Role Title
                            </label>
                            <Input
                              placeholder="Enter Title"
                              type="text"
                              onChange={handleChangeTitle}
                              onBlur={handleBlur}
                              value={roleTitle}
                              name="title"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Type&nbsp;*
                            </label>
                            <Input
                              type="select"
                              name="screenName"
                              value={typeChcek.typeName}

                              onChange={handleChangeType}
                              onBlur={handleBlur}
                            >
                                <option
                                // value="LS Internal"
                               
                              >{"Select Type"}</option>
                              {typeChcek.map((item,index)=>(
                                 <option
                                 key={index}
                                 value={item.typeName}
                                 selected={
                                   values.typeName === item.typeName
                                     ? "selected"
                                     : ""
                                 }
                               >
                                 {item.typeName}
                               </option>
                              ))}
                              {/* <option
                                value="LS Internal"
                                selected={
                                  values.screenName === "LS Internal"
                                    ? "selected"
                                    : ""
                                }
                              >
                                LS Internal
                              </option>
                              <option
                                value="Customer"
                                selected={
                                  values.screenName === "Customer"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Customer
                              </option> */}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <Row>
                            <CardTitle tag="h3" className="rowHorizontal">
                              {"Permission"}
                            </CardTitle>
                          </Row>
                        </Col>
                        <Col  md="5">
                          <FormGroup check inline>
                            <Input type="checkbox" checked={contains(action,allActions)} onChange={selectAllActions} />
                            <Label check>Select All</Label>
                          </FormGroup>
                        </Col>
                        {permission.map((item, index) => (
                          <>
                            <Col md="5" className="row-Margin">
                            <FormGroup check inline>
                            <Input type="checkbox" checked={contains(action,item.actions)} 
                            className="largerCheckbox"
                            onChange={(e)=>selectPermission(e,item)}
                            />
                          </FormGroup>
                              <label
                                className="form-control-label textCapitalize"
                                style={{
                                  marginTop: 10,
                                  fontSize:18,
                                }}
                               
                              >
                                {item.permission}&nbsp;
                              </label>
                              &nbsp;
                            
                              <div
                                style={{
                                    display: "flex",
                                      flexDirection:'column',
                                      gap: 10,
                                      marginLeft:"1rem",
                                    //   marginLeft:"1.2rem",
                                      
                                //   display: "grid",
                                //   gridTemplateColumns: "1fr ",
                                //   gap: 20,
                                }}
                              >
                                {item.actions.map((items, index) => (
                                    //   <Row className="backgrounColorCheck .flex-row">
                                  <div
                                    style={{
                                        // alignSelf:'center',
                                        // justifyContent:'center',
                                        alignItems:'center',
                                        flexDirection:'row',
                                        display:"flex",
                                    }}
                                  >
                                    <FormGroup check inline>
                            <Input type="checkbox" 
                             className="largerCheckbox"
                            checked={action.includes(items)}
                            onChange={(checked) => {
                                toggler(checked, items);
                              }}
                            />
                          </FormGroup>
                                    {/* <Switch
                                      checked={action.includes(items)}
                                      className=""
                                      style={
                                        {
                                          marginRight:10
                                        }
                                      }
                                      onClick={(checked) => {
                                        toggler(checked, items);
                                      }}
                                    /> */}
                                    <div className="mt-2">
                                    <Label className="textCapitalize" >
                                      {items.replace("_", " ")}
                                    </Label>
                                    </div>
                                  </div>
                                //   </Row>
                                ))}
                              </div>
                            </Col>
                          </>
                        ))}

                        {/* <Col lg="6">
                                                    <FormGroup>
                                                 
                                                        <label className="form-control-label">User&nbsp;</label>&nbsp;
                                                        
                                                        <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch 
                                                 checked={action.includes("create_user")}
                                                className='' style={{
                                                    marginLeft:20
                                                }}
                                                onClick={(checked)=>{
                                                        toggler(checked,"create_user");
                                                }}
                                                />
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch
                                                checked={action.includes("create_role")}
                                                style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch 
                                                 checked={action.includes("create_update")}
                                                style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch 
                                                 checked={action.includes("create_delete")}
                                                style={{
                                                    marginLeft:5
                                                }}/>
                                                </div>
                                                </Row>
                                                    </FormGroup>
                                                </Col>
                                                
                                               
                                                <Col lg="6">
                                                <label className="form-control-label">Role&nbsp;</label>&nbsp;
                                                        
                                                <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                </Row>
                                                </Col>

                                              

                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">Customer&nbsp;</label>&nbsp;
                                                        
                                                        <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch className='' style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch style={{
                                                    marginLeft:5
                                                }}/>
                                                </div>
                                                </Row>
                                                    </FormGroup>
                                                </Col>
                                           
                                               
                                                <Col lg="6">
                                                <label className="form-control-label">Dashboard&nbsp;</label>&nbsp;
                                                        
                                                <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                </Row>
                                                </Col> */}
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            // type="submit"
                            onClick={onPressCreateRole}
                            disabled={
                              values.title === "" ||
                              action.length === 0 ||
                              newTypeCall === "" || newTypeCall === "Select Type"
                                ? true
                                : false
                            }
                          >
                            Create Role
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default NewRoleCreate;
