import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  Table,
  Input,
} from "reactstrap";
// import { ApiEndpoints } from "../../constants/constant";
import "./PermissionRole.css";

import FullPageLoader from "../../../components/FullPageLoader";
import { formatDateTimeInTimeZone } from "../../helpers";
import { ApiEndpoints } from "../../constants/constant";
import { getToken } from "../../../onboarding";
import moment from "moment";

function RolePermission(props) {
  const history = useHistory();
  const [dataShowRole, setDataShowRole] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const handleCreateRoleNew = () => {
    const createRole = "/admin/role-premission/newRoleCreate";
    console.log("33 Cjcheck ==>", createRole);
    history.push(createRole);
  };

  const handeToShowRole = (item) => {
    const showRoleDetails = "/admin/role-premission/ViewRoleDetails";

    history.push({
      pathname: showRoleDetails,
      state: { item: item, permission: "view" },
    });
  };

  const getShowRole = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getPermissionApi = `${ApiEndpoints.roleGetURL}?limit=500&offset=0`;

    fetch(getPermissionApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        const permissionGet = res?.data;
        console.log("ReSpinse 59999999", res);
        console.log("permissionGetpermissionGet,==>", permissionGet);
        setDataShowRole(permissionGet);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };

  useEffect(() => {
    getShowRole();
  }, []);
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleEdit = (item) => {
    console.log("909090909==>", item);
    const editPath = `/admin/role-premission/EditRole`;
    console.log("role-premission edit SHOW ==>", editPath);
    history.push({
      pathname: editPath,
      state: { item: item, permission: "edit" },
    });
  };

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Role"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("role_create")) && (
                      <Button
                        size="sm"
                        color="default"
                        onClick={() => handleCreateRoleNew()}
                      >
                        Add New Role
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="orderlist">
                <Table className="table" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Create</th>
                      <th>Role Name</th>
                      <th>Updated By</th>
                      <th>Permissions</th>
                      {/* <th>Total Price</th>
                      <th>Status</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  {dataShowRole?.map(
                    (item, index) => (
                      console.log("124124124124124124===>", item),
                      (
                        <tbody className="list">
                          <tr key={"key"}>
                            <td>
                              {moment(item.created_at).format("MM/DD/YYYY")}
                            </td>
                            <td>{capitalizeFirst(item.role)}</td>
                            <td>{capitalizeFirst(item.updated_by)}</td>
                            <td>
                              {item.permissions.map((paragraph) => (
                                <h4>
                                  {capitalizeFirst(paragraph).replace("_", " ")}
                                </h4>
                              ))}
                            </td>
                            {/* <td>${order.total_price}</td>
                         <td>{order.status}</td> */}
                            <td>
                              <Button
                                className="btn-icon"
                                size="sm"
                                color="info"
                                type="button"
                                onClick={() => handeToShowRole(item)}
                              >
                                <i class="fa fa-eye"></i>
                              </Button>
                              {(userRoles.includes("1") ||
                                roleActions.includes("role_update")) && (
                                <Button
                                  className="btn-icon-only"
                                  size="sm"
                                  color="success"
                                  onClick={() => handleEdit(item)}
                                  type="button"
                                >
                                  <i className="fa fa-edit"></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )
                    )
                  )}
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default RolePermission;
