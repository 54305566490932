import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  CustomInput,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import { styled } from "@material-ui/core";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ApiEndpoints } from "../../constants/constant";
import axios from "axios";
import { toast } from "react-toastify";
import { logUserMetrics } from "../../../utils/file";

const TransactionData = ({
  item,
  isEditable,
  handleEdit,
  handleInputChange,
  saveChanges,
  cancelChanges,
  editedItem,
  paName,
  inclexcludeEdit,
  isChecked,
  setIsLoading,
  getTransactionsData,
  page,
  startDate,
  endDate,
  userRoles,
  roleActions,
}) => {
  console.log("1234564", paName, item);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setModal(!modal);
  const [postContent, setPostContent] = useState(item.notes);
  const [editError, setEditError] = useState("");

  const handlePostContentChange = (event) => {
    const inputValue = event.target.value;
    setPostContent(inputValue);
    if (inputValue.length >= 100) {
      setEditError("Maximum 100 characters allowed.");
    } else {
      setEditError("");
    }
  };

  const handleUpdateClick = async (item) => {
    if (postContent < 1) {
      setEditError("Please enter note");
      return;
    }
    setIsLoading(true);
    const apiUrl = ApiEndpoints.editTransactionNotes;
    const apiKey = "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi";
    const body = {
      record_id: item.record_id,
      notes: postContent,
      paName: paName,
    };
    console.log(body, "boddyy");
    try {
      const response = await axios.put(apiUrl, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });
      if (response.status === 200) {
        toast.success("Notes Edited successfully");
        setIsLoading(false);
        toggle();
        const responseData = response?.data;
        getTransactionsData(page, startDate, endDate, paName);
        let action = "transactions-notes-update";
        let type = "Info";
        let message = "Transactions notes successfully updated.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("api-response-metrics-notes", apiResponse);
        console.log(responseData, "responseEdit");
        setEditError("");
      } else {
        setIsLoading(false);
        console.log("Notes Edited failed:", response?.status);
      }
      console.log("Update successful:", response);
    } catch (error) {
      console.error("Update failed:", error);
      let action = "transactions-notes-update";
      let type = "Error";
      let message = "Error in Transactions notes update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("api-response-notes-error", apiResponse);
    }
  };

  const onClickCancel = () => {
    toggle();
    setEditError("");
    setPostContent(item.notes);
  };
  return (
    <tr>
      {Object.keys(item).map(
        (key, index) =>
          key !== "edited" && (
            <td key={index} scope="col">
              {!isChecked &&
              isEditable &&
              editedItem?.record_id == item?.record_id &&
              // key === "subtotal" ||
              // key === "commission" ||
              // key === "tax_subtotal" ||
              // key === "credit" ||
              // key === "debit" ||
              // key === "marketplace_fee" ||
              key === "adjustments" ? (
                <Input
                  type="text"
                  style={{ minWidth: "90px" }}
                  value={item[key] !== null ? `${item[key]}` : ``}
                  onChange={(e) =>
                    handleInputChange(
                      key,
                      e.target.value,
                      item.record_id,
                      isEditable
                    )
                  }
                  pattern="[0-9]*"
                />
              ) : //  key === "subtotal" ||
              //   key === "commission" ||
              //   key === "tax_subtotal" ||
              //   key === "credit" ||
              //   key === "debit" ||
              //   key === "marketplace_fee" ||
              key === "adjustments" ? (
                item[key] !== null && item[key]?.length ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item[key]}
                  </span>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    -
                  </span>
                )
              ) : key == "exclude" ? (
                <div style={{ marginLeft: "30px" }}>
                  <CustomInput
                    type="switch"
                    id={"customSwitch" + item?.record_id}
                    name={"customSwitch" + item?.record_id}
                    checked={item?.exclude}
                    className="custom-switch"
                    onChange={(e) => inclexcludeEdit(e, item?.record_id)}
                    label=""
                    disabled={
                      editedItem?.record_id != item?.record_id || !isEditable
                    }
                  />
                </div>
              ) : key == "notes" ? (
                <div style={{ display: "flex" }}>
                  {(userRoles.includes("1") ||
                    roleActions.includes("edit_transaction_note")) && (
                    <i
                      className="fa fa-pencil-square-o"
                      aria-hidden="true"
                      onClick={() => {
                        toggle();
                        setIsEdit(false);
                        setEditError("");
                        setPostContent(item.notes);
                      }}
                      style={{ cursor: "pointer", color: "blue" }}
                    ></i>
                  )}
                  <Modal centered isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Notes</ModalHeader>
                    <ModalBody>
                      <>
                        <textarea
                          style={{
                            width: "100%",
                            height: "110px",
                            padding: "15px",
                          }}
                          multiline={true}
                          defaultValue={item?.notes}
                          name="postContent"
                          value={postContent}
                          maxLength={100}
                          onChange={handlePostContentChange}
                          placeholder="Write notes here.."
                        />
                        <div style={{ fontSize: "12px", color: "red" }}>
                          {editError}
                        </div>
                      </>
                    </ModalBody>
                    <ModalFooter>
                      {postContent < 1 ? (
                        <Button
                          color="primary"
                          onClick={() => handleUpdateClick(item)}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => handleUpdateClick(item)}
                        >
                          Update
                        </Button>
                      )}

                      <Button color="secondary" onClick={onClickCancel}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              ) : (
                item[key] || (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    -
                  </span>
                )
              )}
            </td>
          )
      )}
      {/* 
      <td scope="col">       
      </td> */}

      {isEditable && editedItem?.record_id == item.record_id ? (
        <>
          <td scope="col">
            <Button
              onClick={() => saveChanges(item, paName)}
              color="success"
              outline
              size="sm"
            >
              Save
            </Button>
            <Button
              onClick={() => cancelChanges(item)}
              color="danger"
              outline
              size="sm"
            >
              X
            </Button>
          </td>
        </>
      ) : (
        <>
          {(userRoles.includes("1") ||
            roleActions.includes("edit_transaction")) && (
            <td>
              <ButtonGroup>
                <Button
                  className="btn-icon-only left-Padding"
                  color="success"
                  onClick={handleEdit}
                  type="button"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-edit"></i>
                  </span>
                </Button>
              </ButtonGroup>
            </td>
          )}
          {item?.edited === true ? <td>Edited</td> : null}
        </>
      )}
    </tr>
  );
};

export default TransactionData;
