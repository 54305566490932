import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { ApiEndpoints } from "../../admin/constants/constant";
import { logUserMetrics } from "../../utils/file";

export const ExportToExcel = ({
  productDetail,
  user,
  //finalDataDetail,
  //exportData,
  locationId,
  setIsLoading,
  isLoading,
}) => {
  const fileType = "xlsx";

  const [productData, setProductData] = useState([]);

  const exportData = async () => {
    setIsLoading(true);
    console.log("SDF", isLoading);
    if (locationId) {
      fetch(
        `${ApiEndpoints.exportPrice}?locationId=${
          localStorage.getItem("selected_location_id").split("#")[1]
        }`
      )
        .then((response) => response.json())
        .then(async(json) => {
          console.log("json", json);
          setTimeout(() => {
            const data = [
              {
                category: "product",
                data: json.data,
              },
            ];
            setProductData(data);
            exportToCSV(data);
          }, 1000);
          setIsLoading(false);
          let action = "export-products"
          let type = "Info";
          let message = "Products exported successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        });
    }
  };

  const exportToCSV = (finalDataDetail) => {
    finalDataDetail.map((item, index) => {
      // console.log("item", item)
      item["json"] = XLSX.utils.json_to_sheet(item.data);
    });
    const obj = {
      Sheets: {},
      SheetNames: [],
    };
    finalDataDetail.map((item, key) => {
      return (
        (obj.Sheets[item.category] = item.json),
        obj.SheetNames.push(item.category)
      );
    });
    console.log("obj", obj);
    const test = { ...obj };
    const excelBuffer = XLSX.write(test, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "export" + ".xlsx");

    //    const product1 = XLSX.utils.json_to_sheet(productDetail);
    //    const user1 = XLSX.utils.json_to_sheet(user);
    //    const wb = {Sheets:{product:product1, user:user1}, SheetNames:["product", "user"]};
    //    const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"array"});
    //    const data = new Blob([excelBuffer], {type:fileType});
    //    FileSaver.saveAs(data, "myfile"+".xlsx")
  };

  // const exportExcelData = async () => {
  //   let data = await exportData();
  //   console.log("DS", data);
  //   if (data.length > 0) {
  //     exportToCSV(data);
  //   }
  // };

  return (
    <Button
      className="buttons-copy buttons-html5"
      size="sm"
      color="default"
      id="copy-tooltip"
      onClick={exportData}
    >
      <span>
        <i class="fas fa-download"></i> Export
      </span>
    </Button>
  );
};
