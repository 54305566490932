import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
} from "reactstrap";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/posInventoryProductSuggestions.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ProductSuggestionsData = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { item } = location?.state;
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [itemData, setItemData] = useState(item);
  console.log("itemData", itemData);
  const toggleModal = () => setModal(!modal);
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [masterProductData, setMasterProductData] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");

  const { id } = useParams();
  console.log("idinparams", id);

  const handleEditProduct = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const getProductOptions = async () => {
    setIsLoading(true);
    try {
      let suggestionsData = `${ApiEndpoints.posImportSugg}/${id}`;
      let response = await axios.get(suggestionsData);
      console.log("response", response);
      const responseData = [...response?.data?.data?.data];
      console.log("responsedata12", responseData);
      let resData = responseData?.map((item) =>
        item?.suggestion.push({
          customData: true,
        })
      );
      console.log("resData", resData);
      setProductData(responseData);
    } catch (error) {
      console.error("error", error);
    }
  };

  console.log("productsData", productData);

  useEffect(() => {
    getProductOptions();
  }, []);

  const getProductDetails = (pageNum) => {
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        searchKeyword: searchKeyword,
      }),
    };

    let masterProducts = `${ApiEndpoints.masterProductsList}?page=${pageNum}&limit=50`;

    // if (selectedSortOption) {
    //   masterProducts =
    //     masterProducts +
    //     `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    // }

    // if (selectedStatus !== "All") {
    //   masterProducts = masterProducts + `&status=${selectedStatus}`;
    // }

    setIsLoading(true);
    fetch(masterProducts, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("res", res);
        const responseMasterProductsData = res?.data?.data;
        console.log("masterproductdataresponse", responseMasterProductsData);
        if (responseMasterProductsData?.length > 0) {
          const updateMasterProducts =
            pageNum === 1
              ? responseMasterProductsData
              : [...masterProductData, ...responseMasterProductsData];
          const mergedMasterProductsData =
            mergeAndSortMasterProductsData(updateMasterProducts);
          setMasterProductData(mergedMasterProductsData);

          if (responseMasterProductsData?.length < 50) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setMasterProductData(responseMasterProductsData);

            if (responseMasterProductsData?.length < 50) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          }
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);
      });
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getProductDetails(pageNum);
  };

  useEffect(() => {
    if (selectedSortOption) {
      getProductDetails(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getProductDetails(page);
    } else if (searchKeyword === "") {
      getProductDetails(page);
    }
  }, [searchKeyword]);

  const mergeAndSortMasterProductsData = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const handleUpdate = async () => {
    const requestBody = {
      transaction_id: id,
      products: [],
    };

    productData.map((product, productIndex) => {
      console.log("insideproduct", product);
      const importPosFileDataId = product?.import_pos_file_data_id;
      const optionIndex = selectedOptions[productIndex];
      console.log("productindex", productIndex);
      console.log("optionindex", optionIndex);

      if (
        optionIndex !== undefined &&
        optionIndex !== "" &&
        optionIndex !== "add"
      ) {
        const masterMatrixifyId =
          product.suggestion[optionIndex]?.master_matrixify_id;
        const masterMatrixifyTitle =
          product.suggestion[optionIndex]?.master_matrixify_title;
        console.log(
          "mastermatrixifyidtitle",
          masterMatrixifyId,
          masterMatrixifyTitle
        );

        const selectedProductId = product?.suggestion[optionIndex]?.id;
        const selectedProductTitle =
          product?.suggestion[optionIndex]?.full_name;
        const customData = product?.suggestion[optionIndex]?.customData;
        console.log(
          "mastermatrixifyidtitle2",
          selectedProductId,
          selectedProductTitle,
          customData
        );

        if (customData === true) {
          requestBody.products.push({
            import_pos_file_data_id: importPosFileDataId,
            master_matrixify_id: selectedProductId,
            master_matrixify_title: selectedProductTitle,
          });
        } else {
          requestBody.products.push({
            import_pos_file_data_id: importPosFileDataId,
            master_matrixify_id: masterMatrixifyId,
            master_matrixify_title: masterMatrixifyTitle,
          });
        }
      }
      // else if (optionIndex === "none") {
      //   console.log("Empty values added.");
      //   requestBody.products.push({
      //     import_pos_file_data_id: importPosFileDataId,
      //     master_matrixify_id: "",
      //     master_matrixify_title: "",
      //   });
      // }
    });

    console.log("requestBody", requestBody);
    toggleModal();

    setIsLoading(true);
    try {
      let res = await axios.post(ApiEndpoints.submitPosProduct, requestBody);
      console.log("RES", res);
      if (res.status === 200) {
        toast.success("Product created successfully");
        history.push("/admin/pos-inventory");
        setIsLoading(false);
        let action = "pos-suggestion-product-create";
        let type = "Info";
        let message = "POS Suggestion product created successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log(error?.response?.data?.message, "error");
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
      let action = "pos-suggestion-product-create";
      let type = "Error";
      let message = "Error in pos suggestion product creation";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleOptionChange = (productIndex, optionIndex, productId, option) => {
    if (productId) {
      setSelectedProductId(productId);

      let tempArr = [...selectedProduct];

      if (tempArr?.length == 0) {
        tempArr.push({
          [productId]: option,
        });
      } else {
        let allKeys = tempArr.map((item) => {
          return Object.keys(item);
        });
        console.log("allkeys1", allKeys, item, tempArr);
        tempArr.map((item) => {
          let key = Object.keys(item)[0];
          if (!allKeys.includes(key)) {
            console.log("allkeys2", key, allKeys, item, tempArr);
            if (key == productId) {
              console.log("allkeys3", key, allKeys, item, tempArr);
              return item;
            } else {
              console.log("allkeys4", key, allKeys, item, tempArr);
              tempArr.push({
                [productId]: option,
              });
            }
          }
        });
      }

      console.log(
        "productselect",
        tempArr,
        productIndex,
        optionIndex,
        productId,
        option
      );
      setSelectedProduct(tempArr);
    }
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[productIndex] = optionIndex;
      console.log("newSelectedOptions", newSelectedOptions);
      return newSelectedOptions;
    });
  };

  console.log("selected option", selectedOptions);

  const handleAddProductOption = (productIndex, productId) => {
    console.log("addingProduct", productIndex, productId);
    setSelectedProductId(productId);
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[productIndex] = "add";
      return newSelectedOptions;
    });
  };

  const getSelectedOptionsCount = () => {
    return selectedOptions.filter((optionIndex) => optionIndex !== undefined)
      .length;
  };

  const getSelectedOptions = () => {
    const selectedOptionsLength = selectedOptions.filter(
      (optionIndex) => optionIndex !== ""
    ).length;
    return selectedOptionsLength;
  };

  const handleProductSelection = (product) => {
    console.log("productselection", product);

    let tempArr = [...selectedProduct];
    console.log("tempArr1", tempArr);

    let allKeys = tempArr.map((item) => {
      return Object.keys(item);
    });

    console.log("temparrkeys", allKeys);
    let newArr = tempArr.map((item) => {
      let key = Object.keys(item)[0];
      console.log("temparrkeys1", key, allKeys, item, tempArr);
      if (item?.id == selectedProductId) {
        console.log("temparrkeys2", key, allKeys, item, tempArr);
        return {
          [selectedProductId]: product,
        };
      } else {
        console.log("temparrkeys3", key, allKeys, item, tempArr);
        if (!allKeys.includes(key)) {
          console.log("temparrkeys4", key, allKeys, item, tempArr);
          return {
            [selectedProductId]: { ...product, customData: true },
          };
        }
      }
    });
    console.log("tempArr123", tempArr, newArr);
    setSelectedProduct(newArr);
  };

  console.log("selectedproduct", selectedProduct);

  const addProduct = () => {
    console.log("selectedProduct2", selectedProduct);

    let tempProductData = [...productData];

    let index = selectedProduct.findIndex(
      (elm) => Object.keys(elm)[0] == selectedProductId
    );

    console.log("theindex", index);

    let newData = tempProductData.map((item) => {
      let id = item?.import_pos_file_data_id;
      console.log("intheid1", id, selectedProduct[id], selectedProduct);
      if (selectedProduct[index][id]) {
        console.log("intheid2", selectedProduct[index][id]);
        let newObject = {};
        for (let i in selectedProduct[index][id]) {
          newObject[i] = selectedProduct[index][id][i];
        }
        item.suggestion[item.suggestion.length - 1] = newObject;
        console.log("intheid3", newObject, item);
      }
    });
    console.log("newData", newData, tempProductData);
    setProductData(tempProductData);
    setSearchKeyword("");
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSearchKeyword("");
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col className={styles.header} lg="6" xs="6">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className={`fullcalendar-title h2 d-inline-block mb-0 ${styles.heading}`}
                    >
                      {"Product Suggestions"}
                    </h6>
                  </Col>
                  {/* <Row> */}
                  {/* <Col lg="6" xs="5" style={{ fontSize: "13px" }}>
                    Selected options {getSelectedOptionsCount()} out of{" "}
                    {productData.length}
                  </Col> */}
                  <Col className={styles.button} lg="6" xs="6">
                    {(userRoles.includes("1") ||
                      roleActions.includes("product_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={toggleModal}
                      >
                        Submit
                      </Button>
                    )}
                    <Modal
                      isOpen={modal}
                      toggle={toggleModal}
                      size="sm"
                      centered
                    >
                      <ModalHeader toggle={toggleModal}>
                        Confirmation
                      </ModalHeader>
                      <ModalBody style={{ padding: "0px 20px" }}>
                        {getSelectedOptions() < productData.length ? (
                          <>
                            Some products are skipped <br />
                            Are you sure you want to update?
                          </>
                        ) : (
                          "Are you sure you want to update?"
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handleUpdate}>
                          OK
                        </Button>{" "}
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                  {/* </Row> */}
                </Row>
              </CardHeader>

              <div className={styles.tableHeight}>
                <Table className="align-items-center table-flush">
                  <thead className={`thead-light ${styles.tableHeader}`}>
                    <tr>
                      <th>Product Name</th>
                      <th>Options</th>
                    </tr>
                  </thead>

                  <tbody className="list">
                    {productData && productData.length > 0 ? (
                      productData?.map((product, productIndex) => (
                        <tr key={productIndex}>
                          <td>
                            {productIndex + 1}. {product.import_pos_item_name}
                          </td>
                          <td>
                            {product?.suggestion?.map((option, optionIndex) => (
                              <>
                                {console.log("insidesugg", option, optionIndex)}
                                {option?.customData ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name={`product${productIndex}`}
                                      id={`addOption${productIndex}`}
                                      onChange={() => {
                                        handleOptionChange(
                                          productIndex,
                                          optionIndex,
                                          product?.import_pos_file_data_id,
                                          option
                                        );
                                        handleEditProduct();
                                      }}
                                      checked={
                                        selectedOptions[productIndex] ===
                                        optionIndex
                                      }
                                      style={{ marginTop: "-6px" }}
                                    />
                                    <label htmlFor={`addOption${productIndex}`}>
                                      {option?.full_name
                                        ? option?.full_name
                                        : "Add Product"}
                                    </label>

                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleOptionChange(
                                            productIndex,
                                            optionIndex,
                                            product?.import_pos_file_data_id,
                                            option
                                          );
                                          handleEditProduct();
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: "blue",
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    key={optionIndex}
                                    className={styles.suggestions}
                                  >
                                    <input
                                      className={styles.suggRadioBtn}
                                      type="radio"
                                      name={`product${productIndex}`}
                                      id={option.import_pos_file_data_id}
                                      value={option.import_pos_file_data_id}
                                      onChange={
                                        option?.customData == true
                                          ? () => {
                                              handleOptionChange(
                                                productIndex,
                                                optionIndex,
                                                product?.import_pos_file_data_id,
                                                option
                                              );
                                              handleEditProduct();
                                            }
                                          : () => {
                                              handleOptionChange(
                                                productIndex,
                                                optionIndex
                                              );
                                            }
                                      }
                                      checked={
                                        selectedOptions[productIndex] ===
                                        optionIndex
                                      }
                                    />
                                    <label htmlFor={`option${optionIndex}`}>
                                      {option?.master_matrixify_title}
                                    </label>
                                  </div>
                                )}
                              </>
                            ))}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <input
                                type="radio"
                                name={`product${productIndex}`}
                                id={`optionNone${productIndex}`}
                                // value=""
                                onChange={() =>
                                  handleOptionChange(productIndex, "")
                                }
                                checked={selectedOptions[productIndex] === ""}
                                style={{ marginTop: "-6px" }}
                              />
                              <label htmlFor={`optionNone${productIndex}`}>
                                None
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>

                  <Modal
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(!isModalOpen)}
                    centered
                    backdrop="static"
                    size="lg"
                  >
                    <ModalBody
                      style={{
                        height: "auto",
                        maxHeight: "350px",
                        overflowY: "auto",
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Search products..."
                        value={searchKeyword}
                        onChange={(e) => {
                          const { value } = e.target;
                          setSearchKeyword(value);
                        }}
                      />
                      {masterProductData.length ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            marginTop: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          <div style={{ display: "flex", width: "50%" }}>
                            Select
                          </div>
                          <div style={{ display: "flex", width: "100%" }}>
                            Product Name
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "center",
                            }}
                          >
                            UPC
                          </div>
                        </div>
                      ) : null}
                      {masterProductData && masterProductData.length > 0 ? (
                        masterProductData.map((product, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                              marginTop: "20px",
                            }}
                          >
                            <div style={{ display: "flex", width: "50%" }}>
                              <input
                                type="checkbox"
                                id={`product${index}`}
                                onChange={() => handleProductSelection(product)}
                                // checked={selectedProduct.some(
                                //   (elm) => Object.keys(elm)[0] === product?.id
                                // )}
                                checked={selectedProduct.some((elm) => {
                                  const key = Object.keys(elm)[0];
                                  if (selectedProductId === key) {
                                    const value = elm[key];
                                    console.log("value12", value, key);
                                    return value?.id === product?.id;
                                  }
                                })}
                                style={{ marginBottom: "25px" }}
                              />
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                              <label htmlFor={`product${index}`}>
                                {product?.title} {product?.option1_value}
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "center",
                              }}
                            >
                              <label>{product?.variant_barcode}</label>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          {isLoading ? (
                            <div>Loading...</div>
                          ) : (
                            <div>No data found...</div>
                          )}
                        </div>
                      )}

                      <hr />

                      <div>
                        {loadMoreEnabled && (
                          <a
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            className="Link"
                            onClick={handleLoadMore}
                          >
                            Load More...
                          </a>
                        )}
                      </div>

                      {/* <Table className="align-items-center table-flush">
                        <thead
                          className="thead-light"
                          style={{ position: "sticky", top: 0, zIndex: 100 }}
                        >
                          <tr colSpan="3">
                            <Input
                              type="text"
                              placeholder="Search products..."
                              value={searchKeyword}
                              onChange={(e) => {
                                const { value } = e.target;
                                setSearchKeyword(value);
                                // setSearchQuery(e.target.value);
                                // if (e.target.value.length >= 2) {
                                //   getProductDetails(1);
                                // }
                              }}
                            />
                          </tr>
                          <tr>
                            <th>Select</th>
                            <th>Product Name</th>
                            <th>UPC</th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {masterProductData && masterProductData.length > 0 ? (
                            masterProductData.map((product, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={`product${index}`}
                                    onChange={() =>
                                      handleProductSelection(product)
                                    }
                                    checked={selectedProduct === product}
                                    // style={{ marginBottom: "25px" }}
                                  />
                                </td>
                                <td>{product?.full_name}</td>
                                <td>{product?.variant_barcode}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>
                                {isLoading ? "Loading..." : "No data found..."}
                              </td>
                            </tr>
                          )}
                        </tbody>
                        <tbody>
                          <tr>
                            {loadMoreEnabled && (
                              <a
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                className="Link"
                                onClick={handleLoadMore}
                              >
                                Load More...
                              </a>
                            )}
                          </tr>
                        </tbody>
                      </Table> */}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={() => addProduct()}>
                        Add Product
                      </Button>
                      <Button onClick={() => handleClose()}>Cancel</Button>
                    </ModalFooter>
                  </Modal>
                </Table>
              </div>

              <CardFooter>
                <Row>
                  <Col lg="6" xs="5" style={{ fontSize: "13px" }}>
                    Selected options {getSelectedOptionsCount()} out of{" "}
                    {productData.length}
                  </Col>
                  {/* <Col className="text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("product_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={toggleModal}
                      >
                        Submit
                      </Button>
                    )}

                    <Modal
                      isOpen={modal}
                      toggle={toggleModal}
                      size="sm"
                      centered
                    >
                      <ModalHeader toggle={toggleModal}>
                        Confirmation
                      </ModalHeader>
                      <ModalBody style={{ padding: "0px 20px" }}>
                        {getSelectedOptions() < productData.length ? (
                          <>
                            Some products are skipped <br />
                            Are you sure you want to update?
                          </>
                        ) : (
                          "Are you sure you want to update?"
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handleUpdate}>
                          OK
                        </Button>{" "}
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col> */}
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProductSuggestionsData;
