import React, { useMemo, useEffect, useState, useRef } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  CardText,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Input,
} from "reactstrap";
import axios from "axios";
import { logUserMetrics } from "../../../utils/file";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { useTable, usePagination } from "react-table";
import { useHistory, withRouter } from "react-router-dom";
import { getToken } from "../../../onboarding";
/* Insert extra imports for table cells here */

import { formatDate, formatDateTimeInTimeZone } from "../../helpers";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import moment from "moment";
import { toast } from "react-toastify";
import ReactTags from "react-tag-autocomplete";
import SearchBar from "../../components/SearchBar";
import { useMediaQuery } from "@material-ui/core";
import styles from "../../styles/locationsList.module.css";
import MobileSearchBar from "../../components/SearchBarMobile";
const baseAPIURL = require("../../config").baseAPIURL;

const restaurantsColumns = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Code",
    accessor: "code",
  },
  {
    header: "Store ID",
    accessor: "store_id",
  },
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Tenant",
    accessor: "tenant",
  },
  // {
  //   header: "Time Zone",
  //   accessor: "timeZone",
  // },
  // {
  //   header: "Driver Assignment Mode",
  //   accessor: "dam",
  // },
  {
    header: "Created At",
    accessor: "createdAt",
  },
  {
    header: "Status",
    accessor: "is_active",
  },
  {
    header: "Active UPC",
    accessor: "upc",
    activeUPC: true,
  },
  // {
  //   header: "Total UPC",
  //   accessor: "total_upc",
  // },
  {
    header: "Actions",
    accessor: "action",
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

// function ActionsItemView(props) {
// const { data } = props;

// return (
//   <div className="inline-actions-container">
//     <button
//       onClick={() => handleView(data.row.original)}
//       type="button"
//       id="tooltip264453216"
//       className="btn-icon btn btn-info btn-sm"
//     >
//       <i className="fa fa-list"></i>
//     </button>
//     <button
//       onClick={() => handleEdit(data.row.original)}
//       type="button"
//       id="tooltip366246651"
//       className="btn-icon btn btn-success btn-sm"
//     >
//       <i className="fa fa-edit"></i>
//     </button>
//     <button
//       onClick={() => handleDelete(data.row.original)}
//       type="button"
//       id="tooltip476609793"
//       className="btn-icon btn btn-danger btn-sm"
//     >
//       <i className="fa fa-times"></i>
//     </button>
//   </div>
// );
// }

function LocationListView(props) {
  const listName = "restaurants";
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [restaurants, setRestaurants] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [selectedStoreID, setSelectedStoreID] = useState(null);
  const [modal, setModal] = useState(false);
  const [searchKeyword, setSearchKeyWord] = useState("");
  const modalToggle = () => setModal(!modal);
  const [disableButton, setDisableButton] = useState(false);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const reactTags = useRef();
  const [page, setPage] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [tags, setTags] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [statusFilter, setStatusFilter] = useState([]);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [clear, setClear] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const SortOptions = [
    { id: "title", text: "Title" },
    { id: "created_at", text: "Date" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  // useEffect(() => {
  //   let actions = JSON.parse(localStorage.getItem("role_actions"));
  //   setRolesAction(actions);
  // }, []);

  const columns = useMemo(() => restaurantsColumns, []);

  const history = useHistory();

  const handleView = (item) => {
    const viewPath = "/admin/location/" + item.id + "/view";
    history.push(viewPath);
  };

  const handlePage = () => {
    const viewPath = "/admin/location/add";
    history.push(viewPath);
  };

  const handleEdit = (id, isActive) => {
    const editPath = "/admin/location/update/" + id;
    if (isActive) {
      history.push({
        pathname: editPath,
        state: {
          activeKey: "active_upc",
        },
      });
    } else {
      history.push({
        pathname: editPath,
        // state: {
        //   activeKey: "active_upc",
        // },
      });
    }
  };

  const handleDelete = async (item) => {
    console.log("ITEM", item);
    setDisableButton(true);
    // if (window.confirm("Are you sure you want to delete this item?")) {
    // const locationId = item.pk.split("wh#")[1];
    // const code = item.sk.split("code#")[1];
    // const token = getToken();
    // const config = {
    //   // headers: { Authorization: token },
    //   method: "DELETE",
    // };
    let reqBody = {
      id: selectedStoreID,
      is_active: false,
      updated_by: localStorage.getItem("fullname"),
    };

    let res = await axios.delete(ApiEndpoints.locationDelete, {
      data: reqBody,
    });
    console.log("RES", res);
    setModal(false);
    setIsLoading(true);
    if (res.status === 200) {
      setPage(1);
      getLocationList(1);
      modalToggle();
      toast.success("Store deactivated successfully");
      let action = "location-deactivated";
      let type = "Info";
      let message = "Location successfully deactivated.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      setDisableButton(false);
      setIsLoading(false);
    }
    // const response = await fetch(`${ApiEndpoints.locationDelete}`, config).then(
    //   (response) => {}
    // );

    //history.push("/admin/locations");
    // window.location.reload();
    // }
  };

  const getLocationList = (pageNum) => {
    const token = getToken();

    const user_detail = JSON.parse(localStorage.getItem("user_detail"));
    const userId = user_detail?.id;

    let tenantIds = [];

    for (let i in tenantList) {
      if (tenantList[i].value == 17) {
        tenantIds.push("188299065656");
      } else {
        tenantIds.push(tenantList[i].value);
      }
    }
    // setCustomerId(tenantIds.join());

    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        searchKeyword: searchKeyword,
        customerId: tenantIds.join(),
      }),
    };
    console.log("selectedStoreID", tags);
    console.log("config", config);
    const extraQueryParams = null;
    setIsLoading(true);
    let LocationApi = `${ApiEndpoints.locationSearch}?limit=10&page=${pageNum}&customer_id=${customerId}&userId=${userId}`;
    if (lastEvaluatedKey !== null) {
      LocationApi += `&pk=${lastEvaluatedKey?.pk?.split("#")[1]}&sk=${
        lastEvaluatedKey?.sk?.split("store#wh#")[1]
      }`;
    }

    if (selectedSortOption) {
      LocationApi =
        LocationApi +
        `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (selectedStatus !== "All") {
      LocationApi =
        LocationApi +
        `&is_active=${selectedStatus === "Active" ? "true" : "false"}`;
    }

    fetch(LocationApi, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("LOCAT DATA", data);
        const locations = data?.data;
        console.log("125639", locations);
        if (data?.data?.LastEvaluatedKey) {
          console.log("147852", data?.data?.LastEvaluatedKey);
          setLastEvaluatedKey(data?.data?.LastEvaluatedKey);
        } else {
          setLastEvaluatedKey(null);
        }
        // if(page>1){
        //   setLocationData((prevList) =>[...prevList, ...locations]);
        // } else{
        //   setLocationData(locations);
        // }

        if (locations.length > 0) {
          const updatedLocations =
            pageNum === 1 ? locations : [...locationData, ...locations];
          const mergedLocations = mergeAndSortLocations(updatedLocations);
          setLocationData(mergedLocations);

          if (locations.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setLocationData(locations);
          }
          if (locations.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        }

        // setLocationData([...locationData, ...locations]);
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const disableStore = (id) => {
    setSelectedStoreID(id);
    modalToggle();
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getLocationList(pageNum);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    // page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: locationData,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // useEffect(() => {
  //   getLocationList();
  // }, [page,customerId]);

  const getTenantsList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    // setIsLoading(true);
    let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
    fetch(getTenantsApi, config)
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        const responseTenants = res?.data;
        console.log("ReSpinse 57", res);
        let sugg = [];
        responseTenants.map((item) => {
          return sugg.push({
            value: item.id,
            label: item.business_name,
          });
        });

        // for (let i in responseTenants) {
        //   sugg.push({
        //     value: responseTenants[i].id,
        //     label: responseTenants[i].business_name,
        //   });
        // }
        setSuggestions(sugg);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log("51954949", err);
      });
  };

  useEffect(() => {
    getTenantsList();
  }, []);

  const onDelete = (i) => {
    setPage(1);
    let tenantIds = [];
    const tags1 = tags.slice(0);
    tags1.splice(i, 1);
    setTags(tags1);
    console.log("tags1tags1", tags1);
    for (let i in tags1) {
      if (tags1[i].id == 17) {
        tenantIds.push("188299065656");
      } else {
        tenantIds.push(tags1[i].id);
      }
    }
    setCustomerId(tenantIds.join());
    // getLocationList(1);
    // setSearchKeyWord("");
  };

  const onAddition = (tag) => {
    setPage(1);
    let tenantIds = [];
    console.log("taggggg", tag);
    // tenantIds.concat(tag.id);

    console.log("tenantIds", tenantIds);

    const tags1 = [].concat(tags, tag);
    console.log("tags1", tags1);
    setTags(tags1);
    for (let i in tags1) {
      if (tags1[i].id == 17) {
        tenantIds.push("188299065656");
      } else {
        tenantIds.push(tags1[i].id);
      }
    }
    setCustomerId(tenantIds.join());
    // getLocationList(1);
    // setSearchKeyWord("");
  };

  const handleSearch = () => {
    let customersId = [];

    for (let i in tags) {
      if (tags[i].id == 17) {
        customersId.push("188299065656");
      } else {
        customersId.push(tags[i].id);
      }
    }
    console.log("customersId", customersId.join());
    setCustomerId(customersId.join());
    setPage(1);
    getLocationList(1);
  };

  const tenantName = (tenant_id) => {
    let tenant = suggestions.find((e) => e.id == tenant_id);
    if (tenant) {
      return tenant.name;
    } else {
      return "LS-Internal";
    }
  };

  console.log("tenantList", tenantList);

  useEffect(() => {
    setPage(1);
    if (selectedSortOption) {
      getLocationList(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    // if(tenantList.length > 0) {
    getLocationList(1);
    // }
  }, [tenantList]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getLocationList(page);
    } else if (searchKeyword === "") {
      getLocationList(page);
    }
  }, [searchKeyword]);

  const mergeAndSortLocations = (mergedLocations) => {
    if (selectedSortOption) {
      mergedLocations.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedLocations;
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      // getLocationList(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });
      setSelectedStatus(value);
    }
  };

  let btns = ["All", "Active", "Non-Active"];

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyWord("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setTenantList("");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getLocationList(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyWord("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setTenantList("");
    setClear(true);
    setShowCard(false);
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Locations"}
                    </h6>
                  </Col>
                  <Col className="text-md-right" lg="6" xs="6">
                    {(userRoles.includes("1") ||
                      roleActions.includes("location_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        size="sm"
                        color="default"
                        onClick={() => handlePage("")}>
                        Add New Location
                      </Button>
                    )}
                  </Col>
                </Row>

                {/* <Row  className="align-items-center">                         
                               <CardBody>
                                  <Row>
                                    <Col lg="6">
                                  <div 
                                  style={{
                                    width: "100%",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr auto",
                                    alignItems: "center",
                                    gap: 20,
                                  }}
                                  >
                                  <ReactTags
                                      ref={reactTags}
                                      tags={tags}
                                      suggestions={suggestions}
                                      onDelete={onDelete}
                                      onAddition={onAddition}
                                      placeholderText="Search By Tenant"
                                    />
                                    <Input
                                    placeHolder="Location Name and Email"
                                    type="text"
                                    value={searchKeyword}
                                    // disabled={tags.length === 0}
                                    // style={{ height: "48px", width: "170px"  }}
                                    onChange={(e) => setSearchKeyWord(e.target.value)}
                                    />
                                    <Button 
                                      color="primary" 
                                      onClick={()=>handleSearch(2)}
                                      >
                                        Search
                                    </Button>
                                    </div>
                                    </Col>
                                    </Row>
                                </CardBody> 
                         </Row> */}
              </CardHeader>

              <CardBody className={styles.locationList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyWord}
                    getDetail={handleSearch}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search by Location Name & Email"
                    dropDown={false}
                    orderList={false}
                    locationDropDown={true}
                    reactTags={reactTags}
                    tags={tags}
                    suggestions={suggestions}
                    onDelete={onDelete}
                    onAddition={onAddition}
                    tenantList={tenantList}
                    setTenantList={setTenantList}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyWord}
                    getDetail={handleSearch}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Searching Location Name and Email"
                    dropDown={false}
                    orderList={false}
                    locationDropDown={true}
                    reactTags={reactTags}
                    tags={tags}
                    suggestions={suggestions}
                    onDelete={onDelete}
                    onAddition={onAddition}
                    tenantList={tenantList}
                    setTenantList={setTenantList}
                  />
                )}
              </CardBody>

              <div style={{ height: 560, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}>
                    <tr>
                      {restaurantsColumns.map((item) => (
                        <th scope="col">
                          {item.header}
                          {item?.activeUPC && (
                            <span style={{ textTransform: "lowercase" }}>
                              s
                            </span>
                          )}
                        </th>
                      ))}
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {locationData && locationData?.length ? (
                      locationData?.map((item) => (
                        <tr>
                          <td className="budget">LS-{item.id}</td>
                          <td>
                            <span className="status">{item.code}</span>
                          </td>
                          <td className="budget">{item.store_id}</td>
                          <td className="budget">{item.title}</td>
                          <td>
                            <span className="budget">
                              {tenantName(item.customer_id)}
                            </span>
                          </td>
                          {/* <td>
                          <span className="status">{item.timezone}</span>
                        </td> */}
                          {/* <td>
                          <span className="status">
                            {item.dam === true ? "Automatic" : "Manual"}
                          </span>
                        </td> */}
                          <td>
                            <span className="status">
                              {moment(item?.created_at).format("MM/DD/YYYY")}
                            </span>
                          </td>
                          <td>
                            <span className="status">
                              {item.is_active === true
                                ? "Active"
                                : "Non-Active"}
                            </span>
                          </td>
                          <td className="budget">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEdit(item.id, true);
                              }}>
                              {item.upc_count}
                            </a>
                          </td>
                          {/* <td className="budget">{item.totalUPCCount}</td> */}
                          <td>
                            <ButtonGroup>
                              {(userRoles.includes("1") ||
                                roleActions.includes("location_edit") ||
                                roleActions.includes(
                                  "location_update_delivery_slots"
                                )) && (
                                <>
                                  <Button
                                    className="btn-icon-only"
                                    color="success"
                                    onClick={() => handleEdit(item.id)}
                                    id={"tooltipe" + item.userId}
                                    type="button">
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item.userId}>
                                    Edit Location
                                  </UncontrolledTooltip>
                                </>
                              )}
                              {(userRoles.includes("1") ||
                                roleActions.includes("location_delete")) && (
                                <>
                                  <Button
                                    className="btn-icon-only"
                                    color="danger"
                                    onClick={() => disableStore(item.id)}
                                    id={"tooltipd" + item.id}
                                    type="button"
                                    disabled={!item.is_active}>
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-ban"></i>
                                    </span>
                                  </Button>
                                  {!isMobile ? (
                                    <UncontrolledTooltip
                                      delay={0}
                                      autohide={true}
                                      target={"tooltipd" + item.id}>
                                      {/* {item.is_active ? "Disable" : "Enable"}{" "} */}
                                      Disable Location
                                    </UncontrolledTooltip>
                                  ) : null}
                                </>
                              )}
                            </ButtonGroup>

                            <Modal
                              isOpen={modal}
                              fade={false}
                              toggle={modalToggle}
                              size="sm"
                              centered>
                              {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
                              <ModalBody>
                                Are you sure you want to deactivate this store?
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  disabled={disableButton}
                                  onClick={() => handleDelete(item)}>
                                  Yes
                                </Button>{" "}
                                <Button color="secondary" onClick={modalToggle}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardFooter className="py-4">
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}>
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(LocationListView);
