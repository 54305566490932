import React, { useState, useEffect } from "react";
import "./DisputeStyle.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  CardBody,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import { ApiEndpoints } from "../../constants/constant";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import IMGetLocation from "../../generated/ui/IMGetLocation";

const token_MyShopyfy = "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";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Dispute() {
  const classes = useStyles();
  const [disputeName, setDisputeName] = useState("Shopify");
  const [pageNum, setPageNum] = useState(0);
  const [limit, setLimit] = useState(10);
  const [useValue, setUseValue] = useState(0);
  const history = useHistory();
  var currentDate = new Date();
  const [isLoading, setIsLoading] = useState(true);
  const [listShopify, setListShopify] = useState([]);
  const [listStripe, setListStripe] = useState([]);
  const location = useLocation();
  const [lastId, setLastId] = useState("");
  const [lastTab, setLastTab] = useState("");
  const [isData, setIsData] = useState(true);
  const [locationId, setLocationId] = useState("");

  console.log("LOK", isLoading);

  const handleDropdown = (e) => {
    console.log("E", e);
    let value = e.target.value;
    let location_id = value.split("#")[1];
    setLocationId(location_id);
  };

  const getDisputes = async () => {
    if (disputeName === "Shopify") {
      try {
        let shopifyDisputeApi = ApiEndpoints.shopifyDisputes;
        setIsLoading(true);
        let res;
        if (lastId) {
          res = await axios.get(
            `${shopifyDisputeApi}?limit=${limit}&last_id=${lastId}`
          );
          if (res?.data?.data?.disputes.length > 0) {
            setListShopify([...listShopify, ...res?.data?.data?.disputes]);
            setIsData(true);
          } else {
            setIsData(false);
          }
        } else {
          res = await axios.get(`${shopifyDisputeApi}?limit=${limit}`);
          if (res?.data?.data?.disputes.length > 0) {
            setListShopify(res?.data?.data?.disputes);
            setIsData(true);
          } else {
            setIsData(false);
          }
        }
        //const res = await axios.get(`${shopifyDisputeApi}?limit=${limit}`);

        console.log("REs", res);

        //setLastId(res?.data?.data?.disputes[limit - 1].id);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      try {
        setIsLoading(true);
        let stripeDisputeApi = ApiEndpoints.stripeDisputes;
        let stripeRes;
        if (lastId) {
          stripeRes = await axios.get(
            `${stripeDisputeApi}?limit=${limit}&starting_after=${lastId}`
          );
          if (stripeRes?.data?.data?.data.length > 0) {
            setListStripe([...listStripe, ...stripeRes?.data?.data?.data]);
            setIsData(true);
          } else {
            setIsData(false);
          }
        } else {
          stripeRes = await axios.get(`${stripeDisputeApi}?limit=${limit}`);
          if (stripeRes?.data?.data?.data.length > 0) {
            setListStripe(stripeRes?.data?.data?.data);
            setIsData(true);
          } else {
            setIsData(false);
          }
        }

        console.log("Str", stripeRes);
        if (stripeRes?.data?.data?.data.length > 0) {
          setListStripe([...listStripe, ...stripeRes?.data?.data?.data]);
          setIsData(true);
        } else {
          setIsData(false);
        }

        //setLastId(stripeRes?.data?.data?.data[limit - 1].id);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDisputes();
  }, [disputeName, lastId]);

  console.log("LAST", lastId);

  useEffect(() => {
    if (location?.state?.path) {
      setDisputeName(location?.state?.path);
      history.push({ state: { path: "" } });
    }
  }, []);

  var lastDate = currentDate.setDate(currentDate.getDate() - 5);
  lastDate = new Date(lastDate);

  const loadMore = async () => {
    currentDate = new Date(lastDate);
    let date = new Date(lastDate);
    lastDate = date.setDate(date.getDate() - 5);
    lastDate = new Date(lastDate);
    setPageNum((p) => p + 1);

    //setLimit((p) => p + 5);
    let last_id;
    if (disputeName === "Shopify") {
      last_id = listShopify[listShopify.length - 1].id;
    } else {
      last_id = listStripe[listStripe.length - 1].id;
    }
    setLastId(last_id);

    // if (disputeName === "Shopify") {
    //   try {
    //     let shopifyDisputeApi = ApiEndpoints.shopifyDisputes;
    //     setIsLoading(true);
    //     const res = await axios.get(
    //       `${shopifyDisputeApi}?limit=${limit + 5}&last_id=${lastId}`
    //     );
    //     console.log("REs", res);
    //     setListShopify(res?.data?.data?.disputes);
    //     setLimit(limit + 5);
    //     setIsLoading(false);
    //   } catch (error) {
    //     setIsLoading(false);
    //     console.log(error);
    //   }
    // } else {
    //   try {
    //     setIsLoading(true);
    //     let stripeDisputeApi = ApiEndpoints.stripeDisputes;
    //     const stripeRes = await axios.get(
    //       `${stripeDisputeApi}?limit=${limit + 5}&starting_after=${lastId}`
    //     );
    //     console.log("Str", stripeRes);
    //     setListStripe(stripeRes?.data?.data?.data);
    //     setLimit(limit + 5);
    //     setIsLoading(false);
    //   } catch (error) {
    //     setIsLoading(false);
    //     console.log(error);
    //   }
    // }

    // setTimeout(function () {
    //   getOrderList(locationId, orderStatus, size);
    // }, 3000);
  };

  useEffect(() => {
    console.log("call 51", pageNum);
    //getShopyFyList();
    return () => {};
  }, [pageNum]);

  const getShopyFyList = async () => {
    console.log("Call getShopyFy");
    const token = token_MyShopyfy;
    const config = {
      method: "GET",
      headers: { "X-Shopify-Access-Token": token },
    };
    fetch(`${ApiEndpoints.shopyfyUrlDispue}`, config)
      .then((response) => response.json())
      .then(async (data) => {
        const disputes_call = data?.data;
        console.log("77_77Call", disputes_call);
      })
      .catch((err) => {
        console.log("Error 85==>", err);
        setIsLoading(false);
        console.log(err);
      });

    // try {
    //   console.log("Token Call ==>",token);
    //   console.log("95______===>",ApiEndpoints.shopyfyUrlDispue);
    //   console.log("Try call 94")
    //   const data = await axios.get(ApiEndpoints.shopyfyUrlDispue, {
    //     headers: {
    //       "X-Shopify-Access-Token": token,
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   console.log("100______Datatat==>", data);
    //   if (data.status === 200) {
    //     console.log("Data575_____",data);
    //   }
    // } catch (error) {
    //   console.log("96_Error call",error);
    // }
  };

  const dispute_Status_Show = (text) => {
    console.log("tetetet==>", text);
    setDisputeName(text);
    setLastId("");
  };
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Dispute"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    ></div>
                  </Col>
                </Row>
              </CardHeader>
              {/* <CardBody>
                <Row>
                  <Col xs={12} sm={4}>
                    <Form>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="1"
                        ></Label>
                        <Col md="8" className="pull-right">
                          {
                            <Input
                              id="exampleFormControlSelect3"
                              type="select"
                              onChange={handleDropdown}
                            >
                              <IMGetLocation setLocationId={setLocationId} />
                            </Input>
                          }
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody> */}

              <ButtonGroup className="btngroup" responsive>
                <Button
                  className={disputeName === "Shopify" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => dispute_Status_Show("Shopify")}
                >
                  SHOPIFY
                </Button>
                <Button
                  className={disputeName === "LsApp" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => dispute_Status_Show("LsApp")}
                >
                  LS APP
                </Button>
              </ButtonGroup>
              {/* <ListTable
                ordersColumns={ordersColumns}
                orders={orders}
                handleView={handleView}
                history={history}
                handlestatus={listTableData}
                updateOrderStatus={updateOrderStatus}
              /> */}
              {/* <div>{`Call ${disputeName}`}</div> */}

              {disputeName === "Shopify" ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Order Id</th>
                      <th>Amount</th>
                      <th>Reason</th>
                      <th>Status</th>
                      <th>Evidence Due By</th>
                      <th>evidence Sent On</th>
                      <th>finalized On</th>
                      <th></th>

                      {/* <th>Reason</th> */}

                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {listShopify?.map(
                      (item, i) => (
                        console.log("shdfjsmadfbns==>", item),
                        (
                          <tr key={i}>
                            <td>
                              {moment(item.initiated_at).format("MM/DD/YYYY")}
                            </td>
                            <td>{item.order_id}</td>
                            <td>{item.amount}</td>
                            <td>{item.reason}</td>
                            <td>{item.status}</td>
                            <td>
                              {moment(item.evidence_due_by).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                            <td>
                              {moment(item.evidence_sent_on).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                            <td>
                              {moment(item.finalized_on).format("MM/DD/YYYY")}
                            </td>

                            <td>
                              <ButtonGroup>
                                <Button
                                  className="btn-icon-only"
                                  color="info"
                                  onClick={() => {
                                    const viewPath = "/admin/dispute";
                                    history.push({
                                      pathname: viewPath,
                                      state: {
                                        data: item,
                                        name: disputeName,
                                      },
                                    });
                                  }}
                                  id={"tooltipv" + item.userId}
                                  type="button"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-eye"></i>
                                  </span>
                                </Button>
                              </ButtonGroup>
                            </td>

                            {/* <td>Hello</td>
                    <td>DFDF</td>
                    <td>SDFS</td>
                    <td>SDFSF</td>
                    <td>sdfsf</td>
                    <td>
                      <Button
                        className="buttons-copy buttons-html5"
                        color="primary"
                        size="sm"
                      >
                        <span>
                          <i class="fas fa-download"></i> Download
                        </span>
                      </Button>
                    </td> */}
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </Table>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Dispute Id</th>
                      <th>Amount</th>
                      <th>Reason</th>
                      <th>Status</th>
                      <th>Evidence Due By</th>
                      <th>Charge</th>
                      <th>Is Charge Refundable</th>
                      <th></th>

                      {/* <th>Reason</th> */}

                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {listStripe?.map(
                      (item, i) => (
                        console.log("sh==>", item.is_charge_refundable),
                        (
                          <tr key={i}>
                            <td>{moment(item.created).format("MM/DD/YYYY")}</td>
                            <td>{item.id}</td>
                            <td>{item.amount}</td>
                            <td>{item.reason}</td>
                            <td>{item.status}</td>
                            <td>
                              {moment(item.evidence_details.due_by).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                            <td>{item.charge}</td>
                            <td>{item.is_charge_refundable?.toString()}</td>

                            <td>
                              <ButtonGroup>
                                <Button
                                  className="btn-icon-only"
                                  color="info"
                                  onClick={() => {
                                    const viewPath = "/admin/dispute";
                                    history.push({
                                      pathname: viewPath,
                                      state: {
                                        data: item,
                                        name: disputeName,
                                      },
                                    });
                                  }}
                                  id={"tooltipv" + item.userId}
                                  type="button"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-eye"></i>
                                  </span>
                                </Button>
                              </ButtonGroup>
                            </td>

                            {/* <td>Hello</td>
                    <td>DFDF</td>
                    <td>SDFS</td>
                    <td>SDFSF</td>
                    <td>sdfsf</td>
                    <td>
                      <Button
                        className="buttons-copy buttons-html5"
                        color="primary"
                        size="sm"
                      >
                        <span>
                          <i class="fas fa-download"></i> Download
                        </span>
                      </Button>
                    </td> */}
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </Table>
              )}
              <CardFooter className="py-4">
                {isData ? (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={() => loadMore()}
                  >
                    Load More...
                  </a>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    No more data
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Dispute;
