import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import Loader from "../../../onboarding/components/Loader";
import { ApiEndpoints } from "../../constants/constant";
import axios from "axios";
import InternalPreviewGroup from "antd/es/image/PreviewGroup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { logUserMetrics } from "../../../utils/file";
import { useHistory } from "react-router-dom";

const data = [
  {
    name: "liquor",
  },
  {
    name: "wine",
  },
  {
    name: "beer",
  },
  {
    name: "Celebrity Collections",
  },
];

const ManageCollections = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [pickedData, setPickedData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [tenantCollectionArr, setTenantCollectionArr] = useState([]);
  const history = useHistory();
  const [tenantId, setTenantId] = useState("");

  async function getCollectionList() {
    setIsLoading(true);
    try {
      const res = await axios.get(
        ApiEndpoints.collectionList + `?offset=0&limit=500`
      );

      console.log("Res", res);
      if (res.status === 200) {
        setCollections(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function getPickedCollection() {
    setIsLoading(true);
    let loc_id = localStorage.getItem("selected_location_id").split("#")[1];
    let selected_tenant_id = localStorage.getItem("tenant_id");

    try {
      const res = await axios.get(
        ApiEndpoints.pickedCollection +
          `?tenant_id=${selected_tenant_id}` +
          `&location_id=${loc_id}` +
          `&offset=0&limit=100`
      );

      console.log("Resa", res);
      if (res.status === 200) {
        // setCollections(res?.data?.data);
        // setIsLoading(false);
        // "278307373254" "278306652358"
        //let arr = [...res.data.data];
        let arr = JSON.parse(JSON.stringify(res.data.data));
        // arr[0]["collection_id"] = "278307373254";
        // arr[1]["collection_id"] = "278306652358";
        let tenantArr = [];
        for (let i = 0; i < collections.length; i++) {
          let value = arr.find(
            (item) => item.collection_id === collections[i].collection_id
          );

          if (typeof value !== "undefined") {
            tenantArr.push({
              collection_id: value.collection_id,
              image: collections[i].image?.src,
              title: value.title,
              menus: value.menus,
              display_name: value.display_name,
            });
            console.log("TITLE IMG", value.title, collections[i].image?.src);
          } else {
            tenantArr.push({
              collection_id: collections[i].collection_id,
              title: collections[i].title,
              image: collections[i].image?.src,
              menus: [],
              display_name: collections[i].title,
            });
          }
        }

        console.log("TENANT", tenantArr);
        setTenantCollectionArr(tenantArr);

        setCollectionData(tenantArr);

        setPickedData(arr);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCollectionList();
  }, []);

  useEffect(() => {
    if (collections.length > 0) {
      getPickedCollection();
    }
  }, [collections.length]);

  console.log("PICKE", pickedData);

  useEffect(() => {
    if (collections.length > 0 && pickedData.length > 0) {
      let tenantArr = [];
      collections.filter((item, i) => {
        pickedData.find((picked) => {
          if (picked.collection_id == item.collection_id) {
            console.log("TRUE", i);
          } else {
            console.log("FALSE", i);
          }
        });
      });
    }
  }, [collections.length > 0]);

  const initialValues = {
    display_name: "",
    menus: [],
  };

  const handleSubmit = async (values) => {
    let selected_tenant_id = localStorage.getItem("tenant_id");
    setIsLoading(true);
    let body = {
      items: tenantCollectionArr,
      tenant_id: selected_tenant_id,
      location_id: localStorage.getItem("selected_location_id").split("#")[1],
      updated_by: localStorage.getItem("fullname"),
    };

    console.log("REEQ BODY", JSON.stringify(body));

    try {
      let res = await axios.post(ApiEndpoints.pickCollection, body);
      console.log("RESPON", res);
      if (res.status === 200) {
        setIsLoading(false);
        toast.success("App and Website Navigation Configuration Updated");
        let action = "pick-collection-create";
        let type = "Info";
        let message = "Pick collecion successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        history.push("/admin/picked-collections", { tenantId: tenantId });
      }
    } catch (error) {
      console.log(error);
      toast.error("Error in collection update");
      setIsLoading(false);
      let action = "pick-collection-create";
      let type = "Error";
      let message = "error in Pick collecion create.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const onDisplayNameChange = (name, item) => {
    console.log("NAME", name, item);
    let values = JSON.parse(JSON.stringify(collectionData));
    let values1 = JSON.parse(JSON.stringify(tenantCollectionArr));
    let collection = values.find(
      (element) => element.collection_id === item.collection_id
    );
    let collection1 = values1.find(
      (element) => element.collection_id === item.collection_id
    );

    collection.display_name = name;
    collection1.display_name = name;
    setCollectionData(values);
    setTenantCollectionArr(values);
  };

  const handleCheckbox = (checked, item, menuType) => {
    console.log("CHECK", checked, item, menuType);
    let values = JSON.parse(JSON.stringify(collectionData));
    let values1 = JSON.parse(JSON.stringify(tenantCollectionArr));
    let collection = values.find(
      (element) => element.collection_id === item.collection_id
    );
    let collection1 = values1.find(
      (element) => element.collection_id === item.collection_id
    );
    if (checked) {
      let include = collection.menus.includes(menuType);
      let include1 = collection1.menus.includes(menuType);
      if (!include) {
        collection.menus.push(menuType);
      }
      if (!include1) {
        collection1.menus.push(menuType);
      }
    } else {
      let index = collection.menus.findIndex((element) => element === menuType);
      let index1 = collection1.menus.findIndex(
        (element) => element === menuType
      );
      if (index >= 0) {
        collection.menus.splice(index, 1);
      }
      if (index1 >= 0) {
        collection1.menus.splice(index1, 1);
      }
    }
    console.log("VALUESSS", values);
    setCollectionData(values);
    setTenantCollectionArr(values);
  };

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    return arr.filter((item) =>
      item["title"].toLowerCase().includes(str.toLowerCase())
    );
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    console.log("STRING", string, results);

    var stringLength = string.length;
    if (stringLength === 0) {
      setCollectionData(tenantCollectionArr);
    } else {
      let searchRes = handleSearch(tenantCollectionArr, string);
      console.log("SEra", searchRes);
      setCollectionData(searchRes);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"App and Website Navigation Configuration"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="orderlist">
                <Row>
                  <Col xs={12} sm={5} className="mt-4">
                    <FormGroup>
                      <Col md="10">
                        <Input
                          placeholder="Search"
                          id="example-text-input"
                          type="text"
                          onChange={(e) => handleOnSearch(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {({}) => (
                    <Form>
                      <Table className="table" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th>Collection Name</th>
                            <th>Display Name</th>
                            <th>Website Menu</th>
                            <th>App Categories</th>
                            <th>Brand Carousel</th>
                            <th>Featured Brands</th>
                            <th>Deal</th>
                            <th>Promotion</th>
                            <th>Feature</th>
                            <th>Best Seller</th>
                          </tr>
                        </thead>

                        <tbody className="">
                          {collectionData?.map((item, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.title}
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <Input
                                  value={item.display_name}
                                  style={{ margin: 0, minWidth: 250 }}
                                  name="display_name"
                                  type="text"
                                  onChange={(e) =>
                                    onDisplayNameChange(e.target.value, item)
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      type="checkbox"
                                      checked={item.menus.includes(
                                        "website_menu"
                                      )}
                                      // name="menus"
                                      // value="website_menu"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "website_menu"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes(
                                        "app_categories"
                                      )}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="app_categories"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "app_categories"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes(
                                        "brand_carousel"
                                      )}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="brand_carousel"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "brand_carousel"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes(
                                        "featured_brands"
                                      )}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="featured_brands"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "featured_brands"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes("deal")}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="featured_brands"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "deal"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes("promotion")}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="featured_brands"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "promotion"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes("feature")}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="featured_brands"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "feature"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormGroup style={{ margin: 0 }}>
                                    <input
                                      checked={item.menus.includes(
                                        "best_seller"
                                      )}
                                      type="checkbox"
                                      style={{ margin: 0 }}
                                      // name="menus"
                                      // value="featured_brands"
                                      onChange={(e) =>
                                        handleCheckbox(
                                          e.target.checked,
                                          item,
                                          "best_seller"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              </td>
                            </tr>
                          ))}
                          <Button
                            style={{ marginLeft: 15 }}
                            color="primary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </tbody>
                      </Table>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default ManageCollections;
