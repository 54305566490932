import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  ButtonGroup,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { formatDateTimeInTimeZone } from "../../helpers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import constantVariable, { ApiEndpoints, SourceName } from "../../constants/constant";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ListTable = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusUpdatePk, setSatatusUpdatePk] = useState();
  const [userVal, SetUserVal] = useState(0);
  const [dispatcherStatus, orderDispatcherStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [tempOrderStatus, setTempOrderStatus] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const { ordersColumns, orders, handleView, history } = props;
  console.log(orders, "ddataa");
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [deliveryTracking, setDeliveryTracking] = useState();
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const handleChange = (event) => {
    setTempOrderStatus(event.target.value);
  };
  const modalToggle = () => setModal(!modal);
  const orderStatusMethod = () => {
    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        SetUserVal(1);
        // setDeliveryStatus(data?.data?.config?.delivery_status)
        console.log(data?.data?.config?.delivery_status);
        orderDispatcherStatus(data?.data?.config?.Dispatcher_Status);
      });
  };
  const setUpdateStatus = (item) => {
    console.log(item, "ddd");
    setSatatusUpdatePk(item?.pk.split("#")[1]);
    setOrderStatus(item?.order_status);
    if (item?.deliveryTracking !== "undefined") {
      setDeliveryTracking(item?.deliveryTracking);
    } else {
      setDeliveryTracking("");
    }
    setModal(true);
  };
  useEffect(() => {
    orderStatusMethod();
  }, [userVal < 1]);
  const handleUpdateStatus = async () => {
    try {
      let body = {};
      body = {
        orderStatus: tempOrderStatus ? tempOrderStatus : orderStatus,
        deliveryTrackingData: deliveryTracking,
      };
      console.log(body);
      setIsLoading(true);
      console.log("ABCD", statusUpdatePk);
      await fetch(`${ApiEndpoints.orderUpdateStatus}${statusUpdatePk}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      setOrderStatus(tempOrderStatus);

      // confirmModalToggle();
      setConfirmModal(false);
      // modalToggle();
      // setNoteAttributeModal(false);
      setModal(false);
      props.handlestatus();
      props.updateOrderStatus("All");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
    setModal(false);
    console.log(tempOrderStatus);
  };
  const handleDelivery = ($event) => {
    setDeliveryTracking($event.target.value);
  };
  function containsNumbers(str) {
    return /[0-9]/.test(str);
  }
  const setSourceName = (sourceName) => {
    console.log("sourceName ",sourceName == "580111", sourceName, typeof sourceName)
    if (sourceName == SourceName.LSWebsiteSourceName) {
      return "LS Website";
    } else {
      return "LS Mobile App";
    }
    //  else if (sourceName == "pos") {
    //   return "Point of sale";
    // }
    //  else {
    //   return sourceName;
    // }
  };

  return (
    <>
      <Table className="align-items-center table-flush mt-20" responsive>
        <thead className="thead-light">
          <tr>
            {ordersColumns.map((item) => (
              <th scope="col">{item.header}</th>
            ))}
            <th scope="col" />
          </tr>
        </thead>
        <tbody className="list">
          {orders && orders?.length ? (
            orders
              ?.sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              ?.map((item) => (
                <tr>
                  <th scope="row">
                    <Media>
                      <span className="name mb-0 text-sm">
                        {item?.order_name}
                      </span>
                    </Media>
                  </th>
                  <td className="budget">{item?.number_of_item}</td>
                  <td>
                    {/* } */}
                    <span className="status">
                      {item?.order_status}&nbsp;
                      {(userRoles.includes("1") ||
                        roleActions.includes("order_update")) && (
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                          onClick={() => setUpdateStatus(item)}
                          style={{ cursor: "pointer", color: "blue" }}
                        ></i>
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="completion mr-2">
                        {item?.total_price}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className="status">
                      {item?.order_date &&
                        formatDateTimeInTimeZone(item?.order_date)}
                    </span>
                  </td>
                  <td>
                    <span className="status">{item?.checkout_method}</span>
                  </td>
                  <td>
                    <span className="status">
                      {setSourceName(item?.source_name)}
                    </span>
                  </td>
                  <td>
                    <ButtonGroup>
                      <Button
                        className="btn-icon-only"
                        color="info"
                        onClick={() =>
                          history.push({
                            pathname: `/admin/order/${
                              item?.pk?.split("#")[1]
                            }/view`,
                            state: { item },
                          })
                        }
                        id={"tooltipv" + item.userId}
                        type="button"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-eye"></i>
                        </span>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target={"tooltipv" + item.userId}
                      >
                        View Order
                      </UncontrolledTooltip>
                    </ButtonGroup>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <p className="mt-4" style={{ textAlign: "center" }}>
                No Data Found...
              </p>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        backdrop="static"
        centered
      >
        <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader>
        <ModalBody>
          <Input
            id="orderStatusInpur"
            type="select"
            onChange={(e) => handleChange(e)}
          >
            <option value={""}>Select</option>
            {dispatcherStatus?.map((status) => (
              <option
                value={status}
                selected={status === orderStatus ? "selected" : ""}
              >
                {status}
              </option>
            ))}
          </Input>
          <br />
          <label>Enter Tracking Link</label>
          <Input
            type="text"
            name="Delivery"
            value={deliveryTracking}
            onChange={(e) => handleDelivery(e)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmModalToggle()}>
            Update
          </Button>
          <Button color="secondary" onClick={modalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmModal}
        fade={false}
        toggle={confirmModalToggle}
        size="sm"
        centered
        backdrop="static"
      >
        {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
        <ModalBody>Are you sure you want to update?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleUpdateStatus()}>
            Yes
          </Button>
          <Button color="secondary" onClick={confirmModalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ListTable;
