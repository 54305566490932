import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
// core components

import { resetForgotPasswordUser } from "../app/redux/auth/actions";

function ForgotPasswordResetView() {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const { state } = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!state?.email) {
      history.push("/forgot-password");
    }
  }, []);

  const resetForgotPassword = async (data, setSubmitting) => {
    let newData = {
      email: state?.email,
      ...data,
    };
    setFormData(data);
    setIsLoading(true);
    dispatch(resetForgotPasswordUser(newData, history, dispatch));
    setSubmitting(false);
    setIsLoading(false);
  };

  return (
    <>
      <div className="header">
        <Container>
          <div className="header-body text-center">
            <Row className="justify-content-center">
              <Col className="" lg="6" md="8" xl="5">
                <div className="mt-6">
                  <img
                    alt="..."
                    className="rounded-circle border-secondary"
                    src={require("../assets/img/brand/logo.png")}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100"></div>
      </div>
      <Container className="pb-8">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card
              className="bg-secondary border-0 mb-0"
              style={{ borderRadius: "0px" }}
            >
              <CardBody className="px-lg-5 py-lg-5 pb-8">
                {auth && auth.errors && auth.errors.Catcherrors && (
                  <p className="text-danger mb-0">
                    <strong>
                      {auth && auth.errors && auth.errors.Catcherrors}
                    </strong>
                  </p>
                )}
                <div className="text-center text-muted mb-4"></div>
                <Formik
                  initialValues={formData}
                  validate={(values) => {
                    const errors = {};

                    if (!values.code) {
                      errors.code = "Required";
                    }

                    if (!values.newPassword) {
                      errors.newPassword = "Required";
                    }

                    if (!values.confirmPassword) {
                      errors.confirmPassword = "Required";
                    } else if (values.confirmPassword != values.newPassword) {
                      errors.confirmPassword = "Passwords didn't match!";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    resetForgotPassword(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form autoComplete="new-password">
                      <div className="FormFieldContainer">
                        {/* <label className="FormLabel">Password</label> */}
                        <h4>
                          We have sent a password reset code by email to{" "}
                          {state?.email}. Enter it below to reset your password
                        </h4>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-badge" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="Code"
                            type="text"
                            name="code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code}
                            autoComplete="new-password"
                          />
                        </InputGroup>

                        <p className="ErrorMessage">
                          {errors.code && touched.code && errors.code}
                        </p>
                      </div>
                      <div className="FormFieldContainer">
                        {/* <label className="FormLabel">Password</label> */}

                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="New Password"
                            type="password"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            autoComplete="new-password"
                          />
                        </InputGroup>

                        <p className="ErrorMessage">
                          {errors.newPassword &&
                            touched.newPassword &&
                            errors.newPassword}
                          {auth && auth.errors && auth.errors.newPassword}
                        </p>
                      </div>

                      <div className="FormFieldContainer">
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="Confirm Password"
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            autoComplete="new-password"
                          />
                        </InputGroup>
                        <p className="ErrorMessage">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                          {auth && auth.errors && auth.errors.confirmPassword}
                        </p>
                      </div>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="info"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* <a
                  className="text-primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small className=" font-weight-bold">Forgot password?</small>
                </a> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgotPasswordResetView;
