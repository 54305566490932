import { useField } from "formik";
import React from "react";
import { Input } from "reactstrap";

const CustomInput = ({ onFileButtonClick, ref, ...props }) => {
  const [field, meta, helpers] = useField(props);
  console.log("Fields", field, meta, helpers);
  const handleFileInputChange = (event) => {
    helpers.setValue(event.currentTarget.files[0]);
  };

  return (
    <>
      {props.type === "file" ? (
        <>
          <input
            type="file"
            id={props.name}
            name={props.name}
            onChange={handleFileInputChange}
            onBlur={field.onBlur}
            style={{ display: "none" }}
            ref={ref}
          />
          {meta.error && meta.touched && <div>{meta.error}</div>}
        </>
      ) : (
        <>
          <Input {...field} {...props} />
          {meta.touched && meta.error ? (
            <span style={{ color: "red" }}>{meta.error}</span>
          ) : null}
        </>
      )}
    </>
  );
};

export default CustomInput;
