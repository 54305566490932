import React, { useEffect, useState } from 'react';
import { ApiUrls } from '../../../common/apiconstant';


function IMGetLocationTitle(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState(null);
    const { id } = props
    
    useEffect(() => {
        fetch(`${ApiUrls.allLocationList}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
        }).then(response => response.json())
            .then(response => {
                let locations = []
                const getLocationTitles=response.data
                getLocationTitles.filter(getLocationTitle => getLocationTitle.location_id == id).map(filteredPerson => (
                    locations.push(filteredPerson)
                ))
                if(locations[0] !== undefined) {
                    setName(locations[0].title)
                }else{
                    setName("")
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }, [])

    if (isLoading) {
        return null
    }
    return (
        <span>{name}</span>
    )
}

export default IMGetLocationTitle