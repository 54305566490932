import axios from "axios";
import { ApiEndpoints } from "../admin/constants/constant";


export const logUserMetrics = async (action, type, message) => {
    let userMetrics = localStorage.getItem("attributes");
    console.log("userMetrics",userMetrics);
    if (userMetrics){
        userMetrics = JSON.parse(userMetrics);
    }
    let reqBody= {
        email: userMetrics?.email,
        email_verified: userMetrics?.email_verified,
        sub: userMetrics?.sub,
        action: action,
        user_name: localStorage.getItem("fullname"),
        device_id: navigator.userAgent,
        type: type,
        message: message
        }

    try {
      const res = await axios.post(ApiEndpoints.userMetrics,reqBody);
      return res;
    } catch (error) {
      console.log("Error in get login user details", error);
      return error;
    }
  };