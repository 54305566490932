import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant.js";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function EditPermission() {
  const classes = useStyles();

  const location = useLocation();

  const { permission, actions, id } = location?.state.item;

  console.log("Location Permission to show==>", location.state.item);
  console.log("39393939393===.", permission);
  console.log("actions===>", actions);
  const [originalData, setOriginalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(actions);
  const [toggle, setToggle] = useState(false);
  console.log("ORORORORO===>", originalData);
  const [endDate, setEndDate] = useState(new Date());
  const auth = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);

  const history = useHistory();
  const update = async () => {
    console.log("Datattta===>", originalData.permission);
    // if (window.confirm('Are You Sure You want to edit permission')) {
    setIsLoading(true);
    let name = localStorage.getItem("fullname");
    let sendToApi = JSON.stringify({
      id: id,
      permission: originalData.permission,
      actions: options,
      updated_by: name,
    });
    console.log("sendToApisendToApi==>", sendToApi);
    fetch(ApiEndpoints.permissionGetURL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: sendToApi,
    })
      .then((response) => response.json())
      .then(async(res) => {
        console.log("data", res);
        if (res.data !== "") {
          setIsLoading(false);
          // setSubmitting(false);
          handleView();
          let action = "permission-edit"
            let type = "Info";
            let message = "Permission successfully edited.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
          setModal(true);
        }
      })
      .catch(async(err) => {
        console.log(err);
        handleView();
        let action = "permission-edit"
          let type = "Error";
          let message = "Error in permission edit.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
      });
  };
  const modalToggle = () => setModal(!modal);

  const handleView = () => {
    history.goBack();
  };
  useEffect(() => {
    setOriginalData(location.state.item);

    return () => {};
  }, []);

  const handlePermissionChange = (e) => {
    console.log("126126126126==>", e.target.value);
    let permission = JSON.parse(JSON.stringify(originalData));
    permission.permission = e.target.value;

    setOriginalData(permission);
  };

  const openModalCheck = () => {
    modalToggle();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Edit Permission</ModalHeader>
        <ModalBody>
          <h2 className="fullcalendar-title h2 d-inline-block mb-0">
            Are you sure you want to update the permission{" "}
            <i class="fal fa-file-spreadsheet"></i>
          </h2>
          <Button color="primary" onClick={update}>
            Submit
          </Button>
          <Button color="secondary" onClick={modalToggle}>
            Cancel
          </Button>
        </ModalBody>

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Edit Permission"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Permission
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  initialValues={originalData}
                  validate={(values) => {
                    // values = { ...values, ...modifiedNonFormData, ...endDateData };
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // update(values, setSubmitting)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Edit Permission Title
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Title"
                              type="text"
                              onChange={handlePermissionChange}
                              onBlur={handleBlur}
                              value={originalData.permission}
                              name="permission"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Edit Permission&nbsp;
                            </label>
                            <TagsInput
                              value={options}
                              onChange={setOptions}
                              name="fruits"
                              placeHolder="Create Permission"
                            />
                            <p className="text-danger mb-0">
                              {errors.screenName &&
                                touched.screenName &&
                                errors.screenName}
                            </p>
                          </FormGroup>
                          <p></p>
                        </Col>
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={openModalCheck}
                            disabled={
                              originalData.permission === "" ||
                              options.length === 0
                                ? true
                                : false
                            }
                          >
                            Update Permission
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default EditPermission;
