import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  ButtonGroup,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { formatDateTimeInTimeZone } from "../../helpers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../styles/listTable.module.css";
import constantVariable, {
  ApiEndpoints,
  SourceName,
} from "../../constants/constant";
import { logUserMetrics } from "../../../utils/file";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PriceListTable = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusUpdatePk, setSatatusUpdatePk] = useState();
  const [userVal, SetUserVal] = useState(0);
  const [dispatcherStatus, orderDispatcherStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [tempOrderStatus, setTempOrderStatus] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const { ordersColumns, orders, handleView, history } = props;
  console.log(orders, "ddataa");
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [deliveryTracking, setDeliveryTracking] = useState();
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const handleChange = (event) => {
    setTempOrderStatus(event.target.value);
  };
  const modalToggle = () => setModal(!modal);
  const orderStatusMethod = () => {
    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        SetUserVal(1);
        // setDeliveryStatus(data?.data?.config?.delivery_status)
        console.log(data?.data?.config?.delivery_status);
        orderDispatcherStatus(data?.data?.config?.Dispatcher_Status);
      });
  };
  const setUpdateStatus = (item) => {
    console.log(item, "ddd");
    setSatatusUpdatePk(item?.pk.split("#")[1]);
    setOrderStatus(item?.order_status);
    if (item?.deliveryTracking !== "undefined") {
      setDeliveryTracking(item?.deliveryTracking);
    } else {
      setDeliveryTracking("");
    }
    setModal(true);
  };
  useEffect(() => {
    orderStatusMethod();
  }, [userVal < 1]);
  const handleUpdateStatus = async () => {
    try {
      let body = {};
      body = {
        orderStatus: tempOrderStatus ? tempOrderStatus : orderStatus,
        deliveryTrackingData: deliveryTracking,
      };
      console.log(body);
      setIsLoading(true);
      console.log("ABCD", statusUpdatePk);
      await fetch(`${ApiEndpoints.orderUpdateStatus}${statusUpdatePk}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      setOrderStatus(tempOrderStatus);

      // confirmModalToggle();
      setConfirmModal(false);
      // modalToggle();
      // setNoteAttributeModal(false);
      setModal(false);
      props.handlestatus();
      props.updateOrderStatus("All");
      setIsLoading(false);
      window.location.reload();
      let action = "edit-order-status-update";
      let type = "Info";
      let message = "Edit Order Status successfully updated.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    } catch (error) {
      console.log(error);
      setError(error);
      let action = "edit-order-status-update";
      let type = "Error";
      let message = "Error in Edit Order Status update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
    setModal(false);
    console.log(tempOrderStatus);
  };
  const handleDelivery = ($event) => {
    setDeliveryTracking($event.target.value);
  };
  function containsNumbers(str) {
    return /[0-9]/.test(str);
  }
  const setSourceName = (sourceName) => {
    console.log(
      "sourceName ",
      sourceName == "580111",
      sourceName,
      typeof sourceName
    );
    if (sourceName == SourceName.LSWebsiteSourceName) {
      return "LS Website";
    } else {
      return "LS Mobile App";
    }
    //  else if (sourceName == "pos") {
    //   return "Point of sale";
    // }
    //  else {
    //   return sourceName;
    // }
  };
  const [filterType, setFilterType] = useState("");
  const [ordersArr, setOrdersArr] = useState([]);
  const [asc, setAsc] = useState(false);

  const toggle = () => {
    setAsc((p) => !p);
  };

  const handleHeader = (item) => {
    console.log("CLICKED", item);
    if (item.accessor == "totalPrice") {
      console.log("IN");
      setFilterType("total_price");
      toggle();
    } else if (item.accessor == "id") {
      setFilterType("id");
      toggle();
    }
  };
  console.log(filterType, "FILTER");

  useEffect(() => {
    setOrdersArr(orders);
  }, [filterType, orders]);

  const getColorForRisk = (riskStatus) => {
    if (riskStatus === "Low") {
      return "#00FF00";
    } else if (riskStatus === "Medium") {
      return "#FF8000";
    } else if (riskStatus === "High") {
      return "#FF0000";
    } else {
      return "white";
    }
  };

  return (
    <>
      <div
        // style={{ height: 550, overflowY: "scroll" }}
        className={styles.tableContent}
      >
        <Table className="align-items-center table-flush ">
          <thead
            // className="thead-light"
            // style={{ position: "sticky", top: 0, zIndex: 100 }}
            className={`thead-light ${styles.tableHeaderContent}`}
          >
            <tr>
              {ordersColumns.map((item) => (
                <th scope="col">{item.header}</th>
              ))}
              <th scope="col" />
            </tr>
          </thead>

          <tbody className="list">
            {ordersArr && ordersArr?.length ? (
              ordersArr
                // ?.sort(
                //   (a, b) =>
                //   asc ? a[filterType] - b[filterType] : b[filterType] - a[filterType]
                // )
                ?.map((item) => (
                  <tr>
                    <th scope="row">
                      <span
                        className="name mb-0 text-sm"
                        onClick={() =>
                          history.push({
                            pathname: `/admin/order/${
                              item?.pk?.split("#")[1]
                            }/view`,
                            state: { item },
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <div>
                            {item?.name}{" "}
                            <span
                              className="dot"
                              style={{
                                display: "inline-block",
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                marginLeft: 5,
                                backgroundColor: getColorForRisk(
                                  item.risk_status
                                ),
                                marginLeft: "5px",
                              }}
                            />
                          </div>
                          <span style={{ fontSize: "11px" }}>
                            {item?.modified_message}
                          </span>
                        </div>
                      </span>
                    </th>
                    <td className="budget">{item?.location_title}</td>
                    <td className="budget">
                      {item?.no_of_item || item?.line_items?.length}
                    </td>
                    <td>
                      {/* } */}
                      <div className="status">
                        {item?.order_status}&nbsp;
                        {(userRoles.includes("1") ||
                          roleActions.includes("order_update")) &&
                          item?.order_status !== "Cancelled" && (
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                              onClick={() => setUpdateStatus(item)}
                              style={{ cursor: "pointer", color: "blue" }}
                            ></i>
                          )}
                      </div>
                      {item.financial_status && (
                        <div
                          className="financial_status"
                          style={{ fontSize: 11 }}
                        >
                          (
                          {item.financial_status.charAt(0).toUpperCase() +
                            item.financial_status.slice(1).toLowerCase()}
                          )
                        </div>
                      )}
                      <span style={{ fontSize: "11px" }}>
                        {item?.modified_message?.split(" ")[1] == "to"
                          ? "Substituted"
                          : ""}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="completion mr-2">
                          {item?.total_price}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="status">
                        {item?.created_at &&
                          formatDateTimeInTimeZone(item?.created_at)}
                      </span>
                    </td>
                    <td>
                      <span className="status">
                        {item?.delivery_method || item?.checkout_method}
                      </span>
                    </td>
                    <td>
                      <span className="status">
                        {setSourceName(item?.source || item?.source_name)}
                      </span>
                    </td>
                    <td>
                      <ButtonGroup>
                        <Button
                          className="btn-icon-only"
                          color="info"
                          onClick={() =>
                            history.push({
                              pathname: `/admin/order/${
                                item?.pk?.split("#")[1]
                              }/view`,
                              state: { item },
                            })
                          }
                          id={"tooltipv" + item.userId}
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="fa fa-eye"></i>
                          </span>
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target={"tooltipv" + item.userId}
                        >
                          View Order
                        </UncontrolledTooltip>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                {/* <div
                  style={{
                    display: "flex",
                    marginLeft: "50px",
                    marginTop: "40px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>No Data Found...</div>
                </div> */}
                <td>No Data Found...</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        backdrop="static"
        centered
      >
        <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader>
        <ModalBody>
          <Input
            id="orderStatusInpur"
            type="select"
            onChange={(e) => handleChange(e)}
          >
            <option value={""}>Select</option>
            {dispatcherStatus?.map((status) => (
              <option
                value={status}
                selected={status === orderStatus ? "selected" : ""}
              >
                {status}
              </option>
            ))}
          </Input>
          <br />
          <label>Enter Tracking Link</label>
          <Input
            type="text"
            name="Delivery"
            value={deliveryTracking}
            onChange={(e) => handleDelivery(e)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmModalToggle()}>
            Update
          </Button>
          <Button color="secondary" onClick={modalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmModal}
        fade={false}
        toggle={confirmModalToggle}
        size="sm"
        centered
        backdrop="static"
      >
        {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
        <ModalBody>Are you sure you want to update?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleUpdateStatus()}>
            Yes
          </Button>
          <Button color="secondary" onClick={confirmModalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PriceListTable;
