import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/listReporting.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";
import { ApiEndpoints } from "../../constants/constant";
import ModalSku from "./ModalSku";
import { X } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SkuList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [skuReport, setSkuReport] = useState({});
  const [page, setPage] = useState(1);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [reportDataPdf, setReportDataPdf] = useState("");
  const [skuItem, setSkuItem] = useState();
  const [flagSkuData, setFlagSkuData] = useState({});
  const [errorFlag, setErrorFlag] = useState(null);
  const [stateModal, setStateModal] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  let location_name = localStorage.getItem("selected_location_name");
  location_name = encodeURIComponent(location_name);
  const getReportingData = (startDate, endDate) => {
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    setIsLoading(true);
    let url = `${ApiEndpoints.getSKUSalesTransactionData}?startDate=${startDate}&endDate=${endDate}&locationName=${location_name}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("skuDATA", res);
        const responseData = res?.body;
        console.log("12345reportlist", responseData);
        setSkuReport(responseData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getReportingDataPdf = (startDate, endDate) => {
    console.log("startdatee", startDate, endDate);
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    let url = `${ApiEndpoints.getSkuTransactionReportPdf}?startDate=${startDate}&endDate=${endDate}&locationName=${location_name}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("REPORTING PDF", res);
        const responseData = res?.message;
        console.log("reportDataPdf123", responseData);
        setReportDataPdf(responseData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getFlaggedDataSKU = async () => {
    try {
      const apiUrl = `${ApiEndpoints.flaggedSKUTransactions}?startDate=${startDate}&endDate=${endDate}&locationName=${location_name}`;
      const apiKey = "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi";
      const response = await axios.get(apiUrl, {
        headers: {
          "x-api-key": apiKey,
        },
      });
      const responseData = response?.data?.body;
      setFlagSkuData(responseData);
      console.log(responseData, "FLAGGG");
    } catch (error) {
      console.warn(error, "ERROR");
      setErrorFlag(error.message);
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getReportingData(startDate, endDate);
      getReportingDataPdf(startDate, endDate);
      getFlaggedDataSKU(startDate, endDate);
    }
  }, [startDate, endDate]);

  console.log("skuReport", skuReport);
  console.log("reportdatapdf", reportDataPdf);
  useEffect(() => {
    const currentDate = moment(); // Get the current date
    const startOfPreviousWeek = currentDate
      .clone()
      .subtract(1, "week")
      .startOf("week"); // Start of the previous week (Sunday)
    const endOfPreviousWeek = startOfPreviousWeek.clone().endOf("week"); // End of the previous week (Saturday)

    setStartDate(startOfPreviousWeek.format("YYYY-MM-DD")); // Convert to string format
    // setStartDate("2024-02-18");
    setEndDate(endOfPreviousWeek.format("YYYY-MM-DD")); // Convert to string format
    // setEndDate("2024-02-24");
  }, []);

  console.log("startandenddate", startDate, endDate);

  const handleDatesChange = ({ startDate, endDate }) => {
    if (startDate) {
      setStartDate(startDate.format("YYYY-MM-DD")); // Convert to string format
    }
    if (endDate) {
      setEndDate(endDate.format("YYYY-MM-DD")); // Convert to string format
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateModal({ ...stateModal, [anchor]: open });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="12">
                    <div className={styles.button_title}>
                      <h6 className={styles.sku_report}>
                        {"SKU Sold by Transaction"}
                      </h6>
                      <div className={styles.buttonStyle011}>
                        {(userRoles.includes("1") ||
                          roleActions.includes("report_download")) && (
                          <a
                            href={reportDataPdf}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className={styles.buttonStyle02}
                              type="button"
                              size="sm"
                              color="default"
                            >
                              Download
                            </Button>
                          </a>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className={styles.sku_calender} lg="6" xs="12">
                    <div className={styles.sku_calender1}>
                      <DateRangePicker
                        startDate={moment(startDate)} // Convert to Moment.js object
                        startDateId="start_date_id"
                        endDate={moment(endDate)} // Convert to Moment.js object
                        endDateId="end_date_id"
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={setFocusedInput}
                        isOutsideRange={() => false} // Allow selecting dates in the past
                        numberOfMonths={1} // Show only one month
                        monthFormat="MMM YYYY" // Month format
                        displayFormat="MM/DD/YYYY" // Display format for the selected date
                        showClearDates={false} // Show the clear button
                        minimumNights={0} // Minimum number of nights required for a range
                        startDatePlaceholderText="Start Date" // Placeholder for start date input
                        endDatePlaceholderText="End Date" // Placeholder for end date input
                        showDefaultInputIcon={true}
                        enableOutsideDays={true}
                        small={true}
                      />
                    </div>

                    <ModalSku
                      stateModal={stateModal}
                      setStateModal={setStateModal}
                      toggleDrawer={toggleDrawer}
                    >
                      {skuReport && Object.keys(flagSkuData).length ? (
                        <>
                          <div className={styles.order_missing_header}>
                            <span
                              onClick={toggleDrawer("right", false)}
                              style={{ cursor: "pointer" }}
                            >
                              <X
                                style={{
                                  color: "#525f80",
                                  fontSize: "26px",
                                }}
                              />
                            </span>
                            <div className={styles.orderid_discliamer_cont}>
                              <span className={styles.orderid_heading}>
                                Order Id missing - for period{"  "} {skuItem}
                              </span>
                              <span className={styles.orderid_discliamer_text}>
                                * Order id missing on Cash remittance report
                              </span>
                            </div>
                          </div>

                          <div
                            // key={item}
                            style={{
                              overflowY: "auto",
                              height: "700px",
                            }}
                          >
                            {" "}
                            <Table bordered>
                              <thead
                                className="thead-light"
                                style={{
                                  position: "sticky",
                                  top: -1,
                                  zIndex: 1,
                                }}
                              >
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">App</th>
                                  <th scope="col">Order Number</th>
                                </tr>
                              </thead>

                              <tbody className="list">
                                {flagSkuData[skuItem] ? (
                                  flagSkuData &&
                                  skuItem &&
                                  flagSkuData[skuItem] &&
                                  flagSkuData[skuItem].map((report, index) => (
                                    <tr key={index}>
                                      <td>{report.date || "-"}</td>
                                      <td>{report.app || "-"}</td>
                                      <td>{report.order_number || "-"}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="4"
                                      style={{ fontSize: "15px" }}
                                    >
                                      No data found...
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </>
                      ) : (
                        <div style={{ margin: "20px" }}>
                          <p style={{ fontSize: "14px" }}>No data found</p>
                        </div>
                      )}
                    </ModalSku>
                    <div className={styles.buttonStyle01}>
                      {(userRoles.includes("1") ||
                        roleActions.includes("report_download")) && (
                        <a
                          href={reportDataPdf}
                          download="MyExampleDoc"
                          target="_blank"
                        >
                          <Button
                            className={styles.buttonStyle02}
                            type="button"
                            size="sm"
                            color="default"
                          >
                            Download
                          </Button>
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              {skuReport && Object.keys(skuReport).length ? (
                Object.keys(skuReport).map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          height: "90px",
                          border: "2px solid #f6f9fc",
                          fontSize: "18px",
                          fontWeight: 700,
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "flex",
                          paddingLeft: "20px",
                        }}
                      >
                        <span>
                          SKU Sold by Transaction - for period{"    "}
                          {item}
                        </span>
                        <div className={styles.order_missing_button_desktop}>
                          {(userRoles.includes("1") ||
                            roleActions.includes("report_download")) && (
                            <Button
                              onClick={() => {
                                setStateModal({ right: true });
                                setSkuItem(item);
                              }}
                              type="button"
                              size="sm"
                              color="default"
                            >
                              Order Id missing
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className={styles.order_missing_button_mobile}>
                        {(userRoles.includes("1") ||
                          roleActions.includes("report_download")) && (
                          <Button
                            onClick={() => {
                              setStateModal({ right: true });
                              setSkuItem(item);
                            }}
                            type="button"
                            size="sm"
                            color="default"
                          >
                            Order Id missing
                          </Button>
                        )}
                      </div>
                      <div
                        key={item}
                        style={{
                          overflowY: "auto",
                          height: "700px",
                        }}
                      >
                        <Table bordered>
                          <thead
                            className="thead-light"
                            style={{
                              position: "sticky",
                              top: -1,
                              zIndex: 1,
                            }}
                          >
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">App</th>
                              <th scope="col">Order Number</th>
                              <th scope="col">Product Name</th>
                              <th scope="col">Qty</th>
                            </tr>
                          </thead>

                          <tbody className="list">
                            {skuReport[item].map((report, index) => (
                              <tr key={index}>
                                <td>{report.date || "-"}</td>
                                <td>{report.app || "-"}</td>
                                <td>{report.order_number || "-"}</td>
                                <td>{report.product_name || "-"}</td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {report.qty || "-"}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  );
                })
              ) : (
                <div style={{ margin: "20px" }}>
                  <p style={{ fontSize: "14px" }}>No data found</p>
                </div>
              )}

              <CardFooter>
                {/* {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )} */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SkuList;
