import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  Button,
  Input,
  FormGroup,
  Col,
  Table,
  CardHeader,
} from "reactstrap";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { ApiEndpoints } from "../../constants/constant";
import { logUserMetrics } from "../../../utils/file.js";
import axios from "axios";
import { toast } from "react-toastify";
import FullPageLoader from "../../../components/FullPageLoader";
const OrderRefund = (props) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refundReasons, setRefundReasons] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [isCheckedList, setIsCheckedList] = useState(false);
  const [refundStatus, setRefundStatus] = useState("refund");
  const [toggle, setToggle] = useState(false);
  const [refundError, setRefundError] = useState("");
  const [refundPartialStatus, setRefundPartialStatus] = useState(false);

  let { orderId } = useParams();

  console.log("orderid", orderId);

  const [orderDetail, setOrderDetail] = useState({});

  console.log("orderDetail", orderDetail);

  const getOrder = async () => {
    fetch(`${ApiEndpoints.orderGetById}${orderId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);

        let res = JSON.parse(JSON.stringify(data));

        let total = 0;
        for (let i in res?.data?.orderDetail?.line_items) {
          let num = res?.data?.orderDetail?.line_items[i].quantity;
          console.log("num1", num);
          for (let j in res?.data?.orderDetail?.refunds) {
            let refunded_item = res?.data?.orderDetail?.refunds[
              j
            ].refund_line_items.find(
              (e) => e.line_item_id == res?.data?.orderDetail?.line_items[i].id
            );

            console.log("refunded_item", refunded_item);
            console.log("num", num);
            if (refunded_item) {
              num = num - refunded_item.quantity;
            }
          }
          total = total + num;

          res.data.orderDetail.line_items[i].selected_qty = 0;
        }

        if (total == 0) {
          console.log("12345678======");
          setRefundPartialStatus(true);
        }

        setOrderDetail(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  const handleCheckboxChange = () => {
    setIsCheckedList(!isCheckedList);
  };

  const refundOrderApiHandle = async (values, setSubmitting) => {
    setRefundError(values.refundType);

    console.log("refunderrere", refundError);
    // setIsLoading(true);
    try {
      console.log("SUBMIT", orderDetail, values);
      console.log("refundType", values.refundType);
      if (values.refundType == "DELIVERY_FEE") {
        let requestBody = {
          refundType: values.refundType,
          orderId: orderDetail?.data?.orderDetail?.id,
          refundReason:
            values.deliveryReason === "Other"
              ? values.otherDeliveryReason
              : values.deliveryReason,
        };

        console.log("requestBody", requestBody);
        setIsLoading(true);
        let res = await axios.put(ApiEndpoints.refundOrder, requestBody);
        console.log("res12", res);
        if (res.status === 200) {
          toast.success("Order Refunded Successfully");
          history.push(`/admin/order/${orderId}/view`);
          setIsLoading(false);
          let action = "order-refund-status";
          let type = "Info";
          let message = "Order refund successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        }
      } else {
        let items = orderDetail?.data?.orderDetail?.line_items;
        let refundLineItems = [];
        let selectedQtySum = 0;
        items.map((item) => {
          if (item.selected_qty > 0) {
            refundLineItems.push({ qty: item.selected_qty, id: item.id });
            selectedQtySum = selectedQtySum + item.selected_qty;
          }
        });

        let requestBody = {
          refundType: "PARTIAL",
          orderId: orderDetail?.data?.orderDetail?.id,
          refundReason:
            values.reason === "Other" ? values.otherReason : values.reason,
          refundLineItems: refundLineItems,
          shipping: isCheckedList,
        };
        console.log("requestBody", requestBody);

        if (selectedQtySum == 0) {
          toast.error("Please Select Atleast One Item for Refund");
          setIsLoading(false);
          setSubmitting(false);
          return;
        }

        setIsLoading(true);
        let res = await axios.put(ApiEndpoints.refundOrder, requestBody);
        console.log("res12", res);
        if (res.status === 200) {
          toast.success("Order Refunded Successfully");
          history.push(`/admin/order/${orderId}/view`);
          setIsLoading(false);
          let action = "order-refund-status";
          let type = "Info";
          let message = "Order refund successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "Error");
      toast.error("Error in Order Refund");
      let action = "order-refund-status";
      let type = "Error";
      let message = "Order cannot refund.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleChangeRefundItems = async (item) => {
    const tempItemArr = [...lineItems, { id: item.id, title: item.name }];
    await setLineItems(tempItemArr);
  };

  const onChangeSelectedValue = (value, item, data) => {
    //  console.log("valuihds",value);
    let enforcedValue = enforceMinMax(data);
    if (enforcedValue) {
      let orderDetails = JSON.parse(JSON.stringify(orderDetail));
      for (let i in orderDetails.data.orderDetail.line_items) {
        if (orderDetails.data.orderDetail.line_items[i].id == item.id) {
          orderDetails.data.orderDetail.line_items[i].selected_qty = Number(
            enforcedValue.value
          );
        }
      }

      setOrderDetail(orderDetails);
    }
  };

  useEffect(() => {
    console.log("1234567", toggle);
    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
      .then((data) => {
        console.log("datareason", data);
        setRefundReasons(data?.data?.config?.Dispacther_Refund_Reason);
        setIsLoading(false);
      });
  }, [toggle]);

  // console.log("refundType",refundStatus);

  function enforceMinMax(el) {
    console.log("el45896", el);
    if (el.value != "") {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min;
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max;
      }
    }
    console.log("elasdsa", el);
    return el;
  }

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Header name="Home" parentName="Dispatch / Refund" />
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="8">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      Order Refund
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              {/* <span
                className="back-btn"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.goBack()}
              >
                <i class="fa fa-arrow-left " aria-hidden="true"></i>
              </span> */}
              <CardBody>
                {/* <h6 className="mb-6">Order Refund</h6> */}
                <Row>
                  <Col md="12" sm="6">
                    <Formik
                      initialValues={{
                        refundType:
                          refundPartialStatus == false
                            ? "PARTIAL"
                            : "DELIVERY_FEE",
                        reason: "",
                        refundReason: "",
                        deliveryRefundReason: "",
                        delivery: "",
                        otherReason: "",
                        otherDeliveryReason: "",
                        deliveryReason: "",
                        lineItems: [],
                      }}
                      enableReinitialize={true}
                      validate={(values) => {
                        const errors = {};
                        if (!values.refundType) {
                          errors.refundType = "Field Required!";
                        }
                        if (values.refundType === "PARTIAL") {
                          if (values.reason == "Select") {
                            errors.refundReason = "Please select an option";
                          } else if (!values.reason) {
                            errors.refundReason = "Field Required!";
                          }
                        }
                        //  else {
                        //   if (values.deliveryReason == "Select") {
                        //     errors.deliveryRefundReason = "Please select an option";
                        //   } else if (!values.deliveryReason) {
                        //     errors.deliveryRefundReason = "Field Required!";
                        //   }
                        // }

                        if (values.refundType === "DELIVERY_FEE") {
                          if (values.deliveryReason == "Select") {
                            errors.deliveryRefundReason =
                              "Please select an option";
                          } else if (!values.deliveryReason) {
                            errors.deliveryRefundReason = "Field Required! 132";
                          }
                        }
                        // if (!values.reason) {
                        //   errors.refundReason = "Field Required!";
                        // }

                        if (values.refundType === "PARTIAL") {
                          if (
                            values.reason === "Other" &&
                            !values.otherReason
                          ) {
                            errors.otherReason = "Field Required!";
                          }
                        }

                        if (values.refundType === "DELIVERY_FEE") {
                          if (
                            values.deliveryReason === "Other" &&
                            !values.otherDeliveryReason
                          ) {
                            errors.otherDeliveryReason = "Field Required!";
                          }
                        }

                        // if (values.reason === "Other" && !values.otherReason) {
                        //   errors.otherReason = "Field Required!";
                        // }
                        // if (values.reason === "Other" && !values.otherDeliveryReason) {
                        //   errors.otherDeliveryReason = "Field Required!";
                        // }
                        return errors;
                      }}
                      onSubmit={(
                        values,
                        { setSubmitting, setFieldTouched }
                      ) => {
                        setFieldTouched("refundReason", true);
                        refundOrderApiHandle(values, setSubmitting);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setValues,
                        setTouched,
                        setFieldValue,
                        setFieldError,
                        resetForm,
                      }) => (
                        <Form>
                          {console.log("erorororo", errors, values)}
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example5cols3Input"
                            >
                              Refund Type
                            </label>
                            <Row>
                              {/* <Col md="3" sm="6">
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="ENTIRE_ORDER"
                                    defaultChecked
                                    name="refundType"
                                    type="radio"
                                    defaultValue="ENTIRE_ORDER"
                                    value="ENTIRE_ORDER"
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="ENTIRE_ORDER"
                                  >
                                    Entire Order
                                  </label>
                                </div>
                              </Col> */}
                              {refundPartialStatus == false ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Row> */}
                                  <Col lg="12">
                                    <div className="custom-control custom-radio mb-3">
                                      <input
                                        className="custom-control-input"
                                        id="PARTIAL"
                                        name="refundType"
                                        defaultChecked
                                        type="radio"
                                        value="PARTIAL"
                                        onChange={(e) => {
                                          // setFieldError("reason" , "");
                                          resetForm();
                                          handleChange(e);
                                          setToggle(!toggle);
                                        }}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="PARTIAL"
                                      >
                                        Refund Items
                                      </label>
                                    </div>
                                  </Col>
                                  <Col lg="12">
                                    {orderDetail?.data?.orderDetail
                                      ?.checkout_method !== "Local pickup" &&
                                      (!orderDetail?.data?.orderDetail
                                        ?.refunds ||
                                        !orderDetail?.data?.orderDetail?.refunds.some(
                                          (refund) =>
                                            refund.order_adjustments?.some(
                                              (adjustment) =>
                                                adjustment.reason ===
                                                "Shipping refund"
                                            )
                                        )) && (
                                        <div className="custom-control custom-radio mb-3">
                                          <input
                                            className="custom-control-input"
                                            id="DELIVERY_FEE"
                                            name="refundType"
                                            type="radio"
                                            value="DELIVERY_FEE"
                                            onChange={(e) => {
                                              // setFieldError("deliveryRefundReason" , "");
                                              resetForm();
                                              handleChange(e);
                                              setToggle(!toggle);
                                            }}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="DELIVERY_FEE"
                                          >
                                            Delivery Fee
                                          </label>
                                        </div>
                                      )}
                                  </Col>
                                  {/* </Row> */}
                                </div>
                              ) : (
                                <Col md="3" sm="6">
                                  {orderDetail?.data?.orderDetail
                                    ?.checkout_method !== "Local pickup" &&
                                    (!orderDetail?.data?.orderDetail?.refunds ||
                                      !orderDetail?.data?.orderDetail?.refunds.some(
                                        (refund) =>
                                          refund.order_adjustments?.some(
                                            (adjustment) =>
                                              adjustment.reason ===
                                              "Shipping refund"
                                          )
                                      )) && (
                                      <div className="custom-control custom-radio mb-3">
                                        <input
                                          className="custom-control-input"
                                          id="DELIVERY_FEE"
                                          name="refundType"
                                          type="radio"
                                          defaultChecked
                                          value="DELIVERY_FEE"
                                          onChange={(e) => {
                                            // setFieldError("deliveryRefundReason" , "");
                                            resetForm();
                                            handleChange(e);
                                            setToggle(!toggle);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="DELIVERY_FEE"
                                        >
                                          Delivery Fee
                                        </label>
                                      </div>
                                    )}
                                </Col>
                              )}
                              {/* <Col md="3" sm="6">
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="PARTIAL"
                                    name="refundType"
                                    defaultChecked
                                    type="radio"
                                    value="PARTIAL"
                                    onChange={(e) => {
                                      // setFieldError("reason" , "");
                                      resetForm();
                                      handleChange(e);
                                      setToggle(!toggle);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="PARTIAL"
                                  >
                                    Refund Items
                                  </label>
                                </div>
                              </Col>
                              <Col md="3" sm="6">
                                {orderDetail?.data?.orderDetail
                                  ?.checkout_method !== "Local pickup" &&
                                  (!orderDetail?.data?.orderDetail?.refunds ||
                                    !orderDetail?.data?.orderDetail?.refunds.some(
                                      (refund) =>
                                        refund.order_adjustments?.some(
                                          (adjustment) =>
                                            adjustment.reason ===
                                            "Shipping refund"
                                        )
                                    )) && (
                                    <div className="custom-control custom-radio mb-3">
                                      <input
                                        className="custom-control-input"
                                        id="DELIVERY_FEE"
                                        name="refundType"
                                        type="radio"
                                        value="DELIVERY_FEE"
                                        onChange={(e) => {
                                          // setFieldError("deliveryRefundReason" , "");
                                          resetForm();
                                          handleChange(e);
                                          setToggle(!toggle);
                                        }}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="DELIVERY_FEE"
                                      >
                                        Delivery Fee
                                      </label>
                                    </div>
                                  )}
                              </Col> */}

                              {/* <Col md="3" sm="6">
                                <div className="custom-control custom-radio mb-3">
                                  <input
                                    className="custom-control-input"
                                    id="CANCEL"
                                    name="refundType"
                                    type="radio"
                                    value="CANCEL"
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="CANCEL"
                                  >
                                    Cancel Order
                                  </label>
                                </div>
                              </Col> */}
                              <p
                                className="ErrorMessage"
                                style={{ paddingLeft: "12px" }}
                              >
                                {errors.refundType && errors.refundType}
                              </p>
                            </Row>
                            {values.refundType === "PARTIAL" &&
                              refundPartialStatus == false && (
                                <Row className="mt-5">
                                  <Col md="6" sm="6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="reasonInput"
                                    >
                                      Items
                                    </label>
                                    <Table responsive hover size="md" bordered>
                                      <thead>
                                        <tr>
                                          {/* <th></th> */}
                                          <th>Item Name</th>
                                          <th>Item Quantity</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orderDetail?.data?.orderDetail?.line_items.map(
                                          (items) => {
                                            let qty = items.quantity;
                                            let a =
                                              orderDetail?.data?.orderDetail?.refunds.map(
                                                (refundItems) => {
                                                  let b =
                                                    refundItems.refund_line_items.find(
                                                      (e) =>
                                                        e.line_item_id ==
                                                        items.id
                                                    );
                                                  console.log("b", b);

                                                  if (b) {
                                                    qty = qty - b.quantity;
                                                  }
                                                }
                                              );
                                            console.log("items", items);
                                            console.log(
                                              "orderDetail?.data?.orderDetail?.refunds",
                                              orderDetail?.data?.orderDetail
                                                ?.refunds
                                            );

                                            // for(let i in props?.location?.state?.originalData?.refunds){
                                            //   let b = props?.location?.state?.originalData?.refunds[i].refund_line_items.find((elements)=>
                                            //     elements.line_item_id == items.id
                                            // )
                                            // if(b){
                                            //   qty = qty - b.quantity
                                            // }
                                            // }
                                            if (qty > 0) {
                                              return (
                                                <tr>
                                                  {/* <td>
                                                  <input
                                                    id="customCheck1"
                                                    type="checkbox"
                                                    name="itemId"
                                                    onClick={() =>
                                                      handleChangeRefundItems(
                                                        items
                                                      )
                                                    }
                                                  />
                                                </td> */}
                                                  <td>{items.name}</td>
                                                  <td
                                                    style={{
                                                      padding: "0.4rem 0.8rem",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        borderRadius: "8px",
                                                        overflow: "hidden",
                                                        padding:
                                                          "0.4rem 0 0.4rem 0.8rem",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        // justifyContent:"space-between",
                                                        width: "70%",
                                                      }}
                                                    >
                                                      <input
                                                        type="number"
                                                        name="lineItems"
                                                        min={0}
                                                        max={qty}
                                                        defaultValue={0}
                                                        onChange={(e) => {
                                                          console.log(
                                                            "valuihds",
                                                            e,
                                                            e.target.value
                                                          );
                                                          onChangeSelectedValue(
                                                            e.target.value,
                                                            items,
                                                            {
                                                              value:
                                                                e.target.value,
                                                              min: 0,
                                                              max: qty,
                                                            }
                                                          );
                                                        }}
                                                        style={{
                                                          border: "none",
                                                          outline: "none",
                                                          background: "none",
                                                          flex: 0.5,
                                                        }}
                                                        value={
                                                          items.selected_qty
                                                        }
                                                      />
                                                      <span>/{qty}</span>
                                                      {/* <div
                                                      style={{
                                                        margin: "-0.4rem 0",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <button
                                                      >
                                                        +
                                                      </button>
                                                      <button
                                                      >
                                                        -
                                                      </button>
                                                    </div> */}
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            } else {
                                              return null;
                                            }
                                          }
                                        )}

                                        {/* <div style={{margin:"20px 0 20px 20px",fontSize:14}}>
                                    <Input
                                      type="checkbox"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label>Restock Items</label>
                                    <br />
                                    {!isChecked ? (
                                      <span style={{fontSize:12}}>These items will need to be restocked manually</span>
                                    ) : null}                                    
                                    </div>*/}
                                      </tbody>
                                    </Table>

                                    {orderDetail?.data?.orderDetail
                                      ?.checkout_method !== "Local pickup" &&
                                      !orderDetail?.data?.orderDetail?.refunds?.some(
                                        (refund) =>
                                          refund.order_adjustments?.some(
                                            (adjustment) =>
                                              adjustment.reason ===
                                              "Shipping refund"
                                          )
                                      ) && (
                                        <Card style={{ marginTop: 35 }}>
                                          <CardHeader>
                                            Refund shipping
                                          </CardHeader>
                                          <CardBody>
                                            <div style={{ fontSize: 14 }}>
                                              {orderDetail?.data?.orderDetail?.shipping_lines.map(
                                                (item, index) => (
                                                  <div key={index}>
                                                    <label>
                                                      Shipping rate: Local
                                                      delivery (${item.price})
                                                    </label>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        marginLeft: 20,
                                                      }}
                                                    >
                                                      <Input
                                                        type="checkbox"
                                                        checked={isCheckedList}
                                                        onChange={() =>
                                                          handleCheckboxChange()
                                                        }
                                                      />
                                                      <span>Refund amount</span>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </CardBody>
                                        </Card>
                                      )}

                                    <Row className="mt-5">
                                      <Col lg="6">
                                        <label
                                          className="form-control-label"
                                          htmlFor="reasonInput"
                                        >
                                          Refund Reason
                                        </label>
                                        <Input
                                          id="reasonInput"
                                          type="select"
                                          name="reason"
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                        >
                                          <option value={"Select"}>
                                            Select
                                          </option>
                                          {refundReasons?.map((x) => (
                                            <option value={x}>{x}</option>
                                          ))}
                                        </Input>
                                        <p
                                          className="ErrorMessage"
                                          style={{ paddingLeft: "12px" }}
                                        >
                                          {errors.refundReason &&
                                            touched.refundReason &&
                                            // refundError == "PARTIAL" &&
                                            errors.refundReason}
                                        </p>
                                      </Col>
                                    </Row>
                                    {values.reason === "Other" && (
                                      <div>
                                        <Row className="mt-5">
                                          <Col md="6">
                                            <label
                                              className="form-control-label"
                                              htmlFor="otherreason"
                                            >
                                              Mention Other Reason
                                            </label>
                                            <Input
                                              id="otherreason"
                                              placeholder="Other Reason"
                                              rows="3"
                                              type="textarea"
                                              name="otherReason"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                          </Col>
                                        </Row>
                                        <p
                                          className="ErrorMessage"
                                          style={{ paddingLeft: "12px" }}
                                        >
                                          {errors.otherReason &&
                                            touched.otherReason &&
                                            errors.otherReason}
                                        </p>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              )}

                            {values.refundType === "DELIVERY_FEE" && (
                              <div>
                                <Row className="mt-5">
                                  <Col md="6" sm="6">
                                    <Card style={{ marginTop: 35 }}>
                                      <CardHeader>Refund shipping</CardHeader>
                                      <CardBody>
                                        <div style={{ fontSize: 14 }}>
                                          {orderDetail?.data?.orderDetail?.shipping_lines.map(
                                            (item, index) => (
                                              <div key={index}>
                                                <label>
                                                  Shipping rate: Local delivery
                                                  ($
                                                  {item.price})
                                                </label>
                                                {/* <div
                                            style={{
                                              display: "flex",
                                              marginLeft: 20,
                                            }}
                                          >
                                            <Input
                                              type="checkbox"
                                              checked={isCheckedList}
                                              onChange={() =>
                                                handleCheckboxChange()
                                              }
                                            />
                                            <span>Refund amount</span>
                                          </div> */}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                <Row className="mt-5">
                                  <Col md="3" sm="6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="deliveryReasonInput"
                                    >
                                      Refund Reason
                                    </label>
                                    <Input
                                      id="deliveryReasonInput"
                                      type="select"
                                      name="deliveryReason"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <option value={"Select"}>Select</option>
                                      {refundReasons?.map((x) => (
                                        <option value={x}>{x}</option>
                                      ))}
                                    </Input>
                                    <p
                                      className="ErrorMessage"
                                      style={{ paddingLeft: "12px" }}
                                    >
                                      {errors.deliveryRefundReason &&
                                        touched.deliveryRefundReason &&
                                        errors.deliveryRefundReason}
                                    </p>
                                  </Col>
                                </Row>
                                {values.deliveryReason === "Other" && (
                                  <div>
                                    <Row className="mt-5">
                                      <Col md="3" sm="6">
                                        <label
                                          className="form-control-label"
                                          htmlFor="otherDeliveryReasonInput"
                                        >
                                          Mention Other Reason
                                        </label>
                                        <Input
                                          id="otherDeliveryReasonInput"
                                          placeholder="Other Reason"
                                          rows="3"
                                          type="textarea"
                                          name="otherDeliveryReason"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </Col>
                                    </Row>
                                    <p
                                      className="ErrorMessage"
                                      style={{ paddingLeft: "12px" }}
                                    >
                                      {errors.otherDeliveryReason &&
                                        touched.otherDeliveryReason &&
                                        errors.otherDeliveryReason}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </FormGroup>
                          <Button
                            color="primary"
                            className="mt-5"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Initiate Refund
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(OrderRefund);
