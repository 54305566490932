import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CustomInput,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import "./styles.css";
import styles from "../../styles/importEdit.module.css";
import { logUserMetrics } from "../../../utils/file";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let productArray = [];

const arrData = [
  {
    main_title: "Product Name",
    id: "product_name",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "UPC",
    id: "upc",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "Category",
    id: "category",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "Amount",
    id: "amount",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
];

const InventoryFileView = () => {
  //const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { item, permission } = location?.state;
  //console.log("id", id);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const handleClose = () => setShowModal(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");
  const [exportProducts, setExportProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [productSuggModal, setProductSuggModal] = useState(false);
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedHeaderOptions, setSelectedHeaderOptions] = useState([]);
  const [allHeaderOptions, setHeaderAllOptions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allFileData, setAllFileData] = useState([]);
  const [importFileData, setImportFileData] = useState([]);
  const [systemMappedChecked, setSystemMappedChecked] = useState(true);
  const [invalidDataChecked, setInvalidDataChecked] = useState(true);
  const [notFoundChecked, setNotFoundChecked] = useState(true);
  const [manuallyDataChecked, setManuallyDataChecked] = useState(true);

  console.log(
    "checked",
    systemMappedChecked,
    invalidDataChecked,
    notFoundChecked
  );

  const [dataItem, setDataItem] = useState(item);

  console.log("dataItem", dataItem);
  console.log("ITEM", item);

  const getPosData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posInventoryDataByID}/${item.id}`;
    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("response", res);
        setAllData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getPosData();
  }, []);

  const getPosFileData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posImportedDataByID}/${item.id}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("responsFile", res);
        setAllFileData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getPosFileData();
  }, []);

  console.log("allfiledata", allFileData);

  const getImportFileData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posStatusCount}/${item.id}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("responsImportFile", res);
        setImportFileData(res?.data?.rows[0]);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getImportFileData();
  }, []);

  console.log("importFileData", importFileData);

  const getHeaderOptions = () => {
    setIsLoading(true);
    let getHeaders = `${ApiEndpoints.posUploadedHeaders}/${item.id}`;

    fetch(getHeaders)
      .then((response) => response.json())
      .then((res) => {
        let response = res?.data?.headers;
        console.log("responseres", response);
        let tempResponseHeaders = [...response];
        let allHeaders = [];
        for (let i = 0; i < tempResponseHeaders.length; i++) {
          allHeaders.push({
            value: tempResponseHeaders[i],
            label: tempResponseHeaders[i],
          });
        }
        console.log("all headers", allHeaders);
        setHeaderAllOptions(allHeaders);
        let transformedHeaderData = response.map((item, index) => ({
          id: index.toString(),
          main_title: item,
          options: [
            {
              title: item,
              id: index.toString(),
            },
          ],
          options2: allOptions,
        }));
        console.log("responseHeaders", transformedHeaderData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getHeaderOptions();
  }, []);

  console.log("headeropt", allHeaderOptions);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}>
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Imports"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}>
                        Imported By
                      </label>
                      <div>{allData?.uploaded_by}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}>
                        Status
                      </label>
                      <div>{allData?.processing_status}</div>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}>
                        Imported On
                      </label>
                      <div>
                        {moment(allData?.created_at).format("MM/DD/YYYY")}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}>
                        Transaction Id
                      </label>
                      <div>{allData?.id}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}>
                        File Name
                      </label>
                      <div>{allData?.file_name}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}>
                        Download File
                      </label>
                      <div>
                        {item.uploaded_s3_url !== "" && (
                          <a
                            href={item.uploaded_s3_url}
                            download="MyExampleDoc"
                            target="_blank">
                            <Button
                              className="buttons-copy buttons-html5 btn-link"
                              // color="default"
                              size="sm">
                              <span>
                                <i class="fas fa-download"></i>
                              </span>
                            </Button>
                          </a>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  {allData?.reason ? (
                    <Col md="4" sm="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example1cols1Input"
                          style={{ fontWeight: 700 }}>
                          Reason
                        </label>
                        <div>{allData?.reason}</div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {/* </div> */}
                </Row>
                {allData?.processing_status !== "Mapping pending" &&
                importFileData !== undefined ? (
                  <>
                    <Row>
                      {" "}
                      <Col md="6">
                        <FormGroup>
                          <Label style={{ fontWeight: 700 }}>File Data</Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* {importFileData?.manuallyUpdatedCount > "0" && (
                      <Row>
                        {" "}
                        <Col lg="6">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: "20px",
                              marginBottom: "30px",
                            }}
                          >
                            {importFileData?.manuallyUpdatedCount && (
                              <label className="check">
                                <input
                                  disabled
                                  type="checkbox"
                                  checked={manuallyDataChecked}
                                  onChange={() =>
                                    setManuallyDataChecked(manuallyDataChecked)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            )}
                            <Col lg="6">
                              <label style={{ marginBottom: "0" }}>
                                Manually Updated
                              </label>
                            </Col>
                            <Col lg="2">
                              <span>
                                {importFileData?.manuallyUpdatedCount}
                              </span>
                            </Col>
                            <Col
                              lg="5"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                // gap: "40px",
                              }}
                            >
                              {importFileData?.manuallyUpdatedCount > "0" ? (
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/manually-updated/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Manually Updated",
                                    },
                                  }}
                                >
                                  View
                                </Link>
                              ) : null}
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    )} */}

                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "20px",
                            marginBottom: "30px",
                          }}>
                          {importFileData?.systemMappedCount && (
                            <label className="check">
                              <input
                                disabled
                                type="checkbox"
                                checked={systemMappedChecked}
                                onChange={() =>
                                  setSystemMappedChecked(systemMappedChecked)
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          )}
                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Mapped Successfully
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.systemMappedCount}</span>
                          </Col>
                          <Col
                            lg="5"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                              // gap: "40px",
                            }}>
                            {importFileData?.systemMappedCount > "0" ? (
                              <Link
                                style={{ fontSize: "15px" }}
                                to={{
                                  pathname: `/admin/inventory/view/${dataItem.id}`,
                                  state: {
                                    item: allData,
                                    permission: "edit",
                                    selectedKey: "System Mapped",
                                  },
                                }}>
                                View
                              </Link>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "20px",
                            marginBottom: "30px",
                          }}>
                          {importFileData?.invalidDataCount && (
                            <label className="check">
                              <input
                                disabled
                                type="checkbox"
                                checked={invalidDataChecked}
                                onChange={() =>
                                  setInvalidDataChecked(!invalidDataChecked)
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          )}

                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Invalid Data
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.invalidDataCount}</span>
                          </Col>
                          <Col
                            lg="4"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "end",
                              gap: "50px",
                            }}>
                            {importFileData?.invalidDataCount > "0" ? (
                              <>
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/view/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Invalid input",
                                    },
                                  }}>
                                  View
                                </Link>
                              </>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "20px",
                            marginBottom: "30px",
                          }}>
                          {importFileData?.productNotFoundCount && (
                            <label className="check">
                              <input
                                disabled
                                type="checkbox"
                                checked={notFoundChecked}
                                onChange={() =>
                                  setNotFoundChecked(!notFoundChecked)
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          )}
                          <Col lg="6">
                            <label style={{ marginBottom: "0" }}>
                              Not found
                            </label>
                          </Col>
                          <Col lg="2">
                            <span>{importFileData?.productNotFoundCount}</span>
                          </Col>
                          <Col
                            lg="4"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "end",
                              gap: "50px",
                            }}>
                            {importFileData?.productNotFoundCount > "0" ? (
                              <>
                                <Link
                                  style={{ fontSize: "15px" }}
                                  to={{
                                    pathname: `/admin/inventory/view/${dataItem.id}`,
                                    state: {
                                      item: allData,
                                      permission: "edit",
                                      selectedKey: "Not Found",
                                    },
                                  }}>
                                  View
                                </Link>
                              </>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default InventoryFileView;
