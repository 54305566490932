import React, { useMemo, useEffect, useState, useRef } from "react";
import Header from "../../ui/Header.js";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file.js";
import { useHistory } from "react-router-dom";
import {
  Col,
  Card,
  Label,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  CardFooter,
  Table,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,
  Button,
  Row,
  ButtonGroup,
  Media,
  UncontrolledTooltip,
  Input,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import IMGetLocation from "../ui/IMGetLocation.js";
// import "./style.css";
import FullPageLoader from "../../../components/FullPageLoader/index.js";

import { AlexaForBusiness } from "aws-sdk";
import { ApiEndpoints } from "../../constants/constant.js";
import moment from "moment";
import { useMediaQuery } from "@material-ui/core";
import getToken from "../../../onboarding/getToken.js";
import { ExportToPriceExcel } from "../../../components/ExportExcel/priceIndex.js";
import SearchBar from "../../components/SearchBar.js";
import { toast } from "react-toastify";
import styles from "../../styles/productTransaction.module.css";
import MobileSearchBar from "../../components/SearchBarMobile.js";
import ModalTitle from "rsuite/esm/Modal/ModalTitle.js";
import Select from "react-select";
import { Formik, Form, FieldArray, Field } from "formik";
import useOutsideClick from "../hooks/useOutsideClick.js";
const awsSDKconfig = require("../../config/awsSDKConfig.js");
const AWS = require("aws-sdk");
AWS.config.update(awsSDKconfig);
const cognito = new AWS.CognitoIdentityServiceProvider();
const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const inventoryControlsColumns = [
  {
    Header: "Title 1",
  },
  {
    Header: "Title 2",
  },
  {
    Header: "Title 3",
  },
  {
    Header: "Title 4",
  },
  {
    Header: "Title 5",
  },
  {
    Header: "Title 6",
  },
  {
    Header: "Title 7",
  },
];

const dummyData = [
  {
    ID: "1",
    "Uploaded At": "2024-01-18T12:30:00",
    "File Name": "file1.txt",
    "POS Name": "Store A",
    "Uploaded By": "John Doe",
    "No Of Items": 50,
    "Processing Status": "In Progress",
    "Approval Status": "Pending",
    "Download File": "file1_download_link",
    "Error File": "file1_error_link",
    Actions: "View/Edit",
  },
  {
    ID: "2",
    "Uploaded At": "2024-01-17T14:45:00",
    "File Name": "file2.csv",
    "POS Name": "Store B",
    "Uploaded By": "Jane Smith",
    "No Of Items": 75,
    "Processing Status": "Completed",
    "Approval Status": "Approved",
    "Download File": "file2_download_link",
    "Error File": "file2_error_link",
    Actions: "View/Edit",
  },
  {
    ID: "3",
    "Uploaded At": "2024-01-16T09:15:00",
    "File Name": "file3.xlsx",
    "POS Name": "Store C",
    "Uploaded By": "Alice Johnson",
    "No Of Items": 60,
    "Processing Status": "Failed",
    "Approval Status": "Rejected",
    "Download File": "file3_download_link",
    "Error File": "file3_error_link",
    Actions: "View/Edit",
  },
  {
    ID: "4",
    "Uploaded At": "2024-01-15T16:20:00",
    "File Name": "file4.json",
    "POS Name": "Store D",
    "Uploaded By": "Bob Williams",
    "No Of Items": 90,
    "Processing Status": "Completed",
    "Approval Status": "Approved",
    "Download File": "file4_download_link",
    "Error File": "file4_error_link",
    Actions: "View/Edit",
  },
  {
    ID: "5",
    "Uploaded At": "2024-01-14T11:30:00",
    "File Name": "file5.csv",
    "POS Name": "Store E",
    "Uploaded By": "Eva Brown",
    "No Of Items": 70,
    "Processing Status": "In Progress",
    "Approval Status": "Pending",
    "Download File": "file5_download_link",
    "Error File": "file5_error_link",
    Actions: "View/Edit",
  },
  {
    ID: "6",
    "Uploaded At": "2024-01-13T13:45:00",
    "File Name": "file6.txt",
    "POS Name": "Store F",
    "Uploaded By": "Frank Davis",
    "No Of Items": 55,
    "Processing Status": "Completed",
    "Approval Status": "Approved",
    "Download File": "file6_download_link",
    "Error File": "file6_error_link",
    Actions: "View/Edit",
  },
  {
    ID: "7",
    "Uploaded At": "2024-01-12T08:00:00",
    "File Name": "file7.xlsx",
    "POS Name": "Store G",
    "Uploaded By": "Grace Miller",
    "No Of Items": 80,
    "Processing Status": "In Progress",
    "Approval Status": "Pending",
    "Download File": "file7_download_link",
    "Error File": "file7_error_link",
    Actions: "View/Edit",
  },
];

let array1 = [
  {
    title: "Product Name",
    id: "product_name",
  },
  {
    title: "UPC",
    id: "upc",
  },

  {
    title: "Price",
    id: "price",
  },
];

let array2 = [
  {
    title: "Header1 Name",
    id: "product_name",
    array1: array1,
  },
  {
    title: "Header2",
    id: "upc",
  },
  {
    title: "Header3",
    id: "quantity",
  },
  {
    title: "Header4",
    id: "price",
  },
];

// array2.map((item)=>[
//   return(
// <p>item.title</p>
// item.array1.map((item2)=>{
//   retrun(
//     <p>item2.title</p>
//   )
// })
//   )
// ])

const arrData = [
  {
    main_title: "Product Name",
    id: "product_name",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "UPC",
    id: "upc",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "Category",
    id: "category",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
  {
    main_title: "Amount",
    id: "amount",
    options: [
      {
        title: "Product Name",
        id: "product_name",
      },
      {
        title: "UPC",
        id: "upc",
      },
      {
        title: "Quantity",
        id: "quantity",
      },
      {
        title: "Price",
        id: "price",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function POSInventoryFile() {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("All");
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const modalToggle = () => setModal(!modal);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [inventoryControls, setInventoryControls] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const userLocation = localStorage.getItem("location");
  const [isImport, setIsImport] = useState(false);
  const [importStatus, setImportStatus] = useState("");
  const [isExcelData, setIsExcelData] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [statusFilter, setStatusFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedSource, setSelectedSource] = useState([]);
  const [sourceDropDown, setSourceDropDown] = useState(false);
  const toggleSourceDropDown = () =>
    setSourceDropDown((prevState) => !prevState);
  const [clear, setClear] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCheckedList, setIsCheckedList] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [posName, setPosName] = useState("");
  const [posNameError, setPosNameError] = useState("");
  const [headerData, setHeaderData] = useState([]);
  const [productName, setProductName] = useState("");
  const [skuNumber, setSkuNumber] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedHeaderOptions, setSelectedHeaderOptions] = useState([]);
  const [allHeaderOptions, setHeaderAllOptions] = useState([]);
  const [mappingSugg, setMappingSugg] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [filteredUser, setFilteredUser] = useState("");
  const [importReqId, setImportedReqId] = useState("");
  const [initialData, setInitialData] = useState({
    pos_name: "",
  });
  const history = useHistory();
  const [productData, setProductData] = useState([]);

  const inputRef = useRef();

  useOutsideClick(inputRef, () => setShowSuggestion(false));

  const handleClose = () => setShowModal(false);
  const handleSave = () => {
    // Add logic for saving data
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
    getTransactionList(1);
    setPage(1);
  };

  const isMobile = useMediaQuery("(max-width: 500px)");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  //console.log("LOcation", userLocation);

  console.log("ISLOADING", isLoading);

  const SortOptions = [{ id: "created_at", text: "Date" }];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const sourceOptions = [
    { id: "Mapping pending", text: "Mapping pending" },
    { id: "Mapping Approved", text: "Mapping Approved" },
    { id: "Processing", text: "Processing" },
    { id: "Upload in progress", text: "Upload in progress" },
    { id: "Failed", text: "Failed" },
    { id: "Completed", text: "Completed" },
    { id: "Mapping found", text: "Mapping found" },
  ];

  const handleSourceOptions = (sourceId) => {
    setPage(1);
    if (selectedSource.includes(sourceId)) {
      setSelectedSource((prevSelected) =>
        prevSelected.filter((id) => id !== sourceId)
      );
    } else {
      setSelectedSource((prevSelected) => [...prevSelected, sourceId]);
    }
  };

  const getTransactionList = (pageNum) => {
    if (locationId) {
      let posInventoryData = `${ApiEndpoints.posInventoryList}?location_id=${locationId}&page=${pageNum}&limit=10`;

      if (selectedSortOption) {
        posInventoryData += `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
      }

      const requestData = {};

      if (selectedStatus !== "All") {
        posInventoryData += `&approval_status=${selectedStatus}`;
      }

      if (selectedSource.length > 0) {
        posInventoryData += `&processing_status=${selectedSource}`;
      }

      // if (searchKeyword.length > 0) {
      //   posInventoryData += `&searchKeyword=${searchKeyword}`;
      // }

      // if (selectedStatus !== "All") {
      //   requestData.approved = selectedStatus;
      // }

      // if (selectedSource.length > 0) {
      //   requestData.status = selectedSource;
      // }

      if (searchKeyword.length > 0) {
        requestData.searchKeyword = searchKeyword;
      }

      setIsLoading(true);
      console.log("requestData", requestData);

      fetch(posInventoryData, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => res.json())
        .then((response) => {
          const menuTransaction = response.data;
          console.log("RESPONSE NEW", response);

          if (menuTransaction.length > 0) {
            const updatedOrders =
              pageNum === 1
                ? menuTransaction
                : [...inventoryControls, ...menuTransaction];
            const mergedOrders = mergeAndSortOrders(updatedOrders);
            setInventoryControls(mergedOrders);

            if (menuTransaction.length < 10) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          } else {
            console.log("page456", page);
            if (pageNum === 1) {
              setInventoryControls(menuTransaction);

              if (menuTransaction.length < 10) {
                setLoadMoreEnabled(false);
              } else {
                setLoadMoreEnabled(true);
              }
            }
          }
          setIsLoading(false);
          setClear(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error", err);
        });
    }
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getTransactionList(pageNum);
  };

  useEffect(() => {
    setPage(1);
    if (selectedSortOption) {
      getTransactionList(page);
    }
  }, [
    selectedSortOption,
    sortDirection,
    selectedStatus,
    selectedSource,
    locationId,
    isImport === true,
  ]);

  console.log("inventoryconrrolss", inventoryControls);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getTransactionList(page);
    } else if (searchKeyword === "") {
      getTransactionList(page);
    }
  }, [searchKeyword.length]);

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const mergeAndSortOrders = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      // getTransactionList(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });

      //   const status = value.toLowerCase();
      setSelectedStatus(value);
    }
  };

  let btns = ["All", "Pending For Approval", "Approved", "Rejected"];

  const handleClear = async () => {
    setPage(1);
    // setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    // setSelectedDeliveryMethod([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getTransactionList(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    // setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    // setSelectedDeliveryMethod([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  const handleCheckboxChange = () => {
    setIsCheckedList(!isCheckedList);
  };

  const exportData = async () => {
    if (locationId) {
      setIsLoading(true);
      fetch(`${ApiEndpoints.exportPrice}?locationId=${locationId}`)
        .then((response) => response.json())
        .then((json) => {
          console.log("json", json);
          setTimeout(() => {
            const data = [
              {
                category: "product",
                data: json.data,
              },
            ];
            setProductData(data);
            //setIsExcelData(true)
          }, 1000);
        });

      setIsLoading(false);
    }
  };

  const callReferesh = () => {
    fetch(`${ApiEndpoints.productListLocationImport}?location_id=${locationId}`)
      .then((res) => res.json())
      .then((response) => {
        console.log("RESPONSE NEW", response);
        setInventoryControls(response.data);
        setOriginalData(response.data);
        setApprovalStatus("All");
        setIsLoading(false);
      });
  };
  // console.log("Contr", inventoryControls);
  // console.log("PRODUCT DATa", productData);
  const columns = useMemo(() => inventoryControlsColumns, []);
  const handleView = () => {
    const viewPath = "/admin/import-export-show/1";
    history.push(viewPath);
  };

  const handleDropdown = (e) => {
    console.log("E", e);
    let value = e.target.value;
    let location_id = value.split("#")[1];
    setLocationId(location_id);
    // callReferesh();
    setApprovalStatus("All");
  };

  useEffect(() => {
    let location = localStorage.getItem("selected_location_id");
    console.log("location 1234", location);
    if (location) {
      let loc = location.split("#")[1];
      setLocationId(loc);
    }
  }, []);

  const fileInputRef = useRef();

  const [file, setFile] = useState("");

  const updateApprovalStatus = (text) => {
    console.log("147---->", text);
    setApprovalStatus(text);
    let data = JSON.parse(JSON.stringify(originalData));
    if (text === "All") {
      setInventoryControls(data);
    } else {
      let inventoryControl = data.filter((item) => item.approved === text);
      setInventoryControls(inventoryControl);
    }
  };

  const [allItems, setAllItems] = useState([]);

  const handleFileUpload = async (e) => {
    const filePosName = posName.trim();
    if (!filePosName) {
      setPosNameError("POS Name is required");
      return;
    } else {
      setPosNameError("");
    }
    setIsLoading(true);

    console.log("filePosName", filePosName);

    try {
      const fileType = file.type;
      const acceptedTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ];

      if (acceptedTypes.includes(fileType)) {
        let preSignedUrl = `${ApiEndpoints.posPresignedUrl}`;

        let preSignedRes = await axios.post(preSignedUrl, {
          contentType: fileType,
        });
        if (preSignedRes.status === 200) {
          let ukey = preSignedRes?.data?.data?.key;
          let url = preSignedRes?.data?.data?.url;

          console.log("URL", url, ukey);

          let res = await uploadFile(url, file);
          console.log("RES1", res);

          let data = {
            uploaded_s3_key: ukey,
            file_name: file.name,
            pos_name: posName,
            location_id: localStorage
              .getItem("selected_location_id")
              .split("#")[1],
            updated_by: localStorage.getItem("fullname"),
            uploaded_by: localStorage.getItem("fullname"),
          };

          if (res.status === 200) {
            console.log("datat", data);
            let importRes = await axios.post(
              `${ApiEndpoints.posImportFile}`,
              data
            );

            //console.log("FINAL", importRes);
            if (importRes.status === 200) {
              setIsLoading(false);
              setIsImport(true);
              setImportStatus("Successfull");
              setFile("");
              setModal(false);
              toast.success("Request created successfully");
              // toast.success("Your Request is Successfully Submitted");
              let action = "import-pos-file";
              let type = "Info";
              let message = "POS Transaction imported successfully.";
              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);
            } else {
              setIsLoading(false);
              setIsImport(false);
              setFile("");
              setModal(false);
              setImportStatus("Failed");
              toast.error("Your Request is Failed");
            }
          }
        }
      } else {
        setIsLoading(false);
        toast.error(
          "Unsupported file type. Please upload an Excel or CSV file."
        );
        console.error("Unsupported file type");
      }
    } catch (error) {
      console.log("Err", error, error?.response?.data?.message);

      if (
        error?.response &&
        error?.response?.status === 400 &&
        error?.response?.data?.message === "Bad Request"
      ) {
        let response = error?.response?.data?.data?.headers;

        let importedId = error?.response?.data?.data?.imported_request_id;
        setImportedReqId(importedId);

        // let headers = {
        //   id: index.toString(),
        //   main_title: item,
        //   headers,
        // };
        console.log("responseHeadersRaw", response);
        let tempResponseHeaders = [...response];
        let allHeaders = [];
        for (let i = 0; i < tempResponseHeaders.length; i++) {
          allHeaders.push({
            value: tempResponseHeaders[i],
            label: tempResponseHeaders[i],
          });
        }
        console.log("all headers", allHeaders);
        setHeaderAllOptions(allHeaders);
        let transformedHeaderData = response.map((item, index) => ({
          id: index.toString(),
          main_title: item,
          options: [
            {
              title: item,
              id: index.toString(),
            },
          ],
          options2: allOptions,
        }));
        console.log("responseHeaders", transformedHeaderData);
        setIsLoading(false);
        setModal(false);
        setShowModal(true);
        setHeaderData(response);
      } else {
        // Handle other errors
        setIsLoading(false);
        setModal(false);
        console.log("messagemessage", error.message);
        toast.error(
          error.response?.data?.message ||
            "An error occurred during file upload."
        );
        let action = "import-pos-file";
        let type = "Error";
        let message = "POS Transaction cannot imported.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    }
  };

  const handleMappingSubmit = async () => {
    let mapping_key = [];
    let optionsMissing = false;

    allSelectedItems.map((item) => {
      if (!item?.selected_file_column || !item?.selected_master_data) {
        optionsMissing = true;
        console.error("Please select all four options inside");
      } else {
        mapping_key.push({
          file_key: item.selected_file_column,
          mapping_key: item.selected_master_data,
        });
      }
    });

    console.log("mapping_key", mapping_key);

    if (optionsMissing || mapping_key.length !== 4) {
      toast.error("Please select all options.");
      return;
    }

    let reqBody = {
      pos_name: posName,
      mapping_key: mapping_key,
      imported_request_id: importReqId,
      updated_by: localStorage.getItem("fullname"),
      uploaded_by: localStorage.getItem("fullname"),
    };

    console.log("reqBody", reqBody);
    setIsLoading(true);

    try {
      let response = await axios.post(`${ApiEndpoints.posMapping}`, reqBody);

      if (response.status === 200) {
        setIsLoading(false);
        setShowModal(false);
        toast.success("Mapping created successfully");
        getTransactionList(1);
        setPage(1);
        let action = "pos-menu";
        let type = "Info";
        let message = "POS Menu created successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.error("12345", error);
      setIsLoading(false);
      let action = "pos-menu";
      let type = "Error";
      let message = "Error in pos menu.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  let uploadFileToServer = (uploadPreSignedUrl, contentType, requestBody) => {
    setIsLoading(true);

    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          setIsLoading(false);

          resolve(
            JSON.parse(
              JSON.stringify({ status: 200, data: "xhr.responseText" })
            )
          );
        } else {
          setIsLoading(false);

          reject(
            JSON.parse(
              JSON.stringify({ status: 400, data: "xhr.responseText" })
            )
          );
        }
      };

      xhr.open("PUT", uploadPreSignedUrl);
      // xhr.setRequestHeader("Content-Type", contentType);
      // xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.send(requestBody);
    });
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  const callXhr = async (url, data) => {
    // create an XHR object
    const xhr = new XMLHttpRequest();

    // listen for `onload` event
    xhr.onload = () => {
      // process response
      if (xhr.status == 200) {
        // parse JSON data
        console.log(JSON.parse(xhr.response));
      } else {
        console.error("Error!");
      }
    };

    // create a `GET` request
    xhr.open("PUT", url);

    // send request
    xhr.send(data);
  };

  const getLocationList = async () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiEndpoints.locationList}`, config)
      .then((response) => response.json())
      .then(async (data) => {
        const locations = data?.data?.Items;
        console.log("LOc", locations);

        let location = localStorage.getItem("selected_location_id");

        if (location && location !== "undefined") {
          console.log("SDFSDFSDFSDFSDFSDF");
          setLocationId(location);
        } else {
          localStorage.setItem("inventory_location", locations[0].pk);
          // setPageNum(0);
          // updateOrderStatus("All");
          setLocationId(locations[0].pk);
        }

        let role = localStorage.getItem("userRole");

        if (role == "Admin") {
          setLocationData(locations);
        }
        // else {
        //   let data0 = localStorage.getItem("currentLocation");
        //   let locationR = [];
        //   let data = data0.split(",");
        //   data.forEach((el) => {
        //     let data3 = locations.find((item) => item.pk == el);
        //     locationR.push(data3);
        //   });
        //   console.log("locationsss", locationR);
        //   setLocationData(locationR);
        //   // var location = userLocation.split("#")[1];
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  console.log("SDFASD", locationId);

  // useEffect(() => {
  //   getLocationList();
  // }, []);

  const handleChange = async (e) => {
    console.log("EEEE", e.target.value);

    let tempLocation = e.target.value;
    setLocationId(tempLocation);
    localStorage.setItem("inventory_location", tempLocation);
  };

  const toggleLoader = (value) => {
    console.log("SDsgdsag", value);
    setIsLoading(value);
    console.log("LO", isLoading);
  };

  useEffect(() => {
    if (arrData.length) {
      let optns = arrData[0].options;
      let newOptions = optns.map((elm) => ({
        ...elm,
        value: elm.id,
        label: elm.title,
      }));
      setAllOptions(newOptions);
      //setInitialOptions(newOptions);
    }
  }, []);

  // useEffect(() => {
  //   if (headerData.length) {
  //     let optns = headerData.options;
  //     let newOptions = optns.map((elm) => ({
  //       ...elm,
  //       value: elm.id,
  //       label: elm.title,
  //     }));
  //     setHeaderAllOptions(newOptions);
  //     //setInitialOptions(newOptions);
  //   }
  // }, []);

  const handleDropdownChange = (selectedOption, index) => {
    if (!selectedOption) {
      console.log("change cross", selectedOptions[index]);
      let updatedOptions = [...allOptions, selectedOptions[index]];
      setAllOptions(updatedOptions);
      console.log("change selected", selectedOptions);
      delete selectedOptions[index];
      let tempSelectedArr = [...allSelectedItems];
      delete tempSelectedArr[index].selected_master_data;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    } else {
      if (selectedOptions.hasOwnProperty(index)) {
        let lastSelected = selectedOptions[index];
        console.log("change last", lastSelected);
        let updatedOptions = allOptions.filter(
          (option) => option.value !== selectedOption.value
        );
        let newUpdateOption = [...updatedOptions, lastSelected];
        setAllOptions(newUpdateOption);
        setSelectedOptions((p) => ({
          ...p,
          [index]: selectedOption,
        }));
      } else {
        const updatedOptions = allOptions.filter(
          (option) => option.value !== selectedOption.value
        );
        setAllOptions(updatedOptions);
        setSelectedOptions((p) => ({
          ...p,
          [index]: selectedOption,
        }));
      }
      let tempSelectedArr = [...allSelectedItems];
      tempSelectedArr[index].selected_master_data = selectedOption.value;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    }
  };

  console.log("arrdataa", arrData);

  const handleDropdownChangeHeader = (selectedHeaderOption, index) => {
    console.log("selected header option", selectedHeaderOption);
    if (!selectedHeaderOption) {
      console.log("change cross", selectedHeaderOptions[index]);
      let updatedOptions = [...allHeaderOptions, selectedHeaderOptions[index]];
      setHeaderAllOptions(updatedOptions);
      console.log("change selected", selectedHeaderOptions);
      delete selectedHeaderOptions[index];
      let tempSelectedArr = [...allSelectedItems];
      delete tempSelectedArr[index].selected_file_column;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    } else {
      if (selectedHeaderOptions.hasOwnProperty(index)) {
        let lastSelected = selectedHeaderOptions[index];
        console.log("change last", lastSelected);
        let updatedOptions = allHeaderOptions.filter(
          (option) => option.value !== selectedHeaderOption.value
        );
        let newUpdateOption = [...updatedOptions, lastSelected];
        setHeaderAllOptions(newUpdateOption);
        setSelectedHeaderOptions((p) => ({
          ...p,
          [index]: selectedHeaderOption,
        }));
      } else {
        const updatedOptions = allHeaderOptions.filter(
          (option) => option.value !== selectedHeaderOption.value
        );
        setHeaderAllOptions(updatedOptions);
        setSelectedHeaderOptions((p) => ({
          ...p,
          [index]: selectedHeaderOption,
        }));
      }
      let tempSelectedArr = [...allSelectedItems];
      tempSelectedArr[index].selected_file_column = selectedHeaderOption.value;
      console.log("tempSelectedArr", tempSelectedArr);
      setAllSelectedItems(tempSelectedArr);
    }
  };

  console.log("change", selectedOptions, allOptions);

  const handlePosName = (e) => {
    setPosName(e.target.value);
    setPosNameError("");
  };

  // const handleInputChange = (event, setFieldValue) => {
  //   const userInput = event.target.value;
  //   setPosName(userInput);
  //   setUserInput(userInput);
  //   setShowSuggestion(userInput.length > 0);
  // };

  const handleInputChange = async (event, setFieldValue) => {
    const userInput = event.target.value;
    setPosName(userInput);
    setUserInput(userInput);
    setShowSuggestion(userInput.length > 0);
    setPosNameError("");

    try {
      const res = await axios.get(
        `${ApiEndpoints.posMappingSugg}?page=1&limit=1000`
      );
      console.log("possugg", res);
      if (res.status === 200) {
        const responseData = res.data.data;
        const filteredSuggestions = responseData.filter((user) =>
          user.pos_name.toLowerCase().includes(userInput.toLowerCase())
        );
        setMappingSugg(filteredSuggestions);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSelectUser = (selectedUser) => {
    console.log("selectedUser", selectedUser);
    setPosName(selectedUser.pos_name);
    setShowSuggestion(false);
  };

  const handleInputFocus = () => {
    setShowSuggestion(true);
  };

  useEffect(() => {
    if (allSelectedItems.length == 0) {
      let tempArr = [];
      if (allHeaderOptions.length && allOptions.length) {
        for (let i = 0; i < allOptions.length; i++) {
          tempArr.push({
            option1: allHeaderOptions,
            option2: allOptions,
          });
        }
      }
      console.log("temp arr", tempArr);
      if (tempArr.length) {
        setAllSelectedItems(tempArr);
      }
    }
  }, [allHeaderOptions.length, allOptions.length]);

  console.log("posName", posName);

  console.log("selected arr", allSelectedItems);

  const handleDownload = (url) => {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute to force download
    link.setAttribute("download", "MyExampleDoc");

    // Append the anchor to the body
    document.body.appendChild(link);

    // Programmatically click the link to initiate download
    link.click();

    // Clean up: remove the anchor from the DOM
    document.body.removeChild(link);
  };

  return (
    <>
      <Container fluid>
        <Formik
          initialValues={initialData}
          // validationSchema={validationSchema}
          enableReinitialize={true}
          // onSubmit={(values, { setSubmitting }) => {
          //   const trimmedValues = Object.keys(values).reduce((acc, key) => {
          //     acc[key] =
          //       typeof values[key] === "string"
          //         ? values[key].trim()
          //         : values[key];
          //     return acc;
          //   }, {});

          //   addBlackListUser(trimmedValues, setSubmitting);
          // }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col lg="6" xs="7">
                          <h6
                            className="fullcalendar-title h2 d-inline-block mb-0"
                            style={
                              isMobile
                                ? { fontSize: "18px", whiteSpace: "nowrap" }
                                : null
                            }
                          >
                            {"POS Inventory"}
                          </h6>
                        </Col>
                        <Col
                          className="mt-3 mt-md-0 text-md-right"
                          lg="6"
                          xs="5"
                        >
                          <>
                            {/* <Button
                                className="buttons-copy buttons-html5"
                                size="sm"
                                color="default"
                                id="copy-tooltip"
                                onClick={exportData}
                              >
                                <span>
                                  <i class="fas fa-download"></i> Export
                                </span>
                              </Button> */}
                            {/* <Button
                                className="buttons-copy buttons-html5"
                                size="sm"
                                color="default"
                                id="copy-tooltip"
                                onClick={() => setShowModal(true)}
                              >
                                Mapping
                              </Button> */}
                            {(userRoles.includes("1") ||
                              roleActions.includes("pos_import")) && (
                              <Button
                                // className="buttons-copy buttons-html5"
                                className={styles.buttonStyle}
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() => setModal(true)}
                              >
                                <span>
                                  <i class="fas fa-upload"></i> Import
                                </span>
                              </Button>
                            )}
                            <Modal
                              isOpen={showModal}
                              // onHide={handleClose}
                              size="lg"
                              centered
                            >
                              <ModalBody>
                                <Row>
                                  <Col lg="6">
                                    <h2>Mapping POS Inventory</h2>
                                  </Col>

                                  <Col lg="6">
                                    <FormGroup controlId="mappingName">
                                      <Label>Mapping Name</Label>
                                      <Input
                                        type="text"
                                        value={posName}
                                        readOnly
                                        // onChange={(e) => setMappingName(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <h2>File Columns</h2>

                                    {allSelectedItems?.map((item, index) => (
                                      <FormGroup key={item.id}>
                                        <Select
                                          // isDisabled
                                          // defaultValue={{
                                          //   value: item.id,
                                          //   label: item.main_title,
                                          // }}
                                          options={allHeaderOptions}
                                          onChange={(selectedHeaderOption) =>
                                            handleDropdownChangeHeader(
                                              selectedHeaderOption,
                                              index
                                            )
                                          }
                                          isClearable
                                          onInputChange={() => {}}
                                          value={selectedHeaderOptions[item.id]}
                                        />
                                      </FormGroup>
                                    ))}
                                  </Col>
                                  <Col md="6">
                                    <h2>Master Data</h2>
                                    {allSelectedItems?.map((item, index) => (
                                      <FormGroup key={item.id}>
                                        {/* <Label>{item.main_title}</Label> */}
                                        <Select
                                          options={allOptions}
                                          onChange={(selectedOption) =>
                                            handleDropdownChange(
                                              selectedOption,
                                              index
                                            )
                                          }
                                          isClearable
                                          onInputChange={() => {}}
                                          value={selectedOptions[item.id]}
                                        />
                                      </FormGroup>
                                    ))}
                                  </Col>
                                </Row>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  variant="secondary"
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </Button>
                                {/* <Button color="primary" onClick={handleSave}>
                                    Save
                                  </Button> */}
                                <Button
                                  color="primary"
                                  onClick={() => handleMappingSubmit()}
                                >
                                  Submit
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </>
                        </Col>
                      </Row>
                      <Row></Row>
                    </CardHeader>
                    <CardBody className={styles.transactionList}>
                      {isMobile ? (
                        <MobileSearchBar
                          showCard={showCard}
                          selectStatus={selectStatus}
                          sortDirection={sortDirection}
                          statusFilter={statusFilter}
                          searchKeyword={searchKeyword}
                          dropdownOpen={dropdownOpen}
                          toggleDropdown={toggleDropdown}
                          selectedStatus={selectedStatus}
                          SortOptions={SortOptions}
                          handleSearchButtonClick={handleSearchButtonClick}
                          setSearchKeyword={setSearchKeyword}
                          getDetail={getTransactionList}
                          handleClear={handleClear}
                          handleCancelClick={handleCancelClick}
                          handleSortOptionSelect={handleSortOptionSelect}
                          selectedSortOption={selectedSortOption}
                          handleSourceOptions={handleSourceOptions}
                          selectedSource={selectedSource}
                          sourceDropDown={sourceDropDown}
                          toggleSourceDropDown={toggleSourceDropDown}
                          sourceOptions={sourceOptions}
                          btns={btns}
                          placeHolderText="Search"
                          transactionListDropDown={true}
                          dropDown={false}
                          orderList={false}
                        />
                      ) : (
                        <SearchBar
                          showCard={showCard}
                          selectStatus={selectStatus}
                          sortDirection={sortDirection}
                          statusFilter={statusFilter}
                          searchKeyword={searchKeyword}
                          dropdownOpen={dropdownOpen}
                          toggleDropdown={toggleDropdown}
                          selectedStatus={selectedStatus}
                          SortOptions={SortOptions}
                          handleSearchButtonClick={handleSearchButtonClick}
                          setSearchKeyword={setSearchKeyword}
                          getDetail={getTransactionList}
                          handleClear={handleClear}
                          handleCancelClick={handleCancelClick}
                          handleSortOptionSelect={handleSortOptionSelect}
                          selectedSortOption={selectedSortOption}
                          handleSourceOptions={handleSourceOptions}
                          selectedSource={selectedSource}
                          sourceDropDown={sourceDropDown}
                          toggleSourceDropDown={toggleSourceDropDown}
                          sourceOptions={sourceOptions}
                          btns={btns}
                          placeHolderText="Search"
                          transactionListDropDown={true}
                          dropDown={false}
                          orderList={false}
                        />
                      )}
                    </CardBody>
                    <div style={{ height: 560, overflowY: "scroll" }}>
                      <Table className="align-items-center table-flush">
                        <thead
                          className="thead-light"
                          style={{ position: "sticky", top: 0, zIndex: 100 }}
                        >
                          <tr>
                            <th>Uploaded At</th>
                            <th>File Name</th>
                            <th>POS Name</th>
                            <th>Uploaded By</th>
                            {/* <th>No Of Items</th> */}
                            <th>Processing Status</th>
                            <th>Approval Status</th>
                            <th>Download File</th>
                            {/* <th>Error File</th> */}
                            <th>Actions</th>

                            {/* <th>Reason</th> */}

                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {inventoryControls?.length ? (
                            inventoryControls?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(item?.updated_at).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                <td>{item?.file_name}</td>
                                <td>{item?.pos_name}</td>
                                <td>{item?.uploaded_by}</td>
                                {/* <td>{item?.no_of_items}</td> */}
                                <td>{item?.processing_status}</td>
                                <td>{item?.approval_status}</td>
                                <td>
                                  {item?.uploaded_s3_url !== "" && (
                                    <Button
                                      className="buttons-copy buttons-html5 btn-link"
                                      size="sm"
                                      onClick={() =>
                                        handleDownload(item.uploaded_s3_url)
                                      }
                                    >
                                      <span>
                                        <i className="fas fa-download"></i>
                                      </span>
                                    </Button>
                                  )}
                                </td>
                                {/* <td>
                                  {item.error_s3_url !== "" && (
                                    <a
                                      href={item.error_s3_url}
                                      download="MyExampleDoc"
                                      target="_blank"
                                    >
                                      <Button
                                        className="buttons-copy buttons-html5 btn-link"
                                        // color="default"
                                        size="sm"
                                      >
                                        <span>
                                          <i class="fas fa-download"></i>
                                        </span>
                                      </Button>
                                    </a>
                                  )}
                                </td> */}
                                <td>
                                  <ButtonGroup>
                                    {(userRoles.includes("1") ||
                                      roleActions.includes("pos_view")) && (
                                      <Button
                                        className="btn-icon-only"
                                        color="info"
                                        type="button"
                                        onClick={() =>
                                          history.push({
                                            pathname: `/admin/pos-inventory/view/${item.id}`,
                                            state: {
                                              item: item,
                                              // permission: "view"
                                            },
                                          })
                                        }
                                        id={"tooltipv" + "view"}
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="fa fa-eye"></i>
                                        </span>
                                      </Button>
                                    )}

                                    {(userRoles.includes("1") ||
                                      roleActions.includes("pos_edit")) && (
                                      <Button
                                        className="btn-icon-only"
                                        color="success"
                                        type="button"
                                        onClick={() =>
                                          history.push({
                                            pathname: `/admin/pos-inventory/edit/${item.id}`,
                                            state: {
                                              item: item,
                                              // permission: "edit",
                                            },
                                          })
                                        }
                                        id={"tooltipv" + "edit"}
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="fa fa-edit"></i>
                                        </span>
                                      </Button>
                                    )}
                                  </ButtonGroup>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              {isLoading ? (
                                <td colSpan="6">Loading...</td>
                              ) : (
                                <td colSpan="6">No data found...</td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <CardFooter>
                      {loadMoreEnabled && (
                        <a
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          className="Link"
                          onClick={handleLoadMore}
                        >
                          Load More...
                        </a>
                      )}
                    </CardFooter>
                  </Card>
                </div>
              </Row>
              <Modal
                isOpen={modal}
                fade={false}
                toggle={modalToggle}
                size="sm"
                onOpened={() => {
                  setFile(null);
                  setPosName("");
                  setPosNameError("");
                }}
                centered
                backdrop={false}
              >
                <ModalHeader toggle={modalToggle}>Import</ModalHeader>
                <ModalBody>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h5>POS Name</h5>
                    </div>
                    <div ref={inputRef} style={{ position: "relative" }}>
                      <Input
                        style={{
                          width: "100%",
                          height: "40px",
                          // marginBottom: "15px",
                        }}
                        id="exampleFormControlSelect3"
                        type="text"
                        placeholder="Enter POS Name"
                        name="pos_name"
                        value={posName}
                        // onChange={handlePosName}
                        onChange={(e) => handleInputChange(e, setFieldValue)}
                        onFocus={handleInputFocus}
                      />

                      {showSuggestion && mappingSugg?.length > 0 && (
                        <div
                          style={{
                            border: "1px solid #f1f1f1",
                            height: "auto",
                            maxHeight: "275px",
                            width: "100%",
                            overflowY: "scroll",
                            position: "absolute",
                            background: "white",
                            zIndex: 100,
                            cursor: "pointer",
                            // marginTop: "205px",
                            // marginLeft: "165px",
                          }}
                        >
                          <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                            {mappingSugg?.map((user) => (
                              <li
                                key={user.id}
                                onClick={() => handleSelectUser(user)}
                                style={{
                                  fontSize: "13px",
                                  borderTop: "1px solid rgb(222, 226, 230)",
                                  padding: "10px",
                                }}
                              >
                                {user.pos_name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  {posNameError && (
                    <p style={{ color: "red", fontSize: 13 }}>{posNameError}</p>
                  )}
                  <input
                    type="file"
                    hidden
                    name="importBtn"
                    id="importBtn"
                    // onChange={(e) => setFile(e.target.files[0])}
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      if (selectedFile) {
                        console.log("File type:", selectedFile.type);
                        setFile(selectedFile);
                      }
                    }}
                    ref={fileInputRef}
                    accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
                    onClick={(e) => (e.target.value = null)}
                  />
                  {file ? (
                    <>
                      <div>
                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                          File <i class="fal fa-file-spreadsheet"></i>
                        </h6>
                        <h4>{file.name}</h4>
                      </div>
                      <Button color="primary" onClick={handleFileUpload}>
                        Submit
                      </Button>
                      <Button color="secondary" onClick={() => setFile(null)}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <h6 className="fullcalendar-title h4 d-inline-block mb-0 mr-4">
                        Please upload the file
                      </h6>
                      <Button
                        color="primary"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Browse
                      </Button>
                    </>
                  )}

                  <div style={{ display: "grid", fontWeight: "bold" }}>
                    <span style={{ fontSize: "13px" }}>
                      * First row should contain headers
                    </span>
                    <span style={{ fontSize: "13px" }}>
                      * At least 4 headers should be present.
                    </span>
                  </div>
                </ModalBody>
              </Modal>
              {/* <Modal isOpen={modal} size="sm" centered>
                <ModalHeader>Import</ModalHeader>
                <ModalBody>
                  <div style={{ position: "relative" }}>
                    <h5>POS Name</h5>
                    <Field
                      style={{
                        width: "50%",
                        height: "40px",
                        marginBottom: "15px",
                      }}
                      type="text"
                      placeholder="Enter POS Name"
                      name="pos_name"
                      value={posName}
                      onChange={(e) => handleInputChange(e, setFieldValue)}
                    />
                    {showSuggestion && mappingSugg.length > 0 && (
                      <div
                        style={{
                          border: "1px solid #f1f1f1",
                          maxHeight: "125px",
                          width: "100%",
                          overflowY: "scroll",
                          position: "absolute",
                          top: "50px",
                          left: 0,
                          background: "white",
                          zIndex: 100,
                          cursor: "pointer",
                        }}
                      >
                        <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                          {mappingSugg.map((user) => (
                            <li
                              key={user.id}
                              onClick={() => handleSelectUser(user)}
                              style={{
                                fontSize: "13px",
                                borderTop: "1px solid rgb(222, 226, 230)",
                                padding: "10px",
                              }}
                            >
                              {user.pos_name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {posNameError && (
                    <p style={{ color: "red", fontSize: 13 }}>{posNameError}</p>
                  )}

                  <Button color="primary" onClick={handleFileUpload}>
                    Submit
                  </Button>
                  <Button color="secondary">Cancel</Button>
                </ModalBody>
              </Modal> */}
            </Form>
          )}
        </Formik>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default POSInventoryFile;
