import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { formatDateTimeInTimeZone } from "../../helpers";
import giftImage from "../../../assets/img/icons/cards/giftImage.png";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  CardText,
  CardTitle,
  Row,
  CardImg,
  Col,
  Button,
  CardFooter,
  CardSubtitle,
  Form,
  Input,
  FormGroup,
  Container,
  Table,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import axios from "axios";
// import {
//   BarChartLineFill,
//   Wifi,
//   CarFront,
//   Mailbox,
//   Mailbox2,
//   CurrencyBitcoin,
// } from "react-bootstrap-icons";
import "./styles.css";
import { ApiEndpoints, SourceName } from "../../constants/constant";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PublicOrderDetail = () => {
  const classes = useStyles();
  let history = useHistory();
  const { orderId, locationId } = useParams();
  console.log("HistoryHistoryi==>", history);

  const [customerSms, setCustomerSms] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [collapseKey, setCollapseKey] = useState("orderDetails");
  const [deliveryStatus, setDeliveryStatus] = useState([]);
  const [phoneModal, setPhoneModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [deliveryStatusActive, setDeliveryStatusActive] = useState();
  const [deliveryStatusActiveName, setDeliveryStatusActiveName] = useState();
  const [deliveryTrackingDes, setDeliveryTrackingDes] = useState([]);
  const [tabContentKey, setTabContentKey] = useState("generalDeatil");
  const [formKey, setFormKey] = useState("hide");
  const [refundKey, setRefundKey] = useState("hide");

  const [originalData, setOriginalData] = useState(null);
  const [driverDetails, setDriverDetails] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderName, setOrderName] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [unassignDriverModal, setUnassignDriverModal] = useState(false);
  const [messageDriverModal, setMessageDriverModal] = useState(false);
  const [reviewRatingModel, setReviewRatingModel] = useState(false);
  const [messageCustomerModal, setMessageCustomerModal] = useState(false);
  const [noteAttributeModal, setNoteAttributeModal] = useState(false);
  const [customerDetailModal, setCustomerDetailModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [tempOrderStatus, setTempOrderStatus] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [error, setError] = useState(null);
  const [formData, setFormData] = React.useState({});
  const [locationName, setLocationName] = useState("");
  const [tip, setTip] = useState("");
  const [dispatcherStatus, orderDispatcherStatus] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [refundReasons, setRefundReasons] = useState([]);
  const [notesAttributesRows, setNotesAttributesRows] = useState([]);
  const [ratings, setRatings] = useState(0);
  const [comments, setComments] = useState("");
  const [deliveryTracking, setDeliveryTracking] = useState();
  const [giftStatus, setGiftStatus] = useState(false);
  const [checkPickUp, setCheckPickUp] = useState("");
  const [checkOrderStatusValue, setCheckOrderStatusValue] = useState("");
  const [pickStoreUpLocation, setpickStoreUpLocation] = useState({});
  const [location, setLocation] = useState("");
  const [newModifyOrder, setNewModifyOrder] = useState(null);
  const [prevModifyOrder, setPrevModifyOrder] = useState(null);
  const [newModifyOrderName, setNewModifyOrderName] = useState(null);
  const [prevModifyOrderName, setPrevModifyOrderName] = useState(null);

  console.log(
    "checkOrderStatusValuecheckOrderStatusValue=>",
    checkOrderStatusValue
  );
  useEffect(() => {
    let location = "";
    fetch(ApiEndpoints.orderGetById + orderId)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then(async (data) => {
        console.log("!2-2-932-===>", data);
        let gift = data?.data?.orderDetail.note_attributes.find(
          (item) => item.name === "gift"
        );
        let getValue = data?.data?.orderDetail.note_attributes.find(
          (item) => item.name === "order_note"
        );
        let checkNewPickUp = data?.data?.orderDetail.note_attributes.find(
          (item) => item.name === "Checkout-Method"
        ).value;
        console.log("REtsydgbasbd==>", checkNewPickUp);
        let location = data?.data?.orderDetail.note_attributes.find(
          (item) => item.name === "location"
        ).value;
        setLocation(location.split("#")[1]);

        if (checkNewPickUp === "Local pickup") {
          setCheckPickUp(checkNewPickUp);
        } else {
          setCheckPickUp(checkNewPickUp);
        }

        if (typeof gift !== "undefined") {
          if (gift.value === "yes") {
            setGiftStatus(true);
          }
        }
        if (typeof getValue !== "undefined") {
          if (getValue.name === "order_note") {
            setCheckOrderStatusValue(getValue.value);
          }
        }

        const index = data?.data?.orderDetail.line_items.findIndex(
          (x) => x.name == "Tip/Gratuity"
        );
        console.log("asdmansbbdsanm==>", Math.sign(index));
        if (index >= 0) {
          let a = data?.data?.orderDetail.line_items.splice(index, 1);
          console.log("Asdsdsd", a);
          setTip((a?.[0].price * a?.[0].quantity).toFixed(2));
        } else {
          setTip(0);
        }
        setOrderName(data?.data?.orderDetail.name);
        setOrderNote(data?.data?.orderDetail?.note);
        setOriginalData(data?.data?.orderDetail);
        setMobileNumber(
          data?.data?.orderDetail &&
            data?.data?.orderDetail?.shipping_address &&
            data?.data?.orderDetail?.shipping_address?.phone
            ? data?.data?.orderDetail?.shipping_address?.phone
            : data?.data?.orderDetail?.customer?.phone
        );
        console.log("ddddd", data?.data?.orderDetail?.note_attributes);
        console.log("original-data", originalData);
        // let location = data?.data?.orderDetail?.note_attributes.find(
        //   (item) => item.name === "location"
        // );
        // if (location !== "undefined") {
        //   //localStorage.setItem("order_location", location.value);
        //   if (location.value !== localStorage.getItem("order_location")) {
        //     alert("Location does not match");
        //     history.push("/admin/orders");
        //   }
        // }
        let orderStatus = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "order_status"
        );
        if (orderStatus !== "undefined") {
          setOrderStatus(orderStatus.value);
        }

        let newModifyOrder = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "modified_to"
        );

        if (newModifyOrder) {
          setNewModifyOrder(newModifyOrder.value);
        } else {
          setNewModifyOrder(null);
        }

        console.log("newModifyOrdernewModifyOrder", newModifyOrder);
        let prevModifyOrder = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "modified_against"
        );

        if (prevModifyOrder) {
          setPrevModifyOrder(prevModifyOrder.value);
        } else {
          setPrevModifyOrder(null);
        }

        console.log("prevModifyOrderprevModifyOrder", prevModifyOrder);

        let newModifyOrderName = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "modified_to_order_name"
        );

        if (newModifyOrderName) {
          setNewModifyOrderName(newModifyOrderName.value);
        } else {
          setNewModifyOrderName(null);
        }

        console.log("newModifyOrderNamenewModifyOrderName", newModifyOrderName);

        let prevModifyOrderName = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "modified_order_name"
        );

        if (prevModifyOrderName) {
          setPrevModifyOrderName(prevModifyOrderName.value);
        } else {
          setPrevModifyOrderName(null);
        }

        console.log(
          "prevModifyOrderNameprevModifyOrderName",
          prevModifyOrderName
        );

        let deliveryTracking = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "deliveryTracking"
        );

        let locationDetail = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "location"
        );

        console.log("locationDetail", locationDetail);
        location = locationDetail.value.split("#")[1];
        console.log("22222222222222", location);
        getLocation(location);

        console.log("locationDetailisndsuh", locationDetail.value);
        console.log("delivery", deliveryTracking);
        if (typeof deliveryTracking !== "undefined") {
          setDeliveryTracking(deliveryTracking.value);
        }

        if (
          data?.data?.orderDetail?.shipping_address &&
          data?.data?.orderDetail?.shipping_address?.first_name
        ) {
          setFormData(data?.data?.orderDetail?.shipping_address);
        } else {
          setFormData(data?.data?.orderDetail?.customer);
        }
        // driver details
        if (data?.data?.orderDetail?.driver_id) {
          // locationList
          fetch(
            `${ApiEndpoints.getDriverById}/${data?.data?.orderDetail?.driver_id}?type=Driver`
          )
            .then((response) => response.json())
            .then((data) => {
              setDriverDetails(data?.data[0]);
              // setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (data?.data?.orderDetail.note_attributes.length > 0) {
          const rows = [
            ...Array(
              Math.ceil(data?.data?.orderDetail.note_attributes.length / 4)
            ),
          ];
          setNotesAttributesRows(
            rows.map((row, idx) =>
              data?.data?.orderDetail.note_attributes.slice(
                idx * 4,
                idx * 4 + 4
              )
            )
          );
        }
      });

    // locationList

    // order status
    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        setDeliveryStatus(data?.data?.config?.delivery_status);
        console.log(data?.data?.config?.delivery_status);
        orderDispatcherStatus(data?.data?.config?.Dispatcher_Status);
      });

    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        setRefundReasons(data?.data?.config?.Dispacther_Refund_Reason);
      });

    // ratingReview(
    //   orderId,
    //   props?.location?.state?.item?.locationId?.split("#")[1]
    // );
    setIsLoading(false);
  }, [orderId]);

  const getLocation = (location) => {
    fetch(
      `${ApiEndpoints.getLocationByLocationId}/${
        location
        // props?.location?.state?.item?.locationId
        //   ? props?.location?.state?.item?.locationId?.split("#")[1]
        //   : localStorage.getItem("order_location").split("#")[1]
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        const locations = data.data;
        setLocationName(locations?.title);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //pickUpLoactionGet
    fetch(ApiEndpoints.pickUpLocationAdd + location)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        if (data.data.length > 0) {
          console.log("VChjasbdjas=>", data.data[0].address);
          setpickStoreUpLocation(data.data[0].address);
        }
        // setRefundReasons(data?.data?.config?.Dispacther_Refund_Reason);
      });
  }, [location]);

  // const orderModify = async () => {
  //   let reqBody = {
  //     orderId: orderId,
  //   };
  //   try {
  //     let res = await axios.post(`${ApiEndpoints.orderModification}`, reqBody);
  //     if (res.status === 200) {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   orderModify();
  // }, [orderId]);

  // console.log("ASD", orderName, "data", originalData, "mob", mobileNumber);
  // console.log("ASD1", orderStatus, "data", deliveryTracking, "mob", formData);
  console.log("order-data", originalData);
  // function containsNumbers(str) {
  //   return /[0-9]/.test(str);
  // }
  const setSourceName = (sourceName) => {
    console.log(
      "sourceName ",
      sourceName == "580111",
      sourceName,
      typeof sourceName
    );
    if (sourceName == SourceName.LSWebsiteSourceName) {
      return "LS Website";
    } else {
      return "LS Mobile App";
    }
    //  else if (sourceName == "pos") {
    //   return "Point of sale";
    // }
    //  else {
    //   return sourceName;
    // }
  };
  console.log("pickStoreUpLocationpickStoreUpLocation=>", pickStoreUpLocation);
  const haddleSlected = (status, name) => {
    console.log("satt", status);
    if (name) {
      if (
        deliveryStatusActive == 3 &&
        status.key == name &&
        status.completionDetails.success == false
      ) {
        return "selected";
      } else if (status.state == deliveryStatusActive) {
        return "selected";
      }
    }
  };
  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   )
  // }
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  console.log("checkNewPickUpcheckNewPickUpcheckNewPickUp=>", checkPickUp);
  return (
    <>
      <Container fluid>
        <div class="ls-logo">
          <img
            alt="..."
            className=""
            src={
              "//cdn.shopify.com/s/files/1/0596/7079/0342/files/LiquorSplit_BLONDE_Rusty_horiz_032da0ee-0762-4141-b4b5-f3a6ca170b76.png?7260"
            }
            height={100}
          />
        </div>
        <div id="order-detail">
          <div class="header">
            <span>ORDER DETAIL</span>
            <br></br>
            <br></br>
            <span class="od-date">
              {moment(originalData?.created_at).format("MM/DD/YYYY")}{" "}
            </span>
            <br></br>
            <span>Order {orderName} is </span>
            <span class="od-status"> {orderStatus}</span>{" "}
            <span>
              {originalData?.financial_status === "refunded"
                ? "(Refunded)"
                : ""}
            </span>
            <span>
              {originalData?.financial_status === "partially_refunded"
                ? "(Partially Refunded)"
                : ""}
            </span>
            {newModifyOrder ? (
              <span style={{ marginLeft: "10px" }}>
                (Substituted to
                <Link
                  to={`/order/${newModifyOrder}/${location}`}
                  style={{ marginLeft: 5 }}
                >
                  {newModifyOrderName}
                </Link>
                )
              </span>
            ) : null}
            {prevModifyOrder ? (
              <span style={{ marginLeft: "10px" }}>
                (Substituted by
                <Link
                  to={`/order/${prevModifyOrder}/${location}`}
                  style={{ marginLeft: 5 }}
                >
                  {prevModifyOrderName}
                </Link>
                )
              </span>
            ) : null}
            <br></br>
            {giftStatus === true ? (
              <div
                style={{
                  marginTop: 10,
                }}
              >
                {" "}
                <img src={giftImage} height={30} width={30} />{" "}
                <span
                  class="od-status"
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {""}
                  {"This is a gift"}
                </span>
              </div>
            ) : null}
          </div>

          <div class="body">
            <Row>
              <Col lg="6" xs="6" sm="6">
                <CardTitle
                  tag="h3"
                  className="TextColorOrder"
                  style={{ textTransform: "capitalize" }}
                >
                  {originalData?.checkout_method} Order
                </CardTitle>
              </Col>
              <Col lg="6" xs="6" sm="6" className="locationName">
                <CardTitle tag="h3" className="TextColorOrder">
                  {locationName} ({setSourceName(originalData?.source_name)})
                </CardTitle>
              </Col>
            </Row>
            <Row>
              <Col lg="6" xs="12">
                <h6 className="heading-small text-muted">Order information </h6>
                <div class="detail">
                  <Table
                    id="od-items"
                    className="align-items-center table-flush mt-20"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">product</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>

                    {originalData?.line_items.map((item) => (
                      <tr class="item-row">
                        <td scope="col">{item?.name}</td>
                        <td scope="col">{item?.quantity}</td>
                        <td scope="col">{item?.price}</td>
                      </tr>
                    ))}
                  </Table>
                </div>
              </Col>
              <Col lg="6" xs="12">
                <h6 className="heading-small text-muted">
                  Billing information{" "}
                </h6>
                <ListGroup data-toggle="checklist" flush>
                  <ListGroupItem className="checklist-entry flex-column align-items-start">
                    <div className="checklist-item">
                      <div className="checklist-info">
                        <h5 className="checklist-title mb-0">Discount</h5>
                      </div>
                      <div>
                        <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                          - $
                          {originalData &&
                            originalData?.current_total_discounts}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>

                  <ListGroupItem className="checklist-entry flex-column align-items-start">
                    <div className="checklist-item">
                      <div className="checklist-info">
                        <h5 className="checklist-title mb-0">Sub Total</h5>
                      </div>
                      <div>
                        <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                          $
                          {originalData &&
                            parseFloat(
                              originalData?.subtotal_price - tip
                            ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>

                  <ListGroupItem className="checklist-entry flex-column align-items-start">
                    <div className="checklist-item">
                      <div className="checklist-info">
                        <h5 className="checklist-title mb-0">Tip</h5>
                      </div>
                      <div>
                        <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                          ${tip}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>

                  {originalData?.shipping_lines[0]?.code && (
                    <ListGroupItem className="checklist-entry flex-column align-items-start">
                      <div className="checklist-item">
                        <div className="checklist-info">
                          <h5 className="checklist-title mb-0">
                            Shipping ({originalData?.shipping_lines[0]?.code})
                          </h5>
                        </div>
                        <div>
                          <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                            $
                            {originalData &&
                              originalData?.shipping_lines[0]?.price}
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  )}
                  <ListGroupItem className="checklist-entry flex-column align-items-start">
                    <div className="checklist-item">
                      <div className="checklist-info">
                        <h5 className="checklist-title mb-0">Tax</h5>
                      </div>
                      <div>
                        <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                          ${originalData && originalData?.total_tax}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>

                  <ListGroupItem className="checklist-entry flex-column align-items-start">
                    <div className="checklist-item">
                      <div className="checklist-info">
                        <h5 className="checklist-title mb-0">Total Price</h5>
                      </div>
                      <div>
                        <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                          ${originalData && originalData?.current_total_price}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col lg="6">
                <Card
                  body
                  style={{
                    // height:100,
                    width: "100%",
                  }}
                >
                  <CardTitle tag="h3" className="color-text-Received-black">
                    Billing Address
                  </CardTitle>
                  <CardText>
                    {/* {originalData?.billing_address?.first_name +
                      " " +
                      originalData?.billing_address?.last_name}{" "} */}
                    {originalData?.billing_address?.name}
                    {","}
                    <br></br>
                    {originalData?.billing_address?.address1}
                    {","} <br></br>
                    {originalData?.billing_address?.city}{" "}
                    {originalData?.billing_address?.province_code},{" "}
                    {originalData?.billing_address?.zip}
                    {","} <br></br>
                    {originalData?.billing_address?.country}
                  </CardText>
                </Card>
              </Col>
              <Col lg="6">
                <Card
                  body
                  style={{
                    // height:100,
                    width: "100%",
                  }}
                >
                  {checkPickUp === "Local pickup" ? (
                    <CardTitle tag="h3" className="color-text-Received-black">
                      Pickup Location
                    </CardTitle>
                  ) : (
                    <CardTitle tag="h3" className="color-text-Received-black">
                      Shipping Address
                    </CardTitle>
                  )}
                  {checkPickUp === "Local pickup" ? (
                    <CardText>
                      {originalData?.billing_address?.name}
                      {","}
                      <br></br>
                      {pickStoreUpLocation.add1} {","}{" "}
                      {pickStoreUpLocation.building}
                      {","} <br></br>
                      {pickStoreUpLocation.city}
                      {","} {pickStoreUpLocation.state},{" "}
                      {pickStoreUpLocation.zip}
                      {","} <br></br>
                      {pickStoreUpLocation.country}
                    </CardText>
                  ) : (
                    <CardText>
                      {/* {originalData?.billing_address?.first_name +
                        " " +
                        originalData?.billing_address?.last_name}{" "} */}
                      {originalData?.shipping_address?.name}
                      {","}
                      <br></br>
                      {originalData?.shipping_address?.address1} <br></br>
                      {originalData?.shipping_address?.city}{" "}
                      {originalData?.shipping_address?.province_code},{" "}
                      {originalData?.shipping_address?.zip} <br></br>
                      {originalData?.shipping_address?.country}
                    </CardText>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Card
                  body
                  style={{
                    // height:100,
                    width: "100%",
                  }}
                >
                  <CardTitle tag="h3">Contact Information</CardTitle>
                  {checkPickUp === "Local pickup" ? (
                    <CardText>{originalData?.billing_address?.phone}</CardText>
                  ) : (
                    <CardText>{originalData?.billing_address?.phone}</CardText>
                  )}
                  <CardText>{originalData?.customer?.email}</CardText>
                </Card>
              </Col>
              <Col lg="6">
                <Card
                  body
                  style={{
                    // height:100,
                    width: "100%",
                  }}
                >
                  <CardTitle tag="h3">Shipping Method</CardTitle>
                  <CardText>{originalData?.checkout_method}</CardText>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="" lg="12" xs="12">
                <Card
                  body
                  style={{
                    // height:100,
                    width: "100%",
                  }}
                >
                  {giftStatus === true && checkOrderStatusValue !== "" && (
                    <div
                      body
                      style={{
                        marginBottom: "20px",
                        // height:100,
                        // width: "100%",
                      }}
                    >
                      <CardTitle tag="h3" className="color-text-Received-black">
                        Gift Note
                      </CardTitle>
                      <CardText className="color-text-Received-black">
                        {checkOrderStatusValue}
                      </CardText>
                    </div>
                  )}
                  {orderNote && (
                    <div
                      body
                      style={
                        {
                          // height:100,
                          // width: "100%",
                        }
                      }
                    >
                      <CardTitle tag="h3" className="color-text-Received-black">
                        Order Note
                      </CardTitle>
                      <CardText className="color-text-Received-black">
                        {orderNote}
                      </CardText>
                    </div>
                  )}
                  <CardTitle
                    tag="h3"
                    className="color-text-Received-black mt-20"
                  >
                    Order Updates
                  </CardTitle>
                  <CardTitle className="color-text-Received-black" tag="h4">
                    You will get delivery / pickup updates by text or email.
                  </CardTitle>
                  <CardText>
                    For any questions, please email{" "}
                    <a href="mailto:support@liquorsplit.com">
                      support@liquorsplit.com
                    </a>{" "}
                    or call <a href="tel:7866298069">786-629-8069</a>.
                  </CardText>
                  {/* <Row className="mt-2 border-ColorCard">
                  <Mailbox2 size="50" className="icon-color" />
                  <CardTitle tag="h3" className="TextColorOrder">
                    Getting shipping updates by email
                  </CardTitle>
                </Row> */}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PublicOrderDetail;
