import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/listReporting.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";
import { ApiEndpoints } from "../../constants/constant";
import { X } from "react-bootstrap-icons";
import ModalSku from "./ModalSku";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ReportingList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [reportData, setReportData] = useState({});
  const [summaryReports, setSummaryReports] = useState({});
  const [summaryReportsTotal, setSummaryReportsTotal] = useState({});
  const [allReportSummary, setAllReportSummary] = useState({});
  const [reconcillationData, setReconcillationData] = useState({});
  const [page, setPage] = useState(1);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [startDateNew, setStartDateNew] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateNew, setEndDateNew] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [reportDataPdf, setReportDataPdf] = useState("");
  const [amountStillDueValue, setAmountStillDueValue] = useState(false);
  const [amountStillDue, setAmountStillDue] = useState("");
  const [lsPriorPeriodFeesValue, setLsPriorPeriodFeesValue] = useState(false);
  const [lsPriorPeriodFees, setLsPriorPeriodFees] = useState("");
  const [bankFeesEditValue, setBankFeesEditValue] = useState(false);
  const [paBankReconData, setPABankReconData] = useState();
  const [bankFees, setBankFees] = useState("");
  const [skuItem, setSkuItem] = useState();
  const location_name = localStorage.getItem("selected_location_name");
  const encoded_location_name = encodeURIComponent(location_name);
  const location_name_for_PA_bank_recon_data =
    encoded_location_name.toLowerCase();
  const [stateModal, setStateModal] = useState({
    right: false,
  });
  const [flagSkuData, setFlagSkuData] = useState({});
  const [errorFlag, setErrorFlag] = useState(null);

  // Amount Still Due to LiquorSplit from Prior Periods Save and Cancel Button Handling
  const handleAmountStillDueEdit = () => {
    setAmountStillDueValue(true);
    setAmountStillDue();
  };

  const handleAmountStillDueSave = async (key, value) => {
    console.log("test", amountStillDue, lsPriorPeriodFees, bankFees);
    let response = await editReportingData(key, value);
    if (response.status == 200) {
      toast.success("Updated Successfully");
    }
    setAmountStillDueValue(false);
  };

  const handleAmountStillDueCancel = () => {
    setAmountStillDueValue(false);
  };

  // LiquorSplit Fees Incurred this Reporting Period (License Fee + Channel Management Fee, if applicable)  Save and Cancel Button Handling
  const handleLsPriorPeriodFeesEdit = () => {
    setLsPriorPeriodFeesValue(true);
    setLsPriorPeriodFees();
  };

  const handleLsPriorPeriodFeesSave = async (key, value) => {
    let response = await editReportingData(key, value);
    if (response.status == 200) {
      toast.success("Updated Successfully");
    }
    setLsPriorPeriodFeesValue(false);
  };

  const handleLsPriorPeriodFeesCancel = () => {
    setLsPriorPeriodFeesValue(false);
  };

  // Bank Fee (current Period) Save and Cancel Button Handling
  const handleBankFeesEdit = () => {
    setBankFeesEditValue(true);
    setBankFees();
  };

  const handleBankFeesSave = async (key, value) => {
    let response = await editReportingData(key, value);
    if (response.status == 200) {
      toast.success("Updated Successfully");
    }
    setBankFeesEditValue(false);
  };

  const handleBankFeesCancel = () => {
    setBankFeesEditValue(false);
  };

  const editReportingData = async (key, value) => {
    // amount_still_due: amountStillDue,
    // ls_fees_incurred: lsPriorPeriodFees,
    // bank_fee: bankFees,
    try {
      let requestBody = {
        storeName: location_name,
        data: {},
        endDate: endDate,
      };
      requestBody.data[key] = value;

      console.log("requestBody", requestBody);
      // console.log("requestBody", JSON.stringify(requestBody));
      setIsLoading(true);
      const response = await fetch(
        `${ApiEndpoints.editCashRemittedReconciliationReport}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (startDate !== null && endDate !== null) {
        await getReconcillationData(startDate, endDate);
        await getReportingData(startDate, endDate);
        await getReportingData(startDate, endDate);
        await getSummaryData(startDate, endDate);

        await getReportingDataPdf(startDate, endDate);
      }
      console.log("response", response);
      setIsLoading(false);
      getReconcillationData(startDate, endDate);
      let action = "cash-remitted-reconciliation-report";
      let type = "Info";
      let message = "Cash Remitted reconciliation report edited successfully.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      return response;
    } catch (error) {
      console.error("API Error", error);
      setIsLoading(false);
      let action = "cash-remitted-reconciliation-report";
      let type = "Error";
      let message = "Error in cash remitted reconciliation report edit";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      throw error;
    }
  };

  console.log("reconcillation data", reconcillationData);

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const getReportingData = (startDate, endDate) => {
    console.log("startdatee", startDate, endDate);
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    setIsLoading(true);
    let url = `${ApiEndpoints.getCashRemittanceSummary}?startDate=${startDate}&endDate=${endDate}&locationName=${encoded_location_name}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("REPORTING DATA", res);
        const responseData = res?.Body;
        console.log("12345reportlist", responseData);
        setReportData(responseData?.activityReportsData);
        setSummaryReports(responseData?.summaryReportsData);
        setSummaryReportsTotal(responseData?.summaryReportsDataTotal);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getSummaryData = (startDate, endDate) => {
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    // setIsLoading(true);
    let url = `${ApiEndpoints.getSalesTrxSummaryReport}?startDate=${startDate}&endDate=${endDate}&locationName=${encoded_location_name}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("SUMMARYREPORTDATA", res);
        const responseData = res?.body;
        console.log("12345summarylist", responseData);
        setAllReportSummary(responseData);

        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getReconcillationData = (startDate, endDate) => {
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    // setIsLoading(true);
    let url = `${ApiEndpoints.getCashRemittedReconciliationReport}?startDate=${startDate}&endDate=${endDate}&locationName=${encoded_location_name}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("reconcillationdata", res);
        const responseData = res?.Body;
        console.log("12345reconcillation", responseData);
        setReconcillationData(responseData);
        setAmountStillDue(reconcillationData?.amount_still_due);
        setLsPriorPeriodFees(reconcillationData?.ls_fees_incurred);
        setBankFees(reconcillationData?.bank_fee);
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getReportingDataPdf = (startDate, endDate) => {
    console.log("startdatee", startDate, endDate);
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    // setIsLoading(true);
    let url = `${ApiEndpoints.getCashRemittanceReportPDF}?startDate=${startDate}&endDate=${endDate}&locationName=${encoded_location_name}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("REPORTING PDF", res);
        const responseData = res?.message;
        console.log("reportDataPdf123", responseData);
        setReportDataPdf(responseData);
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPABankReconData = (endDate) => {
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    // setIsLoading(true);
    let url = `${ApiEndpoints.getPaBankReconData}?endDate=${endDate}&locationName=${location_name_for_PA_bank_recon_data}`;
    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("REMITTEDS", res);
        const responseData = res?.body;
        console.log("pabankrecondata", responseData);
        setPABankReconData(responseData);
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getFlaggedDataRemittance = async () => {
    try {
      const apiUrl = `${ApiEndpoints.flaggedSKUCashRemitTransactions}?startDate=${startDate}&endDate=${endDate}&locationName=${location_name}`;
      const apiKey = "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi";
      const response = await axios.get(apiUrl, {
        headers: {
          "x-api-key": apiKey,
        },
      });
      const responseData = response?.data?.body;
      setFlagSkuData(responseData);
      console.log(responseData, "FLAGGG");
    } catch (error) {
      console.warn(error, "ERROR");
      setErrorFlag(error.message);
    }
  };
  console.log(flagSkuData, "flagSkuDataa");
  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getReportingData(startDate, endDate);
      getSummaryData(startDate, endDate);
      getReconcillationData(startDate, endDate);
      getReportingDataPdf(startDate, endDate);
      getPABankReconData(endDate);
      getFlaggedDataRemittance(startDate, endDate);
    }
  }, [startDate, endDate]);

  console.log("reportdata", reportData);

  console.log("summaryReports", summaryReports);

  console.log("allReportSummary", allReportSummary);

  console.log("reconcillationData", reconcillationData);

  console.log("reportdatapdf", reportDataPdf);

  console.log("paBankReconData", paBankReconData);

  useEffect(() => {
    const currentDate = moment(); // Get the current date
    const startOfPreviousWeek = currentDate
      .clone()
      .subtract(1, "week")
      .startOf("week"); // Start of the previous week (Sunday)
    const endOfPreviousWeek = startOfPreviousWeek.clone().endOf("week"); // End of the previous week (Saturday)

    setStartDate(startOfPreviousWeek.format("YYYY-MM-DD")); // Convert to string format
    setStartDateNew(startOfPreviousWeek.format("MM-DD-YYYY"));
    // setStartDate("2024-02-18");
    setEndDate(endOfPreviousWeek.format("YYYY-MM-DD")); // Convert to string format
    setEndDateNew(startOfPreviousWeek.format("MM-DD-YYYY"));

    // setEndDate("2024-02-24");
  }, []);

  console.log("startandenddate", startDate, endDate);

  const handleDatesChange = ({ startDate, endDate }) => {
    if (startDate) {
      setStartDate(startDate.format("YYYY-MM-DD")); // Convert to string format
      setStartDateNew(startDate.format("MM-DD-YYYY"));
    }
    if (endDate) {
      setEndDate(endDate.format("YYYY-MM-DD")); // Convert to string format
      setEndDateNew(endDate.format("MM-DD-YYYY"));
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateModal({ ...stateModal, [anchor]: open });
  };

  // const handleSkuItem = (data, index) => {
  //   console.log(data, index, "iiiddddd");
  // };
  console.log(flagSkuData[skuItem], "flaaaag", flagSkuData, skuItem);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="12">
                    <div className={styles.button_title}>
                      <h6 className={styles.client_facing}>
                        {"Internal Facing"}
                      </h6>
                      <div className={styles.buttonStyle011}>
                        {(userRoles.includes("1") ||
                          roleActions.includes("report_download")) && (
                          <a
                            href={reportDataPdf}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className={styles.buttonStyle02}
                              type="button"
                              size="sm"
                              color="default"
                            >
                              Download
                            </Button>
                          </a>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className={styles.sku_calender} lg="6">
                    <div className={styles.sku_calender1}>
                      <DateRangePicker
                        startDate={moment(startDate)} // Convert to Moment.js object
                        startDateId="start_date_id"
                        endDate={moment(endDate)} // Convert to Moment.js object
                        endDateId="end_date_id"
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={setFocusedInput}
                        isOutsideRange={() => false} // Allow selecting dates in the past
                        numberOfMonths={1} // Show only one month
                        monthFormat="MMM YYYY" // Month format
                        displayFormat="MM/DD/YYYY" // Display format for the selected date
                        showClearDates={false} // Show the clear button
                        minimumNights={0} // Minimum number of nights required for a range
                        startDatePlaceholderText="Start Date" // Placeholder for start date input
                        endDatePlaceholderText="End Date" // Placeholder for end date input
                        showDefaultInputIcon={true}
                        enableOutsideDays={true}
                        small={true}
                      />
                    </div>
                    <div className={styles.buttonStyle01}>
                      {(userRoles.includes("1") ||
                        roleActions.includes("report_download")) && (
                        <a
                          href={reportDataPdf}
                          download="MyExampleDoc"
                          target="_blank"
                        >
                          <Button
                            className={styles.buttonStyle02}
                            type="button"
                            size="sm"
                            color="default"
                          >
                            Download
                          </Button>
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <div style={{ margin: "15px 0 15px 20px" }}>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  Cash Remitted Summary Report – Cumulative for all periods
                </div>
                {/* <div style={{ fontSize: "15px", fontWeight: 700 }}>
                  Store: 12th & Pine Wine & Spirits & Cigars
                </div> */}
              </div>

              <div style={{ height: "auto", overflowY: "auto" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      <th scope="col">CASH REMIT ID</th>
                      <th scope="col">PERIOD ENDING</th>
                      <th scope="col">SALES SUBTOTAL(EXCL TAX)</th>
                      <th scope="col">SALES TAX COLLECTED</th>
                      <th scope="col">TOTAL CASH REMITTED</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    <tr>
                      <td>
                        {reconcillationData?.cash_remit_id ? (
                          reconcillationData?.cash_remit_id
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            -
                          </div>
                        )}
                      </td>

                      <td>
                        <div
                          style={
                            {
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                            }
                          }
                        >
                          {reconcillationData?.period_ending ? (
                            <>{reconcillationData?.period_ending}</>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            marginLeft: "20px",
                          }}
                        >
                          {reconcillationData?.sales_subtotal_excl_tax ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.sales_subtotal_excl_tax} */}
                              {Number(
                                reconcillationData?.sales_subtotal_excl_tax
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            marginLeft: "20px",
                          }}
                        >
                          {reconcillationData?.sales_tax_collected ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.sales_tax_collected} */}
                              {Number(
                                reconcillationData?.sales_tax_collected
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            marginLeft: "20px",
                          }}
                        >
                          {reconcillationData?.net_cash_remittance ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.net_cash_remittance} */}
                              {Number(
                                reconcillationData?.net_cash_remittance
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <hr style={{ border: "1px solid black" }}></hr>

              <div style={{ margin: "50px 0 15px 20px" }}>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  Cash Remitted reconciliation report - Cumulative for all
                  periods
                </div>
                {/* <div style={{ fontSize: "15px", fontWeight: 700 }}>
                  Store: 12th & Pine Wine & Spirits & Cigars
                </div> */}
              </div>
              <div style={{ height: "auto", overflowY: "auto" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      <th scope="col">CASH REMIT ID</th>
                      <th scope="col">PERIOD ENDING</th>
                      <th scope="col">SALES SUBTOTAL(EXCL TAX)</th>
                      <th scope="col">SALES TAX COLLECTED</th>
                      <th scope="col">PLATFORM COMMISSION</th>
                      <th scope="col">APP FEE ON MARKETING, DELIVERY, ADJ</th>
                      <th scope="col">DUE FOR PAYOUT</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    <tr>
                      <td>
                        {reconcillationData?.cash_remit_id ? (
                          reconcillationData?.cash_remit_id
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            -
                          </div>
                        )}
                      </td>
                      <td>
                        {reconcillationData?.period_ending ? (
                          reconcillationData?.period_ending
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            -
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.sales_subtotal_excl_tax ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {Number(
                                reconcillationData?.sales_subtotal_excl_tax
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.sales_tax_collected ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.sales_tax_collected} */}
                              {Number(
                                reconcillationData?.sales_tax_collected
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.ls_commission ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.ls_commission} */}
                              {Number(
                                reconcillationData?.ls_commission
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.app_fee_on_marketing ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.app_fee_on_marketing} */}
                              {Number(
                                reconcillationData?.app_fee_on_marketing
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={{ backgroundColor: "#e2efda" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.due_for_payment ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>{" "}
                              {/* {reconcillationData?.due_for_payment} */}
                              {Number(
                                reconcillationData?.due_for_payment
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tbody style={{ lineHeight: "5px" }}>
                    <tr>
                      <td scope="col" colSpan="6">
                        <div>
                          {
                            " Cumulative Due to Client LiquorSplit Before LiquorSplit Fees"
                          }
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.cummulative_due_to_client ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.cummulative_due_to_client} */}
                              {Number(
                                reconcillationData?.cummulative_due_to_client
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>LiquorSplit Fees</th>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>
                          {
                            " LiquorSplit Prior Period Total Fees (License Fee + Channel Management Fee, if applicable)"
                          }
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.ls_prior_period_total_fees ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.ls_prior_period_total_fees} */}
                              {Number(
                                reconcillationData?.ls_prior_period_total_fees
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>{" Bank Fees (prior periods)"}</div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.bank_fees_prior_periods ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.bank_fees_prior_periods} */}
                              {Number(
                                reconcillationData?.bank_fees_prior_periods
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>
                          {
                            "  Amount Still Due to LiquorSplit from Prior Periods"
                          }
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {amountStillDueValue ? (
                            <>
                              <Input
                                type="number"
                                defaultValue={
                                  reconcillationData?.amount_still_due
                                }
                                value={amountStillDue}
                                onChange={(e) =>
                                  setAmountStillDue(e.target.value)
                                }
                                // min={0}
                                style={{ width: "90px" }}
                              />
                              {/* amount_still_due: amountStillDue,
                                 ls_fees_incurred: lsPriorPeriodFees,
                                 bank_fee: bankFees, */}
                              <Button
                                onClick={() =>
                                  handleAmountStillDueSave(
                                    "amount_still_due",
                                    amountStillDue
                                  )
                                }
                                color="success"
                                outline
                                size="sm"
                                style={{ marginLeft: "10px" }}
                              >
                                ✓
                              </Button>
                              <Button
                                onClick={handleAmountStillDueCancel}
                                color="danger"
                                outline
                                size="sm"
                              >
                                X
                              </Button>
                            </>
                          ) : (
                            <>
                              {reconcillationData?.amount_still_due ? (
                                <>
                                  <span style={{ marginRight: "1px" }}>$</span>
                                  {/* {reconcillationData?.amount_still_due} */}
                                  {Number(
                                    reconcillationData?.amount_still_due
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </>
                              ) : (
                                <span>-</span>
                              )}
                              <span style={{ marginLeft: "5px" }}>
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  onClick={handleAmountStillDueEdit}
                                  style={{ cursor: "pointer", color: "blue" }}
                                ></i>
                              </span>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>
                          {
                            "LiquorSplit Fees Incurred this Reporting Period (License Fee + Channel Management Fee, if applicable)"
                          }
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {lsPriorPeriodFeesValue ? (
                            <>
                              <Input
                                type="number"
                                defaultValue={
                                  reconcillationData?.ls_prior_period_fees
                                }
                                value={lsPriorPeriodFees}
                                onChange={(e) =>
                                  setLsPriorPeriodFees(e.target.value)
                                }
                                // min={0}
                                style={{ width: "90px" }}
                              />
                              <Button
                                onClick={() =>
                                  handleLsPriorPeriodFeesSave(
                                    "ls_fees_incurred",
                                    lsPriorPeriodFees
                                  )
                                }
                                color="success"
                                outline
                                size="sm"
                                style={{ marginLeft: "10px" }}
                              >
                                ✓
                              </Button>
                              <Button
                                onClick={handleLsPriorPeriodFeesCancel}
                                color="danger"
                                outline
                                size="sm"
                              >
                                X
                              </Button>
                            </>
                          ) : (
                            <>
                              {reconcillationData?.ls_prior_period_fees ? (
                                <>
                                  <span style={{ marginRight: "1px" }}>$</span>
                                  {/* {reconcillationData?.ls_prior_period_fees} */}
                                  {Number(
                                    reconcillationData?.ls_prior_period_fees
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </>
                              ) : (
                                <span>-</span>
                              )}
                              <div style={{ marginLeft: "5px" }}>
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  onClick={handleLsPriorPeriodFeesEdit}
                                  style={{ cursor: "pointer", color: "blue" }}
                                ></i>
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>{"Bank Fee (current Period)"}</div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {bankFeesEditValue ? (
                            <>
                              <Input
                                type="number"
                                defaultValue={
                                  reconcillationData?.bank_fees_current_period
                                }
                                value={bankFees}
                                onChange={(e) => setBankFees(e.target.value)}
                                // min={0}
                                style={{ width: "90px" }}
                              />
                              <Button
                                onClick={() =>
                                  handleBankFeesSave("bank_fee", bankFees)
                                }
                                color="success"
                                outline
                                size="sm"
                                style={{ marginLeft: "10px" }}
                              >
                                ✓
                              </Button>
                              <Button
                                onClick={handleBankFeesCancel}
                                color="danger"
                                outline
                                size="sm"
                              >
                                X
                              </Button>
                            </>
                          ) : (
                            <>
                              {reconcillationData?.bank_fees_current_period ? (
                                <>
                                  <span style={{ marginRight: "1px" }}>$</span>
                                  {/* {reconcillationData?.bank_fees_current_period} */}
                                  {Number(
                                    reconcillationData?.bank_fees_current_period
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </>
                              ) : (
                                <span>-</span>
                              )}
                              <span style={{ marginLeft: "5px" }}>
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  onClick={handleBankFeesEdit}
                                  style={{ cursor: "pointer", color: "blue" }}
                                ></i>
                              </span>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>{"Total Due to LiquorSplit this Period"}</div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.total_due ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.total_due} */}
                              {Number(
                                reconcillationData?.total_due
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>
                          {
                            "Net Cash Remittance to Client Store from LiquorSplit"
                          }
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.net_cash_remittance ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {reconcillationData?.net_cash_remittance} */}
                              {Number(
                                reconcillationData?.net_cash_remittance
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div>
                          {"Cumulative Marketing Fees Paid for by  LiquorSplit"}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {reconcillationData?.cumulative_marketing_fees ? (
                            <>
                              {/* $ {reconcillationData?.cumulative_marketing_fees} */}
                              ${" "}
                              {Number(
                                reconcillationData?.cumulative_marketing_fees
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="12">
                        <div>
                          {"Remitted by LiquorSplit"}
                          {paBankReconData && (
                            <>
                              {paBankReconData?.bankRecs.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    marginTop: "20px",
                                    borderBottom: "1px solid #e6e6e6",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    padding: "0.7rem",
                                    flexDirection: "row",
                                    display: "flex",
                                  }}
                                >
                                  {Array.isArray(paBankReconData?.bankRecs) ? (
                                    <div
                                      style={{
                                        width: "93%",
                                      }}
                                    >
                                      {item?.formatted_date}
                                    </div>
                                  ) : (
                                    <span>-</span>
                                  )}
                                  {Array.isArray(paBankReconData?.bankRecs) ? (
                                    <div
                                      style={{
                                        width: "7%",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      ${" "}
                                      {Number(item?.amount).toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </td>
                      {/* <td scope="col" colSpan="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          {paBankReconData &&
                          Array.isArray(paBankReconData?.bankRecs) ? (
                            <>
                              {paBankReconData?.bankRecs.map((item, index) => (
                                <div key={index} style={{ marginTop: "20px" }}>
                                  $ {item?.amount}
                                </div>
                              ))}
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td> */}
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div style={{ fontWeight: "bold" }}>
                          {"Total Remitted by LiquorSplit"}
                        </div>
                      </td>
                      <td scope="col" colSpan="6">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {paBankReconData ? (
                            <div
                              style={{ marginTop: "10px", fontWeight: "bold" }}
                            >
                              ${" "}
                              {Number(
                                paBankReconData?.totalAmountRemitted
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td scope="col" colSpan="6">
                        <div style={{ fontWeight: "bold" }}>
                          {"Still Due from LiquorSplit"}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {paBankReconData ? (
                            <div
                              style={{ marginTop: "10px", fontWeight: "bold" }}
                            >
                              ${" "}
                              {Number(paBankReconData?.stillDue).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </div>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <hr style={{ border: "1px solid black" }}></hr>
              <div style={{ margin: "30px 0 15px 20px" }}>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  Summary Report by Channel Partner - for period {startDateNew}{" "}
                  to {endDateNew}
                </div>
              </div>
              <div style={{ height: "auto", overflowY: "auto" }}>
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">PLATFORM</th>
                      <th
                        scope="col"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        SALES TAX NOTES
                      </th>
                      <th scope="col">SALES SUBTOTAL(EXCL TAX)</th>
                      <th scope="col">SALES TAX COLLECTED</th>
                      <th scope="col">PLATFORM COMMISSION</th>
                      <th scope="col">APP FEE ON MARKETING, DELIVERY, ADJ</th>
                      <th scope="col">DUE FOR PAYOUT</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {allReportSummary &&
                      Object.keys(allReportSummary).map((platform, index) => {
                        return allReportSummary[platform].map(
                          (data, dataIndex) => {
                            return (
                              <tr key={`${platform}-${dataIndex}`}>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {data?.platform || "-"}
                                  </div>
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {data?.sales_subtotal_excl_tax || "-"} */}
                                    {data?.sales_tax ? (
                                      <>
                                        {/* <span style={{ marginRight: "1px" }}>
                                        $
                                      </span>{" "} */}
                                        {data?.sales_tax}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {data??.sales_subtotal_excl_tax || "-"} */}
                                    {data?.sales_subtotal_excl_tax ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {data?.sales_subtotal_excl_tax} */}
                                        {Number(
                                          data?.sales_subtotal_excl_tax
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {data??.sales_tax_collected || "-"} */}
                                    {data?.sales_tax_collected ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {data?.sales_tax_collected} */}
                                        {Number(
                                          data?.sales_tax_collected
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {data??.ls_commission || "-"} */}
                                    {data?.ls_commission ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {data?.ls_commission} */}
                                        {Number(
                                          data?.ls_commission
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {data??.app_fee_on_marketing || "-"} */}
                                    {data?.app_fee_on_marketing ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {data?.app_fee_on_marketing} */}
                                        {Number(
                                          data?.app_fee_on_marketing
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: "#e2efda" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* {data??.due_for_payment || "-"} */}
                                    {data?.due_for_payment ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {data?.due_for_payment} */}
                                        {Number(
                                          data?.due_for_payment
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        );
                      })}

                    <div style={{ marginTop: "20px" }}></div>
                    <tr
                      style={{
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <td colSpan="2" style={{ fontWeight: "bold" }}>
                        Total
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {summaryReportsTotal?.sales_subtotal_excl_tax ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {summaryReportsTotal?.sales_subtotal_excl_tax} */}
                              {Number(
                                summaryReportsTotal?.sales_subtotal_excl_tax
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {summaryReportsTotal?.sales_tax_collected ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>{" "}
                              {/* {summaryReportsTotal?.sales_tax_collected} */}
                              {Number(
                                summaryReportsTotal?.sales_tax_collected
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {summaryReportsTotal?.ls_commission ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {summaryReportsTotal?.ls_commission} */}
                              {Number(
                                summaryReportsTotal?.ls_commission
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {summaryReportsTotal?.app_fee_on_marketing ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {summaryReportsTotal?.app_fee_on_marketing} */}
                              {Number(
                                summaryReportsTotal?.app_fee_on_marketing
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {summaryReportsTotal?.due_for_payment ? (
                            <>
                              <span style={{ marginRight: "1px" }}>$</span>
                              {/* {summaryReportsTotal?.due_for_payment} */}
                              {Number(
                                summaryReportsTotal?.due_for_payment
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              -
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <div style={{ padding: "20px 0px 20px 25px" }}>
                    Note: Client store has already received payment for
                    transactions from Instacart shoppers, and no payout is due.
                  </div>
                </div>
              </div>
              <hr style={{ border: "1px solid black" }}></hr>
              <div style={{ margin: "30px 0 15px 20px" }}>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  Sale Transaction Activity by Channel Partner
                </div>
              </div>
              <ModalSku
                stateModal={stateModal}
                setStateModal={setStateModal}
                toggleDrawer={toggleDrawer}
              >
                <div style={{ marginLeft: "4px" }}>
                  <Table bordered>
                    <thead
                      className="thead-light"
                      style={{ position: "sticky", top: 0, zIndex: 100 }}
                    >
                      <tr>
                        <th
                          scope="col"
                          colSpan="8"
                          style={{ backgroundColor: "white" }}
                        >
                          <span
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <span
                              onClick={toggleDrawer("right", false)}
                              style={{ cursor: "pointer" }}
                            >
                              <X
                                style={{ color: "#525f80", fontSize: "26px" }}
                              />
                            </span>

                            <div className={styles.orderid_discliamer_cont}>
                              <span className={styles.orderid_heading}>
                                Order Id Missing
                              </span>
                              <span className={styles.orderid_discliamer_text}>
                                * Order id missing on SKU by transactions report
                              </span>
                            </div>
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          colSpan="8"
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <span>
                            {skuItem === "shopify"
                              ? "LIQUORSPLIT"
                              : skuItem?.toUpperCase()}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">ORDER DATE</th>
                        <th scope="col">ORDER ID</th>
                      </tr>
                    </thead>

                    <tbody className="list">
                      {flagSkuData &&
                      flagSkuData[skuItem] &&
                      flagSkuData[skuItem]?.length ? (
                        flagSkuData[skuItem].map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item?.date ? (
                                item?.date
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  -
                                </div>
                              )}
                            </td>
                            <td>
                              {item?.order_number ? (
                                item?.order_number
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  -
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ fontSize: "15px" }}>
                            No data found...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </ModalSku>
              <div style={{ height: "auto", overflowY: "scroll" }}>
                {reportData &&
                  Object.keys(reportData).map((data, index) => (
                    <>
                      <Table
                        key={index}
                        className="align-items-center table-flush"
                      >
                        <thead
                          className="thead-light"
                          style={{ position: "sticky", top: 0, zIndex: 100 }}
                        >
                          <tr>
                            <th
                              scope="col"
                              colSpan="8"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <span>
                                {data === "shopify"
                                  ? "LIQUORSPLIT"
                                  : data?.toUpperCase()}
                              </span>

                              <Button
                                onClick={() => {
                                  setStateModal({ right: true });
                                  setSkuItem(
                                    data === "shopify" ? "liquorsplit" : data
                                  );
                                }}
                                type="button"
                                size="sm"
                                color="default"
                                style={{ marginLeft: "90px" }}
                              >
                                Order Id missing
                              </Button>
                            </th>
                          </tr>
                          <tr>
                            <th scope="col">ORDER ID</th>
                            <th scope="col">ORDER DATE</th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              APP TRANSACTION TYPE
                            </th>
                            <th scope="col">SALES SUBTOTAL(EXCL TAX)</th>
                            <th scope="col">SALES TAX COLLECTED</th>
                            <th scope="col">PLATFORM COMMISSION</th>
                            <th scope="col">
                              APP FEE ON MARKETING,DELIVERY,ADJ
                            </th>
                            <th scope="col">DUE FOR PAYOUT</th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {reportData[data]?.length ? (
                            reportData[data].map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {item?.order_id ? (
                                    item?.order_id
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      -
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {item?.order_date ? (
                                    item?.order_date
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      -
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.app_transaction_type ? (
                                      <>{item?.app_transaction_type}</>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.sales_subtotal_excl_tax ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {item?.sales_subtotal_excl_tax} */}
                                        {Number(
                                          item?.sales_subtotal_excl_tax
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.sales_tax_collected ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {item?.sales_tax_collected} */}
                                        {Number(
                                          item?.sales_tax_collected
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.ls_commission ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {item?.ls_commission} */}
                                        {Number(
                                          item?.ls_commission
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.app_fee_on_marketing ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {item?.app_fee_on_marketing} */}
                                        {Number(
                                          item?.app_fee_on_marketing
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "#e2efda",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.due_for_payment ? (
                                      <>
                                        <span style={{ marginRight: "1px" }}>
                                          $
                                        </span>{" "}
                                        {/* {item?.due_for_payment} */}
                                        {Number(
                                          item?.due_for_payment
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        -
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ fontSize: "15px" }}>
                                No data found...
                              </td>
                            </tr>
                          )}
                        </tbody>

                        <tbody className="list">
                          <div style={{ marginTop: "30px" }}></div>
                          {summaryReports &&
                          summaryReports[data] &&
                          summaryReports[data].length ? (
                            summaryReports[data].map((item, index) => (
                              <>
                                <tr
                                  key={`summary-${index}`}
                                  style={{
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                >
                                  <td scope="col" colSpan="3">
                                    <div
                                      style={{
                                        // margin: "10px 0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {"Total"}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.sales_subtotal_excl_tax ? (
                                        <>
                                          <span style={{ marginRight: "1px" }}>
                                            $
                                          </span>{" "}
                                          {/* {item?.sales_subtotal_excl_tax} */}
                                          {Number(
                                            item?.sales_subtotal_excl_tax
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.sales_tax_collected ? (
                                        <>
                                          <span style={{ marginRight: "1px" }}>
                                            $
                                          </span>{" "}
                                          {/* {item?.sales_tax_collected} */}
                                          {Number(
                                            item?.sales_tax_collected
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.ls_commission ? (
                                        <>
                                          <span style={{ marginRight: "1px" }}>
                                            $
                                          </span>{" "}
                                          {/* {item?.ls_commission} */}
                                          {Number(
                                            item?.ls_commission
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.app_fee_on_marketing ? (
                                        <>
                                          <span style={{ marginRight: "1px" }}>
                                            $
                                          </span>{" "}
                                          {/* {item?.app_fee_on_marketing} */}
                                          {Number(
                                            item?.app_fee_on_marketing
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.due_for_payment ? (
                                        <>
                                          <span style={{ marginRight: "1px" }}>
                                            $
                                          </span>{" "}
                                          {/* {item?.due_for_payment} */}
                                          {Number(
                                            item?.due_for_payment
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan="5"></td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {item?.ls_commission &&
                                      item?.sales_subtotal_excl_tax ? (
                                        `${(
                                          (parseFloat(item?.ls_commission) /
                                            (parseFloat(
                                              item?.sales_subtotal_excl_tax
                                            ) +
                                              parseFloat(
                                                item?.app_fee_on_marketing || 0
                                              ))) *
                                          100
                                        ).toFixed(1)}%`
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ fontSize: "15px" }}>
                                No data found...
                              </td>
                            </tr>
                          )}
                          {/* <div style={{ marginTop: "40px" }}></div> */}
                        </tbody>
                      </Table>
                    </>
                  ))}
              </div>

              <CardFooter>
                {/* {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )} */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ReportingList;
