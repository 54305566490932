import React, { useMemo, useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./style.css";
import { getToken } from "../../../onboarding";
import Header from "../../ui/Header.js";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import ListTable from "./listTable";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { number } from "prop-types";
import { set } from "lodash";

const ordersColumns = [
  {
    header: "Order Name",
    accessor: "id",
  },
  {
    header: "Number Of Items",
    accessor: "noOfItems",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Total Price $",
    accessor: "totalPrice",
  },
  {
    header: "Order Date",
    accessor: "orderDate",
  },
  {
    header: "Delivery Method",
    accessor: "deliveryMethod",
  },
  {
    header: "Source",
    accessor: "source_name",
  },
];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
var size = 50;
var currentDate = new Date();
var lastDate = currentDate.setDate(currentDate.getDate() - 5);
lastDate = new Date(lastDate);
currentDate = new Date();
console.log(currentDate);
function OrdersListView(props) {
  const classes = useStyles();
  const listName = "orders";
  // const [size,setSize]= useState(10)
  const [isLoading, setIsLoading] = useState(true);
  const [useValue, setUseValue] = useState(0);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [size, setSize] = useState(10);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const userLocation = localStorage.getItem("currentLocation");
  console.log("location", userLocation);

  const history = useHistory();

  const { search } = useLocation();

  console.log("QUERY PARAMS", search);

  const handleView = (item) => {
    const id = item?.pk.split("#")[1];
  };

  const columns = useMemo(() => ordersColumns, []);
  const handleChange = async (e) => {
    console.log("EEEE", e.target.value);

    let tempLocation = e.target.value;
    console.log("Before subscribe", tempLocation);
    // debugger;
    // let reqBodyUnsubscribe = {
    //   locationId: localStorage?.getItem("order_location")?.split("#")[1],
    //   token: localStorage?.getItem("FCM_token"),
    // };
    let reqBodySubscribe = {
      locationId: `${tempLocation}`.split("#")[1],
      token: localStorage?.getItem("FCM_token"),
      userId: localStorage?.getItem("userId"),
    };
    let configSubscribe = {
      method: "POST",
      body: JSON.stringify(reqBodySubscribe),
    };

    // let configUnsubscribe = {
    //   method: "POST",
    //   body: JSON.stringify(reqBodyUnsubscribe),
    // };
    //let unsubscribeApi = `${ApiEndpoints.unsubscribeFcm}`;
    let subscribeApi = `${ApiEndpoints.subscribeFcm}`;

    // let unsubscribeRes = await fetch(unsubscribeApi, configUnsubscribe);
    // console.log("UNSUB", unsubscribeRes);

    let subscribeRes = await fetch(subscribeApi, configSubscribe);
    console.log("SUBS", subscribeRes);
    console.log("Before locale storage", tempLocation);
    updateOrderStatus("All");
    setLocationId(tempLocation);
    localStorage.setItem("order_location", tempLocation);

    //console.log("handle change");
    //getOrderList(e?.target?.value, orderStatus, size, currentDate, lastDate);
  };

  const updateOrderStatus = (orderStatus) => {
    setOrders([]);
    setLastEvaluatedKey(null);
    setOrderStatus(orderStatus);
    setPageNum(0);
    setSearchKeyword("");
    currentDate = new Date();
    lastDate = currentDate.setDate(currentDate.getDate() - 5);
    lastDate = new Date(lastDate);
    currentDate = new Date();
    // setTimeout(function () {
    //   getOrderList(locationId, orderStatus, size);
    // }, 1000);
  };

  const allOrders = (orderStatus) => {
    setOrders([]);
    setLastEvaluatedKey(null);
    setOrderStatus(orderStatus);
    currentDate = new Date();
    lastDate = currentDate.setDate(currentDate.getDate() - 5);
    lastDate = new Date(lastDate);
    currentDate = new Date();
    console.log(currentDate);
    getOrderList(locationId, orderStatus, size, currentDate, lastDate);
  };

  const getOrderList = (
    locationId,
    orderStatus,
    size,
    currentDate,
    lastDate
  ) => {
    const token = getToken();
    console.log("GET ORDER LIST", locationId);

    let UserLocations = [];
    // if (userLocation !== null) {
    //   let data0=localStorage.getItem('currentLocation')
    //   let locationR=[]
    //    let data= data0.split(',')
    //    UserLocations=data
    //   // var location = userLocation.split("#")[1];
    //   var location=UserLocations[0].split('#')[1]
    // } else {
    //   var location = locationId.split("#")[1];

    // }

    //let location = locationId.split("#")[1];
    let reqBody = {
      search_terms: {
        order_status: [`${orderStatus}`],
      },
      numeric_ranges: [],
      //locationId: "wh#aa2d8ad1-4ba2-427b-b78f-7da626a379df",
      locationId: locationId,
    };
    const config = {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify(reqBody),
    };
    //let OrderApi = `${ApiEndpoints.orderListByLocationId}?locationId=${location}&orderStatus=${orderStatus}&size=${size}&currentDate=${currentDate}&lastDate=${lastDate}`;
    let OrderApi = `${ApiEndpoints.orderList}?page=${pageNum}&size=${size}&order_number=desc`;
    // if (lastEvaluatedKey !== null) {
    //   OrderApi += `&pk=${lastEvaluatedKey?.pk?.split("#")[1]}&sk=${
    //     lastEvaluatedKey?.sk?.split("store#wh#")[1]
    //   }`;
    // }
    setIsLoading(true);
    fetch(OrderApi, config)
      .then((response) => response.json())
      .then((data) => {
        const ordersRepsonse = data?.data;
        console.log("Order REs", ...ordersRepsonse);
        if (pageNum > 0) {
          setOrders([...orders, ...ordersRepsonse]);
          if(ordersRepsonse.length < 10){
            setLoadMoreEnabled(false);

          }else{
            setLoadMoreEnabled(true);
          }
        } else if (pageNum === 0) {
          console.log("OT", ordersRepsonse);
          setOrders(ordersRepsonse);
           if(ordersRepsonse.length < 10){
            setLoadMoreEnabled(false);

          }else{
            setLoadMoreEnabled(true);
          }
        }
        
        setIsLoading(false);
        // setTimeout(() => {
        //   setIsLoading(false);
        //   console.log(isLoading);
        // }, 2000);
        // if (data?.data?.LastEvaluatedKey) {
        //   setLastEvaluatedKey(data?.data?.LastEvaluatedKey);
        // } else {
        //   setLastEvaluatedKey(null);
        // }
        // setOrders([...orders, ...ordersRepsonse]);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        console.log(err);
      });
    console.log(isLoading);
  };

  const listTableData = (data) => {
    allOrders(orderStatus);
  };

  // const getLocationList = async () => {
  //   const token = getToken();
  //   const config = {
  //     headers: { Authorization: token },
  //   };
  //   fetch(`${ApiEndpoints.locationList}`, config)
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       const locations = data?.data?.Items;
  //       console.log("LOc", locations);

  //       let location = localStorage.getItem("order_location");

  //       if (location && location !== "undefined") {
  //         console.log("SDFSDFSDFSDFSDFSDF");
  //         setLocationId(location);
  //         let reqBodySubscribe = {
  //           locationId: location?.split("#")[1],
  //           token: localStorage.getItem("FCM_token"),
  //           userId: localStorage.getItem("userId"),
  //         };
  //         let configSubscribe = {
  //           method: "POST",
  //           body: JSON.stringify(reqBodySubscribe),
  //         };

  //         let subscribeApi = `${ApiEndpoints.subscribeFcm}`;

  //         let subscribeRes = await fetch(subscribeApi, configSubscribe);
  //         console.log("SUBS", subscribeRes);
  //         // setPageNum(0);
  //         // updateOrderStatus("All");
  //       } else {
  //         let reqBodySubscribe = {
  //           locationId: locations[0].pk?.split("#")[1],
  //           token: localStorage.getItem("FCM_token"),
  //           userId: localStorage.getItem("userId"),
  //         };
  //         let configSubscribe = {
  //           method: "POST",
  //           body: JSON.stringify(reqBodySubscribe),
  //         };

  //         let subscribeApi = `${ApiEndpoints.subscribeFcm}`;

  //         let subscribeRes = await fetch(subscribeApi, configSubscribe);
  //         console.log("SUBS", subscribeRes);

  //         localStorage.setItem("order_location", locations[0].pk);
  //         // setPageNum(0);
  //         // updateOrderStatus("All");
  //         setLocationId(locations[0].pk);
  //       }

  //       let role = localStorage.getItem("userRole");

  //       if (role == "Admin") {
  //         setLocationData(locations);
  //       } else {
  //         let data0 = localStorage.getItem("currentLocation");
  //         let locationR = [];
  //         let data = data0.split(",");
  //         data.forEach((el) => {
  //           let data3 = locations.find((item) => item.pk == el);
  //           locationR.push(data3);
  //         });
  //         console.log("locationsss", locationR);
  //         setLocationData(locationR);
  //         // var location = userLocation.split("#")[1];
  //       }
  //       // if (userLocation !== null) {
  //       //   let data0 = localStorage.getItem("currentLocation");
  //       //   let data = data0.split(",");
  //       //   setLocationId(data[0]);
  //       //   //getOrderList(data[0], orderStatus, size, currentDate, lastDate);
  //       // } else {
  //       //   setLocationId(locations[0]?.pk);
  //       //   // getOrderList(
  //       //   //   locations[0]?.pk,
  //       //   //   orderStatus,
  //       //   //   size,
  //       //   //   currentDate,
  //       //   //   lastDate
  //       //   // );
  //       // }
  //       // setIsLoading(false);
  //       setUseValue(1);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: orders,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    currentDate = new Date();
    lastDate = currentDate.setDate(currentDate.getDate() - 5);
    lastDate = new Date(lastDate);
    currentDate = new Date();
    //console.log(currentDate);
    //getLocationList();
  }, [useValue < 1]);

  const getLocationList = () => {
    let location = localStorage.getItem("selected_location_id");
    if (location) {
      setLocationId(location);
    }
  };

  useEffect(() => {
    getLocationList();
  }, []);

  const loadMore = () => {
    currentDate = new Date(lastDate);
    let date = new Date(lastDate);
    lastDate = date.setDate(date.getDate() - 5);
    lastDate = new Date(lastDate);
    setPageNum((p) => p + 1);

    // setTimeout(function () {
    //   getOrderList(locationId, orderStatus, size);
    // }, 3000);
  };

  useEffect(() => {
    if (locationId) {
      getOrderList(locationId, orderStatus, size);
    }
  }, [pageNum, orderStatus, locationId]);

  useEffect(() => {
    if (searchKeyword.length > 0) {
      if (searchKeyword.length > 2) {
        searchFilter(searchKeyword);
      } else if (searchKeyword.length === 0) {
        //updateOrderStatus("All");
        getOrderList(locationId, "All", size);
      }
    }
  }, [searchKeyword]);

  const searchFilter = (keyword) => {
    setOrderStatus("All");
    let reqBody = {
      search_keyword: keyword,
      locationId: locationId,
    };

    const config = {
      method: "POST",
      body: JSON.stringify(reqBody),
    };
    let OrderApi = `${ApiEndpoints.orderList}?page=0&size=50&order_number=asc`;
    setIsLoading(true);
    fetch(OrderApi, config)
      .then((response) => response.json())
      .then((data) => {
        const ordersRepsonse = data?.data;
        console.log("Order REs", ...ordersRepsonse);
        setOrders([...ordersRepsonse]);
        setIsLoading(false);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        console.log(err);
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Order"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      {
                        <Input
                          id="exampleFormControlSelect1"
                          type="select"
                          onChange={handleChange}
                        >
                          {locationData.map((item, index) => (
                            <option
                              key={index}
                              value={item?.pk}
                              selected={
                                item?.pk === locationId ? "selected" : ""
                              }
                            >
                              {item?.description}
                            </option>
                          ))}
                        </Input>
                      }
                    </div>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} sm={5}>
                    <FormGroup className="row">
                      <Col md="10">
                        <Input
                          placeholder="Search"
                          id="example-text-input"
                          type="text"
                          onChange={(e) => setSearchKeyword(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <ButtonGroup className="btngroup" responsive>
                <Button
                  className={orderStatus === "All" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("All")}
                >
                  All
                </Button>
                <Button
                  className={orderStatus === "Received" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("Received")}
                >
                  Received
                </Button>
                <Button
                  className={orderStatus === "In Progress" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("In Progress")}
                >
                  In Progress
                </Button>
                <Button
                  className={
                    orderStatus === "Ready for delivery" ? "active" : ""
                  }
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("Ready for delivery")}
                >
                  Ready for Delivery
                </Button>
                <Button
                  className={orderStatus === "Ready for pickup" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("Ready for pickup")}
                >
                  Ready for Pickup
                </Button>
                <Button
                  className={orderStatus === "Out for delivery" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("Out for delivery")}
                >
                  Out For Delivery
                </Button>
                <Button
                  className={orderStatus === "Picked" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("Picked")}
                >
                  Picked
                </Button>
                {/* <Button
                  className={orderStatus === "Delivered" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateOrderStatus("Delivered")}
                >
                  Delivered
                </Button> */}
              </ButtonGroup>
              <ListTable
                ordersColumns={ordersColumns}
                orders={orders}
                handleView={handleView}
                history={history}
                handlestatus={listTableData}
                updateOrderStatus={updateOrderStatus}
              />
              <CardFooter className="py-4">
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={() => loadMore()}
                  >
                    Load More...
                  </a>)
                }
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default OrdersListView;
