import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
} from "reactstrap";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/shopifyProductsList.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const headerData = [
  {
    header: "Image",
  },
  {
    header: "Title",
  },
  {
    header: "No Of Variants",
  },
  {
    header: "Status",
  },
  {
    header: "Actions",
  },
];

// const dummyData = [
//   {
//     productName: "Baileys Almande Almond Milk Liqueur",
//     options: [
//       {
//         itemName: "Baileys Original Irish Cream",
//         master_productId: 7,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Baileys Strawberry Irish Cream",
//         master_productId: 8,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Baileys Scrumptious Salted Caramel Irish Cream",
//         master_productId: 356,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Baileys Deliciously Light Irish Cream",
//         master_productId: 421,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//     ],
//   },
//   {
//     productName: "Captain Morgan Private Stock Rum",
//     options: [
//       {
//         itemName: "Bacardi Black Rum",
//         master_productId: 140,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Bacardi Coconut Rum",
//         master_productId: 141,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Bacardi Gold Rum",
//         master_productId: 142,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "OCaptain Morgan Original Spiced Rum",
//         master_productId: 150,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//     ],
//   },
//   {
//     productName: "Ciroc Pineapple Vodka",
//     options: [
//       {
//         itemName: "Ciroc Apple Vodka",
//         master_productId: 152,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Ciroc Peach Vodka",
//         master_productId: 154,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Ciroc Red Berry Vodka",
//         master_productId: 155,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Ketel One Vodka",
//         master_productId: 167,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//     ],
//   },
//   {
//     productName: "Bombay Sapphire Gin",
//     options: [
//       {
//         itemName: "Bombay Dry Gin",
//         master_productId: 249,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Hendrick's Gin",
//         master_productId: 51,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Puerto de Indias Strawberry Gin",
//         master_productId: 212,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Aviation Gin",
//         master_productId: 246,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//     ],
//   },
//   {
//     productName: "Buchanan's Special Reserve Scotch Whiskey 18 year",
//     options: [
//       {
//         itemName: "Buchanan's Deluxe 12 Year Blended Scotch Whisky",
//         master_productId: 497,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Buchanan's Master Blended Scotch Whisky",
//         master_productId: 11,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "Woodford Reserve Kentucky Straight Rye Whiskey",
//         master_productId: 137,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//       {
//         itemName: "DKendall-Jackson Vintner's Reserve Pinot Noir",
//         master_productId: 515,
//         transaction_id: 275,
//         location_id: "1ee41fda-600d-4d63-b42e-8db350e3790a",
//       },
//     ],
//   },
// ];

const ImportSuggestionApproval = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);

  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationList, setAllLocationList] = useState();
  const { item } = location?.state;
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [itemData, setItemData] = useState(item);

  console.log("itemData", itemData);

  const toggleModal = () => setModal(!modal);
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  // const { id } = useParams();

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  let id = itemData[0]?.transaction_id;

  let url = `https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/import-suggestion/${id}`;

  const getProductOptions = async () => {
    setIsLoading(true);
    let response = await axios.get(url);
    console.log("response", response);
    setProductData(response?.data?.data?.data);
    setIsLoading(false);
  };

  console.log("productsData", productData);

  useEffect(() => {
    getProductOptions();
  }, []);

  const handleUpdate = async () => {
    const requestBody = {
      location_id: localStorage.getItem("selected_location_id").split("#")[1],
      transaction_id: itemData[0]?.transaction_id,
      products: [],
    };

    productData.map((product, productIndex) => {
      const importItemId = product.import_item_id;
      const optionIndex = selectedOptions[productIndex];

      if (optionIndex !== undefined && optionIndex !== "") {
        const masterProductId =
          product.suggestion[optionIndex]?.master_product_id;

        requestBody.products.push({
          import_item_id: importItemId,
          master_product_id: masterProductId,
        });
      } else {
        requestBody.products.push({
          import_item_id: importItemId,
          master_product_id: "",
        });
      }
    });

    console.log("requestBody", requestBody);
    toggleModal();
    setIsLoading(true);

    try {
      let res = await axios.post(ApiEndpoints.Suggestions, requestBody);
      console.log("RES", res);
      if (res.status === 200) {
        toast.success("Product created successfully");
        history.push("/admin/menu-import");
        setIsLoading(false);
        let action = "suggestion-product-create";
        let type = "Info";
        let message = "Suggestion product created successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log(error?.response?.data?.message, "error");
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
      let action = "suggestion-product-create";
      let type = "Error";
      let message = "Error in suggestion product creation";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleOptionChange = (productIndex, optionIndex) => {
    console.log("product select", productIndex, optionIndex);
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[productIndex] = optionIndex;
      console.log("newSelectedOptions", newSelectedOptions);
      return newSelectedOptions;
    });
  };

  console.log("selected option", selectedOptions);

  // const handleCheckboxChange = (productIndex, optionIndex) => {
  //   setSelectedOptions((prevSelectedOptions) => {
  //     const updatedOptions = { ...prevSelectedOptions };

  //     if (updatedOptions[productIndex] === optionIndex) {
  //       delete updatedOptions[productIndex];
  //     } else {
  //       updatedOptions[productIndex] = optionIndex;
  //     }

  //     return updatedOptions;
  //   });
  // };

  const getSelectedOptionsCount = () => {
    return selectedOptions.filter((optionIndex) => optionIndex !== undefined)
      .length;
  };

  const getSelectedOptions = () => {
    const selectedOptionsLength = selectedOptions.filter(
      (optionIndex) => optionIndex !== ""
    ).length;
    return selectedOptionsLength;
  };

  // const handleUpdate = () => {
  //   toggleModal();
  //   const selectedOptionCount = getSelectedOptionCount();

  //   if (selectedOptionCount === 0) {
  //     toast.error("Please select at least one option to proceed");
  //     return;
  //   }
  // };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="5">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                    >
                      {"Product Suggestions"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("product_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={toggleModal}
                      >
                        Update
                      </Button>
                    )}

                    <Modal
                      isOpen={modal}
                      toggle={toggleModal}
                      size="sm"
                      centered
                    >
                      <ModalHeader toggle={toggleModal}>
                        Confirmation
                      </ModalHeader>
                      <ModalBody style={{ padding: "0px 20px" }}>
                        {getSelectedOptions() < productData.length ? (
                          <>
                            Some products are skipped <br />
                            Are you sure you want to update?
                          </>
                        ) : (
                          "Are you sure you want to update?"
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handleUpdate}>
                          OK
                        </Button>{" "}
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col> */}
                </Row>
              </CardHeader>

              <div style={{ height: 615, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      <th>Product Name</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {productData.map((product, productIndex) => (
                      <tr key={productIndex}>
                        <td>
                          {productIndex + 1}. {product.import_item_name}
                        </td>
                        <td>
                          {product.suggestion.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <input
                                type="radio"
                                name={`product${productIndex}`}
                                id={option?.master_product_id}
                                value={option?.master_product_id}
                                onChange={() =>
                                  handleOptionChange(productIndex, optionIndex)
                                }
                                checked={
                                  selectedOptions[productIndex] === optionIndex
                                }
                                style={{ marginTop: "-6px" }}
                              />
                              <label htmlFor={`option${optionIndex}`}>
                                {option.master_product_name}
                              </label>
                            </div>
                          ))}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <input
                              type="radio"
                              name={`product${productIndex}`}
                              id={`optionNone`}
                              value=""
                              onChange={() =>
                                handleOptionChange(productIndex, "")
                              }
                              checked={selectedOptions[productIndex] === ""}
                              style={{ marginTop: "-6px" }}
                            />
                            <label htmlFor={`optionNone`}>None</label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <CardFooter>
                {/* {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )} */}
                <Row>
                  {/* <div style={{ fontSize: "13px" }}> */}
                  <Col lg="6" xs="5" style={{ fontSize: "13px" }}>
                    Selected options {getSelectedOptionsCount()} out of{" "}
                    {productData.length}
                  </Col>
                  {/* </div> */}
                  <Col className="text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("product_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={toggleModal}
                      >
                        Update
                      </Button>
                    )}

                    <Modal
                      isOpen={modal}
                      toggle={toggleModal}
                      size="sm"
                      centered
                    >
                      <ModalHeader toggle={toggleModal}>
                        Confirmation
                      </ModalHeader>
                      <ModalBody style={{ padding: "0px 20px" }}>
                        {getSelectedOptions() < productData.length ? (
                          <>
                            Some products are skipped <br />
                            Are you sure you want to update?
                          </>
                        ) : (
                          "Are you sure you want to update?"
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handleUpdate}>
                          OK
                        </Button>{" "}
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ImportSuggestionApproval;
