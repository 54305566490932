import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardFooter, Col, Container, Row } from "reactstrap";
import { Chart } from "react-google-charts";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";
import { ApiEndpoints } from "../../constants/constant";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DashBoardList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [activeUPC, setActiveUPC] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  let location_id = localStorage.getItem("selected_location_id").split("#")[1];

  const handleActiveUPC = async () => {
    let activeUPC = `${ApiEndpoints.activeUPC}?location_id=${location_id}`;

    try {
      setIsLoading(true);
      let res = await axios.get(activeUPC);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        const data = res?.data?.data[0];
        console.log("activeUPCDATA", data);
        const totalUPCValue = parseInt(data?.total_upc) || 0;
        const activeUPCValue = parseInt(data?.active_upc) || 0;
        const inactiveUPCValue = totalUPCValue - activeUPCValue;

        const chartUPCData = [
          ["Status", "Count"],
          ["Active UPC", activeUPCValue],
          ["Inactive UPC", inactiveUPCValue],
        ];

        console.log("chartUPCData", chartUPCData);

        setActiveUPC(chartUPCData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategory = async () => {
    let categoryData = `${ApiEndpoints.activeUPCCategory}?location_id=${location_id}`;

    try {
      setIsLoading(true);
      let res = await axios.get(categoryData);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        const data = res?.data?.data;
        console.log("activeCategoryDATA", data);
        // const formattedData = data.map((item) => [
        //   item.category_name || "Uncategorized",
        //   parseInt(item.total_upc),
        //   `Category: ${
        //     item.category_name || "Uncategorized"
        //   } , Total UPC: ${parseInt(item.total_upc)} , Active UPC : ${
        //     item.active_upc
        //   } , Active Percentage: ${
        //     item.active_upc
        //       ? (
        //           (parseInt(item.active_upc) / parseInt(item.total_upc)) *
        //           100
        //         ).toFixed(2) + "%"
        //       : "N/A"
        //   }`,
        // ]);

        // // Add header row
        // const chartCategoryData = [
        //   [
        //     "Category",
        //     "Total UPC",
        //     { role: "tooltip", type: "string", p: { html: true } },
        //   ],
        //   ...formattedData,
        // ];

        // console.log("chartCategoryData", chartCategoryData);

        // setCategoryData(chartCategoryData);

        const formattedData = data.map((item) => [
          item.category_name || "Uncategorized",
          parseInt(item.total_upc),
          item.total_upc, // Annotation for total UPC
          parseInt(item.active_upc),
          item.active_upc, // Annotation for active UPC
          parseInt(item.total_upc) - parseInt(item.active_upc),
          parseInt(item.total_upc) - parseInt(item.active_upc), // Annotation for inactive UPC
        ]);
        setCategoryData([
          [
            "Category",
            "Total UPC",
            { role: "annotation" }, // Annotation column
            "Active UPC",
            { role: "annotation" }, // Annotation column
            "Inactive UPC",
            { role: "annotation" },
          ],
          ...formattedData,
        ]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleActiveUPC();
    handleCategory();
  }, []);

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  const shiftSize = 7;

  const options = {
    title: "Active UPC Data",
    is3D: true,
    titleTextStyle: { fontSize: 20 },
  };

  const options2 = {
    title: "Category Data",
    chart: {
      title: "Category Data",
      subtitle: "Active and Inactive UPCs by Category",
    },
    titleTextStyle: { fontSize: 20 },
    colors: ["#23AC55", "#1E39E3", "#C81111"],
    hAxis: {
      slantedText: true, // Enable slanted text
      slantedTextAngle: -30, // Set the angle of rotation to -30 degrees
    },
    annotations: {
      textStyle: {
        fontSize: 12, // Set the font size of the annotations
      },
    },
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Dashboard"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ display: isMobile ? "" : "flex" }}>
                {/* <div style={{ flex: isMobile ? "" : 1 }}> */}
                <Chart
                  chartType="PieChart"
                  data={activeUPC}
                  options={options}
                  width={isMobile ? "500px" : "600px"}
                  height={"500px"}
                />
                {/* </div>
                <div style={{ flex: isMobile ? "" : 1 }}> */}
                <Chart
                  chartType="ColumnChart"
                  data={categoryData}
                  options={options2}
                  width={"100%"}
                  height={"500px"}
                />
                {/* </div> */}
              </div>

              <CardFooter>
                {/* {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )} */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default DashBoardList;
