import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import styles from "../../styles/importEdit.module.css";
import { logUserMetrics } from "../../../utils/file";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let productArray = [];

const dummyData = [
  {
    productName: "Product 1",
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  },
  {
    productName: "Product 2",
    options: ["Option A", "Option B", "Option C", "Option D"],
  },
  {
    productName: "Product 3",
    options: ["Red", "Blue", "Green", "Yellow"],
  },
  {
    productName: "Product 4",
    options: ["Small", "Medium", "Large", "XL"],
  },
  {
    productName: "Product 5",
    options: ["A", "B", "C", "D"],
  },
  {
    productName: "Product 6",
    options: ["Option X", "Option Y", "Option Z", "Option W"],
  },
  {
    productName: "Product 7",
    options: ["Apple", "Orange", "Banana", "Grapes"],
  },
  {
    productName: "Product 8",
    options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  },
  {
    productName: "Product 9",
    options: ["Winter", "Spring", "Summer", "Fall"],
  },
  {
    productName: "Product 10",
    options: ["Metallic", "Matte", "Glossy", "Textured"],
  },
];

const ImportTransactionListEdit = () => {
  //const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  // const { item, permission } = location?.state;
  //console.log("id", id);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");
  const [exportProducts, setExportProducts] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [productSuggModal, setProductSuggModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [errorUPCs, setErrorUPCs] = useState([
    "8248646746460",
    "87000201781",
    "88076179080",
    "80480301026",
    "88110955342",
  ]);

  const { id } = useParams();

  console.log("ididididi", id);

  const prodListModal = () => {
    setProductSuggModal(!productSuggModal);
  };

  const handleOptionChange = (productIndex, optionIndex) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [productIndex]: optionIndex,
    }));
  };

  // const getTotalOptions = (productIndex) => {
  //   return dummyData[productIndex].options.length;
  // };

  const getSelectedOptionCount = () => {
    return Object.keys(selectedOptions).length;
  };

  const [dataItem, setDataItem] = useState({});

  console.log("dataItem", dataItem);

  // console.log("itemitem", item);

  const modalToggle = () => {
    setApprovalStatus("");
    setModal(!modal);
  };

  const confirmModalToggle = () => {
    setConfirmModal(!confirmModal);
  };

  // console.log("ITEM", item);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          // Fetch data for productDetailsApi
          let productDetailsApi = `${ApiEndpoints.productListLocationImport}?location_id=${locationId}&sortBy=created_at&sortOrder=DESC`;
          let res = await axios.get(productDetailsApi);
          let response = res?.data?.data;
          console.log("responselist", response);
          let selectedItem = response.find((item) => item.id === id);
          console.log("selectedItem", selectedItem);
          setDataItem(selectedItem);
          // setLocationId(selectedItem.location_id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getImportAsJson = async () => {
      try {
        setIsLoading(true);
        let importApi = `${ApiEndpoints.jsonMenuDataImport}?transaction_id=${id}`;
        let res = await axios.get(importApi);
        console.log("TES", res);
        let response = JSON.parse(JSON.stringify(res.data.data));
        console.log("response4564646", response);
        setData(response);

        let locId = response?.[0]?.location_id;
        setLocationId(locId);
        console.log("locationID", locId);
        // checkErrorUPCs(response);
        productArray = response;
        setExportProducts(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();
    if (id) {
      getImportAsJson();
    } else {
      history.push("/admin/menu-import");
    }
  }, [id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (id) {
  //         let productDetailsApi = `${
  //           ApiEndpoints.productListLocationImport
  //         }?location_id=${locationId}&page=${1}&limit=10`;
  //         let res = await axios.get(productDetailsApi);
  //         let response = res?.data;
  //         console.log("responselist", response);
  //         let selectedItem = response.find((item) => item.id === id);
  //         console.log("selectedItem", selectedItem);
  //         setDataItem(selectedItem);
  //         // setLocationId(selectedItem.location_id);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [id]);

  // async function getImportAsJson() {
  //   try {
  //     setIsLoading(true);
  //     let importApi = `${ApiEndpoints.jsonMenuDataImport}?transaction_id=${id}`;
  //     let res = await axios.get(importApi);
  //     console.log("TES", res);
  //     let response = JSON.parse(JSON.stringify(res.data.data));
  //     console.log("response4564646", response);
  //     setData(response);

  //     let locId = response?.[0]?.location_id;
  //     setLocationId(locId);
  //     console.log("locationID", locId);
  //     // checkErrorUPCs(response);
  //     productArray = response;
  //     setExportProducts(response);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //   }
  // }

  console.log("errorUPCserrorUPCs", errorUPCs);

  // const checkErrorUPCs = (response) => {
  //   const errorUPCsList = [];
  //   const targetUPCs = [
  //     "820007764644",
  //     "8700094594594",
  //     "8809595955959",
  //     "989848949948",
  //     "85489418498498",
  //   ];

  //   response.forEach((item) => {
  //     console.log("itemsaans", item);
  //     if (targetUPCs.includes(item.upc)) {
  //       errorUPCsList.push(item.upc);
  //     }
  //   });

  //   console.log("ajksdsjabjdks", errorUPCsList);

  //   setErrorUPCs(errorUPCsList);
  // };

  console.log("expoertgayusgdyusa", exportProducts);

  // useEffect(() => {
  //   if (Object.keys(dataItem).length > 0) {
  //     getImportAsJson();
  //   } else {
  //     history.push("/admin/menu-import");
  //   }
  // }, []);

  console.log("DATA", data);

  const updateAprrovalStatus = (value) => {
    console.log("valuevalue", value);
    setApprovalStatus(value);
    if (value === "approve") {
      setConfirmModal(true);
    } else if (value === "reject") {
      setModal(true);
    } else {
      setApprovalStatus("pending");
    }
  };

  const hanldeUpdateApproval = async () => {
    if (approvalStatus === "approve") {
      console.log("Approved api call");
      let approvalApi = `${ApiEndpoints.aaprovedMenuImports}`;
      let body = {
        id: dataItem.id,
        approved: "Approved",
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          setConfirmModal(false);
          setIsLoading(false);
          setDataItem({ ...dataItem, approved: "Approved" });
          toast.success("Transaction is successfully approved");
          let action = "menu-transaction";
          let type = "Info";
          let message = "Menu Transaction approved successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
          toast.error("Error in transaction approval");
          let action = "menu-transaction";
          let type = "Error";
          let message = "Error in Menu Transaction approval.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (approvalStatus === "reject") {
      console.log("Reject api call");
      let approvalApi = `${ApiEndpoints.aaprovedMenuImports}`;
      let body = {
        id: dataItem.id,
        approved: "Rejected",
        reason: reason,
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          setModal(false);
          setReasonModalCheck(false);
          setIsLoading(false);
          setDataItem({
            ...dataItem,
            approved: "Rejected",
            reason: reason,
          });
          setReason("");
          toast.success("Transaction is successfully rejected");
          let action = "menu-transaction";
          let type = "Info";
          let message = "Menu Transaction rejected successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error in transaction approval");
        let action = "menu-transaction";
        let type = "Error";
        let message = "Error in Menu Transaction reject.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    }
  };

  // const dropdownArr = ["Pending For Approval", "Approve", "Reject"];
  // const [dropdown, setDropdown] = useState(dropdownArr);

  // const selectedValue = (value) => {
  //   let index = dropdownArr.findIndex((item) => item === value);
  //   dropdownArr.splice(index, 1);
  //   dropdownArr.unshift(value);
  //   setDropdown(dropdownArr);
  // };

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    let res = arr.filter(
      (item) =>
        (item &&
          item["upc"] &&
          item["upc"].toLowerCase().includes(str.toLowerCase())) ||
        item["name"].toLowerCase().includes(str.toLowerCase())
    );
    console.log("RESULT", res);
    return res;
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchText(string);
    console.log("STRING", string, results);

    let searchRes = handleSearch(productArray, string);
    console.log("SEra", searchRes);
    let products = [];

    for (let i = 0; i < searchRes.length; i++) {
      products.push({
        sku: searchRes[i]["sku"],
        upc: searchRes[i]["upc"],
        price: searchRes[i]["price"],
        name: searchRes[i]["name"],
        ls_qty: searchRes[i]["ls_qty"],
        status: searchRes[i]["status"],
      });
    }

    setData(products);

    var stringLength = string.length;
    if (stringLength <= 0) {
      let products = [];
      for (let i = 0; i < exportProducts.length; i++) {
        products.push({
          sku: exportProducts[i]["sku"],
          upc: exportProducts[i]["upc"],
          price: exportProducts[i]["price"],
          name: exportProducts[i]["name"],
          ls_qty: exportProducts[i]["ls_qty"],
          status: exportProducts[i]["status"],
        });
      }
      setData(products);
    }
  };

  function onClear() {
    let products = [];
    for (let i = 0; i < exportProducts.length; i++) {
      products.push({
        sku: exportProducts[i]["sku"],
        upc: exportProducts[i]["upc"],
        price: exportProducts[i]["price"],
        name: exportProducts[i]["name"],
        ls_qty: exportProducts[i]["ls_qty"],
        status: exportProducts[i]["status"],
      });
    }
    console.log("asdsad4as84", products);
    setData(products);
  }

  useEffect(() => {
    if (searchText.length === 0) {
      onClear();
    }
  }, [searchText.length]);

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  };

  const handleOnSelect = (item) => {
    const exists = data.find((p) => p.id === item.id);
    if (exists) {
      alert("Item already selected.");
      return;
    }
    setData([...data, item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleView = (data) => {
    console.log("datsadsua", data);
    const viewPath = {
      pathname: `/admin/location-menu-approval/${dataItem.id}`,
      state: {
        item: data,
        permission: "edit",
      },
    };
    history.push(viewPath);
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.push("/admin/menu-import")}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Imports"}
                    </h6>
                  </Col>
                  {/* <Col className="text-md-right" lg="6" xs="7">
                    <Button
                      color="default"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Review & Update
                    </Button>
                    <Modal
                      isOpen={productSuggModal}
                      toggle={prodListModal}
                      size="lg"
                      centered
                    >
                      <ModalHeader toggle={prodListModal}>
                        Product Options
                      </ModalHeader>
                      <ModalBody
                        style={{
                          overflowY: "auto",
                          height: "500px",
                          padding: "0px",
                        }}
                      >
                        <Table className="align-items-center table-flush">
                          <thead
                            className="thead-light"
                            style={{ position: "sticky", top: 0, zIndex: 100 }}
                          >
                            <tr>
                              <th>Product Name</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody className="list">
                            {dummyData.map((product, productIndex) => (
                              <tr key={productIndex}>
                                <td>
                                  {productIndex + 1}. {product.productName}
                                </td>
                                <td>
                                  {product.options.map(
                                    (option, optionIndex) => (
                                      <div key={optionIndex}>
                                        <Input
                                          type="radio"
                                          name={`product${productIndex}`}
                                          id={`option${optionIndex}`}
                                          value={option}
                                          onChange={() =>
                                            handleOptionChange(
                                              productIndex,
                                              optionIndex
                                            )
                                          }
                                          checked={
                                            selectedOptions[productIndex] ===
                                            optionIndex
                                          }
                                        />
                                        <Label htmlFor={`option${optionIndex}`}>
                                          {option}
                                        </Label>
                                      </div>
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </ModalBody>
                      <ModalFooter
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "13px" }}>
                          Selected options {getSelectedOptionCount()} out of{" "}
                          {dummyData.length}
                        </div>
                        <div>
                          <Button color="primary" onClick={prodListModal}>
                            Update
                          </Button>
                          <Button
                            onClick={() => {
                              prodListModal();
                              setSelectedOptions({});
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </ModalFooter>
                    </Modal>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported By
                      </label>
                      <div>{dataItem?.name}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Status
                      </label>
                      <div>{dataItem?.status}</div>
                    </FormGroup>
                  </Col>
                  {/* <Col md="4" sm="4">
                    <label
                      className="form-control-label"
                      htmlFor="example1cols1Input"
                      style={{ fontWeight: 700 }}
                    >
                      Approval Status
                    </label>
                    {dataItem?.approved !== "Approved" &&
                    dataItem?.approved !== "Rejected" ? (
                      <FormGroup>
                        <Input
                          id="exampleFormControlSelect3"
                          type="select"
                          onChange={(e) => {
                            updateAprrovalStatus(e.target.value);
                          }}
                          value={approvalStatus}
                        >
                          <option value="pending">Pending for Approval</option>
                          <option value="approve">Approve</option>
                          <option value="reject">Reject</option>
                        </Input>
                      </FormGroup>
                    ) : (
                      <div>{dataItem?.approved}</div>
                    )}
                  </Col> */}

                  {dataItem?.status === "Hold" ? (
                    <Col md="4" sm="4">
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Approval Status
                      </label>
                      <div>{dataItem?.approved}</div>
                    </Col>
                  ) : (
                    <Col md="4" sm="4">
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Approval Status
                      </label>
                      {dataItem?.approved !== "Approved" &&
                      dataItem?.approved !== "Rejected" ? (
                        <FormGroup>
                          <Input
                            id="exampleFormControlSelect3"
                            type="select"
                            onChange={(e) => {
                              updateAprrovalStatus(e.target.value);
                            }}
                            value={approvalStatus}
                          >
                            <option value="pending">
                              Pending for Approval
                            </option>
                            <option value="approve">Approve</option>
                            <option value="reject">Reject</option>
                          </Input>
                        </FormGroup>
                      ) : (
                        <div>{dataItem?.approved}</div>
                      )}
                    </Col>
                  )}

                  {/* <Col md="4" sm="4">
                    <label
                      className="form-control-label"
                      htmlFor="example1cols1Input"
                      style={{ fontWeight: 700 }}
                    >
                      Approval Status
                    </label>
                    {dataItem?.approved !== "Approved" &&
                    dataItem?.approved !== "Rejected" ? (
                      <FormGroup>
                        {dataItem?.approved === "Hold" ? (
                          <div>Awaiting User Response</div>
                        ) : (
                          <Input
                            id="exampleFormControlSelect3"
                            type="select"
                            onChange={(e) => {
                              updateAprrovalStatus(e.target.value);
                            }}
                            value={approvalStatus}
                          >
                            <option value="pending">
                              Pending for Approval
                            </option>
                            <option value="approve">Approve</option>
                            <option value="reject">Reject</option>
                          </Input>
                        )}
                      </FormGroup>
                    ) : (
                      <div>{dataItem?.approved}</div>
                    )}
                  </Col> */}

                  <Modal
                    isOpen={modal}
                    fade={false}
                    toggle={modalToggle}
                    backdrop="static"
                    centered
                  >
                    <ModalHeader toggle={modalToggle}>
                      Reason For Rejection
                    </ModalHeader>
                    <ModalBody>
                      <label>Reason</label>
                      <Input
                        type="text"
                        name="reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => reasonModalToggle()}
                        disabled={!reason ? true : false}
                      >
                        Update
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          setReason();
                          modalToggle();
                          updateAprrovalStatus("pending");
                        }}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={confirmModal}
                    fade={false}
                    toggle={confirmModalToggle}
                    size="sm"
                    centered
                    backdrop="static"
                  >
                    <ModalBody>Are you sure you want to approve?</ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => hanldeUpdateApproval()}
                      >
                        Yes
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          confirmModalToggle();
                          updateAprrovalStatus("pending");
                        }}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={reasonModalCheck}
                    fade={false}
                    toggle={reasonModalToggle}
                    size="sm"
                    centered
                    backdrop="static"
                  >
                    <ModalBody>Are you sure you want to reject?</ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => hanldeUpdateApproval()}
                      >
                        Yes
                      </Button>
                      <Button color="secondary" onClick={reasonModalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* </Row>
                <Row> */}
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported On
                      </label>
                      <div>
                        {moment(dataItem?.created_at).format("MM/DD/YYYY")}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Transaction Id
                      </label>
                      <div>{dataItem?.id}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        File Name
                      </label>
                      <div>
                        {dataItem?.file_name &&
                          dataItem.file_name.split(".xlsx")[0]}
                      </div>
                    </FormGroup>
                  </Col>
                  {dataItem?.reason ? (
                    <Col md="4" sm="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example1cols1Input"
                          style={{ fontWeight: 700 }}
                        >
                          Reason
                        </label>
                        <div>{dataItem?.reason}</div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {/* </div> */}
                </Row>
                {/* {dataItem?.status === "Hold" ? (
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <Link
                          style={{ color: "red", fontSize: "15px" }}
                          to={{
                            pathname: "/admin/location-menu-approval",
                            state: {
                              item: data,
                              permission: "edit",
                            },
                          }}
                        >
                          Some product’s UPCs have not matched please click here
                          to Review and Update
                        </Link>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null} */}

                {dataItem?.status === "Hold" ? (
                  <Row>
                    <Col md="4" sm="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example1cols1Input"
                          style={{ fontWeight: 700 }}
                        >
                          Some products UPCs do not match kindly review and
                          update
                        </label>
                        <Button
                          color="default"
                          onClick={() => handleView(data)}
                          size="sm"
                        >
                          Review & Update
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md="4">
                    <FormGroup className={styles.searchBar}>
                      <label
                        className="form-control-label"
                        htmlFor="example12cols2Input"
                        style={{ fontWeight: 700 }}
                      >
                        Search
                      </label>
                      <ReactSearchAutocomplete
                        items={data}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        placeholder="Search by Name or UPC"
                        autoFocus
                        maxResults={0}
                        showIcon={true}
                        onClear={onClear}
                        styling={{ marginTop: "0px" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Table
                    className="table"
                    bordered
                    hover
                    responsive
                    style={{ border: "1px solid #e9ecef" }}
                  >
                    <thead>
                      <tr>
                        <th>Sku</th>
                        <th>UPC</th>
                        {/* <th>Inventory Id</th>
                        <th>Location Id</th> */}
                        <th>Name</th>
                        <th>Quantity</th>
                        {/* <th>New Qty</th> */}
                        <th>Price</th>
                        {dataItem?.approved !== "Pending For Approval" && (
                          <th>Mapped Status</th>
                        )}
                        {/* <th>Status</th> */}
                        {/* {dataItem?.status === "Hold" ? <th>Error</th> : null} */}
                        {/* <th>Status</th> */}

                        {/* <th>Reason</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        //   data?.length > 0 &&
                        data.map((item, key) => (
                          <tr key={key} id={key}>
                            <td>{item.sku}</td>
                            <td>{item.upc}</td>
                            {/* <td>{item["Inventory Item ID"]}</td>
                            <td>{item["Location ID"]}</td> */}
                            <td>{item.name}</td>
                            <td>{item.ls_qty}</td>
                            {/* <td>{item["New Qty"] || item.quantity}</td> */}
                            <td>{item.price}</td>
                            {/* {errorUPCs[0]} */}
                            {/* {dataItem.status === "Completed" && errorUPCs.includes(item.upc) ? (
                              <td> Mapped</td>
                            ) : (
                              <td> Updated </td>
                            )} */}
                            {dataItem?.approved !== "Pending For Approval" && (
                              <td>
                                {item?.mapped_status?.length > 0
                                  ? item?.mapped_status
                                  : "-"}
                              </td>
                            )}
                            {/* {dataItem?.status === "Hold" && (
                              <td>
                                {errorUPCs.includes(item.upc)
                                  ? "UPC doesn't match"
                                  : ""}
                              </td>
                            )} */}
                            {/* <td>{item.status}</td> */}
                            {/* <td></td> */}
                            {/* <td>{item["Sku"]}</td> */}
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ImportTransactionListEdit;
