import React, { useState, useEffect } from "react";
import Header from "../../ui/Header.js";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ApiEndpoints } from "../../constants/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Row,
  Table,
  Badge,
  Col,
} from "reactstrap";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";
const options = [];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function NotificationShow(props) {
  const classes = useStyles();
  let { locationId } = useParams();
  let { notificationId } = useParams();
  let { item } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const token = getToken();
  const config = {
    headers: { Authorization: token },
  };
  let LocationApi = `${ApiUrls.allLocationList}?size=50`;

  const findingData = (item) => {
    let selectedLocationData = [];
    if (locationD.length > 0) {
      console.log("daraa", item);
      console.log("daraa1", locationD.length);
      for (let index = 0; index < item.length; index++) {
        const element = locationD.find((el) => el.value === item[index]);

        if (typeof element !== "undefined") {
          selectedLocationData.push(element);
        }
      }
    }
    let singledata = [];
    selectedLocationData.forEach((element) => {
      singledata.push(element.label);
    });
    return singledata.toString();
  };
  const handleView = () => {
    const viewPath = "/admin/notification";
    history.push(viewPath);
  };
  useEffect(() => {
    console.log("dddd", JSON.parse(item));
    setOriginalData(JSON.parse(item));
    setIsLoading(true);
    fetch(LocationApi, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("7777", data.data);
        const locations = data?.data;
        locations.forEach((element) => {
          const found = options.some((el) => el.label === element?.title);
          if (!found) {
            let locationId = element.location_id.split("#");
            options.push({
              label: element?.title,
              value: locationId[locationId.length - 1],
            });
          }
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setlocationD(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [item]);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Message Detail"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Messages
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table border="1" style={{ borderColor: "#e9ecef" }} responsive>
                  <tbody>
                    <tr>
                      <th>Title</th>
                      <td>{originalData.title}</td>
                    </tr>
                    <tr>
                      <th>Primary Message</th>
                      <td>{originalData.primary_message}</td>
                    </tr>
                    <tr>
                      <th>Secondary Message</th>
                      <td>{originalData.secondary_message}</td>
                    </tr>
                    <tr>
                      <th>Screen Name</th>
                      <td>
                        {originalData.screenName === "website"
                          ? "All pages"
                          : originalData.screenName}
                      </td>
                    </tr>
                    <tr>
                      <th>Is Blocking</th>
                      <td>{originalData.isBlocking}</td>
                    </tr>
                    <tr>
                      <th>Target</th>
                      <td>
                        {originalData.target === "" ||
                        typeof originalData.target === "undefined"
                          ? "All"
                          : originalData.target}
                      </td>
                    </tr>
                    <tr>
                      <th>Location</th>
                      <td>{findingData(originalData?.location)}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <Badge
                          className="badge-lg"
                          color={
                            originalData.isEnabled === "true"
                              ? "success"
                              : "danger"
                          }
                        >
                          {originalData.isEnabled === "true"
                            ? "Enabled"
                            : "Disabled"}
                        </Badge>
                      </td>
                    </tr>

                    <tr>
                      <th>Start Date</th>
                      <td>
                        {moment(originalData?.startDate, "x").format(
                          "MM/DD/YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>End Date</th>
                      <td>
                        {" "}
                        {moment(originalData?.endDate, "x").format(
                          "MM/DD/YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Created Date</th>
                      <td>{moment(originalData.ca).format("MM/DD/YYYY")}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default NotificationShow;
