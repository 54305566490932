import React, { useMemo, useEffect, useState } from "react";
import Header from "../../ui/Header.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardFooter,
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    InputGroup, InputGroupAddon, InputGroupText, Input,
    CardBody,
    Form,
    FormGroup,
    Label,
    Badge,
    UncontrolledTooltip,
  } from "reactstrap";
import { ArrowDownUp, Search } from 'react-bootstrap-icons';
import Select from "react-select";
import { ApiUrls } from "../../../common/apiconstant.js";
import { getToken } from "../../../onboarding/index.js";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Loader from "../../../onboarding/components/Loader.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
const employeesColumns = [
  {
    header: "Image",
  },
  {
    header: "Product Name",
  },
  {
    header: "Status",
  },
  {
    header: "Inventory",
  },
  {
    header: "Actions",
  }
];

function ProductDetailsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [nextKey, setNextKey] = useState(null);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [isDelete, setIsDelete] = useState(false);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [sortDirection, setSortDirection] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationList, setAllLocationList] = useState();


  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);


  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  let {id} = useParams();

  const userLocation = localStorage.getItem("location");

  let loc_id = localStorage.getItem("selected_location_id").split("#")[1];

  const getCouponsList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getCouponsApi = `${ApiEndpoints.priceOrderList}?page=1&limit=1`;
    fetch(getCouponsApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("data",res);
        const responseCoupons = res?.data;
        console.log("ReSpinse 57", responseCoupons);
        setDiscountList(responseCoupons)
        // if (responseCoupons.length > 0) {
        //   setTenantList((prevList) => [...prevList, ...responseCoupons]);

        //   if (responseCoupons.length < 10) {
        //     setLoadMoreEnabled(false);
        //   }
        // } else {
        //   setLoadMoreEnabled(false);
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("51954949", err);
      });
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getCouponsList();
  }, [page]);

  const Menu = () => {
    const handleMenuItemClick = () => {
      history.push("/admin/create-product-discount");
    };
    const handleOrderDiscount = () => {
      history.push("/admin/create-order-discount");
    };

    return (
      <div className="menu">
        <ul style={{ paddingLeft: 0 }}>
          <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleMenuItemClick()}
          >
            <div style={{ padding: 20 }}>Amount Off Products</div>
          </li>
          <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleOrderDiscount()}
          >
            <div style={{ padding: 20 }}>Amount Off Order</div>
          </li>
          {/* <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleMenuItemClick("/page")}
          >
            Buy X Get Y
          </li>
          <li
            className="menu-item"
            style={{
              cursor: "pointer",
              listStyle: "none",
              borderBottom: "1px solid #ccc",
            }}
            onClick={() => handleMenuItemClick("/page")}
          >
            Free Shipping
          </li> */}
        </ul>
      </div>
    );
  };

  const columns = useMemo(() => employeesColumns, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleAdd = () => {
    const viewPath = "/admin/discount-create";
    history.push(viewPath);
  };


  const handleView = (item) => {
    if (item.discount_type === "Product Discount") {
      const viewPath = `/admin/discount-view/${item.id}`;
      history.push(viewPath);
    } else {
      const orderDiscountPath = `/admin/order-discount/${item.id}`;
      history.push(orderDiscountPath);
    }
  };
  
  const handleEdit = (item) => {
    if (item.discount_type === "Product Discount") {
      const viewPath = `/admin/discount-edit/${item.id}`;
      history.push(viewPath);
    } else {
      const orderDiscountPath = `/admin/discount-order-edit/${item.id}`;
      history.push(orderDiscountPath);
    }
  }

const handleDelete = async (item) => {
  console.log("iyashgd",item);
  let reqBody = {
    update_by: localStorage.getItem("fullname"),
  };
  let res = await axios.delete(`${ApiEndpoints.discountsList}/${item.id}`, {
    data: reqBody,
  });
  console.log("ress", res);
  if (res.status === 200) {
    getCouponsList();
    modalToggle();
    toast.success("Discount Deleted Successfully");
  }
};




  const disableCoupon = (id) => {
    console.log("hsksa",id);
    setSelectedCoupon(id);
    modalToggle();
  }



  const getAllLocationList = async () => {
    let response = await axios.get(ApiUrls.locationList)
  console.log("gsdayhg",response);
  let locationArray = []
  const res = response?.data?.data?.Items;
   res.map((item)=>{
    return locationArray.push({label:item.title, value:item.pk}) 
   })
   setAllLocationList(locationArray);
  }
  console.log("jkasgds",allLocationList);
  
  useEffect(()=>{
    getAllLocationList();
  },[])


  const SortOptions = [
    { id: "1", text: "1", access:"id" },
    { id: "2", text: "2", access: "noOfItems" },
    { id: "3", text: "3", access: "totalPrice" },
    { id: "4", text: "4", access:"orderDate" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection('ASC');
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard((prevShowCard) => !prevShowCard);
  };

  const handleCreate = () => {
    const viewPath = "/admin/create-product";
    history.push(viewPath);
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center justify-content-between">
                  <Col lg="12" >
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"ALL PRODUCTS"}
                    </h6>
                    <div style={{display:"flex",justifyContent:"end",marginTop:"-40px",marginRight:"70px"}}>      
                    <Button onClick={handleSearchButtonClick}
                    >
                      <Search/>
                    </Button>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle className="ml-2">
                            <ArrowDownUp />
                          </DropdownToggle>
                          <DropdownMenu style={{ position: 'absolute', left:"-120px" }}>
                            
                            {SortOptions.map((option) => (
                              <DropdownItem
                              key={option.id}
                              onClick={() => handleSortOptionSelect(option.id)}
                            >
                              <input
                                type="radio"
                                name="sortOption"
                                checked={selectedSortOption === option.id}
                                onChange={() => handleSortOptionSelect(option.id)}
                              />
                              <span style={{ marginLeft: "8px" }}>{option.text}</span>
                              {selectedSortOption === option.id && (
                                <span style={{ marginLeft: "4px" }}>
                                  {sortDirection === "ASC" ? '↓' : '↑'}
                                </span>
                              )}
                            </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <Button
                        type="button"
                        color="primary"
                        onClick={handleCreate}
                        >
                        ADD NEW PRODUCT
                        </Button>
                        </div>
                        {showCard && (
                      
                      <Card>
                        
                        <CardBody>
                        <Row>
                  <Col xs={12}>
                    <FormGroup className="d-flex align-items-center">
                      <div style={{display: "flex", gap:20, width: "100%" }}>
                      <Col xs="auto">
                        <Input
                          placeholder="Search"
                          id="example-text-input"
                          type="text"
                          onChange={(e) => setSearchKeyword(e.target.value)}
                          style={{ width: "550px", height:38 }}
                          value={searchKeyword}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">                        
                      {/* </Col>
                      <Col xs={6} sm={3} className="d-flex align-items-center"> */}
                      <div className="ml-2" style={{width:"100%"}}>
                          <Select
                            placeholder="Locations"
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            value={selectedLocation}
                            name="locations"
                            options={allLocationList}
                            isMulti={true}
                            onChange={setSelectedLocation}
                            styles={{control : (baseStyles) => ({ ...baseStyles, minWidth: "100%"})}}
                          />
                        </div>
                        </Col>
                        </div>
                    </FormGroup>

                    <CardBody>
                    <h3>
                        Test
                      </h3>
                      <div style={{display: "flex" , gap: 20}}>
                      <FormGroup check inline>
                      <Input type="checkbox" />
                      {/* <Input type="checkbox" checked={statusFilter.includes("Received")} onClick={()=>selectStatus("Received")} /> */}
                      <Label check  >
                        1
                      </Label>
                    </FormGroup>
                      <FormGroup check inline>
                      <Input type="checkbox" />
                      {/* <Input type="checkbox" checked={statusFilter.includes("In Progress")} onClick={()=>selectStatus("In Progress")} /> */}
                      <Label check >
                        2
                      </Label>
                    </FormGroup>
                    <FormGroup check inline >
                    <Input type="checkbox" />
                      {/* <Input type="checkbox" checked={statusFilter.includes("Ready for delivery")} onClick={()=>selectStatus("Ready for delivery")} /> */}
                      <Label check >
                        3
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Input type="checkbox" />
                      {/* <Input type="checkbox" checked={statusFilter.includes("Ready for pickup")} onClick={()=>selectStatus("Ready for pickup")} /> */}
                      <Label check>
                        4
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Input type="checkbox" />
                      {/* <Input type="checkbox" checked={statusFilter.includes("Out for delivery")} onClick={()=>selectStatus("Out for delivery")} /> */}
                      <Label check>
                        5
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Input type="checkbox" />
                      {/* <Input type="checkbox" checked={statusFilter.includes("Picked")} onClick={()=>selectStatus("Picked")} /> */}
                      <Label check>
                        6
                      </Label>
                    </FormGroup>
                    </div>
                      </CardBody>
                      {/* <div style={{display: "flex", marginLeft:"20px",gap:"10%"}}>
                      <div >
                      
                        <h3>
                          Source
                        </h3>
                        <div style={{display:"flex",gap:"20px"}}>
                        <FormGroup check inline>
                        <Input type="checkbox" />
                        <Input
                          type="checkbox"
                          checked={sourceFilter.includes("46035795969") || sourceFilter.includes("47480569857") || sourceFilter.includes("web")}
                          onClick={() => selectSource(["46035795969", "47480569857", "web"])}
                        />
                      <Label check>
                        LS Website
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Input type="checkbox" />
                    <Input
                      type="checkbox"
                      checked={sourceFilter.includes("41306324993") || sourceFilter.includes("6919743")}
                      onClick={() => selectSource(["41306324993", "6919743"])}
                    />
                      <Label check>
                        LS Mobile App
                      </Label>
                    </FormGroup>
                    </div>
                    </div>
                      <div>
                        <h3>
                          Delivery Method
                        </h3>
                        <div style={{display:"flex",gap:"20px"}}>
                        <FormGroup check inline>
                        <Input type="checkbox" />
                      <Input type="checkbox" checked={deliveryFilter.includes("delivery")} onClick={()=>selectDelivery("delivery")} />
                      <Label check>
                        Delivery
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                    <Input type="checkbox" />
                      <Input type="checkbox" checked={deliveryFilter.includes("Local pickup")} onClick={()=>selectDelivery("Local pickup")} />
                      <Label check>
                        Local PickUp
                      </Label>
                    </FormGroup>
                    </div>
                    </div>
                      </div>  */}
                      <div style={{marginRight:"15px" , display: "flex", justifyContent: "end"}}>
                      <Button
                          color="primary"
                        //   onClick={()=>getOrderList("","","","","",1)}
                        >                          
                          Search
                        </Button>  
                       {/* {searchKeyword.length || deliveryFilter.length || sourceFilter.length || statusFilter.length || selectedLocation.length > 0 ?  */}
                       <Button
                          color="primary"
                        //   onClick={handleClear}
                        >                          
                          Clear
                        </Button>
                        {/* :null }  */}
                        </div>
                  </Col>
                </Row>

                        </CardBody>
                        
                      </Card>                      
                    )} 
                  
                  </Col>
                </Row>
              </CardHeader>
              <Table response>
                <thead className="thead-light">
                  <tr>
                    {employeesColumns.map((item) => (
                      <th scope="row">{item.header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {discountList && discountList?.length ? (
                    discountList?.map((item) => (
                      <tr>
                        <td>
                            <img 
                                src="https://cdn.shopify.com/s/files/1/0741/2023/3262/products/1800_Reposado_25650199-a9bc-46ee-8b9d-d31c112049f3.jpg?v=1687508874"
                                alt="Image Description"
                                width="50"
                                height="50"
                            />
                        </td>                      
                        <td>1800 Reposado Tequila</td>                      
                        <td>
                        <Badge
                            className="badge-lg"
                            color={item?.is_active ? "success" : "danger"}
                          >
                            {item?.is_active ? "Yes" : "No"}
                          </Badge>
                        </td>
                        <td>500 in stock for 2 variants</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              className="btn-icon-only"
                              color="info"
                              onClick={() => handleView(item)}
                              id={"tooltipv" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-eye"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipv" + item?.id}
                            >
                              View Discount
                            </UncontrolledTooltip>
                            {(userRoles.includes("1") ||
                              roleActions.includes("tenants_update")) && (
                              <>
                                <Button
                                  className="btn-icon-only "
                                  color="success"
                                  onClick={() => handleEdit(item)}
                                  id={"tooltipe" + item?.id}
                                  type="button"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-edit"></i>
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltipe" + item?.id}
                                >
                                  Edit Discount
                                </UncontrolledTooltip>
                              </>
                            )}
                             <Button
                              className="btn-icon-only"
                              color="danger"
                              onClick={() => disableCoupon(item)}
                              id={"tooltipd" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-ban"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipd" + item?.id}
                            >
                              Delete Discount
                            </UncontrolledTooltip>
                          </ButtonGroup>

                          {/* <Modal
                            isOpen={modal}
                            fade={false}
                            toggle={modalToggle}
                            size="sm"
                            centered
                          >
                            <ModalBody>
                              Are you sure you want to delete this discount?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="primary"
                                onClick={() => handleDelete(selectedCoupon)}
                              >
                                Yes
                              </Button>{" "}
                              <Button color="secondary" onClick={modalToggle}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {/* {isLoading ? (
                        <td colSpan="8">Loading...</td>
                      ) : (
                        <td>No data found...</td>
                      )} */}
                    </tr>
                  )}
                </tbody>
              </Table>
              {loadMoreEnabled && (
                <a
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  className="Link"
                  onClick={handleLoadMore}
                >
                  Load More...
                </a>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default ProductDetailsList;
