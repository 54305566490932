import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant.js";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
import { logUserMetrics } from "../../../utils/file.js";

const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function NewPermissionCreate() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [titleCreate, setTitleCreate] = useState("");
  const [modal, setModal] = useState(false);
  const history = useHistory();
  
  const [formData, setFormData] = useState({});
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [endDateData, setendDateData] = useState({});
  
    console.log("optiosn====>59",options);
   
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const auth = useSelector((state) => state.auth);
  const [permissionValue, setPermissionValue] = useState([]);
  const [notificationData, setNotificationData] = useState();
  

  

  let LocationApi = `${ApiEndpoints.locationList}?size=10`;

  const handleendDateOnChange = (date) => {
    setEndDate(date);
  };
  const create = async () => {
    // if (window.confirm("Are You Sure You want to add permission")) {
      setIsLoading(true);
      let name = localStorage.getItem("fullname");
      let sendToApi = JSON.stringify({
        permission: titleCreate,
        actions: options,
        updated_by: name,
      });
      console.log("sendToApisendToApi==>", sendToApi);
      fetch(ApiEndpoints.permissionGetURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: sendToApi,
      })
        .then((response) => response.json())
        .then(async(res) => {
          console.log("data", res);
          if (res.data !== "") {
            setIsLoading(false);
            handleView();
            let action = "permission-create"
            let type = "Info";
            let message = "Permission successfully created.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          } else {
            setIsLoading(false);
            setModal(true);
          }
        })
        .catch(async(err) => {
          console.log(err);
          handleView();
          let action = "permission-create"
          let type = "Error";
          let message = "Error in permission create.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        });
    // }
  };
  const modalToggle = () => setModal(!modal);

  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };
const titleCreateHandle = (e) =>{
  console.log("ETETETETET===>",e.target.value);
        setTitleCreate(e.target.value);
}
  const handleView = () => {
    // const viewPath = "/admin/Role/Role-Premission";
    // console.log("check 13555==>",viewPath);
    // history.push(viewPath)
    history.goBack();
  };
  useEffect(() => {});
  const openModalCheck = () => {
    console.log("titleCreatetitleCreate==>",titleCreate);
    console.log("132313113==",options);
    modalToggle()
    }
  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Add  Permission</ModalHeader>
        <ModalBody>
        
              <h2 className="fullcalendar-title h2 d-inline-block mb-0">
                Are you sure you want to add the permission <i class="fal fa-file-spreadsheet"></i>
              </h2>
              <Button color="primary" onClick={create}>
                Submit
              </Button>
              <Button color="secondary" onClick={
                modalToggle
              }>
                Cancel
              </Button>
            
         
          
        </ModalBody>

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Add Permission"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Permission
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  initialValues={{}}
                  validate={(values) => {
                    // values = { ...values, ...modifiedNonFormData, ...endDateData };
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    // if (!values.isBlocking) {
                    //     errors.isBlocking = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    // if (!values.startDate) {
                    //     errors.startDate = 'Required';
                    // }
                    // if (!values.endDate) {
                    //     errors.endDate = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // create(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Permission Title
                            </label>
                            <Input
                              placeholder="Enter Title"
                              type="text"
                              onChange={titleCreateHandle}
                              onBlur={handleBlur}
                              value={titleCreate}
                              name="title"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Create Permission&nbsp;*
                            </label>
                            {/* <Multiselect 
                      isObject={false}
                      onRemove={(event)=>{ 
                         console.log(event)
                         setPermissionValue(event)
                        } }
                      onSelect={ (event)=>{ 
                        console.log(event) 
                        setPermissionValue(event)
                    
                    }}
                      options={ options }                

                    //   showCheckbox
                      />      */}

<TagsInput
        value={options}
        onChange={setOptions}
        name="fruits"
        placeHolder="Create Permission"
      />
                            <p className="text-danger mb-0">
                              {errors.screenName &&
                                touched.screenName &&
                                errors.screenName}
                            </p>
                          </FormGroup>
                          <p></p>
                        </Col>
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={openModalCheck}
                            disabled={
                              titleCreate ==="" || options.length === 0
                                ? true
                                : false
                            }
                          >
                            Create Permission
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default NewPermissionCreate;
