import React, { useState, useEffect } from "react";

import { MultiSelect } from "react-multi-select-component";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant.js";
import { Switch } from "antd";
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();
const options = [];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function EditRole() {
  const location = useLocation();

  const [allActions, setAllActions] = useState([]);

  const { permissions, role, id, type } = location?.state.item;
  console.log("Location Role 154==>", location.state.item, "DIIDIDD==>", id);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState(permissions);
  console.log("149149149==>", action);

  const auth = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [permission, setPermission] = useState([]);
  //

  const history = useHistory();

  const handleRoleChange = (e) => {
    console.log("126126126126==>", e.target.value);
    let role = JSON.parse(JSON.stringify(originalData));
    role.role = e.target.value;

    setOriginalData(role);
  };

  const handleTypeChange = (e) => {
    let role = JSON.parse(JSON.stringify(originalData));
    role.type = e.target.value;
    setOriginalData(role);
  };

  const updateRole = async (data, setSubmitting) => {
    console.log("17171717==>", originalData.role);
    let name = localStorage.getItem("fullname");
    let sendToApi = JSON.stringify({
      id: id,
      role: originalData.role,
      type: originalData.type,
      permissions: action,
      updated_by: name,
    });
    console.log("176 role ==>", sendToApi);
    // if (window.confirm("Are You Sure You want to add role")) {
    setIsLoading(true);
    fetch(ApiEndpoints.roleGetURL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: sendToApi,
    })
      .then((response) => response.json())
      .then(async(res) => {
        console.log("194194", res);
        if (res.data !== "") {
          setIsLoading(false);
          setSubmitting(false);
          handleView();
          let action = "role-update"
            let type = "Info";
            let message = "Role successfully updated.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
          setModal(true);
        }
      })
      .catch(async(err) => {
        console.log(err);
        handleView();
        let action = "role-update"
        let type = "Error";
        let message = "Error in role update.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      });
    // }
  };
  const modalToggle = () => setModal(!modal);

  const toggler = (checked, value) => {
    console.log("130130==>", checked.target.checked, "TWO value==>", value);
    let actionOne = JSON.parse(JSON.stringify(action));
    if (checked.target.checked) {
      actionOne.push(value);
      setAction(actionOne);
    } else {
      let index = actionOne.findIndex((item) => item === value);
      actionOne.splice(index, 1);
      console.log("138138====>", index, "ACTION RODEAD==>");
      setAction(actionOne);
    }
  };
  console.log("actionsOneactionsOne=>", action);
  const handleView = () => {
    const viewPath = "/admin/Role";
    history.push(viewPath);
  };
  const call_Permission_get_api = async () => {
    let getPermissionApi = `${ApiEndpoints.permissionGetURL}?limit=50&offset=0`;
    console.log("call 158 api ==>", getPermissionApi);
    try {
      await fetch(getPermissionApi, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("RESPONSE TO GET ==>", res);
          setPermission(res.data);
          let allActions = [];

          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < res.data[i].actions.length; j++) {
              // const element = array[index];
              allActions.push(res.data[i].actions[j]);
            }
          }
          setAllActions(allActions);
        });
    } catch (error) {
      console.log("160 Error Error : ", error);
    }
  };
  useEffect(() => {
    setOriginalData(location.state.item);
    call_Permission_get_api();
    return () => {};
  }, []);
  const contains = (target, pattern) => {
    console.log("174174", target);
    let value = true;
    for (let i = 0; i < pattern.length; i++) {
      if (!target.includes(pattern[i])) {
        value = false;
      }
    }
    console.log("181==>", value);
    return value;
  };
  const selectAllActions = (e) => {
    console.log("SESEE==>", e.target.checked);
    if (e.target.checked) {
      setAction(allActions);
    } else {
      setAction([]);
    }
  };
  const selectPermission = (e, item) => {
    let tempAction = JSON.parse(JSON.stringify(action));
    if (e.target.checked) {
      for (let i = 0; i < item.actions.length; i++) {
        if (!action.includes(item.actions[i])) {
          tempAction.push(item.actions[i]);
        }
      }
    } else {
      for (let i = 0; i < item.actions.length; i++) {
        if (action.includes(item.actions[i])) {
          let index = tempAction.findIndex(
            (items) => items === item.actions[i]
          );
          tempAction.splice(index, 1);
        }
      }
    }
    setAction(tempAction);
  };
  const openModalCheck = () => {
    modalToggle();
  };
  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Edit Role</ModalHeader>
        <ModalBody>
          <h2 className="fullcalendar-title h2 d-inline-block mb-0">
            Are you sure you want to update the role{" "}
            <i class="fal fa-file-spreadsheet"></i>
          </h2>
          <Button color="primary" onClick={updateRole}>
            Submit
          </Button>
          <Button color="secondary" onClick={modalToggle}>
            Cancel
          </Button>
        </ModalBody>

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Edit Role"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Role
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  initialValues={originalData}
                  validate={(values) => {
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    // if (!values.isBlocking) {
                    //     errors.isBlocking = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }

                    // if (!values.endDate) {
                    //     errors.endDate = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // updateRole(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Edit Role Title
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Title"
                              type="text"
                              onChange={handleRoleChange}
                              onBlur={handleBlur}
                              value={originalData.role}
                              name="title"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <FormGroup>
                              <label className="form-control-label">
                                Type&nbsp;*
                              </label>
                              <Input
                                type="select"
                                name="type"
                                onChange={handleTypeChange}
                                onBlur={handleBlur}
                              >
                                <option
                                  value="LS Internal"
                                  selected={
                                    originalData.type === "LS Internal"
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  LS Internal
                                </option>
                                <option
                                  value="Customer"
                                  selected={
                                    originalData.type === "Customer"
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  Customer
                                </option>
                              </Input>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <Row>
                            <CardTitle tag="h3" className="rowHorizontal">
                              {"Permission"}
                            </CardTitle>
                          </Row>
                        </Col>
                        <Col lg="6">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <FormGroup check inline>
                              <Input
                                type="checkbox"
                                checked={contains(action, allActions)}
                                onChange={selectAllActions}
                                className="largerCheckbox"
                              />
                              <Label check>Select All</Label>
                            </FormGroup>
                          </div>
                        </Col>
                        {permission.map((item, index) => (
                          <>
                            <Col md="5" className="row-Margin ">
                              <FormGroup check inline>
                                <Input
                                  type="checkbox"
                                  checked={contains(action, item.actions)}
                                  onChange={(e) => selectPermission(e, item)}
                                  className="largerCheckbox"
                                />
                              </FormGroup>
                              <label
                                className="form-control-label textCapitalize"
                                style={{
                                  fontSize: 18,
                                }}
                              >
                                {item.permission}&nbsp;
                              </label>
                              &nbsp;
                              <div
                                style={{
                                  // display: "flex",
                                  flexDirection: "column",
                                  // backgroundColor:"yellow"
                                }}
                              >
                                {item.actions.map((items, index) => (
                                  <div
                                    style={{
                                      marginLeft: "1rem",
                                      // alignSelf: "center",
                                      // justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      display: "flex",
                                    }}
                                  >
                                    <FormGroup check inline>
                                      <Input
                                        className="largerCheckbox"
                                        type="checkbox"
                                        checked={action.includes(items)}
                                        onChange={(checked) => {
                                          toggler(checked, items);
                                        }}
                                      />
                                    </FormGroup>
                                    {/* <Switch 
                                                             checked={action.includes(items)}
                                                            className='' style={{
                                                                marginRight:20
                                                            }}
                                                            onClick={(checked)=>{
                                                                    toggler(checked,items);
                                                            }} 
                                                            /> */}
                                    <div className="mt-1">
                                      <Label className="textCapitalize">
                                        {items.replace("_", " ")}
                                      </Label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          </>
                        ))}
                        {/* <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">User&nbsp;</label>&nbsp;
                                                        
                                                        <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch 
                                                 checked={action.includes("create_user")}
                                                className='' style={{
                                                    marginLeft:20
                                                }}
                                                onClick={(checked)=>{
                                                        toggler(checked,"create_user");
                                                }}
                                                />
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch
                                                checked={action.includes("create_role")}
                                                style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch 
                                                 checked={action.includes("create_update")}
                                                style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch 
                                                 checked={action.includes("create_delete")}
                                                style={{
                                                    marginLeft:5
                                                }}/>
                                                </div>
                                                </Row>
                                                    </FormGroup>
                                                </Col>
                                           
                                               
                                                <Col lg="6">
                                                <label className="form-control-label">Role&nbsp;</label>&nbsp;
                                                        
                                                <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                </Row>
                                                </Col>

                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">Customer&nbsp;</label>&nbsp;
                                                        
                                                        <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch className='' style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch style={{
                                                    marginLeft:15
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch style={{
                                                    marginLeft:5
                                                }}/>
                                                </div>
                                                </Row>
                                                    </FormGroup>
                                                </Col> */}

                        {/* <Col lg="6">
                                                <label className="form-control-label">Dashboard&nbsp;</label>&nbsp;
                                                        
                                                <Row md="2" className="rowHorizontal">
                                                    <div>
                                                <Label>{"Create"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Read"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Update"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                <div>
                                                <Label>{"Delete"}</Label>
                                                <Switch style={{
                                                    marginLeft:20
                                                }}/>
                                                </div>
                                                </Row>
                                                </Col> */}
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={openModalCheck}
                            disabled={
                              originalData.role === "" ||
                              permission.length === 0
                                ? true
                                : false
                            }
                          >
                            Update Role
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default EditRole;
