import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/shopifyProductsList.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const headerData = [
  // {
  //   header: "Order No",
  // },
  // {
  //   header: "Customer Id",
  // },
  {
    header: "S No.",
  },
  {
    header: "Name",
  },
  {
    header: "E-mail",
  },
  {
    header: "Order E-mail",
  },
  {
    header: "Phone Number",
  },
  // {
  //   header: "No of attempts",
  // },
  {
    header: "Delivery Address",
  },
  {
    header: "Billing Address",
  },
  {
    header: "Delivery Contact Name",
  },
  {
    header: "Active",
  },
  {
    header: "Actions",
  },
];

// const headerData = [
//   {
//     header: "Image",
//   },
//   {
//     header: "Title",
//   },
//   {
//     header: "No Of Variants",
//   },
//   {
//     header: "Status",
//   },
//   {
//     header: "Actions",
//   },
// ];

const blacklistData = [
  {
    CustomerId: "12",
    Name: "Adam",
    Email: "adam@email.com",
    PhoneNumber: "1234567890",
    Address: "315th ave Miami Florida 33130",
  },
  {
    CustomerId: "23",
    Name: "Eve",
    Email: "eve@email.com",
    PhoneNumber: "9876543210",
    Address: "42nd St New York, NY 10001",
  },
  {
    CustomerId: "34",
    Name: "Charlie",
    Email: "charlie@email.com",
    PhoneNumber: "5555555555",
    Address: "Broadway St Los Angeles, CA 90001",
  },
  {
    CustomerId: "45",
    Name: "David",
    Email: "david@email.com",
    PhoneNumber: "2223334444",
    Address: "King St Seattle, WA 98101",
  },
  {
    CustomerId: "56",
    Name: "Fiona",
    Email: "fiona@email.com",
    PhoneNumber: "6667778888",
    Address: "Ocean Dr Miami Beach, FL 33139",
  },
  {
    CustomerId: "67",
    Name: "Grace",
    Email: "grace@email.com",
    PhoneNumber: "9990001111",
    Address: "Main St San Francisco, CA 94105",
  },
  {
    CustomerId: "78",
    Name: "Hank",
    Email: "hank@email.com",
    PhoneNumber: "4444444444",
    Address: "Pine St Boston, MA 02108",
  },
];

const BlackListUsersList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);

  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationList, setAllLocationList] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  const SortOptions = [
    { id: "name", text: "Name" },
    { id: "created_at", text: "Date" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const getBlackListUser = (pageNum) => {
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        searchKeyword: searchKeyword,
      }),
    };
    setIsLoading(true);
    let blacklistAPI = `${ApiEndpoints.blackListUserList}?limit=10&page=${pageNum}`;

    if (selectedSortOption) {
      blacklistAPI =
        blacklistAPI +
        `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (selectedStatus !== "All") {
      blacklistAPI =
        blacklistAPI +
        `&is_active=${selectedStatus === "Active" ? "true" : "false"}`;
    }

    fetch(blacklistAPI, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("BLACKLIST DATA", res);
        const responseData = res?.data;
        console.log("125639", responseData);
        // if(page>1){
        //   setLocationData((prevList) =>[...prevList, ...responseData]);
        // } else{
        //   setLocationData(responseData);
        // }

        if (responseData.length > 0) {
          const updatedBlackList =
            pageNum === 1 ? responseData : [...userData, ...responseData];
          const mergedBlackList = mergeAndSortBlackList(updatedBlackList);
          setUserData(mergedBlackList);

          if (responseData.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setUserData(responseData);
          }
          if (responseData.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedSortOption) {
      getBlackListUser(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getBlackListUser(pageNum);
  };

  useEffect(() => {
    if (selectedSortOption) {
      getBlackListUser(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getBlackListUser(page);
    } else if (searchKeyword === "") {
      getBlackListUser(page);
    }
  }, [searchKeyword]);

  const mergeAndSortBlackList = (mergedBlackList) => {
    if (selectedSortOption) {
      mergedBlackList.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedBlackList;
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });
      setSelectedStatus(value);
    }
  };

  let btns = ["All", "Active", "Non-Active"];

  const handleCreate = () => {
    history.push("/admin/blacklist-create");
  };

  const handleEdit = (item) => {
    history.push(`/admin/blacklist-edit/${item.id}`);
  };

  const handleView = (item) => {
    history.push(`/admin/blacklist-view/${item.id}`);
  };

  const handleDelete = async (item) => {
    console.log("itemsss", item);
    setIsLoading(true);
    if (item.is_active) {
      toast.success("BlackList disabled successfully");
    } else {
      toast.success("BlackList enabled successfully");
    }
    let body = {
      id: item.id,
      is_active: !item.is_active,
      updated_by: localStorage.getItem("fullname"),
    };
    console.log("ITEM", body);
    try {
      const res = await axios.put(ApiEndpoints.blackListUserDelete, body);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setPage(1);
        getBlackListUser(1);
        setDeleteConfirmModal(false);
        setIsLoading(false);
        // toast.success("BlackList disabled successfully");
        let action = "blacklist-delete";
        let type = "Info";
        let message = "BlackList disabled successfully";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log("ERR", error);
      setIsLoading(false);
      let action = "blacklist-delete";
      let type = "Error";
      let message = "Error in blacklist disabled";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getBlackListUser(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Blacklist"}
                    </h6>
                  </Col>
                  <Col className="text-md-right" lg="6" xs="6">
                    {(userRoles.includes("1") ||
                      roleActions.includes("blacklist_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={handleCreate}
                      >
                        Add BlackList
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className={styles.productList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getBlackListUser}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search"
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getBlackListUser}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search"
                    dropDown={false}
                    orderList={false}
                    // fcm={true}
                    // fcmSearchBtn={fcmSearchBtn}
                    // fcmInputSearch={fcmInputSearch}
                    search={search}
                  />
                )}
              </CardBody>
              <div style={{ height: "550px", overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item, index) => (
                        <th key={index} scope="col">
                          {item.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {userData && userData.length ? (
                      userData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          {/* <td>{item.order_number}</td>
                          <td>{item.customer_id}</td> */}
                          <td>{item?.name?.length > 0 ? item?.name : "-"}</td>
                          <td>{item?.email?.length > 0 ? item?.email : "-"}</td>
                          <td>
                            {item?.order_email?.length > 0
                              ? item?.order_email
                              : "-"}
                          </td>
                          <td>{item?.phone?.length > 0 ? item?.phone : "-"}</td>
                          {/* <td>
                            {item?.attempts !== null ? item?.attempts : "-"}
                          </td> */}
                          <td>
                            {item?.address?.length > 0 ? item?.address : "-"}
                          </td>
                          <td>
                            {item?.billing_address?.length > 0
                              ? item?.billing_address
                              : "-"}
                          </td>
                          <td>
                            {item?.delivery_address_name?.length > 0
                              ? item?.delivery_address_name
                              : "-"}
                          </td>
                          <td>{item?.is_active ? "Yes" : "No"}</td>
                          <td>
                            <ButtonGroup>
                              <Button
                                className="btn-icon-only"
                                color="info"
                                onClick={() => handleView(item)}
                                id={"tooltipv" + item?.id}
                                type="button"
                              >
                                <span className="btn-inner--icon">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltipv" + item?.id}
                              >
                                View Entry
                              </UncontrolledTooltip>
                              {/* {(userRoles.includes("1") ||
                                roleActions.includes("blacklist_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only left-Padding"
                                    color="success"
                                    onClick={() => handleEdit(item)}
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit User
                                  </UncontrolledTooltip>
                                </>
                              )} */}
                              {(userRoles.includes("1") ||
                                roleActions.includes("blacklist_delete")) && (
                                <>
                                  <Button
                                    className="btn-icon-only"
                                    color="danger"
                                    onClick={() => {
                                      setDeleteConfirmModal(true);
                                      setDeleteUserData(item);
                                    }}
                                    id={"tooltipd" + item?.id}
                                    type="button"
                                    disabled={item.is_active == false}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-ban"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipd" + item?.id}
                                  >
                                    Disable Entry
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </ButtonGroup>
                            <Modal
                              isOpen={deleteConfirmModal}
                              fade={false}
                              toggle={deleteConfirmToggle}
                              size="sm"
                              centered
                              backdrop="static"
                            >
                              <ModalBody>
                                Are you sure you want to disable this entry?
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={() => handleDelete(deleteUserData)}
                                >
                                  Yes
                                </Button>
                                <Button
                                  color="secondary"
                                  onClick={deleteConfirmToggle}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="6">Loading...</td>
                        ) : (
                          <td colSpan="6">No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              {/* <div style={{ height: 550, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item) => (
                        <th scope="col">{item.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {productData && productData?.length ? (
                      productData?.map((item) => (
                        <tr>
                          <td>
                            {item.images && item.images.length > 0 ? (
                              <img
                                src={item.images[0].src}
                                alt="Image Description"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            ) : (
                              <img
                                src={Logo}
                                alt="Placeholder Image"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            )}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.variants.length}</td>
                          <td>
                            {item.status && (
                              <span>
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1).toLowerCase()}
                              </span>
                            )}
                          </td>                         

                          <td>
                            <ButtonGroup>                            
                              {(userRoles.includes("1") ||
                                roleActions.includes("product_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only "
                                    color="success"
                                    onClick={() =>
                                      history.push(
                                        `/admin/products/edit/${item.id}`,
                                        {
                                          item: item,
                                          permission: "edit",
                                        }
                                      )
                                    }
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit Product
                                  </UncontrolledTooltip>
                                </>
                              )}                              
                            </ButtonGroup>
                           
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div> */}
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BlackListUsersList;
