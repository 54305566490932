import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../onboarding/components/Loader";
import moment from "moment";
import { logUserMetrics } from "../../../utils/file";
import { ApiUrls } from "../../../common/apiconstant";
const ProductDetails = () => {
  //const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { item, permission } = location?.state;
  //console.log("id", id);
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");
  const [locations, setLocations] = useState([]);
  const [productData, setProductData] = useState({});
  const [dataItem, setDataItem] = useState(item);

  const { id } = useParams();

  const getTransactionData = () => {
    setIsLoading(true);
    fetch(`${ApiEndpoints.productTransaction}/${id}`)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("res", res);
        const responseProductData = res;
        console.log("Response Product Data", res);
        setProductData(responseProductData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);
      });
  };

  useEffect(() => {
    getTransactionData();
  }, []);

  const locationList = () => {
    fetch(`${ApiUrls.locationList}`)
      .then((response) => response.json())
      .then((data) => {
        const locations = data?.data.Items;
        console.log("locations", locations);
        setLocations(locations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    locationList();
  }, []);

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="12">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Product Details"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                      >
                        Product Title
                      </Label>
                      <div>{productData?.data?.product_title}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                      >
                        Status
                      </Label>
                      <div>{productData?.data?.status}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Created
                      </Label>
                      <div>
                        {moment(productData?.data?.created_at).format(
                          "MM/DD/YY"
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Created 
                      </Label>
                      <div>
                      <div>{productData?.data?.created_at}</div>
                      </div>
                    </FormGroup>
                  </Col>              
                </Row> */}
              </CardBody>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Serial No</th>
                    <th>Uploaded At</th>
                    <th>Location Title</th>
                    <th>Product Name</th>
                    <th>Status</th>
                    {productData?.data?.transationItems?.some(
                      (item) => item.error !== null
                    ) && <th>Error</th>}
                  </tr>
                </thead>
                <tbody className="list">
                  {productData.data &&
                    productData.data.transationItems.map(
                      (transactionItem, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {moment(transactionItem.created_at).format(
                              "MM/DD/YY"
                            )}
                          </td>
                          <td>{transactionItem.location_title}</td>
                          <td>{transactionItem.handle}</td>
                          <td>
                            {transactionItem.status === "Fail" && (
                              <span>Fail</span>
                            )}
                            {transactionItem.status === "Completed" && (
                              <span>Created</span>
                            )}
                            {transactionItem.status === "Processing" && (
                              <span>Processing</span>
                            )}
                          </td>
                          {transactionItem.error !== null && (
                            <td>{transactionItem.error}</td>
                          )}
                        </tr>
                      )
                    )}
                </tbody>
                {/* <thead className="thead-light">
                      <tr>
                        <th>Locations Name</th>
                        <th>Status</th>
                        <th>
                        {locationData.some(location => location.error) && <th>Error</th>}
                        </th>
                        <th></th>                                             
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                    {locationData.map((location) => (
                    <tr key={location.id}>
                        <td>{location.title}</td>
                        <td>{location.status}</td>
                        <td>
                            {location.error ? (
                                <td>{location.error}
                                <Button
                                    color="info" 
                                    style={{marginLeft:"20%"}}>
                                   Retry
                                </Button>
                            </td>
                            ) : (
                                null
                            )}
                        </td>
                    </tr>
                    ))}
                    </tbody> */}
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default ProductDetails;
