import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

//enter your web app configuration options
// const firebaseConfig = {
//   apiKey: "AIzaSyAOWHBpPhKoNhcGFKHH_Q_0AtL2gV-imgQ",
//   authDomain: "production-a9404.firebaseapp.com",
//   databaseURL: "https://production-a9404.firebaseio.com",
//   projectId: "production-a9404",
//   storageBucket: "production-a9404.appspot.com",
//   messagingSenderId: "525472070731",
//   appId: "1:525472070731:web:c757787f10140b9bba61ce",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBoQ0nIqJlG125BGiYJcmZK8bZAwkfgsNc",
  authDomain: "liquorsplitlive.firebaseapp.com",
  databaseURL: "https://liquorsplitlive-default-rtdb.firebaseio.com",
  projectId: "liquorsplitlive",
  storageBucket: "liquorsplitlive.appspot.com",
  messagingSenderId: "75080422403",
  appId: "1:75080422403:web:3cfdc0e08b56a7a95a2a36",
  measurementId: "G-C9Y1FB25VD",
};

const firebaseConfigFcm = {
  apiKey: "AIzaSyAs-hVjl7HFflCrr9QKlM0hSIbgKhjA5Oc",
  authDomain: "liquorsplit-89663.firebaseapp.com",
  databaseURL: "https://liquorsplit-89663.firebaseio.com",
  projectId: "liquorsplit-89663",
  storageBucket: "liquorsplit-89663.appspot.com",
  messagingSenderId: "105035049945",
  appId: "1:105035049945:web:e1aedd5ce5005b10cd07c8",
  measurementId: "G-FBYSY5RMDX",
};

let app1;
//if (!firebase.apps.length) {
//firebase.initializeApp(firebaseConfig);
// app1 = initializeApp(firebaseConfigFcm);
app1 = initializeApp(firebaseConfig);

const database = getDatabase(app1);
const auth = getAuth();
//}
//export const messaging = firebase.messaging(app1);
// app1 = firebase.initializeApp(firebaseConfigFcm);

export { app1, database, auth };
