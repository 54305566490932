import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import styles from "../styles/SearchBar.module.css";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import Select from "react-select";

const SearchBar = ({
  showCard,
  selectStatus,
  handleSearchButtonClick,
  getDetail,
  SortOptions,
  handleSortOptionSelect,
  selectedSortOption,
  setSearchKeyword,
  handleClear,
  handleCancelClick,
  selectedStatus,
  sortDirection,
  statusFilter,
  searchKeyword,
  dropdownOpen,
  toggleDropdown,
  btns,
  placeHolderText,
  allLocationList,
  selectedLocation,
  setSelectedLocation,
  sourceDropDown,
  sourceOptions,
  handleSourceOptions,
  selectedSource,
  toggleSourceDropDown,
  deliveryMethodDropDown,
  deliveryMethodOptions,
  selectedDeliveryMethod,
  handleDeliveryMethodOptions,
  toggleDeliveryMethodDropDown,
  orderTypeDropDown,
  toggleOrderTypeDropDown,
  selectedOrderType,
  orderTypeOptions,
  handleOrderTypeOptions,
  dropDown,
  orderList,
  locationDropDown,
  transactionListDropDown,
  reactTags,
  tags,
  suggestions,
  onDelete,
  onAddition,
  setSuggestions,
  customerId,
  setTenantList,
  tenantList,
  fcm,
  fcmSearchBtn,
  fcmInputSearch,
  search,
  screenName,
  setIsChecked,
  isChecked,
}) => {
  return (
    <CardBody>
      <div className={styles.searchBaHeader}>
        {showCard ? (
          <div className={styles.searchBarContents}>
            <div className={styles.searchBarButtons}>
              <ButtonGroup aria-label="Basic examples">
                {btns.map((item) => (
                  <Button
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedStatus == item ? "#e6e6e6" : "initial",
                    }}
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={() => selectStatus(item)}
                  >
                    {item}
                  </Button>
                ))}
              </ButtonGroup>
              <div className={styles.searchBarSearchButtons}>
                <ButtonGroup aria-label="Basic examples">
                  {!showCard ? (
                    <Button size="sm" onClick={handleSearchButtonClick}>
                      <Search />
                    </Button>
                  ) : null}
                  {orderList ? (
                    <Button
                      size="sm"
                      id="copy-tooltip"
                      onClick={() => {
                        getDetail("", "", "", "", "", 1);
                      }}
                    >
                      <ArrowClockwise />
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      id="copy-tooltip"
                      onClick={() => {
                        getDetail(1);
                      }}
                    >
                      <ArrowClockwise />
                    </Button>
                  )}
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      className={`ml-2 btn-sm ${styles.serachBarBackGroundColor}`}
                    >
                      <ArrowDownUp />
                    </DropdownToggle>
                    <DropdownMenu className={styles.searchBardropDownMenu}>
                      {SortOptions.map((option) => (
                        <DropdownItem
                          key={option.id}
                          onClick={() => handleSortOptionSelect(option.id)}
                        >
                          <div className={styles.searchBarDropDown}>
                            <input
                              type="radio"
                              name="sortOption"
                              checked={selectedSortOption === option.id}
                              onChange={() => handleSortOptionSelect(option.id)}
                            />
                            <span className={styles.searchBarDropDownOptions}>
                              {option.text}
                            </span>
                            {selectedSortOption === option.id && (
                              <span
                                className={styles.searchBarDropDownOptions}
                                style={{ marginTop: "-3px" }}
                              >
                                {sortDirection === "ASC" ? "↓" : "↑"}
                              </span>
                            )}
                          </div>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </ButtonGroup>
              </div>
            </div>
            <div className={styles.searchBarSearch}>
              {orderList ? (
                <Input
                  className={styles.searchBarSearchInput}
                  // id="example-text-input"
                  type="text"
                  placeHolder={placeHolderText}
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      getDetail("", "", "", "", "", 1);
                    }
                  }}
                  autoFocus={showCard}
                />
              ) : fcm ? (
                <Input
                  className={styles.searchBarSearchInput}
                  // id="example-text-input"
                  type="text"
                  placeHolder={placeHolderText}
                  value={search}
                  onChange={(e) => fcmInputSearch(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") {
                  //     getDetail(1);
                  //   }
                  // }}
                  autoFocus={showCard}
                />
              ) : (
                <Input
                  className={styles.searchBarSearchInput}
                  // id="example-text-input"
                  type="text"
                  placeHolder={placeHolderText}
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      getDetail(1);
                    }
                  }}
                  autoFocus={showCard}
                />
              )}
              <ButtonGroup aria-label="Basic examples">
                {/* {searchKeyword.length || statusFilter.length > 0 ? (
                  <Button
                    style={{ cursor: "pointer" }}
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                ) : null} */}
                {orderList ? (
                  <div className={styles.searchBarClearSearch}>
                    {searchKeyword.length ||
                      selectedDeliveryMethod.length ||
                      selectedSource.length ||
                      selectedLocation.length > 0 ? (
                      <Button
                        className={styles.searchBarCursor}
                        color="secondary"
                        type="button"
                        size="sm"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        getDetail("", "", "", "", "", 1);
                      }}
                      classname={styles.searchbarcursor}
                      color="secondary"
                      type="button"
                      size="sm"
                    >
                      Search
                    </Button>
                  </div>
                ) : fcm ? (
                  <div className={styles.searchBarClearSearch}>
                    {searchKeyword.length > 0 ? (
                      <Button
                        classname={styles.searchbarcursor}
                        color="secondary"
                        type="button"
                        size="sm"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        fcmSearchBtn();
                      }}
                      classname={styles.searchbarcursor}
                      color="secondary"
                      type="button"
                      size="sm"
                    >
                      Search
                    </Button>
                  </div>
                ) : (
                  <div className={styles.searchBarClearSearch}>
                    {searchKeyword.length > 0 ? (
                      <Button
                        classname={styles.searchbarcursor}
                        color="secondary"
                        type="button"
                        size="sm"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        getDetail(1);
                      }}
                      classname={styles.searchbarcursor}
                      color="secondary"
                      type="button"
                      size="sm"
                    >
                      Search
                    </Button>
                  </div>
                )}
                <Button
                  onClick={handleCancelClick}
                  classname={styles.searchbarcursor}
                  color="secondary"
                  type="button"
                  size="sm"
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </div>{" "}
            {dropDown && (
              <div className={styles.searchBarOrderListDropDown}>
                {/* <div> */}
                <Select
                  placeholder="Locations"
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={selectedLocation}
                  name="locations"
                  options={allLocationList}
                  isMulti={true}
                  onChange={setSelectedLocation}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "auto",
                      minHeight: "35px",
                      width: "auto",
                      minWidth: "30%",
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      fontSize: 15,
                      color: "#adb5bd",
                    }),
                    menuList: (styles) => ({
                      ...styles,
                      fontSize: 14,
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      fontSize: 15,
                    }),
                  }}
                />
                {/* </div> */}
                <div className={styles.searchBarMethods}>
                  <Dropdown
                    isOpen={sourceDropDown}
                    toggle={toggleSourceDropDown}
                  >
                    <DropdownToggle
                      className={`ml-2 btn-sm ${styles.serachBarBackGroundColor}`}
                      size="lg"
                      caret
                    >
                      Source
                    </DropdownToggle>
                    <DropdownMenu>
                      {sourceOptions.map((sourceOption) => (
                        <DropdownItem
                          className={styles.searchBarOrdersOptions}
                          key={sourceOption.id}
                          onClick={() => handleSourceOptions(sourceOption.id)}
                        >
                          <div className={styles.searchBarDropDown}>
                            <input
                              type="checkbox"
                              name="sourceOption"
                              checked={selectedSource.includes(sourceOption.id)}
                              onClick={() =>
                                handleSourceOptions(sourceOption.id)
                              }
                            />
                            <span className={styles.searchBarDropDownOptions}>
                              {sourceOption.text}
                            </span>
                          </div>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  <Dropdown
                    isOpen={deliveryMethodDropDown}
                    toggle={toggleDeliveryMethodDropDown}
                  >
                    <DropdownToggle
                      className={`ml-2 btn-sm ${styles.serachBarBackGroundColor}`}
                      size="lg"
                      caret
                    >
                      Delivery Method
                    </DropdownToggle>
                    <DropdownMenu>
                      {deliveryMethodOptions.map((deliveryOption) => (
                        <DropdownItem
                          className={styles.searchBarOrdersOptions}
                          key={deliveryOption.id}
                          onClick={() =>
                            handleDeliveryMethodOptions(deliveryOption.id)
                          }
                        >
                          <div className={styles.searchBarDropDown}>
                            <input
                              type="checkbox"
                              name="deliveryOption"
                              checked={selectedDeliveryMethod.includes(
                                deliveryOption.id
                              )}
                              onClick={() =>
                                handleDeliveryMethodOptions(deliveryOption.id)
                              }
                            />
                            <span className={styles.searchBarDropDownOptions}>
                              {deliveryOption.text}
                            </span>
                          </div>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  {/* <Dropdown
                    isOpen={orderTypeDropDown}
                    toggle={toggleOrderTypeDropDown}
                  >
                    <DropdownToggle
                      className={`ml-2 btn-sm ${styles.serachBarBackGroundColor}`}
                      size="lg"
                      caret
                    >
                      Order Type
                    </DropdownToggle>
                    <DropdownMenu>
                      {orderTypeOptions.map((orderType) => (
                        <DropdownItem
                          className={styles.searchBarOrdersOptions}
                          key={orderType.id}
                          onClick={() => handleOrderTypeOptions(orderType.id)}
                        >
                          <div className={styles.searchBarDropDown}>
                            <input
                              type="checkbox"
                              name="orderType"
                              checked={selectedOrderType.includes(orderType.id)}
                              onClick={() =>
                                handleOrderTypeOptions(orderType.id)
                              }
                            />
                            <span className={styles.searchBarDropDownOptions}>
                              {orderType.text}
                            </span>
                          </div>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown> */}

                  <div>
                    {orderTypeOptions.map((orderType) => (
                      <div
                        key={orderType.id}
                        className={styles.searchBarTestDropDown}
                      >
                        <Input
                          type="checkbox"
                          id={`orderType_${orderType.id}`}
                          name="orderType"
                          checked={selectedOrderType.includes(orderType.id)}
                          onChange={() => handleOrderTypeOptions(orderType.id)}
                        />
                        <Label
                          className={styles.searchBarTestDropDownLabel}
                          htmlFor={`orderType_${orderType.id}`}
                        >
                          {orderType.text}
                        </Label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {transactionListDropDown && (
              <div className={styles.searchBarTransactionDropDown}>
                <Dropdown isOpen={sourceDropDown} toggle={toggleSourceDropDown}>
                  <DropdownToggle
                    className={`ml-2 btn-sm ${styles.serachBarBackGroundColor}`}
                    size="lg"
                    caret
                  >
                    Processing Status
                  </DropdownToggle>
                  <DropdownMenu>
                    {sourceOptions.map((sourceOption) => (
                      <DropdownItem
                        className={styles.searchBartransactionOptions}
                        key={sourceOption.id}
                        onClick={() => handleSourceOptions(sourceOption.id)}
                      >
                        <div className={styles.searchBarDropDown}>
                          <input
                            type="checkbox"
                            name="sourceOption"
                            checked={selectedSource.includes(sourceOption.id)}
                            onClick={() => handleSourceOptions(sourceOption.id)}
                          />
                          <span className={styles.searchBarDropDownOptions}>
                            {sourceOption.text}
                          </span>
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {locationDropDown && (
              <div className={styles.searchBarLocationDropDown}>
                <Select
                  placeholder="Search by Tenant"
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  value={tenantList}
                  name="tenants"
                  options={suggestions}
                  isMulti={true}
                  onChange={setTenantList}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      // border: "1px solid #9d9d9d",
                      // borderRadius:5,
                      height: "auto",
                      minHeight: "45px",
                      width: "auto",
                      minWidth: "30%",
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      fontSize: 15,
                      color: "#adb5bd",
                    }),
                    menuList: (styles) => ({
                      ...styles,
                      fontSize: 14,
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      fontSize: 15,
                    }),
                  }}
                />
              </div>
            )}
            {screenName === "Transaction" && (
              <div
                style={{
                  height: "3rem",
                  width: "10rem",
                  marginLeft: "30px",
                  marginTop: "10px",
                }}
              >
                <ButtonGroup>
                  <Input
                    name="Erroneous Transaction"
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)} // Toggle the state when the checkbox is clicked
                  />

                  <Label
                    className={styles.searchBarTestDropDownLabel}
                  // htmlFor={`orderType_${orderType.id}`}
                  >
                    Erroneous Transaction
                  </Label>
                </ButtonGroup>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.searchBarCardHeader}>
            <div className={styles.searchBarButtons}>
              <ButtonGroup aria-label="Basic examples">
                {btns.map((item) => (
                  <Button
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedStatus == item ? "#e6e6e6" : "initial",
                    }}
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={() => selectStatus(item)}
                  >
                    {item}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <div className={styles.searchBarShowCard}>
              <ButtonGroup aria-label="Basic examples">
                {!showCard ? (
                  <Button size="sm" onClick={handleSearchButtonClick}>
                    <Search />
                  </Button>
                ) : null}
                {orderList ? (
                  <Button
                    size="sm"
                    id="copy-tooltip"
                    onClick={() => {
                      getDetail("", "", "", "", "", 1);
                    }}
                  >
                    <ArrowClockwise />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    id="copy-tooltip"
                    onClick={() => {
                      getDetail(1);
                    }}
                  >
                    <ArrowClockwise />
                  </Button>
                )}
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className={`ml-2 btn-sm ${styles.serachBarBackGroundColor}`}
                  >
                    <ArrowDownUp />
                  </DropdownToggle>

                  <DropdownMenu className={styles.searchBardropDownMenu}>
                    {SortOptions.map((option) => (
                      <DropdownItem
                        key={option.id}
                        onClick={() => handleSortOptionSelect(option.id)}
                      >
                        <div className={styles.searchBarDropDown}>
                          <input
                            type="radio"
                            name="sortOption"
                            checked={selectedSortOption === option.id}
                            onChange={() => handleSortOptionSelect(option.id)}
                          />
                          <span className={styles.searchBarDropDownOptions}>
                            {option.text}
                          </span>
                          {selectedSortOption === option.id && (
                            <span
                              className={styles.searchBarDropDownOptions}
                              style={{ marginTop: "-3px" }}
                            >
                              {sortDirection === "ASC" ? "↓" : "↑"}
                            </span>
                          )}
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </ButtonGroup>
            </div>
          </div>
        )}
      </div>
    </CardBody>
  );
};

export default SearchBar;
