import axios from "axios";
import setAuthToken from "../../../onboarding/setAuthToken";
import jwt_decode from "jwt-decode";
import { auth, firebase } from "../../../admin/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { SET_CURRENT_USER, USER_LOADING, GET_ERRORS } from "./types";
import { BehaviorSubject } from "rxjs";
import { ApiUrls } from "../../../common/apiconstant";
import { toast } from "react-toastify";
import { logUserMetrics } from "../../../utils/file";
const jwt = require("jsonwebtoken");
const baseAPIURL = require("../../../admin/config").baseAPIURL;
const keys = require("../../../admin/config");
const validateRegisterInput = require("../../../admin/helpers/register");
const validateLoginInput = require("../../../admin/helpers/login");
const validateResetPasswordInput = require("../../../admin/helpers/resetpassword");
const validateForgotPasswordInput = require("../../../admin/helpers/forgotpassword");
const validateResetForgotPasswordInput = require("../../../admin/helpers/resetforgotpassword");
const { Auth } = require("aws-amplify");
let congitoUser = new BehaviorSubject(null);
// Register User
export const registerUser = (userData, history, dispatch2) => (dispatch) => {
  const { errors, isValid } = validateRegisterInput(userData);

  if (!isValid) {
    console.log("2");
    dispatch2({
      type: GET_ERRORS,
      payload: errors,
    });
  } else {
    const { email, password, firstName, lastName } = userData;

    Auth.signUp({
      username: email,
      password: password,
    })
      .then(async (res) => {
        console.log("response", JSON.parse(JSON.stringify(res)));
        const JsonSignUpUser = res.userSub;
        const JsonSignUpUserEmail = res.user.username;
        localStorage.setItem("useremail", JsonSignUpUserEmail);
        console.log(JsonSignUpUserEmail);
        localStorage.setItem("username", email);
        let action = "signUp-button";
        let type = "Info";
        let message = "User successfully signUp.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);

        //console.log(JsonSignUpUser)
        const viewPath = "/admin/employees-profile/" + JsonSignUpUser;
        history.push(viewPath);
      })
      .catch(async (err) => {
        console.log("error", err);

        dispatch2({
          type: GET_ERRORS,
          payload: {
            Catcherrors: err.message,
          },
        });
        let action = "signUp-button";
        let type = "Error";
        let message = "Error in signUp.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      });
  }
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  console.log("Test", JSON.parse(jsonPayload));
  return JSON.parse(jsonPayload);
}

// Login - get user token
export const loginUser = (userData, history, dispatch2) => (dispatch) => {
  const { errors, isValid } = validateLoginInput(userData);
  console.log("errorrs", errors.message);

  if (!isValid) {
    dispatch2({
      type: GET_ERRORS,
      payload: errors,
    });
  } else {
    const { email, password } = userData;
    // integrated with Aws congnito
    Auth.signIn({
      username: email,
      password: password,
    })
      .then(async (user) => {
        console.log(user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          congitoUser.next(user);
          history.push("/password-reset");
        } else {
          console.log("response", JSON.parse(JSON.stringify(user)));
          const JsonSignInUserSession = JSON.parse(JSON.stringify(user))
            .signInUserSession.accessToken;
          const bearerToken = "Bearer " + JsonSignInUserSession.jwtToken;
          let res123 = await parseJwt(JsonSignInUserSession.jwtToken);
          console.log("JsonSignInUserSession", res123);
          localStorage.setItem("jwtToken", bearerToken);
          localStorage.setItem("username", JSON.parse(JSON.stringify(email)));
          // Set bearerToken to Auth header
          setAuthToken(bearerToken);
          // Decode bearerToken to get user data
          // const decoded = jwt_decode(bearerToken);
          // Set current user

          const attributes = {
            email: user?.attributes?.email,
            email_verified: user?.attributes?.email_verified,
            sub: user?.attributes?.sub,
          };

          localStorage.setItem("attributes", JSON.stringify(attributes));

          console.log("attributes", attributes);

          fetch(`${ApiUrls.getLoginUser}?email=${email}`)
            .then((response) => response.json())
            .catch(async (err) => {
              console.log(err);
              let action = "login-button";
              let type = "Error";
              let message = "Error in user login.";

              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);
            })
            .then(async (res) => {
              console.log("NAMESSGGS", res);

              if (!res?.data?.user?.is_active) {
                console.log("IN IF ADMIN");
                toast.error(
                  "Your account has been suspended, Please contact liquorsplit admin."
                );

                return;
              }

              localStorage.setItem("userRole", "Admin");
              localStorage.setItem("Name", res?.data?.user?.firstname);
              localStorage.setItem(
                "fullname",
                res?.data?.user?.firstname + " " + res?.data?.user?.lastname
              );
              localStorage.setItem("userId", res?.data?.user?.email);
              // if (
              //   typeof res?.data[0]?.user_role !== undefined &&
              //   res?.data[0].user_role !== Role.Admin
              // ) {
              //   if (res?.data[0].defaultStore !== undefined) {
              //     localStorage.setItem("location", res?.data[0].defaultStore);
              //   }
              // }
              console.log(
                "res?.data?.rolesActionres?.data?.rolesAction=>",
                res?.data?.rolesAction
              );
              localStorage.setItem(
                "user_detail",
                JSON.stringify(res?.data?.user)
              );
              localStorage.setItem(
                "role_actions",
                JSON.stringify(res?.data?.rolesAction)
              );

              console.log(JsonSignInUserSession.jwtToken, "Payload-");

              let action = "login-button";
              let type = "Info";
              let message = "User successfully logged in.";

              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);

              dispatch2(setCurrentUser(JsonSignInUserSession.payload));
              let firebaseLogin = await signInWithEmailAndPassword(
                auth,
                "admin@liquorsplit.com",
                "Liquor@#1234"
              );
              console.log("firebaseLogin", firebaseLogin);
              // await signOut();
              history.push("/admin");
            });
        }
      })
      .catch(async (error) => {
        console.log("error:", error);
        console.log("error.code:", error.code);
        console.log("error.message:", error.message);
        var myErrors = {
          message: error.message,
        };
        switch (error.code) {
          case "auth/wrong-password":
            myErrors["password"] = "Wrong password!";
            break;
          case "NotAuthorizedException":
            if (error.message == "Incorrect username or password.") {
              console.log("sjfhdsf ", myErrors);
              myErrors["server"] = "Incorrect Username and Password";
            } else {
              console.log("jsbdsjbjfkbkdbf ", myErrors);
              myErrors["server"] =
                "Your account has been blocked Please contact your account administrator";
            }
            // case "NotAuthorizedException":
            //   myErrors["server"] = "Your account has been blocked Please contact your account administrator";
            break;
          case "auth/user-not-found":
            myErrors["email"] = "No user with this email!";
            break;
          default:
            myErrors["server"] = "Server error!";
        }
        dispatch2({
          type: GET_ERRORS,
          payload: myErrors,
        });
      });
  }
};

export const resetPasswordUser =
  (userData, history, dispatch2) => (dispatch) => {
    const { errors, isValid } = validateResetPasswordInput(userData);
    if (!isValid) {
      dispatch2({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      const { confirmPassword } = userData;
      congitoUser.subscribe((value) => {
        Auth.completeNewPassword(value, confirmPassword, [])
          .then((data) => {
            console.log("response", JSON.parse(JSON.stringify(data)));
            const JsonSignInUserSession = JSON.parse(JSON.stringify(data))
              .signInUserSession.accessToken;
            const bearerToken = "Bearer " + JsonSignInUserSession.jwtToken;
            localStorage.setItem("jwtToken", bearerToken);
            localStorage.setItem(
              "username",
              JSON.parse(
                JSON.stringify(data?.challengeParam?.userAttributes?.email)
              )
            );
            // Set bearerToken to Auth header
            setAuthToken(bearerToken);
            // Decode bearerToken to get user data
            // const decoded = jwt_decode(bearerToken);
            // Set current user

            const attributes = {
              email: data?.attributes?.email,
              email_verified: data?.attributes?.email_verified,
              sub: data?.attributes?.sub,
            };

            localStorage.setItem("attributes", JSON.stringify(attributes));

            console.log("attributes", attributes);

            fetch(
              `${ApiUrls.getLoginUser}?email=${data?.challengeParam?.userAttributes?.email}`
            )
              .then((response) => response.json())
              .catch((err) => {
                console.log(err);
              })
              .then(async (res) => {
                console.log("NAMESSGGS", res);

                if (!res?.data?.user?.is_active) {
                  console.log("IN IF ADMIN");
                  toast.error(
                    "Your account has been suspended, Please contact liquorsplit admin."
                  );

                  return;
                }

                localStorage.setItem("userRole", "Admin");
                localStorage.setItem("Name", res?.data?.user?.firstname);
                localStorage.setItem(
                  "fullname",
                  res?.data?.user?.firstname + " " + res?.data?.user?.lastname
                );
                localStorage.setItem("userId", res?.data?.user?.email);
                // if (
                //   typeof res?.data[0]?.user_role !== undefined &&
                //   res?.data[0].user_role !== Role.Admin
                // ) {
                //   if (res?.data[0].defaultStore !== undefined) {
                //     localStorage.setItem("location", res?.data[0].defaultStore);
                //   }
                // }
                console.log(
                  "res?.data?.rolesActionres?.data?.rolesAction=>",
                  res?.data?.rolesAction
                );
                localStorage.setItem(
                  "user_detail",
                  JSON.stringify(res?.data?.user)
                );
                localStorage.setItem(
                  "role_actions",
                  JSON.stringify(res?.data?.rolesAction)
                );

                let action = "reset-password-user";
                let type = "Info";
                let message = "User Password Reset successfully.";
                const apiResponse = await logUserMetrics(action, type, message);
                console.log("apiirresponse", apiResponse);

                console.log(JsonSignInUserSession.jwtToken, "Payload-");
                dispatch2(setCurrentUser(JsonSignInUserSession.payload));
                history.push("/admin");
              });

            // console.log(JsonSignInUserSession.jwtToken, "Payload-");
            // dispatch2(setCurrentUser(JsonSignInUserSession.payload));
            // history.push("/admin");
          })
          .catch(async (err) => {
            dispatch2({
              type: GET_ERRORS,
              payload: {
                Catcherrors: err.message,
              },
            });
            let action = "reset-password-user";
            let type = "Error";
            let message = "Error in User Password Reset.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          });
      });
    }
  };

// Forgot Password
export const forgotPasswordUser =
  (userData, history, dispatch2) => (dispatch) => {
    const { errors, isValid } = validateForgotPasswordInput(userData);
    if (!isValid) {
      dispatch2({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      const { email } = userData;
      //console.log("DTAT", data);

      Auth.forgotPassword(email)
        .then(async (data) => {
          console.log("response", JSON.parse(JSON.stringify(data)));
          let action = "forgot-password-user";
          let type = "Info";
          let message = "User Password Forgot successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);

          history.push("/forgot-password-reset", { email: email });
        })
        .catch(async (err) => {
          dispatch2({
            type: GET_ERRORS,
            payload: {
              Catcherrors: err.message,
            },
          });
          let action = "forgot-password-user";
          let type = "Error";
          let message = "Error in User Password Forgot.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        });
    }
  };

// reset forgot password
export const resetForgotPasswordUser =
  (userData, history, dispatch2) => (dispatch) => {
    const { errors, isValid } = validateResetForgotPasswordInput(userData);
    if (!isValid) {
      console.log(errors);
      dispatch2({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      const { email, code, newPassword } = userData;
      console.log("DATA", email, code, newPassword);

      Auth.forgotPasswordSubmit(email, code, newPassword)
        .then(async (data) => {
          console.log("response", data);
          if (data === "SUCCESS") {
            dispatch2({
              type: GET_ERRORS,
              payload: {
                Catcherrors: "",
              },
            });
            let action = "reset-forgot-password-user";
            let type = "Info";
            let message = "User Reset Password Forgot successfully.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);

            history.push("/login");
          }
          // let action = "Reset Forgot Password User"
          // let type = "Info";
          // let message = "Reset Forgot Password User successfully.";

          // try {
          //   const apiResponse = await logUserMetrics(action, type, message);
          //   console.log("apiirresponse", apiResponse);
          // } catch (error) {
          //   console.log("Error in log user metrics", error);
          // }
          //if(data)
        })
        .catch(async (err) => {
          console.log("ERR", err, err.message);

          dispatch2({
            type: GET_ERRORS,
            payload: {
              Catcherrors: err.message,
            },
          });
          let action = "reset-forgot-password-user";
          let type = "Error";
          let message = "Error in User Reset Password Forgot.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        });
    }
  };

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = (dispatch, history) => (dispatch2) => {
  Auth.signOut();
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("username");
  localStorage.removeItem("location");
  localStorage.removeItem("attributes");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  history.push("/login");
};
