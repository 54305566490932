import React, { useMemo, useEffect, useState } from 'react';
import Header from "../../ui/Header.js";
import { useHistory } from "react-router-dom";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";
import FullPageLoader from "../../../components/FullPageLoader";
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Button,
    Row,
    ButtonGroup,
    Media,
    UncontrolledTooltip,
    Badge, Col
} from "reactstrap";
const driversColumns = [
    {
        Header: "Name",
    },
    {
        Header: "Phone",
    },
    {
        Header: "Email",
    },
    {
        Header: "Vehicle License",
    },
    {
        Header: "Is Verified",
    },
    {
        Header: "Is Enable",
    },
    {
        Header: "Actions",
    }
]

function DriverList() {

    const [isLoading, setIsLoading] = useState(true);
    const [driversData, setDriversData] = useState([]);
    const [nextKey, setNextKey] = useState(null);
    const history = useHistory();

    const getDriversList = () => {

        const token = getToken();
        const config = {
            headers: { Authorization: token },
        };

        setIsLoading(true);
        let DriverApi = `${ApiUrls.userLists}?size=10&role=Driver`;
        if (nextKey !== null) {
            DriverApi += `&pk=${nextKey?.pk?.split("#")[1]}&sk=${nextKey?.sk?.split("#")[1]
                }`;
        }

        fetch(
            DriverApi,
            config
        )
            .then((response) => response.json())
            .then((res) => {
                const driver = res?.data;

                if (driver.length == 10) {
                    setNextKey(driver[driver.length - 1]);
                } else {
                    setNextKey(null);
                }
                setDriversData(driver);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }

    useEffect(() => {
        getDriversList();
    }, [])


    const columns = useMemo(
        () => driversColumns,
        []
    )



    const handleAdd = () => {
        const viewPath = "/admin/drivers-create"
        history.push(viewPath)
    }

    const handleView = (item) => {
        const viewPath = "/admin/drivers-show/" + item.userId
        history.push(viewPath)
    }

    const handleEdit = (item) => {
        const editPath = "/admin/drivers-edit/" + item.userId
        history.push(editPath)
    }

    const handleDelete = async (item) => {
        if (window.confirm('Are you sure you want to Disable this employee?')) {
            if (item.isEnabled) {
                var isEnable = false;
            } else {
                var isEnable = true;
            }
            setIsLoading(true);
            fetch(`${ApiUrls.userBlock}` + item.userId, {
                method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
                body: JSON.stringify({ "type": "Driver", 'isEnabled': isEnable }) // body data type must match "Content-Type" header
            }).then(response => response.json())
                .then(res => {
                    console.log(res)
                    if (res.message == "") {
                        if (res.data.message == "SUCCESS") {
                            window.location.reload()
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error('Error:', error);
                });
        }
    }

if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    )
  }

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                                            {"All Drivers"}
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => handleAdd()}
                                            size="sm"
                                        >
                                            Add Driver
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        {driversColumns.map((item) => (
                                            <th scope="col">{item.Header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {driversData && driversData?.length ? (
                                        driversData?.map((item) => (
                                            <tr>
                                                <th scope="row">
                                                    <Media className="align-items-center">
                                                        <a
                                                            className="avatar rounded-circle mr-3"
                                                            href="#pablo"
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            <img
                                                                alt="..."
                                                                src={item.profileImg}
                                                            />
                                                        </a>
                                                        <Media>
                                                            <span className="name mb-0 text-sm">
                                                                {item?.firstName + " " + item?.lastName}
                                                            </span>
                                                        </Media>
                                                    </Media>
                                                </th>
                                                <td className="budget">{item?.phoneNo}</td>
                                                <td className="budget">{item?.email}</td>
                                                <td className="budget">{item?.vehicleLicense}</td>
                                                <td className="budget">
                                                    <Badge className="badge-lg" color={item?.isVerified ? 'success' : 'danger'}>
                                                        {item?.isVerified ? 'Yes' : 'No'}
                                                    </Badge>
                                                </td>
                                                <td className="budget">
                                                    <Badge className="badge-lg" color={item?.isEnabled ? 'success' : 'danger'}>
                                                        {item?.isEnabled ? 'Enabled' : 'Disabled'}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="btn-icon-only"
                                                            color="info"
                                                            onClick={() => handleView(item)}
                                                            id={"tooltipv" + item.userId}
                                                            type="button"
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target={"tooltipv" + item.userId}>
                                                            View Employee
                                                        </UncontrolledTooltip>

                                                        <Button
                                                            className="btn-icon-only"
                                                            color="success"
                                                            onClick={() => handleEdit(item)}
                                                            id={"tooltipe" + item.userId}
                                                            type="button"
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target={"tooltipe" + item.userId}>
                                                            Edit Employee
                                                        </UncontrolledTooltip>

                                                        <Button className="btn-icon-only" color="danger" onClick={() => handleDelete(item)} id={"tooltipd" + item.userId} type="button">
                                                            
                                                                <i className="fa fa-ban"></i>
                                                            
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target={"tooltipd" + item.userId}>
                                                            {item.isEnabled ? "Disable" : "Enable"} Employee
                                                        </UncontrolledTooltip>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            {isLoading ? (
                                                <td colSpan="8">Loading...</td>
                                            ) : (
                                                <td colSpan="8">No data found...</td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                {nextKey !== null && (
                                    <a
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                        className="Link"
                                        onClick={() => getDriversList()}
                                    >
                                        Load More...
                                    </a>
                                )}
                            </CardFooter>


                        </Card>
                    </div>
                </Row>
            </Container>
        </>

    )
}

export default DriverList;
