import React, { useMemo, useEffect, useState, useRef } from "react";
import Header from "../../ui/Header.js";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file.js";
import { useHistory } from "react-router-dom";
import {
  Col,
  Card,
  Label,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  CardFooter,
  Table,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,
  Button,
  Row,
  ButtonGroup,
  Media,
  UncontrolledTooltip,
  Input,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import IMGetLocation from "../ui/IMGetLocation.js";
// import "./style.css";
import FullPageLoader from "../../../components/FullPageLoader/index.js";

import { AlexaForBusiness } from "aws-sdk";
import { ApiEndpoints } from "../../constants/constant.js";
import moment from "moment";
import { useMediaQuery } from "@material-ui/core";
import getToken from "../../../onboarding/getToken.js";
import { ExportToPriceExcel } from "../../../components/ExportExcel/priceIndex.js";
import SearchBar from "../../components/SearchBar.js";
import { toast } from "react-toastify";
import styles from "../../styles/productTransaction.module.css";
import MobileSearchBar from "../../components/SearchBarMobile.js";
import ModalTitle from "rsuite/esm/Modal/ModalTitle.js";
import Select from "react-select";
import { Formik, Form, FieldArray, Field } from "formik";
import useOutsideClick from "../hooks/useOutsideClick.js";
const awsSDKconfig = require("../../config/awsSDKConfig.js");
const AWS = require("aws-sdk");
AWS.config.update(awsSDKconfig);
const cognito = new AWS.CognitoIdentityServiceProvider();
const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function POSMappingFile() {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("All");
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const modalToggle = () => setModal(!modal);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [inventoryControls, setInventoryControls] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const userLocation = localStorage.getItem("location");
  const [isImport, setIsImport] = useState(false);
  const [importStatus, setImportStatus] = useState("");
  const [isExcelData, setIsExcelData] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [statusFilter, setStatusFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedSource, setSelectedSource] = useState([]);
  const [sourceDropDown, setSourceDropDown] = useState(false);
  const toggleSourceDropDown = () =>
    setSourceDropDown((prevState) => !prevState);
  const [clear, setClear] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCheckedList, setIsCheckedList] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [posName, setPosName] = useState("");
  const [posNameError, setPosNameError] = useState("");
  const [headerData, setHeaderData] = useState([]);
  const [productName, setProductName] = useState("");
  const [skuNumber, setSkuNumber] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedHeaderOptions, setSelectedHeaderOptions] = useState([]);
  const [allHeaderOptions, setHeaderAllOptions] = useState([]);
  const [mappingSugg, setMappingSugg] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [filteredUser, setFilteredUser] = useState("");
  const [importReqId, setImportedReqId] = useState("");
  const [initialData, setInitialData] = useState({
    pos_name: "",
  });
  const fileInputRef = useRef();
  const [file, setFile] = useState("");
  const [allItems, setAllItems] = useState([]);

  const inputRef = useRef();

  useOutsideClick(inputRef, () => setShowSuggestion(false));

  const handleClose = () => setShowModal(false);
  const handleSave = () => {
    // Add logic for saving data
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
    getTransactionList(1);
    setPage(1);
  };
  const history = useHistory();
  const [productData, setProductData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  console.log("ISLOADING", isLoading);

  const SortOptions = [{ id: "created_at", text: "Date" }];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const sourceOptions = [
    { id: "Mapping pending", text: "Mapping pending" },
    { id: "Mapping Approved", text: "Mapping Approved" },
    { id: "Processing", text: "Processing" },
    { id: "Completed", text: "Completed" },
  ];

  const handleSourceOptions = (sourceId) => {
    setPage(1);
    if (selectedSource.includes(sourceId)) {
      setSelectedSource((prevSelected) =>
        prevSelected.filter((id) => id !== sourceId)
      );
    } else {
      setSelectedSource((prevSelected) => [...prevSelected, sourceId]);
    }
  };

  const getTransactionList = (pageNum) => {
    if (locationId) {
      let apiUrl = `${ApiEndpoints.mappingList}?location_id=${locationId}&page=${pageNum}&limit=10`;

      if (selectedSortOption) {
        apiUrl += `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
      }

      const requestData = {};

      if (selectedStatus !== "All") {
        apiUrl += `&approval_status=${selectedStatus}`;
      }

      if (selectedSource.length > 0) {
        apiUrl += `&processing_status=${selectedSource}`;
      }

      if (searchKeyword.length > 0) {
        requestData.searchKeyword = searchKeyword;
      }

      setIsLoading(true);
      console.log("requestData", requestData);

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => res.json())
        .then((response) => {
          const menuTransaction = response.data;
          console.log("RESPONSE NEW", response);

          if (menuTransaction.length > 0) {
            const updatedOrders =
              pageNum === 1
                ? menuTransaction
                : [...inventoryControls, ...menuTransaction];
            const mergedOrders = mergeAndSortOrders(updatedOrders);
            setInventoryControls(mergedOrders);

            if (menuTransaction.length < 10) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          } else {
            console.log("page456", page);
            if (pageNum === 1) {
              setInventoryControls(menuTransaction);

              if (menuTransaction.length < 10) {
                setLoadMoreEnabled(false);
              } else {
                setLoadMoreEnabled(true);
              }
            }
          }
          setIsLoading(false);
          setClear(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error", err);
        });
    }
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getTransactionList(pageNum);
  };

  useEffect(() => {
    setPage(1);
    if (selectedSortOption) {
      getTransactionList(page);
    }
  }, [
    selectedSortOption,
    sortDirection,
    selectedStatus,
    selectedSource,
    locationId,
    isImport === true,
  ]);

  console.log("inventoryconrrolss", inventoryControls);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getTransactionList(page);
    } else if (searchKeyword === "") {
      getTransactionList(page);
    }
  }, [searchKeyword.length]);

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const mergeAndSortOrders = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      getTransactionList(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });

      //   const status = value.toLowerCase();
      setSelectedStatus(value);
    }
  };

  let btns = ["All"];

  const handleClear = async () => {
    setPage(1);
    // setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    // setSelectedDeliveryMethod([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getTransactionList(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    // setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    // setSelectedDeliveryMethod([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  const handleCheckboxChange = () => {
    setIsCheckedList(!isCheckedList);
  };

  const exportData = async () => {
    if (locationId) {
      setIsLoading(true);
      fetch(`${ApiEndpoints.exportPrice}?locationId=${locationId}`)
        .then((response) => response.json())
        .then((json) => {
          console.log("json", json);
          setTimeout(() => {
            const data = [
              {
                category: "product",
                data: json.data,
              },
            ];
            setProductData(data);
            //setIsExcelData(true)
          }, 1000);
        });

      setIsLoading(false);
    }
  };

  const callReferesh = () => {
    fetch(`${ApiEndpoints.productListLocationImport}?location_id=${locationId}`)
      .then((res) => res.json())
      .then((response) => {
        console.log("RESPONSE NEW", response);
        setInventoryControls(response.data);
        setOriginalData(response.data);
        setApprovalStatus("All");
        setIsLoading(false);
      });
  };
  // console.log("Contr", inventoryControls);
  // console.log("PRODUCT DATa", productData);
  const handleView = () => {
    const viewPath = "/admin/import-export-show/1";
    history.push(viewPath);
  };

  const handleDropdown = (e) => {
    console.log("E", e);
    let value = e.target.value;
    let location_id = value.split("#")[1];
    setLocationId(location_id);
    // callReferesh();
    setApprovalStatus("All");
  };

  useEffect(() => {
    let location = localStorage.getItem("selected_location_id");
    console.log("location 1234", location);
    if (location) {
      let loc = location.split("#")[1];
      setLocationId(loc);
    }
  }, []);

  return (
    <>
      <Container fluid>
        <Formik
          initialValues={initialData}
          // validationSchema={validationSchema}
          enableReinitialize={true}
          // onSubmit={(values, { setSubmitting }) => {
          //   const trimmedValues = Object.keys(values).reduce((acc, key) => {
          //     acc[key] =
          //       typeof values[key] === "string"
          //         ? values[key].trim()
          //         : values[key];
          //     return acc;
          //   }, {});

          //   addBlackListUser(trimmedValues, setSubmitting);
          // }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col lg="6" xs="7">
                          <h6
                            className="fullcalendar-title h2 d-inline-block mb-0"
                            style={
                              isMobile
                                ? { fontSize: "18px", whiteSpace: "nowrap" }
                                : null
                            }
                          >
                            {"POS Inventory Mapping"}
                          </h6>
                        </Col>
                      </Row>
                      <Row></Row>
                    </CardHeader>
                    <CardBody className={styles.transactionList}>
                      {isMobile ? (
                        <MobileSearchBar
                          showCard={showCard}
                          selectStatus={selectStatus}
                          sortDirection={sortDirection}
                          statusFilter={statusFilter}
                          searchKeyword={searchKeyword}
                          dropdownOpen={dropdownOpen}
                          toggleDropdown={toggleDropdown}
                          selectedStatus={selectedStatus}
                          SortOptions={SortOptions}
                          handleSearchButtonClick={handleSearchButtonClick}
                          setSearchKeyword={setSearchKeyword}
                          getDetail={getTransactionList}
                          handleClear={handleClear}
                          handleCancelClick={handleCancelClick}
                          handleSortOptionSelect={handleSortOptionSelect}
                          selectedSortOption={selectedSortOption}
                          handleSourceOptions={handleSourceOptions}
                          selectedSource={selectedSource}
                          sourceDropDown={sourceDropDown}
                          toggleSourceDropDown={toggleSourceDropDown}
                          sourceOptions={sourceOptions}
                          btns={btns}
                          placeHolderText="Search"
                          transactionListDropDown={false}
                          dropDown={false}
                          orderList={false}
                        />
                      ) : (
                        <SearchBar
                          showCard={showCard}
                          selectStatus={selectStatus}
                          sortDirection={sortDirection}
                          statusFilter={statusFilter}
                          searchKeyword={searchKeyword}
                          dropdownOpen={dropdownOpen}
                          toggleDropdown={toggleDropdown}
                          selectedStatus={selectedStatus}
                          SortOptions={SortOptions}
                          handleSearchButtonClick={handleSearchButtonClick}
                          setSearchKeyword={setSearchKeyword}
                          getDetail={getTransactionList}
                          handleClear={handleClear}
                          handleCancelClick={handleCancelClick}
                          handleSortOptionSelect={handleSortOptionSelect}
                          selectedSortOption={selectedSortOption}
                          handleSourceOptions={handleSourceOptions}
                          selectedSource={selectedSource}
                          sourceDropDown={sourceDropDown}
                          toggleSourceDropDown={toggleSourceDropDown}
                          sourceOptions={sourceOptions}
                          btns={btns}
                          placeHolderText="Search"
                          transactionListDropDown={false}
                          dropDown={false}
                          orderList={false}
                        />
                      )}
                    </CardBody>
                    <div style={{ height: 560, overflowY: "scroll" }}>
                      <Table className="align-items-center table-flush">
                        <thead
                          className="thead-light"
                          style={{ position: "sticky", top: 0, zIndex: 100 }}
                        >
                          <tr>
                            <th>Sr. No.</th>
                            <th>Created At</th>
                            <th>POS Name</th>
                            <th>Uploaded By</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {inventoryControls?.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {moment(item?.created_at).format("MM/DD/YYYY")}
                              </td>
                              <td>{item?.pos_name}</td>
                              <td>{item?.updated_by}</td>
                              <td>
                                <ButtonGroup>
                                  <Button
                                    className="btn-icon-only"
                                    color="info"
                                    type="button"
                                    onClick={() =>
                                      history.push({
                                        pathname: `/admin/pos/mapping/view/${item.id}`,
                                        state: {
                                          item: item,
                                          // permission: "view"
                                        },
                                      })
                                    }
                                    id={"tooltipv" + "view"}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-eye"></i>
                                    </span>
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <CardFooter>
                      {loadMoreEnabled && (
                        <a
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          className="Link"
                          onClick={handleLoadMore}
                        >
                          Load More...
                        </a>
                      )}
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default POSMappingFile;
