/*!
=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const routes = [
  {
    // path: "/",
    // name: "",
    // icon: "",
    // layout: "/admin",
    // state: "formsCollapse",
    hasPermission: "dashboard",
    // collapse: false,
    // views: [],
    type: "seprator",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    layout: "/admin",
    state: "formsCollapse",
    hasPermission: "dashboard",
    collapse: false,
    views: [],
  },
  // {
  //   collapse: true,
  //   name: "Reports",
  //   icon: "ni ni-ui-04 text-info",
  //   layout: "/admin",
  //   hasPermission: "reports",
  //   state: "reportCollapse",
  //   views: [
  //     {
  //       path: "/reporting",
  //       name: "Cash Remittance",
  //       miniName: "R",
  //       layout: "/admin",
  //       hasPermission: "reports",
  //     },
  //     {
  //       path: "/sku",
  //       name: "SKU Transaction",
  //       miniName: "S",
  //       layout: "/admin",
  //       hasPermission: "reports",
  //     },
  //     {
  //       path: "/reports/transaction",
  //       name: "Transactions",
  //       miniName: "T",
  //       layout: "/admin",
  //       hasPermission: "reports",
  //     },
  //     // {
  //     //   path: "/manage-sku-transaction",
  //     //   name: "Manage SKU",
  //     //   miniName: "M",
  //     //   layout: "/admin",
  //     //   hasPermission: "reports",
  //     // },
  //   ],
  // },
  // {
  //   path: "/manage-sku-transaction",
  //   name: "Manage SKU",
  //   icon: "ni ni-ui-04 text-info",
  //   layout: "/admin",
  //   state: "formsCollapse",
  //   hasPermission: "manage_sku_reports",
  //   collapse: false,
  //   views: [],
  // },
  {
    collapse: true,
    name: "B2C",
    icon: "ni ni-ui-04 text-info",
    state: "dispatchCollapse",
    hasPermission: "dispatch",
    views: [
      {
        path: "/list-orders",
        name: "Orders",
        miniName: "O",
        layout: "/admin",
        hasPermission: "list_orders",
      },
    ],
    // views: [
    //   {
    //     path: "/orders",
    //     name: "Orders",
    //     miniName: "O",
    //     layout: "/admin",
    //     hasPermission: "orders",
    //   },
    // ],
  },
  {
    collapse: true,
    name: "Communication",
    icon: "ni ni-ui-04 text-info",
    state: "firebaseCollapse",
    hasPermission: "dispatch",
    views: [
      {
        path: "/notifications",
        name: "Notification",
        miniName: "N",
        layout: "/admin",
        hasPermission: "notifications",
      },
    ],
  },
  {
    collapse: true,
    name: "Blacklist",
    icon: "ni ni-ui-04 text-info",
    state: "blacklistCollapse",
    hasPermission: "blacklist",
    views: [
      {
        path: "/blacklist",
        name: "Manage Blacklist",
        miniName: "B",
        layout: "/admin",
        hasPermission: "blacklist",
      },
    ],
  },
  {
    collapse: true,
    name: "Inventory",
    icon: "ni ni-ui-04 text-info",
    state: "inventoryCollapse",
    hasPermission: "inventory",
    views: [
      {
        path: "/inventory-products",
        name: "Search",
        miniName: "S",
        layout: "/admin",
        hasPermission: "search",
      },
      {
        path: "/inventory-controls",
        name: "Inventory Controls",
        miniName: "I",
        layout: "/admin",
        hasPermission: "controls",
      },
      {
        path: "/import-export",
        name: "Import/Export",
        miniName: "I",
        layout: "/admin",
        hasPermission: "controls",
      },
      {
        path: "/inventory-update",
        name: "Manual Update",
        miniName: "M",
        layout: "/admin",
        hasPermission: "transaction",
      },
      {
        path: "/inventory-adjustment",
        name: "App Sales",
        miniName: "A",
        layout: "/admin",
        hasPermission: "transaction",
      },

      // {
      //   path: "/import-approved",
      //   name: "Approved",
      //   miniName: "C",
      //   layout: "/admin",
      //   hasPermission: "controls",
      // },
      // {
      //   path: "/inventory-transactions",
      //   name: "Transaction",
      //   miniName: "T",
      //   layout: "/admin",
      //   hasPermission: "transaction",
      // },
      // {
      //   path: "/inventory-quick-scan",
      //   name: "Quick Scan",
      //   miniName: "Q",
      //   layout: "/admin",
      //   hasPermission: "quick-scan",
      // },

      // {
      //   path: "/managecollection",
      //   name: "Manage",
      //   miniName: "T",
      //   layout: "/admin",
      //   hasPermission: "manage",
      // },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Dispute",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "dispatchCollapseDispute",
  //   hasPermission: "disputes",
  //   views: [
  //     {
  //       path: "/disputes",
  //       name: "Dispute",
  //       miniName: "O",
  //       layout: "/admin",
  //       hasPermission: "disputes",
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Messages",
    icon: "ni ni-ui-04 text-info",
    hasPermission: "messages",
    views: [
      {
        path: "/notification",
        name: "Ops Message",
        miniName: "O",
        layout: "/admin",
        hasPermission: "messages",
      },
    ],
  },
  {
    collapse: true,
    name: "POS Inventory",
    icon: "ni ni-ui-04 text-info",
    state: "posinventoryCollapse",
    hasPermission: "pos_inventory",
    views: [
      {
        path: "/pos-inventory",
        name: "POS Inventory File",
        miniName: "P",
        layout: "/admin",
        hasPermission: "pos_inventory_file",
      },
      {
        path: "/pos/mapping",
        name: "POS Inv Mapping",
        miniName: "M",
        layout: "/admin",
        hasPermission: "pos_inventory_mapping",
      },
    ],
  },
  {
    collapse: true,
    name: "App Channels",
    icon: "ni ni-ui-04 text-info",
    state: "appChannelsCollapse",
    hasPermission: "app_channels",
    views: [
      {
        path: "/manage-collections",
        name: "Navigation Confg",
        miniName: "N",
        layout: "/admin",
        hasPermission: "navigation_config",
      },

      {
        path: "/picked-collections",
        name: "Navigation Mngt",
        miniName: "N",
        layout: "/admin",
        hasPermission: "navigation_mngt",
      },
      {
        path: "/collections",
        name: "Collections",
        miniName: "C",
        layout: "/admin",
        hasPermission: "collections",
      },
      {
        path: "/sub-category",
        name: "Sub Category",
        miniName: "S",
        layout: "/admin",
        hasPermission: "sub_category",
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Discount",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "discountsCollapse",
  //   hasPermission: "discounts",
  //   views: [
  //     {
  //       path: "/discount",
  //       name: "Discount",
  //       miniName: "U",
  //       layout: "/admin",
  //       hasPermission: "discounts",
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Products",
    icon: "ni ni-ui-04 text-info",
    state: "productsCollapse",
    hasPermission: "products",
    views: [
      {
        path: "/products",
        name: "Products",
        miniName: "P",
        layout: "/admin",
        hasPermission: "products",
      },
      // {
      //   path: "/product/menu",
      //   name: "Menu",
      //   miniName: "M",
      //   layout: "/admin",
      //   hasPermission: "menu",
      // },
      // {
      //   path: "/menu-import",
      //   name: "Menu Import",
      //   miniName: "M",
      //   layout: "/admin",
      //   hasPermission: "menu_import",
      // },
      {
        path: "/transaction",
        name: "Transaction",
        miniName: "T",
        layout: "/admin",
        hasPermission: "product_transaction",
      },
    ],
  },
  {
    // path: "/",
    // name: "",
    // icon: "",
    // layout: "/admin",
    // state: "formsCollapse",
    hasPermission: "dashboard",
    // collapse: false,
    // views: [],
    type: "seprator",
  },
  //  {
  //     collapse: true,
  //     name: "Orders",
  //     icon: "ni ni-ui-04 text-info",
  //     state: "ordersCollapse",
  //     hasPermission: "list_orders",
  //     views: [
  //       {
  //         path: "/list-orders",
  //         name: "Order List",
  //         miniName: "G",
  //         layout: "/admin",
  //         hasPermission: "list_orders",
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Tenants",
    icon: "fas fa-map-marker-alt text-primary",
    layout: "/admin",
    hasPermission: "tenants",
    state: "tenantsCollapse",
    views: [
      {
        path: "/tenant",
        name: "Tenants",
        miniName: "T",
        layout: "/admin",
        hasPermission: "tenants",
      },
      {
        path: "/archived-tenant",
        name: "Archived Tenants",
        miniName: "A",
        layout: "/admin",
        hasPermission: "archived_tenants",
      },
    ],
  },
  {
    collapse: true,
    name: "Menu",
    icon: "ni ni-ui-04 text-info",
    state: "masterProductsCollapse",
    hasPermission: "master_products",
    views: [
      {
        path: "/master-menu",
        name: "Master Menu",
        miniName: "P",
        layout: "/admin",
        hasPermission: "master_products",
      },
    ],
  },
  {
    collapse: true,
    name: "App Channel",
    icon: "ni ni-ui-04 text-info",
    state: "appChannelCollapse",
    hasPermission: "app_channel",
    views: [
      {
        path: "/manage-collection-list",
        name: "Navigation Confg",
        miniName: "N",
        layout: "/admin/channel-tenants",
        hasPermission: "navigation_config",
      },

      {
        path: "/picked-collection-list",
        name: "Navigation Mngt",
        miniName: "N",
        layout: "/admin/channel-tenants",
        hasPermission: "navigation_mngt",
      },
      {
        path: "/collection-list",
        name: "Collection",
        miniName: "C",
        layout: "/admin/channel-tenants",
        hasPermission: "collection_list",
      },
      {
        path: "/list-sub-category",
        name: "Sub Category",
        miniName: "S",
        layout: "/admin/channel-tenants",
        hasPermission: "sub_category",
      },
    ],
  },
  {
    collapse: true,
    name: "Users and Roles",
    icon: "fas fa-users text-pink",
    state: "adminCollapse",
    hasPermission: "users",
    views: [
      {
        path: "/users",
        name: "Users",
        miniName: "U",
        layout: "/admin",
        hasPermission: "users",
      },
      {
        path: "/archived-users",
        name: "Archived Users",
        miniName: "A",
        layout: "/admin",
        hasPermission: "archived_users",
      },
      // {
      //   path: "/Role-Premission",
      //   name: "Permission",
      //   miniName: "P",
      //   layout: "/admin",
      //   hasPermission: "role_permission",
      // },
      {
        path: "/Role",
        name: "Roles",
        miniName: "R",
        layout: "/admin",
        hasPermission: "roles",
      },

      // {
      //   path: "/drivers",
      //   name: "Drivers",
      //   miniName: "D",
      //   layout: "/admin",
      //   hasPermission: "drivers",
      // },
    ],
  },

  {
    collapse: true,
    name: "Locations",
    icon: "fas fa-map-marker-alt text-primary",
    layout: "/admin",
    hasPermission: "locations",
    state: "locationCollapse",
    views: [
      {
        path: "/locations",
        name: "Locations",
        miniName: "L",
        layout: "/admin",
        hasPermission: "locations",
      },
    ],
  },

  // {
  //   collapse: true,
  //   name: "Role",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "dispatchRole",
  //   hasPermission: "dispatch",
  //   views: [
  //     {
  //       path: "/Role-Premission",
  //       name: "Premission",
  //       miniName: "O",
  //       layout: "/admin",
  //       hasPermission: "rolepermission",
  //     },
  //     {
  //       path: "/Role",
  //       name: "Role",
  //       miniName: "O",
  //       layout: "/admin",
  //       hasPermission: "roles",
  //     },
  //   ],
  // },

  // {
  //   collapse: true,
  //   name: "B2B",
  //   icon: "far fa-building text-primary",
  //   state: "b2bCollapse",
  //   hasPermission: "admin",
  //   views: [
  //     {
  //       path: "/b2b-order",
  //       name: "Quotations",
  //       miniName: "N",
  //       layout: "/admin",
  //       hasPermission: "employees",
  //     },
  //   ],
  // },

  {
    path: "/logout",
    name: "Logout",
    icon: "fas fa-sign-out-alt text-primary",
    layout: "/admin",
    hasPermission: "logout",
  },
];

export default routes;
