import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, isEmptyArray } from "formik";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
} from "reactstrap";
import { isEmpty } from "lodash";
import { getToken } from "../../../onboarding";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { Select } from "@material-ui/core";
import { toast } from "react-toastify";
import Loader from "../../../onboarding/components/Loader";
var resdata = require("../response.json");

let productArray = [];

const SearchInventoryProduts = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [source, setSource] = useState("");
  const [exportProducts, setExportProducts] = useState([]);
  const [searchText, setSearchText] = useState("");

  // useEffect(() => {
  //   let roles = localStorage.getItem("role_actions");
  //   if (roles) {
  //     let allRoles = JSON.parse(roles);
  //     if (!allRoles.includes("inventory_admin_manage")) {
  //       history.push("/admin/inventory-update");
  //     }
  //   }
  // }, []);

  const handlePage = () => {
    const viewPath = "/admin/inventory-update";
    history.push(viewPath);
  };

  const getExportProducts = async (locationId) => {
    setIsLoading(true);
    try {
      let res = await axios.get(
        `${ApiEndpoints.exportProducts}?locationId=${locationId.split("#")[1]}`
      );

      console.log("RESP", res);
      if (res.status === 200) {
        let products = [];
        let exportProductsData = JSON.parse(JSON.stringify(res.data.data));

        for (let i = 0; i < exportProductsData.length; i++) {
          exportProductsData[i]["New Price"] = exportProductsData[i]["Price"];
          products.push({
            count: exportProductsData[i]["LS Qty"],
            id: exportProductsData[i]["Sku"],
            inventory_item_id: exportProductsData[i]["Inventory Item ID"],
            location_id: exportProductsData[i]["Location ID"],
            name: exportProductsData[i]["Item Name"],
            upc: exportProductsData[i]["UPC"],
            price: exportProductsData[i]["Price"],
            quantity: exportProductsData[i]["New Qty"],
            new_price: exportProductsData[i]["New Price"],
          });
        }
        setProductData(products);
        productArray = exportProductsData;
        setExportProducts(exportProductsData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  console.log("EXPOT", exportProducts);

  useEffect(() => {
    // let locationId = localStorage.getItem("transaction_locationId");
    let locationId = localStorage.getItem("selected_location_id");
    if (locationId) {
      getExportProducts(locationId);
    }
  }, []);

  // const handleSearch = (arr, str) => {
  //   const filteredUsers = arr.filter((user) => {
  //     return Object.values(user).some(
  //       (value) =>
  //         typeof value === "string" &&
  //         value.toLowerCase().includes(str.toLowerCase())
  //     );
  //   });
  //   console.log("FILTERED", filteredUsers);
  //   return filteredUsers;
  // };

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    let res = arr.filter((item) =>
      item["Item Name"].toLowerCase().includes(str.toLowerCase())
    );
    console.log("RESULT", res);
    return res;
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchText(string);
    console.log("STRING", string, results);

    let searchRes = handleSearch(productArray, string);
    console.log("SEra", searchRes);
    let products = [];

    for (let i = 0; i < searchRes.length; i++) {
      products.push({
        id: searchRes[i].Sku,
        price: searchRes[i]["Price"],
        name: searchRes[i]["Item Name"],
        upc: searchRes[i]["UPC"],
        quantity: searchRes[i]["New Qty"],
        count: searchRes[i]["LS Qty"],
        inventory_item_id: searchRes[i]["Inventory Item ID"],
        location_id: searchRes[i]["Location ID"],
        new_price: searchRes[i]["New Price"],
      });
    }

    setProductData(products);

    var stringLength = string.length;
    if (stringLength <= 0) {
      let products = [];
      for (let i = 0; i < exportProducts.length; i++) {
        products.push({
          count: exportProducts[i]["LS Qty"],
          id: exportProducts[i]["Sku"],
          inventory_item_id: exportProducts[i]["Inventory Item ID"],
          location_id: exportProducts[i]["Location ID"],
          name: exportProducts[i]["Item Name"],
          upc: exportProducts[i]["UPC"],
          price: exportProducts[i]["Price"],
          quantity: exportProducts[i]["New Qty"],
          new_price: exportProducts[i]["New Price"],
        });
      }
      setProductData(products);
      //setProductData(exportProducts);
    }
  };

  function onClear() {
    let products = [];
    for (let i = 0; i < exportProducts.length; i++) {
      products.push({
        count: exportProducts[i]["LS Qty"],
        id: exportProducts[i]["Sku"],
        inventory_item_id: exportProducts[i]["Inventory Item ID"],
        location_id: exportProducts[i]["Location ID"],
        name: exportProducts[i]["Item Name"],
        upc: exportProducts[i]["UPC"],
        price: exportProducts[i]["Price"],
        quantity: exportProducts[i]["New Qty"],
        new_price: exportProducts[i]["New Price"],
      });
    }
    setProductData(products);
  }

  useEffect(() => {
    if (searchText.length === 0) {
      onClear();
    }
  }, [searchText.length]);

  console.log("ITEM", items);

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  };

  const handleOnSelect = (item) => {
    // the item selected
    const exists = productData.find((p) => p.id === item.id);
    if (exists) {
      alert("Item already selected.");
      return;
    }
    setProductData([...productData, item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  const handleChangeQnantity = (key, price, quantity) => {
    console.log("KYEEASDFSADF", key);
    let exportProductValue = JSON.parse(JSON.stringify(exportProducts));
    let productDataValue = JSON.parse(JSON.stringify(productData));
    if (quantity.trim() == "" || quantity <= 0) {
      let value = exportProductValue.find((item) => item["Sku"] == key);
      console.log("VALUE ", value);
      if (typeof value !== "undefined") {
        value["New Qty"] = 1;
      }
      let value1 = productDataValue.find((item) => item["id"] == key);
      if (typeof value1 !== "undefined") {
        value1.quantity = 1;
      }

      // productData[key].quantity = 1;
      // productData[key].total_price = price;
      setProductData([...productDataValue]);
      setExportProducts([...exportProductValue]);
    } else {
      let value = exportProductValue.find((item) => item["Sku"] == key);
      console.log("VALUE1", value);
      if (typeof value !== "undefined") {
        value["New Qty"] = quantity;
      }
      let value1 = productDataValue.find((item) => item["id"] == key);
      console.log("VALUASDFASDF", value1);
      if (typeof value1 !== "undefined") {
        value1.quantity = quantity;
      }

      // productData[key].quantity = quantity;
      // productData[key].total_price = quantity * price;
      setProductData([...productDataValue]);
      setExportProducts([...exportProductValue]);
    }
  };

  const handleChangePrice = (key, price, new_price) => {
    console.log("KYEEASDFSADF", key);
    let exportProductValue = JSON.parse(JSON.stringify(exportProducts));
    let productDataValue = JSON.parse(JSON.stringify(productData));
    // // if (quantity.trim() == "" || quantity <= 0) {
    // //   let value = exportProductValue.find((item) => item["Sku"] == key);
    // //   console.log("VALUE ", value);
    // //   if (typeof value !== "undefined") {
    // //     value["New Price"] = 1;
    // //   }
    // //   let value1 = productDataValue.find((item) => item["id"] == key);
    // //   if (typeof value1 !== "undefined") {
    // //     value1.quantity = 1;
    // //   }

    // //   // productData[key].quantity = 1;
    // //   // productData[key].total_price = price;
    // //   setProductData([...productDataValue]);
    // //   setExportProducts([...exportProductValue]);
    // // }
    // else {
    let value = exportProductValue.find((item) => item["Sku"] == key);
    console.log("VALUE1", value);
    if (typeof value !== "undefined") {
      value["New Price"] = new_price;
    }
    let value1 = productDataValue.find((item) => item["id"] == key);
    console.log("VALUASDFASDF", value1);
    if (typeof value1 !== "undefined") {
      value1.new_price = new_price;
    }

    // productData[key].quantity = quantity;
    // productData[key].total_price = quantity * price;
    setProductData([...productDataValue]);
    setExportProducts([...exportProductValue]);
    //}
  };

  const removeItem = (key) => {
    if (window.confirm("Are you want to sure to remove this item ?")) {
      productData.splice(key, 1);
      setProductData([...productData]);
    }
  };

  console.log("PRODUCT DATA", productData);

  console.log("EXPORTTTTT", exportProducts);

  const onClickSourceSelection = (value, id) => {
    let products = [...productData];
    let item = products.find((item) => item.id == id);
    if (typeof item !== "undefined") {
      item.source = value;
    }
    setProductData(productData);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    {/* <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span> */}
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Inventory Search"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example12cols2Input"
                      >
                        Select Product
                      </label>
                      <ReactSearchAutocomplete
                        items={productData}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        maxResults={0}
                        showIcon={true}
                        onClear={onClear}

                        // formatResult={formatResult}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Table className="table" responsive>
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Product Name</th>
                        <th>UPC</th>
                        <th>Sku</th>

                        <th>Price</th>
                        {/* <th>New Price</th> */}
                        <th>Current Stock</th>
                        {/* <th>New Quantity</th> */}
                        {/* <th>Source</th> */}
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {productData.map((product, key) => (
                        <tr key={key} id={key}>
                          <td>{key + 1}</td>
                          <td style={{ whiteSpace: "initial" }}>
                            {product.name}
                          </td>
                          <td>{product.upc}</td>
                          <td>{product.id}</td>
                          <td>{product.price}</td>
                          {/* <td>
                                  <input
                                    defaultValue={product.new_price}
                                    onChange={(e) =>
                                      handleChangePrice(
                                        product?.id,
                                        product.price,
                                        e.target.value
                                      )
                                    }
                                    value={product.new_price}
                                    min={0}
                                  />
                                </td> */}

                          <td>{product.count}</td>
                          {/* <td>
                                  <input
                                    type="number"
                                    defaultValue={
                                      product.quantity < 0
                                        ? 0
                                        : product.quantity
                                    }
                                    onChange={(e) =>
                                      handleChangeQnantity(
                                        product?.id,
                                        product.price,
                                        e.target.value
                                      )
                                    }
                                    value={
                                      product.quantity < 0
                                        ? 0
                                        : product.quantity
                                    }
                                    min={0}
                                  />
                                </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
                {/* <Row>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Updating" : "Save"}
                          </Button>
                          {!isSubmitting ? (
                            <Button
                              color="secondary"
                              type="button"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>
                          ) : null}
                        </FormGroup>
                      </Row> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default SearchInventoryProduts;
