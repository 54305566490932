import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { forgotPasswordUser } from "../app/redux/auth/actions";
import { GET_ERRORS } from "../app/redux/auth/types";
// core components

const ForgotPasswordView = () => {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const dispatch = useDispatch();

  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch({
      type: GET_ERRORS,
      payload: {
        Catcherrors: "",
      },
    });
  }, []);
  const forgotPassword = async (data, setSubmitting) => {
    setFormData(data);
    setIsLoading(true);
    dispatch(forgotPasswordUser(data, history, dispatch));
    setSubmitting(false);
    setIsLoading(false);
  };
  return (
    <>
      <div className="header">
        <Container>
          <div className="header-body text-center">
            <Row className="justify-content-center">
              <Col className="" lg="6" md="8" xl="5">
                <div className="mt-6">
                  <img
                    alt="..."
                    className="rounded-circle border-secondary"
                    src={require("../assets/img/brand/logo.png")}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100"></div>
      </div>
      <Container className="pb-8">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card
              className="bg-secondary border-0 mb-0"
              style={{ borderRadius: "0px" }}
            >
              <CardBody className="px-lg-5 py-lg-5 pb-8">
                {auth && auth.errors && auth.errors.Catcherrors && (
                  <p className="text-danger mb-0">
                    <strong>
                      {auth && auth.errors && auth.errors.Catcherrors}
                    </strong>
                  </p>
                )}
                <div className="text-center text-muted mb-4"></div>
                <Formik
                  initialValues={formData}
                  validate={(values) => {
                    const errors = {};

                    if (!values.email) {
                      errors.email = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    //resetPassword(values, setSubmitting);
                    console.log(values);
                    forgotPassword(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form autoComplete="new-password">
                      <div className="FormFieldContainer">
                        {/* <label className="FormLabel">Password</label> */}
                        <h1>
                          <strong>Forgot Your Password?</strong>
                        </h1>
                        <h4>
                          Enter your Email below and we will send a message to
                          reset your password
                        </h4>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            autoComplete="new-password"
                          />
                        </InputGroup>

                        <p className="ErrorMessage">
                          {errors.email && touched.email && errors.email}
                          {auth && auth.errors && auth.errors.email}
                        </p>
                      </div>

                      {/* <div className="FormFieldContainer">
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="Confirm Password"
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                          />
                        </InputGroup>
                        <p className="ErrorMessage">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                          {auth && auth.errors && auth.errors.confirmPassword}
                        </p>
                      </div> */}
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="info"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Forgot Password
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <a
                  className="text-primary"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/login");
                  }}
                >
                  <small className=" font-weight-bold">Login</small>
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPasswordView;
