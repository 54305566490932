import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import { SignUpView, LoginView, PasswordResetView } from "../onboarding";
import { AdminView } from "../admin";
import { HomePage } from "../web";

import getToken from "../onboarding/getToken";
import "./styles.css";
import PublicOrderDetail from "../admin/generated/orders/publicOrderDetail";
import ForgotPasswordView from "../onboarding/ForgotPasswordView";
import ForgotPasswordResetView from "../onboarding/ForgorPasswordResetView";

function AppView() {
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = getToken();

  // alert(isAuthenticated)

  // if (isAuthenticated) {
  //   return (<Redirect to="/admin/users" />)
  // }

  if (isAuthenticated) {
    return <AdminView />;
  }

  return (
    <div className="App">
      <Switch>
        <Route path="/login">
          <LoginView />
        </Route>
        <Route path="/order/:orderId/:locationId">
          <PublicOrderDetail />
        </Route>
        <Route path="/register">
          <SignUpView />
        </Route>
        <Route path="/password-reset">
          <PasswordResetView />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordView />
        </Route>
        <Route path="/forgot-password-reset">
          <ForgotPasswordResetView />
        </Route>
        <Route path="/logout">
          <SignUpView />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </div>
  );
}

export default AppView;
