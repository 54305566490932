import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  Table,
  Input,
} from "reactstrap";
import "./PermissionRole.css";

import FullPageLoader from "../../../components/FullPageLoader";
import { formatDateTimeInTimeZone } from "../../helpers";
import { ApiEndpoints } from "../../constants/constant";
import { getToken } from "../../../onboarding";
import moment from "moment";

function RolePermission(props) {
  const history = useHistory();
  const [dataShowPermission, setDataShowPermission] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCreateRoleNew = () => {
    const createRole = "/admin/role-premission/NewPermissionCreate";
    console.log("33 NewPermissionCreate ==>", createRole);
    history.push(createRole);
  };

  const handeToShowRole = (item) => {
    console.log("CHeck To datta===>", item);
    const showRoleDetails = "/admin/role-premission/viewPremissionDetails/";
    history.push({
      pathname: showRoleDetails,
      state: { item: item, permission: "view" },
    });
  };
  const handleEdit = (item) => {
    console.log("909090909==>", item);
    const editPath = `/admin/role-premission/editPermission`;
    console.log("role-premission edit SHOW ==>", editPath);
    history.push({
      pathname: editPath,
      state: { item: item, permission: "edit" },
    });
  };
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const getShowPermission = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getPermissionApi = `${ApiEndpoints.permissionGetURL}?limit=50&offset=0`;

    fetch(getPermissionApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        const permissionGet = res?.data;
        console.log("ReSpinse 57", res);
        console.log("permissionGetpermissionGet,==>", permissionGet);
        setDataShowPermission(permissionGet);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };

  useEffect(() => {
    getShowPermission();
  }, []);

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Permission"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      size="sm"
                      color="default"
                      onClick={() => handleCreateRoleNew()}
                    >
                       Add New Permission
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="orderlist">
                <Table className="table" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Permission</th>
                      <th>Updated By</th>

                      <th>Actions</th>
                      {/* <th>Total Price</th>
                      <th>Status</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  {dataShowPermission?.map(
                    (item, index) => (
                      console.log("122222===>", item),
                      (
                        <tbody className="list">
                          <tr key={"key"}>
                            <td>
                              {moment(item.updated_at).format("MM/DD/YYYY")}
                            </td>
                            <td>{capitalizeFirst(item.permission)}</td>
                            <td>{capitalizeFirst(item.updated_by)}</td>

                            <td>
                              {item.actions.map((paragraph) => (
                                <h4>
                                  {capitalizeFirst(paragraph).replace("_", " ")}
                                </h4>
                              ))}
                            </td>
                            {/* <td>${order.total_price}</td>
                         <td>{order.status}</td> */}
                            <td>
                              <Button
                                className="btn-icon"
                                size="sm"
                                color="info"
                                type="button"
                                onClick={() => handeToShowRole(item)}
                              >
                                <i class="fa fa-eye"></i>
                              </Button>
                              <Button
                                className="btn-icon-only"
                                size="sm"
                                color="success"
                                onClick={() => handleEdit(item)}
                                type="button"
                              >
                                <i className="fa fa-edit"></i>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )
                    )
                  )}
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default RolePermission;
