import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  Table,
  Input,
  ButtonGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { getToken } from "../../../onboarding";
import { ApiEndpoints } from "../../constants/constant";
import "./style.css";
import { formatDateTimeInTimeZone } from "../../helpers";
import FullPageLoader from "../../../components/FullPageLoader";
import moment from "moment";
import Loader from "../../../onboarding/components/Loader";
var data = require("../response.json");

function OrderListAdmin(props) {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [locationId, setLocaitonId] = useState("");
  const location = useLocation();
  const { orderID } = useParams();

  useEffect(() => {
    let roles = localStorage.getItem("role_actions");
    if (roles) {
      let allRoles = JSON.parse(roles);
      if (!allRoles.includes("inventory_admin_manage")) {
        history.push("/admin/inventory-update");
      }
    }
  }, []);
  const handlePage = () => {
    const viewPath = "/admin/inventory-update-admin/create";
    history.push(viewPath);
  };

  const handleEdit = (item) => {
    const editPath = "/admin/inventory-update-admin/edit/" + item;
    history.push(editPath);
  };

  const handleView = (item) => {
    const viewPath = "/admin/inventory-update-admin/view/" + item;
    history.push(viewPath);
  };

  const handleChange = (e) => {
    console.log("ETAR", e.target.value);
    setLocaitonId(e.target.value);
    localStorage.setItem("transaction_locationId", e.target.value);
  };

  const getLocationList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiEndpoints.locationList}`, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("DATUUUUU", data);
        const locations = data?.data?.Items;
        setLocationData(locations);
        //setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getOrderList = () => {
    setIsLoading(true);
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(
      `${ApiEndpoints.transactionApi}?locationId=${
        localStorage.getItem("selected_location_id").split("#")[1]
      }&limit=30&offset=0`,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("VIEW DATA", data);
        setOrders(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    // if (locationId) {
    getOrderList();
    // }

    getLocationList();
  }, [locationId]);

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Inventory Transaction Admin"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => handlePage("")}
                    >
                      New transaction
                    </Button>
                    {/* <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <Input
                        id="exampleFormControlSelect1"
                        type="select"
                        onChange={handleChange}
                      >
                        {locationData.map((item, index) => (
                          <option
                            key={index}
                            value={item?.pk}
                            selected={
                              item?.pk ===
                              "wh#aa2d8ad1-4ba2-427b-b78f-7da626a379df"
                                ? "selected"
                                : ""
                            }
                          >
                            {item?.title}
                          </option>
                        ))}
                      </Input>
                    </div> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="orderlist">
                <Table className="table" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      {/* <th>Source</th> */}
                      <th>User Name</th>
                      <th>No. Of Items</th>
                      <th>Approved Status</th>
                      <th>Status</th>
                      {/* <th>Total Price</th>
                      <th>Status</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {orders?.map((order, key) => (
                      <tr key={key}>
                        <td>{moment(order.created_at).format("MM/DD/YYYY")}</td>
                        {/* <td>{order.source}</td> */}
                        <td>{order.name}</td>
                        <td>{order.no_of_item}</td>
                        {/* <td>${order.total_price}</td>
                        <td>{order.status}</td> */}
                        <td>{order?.is_approved}</td>
                        <td>{order?.status}</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              className="btn-icon"
                              size="sm"
                              color="info"
                              type="button"
                              onClick={() => handleView(order.id)}
                              id={"Tooltip-" + "view"}
                            >
                              <i class="fa fa-eye"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"Tooltip-" + "view"}
                            >
                              View Transaction
                            </UncontrolledTooltip>
                            <Button
                              className="btn-icon"
                              size="sm"
                              color="success"
                              type="button"
                              onClick={() => handleEdit(order.id)}
                              id={"Tooltip-" + "edit"}
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-edit"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"Tooltip-" + "edit"}
                            >
                              Edit Transaction
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default OrderListAdmin;
