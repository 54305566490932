import React, { useState, useEffect } from "react";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  Form,
  FormGroup,
  UncontrolledAlert,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  Row,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { ApiUrls } from "../../../common/apiconstant";
import { container } from "aws-amplify";

const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();
function EmployeesProfileEdit() {
  const history = useHistory();
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [originalData, setOriginalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  let { userID } = useParams();

  useEffect(() => {
    fetch(`${ApiUrls.getUser}` + userID + "?type=Staff", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setOriginalData(res.data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    fetch(`${ApiUrls.timeZones}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeZones(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(`${ApiUrls.locationList}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        setLocations(response.data.Items);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  const handleView = () => {
    const viewPath = "/admin/employees";
    history.push(viewPath);
  };

  const updateProfile = async (data, setSubmitting) => {
    delete data["ua"];
    delete data["ca"];

    data.type = "Staff";
    data.role = data.user_role;
    data.signUpMode = "Email";

    if (data["isVerified"] == "yes") {
      data.isVerified = true;
    } else if (data["isVerified"] == "no") {
      data.isVerified = false;
    } else {
      data.isVerified = true;
    }

    if (modifiedNonFormData.profileImg !== undefined) {
      if (modifiedNonFormData.profileImg) {
        data["profileImg"] = modifiedNonFormData.profileImg;
      } else {
        let urlArray = data.profileImg.split("/");
        let bucket = urlArray[3].split("?");
        data["profileImg"] = bucket[0];
      }
    } else {
      let urlArray = data.profileImg.split("/");
      let bucket = urlArray[3].split("?");
      data["profileImg"] = bucket[0];
    }

    console.log(data);

    setIsLoading(true);

    fetch(`${ApiUrls.updateUser}` + userID, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, ...modifiedNonFormData }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
        let seterros = [];
        console.log("131565", res);
        if (res.message == "") {
          if (res.data.message == "SUCCESS") {
            setErrorMsg("");
            setSuccessMsg("Profile Updated successfully");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          setSuccessMsg("");
          res.message.map((item) => seterros.push(item));
          setErrorMsg(seterros);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setSubmitting(false);
    setIsLoading(false);
  };

  const handleImageUpload = (event, fieldName) => {
    let selectedfiles = "";
    var newData = { ...modifiedNonFormData };
    const imageFile = event.target.files;
    setButtonDisabled(true);
    if (imageFile.length > 0) {
      selectedfiles = imageFile[0];

      const imagesKays = '[{ "key": "' + fieldName + profileImgUUid + '"}]';

      fetch(`${ApiUrls.imageUpload}`, {
        method: "POST",
        body: imagesKays,
      })
        .then((response) => response.json())
        .then((response) => {
          //console.log(response);
          const presignedUrl = response[0].presignedUrl;
          console.log(presignedUrl);

          fetch(presignedUrl, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            body: selectedfiles,
          })
            .then((res) => {
              setButtonDisabled(false);
              newData[fieldName] = fieldName + profileImgUUid;
              setModifiedNonFormData(newData);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setButtonDisabled(false);
          console.error(error);
        });
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Edit Profile"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Users
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}

                <Formik
                  enableReinitialize
                  initialValues={originalData}
                  validate={(values) => {
                    values = { ...values, ...modifiedNonFormData };
                    const errors = {};
                    {
                      /* Insert all form errors here */
                    }

                    if (!values.firstName) {
                      errors.firstName = "Field Required!";
                    }
                    if (!values.lastName) {
                      errors.lastName = "Field Required!";
                    }
                    if (!values.phoneNo) {
                      errors.phoneNo = "Field Required!";
                    }
                    if (!values.defaultStore) {
                      errors.defaultStore = "Field Required!";
                    }
                    if (!values.currentStore) {
                      errors.currentStore = "Field Required!";
                    }
                    if (!values.isVerified) {
                      errors.isVerified = "Required";
                    }
                    if (!values.user_role) {
                      errors.user_role = "Required";
                    }
                    console.log(errors);
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    updateProfile(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              First Name{" "}
                            </label>
                            <Input
                              className="FormTextField"
                              placeholder="Enter First Name"
                              type="firstName"
                              name="firstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                            />
                            <p className="ErrorMessage">
                              {errors.firstName &&
                                touched.firstName &&
                                errors.firstName}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Last Name
                            </label>
                            <Input
                              placeholder="Enter Last Name"
                              type="text"
                              name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                            />
                            <p className="ErrorMessage">
                              {errors.lastName &&
                                touched.lastName &&
                                errors.lastName}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Email{" "}
                            </label>
                            <Input
                              type="email"
                              name="email"
                              readonly="readonly"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Phone Number{" "}
                            </label>
                            <Input
                              type="tel"
                              placeholder="Enter Phone Number"
                              name="phoneNo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phoneNo}
                            />
                            <p className="ErrorMessage">
                              {errors.phoneNo &&
                                touched.phoneNo &&
                                errors.phoneNo}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Location
                            </label>
                            <Input
                              type="select"
                              name="defaultStore"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select Location</option>
                              {locations.map((location) => (
                                <option
                                  value={location.pk}
                                  selected={
                                    values.defaultStore == location.pk
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {location.title}
                                </option>
                              ))}
                            </Input>
                            <p className="ErrorMessage">
                              {errors.defaultStore &&
                                touched.defaultStore &&
                                errors.defaultStore}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Working Location
                            </label>
                            <Input
                              type="select"
                              name="currentStore"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select Working Location</option>
                              {locations.map((location) => (
                                <option
                                  value={location.pk}
                                  selected={
                                    values.currentStore == location.pk
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {location.title}
                                </option>
                              ))}
                            </Input>
                            <p className="ErrorMessage">
                              {errors.currentStore &&
                                touched.currentStore &&
                                errors.currentStore}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Select Timezone
                            </label>
                            <Input
                              type="select"
                              name="timeZone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select Select Timezone</option>
                              {timeZones.map((item, index) => (
                                <option
                                  key={index}
                                  value={item?.timeZone}
                                  selected={
                                    values.timeZone == item?.timeZone
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </Input>
                            <p className="ErrorMessage">
                              {errors.timeZone &&
                                touched.timeZone &&
                                errors.timeZone}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Verified
                            </label>
                            <Input
                              type="select"
                              name="isVerified"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Is Verified</option>
                              <option
                                value="yes"
                                selected={values.isVerified ? "selected" : ""}
                              >
                                Yes
                              </option>
                              <option
                                value="no"
                                selected={!values.isVerified ? "selected" : ""}
                              >
                                No
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Select Role
                            </label>
                            <Input
                              type="select"
                              name="role"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Role</option>
                              <option
                                value="Admin"
                                selected={
                                  values.user_role === "Admin" ? "selected" : ""
                                }
                              >
                                Admin
                              </option>
                              <option
                                value="Location admin"
                                selected={
                                  values.user_role === "Location admin"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Location Admin
                              </option>
                              <option
                                value="Curator"
                                selected={
                                  values.user_role === "Curator"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Curator
                              </option>
                              <option
                                value="Dispatcher"
                                selected={
                                  values.user_role === "Dispatcher"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Dispatcher
                              </option>
                            </Input>
                            <p className="ErrorMessage">
                              {errors.role && touched.role && errors.role}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Profile Image
                            </label>
                            <br />
                            <input
                              lang="en"
                              type="file"
                              name="profileImg"
                              onChange={(event) => {
                                handleImageUpload(event, "profileImg");
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting || buttonDisabled}
                            >
                              Update Profile
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default EmployeesProfileEdit;
