import React, { useState, useEffect, useRef } from "react";

import Multiselect from "multiselect-react-dropdown";
// import Header from "../Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import "./style.css";
import DatePicker from "react-datepicker";
import { Search } from "react-bootstrap-icons";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
import SubCategory from "./list.js";
import { toast } from "react-toastify";
import { element } from "prop-types";
import styles from "../../styles/modifyOrder.module.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { logUserMetrics } from "../../../utils/file.js";
import FullPageLoader from "../../../components/FullPageLoader";
import Select from "react-select";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const priceListInitial = [
  {
    min: 0,
    max: 10,
    id: 0,
    value: "$0 - $10",
    checked: false,
  },
  {
    min: 10,
    max: 50,
    id: 1,
    value: "$10 - $50",
    checked: false,
  },
  {
    min: 50,
    max: 100,
    id: 2,
    value: "$50 - $100",
    checked: false,
  },
  {
    min: 100,
    max: 500,
    id: 3,
    value: "$100 - $500",
    checked: false,
  },
  {
    min: 500,
    max: 100000,
    id: 4,
    value: "$500 & above",
    checked: false,
  },
];

const categoryMapping = {
  rtd: "rtd",
  wine: "wine",
  liquor: "spirits",
  beer: "beer",
};

function ModifyOrder() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [collections, setCollections] = useState([]);
  const [products, setProducts] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedCollectionList, setSelectedCollectionList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [variantIds, setVariantIds] = useState([]);
  const [collectionFocus, setCollectionFocus] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [searchDataArr, setSearchDataArr] = useState([]);
  const [tempSelectedProducts, setTempSelectedProducts] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [orderID, setOrderID] = useState();
  const [lineItems, setLineItems] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [priceList, setPriceList] = useState(priceListInitial);
  const [priceRange, setPriceRange] = useState({});
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [filterProducts, setFilterProducts] = useState([]);
  const [srcStr, setSrcStr] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [selectedSubCategoriesValue, setSelectedSubCategoriesValue] = useState(
    []
  );

  const categoryToggle = () =>
    setCategoryDropdownOpen((prevState) => !prevState);
  const [subCategoryDropdownOpen, setSubCategoryDropdownOpen] = useState(false);
  const subCategoryToggle = () =>
    setSubCategoryDropdownOpen((prevState) => !prevState);
  const [priceDropdownOpen, setPriceDropdownOpen] = useState(false);
  const priceToggle = () => setPriceDropdownOpen((prevState) => !prevState);
  let appliesRef = useRef();

  // let initialValues = {};

  let { orderId } = useParams();

  const getOrder = async () => {
    setIsLoading(true);
    fetch(`${ApiEndpoints.orderGetById}${orderId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);

        const locId = data?.data?.orderDetail?.locationId;

        console.log("locationIdlocationId", locId);
        setLocationId(locId);
        // getExportProducts(locationId);

        // getAllProductsMenus(locationId);

        const mail = data?.data?.orderDetail?.customer?.email;

        console.log("maill", mail);
        setCustomerEmail(mail);

        const id = data?.data?.orderDetail?.id.toString();
        setOrderID(id);

        const Items = data?.data?.orderDetail.line_items;
        console.log("ItemsItems", Items);
        setLineItems(Items);

        const allItems = data?.data?.orderDetail.line_items;
        for (let i in allItems) {
          data.data.orderDetail.line_items[i].initialQuantity =
            data?.data?.orderDetail.line_items[i].quantity;
        }
        console.log("dataasd", data);

        const extractedVariantIds = Items.map((item) => item.variant_id);
        setVariantIds(extractedVariantIds);

        setOrderDetail(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  console.log("orderDetail", orderDetail);
  console.log("variantIds", variantIds);
  console.log("lineItems", lineItems);

  useEffect(() => {
    getOrder();
  }, [orderId]);

  // const getExportProducts = async (locationId) => {
  //   setIsLoading(true);
  //   try {
  //     let res = await axios.get(
  //       `${ApiEndpoints.getAllProductsInventory}?locationId=${
  //         locationId.split("#")[1]
  //       }&page=1&limit=1000`
  //     );

  //     console.log("RESP====>", res);

  //     let data = res?.data?.data;

  //     if (res.status === 200) {
  //       let products = [];
  //       let exportProductsData = JSON.parse(JSON.stringify(data));
  //       for (let i = 0; i < exportProductsData.length; i++) {
  //         let body = {};
  //         body.title = exportProductsData[i]["item_name"];
  //         body.product_id = parseInt(exportProductsData[i]["product_id"]);
  //         body.price = exportProductsData[i]["price"];
  //         body.variant_id = parseInt(exportProductsData[i]["variant_id"]);
  //         body.lsQty = exportProductsData[i]["ls_qty"];
  //         body.quantity = 1;
  //         products.push(body);
  //         setIsLoading(false);
  //       }
  //       console.log("ABCD", products, exportProductsData, collections);
  //       setProducts(products);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getExportProducts(locationId);
  // }, [locationId]);

  console.log("EXPOT", products);
  console.log("menulocatoinID", locationId);
  // let locId = locationId.split("#")[1];

  const getLocation = (locationId) => {
    fetch(`${ApiEndpoints.getLocationByLocationId}/${locationId.split("#")[1]}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("datadatadata", data);
        // const locations = data.data;
        setCustomerID(data?.data?.customer_id);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (locationId) {
      getLocation(locationId);
    }
  }, [locationId]);

  console.log("custromeetyf", customerID);

  const getAllProductsMenus = async (locationId) => {
    setIsLoading(true);

    let tenantId = localStorage.getItem("tenant_id");

    try {
      let res = await axios.get(
        // `https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/get-menu?tenant_id=25&menu=website_menu&location_id=8e0e2801-8fc8-4bee-bb32-1328214370c0`
        // `https://1tm9iv9ph2.execute-api.us-east-1.amazonaws.com/dev/v1/get-menu?locationId=8e0e2801-8fc8-4bee-bb32-1328214370c0&tenant_id=25&menu=website_menu`
        `${ApiEndpoints.getAllMenus}?location_id=${
          locationId.split("#")[1]
        }&tenant_id=${customerID}&menu=website_menu`
      );

      console.log("menudatata", res);
      let itemNameArr = [];
      let itemName = res?.data?.data?.website_menu;
      itemName.map((item) => {
        return itemNameArr.push({ label: item.display_name, value: item.id });
      });
      console.log("itemNameArr", itemNameArr, itemName);
      setCategoryName(itemNameArr);
      setAllCategory(res?.data?.data?.website_menu);
      setIsLoading(false);
    } catch (error) {
      console.log("errorinmenu", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (locationId && customerID) {
      getAllProductsMenus(locationId);
    }
  }, [locationId, customerID]);

  const handleCategoryToggle = (clickTitle) => {
    let tempArr = [...allCategory];
    console.log("tempArr", tempArr);
    let tempSelectedSubCategories = [...selectedSubCategories];
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(clickTitle)) {
        let tempItem = tempArr.find((e) => e.title == clickTitle);
        console.log("tempItemm", tempItem, allSubCategory);
        let tempRemoveItem = allSubCategory.filter(
          (item) => !tempItem.sub_menu.some((elm) => elm.name === item.name)
        );
        console.log("tempRemoveItem", tempRemoveItem, selectedSubCategories);
        setAllSubCategory(tempRemoveItem);
        let filteredSelectedSubCategories = tempSelectedSubCategories.filter(
          (data) => tempRemoveItem.some((elm1) => elm1.name === data)
        );
        console.log(
          "filteredSelectedSubCategories",
          filteredSelectedSubCategories
        );
        setSelectedSubCategories(filteredSelectedSubCategories);
        return prevSelected.filter((title) => title !== clickTitle);
      } else {
        // let tempItem = tempArr.find((e) => e.title == clickTitle);
        // setAllSubCategory((p) => [...p, ...tempItem.sub_menu]);
        // return [...prevSelected, clickTitle];

        let tempArr = [...allCategory];
        let tempItem = tempArr.find((e) => e.title === clickTitle);
        setAllSubCategory((p) => [...p, ...tempItem.sub_menu]);

        return [...prevSelected, clickTitle];
      }
    });
  };

  console.log("allSubCategory", allSubCategory);

  // const handleSubCategoryToggle = (clickName) => {
  //   setSelectedSubCategories((prevSelected) => {
  //     if (prevSelected.includes(clickName)) {
  //       console.log("prevSelected", prevSelected, clickName);
  //       return prevSelected.filter((name) => name !== clickName);
  //     } else {
  //       // setSrcStr("");
  //       // setSearchResults([]);
  //       return [...prevSelected, clickName];
  //     }
  //   });
  // };

  const handleSubCategoryToggle = (clickName) => {
    setSelectedSubCategories((prevSelected) => {
      if (prevSelected.includes(clickName)) {
        console.log("prevSelected", prevSelected, clickName);
        setFilterProducts([]);
        return prevSelected.filter((name) => name !== clickName);
      } else {
        // setSrcStr("");
        // setSearchResults([]);
        return [...prevSelected, clickName];
      }
    });
  };

  console.log("selectedCategories", selectedCategories);
  console.log("selectedSubCategories", selectedSubCategories);

  function handleCategorySelect(category, subcategory, clear) {
    // if (clear) {
    //   setSelectedFilters((p) => {
    //     return {
    //       [category]: subcategory,
    //     };
    //   });
    //   return;
    // }

    setSelectedFilters((prevState) => {
      const subcategories = prevState[category] || [];
      //if(prevState[category])

      let selectedSubCategory = 0;
      for (let key in selectedFilters) {
        if (selectedFilters[key].length > 0) {
          selectedSubCategory =
            selectedSubCategory + selectedFilters[key].length;
        }
      }

      if (subcategories.includes(subcategory)) {
        // if (selectedSubCategory === 1) {
        //   return {
        //     ...prevState,
        //   };
        // }
        return {
          ...prevState,
          [category]: subcategories.filter((s) => s !== subcategory),
        };
      } else {
        return {
          ...prevState,
          [category]: [...subcategories, subcategory],
        };
      }
    });
  }

  console.log("selectedFilter", selectedFilters);

  function handlePriceSelect(priceId) {
    //console.log("PR", priceId);
    const updatedPriceList = priceList.map((price) => {
      if (price.id === priceId) {
        price.checked = !price.checked;
      }
      return price;
    });

    let minValue = 100000;
    let maxValue = 0;

    //console.log("MIN", minValue, maxValue);

    let newMin;
    let newMax;

    updatedPriceList.map((price) => {
      if (price.checked) {
        if (price.min < minValue) {
          minValue = price.min;
          newMin = price.min;
        }
        if (price.max > maxValue) {
          maxValue = price.max;
          newMax = price.max;
        }
      }
    });

    //const range = ${minValue}-${maxValue};
    setPriceRange({ min: newMin, max: newMax });
    setPriceDropdownOpen(false);
    //setSelectedPrices(range);
  }

  const handleResetPriceList = () => {
    const resetPriceList = priceList.map((item) => ({
      ...item,
      checked: false,
    }));
    setPriceList(resetPriceList);
  };

  console.log("priceRange", priceRange);

  // const options = [
  //   { label: "Red Wine", value: "18" },

  //   { label: "Rose Wine", value: "19" },

  //   { label: "White Wine", value: "20" },

  //   { label: "Dessert Wine", value: "41" },

  //   { label: "Sparkling Wine", value: "22" },

  //   { label: "Champagne", value: "23" },
  // ];

  // let array = [{ label: "liquor", options: options }];

  // const formatGroupLabel = (data) => (
  //   <div>
  //     <span>{data.label}</span>
  //     <span>{data.options.length}</span>
  //   </div>
  // );

  const handleCategoryChange = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.label);
    console.log("selectedLabels 123", selectedLabels);
    setSelectedCategories(selectedLabels);
    let array = [];
    console.log("selectedSubCategories 1", selectedSubCategoriesValue);
    for (let i in selectedSubCategoriesValue) {
      console.log("test", selectedSubCategoriesValue[i].category);
      if (selectedLabels.includes(selectedSubCategoriesValue[i].category)) {
        array.push(selectedSubCategoriesValue[i]);
      }
    }
    const allSubCategories = allCategory.reduce((acc, category) => {
      if (selectedLabels.includes(category.display_name) && category.sub_menu) {
        acc.push({
          label: category.display_name,
          options: category.sub_menu.map((subMenu) => ({
            label: subMenu.name,
            value: subMenu.id,
            category: category.display_name,
          })),
        });
      }
      return acc;
    }, []);

    // let array = [];
    // console.log("selectedSubCategories", selectedSubCategories);

    // for (let i in selectedSubCategories) {
    //   let a = selectedLabels.includes(selectedSubCategories[i].category);
    //   if (a) {
    //     array.push(selectedSubCategories[i]);
    //   }
    // }
    console.log("selectedSubCategories 123", array);
    setSelectedSubCategoriesValue(array);
    console.log("selectedLabels", selectedLabels);
    console.log("AllSubcategories", allSubCategories);
    setSubCategoryName(allSubCategories);
    // setSelectedSubCategories(["Red Wine"]);
  };

  const getSelectedCategoryValues = () => {
    return categoryName.filter((option) =>
      selectedCategories.includes(option.label)
    );
  };

  const handleSubCategoryChange = (selectedOptions) => {
    console.log("selectedOptionsselectedOptions", selectedOptions);
    let selected = selectedSubCategoriesValue;

    for (let i in selectedOptions) {
      selected.push(selectedOptions[i]);
    }
    setSelectedSubCategoriesValue(selectedOptions);
    // const selectedLabels = selectedOptions.map((option) => option.label);
    // console.log("111111111111111", selectedLabels);
    // setSelectedSubCategories(["Red Wine"]);

    // setSelectedSubCategories((p) => [...p, ...selectedLabels]);
  };

  const getSelectedSubCategoryValues = () => {
    let arr = subCategoryName;

    let tempArr = arr.filter((option) =>
      option.options.map((item) => item.label)
    );
    console.log("tempArrtempArr", tempArr, arr);
    // return subCategoryName
    //   .filter((option) => selectedSubCategories.includes(option.label))
    //   .map((selectedSubCategory) => selectedSubCategory.label)
    //   .join(", ");S
  };

  console.log("selectedSubCategoriesValue", selectedSubCategoriesValue);

  console.log("subcategoryname", subCategoryName, selectedSubCategories);

  // useEffect(() => {
  //   getSelectedSubCategoryValues();
  // }, [allCategory]);

  const getExportProductsSearch = async () => {
    // setIsLoading(true);

    // let categories = [];
    // let subCategories = [];

    // let tempSelectedFilters = { ...selectedFilters };

    // for (let key in tempSelectedFilters) {
    //   if (tempSelectedFilters[key].length > 0) {
    //     console.log(
    //       "tempSelectedFilters",
    //       tempSelectedFilters,
    //       key,
    //       tempSelectedFilters[key]
    //     );
    //     categories.push(key);
    //     subCategories = tempSelectedFilters[key];
    //     // ([...tempSelectedFilters[key]]);
    //   }
    // }

    // const flattenedTags = subCategories.flat();

    // console.log("asudhybsuydbusa", categories, subCategories);

    const lowerCaseCategories = selectedCategories.map((category) =>
      category.toLowerCase()
    );

    const mappedCategories = lowerCaseCategories
      .map((category) => {
        switch (category) {
          case "seltzers & rtd":
            return ["seltzer", "rtd"];
          case "liquor":
            return ["liquor", "spirits"];

          default:
            return [category];
        }
      })
      .flat();

    // const mappedCategories = lowerCaseCategories
    //   .map((category) =>
    //     category === "liquor" ? ["liquor", "spirits"] : [category]
    //   )
    //   .flat();

    const filteredSubCategories = selectedSubCategoriesValue.map(
      (subCategory) =>
        subCategory.label.toLowerCase().replace("wine", "").trim()
    );

    console.log("filteredSubCategories", filteredSubCategories);

    // const lowerCaseSubCategories = selectedSubCategoriesValue.map(
    //   (subCategory) => subCategory.toLowerCase()
    // );

    // const filteredSubCategories = lowerCaseSubCategories.map(
    //   (subCategory) => subCategory.replace("wine", "").split(" ")[0]
    // );

    let reqBody = {
      // search_terms: {
      //   out_of_stock: ["false"],
      // },
      // numeric_ranges: [
      //   { "number_sort.price": { gte: priceRange.min, lte: priceRange.max } },
      // ],
      // category: mappedCategories,
      // tags: filteredSubCategories,

      // price: priceList
      //   ? { gte: priceRange.min, lte: priceRange.max }
      //   : { gte: 0, lte: 100000 },
      location_id: locationId.split("#")[1],
      searchKeyword: srcStr,
    };

    if (mappedCategories.length > 0) {
      reqBody.category = mappedCategories;
    }
    if (filteredSubCategories.length > 0) {
      reqBody.tags = filteredSubCategories;
    }

    if (priceRange.min !== undefined && priceRange.max !== undefined) {
      reqBody.price = {
        gte: priceRange.min,
        lte: priceRange.max,
      };
    } else {
      reqBody.price = {
        gte: 0,
        lte: 100000,
      };
    }

    // if (priceRange) {
    //   reqBody.price = {
    //     gte: priceRange.min,
    //     lte: priceRange.max,
    //   };
    // } else {
    //   reqBody.price = {
    //     gte: 0,
    //     lte: 100000,
    //   };
    // }
    setIsLoading(true);
    try {
      let res = await axios.post(`${ApiEndpoints.searchProducts}`, reqBody);

      console.log("RESP====>asdasds", res);
      if (res.status === 200) {
        setSearchResults(res?.data?.data);
        setFilterProducts(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  console.log("priceRange", priceRange);

  useEffect(() => {
    if (
      selectedCategories.length > 0 ||
      selectedSubCategoriesValue.length > 0
    ) {
      getExportProductsSearch();
    } else {
      setSearchResults(null);
      setFilterProducts([]);
    }
  }, [selectedCategories, selectedSubCategoriesValue, priceRange]);

  // useEffect(() => {
  //   // if (srcStr.length === 0) {

  //   //   console.log("kjfijfjojo", srcStr.length);
  //   // }

  //   console.log("jdgcjjk", srcStr.length);
  //   if (srcStr.length >= 2) {
  //     getExportProductsSearch();
  //   } else {
  //     setSearchResults(null);
  //     setFilterProducts([]);
  //   }
  // }, [srcStr.length]);

  // const getExportProductsElasticSearch = async (locationId) => {
  //   setIsLoading(true);

  //   let reqBody = {
  //     locationId: locationId,
  //     search_keyword: srcStr,
  //   };
  //   try {
  //     let res = await axios.post(
  //       `${ApiEndpoints.getAllMenusSearch}?page=0&size=10&bestseller=asc`,
  //       reqBody
  //     );
  //     let resultSearch = res.data;
  //     setSearchResults(resultSearch?.data);
  //     console.log("searchdata", res);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("error", error);
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getExportProductsElasticSearch();
  // }, [srcStr]);

  const handleSearchKeyword = () => {
    if (
      srcStr.trim() !== "" ||
      selectedCategories.length > 0 ||
      selectedSubCategoriesValue.length > 0
    ) {
      getExportProductsSearch();
    }
  };

  const handleInputChange = (e) => {
    setSrcStr(e.target.value);
  };

  const handleKeyPress = (key) => {
    if (srcStr.length > 0) {
      if (key == "Enter") {
        getExportProductsSearch();
      }
    }
  };

  console.log("searchresult", searchResults);

  const handleClearSearchKeyword = () => {
    setSrcStr("");
    setSearchResults([]);
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setSelectedSubCategoriesValue([]);
    setSelectedFilters([]);
    setPriceDropdownOpen(false);
    setPriceRange({});
    setAllSubCategory([]);
    setFilterProducts([]);
    handleResetPriceList();
  };

  console.log("EXPOT", products);

  const handleView = () => {
    history.goBack();
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // const handleSelection = (obj) => {
  //   let arr = JSON.parse(JSON.stringify(selectedProducts));
  //   console.log("ARR", arr, "OBJ", obj);
  //   if (arr.some((element) => element.variant_id == obj.variant_id)) {
  //     let index = arr.findIndex((elm) => elm.variant_id == obj.variant_id);
  //     arr.splice(index, 1);
  //     setSelectedProducts(arr);
  //   } else {
  //     arr.push(obj);
  //     setSelectedProducts(arr);
  //   }
  // };

  const handleSelection = (obj) => {
    if (variantIds.includes(obj.variant_id)) {
      return;
    }
    let arr = JSON.parse(JSON.stringify(selectedProducts));
    console.log("ARR", arr, "OBJ", obj);
    if (arr.some((element) => element.variant_id === obj.variant_id)) {
      let index = arr.findIndex((elm) => elm.variant_id === obj.variant_id);
      arr.splice(index, 1);
    } else {
      obj.isNew = true;
      obj.quantity = 1;
      arr.push(obj);
    }

    console.log("selectedpro3");

    setSelectedProducts(arr);
  };

  console.log("dataarrrr", dataArr);

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    return arr.filter((item) =>
      item.title.toLowerCase().includes(str.toLowerCase())
    );
  };

  const handleAppliesModal = () => {
    setDataArr(products);
    setSearchDataArr(products);
    setTempSelectedProducts(selectedProducts);
    setModal(true);
  };

  const clearSearch = () => {
    setSearchStr("");
  };

  const orderModification = async () => {
    let items = orderDetail?.data?.orderDetail?.line_items;

    let allData = [];
    if (items.length > 0) {
      items.map((item) => {
        if (item.quantity > 0) {
          allData.push(item);
        }
      });
    }

    if (selectedProducts.length > 0) {
      selectedProducts.map((item) => {
        if (item.quantity > 0) {
          allData.push(item);
        }
      });
    }

    // if (selectedProducts.length > 0) {
    //   selectedProducts.map((item) => {
    //     const hasPositiveQuantity = item.variants.some(
    //       (variant) => variant.qty > 0
    //     );

    //     if (hasPositiveQuantity) {
    //       allData.push(item);
    //     }
    //   });
    // }

    console.log("itemsitemsalldata", allData, selectedProducts);

    if (allData.length <= 0) {
      toast.error("Please select at least one item");
      return;
    }

    if (emailError) {
      return;
    }

    if (items.length === allData.length) {
      const hasQuantityChanges = allData.map((newItem) => {
        return newItem.initialQuantity === newItem.quantity;
      });
      console.log("hasQuantityChanges", hasQuantityChanges);
      if (!hasQuantityChanges.includes(false)) {
        toast.error("Please make changes before modifying the order");
        return;
      }
    }

    let requestBody = {
      orderId: orderID,
      line_items: allData,
      // email: CustomerEmail,
    };

    console.log("requestBody", requestBody);

    setIsLoading(true);
    try {
      let res = await axios.post(`${ApiEndpoints.orderModify}`, requestBody);
      console.log("RESPONSE", res);

      if (res.status === 200) {
        toast.success("Order modification request created successfully");
        history.push(`/admin/order/${orderId}/view`);
        setIsLoading(false);
        let action = "order-modification-request-status";
        let type = "Info";
        let message = "Order modification request created successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      let action = "order-modification-request-status";
      let type = "Error";
      let message = "Error in order modification request.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  useEffect(() => {
    if (modal) {
      console.log("searchdatastr", searchStr, dataArr);
      let res = handleSearch(dataArr, searchStr);
      setDataArr(res);
    }

    if (searchStr.length === 0) {
      console.log("searchDataArr", searchDataArr);
      setDataArr(searchDataArr);
    }
  }, [searchStr.length]);

  const handleRemoveItem = (itemId) => {
    let tempData = { ...orderDetail };
    console.log("tempayugau", tempData);
    let removeProduct = tempData?.data?.orderDetail?.line_items.map((item) => {
      if (item.variant_id == itemId) {
        item.isNew = false;
        item.quantity = 0;
      }
    });
    console.log("removeProductremoveProduct", removeProduct);
    console.log("tempayugau1", tempData);
    // let removeIndex = tempData?.data?.orderDetail?.line_items.findIndex(
    //   (item) => item.product_id == itemId
    // );

    // let modifyItemData = tempData?.data?.orderDetail?.line_items.splice(
    //   removeIndex,
    //   1
    // );
    setOrderDetail(tempData);
  };

  const handleRemoveProduct = (variantId) => {
    let items = selectedProducts.filter(
      (product) => product.variant_id !== variantId
    );
    console.log("ITEMS", items);
    console.log("selectedpro4");
    setSelectedProducts({});
  };

  // const handleRemoveProduct = (variantId) => {
  //   let tempArr = [...selectedProducts];
  //   tempArr.map((item) => {
  //     if (item.variant_id == variantId) {
  //       item.isNew = false;
  //       item.quantity = 0;
  //     }
  //   });
  //   setSelectedProducts(tempArr);
  // };

  // const unselectCollectionItem = (id) => {
  //   let tempArr = [...selectedCollectionList];
  //   let index = tempArr.findIndex((elm) => elm.id == id);
  //   if (index >= 0) {
  //     tempArr.splice(index, 1);
  //   }
  //   setSelectedCollectionList(tempArr);
  // };

  console.log("selectedProductsselectedProducts", selectedProducts);

  // const unselectProductsItem = (id) => {
  //   let tempArr = [...selectedProducts];
  //   let index = tempArr.findIndex((elm) => elm.id == id);
  //   if (index >= 0) {
  //     tempArr.splice(index, 1);
  //   }
  //   setSelectedProducts(tempArr);
  // };

  const handleQuantity = (items, qty) => {
    let orderDetails = JSON.parse(JSON.stringify(orderDetail));
    for (let i in orderDetails?.data?.orderDetail?.line_items) {
      if (
        orderDetails.data.orderDetail.line_items[i].variant_id ==
        items.variant_id
      ) {
        orderDetails.data.orderDetail.line_items[i].quantity = Number(qty);
        if (orderDetails.data.orderDetail.line_items[i].quantity == 0) {
          orderDetails.data.orderDetail.line_items[i].isNew = false;
        } else {
          orderDetails.data.orderDetail.line_items[i].isNew = true;
        }
      }
    }

    setOrderDetail(orderDetails);
  };

  // const handleQuantityChange = (item, productQty) => {
  //   let products = JSON.parse(JSON.stringify(selectedProducts));
  //   for (let i in products) {
  //     if (products[i].variant_id == item.variant_id) {
  //       products[i].quantity = Number(productQty);
  //       if (products[i].quantity == 0) {
  //         products[i].isNew = false;
  //       } else {
  //         products[i].isNew = true;
  //       }
  //     }
  //   }
  //   setSelectedProducts(products);
  // };

  const handleQuantityChange = (item, productQty) => {
    let updatedProducts = selectedProducts.map((product) => {
      if (product.variant_id === item.variant_id) {
        const newQuantity = Number(productQty);

        if (newQuantity === 0) {
          // let temp = [...selectedProducts];
          // let arr = temp.filter((elm) => elm.variant_id != item.variant_id);
          // return ;
          // handleRemoveProduct(item.variant_id);
          return null;
        }

        return {
          ...product,
          quantity: newQuantity,
          isNew: true,
        };
      }

      return product;
    });
    console.log("updatedProducts00", updatedProducts);
    console.log("selectedpro5");
    updatedProducts = updatedProducts.filter((product) => product !== null);
    setSelectedProducts(updatedProducts);
  };

  // const handleQuantityChange = (item, productQty) => {
  //   let updatedProducts = selectedProducts.map((product) => {
  //     if (product.variant_id === item.variant_id) {
  //       const newQuantity = Number(productQty);

  //       if (newQuantity >= 0) {
  //         return {
  //           ...product,
  //           quantity: newQuantity,
  //           isNew: true,
  //         };
  //       }
  //     }

  //     return product;
  //   });

  //   setSelectedProducts(updatedProducts);
  // };

  console.log("filterProductsfilterProducts", filterProducts);

  const handleMailChange = (event) => {
    const email = event.target.value;
    setCustomerEmail(email);
    if (!email) {
      setEmailError("Email is required");
    } else if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  console.log("SELECTED_COLL", selectedCollectionList);

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        // style={{ padding: 0 }}
        backdrop="static"
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            toggle();
            clearSearch();
          }}
          close={() => {
            clearSearch();
            setModal(false);
            setTempSelectedProducts([]);
          }}
          style={{ width: "100%", display: "block" }}
        >
          <div style={{ padding: 20 }}>{"Add Products"}</div>
          {/* <Card>
            <CardBody
              style={{
                background: "#f1f1f1",
                borderRadius: "20px",
                zIndex: 100,
                fontSize: "14px",
              }}
            > */}
        </ModalHeader>
        <ModalBody
          style={{
            background: "#f1f1f1",
          }}
        >
          <div>
            <InputGroup
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "-15px",
              }}
            >
              <Input
                placeholder="Search products by name and category"
                style={{
                  height: "38px",
                  borderRadius: "5px",
                  border: "1px solid #9d9d9d",
                }}
                value={srcStr}
                onChange={handleInputChange}
                onKeyPress={(e) => handleKeyPress(e.key)}
                autoFocus={true}
              />
              {srcStr.length > 0 ||
              selectedCategories.length > 0 ||
              selectedSubCategoriesValue.length > 0 ? (
                <ButtonGroup
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    background: "#f1f1f1",
                    padding: "10px",
                    // borderRadius: "5px",
                  }}
                >
                  <Button
                    onClick={handleSearchKeyword}
                    // color="secondary"
                    type="button"
                    size="sm"
                    style={{ background: "#f1f1f1" }}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={handleClearSearchKeyword}
                    // color="secondary"
                    type="button"
                    size="sm"
                    style={{ background: "#f1f1f1" }}
                  >
                    Clear
                  </Button>
                </ButtonGroup>
              ) : null}
            </InputGroup>
            {/* <Dropdown isOpen={categoryDropdownOpen} toggle={categoryToggle}>
               <DropdownToggle caret size="sm">
                 Category
               </DropdownToggle>
               <DropdownMenu>
                 {allCategory?.map((item) => (
                   <div key={item.id}>
                     <DropdownItem
                       toggle={false}
                       style={{
                         display: "flex",
                         alignItems: "center",
                         gap: "10px",
                       }}
                       onClick={() => handleCategoryToggle(item.title)}
                     >
                       <span>
                         {selectedCategories.includes(item.title) ? "-" : "+"}
                       </span>
                       {item?.display_name}
                     </DropdownItem>
                     {selectedCategories.includes(item.title) && (
                       <>
                         {item.sub_menu?.map((submenuItem) => (
                           <DropdownItem
                             key={submenuItem.id}
                             size="sm"
                             style={{
                               display: "flex",
                               alignItems: "center",
                               gap: "10px",
                             }}
                           >
                             <input
                               type="checkbox"
                               checked={selectedSubCategories.includes(
                                 submenuItem.name
                               )}
                               onChange={() =>
                                 handleCategorySelect(
                                   item.title,
                                   submenuItem.name
                                 )
                               }
                             />
                             {submenuItem.name}
                           </DropdownItem>
                         ))}
                       </>
                     )}
                   </div>
                 ))}
               </DropdownMenu>
             </Dropdown> */}
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop:
                  srcStr.length > 0 ||
                  selectedCategories.length > 0 ||
                  selectedSubCategoriesValue.length > 0
                    ? "-15px"
                    : "",
              }}
            >
              <Select
                placeholder="Search by category"
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="categories"
                options={categoryName}
                isMulti={true}
                value={getSelectedCategoryValues()}
                onChange={handleCategoryChange}
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    // border: "1px solid #9d9d9d",
                    // borderRadius:5,
                    height: "auto",
                    // minHeight: "45px",
                    width: "35%",
                    // maxWidth: "40%",
                    borderRadius: "5px",
                    border: "1px solid #9d9d9d",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: 15,
                    color: "#adb5bd",
                  }),
                  menuList: (styles) => ({
                    ...styles,
                    fontSize: 13,
                    fontWeight: 500,
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    fontSize: 15,
                  }),
                }}
              />

              <Select
                placeholder={
                  selectedCategories.length === 0
                    ? "No sub-category selected"
                    : "Search by subcategory"
                }
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="subcategories"
                options={subCategoryName}
                isMulti={true}
                value={selectedSubCategoriesValue}
                // value={[{ value: "hghg", label: "rdgdgfd" }]}
                onChange={handleSubCategoryChange}
                isDisabled={selectedCategories.length === 0}
                // formatGroupLabel={formatGroupLabel}
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    height: "auto",
                    width: "35%",
                    borderRadius: "5px",
                    border: "1px solid #9d9d9d",
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: 15,
                    color: "#adb5bd",
                  }),
                  menuList: (styles) => ({
                    ...styles,
                    fontSize: 13,
                    fontWeight: 500,
                  }),
                  multiValue: (styles) => ({
                    ...styles,
                    fontSize: 15,
                  }),
                  groupHeading: (styles) => ({
                    ...styles,
                    fontWeight: 1000,
                  }),
                }}
              />
              <Dropdown
                isOpen={priceDropdownOpen}
                toggle={priceToggle}
                disabled={selectedCategories.length === 0}
              >
                <DropdownToggle
                  caret
                  size="sm"
                  style={{
                    background: "none",
                  }}
                >
                  Price
                </DropdownToggle>
                <DropdownMenu>
                  {priceList.map((item) => (
                    <DropdownItem
                      toggle={false}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handlePriceSelect(item.id)}
                      />
                      {item?.value}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {priceRange.min !== undefined && priceRange.max !== undefined ? (
                <span style={{ fontSize: "13px" }}>
                  (${priceRange.min} - ${priceRange.max})
                </span>
              ) : null}
            </div>
            {/* <Dropdown isOpen={categoryDropdownOpen} toggle={categoryToggle}>
              <DropdownToggle caret size="sm">
                Category
              </DropdownToggle>
              <DropdownMenu>
                {allCategory?.map((item) => (
                  <div key={item.id}>
                    <DropdownItem
                      toggle={false}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedCategories.includes(item.title)}
                        onChange={() => handleCategoryToggle(item.title)}
                      />
                      {item?.display_name}
                    </DropdownItem>
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={subCategoryDropdownOpen}
              toggle={subCategoryToggle}
              disabled={selectedCategories.length === 0}
            >
              <DropdownToggle caret size="sm">
                {selectedCategories.length === 0
                  ? "No category selected"
                  : "Sub-Category"}
              </DropdownToggle>
              <DropdownMenu>
                {allSubCategory?.map((item) => (
                  <div key={item.id}>
                    <DropdownItem
                      toggle={false}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedSubCategories.includes(item.name)}
                        onChange={() => handleSubCategoryToggle(item.name)}
                      />
                      {item?.name}
                    </DropdownItem>
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown> */}
            {/* <Dropdown
              isOpen={subCategoryDropdownOpen}
              toggle={subCategoryToggle}
            >
              <DropdownToggle caret size="sm">
                Sub-category
              </DropdownToggle>
              <DropdownMenu>
                {selectedCategories.map((selectedCategory) => (
                  <div key={selectedCategory}>
                    {allCategory
                      ?.find((item) => item.title === selectedCategory)
                      ?.sub_menu?.map((submenuItem) => (
                        <DropdownItem
                          key={submenuItem.id}
                          size="sm"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedSubCategories.includes(
                              submenuItem.name
                            )}
                            onChange={() =>
                              handleCategorySelect(
                                selectedCategory,
                                submenuItem.name
                              )
                            }
                          />
                          {submenuItem.name}
                        </DropdownItem>
                      ))}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown> */}
            {/* <Dropdown
                isOpen={priceDropdownOpen}
                toggle={priceToggle}
                disabled={selectedSubCategories.length === 0}
              >
                <DropdownToggle caret size="sm">
                  Price
                </DropdownToggle>
                <DropdownMenu>
                  {priceList.map((item) => (
                    <DropdownItem
                      toggle={false}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handlePriceSelect(item.id)}
                      />
                      {item?.value}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {priceRange.min !== undefined && priceRange.max !== undefined ? (
                <>
                  {priceRange.min} & {priceRange.max}
                </>
              ) : null} */}
            {/* </CardBody>
          </Card> */}
          </div>
        </ModalBody>
        <ModalBody
          style={{
            // height:
            //   (filterProducts?.length || searchResults?.length) > 0
            //     ? 450
            //     : "auto",
            height: "auto",
            maxHeight: "250px",
            overflowY: "auto",
            padding: 0,
          }}
        >
          {/* {dataArr?.length > 0 ? (
            dataArr.map((item, i) => (
              <div
                key={item?.variant_id + "" + i}
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: 10,
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  padding: 20,
                }}
              >
                <input
                  type="checkbox"
                  checked={
                    selectedProducts.some(
                      (element) => element.variant_id == item.variant_id
                    ) || variantIds.includes(item.variant_id)
                  }
                  onChange={() => handleSelection(item)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ marginBottom: 0, fontSize: "14px" }}>
                    {item?.title}
                  </p>
                  {item?.price && (
                    <p style={{ marginBottom: 0, fontSize: "14px" }}>
                      ${item?.price}
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div style={{ padding: 20, textAlign: "center" }}>
              No data found.
            </div>
          )} */}
          {filterProducts && filterProducts === null && <></>}
          {(filterProducts && filterProducts.length > 0) ||
          (searchResults && searchResults.length > 0) ? (
            filterProducts?.map((item, i) => (
              <div
                key={item?.variant_id + "" + i}
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: 10,
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  padding: "10px 15px 10px 15px",
                }}
              >
                <input
                  type="checkbox"
                  checked={
                    selectedProducts.some(
                      (element) => element.variant_id == item.variant_id
                    ) || variantIds.includes(item.variant_id)
                  }
                  onChange={() => handleSelection(item)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ marginBottom: 0, fontSize: "13px" }}>
                    {item?.item_name}
                  </p>
                  {item?.price && (
                    <p style={{ marginBottom: 0, fontSize: "13px" }}>
                      ${item?.price}
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : (
            // :
            //  selectedCategories.length > 0 ||
            //   selectedSubCategoriesValue.length > 0 ? (
            //   <div style={{ padding: 20, textAlign: "center" }}>
            //     No data found.
            //   </div>
            // )
            <div style={{ padding: 20, textAlign: "center" }}>
              {searchResults === null
                ? "Please select category & sub-category"
                : searchResults.length === 0
                ? "No data found"
                : "No data found"}
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {/* <Button
            color="primary"
            onClick={() => {
              clearSearch();
              toggle();
            }}
          >
            Add
          </Button>{" "} */}
          <Button
            color="secondary"
            onClick={() => {
              setSelectedCategories([]);
              setSelectedSubCategories([]);
              setSelectedSubCategoriesValue([]);
              setSelectedFilters([]);
              setPriceDropdownOpen(false);
              setPriceRange({});
              setSrcStr("");
              setSearchResults([]);
              setAllSubCategory([]);
              setFilterProducts([]);
              handleResetPriceList();
              clearSearch();
              toggle();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="8">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Modify Order"}
                    </h6>
                    <h4
                      class="checklist-title mb-0"
                      style={{ marginLeft: "25px" }}
                    >
                      {orderDetail?.data?.orderDetail?.name}
                    </h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={originalData}
                  validate={(values) => {
                    let value = values;
                    console.log("value", values);
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // editDiscount(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col
                          lg="12"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            overflow: "auto",
                          }}
                        >
                          <Col lg="6">
                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "850px",
                                  }}
                                >
                                  <CardBody>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <CardTitle
                                        tag="h3"
                                        style={{ marginBottom: 0 }}
                                      >
                                        Products
                                      </CardTitle>
                                      <Button
                                        style={{
                                          height: "35px",
                                          borderRadius: "5px",
                                        }}
                                        size="sm"
                                        color="default"
                                        onClick={() => handleAppliesModal()}
                                      >
                                        Add Product
                                      </Button>
                                    </div>
                                    {/* <hr /> */}
                                    <CardText
                                      style={{
                                        display: "flex",
                                        gap: 8,
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* <Button
                                        style={{
                                          height: "40px",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() => handleAppliesModal()}
                                      >
                                        Add Product
                                      </Button> */}
                                      {/* <Input
                                        style={{
                                          height: "40px",
                                          borderRadius: "10px",
                                        }}
                                        placeholder={"Search products to add"}
                                        // onFocus={() => {
                                        //   setCollectionFocus(true);
                                        // }}
                                        // onBlur={() => {
                                        //   setCollectionFocus(false);
                                        // }}
                                        value={""}
                                        onChange={(e) => {
                                          handleAppliesModal();
                                          // setSearchStr(e.target.value);
                                        }}
                                        onClick={() => handleAppliesModal()}
                                        ref={appliesRef}
                                      /> */}
                                    </CardText>
                                    <Row>
                                      <Col lg="12">
                                        {/* <Label
                                          className="form-control-label"
                                          htmlFor="reasonInput"
                                        >
                                          Items
                                        </Label> */}
                                        <Table>
                                          <thead>
                                            <tr>
                                              <th>Item Name</th>
                                              <th>Item Quantity</th>
                                              <th></th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody className={styles.tableBody}>
                                            {orderDetail?.data?.orderDetail?.line_items.map(
                                              (items) => {
                                                // let qty = items.quantity;
                                                console.log("items", items);
                                                // const initialQuantity =
                                                //   items.initialQuantity ||
                                                //   items.quantity;
                                                // const isModified =
                                                //   items.isNew ||
                                                //   items.quantity !==
                                                //     items.initialQuantity;
                                                return (
                                                  <tr
                                                    key={items.id}
                                                    style={{
                                                      backgroundColor:
                                                        items?.isNew == false
                                                          ? "#e9ecef"
                                                          : "inherit",
                                                      fontWeight:
                                                        items?.isNew == false
                                                          ? "100"
                                                          : "600",
                                                    }}
                                                  >
                                                    <td
                                                    // style={{
                                                    //   fontWeight:
                                                    //     items?.isNew == false
                                                    //       ? "100"
                                                    //       : "",
                                                    // }}
                                                    >
                                                      {items.name}
                                                    </td>
                                                    <td
                                                      style={{
                                                        padding:
                                                          "0.4rem 0.8rem",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          borderRadius: "8px",
                                                          // overflow: "hidden",
                                                          padding:
                                                            "0.4rem 0.5rem 0.4rem 0.5rem",
                                                          // display: "flex",
                                                          // alignItems:
                                                          //   "center",
                                                          width: "45%",
                                                        }}
                                                      >
                                                        <input
                                                          type="number"
                                                          name="lineItems"
                                                          min={0}
                                                          defaultValue={
                                                            items.quantity
                                                          }
                                                          style={{
                                                            border: "none",
                                                            outline: "none",
                                                            background: "none",
                                                            flex: 0.5,
                                                            width: "100%",
                                                          }}
                                                          value={items.quantity}
                                                          onChange={(e) =>
                                                            handleQuantity(
                                                              items,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <span
                                                        aria-hidden="true"
                                                        style={{
                                                          marginRight: "30px",
                                                          cursor: "pointer",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveItem(
                                                            items.variant_id
                                                          )
                                                        }
                                                      >
                                                        {items.quantity > 0 ? (
                                                          <i className="fas fa-trash-alt"></i>
                                                        ) : (
                                                          "X"
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {items.quantity > 0 ? (
                                                        items.initialQuantity !==
                                                        items.quantity ? (
                                                          <>
                                                            <span>
                                                              Modified
                                                            </span>
                                                          </>
                                                        ) : null
                                                      ) : (
                                                        <span>Removed</span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                            {selectedProducts.length > 0 &&
                                              selectedProducts?.map(
                                                (product, i) => (
                                                  <tr
                                                    key={i}
                                                    style={{
                                                      // backgroundColor:
                                                      //   items?.isNew == false
                                                      //     ? "#e9ecef"
                                                      //     : "inherit",
                                                      fontWeight:
                                                        product?.isNew == false
                                                          ? "100"
                                                          : "600",
                                                    }}
                                                  >
                                                    <td>
                                                      {product?.item_name}
                                                    </td>
                                                    <td
                                                      style={{
                                                        padding:
                                                          "0.4rem 0.8rem",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          borderRadius: "8px",
                                                          // overflow: "hidden",
                                                          padding:
                                                            "0.4rem 0.5rem 0.4rem 0.5rem",
                                                          // display: "flex",
                                                          // alignItems: "center",
                                                          width: "45%",
                                                        }}
                                                      >
                                                        <input
                                                          type="number"
                                                          name="selected_products"
                                                          min={0}
                                                          max={product?.lsQty}
                                                          defaultValue={1}
                                                          style={{
                                                            border: "none",
                                                            outline: "none",
                                                            background: "none",
                                                            flex: 0.5,
                                                            width: "100%",
                                                          }}
                                                          value={
                                                            product?.quantity
                                                          }
                                                          onChange={(e) =>
                                                            handleQuantityChange(
                                                              product,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                    <td
                                                    // style={{
                                                    //   padding: "0px 0px 20px 0px",
                                                    // }}
                                                    >
                                                      {/* <Button
                                                    size="sm"
                                                    color="link"
                                                    onClick={() =>
                                                      handleRemoveProduct(
                                                        product.variant_id
                                                      )
                                                    }
                                                  >
                                                    Remove Item
                                                  </Button> */}
                                                      <span
                                                        aria-hidden="true"
                                                        style={{
                                                          marginRight: "30px",
                                                          cursor: "pointer",
                                                          // display: "flex",
                                                          // alignItems: "center",
                                                          // justifyContent:
                                                          //   "space-between",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveProduct(
                                                            product?.variant_id
                                                          )
                                                        }
                                                      >
                                                        <i class="fas fa-trash-alt"></i>{" "}
                                                      </span>
                                                      {/* <Button
                                                    size="sm"
                                                    color="link"
                                                    onClick={() =>
                                                      handleRemoveProduct(
                                                        product.variant_id
                                                      )
                                                    }
                                                  >
                                                    Remove Item
                                                  </Button> */}
                                                    </td>
                                                    <td>
                                                      <span>Added</span>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                        </Table>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col>
                            {/* <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "850px",
                                  }}
                                >
                                  <CardHeader>
                                    <h4>Customer E-mail</h4>
                                  </CardHeader>
                                  <CardBody>
                                    <Input
                                      id="customer_email"
                                      placeholder="Customer E-mail"
                                      type="email"
                                      name="customer_email"
                                      value={CustomerEmail}
                                      onChange={handleMailChange}
                                    />
                                    {emailError && (
                                      <span
                                        style={{ color: "red", fontSize: 12 }}
                                      >
                                        {emailError}
                                      </span>
                                    )}
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col> */}
                            <Col>
                              <FormGroup>
                                <Button
                                  color="primary"
                                  // type="submit"
                                  onClick={orderModification}
                                  disabled={isSubmitting}
                                >
                                  Update Order
                                </Button>
                              </FormGroup>
                            </Col>
                          </Col>

                          <div>
                            <Col lg="9" style={{ width: "100%" }}>
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{ width: "450px" }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">Notes</CardTitle>
                                    <CardText style={{ fontSize: "0.8125rem" }}>
                                      {orderDetail?.data?.orderDetail?.note !==
                                      null ? (
                                        <div>
                                          {orderDetail?.data?.orderDetail?.note}
                                        </div>
                                      ) : (
                                        <div>No notes from customer</div>
                                      )}
                                    </CardText>
                                    <CardTitle tag="h4">
                                      Additional Details
                                    </CardTitle>
                                    {orderDetail?.data?.orderDetail?.note_attributes.map(
                                      (item, index) => (
                                        <CardText
                                          style={{ fontSize: "0.8125rem" }}
                                          key={index}
                                        >
                                          <span style={{ fontWeight: "bold" }}>
                                            {item.name} : {""}
                                          </span>
                                          {item.value}
                                        </CardText>
                                      )
                                    )}

                                    {/* {orderDetail?.data?.orderDetail.map(
                                      (item, index) => (
                                        <CardText
                                          style={{ fontSize: "0.8125rem" }}
                                          key={index}
                                        >
                                          <span style={{ fontWeight: "bold" }}>
                                            {item?.customer?.name} : {""}
                                          </span>
                                          {item.value}
                                        </CardText>
                                      )
                                    )} */}
                                  </CardHeader>
                                </Card>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{ width: "450px" }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">Customer</CardTitle>
                                    <CardText style={{ fontSize: "0.8125rem" }}>
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.billing_address?.name
                                      }
                                    </CardText>
                                    <CardTitle tag="h4">
                                      Contact Information
                                    </CardTitle>
                                    <CardText style={{ fontSize: "0.8125rem" }}>
                                      {
                                        orderDetail?.data?.orderDetail?.customer
                                          ?.email
                                      }
                                    </CardText>
                                    <CardTitle tag="h4">
                                      Shipping Address
                                    </CardTitle>
                                    <CardText style={{ fontSize: "0.8125rem" }}>
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.name
                                      }
                                      <br />
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.address1
                                      }{" "}
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.address2
                                      }
                                      <br />
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.city
                                      }{" "}
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.province_code
                                      }{" "}
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.zip
                                      }
                                      <br />
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.country
                                      }
                                      <br />
                                      {
                                        orderDetail?.data?.orderDetail
                                          ?.shipping_address?.phone
                                      }
                                    </CardText>
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default ModifyOrder;
