import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  UncontrolledAlert,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import { logUserMetrics } from "../../../utils/file";
import { useHistory, withRouter } from "react-router-dom";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useOutsideClick from "../hooks/useOutsideClick.js";
import styles from "../../styles/blackListCreate.module.css";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BlackListUsersCreate = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [orderNumber, setOrderNumber] = useState("");
  const [orderDetail, setOrderDetail] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [clear, setClear] = useState(false);
  const [initialData, setInitialData] = useState({
    customer_id: "",
    name: "",
    email: "",
    order_email: "",
    phone: "",
    address: "",
    reason: "",
    billing_address: "",
    order_number: "",
    delivery_address_name: "",
  });

  const inputRef = useRef();

  useOutsideClick(inputRef, () => setShowSuggestion(false));

  //   const getTenantsList = () => {
  //     setIsLoading(true);
  //     let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
  //     fetch(getTenantsApi)
  //       .then((response) => response.json())
  //       .then((res) => {
  //         console.log(res);
  //         const responseTenants = res?.data;
  //         console.log("ReSpinse 57", res);
  //         let sugg = [];
  //         for (let i in responseTenants) {
  //           sugg.push({
  //             value: responseTenants[i].id,
  //             label: responseTenants[i].business_name,
  //           });
  //         }
  //         setCustomers(sugg);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log("51954949", err);
  //       });
  //   };

  //   useEffect(() => {
  //     getTenantsList();
  //   }, []);

  const resetFunction = () => {
    setInitialData({
      customer_id: "",
      name: "",
      email: "",
      order_email: "",
      phone: "",
      address: "",
      billing_address: "",
      reason: "",
      order_number: "",
      delivery_address_name: "",
    });
  };

  const getOrder = () => {
    setIsLoading(true);
    let getBlackListUser = `${ApiEndpoints.priceOrderList}?page=1&limit=20`;

    fetch(getBlackListUser)
      .then((response) => response.json())
      .then((res) => {
        console.log("response", res);
        const responseOrder = res?.data;
        console.log("responseorder", responseOrder);

        let updatedData = res?.data?.map((item) => ({
          ...item,
          value: item?.id,
          label: item?.order_number,
        }));
        setOrderDetail(updatedData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getOrder();
  }, [clear]);

  const getOrderByKeyword = () => {
    setIsLoading(true);
    let getBlackListUser = `${ApiEndpoints.priceOrderList}?searchKeyword=${searchKeyword}`;

    fetch(getBlackListUser)
      .then((response) => response.json())
      .then((res) => {
        console.log("response", res);
        const responseOrder = res?.data;
        console.log("responseorder", responseOrder);

        let updatedData = res?.data?.map((item) => ({
          ...item,
          value: item?.id,
          label: item?.order_number,
        }));
        setOrderDetail(updatedData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    if (searchKeyword?.length > 0) {
      getOrderByKeyword();
    }
  }, [searchKeyword]);

  const handleOrderDetail = (item) => {
    console.log("ITEM SDD", item);
    // if(searchKeyword.length>0){
    //   setSearchKeyword()
    // }

    let IdOrder = item?.pk.split("#")[1];
    console.log("ITEM ORDER NUMBER", IdOrder);
    setOrderId(IdOrder);

    let orderName = item?.order_number;
    setOrderNumber(orderName);
  };

  const getOrderById = (orderId) => {
    setIsLoading(true);
    fetch(ApiEndpoints.orderGetById + orderId)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        console.log("orderdatabyid", data);
        let response = data?.data?.orderDetail;
        console.log("orderdetailsbyid", response);

        let checkOutMethodName = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "Checkout-Method"
        );

        // if (checkOutMethodName) {
        //   setCheckOutMethod(checkOutMethodName.value);
        // }

        console.log("checkOutMethodName", checkOutMethodName);

        let firstName = response?.customer?.first_name;
        let lastName = response?.customer?.last_name;
        let name = firstName + " " + lastName;
        let addressName;
        let phoneNumber;
        let deliveryAddressName;
        if (checkOutMethodName?.value?.toLowerCase() === "delivery") {
          deliveryAddressName = response?.shipping_address?.name;
          phoneNumber = response?.shipping_address?.phone;
          addressName = response?.shipping_address?.address1;
        } else if (checkOutMethodName?.value.toLowerCase() === "local pickup") {
          deliveryAddressName = response?.billing_address?.name;
          phoneNumber = response?.billing_address?.phone;
          addressName = response?.billing_address?.address1;
        }
        console.log(
          "deliveryAddressName",
          deliveryAddressName,
          checkOutMethodName,
          response
        );
        let mail = response?.customer?.email;
        if (mail && mail?.length >= 52) {
          const noteAttributes = data?.data?.orderDetail?.note_attributes;
          const emailFromNoteAttributes = noteAttributes.find(
            (item) => item?.name === "email"
          )?.value;

          if (
            emailFromNoteAttributes &&
            emailFromNoteAttributes?.length <= 52
          ) {
            mail = emailFromNoteAttributes;
          }
        }
        console.log("mailofguest", mail);
        console.log("phonenumberinorder", phoneNumber);
        setInitialData({
          customer_id: response?.customer?.id?.toString(),
          name: name,
          email: response?.customer?.email,
          order_email: mail,
          phone: phoneNumber,
          address: addressName,
          delivery_address_name: deliveryAddressName,
          billing_address: response?.billing_address?.address1,
          reason: "",
          order_number: response?.order_number?.toString(),
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (orderId) {
      getOrderById(orderId);
    } else if (orderId === undefined) {
      setInitialData({
        customer_id: "",
        name: "",
        email: "",
        order_email: "",
        phone: "",
        address: "",
        billing_address: "",
        reason: "",
        order_number: "",
        delivery_address_name: "",
      });
    }
  }, [orderId]);

  console.log("Initial Data", initialData);

  console.log("Order Id", orderId);

  const getAllUserList = async () => {
    // setIsLoading(true);

    let url = `${ApiEndpoints.consumersList}?page=1&limit=10`;

    console.log("search--word", searchWord);

    if (searchWord) {
      url = url + `&searchKeyword=${searchWord}`;
    }

    const res = await axios.get(url);
    console.log("REs", res);
    let updatedData = res?.data?.data;
    // ?.map((item) => ({
    //   ...item,
    //   value: item?.id,
    //   label: item.firebase_id,
    // }));
    // let tempData = [...updatedData];
    // let arr = [];
    // tempData.map((item, i) =>
    //   arr.push({
    //     ...item,
    //     id: Number(item.id),
    //     //name: item?.first_name + " " + item?.last_name + " " + item?.email,
    //   })
    // );

    console.log("All users", updatedData);
    setAllUser(updatedData);
    // setIsLoading(false);
  };

  console.log("AlluserList", allUser);

  // console.log("searchKeyword", searchKeyword, searchWord);

  useEffect(() => {
    getAllUserList();
  }, []);

  useEffect(() => {
    if (searchWord.length > 0) {
      getAllUserList();
    }
  }, [searchWord]);

  // const handleSearch = (arr, str) => {
  //   console.log("ARRR", arr);
  //   let res = arr.filter(
  //     (item) =>
  //       (item &&
  //         item["email"] &&
  //         item["email"]?.toLowerCase().includes(str.toLowerCase())) ||
  //       item["phone"]?.toLowerCase().includes(str.toLowerCase()) ||
  //       item["first_name"]?.toLowerCase().includes(str.toLowerCase()) ||
  //       item["last_name"]?.toLowerCase().includes(str.toLowerCase())
  //   );
  //   console.log("RESULT", res);
  //   return res;
  // };

  // const handleOnSearch = (string, results) => {
  //   // onSearch will have as the first callback parameter
  //   // the string searched and for the second the results.
  //   setSearchText(string);
  //   console.log("STRING", string, results);

  //   let searchRes = handleSearch(allUser, string);
  //   console.log("SEra", searchRes);
  //   setAllUser(searchRes);
  // };

  // // function onClear() {
  // //   let products = [];
  // //   for (let i = 0; i < exportProducts.length; i++) {
  // //     products.push({
  // //       sku: exportProducts[i]["sku"],
  // //       upc: exportProducts[i]["upc"],
  // //       price: exportProducts[i]["price"],
  // //       name: exportProducts[i]["name"],
  // //       ls_qty: exportProducts[i]["ls_qty"],
  // //       status: exportProducts[i]["status"],
  // //     });
  // //   }
  // //   console.log("asdsad4as84", products);
  // //   setData(products);
  // // }

  // const handleOnSelect = (item) => {
  //   const exists = allUser.find((p) => p.id === item.id);
  //   console.log("exists", exists, item);
  // };

  // const formatResult = (item) => {
  //   console.log("ITEMS ,it", item);
  //   return (
  //     <>
  //       {/* <span style={{ display: "block", textAlign: "left" }}>
  //         id: {item.id}
  //       </span> */}
  //       <span style={{ display: "block", textAlign: "left" }}>{item}</span>
  //     </>
  //   );
  // };

  const addBlackListUser = async (data, setSubmitting) => {
    if (
      data?.customer_id?.length == 0 &&
      data?.name?.length == 0 &&
      data?.email?.length == 0 &&
      data?.order_email?.length == 0 &&
      data?.phone?.length == 0 &&
      data?.address?.length == 0 &&
      data?.delivery_address_name?.length == 0 &&
      data?.billing_address?.length == 0
    ) {
      toast.error("Please add one more field to continue");
      setSubmitting(false);
      return;
    }

    let finalName;
    if (data?.name?.toLowerCase() == "guest user") {
      finalName = "";
    } else {
      finalName = data?.name;
    }

    let addressName;
    if (data?.delivery_address_name?.toLowerCase() == "guest user") {
      addressName = "";
    } else {
      addressName = data?.delivery_address_name;
    }

    if (
      data?.customer_id?.length == 0 &&
      finalName?.length == 0 &&
      data?.email?.length == 0 &&
      data?.order_email?.length == 0 &&
      data?.phone?.length == 0 &&
      data?.address?.length == 0 &&
      addressName?.length == 0 &&
      data?.billing_address.length == 0
    ) {
      toast.error(
        `Name and delivery contact name field cannot be "Guest User"`
      );
      setSubmitting(false);
      return;
    }

    let phoneNumber;

    if (data?.phone?.length > 0) {
      phoneNumber = data?.phone.toString();
    } else {
      phoneNumber = "";
    }

    console.log("phoneNumber", phoneNumber);

    setIsLoading(true);
    let reqBody = {
      order_number: orderNumber,
      customer_id: data?.customer_id,
      name: finalName?.trim(),
      email: data?.email,
      order_email: data?.order_email,
      phone: phoneNumber,
      address: data?.address?.trim(),
      reason: data?.reason?.trim(),
      billing_address: data?.billing_address.trim(),
      delivery_address_name: addressName?.trim(),
      updated_by: localStorage.getItem("fullname"),
    };

    console.log("requestBody", reqBody);

    try {
      let res = await axios.post(ApiEndpoints.createBlackListUser, reqBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setIsLoading(false);
        toast.success("BlackList created successfully");
        history.push("/admin/blacklist");
        let action = "blacklist-create";
        let type = "Info";
        let message = "BlackList successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      toast.error("Something went wrong");
      let action = "blacklist-create";
      let type = "Error";
      let message = "Error in blackList create";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleInputChange = (event, setFieldValue, values) => {
    console.log("event for id", event.target.value, values);
    if (values?.email?.length > 0 && values?.customer_id?.length < 2) {
      resetFunction();
      setFilteredUser([]);
      if (showSuggestion === false) {
        setShowSuggestion(true);
      }
      return;
    }
    const userInput = event.target.value;
    setFieldValue("customer_id", userInput);
    const filteredData = allUser.filter(
      (user) =>
        user?.shopify_id?.toLowerCase().includes(userInput) ||
        user?.email?.toLowerCase().includes(userInput) ||
        user?.first_name?.toLowerCase().includes(userInput) ||
        user?.last_name?.toLowerCase().includes(userInput)
    );
    console.log("filtered Data", filteredData, userInput);
    // setFilteredUser(filteredData);
    if (userInput) {
      setFilteredUser(filteredData);
      setSearchWord(userInput);
    } else {
      setFilteredUser([]);
      setSearchWord("");
    }
    if (showSuggestion === false) {
      setShowSuggestion(true);
    }
  };

  const handleSelectUser = (selectedUserId, setFieldValue) => {
    const selectedUser = allUser.find((user) => user.id === selectedUserId);
    console.log("selectedUser", selectedUser, selectedUserId);
    // setFieldValue("customer_id", selectedUser?.shopify_id);

    if (selectedUser) {
      let firstName =
        selectedUser?.first_name?.length > 0 ? selectedUser?.first_name : "";
      let lastName =
        selectedUser?.last_name?.length > 0 ? selectedUser?.last_name : "";
      let userName;
      if (lastName?.length > 0) {
        userName = firstName + " " + lastName;
      } else {
        userName = firstName;
      }

      setInitialData({
        customer_id: selectedUser?.shopify_id,
        name: userName,
        email: selectedUser?.email,
        phone: selectedUser?.phone,
        order_email: "",
        address: "",
        billing_address: "",
        reason: "",
        order_number: "",
        delivery_address_name: "",
      });
    }
    setShowSuggestion(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const validationSchema = Yup.object({
    reason: Yup.string().trim().required("Required"),
    // phone: Yup.string()
    //   .required("Phone is required")
    //   .matches(/^\+?[0-9()-]+$/, "Invalid phone number format"),
    // phone: Yup.string()
    //   .nullable()
    //   .matches(/^\+?[0-9]+$/, "Invalid phone number")
    //   .min(10, "Phone number must be at least 10 digits")
    //   .max(13, "Phone number must not exceed 13 digits"),
  });

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Add BlackList"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}
                <Formik
                  initialValues={initialData}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  // validate={(values) => {
                  //   let value = values;
                  //   console.log(values);
                  //   const errors = {};

                  //   console.log("errors", errors);

                  //   var pattern = new RegExp(/^[0-9\b]+$/);

                  //   // if (!values.customer_id) {
                  //   //   errors.customer_id = "Field Required!";
                  //   // }

                  //   // if (!values.name) {
                  //   //   errors.name = "Field Required!";
                  //   // }

                  //   // if (!values.email) {
                  //   //   errors.email = "Field Required";
                  //   // } else if (
                  //   //   !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  //   //     values.email
                  //   //   )
                  //   // ) {
                  //   //   errors.email = "Invalid email address";
                  //   // }

                  //   // if (!values.phone) {
                  //   //   errors.phone = "Field Required";
                  //   // } else if (!pattern.test(values.phone)) {
                  //   //   errors.phone = "Please enter only number.";
                  //   // } else if (values.phone.length != 10) {
                  //   //   errors.phone = "Please enter valid phone number.";
                  //   // }

                  //   // if (!values.address) {
                  //   //   errors.address = "Field Required!";
                  //   // }

                  //   if (!values.reason) {
                  //     errors.reason = "Field Required!";
                  //   }

                  //   return errors;
                  // }}
                  onSubmit={(values, { setSubmitting }) => {
                    // Trim values before submitting
                    const trimmedValues = Object.keys(values).reduce(
                      (acc, key) => {
                        acc[key] =
                          typeof values[key] === "string"
                            ? values[key].trim()
                            : values[key];
                        return acc;
                      },
                      {}
                    );

                    addBlackListUser(trimmedValues, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form onKeyDown={handleKeyPress}>
                      {console.log("errors", errors)}
                      {/* <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              *(Guest User cannot be blacklisted using name
                              field)
                            </label>
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Order No
                            </label>
                            <Select
                              placeholder="Enter Order Number"
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="order_number"
                              options={orderDetail}
                              isMulti={false}
                              isClearable
                              // value={selectedSubCategoriesValue}
                              // value={[{ value: "hghg", label: "rdgdgfd" }]}
                              onInputChange={(e) => setSearchKeyword(e)}
                              onMenuClose={() => {
                                setClear((p) => !p);
                                setOrderId(null);
                              }}
                              inputValue={searchKeyword}
                              onChange={handleOrderDetail}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  height: "46px",
                                  zIndex: 1000,
                                }),
                                container: (baseStyles) => ({
                                  ...baseStyles,
                                  zIndex: 1000,
                                }),
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Customer ID
                            </label>
                            <div ref={inputRef}>
                              <Input
                                readOnly={orderNumber ? true : false}
                                id="customer_id"
                                placeholder="Enter Customer Id"
                                type="text"
                                name="customer_id"
                                value={values?.customer_id}
                                onChange={(e) =>
                                  handleInputChange(e, setFieldValue, values)
                                }
                              />
                              {showSuggestion &&
                                filteredUser &&
                                filteredUser.length > 0 && (
                                  <div
                                    style={{
                                      border: "1px solid #f1f1f1",
                                      height: "auto",
                                      maxHeight: "125px",
                                      width: "91%",
                                      overflowY: "scroll",
                                      position: "absolute",
                                      background: "white",
                                      zIndex: 100,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <ul
                                      style={{
                                        listStyle: "none",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      {filteredUser.map((user) => (
                                        <li
                                          key={user.id}
                                          onClick={() =>
                                            handleSelectUser(
                                              user.id,
                                              setFieldValue
                                            )
                                          }
                                          style={{
                                            // margin: "5px 0px 5px 0px",
                                            fontSize: "13px",
                                            borderTop:
                                              "1px solid rgb(222, 226, 230)",
                                            padding: "10px",
                                          }}
                                        >
                                          {user.email}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Customer ID
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="customer_id"
                              placeholder="Enter Customer Id"
                              type="text"
                              name="customer_id"
                              value={values.customer_id}
                              onChange={(e) =>
                                handleInputChange(e, setFieldValue, values)
                              }
                            />
                            {showSuggestion &&
                              filteredUser &&
                              filteredUser.length > 0 && (
                                <div
                                  style={{
                                    border: "1px solid #f1f1f1",
                                    height: "125px",
                                    width: "90%",
                                    overflowY: "scroll",
                                    position: "absolute",
                                    background: "white",
                                    zIndex: 100,
                                    cursor: "pointer",
                                  }}
                                >
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    {filteredUser.map((user) => (
                                      <li
                                        key={user.id}
                                        onClick={() =>
                                          handleSelectUser(
                                            user.id,
                                            setFieldValue
                                          )
                                        }
                                        style={{
                                          // margin: "5px 0px 5px 0px",
                                          fontSize: "13px",
                                          borderTop:
                                            "1px solid rgb(222, 226, 230)",
                                          padding: "10px",
                                        }}
                                      >
                                        {user.email}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </FormGroup>
                        </Col> */}
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Name
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="example3cols2Input"
                              placeholder="Enter Name"
                              type="text"
                              value={values?.name}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <p className="ErrorMessage">
                              {errors.name && touched.name && errors.name}
                            </p> */}
                            <span
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              * 'Guest User' cannot be blacklisted using name
                              field
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              E-mail
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="example3cols2Input"
                              placeholder="Enter E-mail"
                              type="email"
                              value={values?.email}
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <p className="ErrorMessage">
                              {errors.email && touched.email && errors.email}
                            </p> */}
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Order E-mail
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="example3cols2Input"
                              placeholder="Enter Order E-mail"
                              type="email"
                              value={values?.order_email}
                              name="order_email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <p className="ErrorMessage">
                              {errors.email && touched.email && errors.email}
                            </p> */}
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Phone
                            </label>
                            <Input
                              className={styles.phone_input}
                              readOnly={orderNumber ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Phone"
                              type="text"
                              value={values?.phone}
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.phone && touched.phone && errors.phone}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Delivery Address
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Address"
                              type="text"
                              value={values?.address}
                              name="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <p className="ErrorMessage">
                              {errors.address &&
                                touched.address &&
                                errors.address}
                            </p> */}
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Billing Address
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Billing Address"
                              type="text"
                              value={values?.billing_address}
                              name="billing_address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Delivery Contact Name
                            </label>
                            <Input
                              readOnly={orderNumber ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Delivery Contact Name"
                              type="text"
                              value={values?.delivery_address_name}
                              name="delivery_address_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <p className="ErrorMessage">
                              {errors.delivery_address_name &&
                                touched.delivery_address_name &&
                                errors.delivery_address_name}
                            </p> */}
                            <span
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              * 'Guest User' cannot be blacklisted using
                              delivery contact name field
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Reason For BlackList
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter Reason For BlackList"
                              type="textarea"
                              value={values?.reason}
                              name="reason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.reason && touched.reason && errors.reason}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div>
                        <Button
                          className="PrimaryButton"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Add Blacklist
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BlackListUsersCreate;
