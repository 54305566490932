import React, { useMemo, useEffect, useState } from "react";
import Header from "../../ui/Header.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";

import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Button,
  Row,
  Input,
  Media,
  ButtonGroup,
  UncontrolledTooltip,
  Badge,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  CardBody,
} from "reactstrap";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";
import FullPageLoader from "../../../components/FullPageLoader";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import Loader from "../../../onboarding/components/Loader.js";
import MobileSearchBar from "../../components/SearchBarMobile.js";
import styles from "../../styles/shopifyProductsList.module.css";
import SearchBar from "../../components/SearchBar.js";
const employeesColumns = [
  {
    header: "Business-Name",
  },
  {
    header: "Email",
  },
  {
    header: "Phone",
  },
  {
    header: "Contact Person",
  },
  {
    header: "Contact Person E-mail",
  },
  {
    header: "Contact Person phone",
  },
  // {
  //   header: "Location",
  // },
  // {
  //   header: "Address",
  // },
  {
    header: "Is Active",
  },
  //   {
  //     header: "Is Enable",
  //   },
  {
    header: "Actions",
  },
];

function TenantsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [nextKey, setNextKey] = useState(null);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [statusFilter, setStatusFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [clear, setClear] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  const userLocation = localStorage.getItem("location");

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      // getProductDetails(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });
      setSelectedStatus(value);
    }
  };

  let btns = ["All"];

  const SortOptions = [
    { id: "business_name", text: "Business Name" },
    { id: "created_at", text: "Date" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const getTenantsList = (pageNum) => {
    setIsLoading(true);
    let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=10&offset=${pageNum}&is_active=false`;
    if (selectedSortOption) {
      getTenantsApi =
        getTenantsApi +
        `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (selectedStatus !== "All") {
      getTenantsApi = getTenantsApi + `&status=${selectedStatus}`;
    }

    if (searchKeyword.length > 0) {
      getTenantsApi = getTenantsApi + `&searchKeyword=${searchKeyword}`;
    }

    fetch(getTenantsApi)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        const responseTenants = res?.data;
        console.log("ReSpinse 57", res);
        // if (responseTenants.length > 0) {
        //   setTenantList((prevList) => [...prevList, ...responseTenants]);

        //   if (responseTenants.length < 10) {
        //     setLoadMoreEnabled(false);
        //   }
        // } else {
        //   setLoadMoreEnabled(false);
        // }

        if (responseTenants.length > 0) {
          const updatedTenantList =
            pageNum === 1
              ? responseTenants
              : [...tenantList, ...responseTenants];
          const mergedTenantList = mergeAndSortTenantList(updatedTenantList);
          setTenantList(mergedTenantList);

          if (responseTenants.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setTenantList(responseTenants);

            if (responseTenants.length < 10) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          }
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("51954949", err);
      });
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getTenantsList(pageNum);
  };

  const mergeAndSortTenantList = (mergedTenantList) => {
    if (selectedSortOption) {
      mergedTenantList.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedTenantList;
  };

  // useEffect(() => {
  //   getTenantsList();
  // }, [page]);

  useEffect(() => {
    if (selectedSortOption) {
      getTenantsList(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getTenantsList(page);
    } else if (searchKeyword === "") {
      getTenantsList(page);
    }
  }, [searchKeyword]);

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getTenantsList(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  const columns = useMemo(() => employeesColumns, []);

  const handleAdd = () => {
    const viewPath = "/admin/tenant-create";
    history.push(viewPath);
  };

  const handleView = (item) => {
    const viewPath = "/admin/archived-tenant-show/" + item.email;
    history.push(viewPath);
  };

  const handleEdit = (item) => {
    console.log("EDIT174174174==>", item);

    const editPath = `/admin/archived-tenant-edit/${item.email}`;
    history.push({
      pathname: editPath,
    });
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"All Archived Tenants"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className={styles.productList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getTenantsList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Searching All Tenants"
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getTenantsList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Searching All Tenants"
                    dropDown={false}
                    orderList={false}
                  />
                )}
              </CardBody>
              <div style={{ height: 650, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {employeesColumns.map((item) => (
                        <th scope="col">{item.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {tenantList && tenantList?.length ? (
                      tenantList?.map((item) => (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={item.logo}
                                  style={{
                                    objectFit: "cover",
                                    width: 50,
                                    height: 50,
                                  }}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  {item.business_name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>

                          <td>
                            {item.contact_person_firstname}{" "}
                            {item.contact_person_lastname}
                          </td>
                          <td>{item.contact_person_email}</td>
                          <td>{item.contact_person_phone}</td>

                          <td>
                            <Badge
                              className="badge-lg"
                              color={item?.is_active ? "success" : "danger"}
                            >
                              {item?.is_active ? "Yes" : "No"}
                            </Badge>
                          </td>

                          <td>
                            <ButtonGroup>
                              <Button
                                className="btn-icon-only"
                                color="info"
                                onClick={() => handleView(item)}
                                id={"tooltipv" + item?.id}
                                type="button"
                              >
                                <span className="btn-inner--icon">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltipv" + item?.id}
                              >
                                View Tenant
                              </UncontrolledTooltip>
                              {(userRoles.includes("1") ||
                                roleActions.includes("tenants_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only left-Padding"
                                    color="success"
                                    onClick={() => handleEdit(item)}
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit Tenant
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default TenantsList;
