import React, { useState, useEffect, useRef } from "react";

import Multiselect from "multiselect-react-dropdown";
// import Header from "../Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import "./style.css";
import DatePicker from "react-datepicker";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
import SubCategory from "./list.js";
import { toast } from "react-toastify";
import { element } from "prop-types";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ViewCouponDetails() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [discountType, setDiscountType] = useState("code");
  const [valueType, setValueType] = useState("valueType");
  const [appliesTo, setAppliesTo] = useState("");
  const [mpr, setMpr] = useState("");
  const [customerEligibility, setCustomerEligibility] =
    useState("all");
  const [maxDiscount, setMaxDiscount] = useState("");
  const [combineDiscount, setCombineDiscount] = useState([]);
  const [isEndDate, setIsEndDate] = useState(false);
  const[isEndTime, setIsEndTime] = useState();
  const [isStartDate, setIsStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [dataArr, setDataArr] = useState([]);
  const [collections, setCollections] = useState([]);
  const [products, setProducts] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedCollectionList, setSelectedCollectionList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [collectionFocus, setCollectionFocus] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [searchDataArr, setSearchDataArr] = useState([]);
  const [discountCode, setDiscountCode] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [minPurchaseAmount, setMinimumPurchaseAmount] = useState("minPurchaseAmount");
  const[typeOfDiscount, setTypeOfDiscount] = useState("");
  const [status, setStatus] = useState("");
  
  const [minPurchaseQuantity, setMinimumPurchaseQuantity] = useState("minPurchaseQuantity");
  const [discountUse, setDiscountUse] = useState(""); 

  let appliesRef = useRef();

  // let initialValues = {};

 let {id} = useParams();

 const getCouponDetail = (prod,colle) => {
  setIsLoading(true);
  let getCoupons = `${ApiEndpoints.discountsList}/${id}`;
      fetch(getCoupons)
          .then((response) => response.json())
          .then((res) => {
              console.log("resss",res);
            const responseCoupon = res.data;
              console.log("data",res);

              setOriginalData(responseCoupon);
              setTypeOfDiscount(responseCoupon.discounts.discount_type)
              setDiscountCode(responseCoupon.priceRule.price_rule.title)
              setValueType(responseCoupon.priceRule.price_rule.value_type)
              setDiscountValue(responseCoupon.priceRule.price_rule.value * -1) 
              if(!responseCoupon.priceRule.price_rule.prerequisite_subtotal_range && !responseCoupon.priceRule.price_rule.prerequisite_quantity_range) {
                setMpr("no_minimum")
              }
              if(responseCoupon.priceRule.price_rule.prerequisite_subtotal_range){
                setMpr("min_amount")
                console.log("11345",responseCoupon.priceRule.price_rule.prerequisite_subtotal_range);
                setMinimumPurchaseAmount(responseCoupon.priceRule.price_rule.prerequisite_subtotal_range.greater_than_or_equal_to)                               
              } 
              if(responseCoupon.priceRule.price_rule.prerequisite_quantity_range){
                setMpr("min_quantity")
                console.log("258963",responseCoupon.priceRule.price_rule.prerequisite_quantity_range);
                setMinimumPurchaseQuantity(responseCoupon.priceRule.price_rule.prerequisite_quantity_range.greater_than_or_equal_to)
              }  

              console.log(responseCoupon.priceRule.price_rule.entitled_variant_ids)
              if (responseCoupon.priceRule.price_rule.entitled_collection_ids && responseCoupon.priceRule.price_rule.entitled_collection_ids.length >0) {
                setAppliesTo("collections");
                console.log("inside collection")
                let selectedValue = colle.filter((x)=> responseCoupon.priceRule.price_rule.entitled_collection_ids.includes(Number(x.id)))
                console.log("selectedValue",selectedValue)
                setSelectedCollectionList(selectedValue)
              } else if (responseCoupon.priceRule.price_rule.entitled_variant_ids && responseCoupon.priceRule.price_rule.entitled_variant_ids.length >0) {
                console.log("inside products")

                setAppliesTo("products");
                console.log("products",prod)
                let selectedValue = prod.filter((x)=> responseCoupon.priceRule.price_rule.entitled_variant_ids.includes(Number(x.variantId)))
                console.log("selectedValue",selectedValue)
                setSelectedProducts(selectedValue)
              }
              
              if (responseCoupon.priceRule.price_rule.usage_limit) {
                setMaxDiscount(p => [...p, "limit_discount_used"]);
                setDiscountUse(responseCoupon.priceRule.price_rule.usage_limit);
              }
              if (responseCoupon.priceRule.price_rule.once_per_customer) {
                setMaxDiscount(p => [...p, "discount_one"]);
              }    

              setStatus(responseCoupon.discounts.is_active ? "Discount is active" : "Discount is not active");


              setIsStartDate(responseCoupon.priceRule.price_rule.starts_at.substring(0, 10))
              setStartTime(responseCoupon.priceRule.price_rule.starts_at.substring(11, 16))

              setIsEndDate(responseCoupon.priceRule.price_rule.ends_at ? responseCoupon.priceRule.price_rule.ends_at.substring(0, 10) : '')
              setIsEndTime(responseCoupon.priceRule.price_rule.ends_at ? responseCoupon.priceRule.price_rule.ends_at.substring(11, 16) : '')

              setIsLoading(false);
          }) .catch((error) => {
              setIsLoading(false);
          });
};

// useEffect(() => {
//   getCouponDetail()
// },[]);

  function generateCoupon(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let coupon = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      coupon += characters.charAt(randomIndex);
    }
  setDiscountCode(coupon)
    
  }

  async function getCollectionList() {
    try {
      const res = await axios.get(
        ApiEndpoints.collectionList + `?offset=0&limit=100`
      );

      console.log("Res", res);
      if (res.status === 200) {
        let collectionResponse = res?.data?.data;
        let collections = [];
        for (let i = 0; i < collectionResponse.length; i++) {
          let body = {};
          body.title = collectionResponse[i].title;
          body.id = collectionResponse[i].collection_id;
          collections.push(body);
        }
        setCollections(collections);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  // useEffect(() => {
  //   getCollectionList();
  // }, []);

  const getExportProducts = async (locationId) => {
    try {
      let res = await axios.get(
        `${ApiEndpoints.getAllProductsInventory}?locationId=${locationId.split("#")[1]}&page=1&limit=1000`
      );

      console.log("RESP", res);
      if (res.status === 200) {
        let products = [];
        let exportProductsData = JSON.parse(JSON.stringify(res.data.data));
        for (let i = 0; i < exportProductsData.length; i++) {
          let body = {};
          body.title = exportProductsData[i]["item_name"];
          body.id = exportProductsData[i]["product_id"];
          body.price = exportProductsData[i]["price"];
          body.variantId = exportProductsData[i]["variant_id"];
          products.push(body);
        }
        console.log("ABCD", exportProductsData);
        setProducts(products);
        console.log("products",products);
        const res1 = await axios.get(
          ApiEndpoints.collectionList + `?offset=0&limit=100`
        );
  
        console.log("Res", res1);
        if (res1.status === 200) {
          let collectionResponse = res1?.data?.data;
          let collections = [];
          for (let i = 0; i < collectionResponse.length; i++) {
            let body = {};
            body.title = collectionResponse[i].title;
            body.id = collectionResponse[i].collection_id;
            collections.push(body);
          }
          setCollections(collections);
          getCouponDetail(products,collections);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  console.log("EXPOT", products);

  useEffect(() => {
    // let locationId = localStorage.getItem("transaction_locationId");
    let locationId = localStorage.getItem("selected_location_id");
    if (locationId) {
      getExportProducts(locationId);
    }
  }, []);

  const handleView = () => {
    history.goBack();
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleSelection = (obj) => {
    if (appliesTo === "collections") {
      let arr = JSON.parse(JSON.stringify(selectedCollectionList));
      console.log("ARR", arr, "OBJ", obj);
      if (arr.some((element) => element.id == obj.id)) {
        let index = arr.findIndex((elm) => elm.id == obj.id);
        arr.splice(index, 1);
        setSelectedCollectionList(arr);
      } else {
        arr.push(obj);
        setSelectedCollectionList(arr);
      }
    } else {
      let arr = JSON.parse(JSON.stringify(selectedProducts));
      console.log("ARR", arr, "OBJ", obj);
      if (arr.some((element) => element.id == obj.id)) {
        let index = arr.findIndex((elm) => elm.id == obj.id);
        arr.splice(index, 1);
        setSelectedProducts(arr);
      } else {
        arr.push(obj);
        setSelectedProducts(arr);
      }
    }
  };

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    return arr.filter((item) =>
      item.title.toLowerCase().includes(str.toLowerCase())
    );
  };

  const handleAppliesModal = () => {
    if (appliesTo === "collections") {
      setDataArr(collections);
      setSearchDataArr(collections);
    } else {
      setDataArr(products);
      setSearchDataArr(products);
    }

    setModal(true);
  };


// const createDiscount=async()=>{
//   console.log(discountCode);
//   let requestBody={}
//   requestBody.price_rule = {}
//   if(discountCode.length > 0){
//     requestBody.price_rule.title = discountCode
//   }else{
//     //Error toast
//   }
//   if(valueType){
//     requestBody.price_rule.value_type=valueType
//   }

//   if(valueType === "percentage"){
//     requestBody.price_rule.value=  Number(discountValue)*-1
//   }else{
//     requestBody.price_rule.value=  Number(discountValue)*-1
//   }

//   requestBody.price_rule.customer_selection = customerEligibility
//   requestBody.price_rule.target_type="line_item"
//   requestBody.price_rule.target_selection= "entitled"
//   requestBody.price_rule.allocation_method= "across"
//   requestBody.price_rule.starts_at= "2018-03-22T00:00:00-00:00"
//   if(appliesTo == "products"){
//     requestBody.price_rule.entitled_variant_ids=[]
//     console.log("selectedProducts",selectedProducts)
//     for(let i in selectedProducts){
//       requestBody.price_rule.entitled_variant_ids.push(selectedProducts[i].variantId)
//     }
//   }else if(appliesTo == "collections"){
//     requestBody.price_rule.entitled_collection_ids=[]
//     console.log("selectedCollectionList",selectedCollectionList)
//     for(let i in selectedCollectionList){
//       requestBody.price_rule.entitled_collection_ids.push(selectedCollectionList[i].id)
//     }
//   }

//   if(mpr !== "no_minimum"){
//     if(mpr =="min_amount"){
//       requestBody.price_rule.prerequisite_subtotal_range ={"greater_than_or_equal_to":minPurchaseAmount}
//     }else if(mpr =="min_quantity"){
//       requestBody.price_rule.prerequisite_quantity_range ={"greater_than_or_equal_to":Number(minPurchaseQuantity)}
//     }
//   }

//   requestBody.created_by = localStorage.getItem("fullname");
//   requestBody.location_id = localStorage.getItem("selected_location_id").split("#")[1];
// requestBody.discount_type = "Product Discount"

//   console.log("requestBody",JSON.stringify(requestBody))

//   const res =await axios.post("https://i0a73s5uf3.execute-api.us-east-1.amazonaws.com/dev/v1/discounts",requestBody);
// console.log("create discount response",res)
// }

  useEffect(() => {
    if (modal) {
      let res = handleSearch(dataArr, searchStr);
      setDataArr(res);
    }

    if (searchStr.length === 0) {
      setDataArr(searchDataArr);
    }
  }, [searchStr.length]);

  const unselectCollectionItem = (id) => {
    let tempArr = [...selectedCollectionList];
    let index = tempArr.findIndex((elm) => elm.id == id);
    if (index >= 0) {
      tempArr.splice(index, 1);
    }
    setSelectedCollectionList(tempArr);
  };

  const unselectProductsItem = (id) => {
    let tempArr = [...selectedProducts];
    let index = tempArr.findIndex((elm) => elm.id == id);
    if (index >= 0) {
      tempArr.splice(index, 1);
    }
    setSelectedProducts(tempArr);
  };

  console.log("SELECTED_COLL", selectedCollectionList);

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      {/* <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Add Sub Category</ModalHeader>
      </Modal> */}
      <Modal isOpen={modal} toggle={toggle} style={{ padding: 0 }}>
        <ModalHeader
          toggle={toggle}
          close={() => {
            setSearchStr("");
            setModal(false);
          }}
          style={{ padding: 0 }}
        >
          <div style={{ padding: 20 }}>
            {appliesTo === "collections" ? "Add Collections" : "Add Products"}
          </div>
        </ModalHeader>
        <ModalBody style={{ height: 300, overflowY: "auto", padding: 0 }}>
          <div style={{ padding: 20 }}>
            <InputGroup>
              <InputGroupText style={{ borderRight: "none" }}>
                <i className="fas fa-search" />
              </InputGroupText>

              <Input
                placeholder={
                  appliesTo === "collections"
                    ? "Search Collections"
                    : "Search Products"
                }
                style={{
                  borderLeft: "none",
                }}
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                autoFocus
              />
            </InputGroup>
          </div>
          {dataArr?.map((item) => (
            <div
              key={item?.id}
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: 10,
                alignItems: "center",
                borderBottom: "1px solid #ccc",
                padding: 20,
              }}
            >
              <input
                type="checkbox"
                checked={
                  appliesTo === "collections"
                    ? selectedCollectionList.some(
                        (element) => element.id == item.id
                      )
                    : selectedProducts.some((element) => element.id == item.id)
                }
                onChange={() => handleSelection(item)}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <p style={{ marginBottom: 0 }}>{item?.title}</p>
                {item?.price && (
                  <p style={{ marginBottom: 0 }}>${item?.price}</p>
                )}
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setDataArr(searchDataArr);
              setModal(false);
              if (appliesTo === "collections") {
                setSelectedCollectionList([...selectedCollectionList]);
              } else {
                setSelectedProducts([...selectedProducts]);
              }
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"View product discount"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Sub Category
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={originalData}
                  validate={(values) => {
                    let value = values;
                    console.log("value", values);
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    // if (!values.sub_category) {
                    //   errors.sub_category = "Required";
                    // }
                    // if (!values.description) {
                    //   errors.description = "Required";
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // createCoupon(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col
                          lg="12"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <Col lg="6">
                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4 style={{ marginBottom: 0 }}>
                                      Amount Off Products
                                    </h4>
                                    <h4 style={{ marginBottom: 0 }}>
                                      Product discount
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                                    <CardTitle tag="h5">Method</CardTitle>
                                    <CardText>
                                      <FormGroup check>
                                        <Input
                                          name="discountType"
                                          type="radio"
                                          checked={discountType === "code"}
                                          // onChange={() =>
                                          //   setDiscountType("code")
                                          // }
                                        />
                                        <Label check>Discount code</Label>
                                      </FormGroup>
                                    </CardText>
                                    <CardText>
                                      <CardTitle
                                        tag="p"
                                        style={{ marginBottom: 0 }}
                                      >
                                        {discountType === "code"
                                          ? "Discount code"
                                          : "Title"}
                                      </CardTitle>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: 5,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Input 
                                        readOnly
                                      // onChange={(e)=>setDiscountCode(e.target.value)}
                                        value={discountCode}

                                      />
                                        {/* {discountType === "code" && (
                                          <Button
                                            type="button"
                                            style={{ margin: 0 }}
                                            onClick={()=>generateCoupon(10)}
                                          >
                                            Generate
                                          </Button>
                                        )} */}
                                      </div>
                                      <CardTitle
                                        tag="p"
                                        style={{ marginBottom: 0 }}
                                      >
                                        {discountType === "code"
                                          ? "Customers must enter this code at checkout."
                                          : "Customers will see this in their cart and at checkout."}
                                      </CardTitle>
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h5">Value</CardTitle>
                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        gridTemplateColumns: "auto 1fr",
                                        gap: 16,
                                      }}
                                    >
                                      <ButtonGroup
                                        size="sm"
                                        style={{ height: 46 }}
                                      >
                                        <Button
                                        value= {valueType}
                                          // onClick={() =>
                                          //   setValueType("percentage")
                                          // }
                                          type="button"
                                          style={{
                                            margin: 0,
                                            background:
                                              valueType === "percentage"
                                                ? "#616a75"
                                                : "#f7fafc",
                                            color:
                                              valueType === "percentage"
                                                ? "#fff"
                                                : "#212529",
                                          }}
                                        >
                                          Percentage
                                        </Button>
                                        <Button
                                         value= {valueType}
                                          // onClick={() => setValueType("fixed_amount")}
                                          type="button"
                                          style={{
                                            margin: 0,
                                            background:
                                              valueType === "fixed_amount"
                                                ? "#616a75"
                                                : "#f7fafc",
                                            color:
                                              valueType === "fixed_amount"
                                                ? "#fff"
                                                : "#212529",
                                          }}
                                        >
                                          Fixed Amount
                                        </Button>
                                      </ButtonGroup>
                                      <InputGroup>
                                        {valueType === "fixed_amount" && (
                                          <InputGroupText
                                            style={{ borderRight: "none" }}
                                          >
                                            $
                                          </InputGroupText>
                                        )}

                                        <Input
                                          readOnly
                                          placeholder={
                                            valueType === "fixed_amount" ? "0.00" : ""
                                          }
                                          style={{
                                            borderLeft:
                                              valueType === "fixed_amount" && "none",
                                            borderRight:
                                              valueType === "percentage" &&
                                              "none",
                                          }}
                                          // onChange={(e)=>setDiscountValue(e.target.value)}
                                         value={discountValue}
                                        />

                                        {valueType === "percentage" && (
                                          <InputGroupText
                                            style={{ borderLeft: "none" }}
                                          >
                                            %
                                          </InputGroupText>
                                        )}
                                      </InputGroup>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <CardTitle tag="h5">Applies to</CardTitle>
                                    <CardText>
                                      <FormGroup check>
                                        <Input
                                          readOnly
                                          name="appliesTo"
                                          type="radio"
                                          checked={appliesTo === "collections"}
                                          // onChange={() =>
                                          //   setAppliesTo("collections")
                                          // }
                                        />
                                        <Label check>
                                          Specific collections
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                          readOnly
                                          name="appliesTo"
                                          type="radio"
                                          checked={appliesTo === "products"}
                                          // onChange={() =>
                                          //   setAppliesTo("products")
                                          // }
                                        />
                                        <Label check>Specific products</Label>
                                      </FormGroup>
                                    </CardText>
                                    <CardText
                                      style={{
                                        display: "flex",
                                        gap: 8,
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputGroup>
                                        <InputGroupText
                                          style={{
                                            borderLeft: collectionFocus
                                              ? "1px solid #5e72e4"
                                              : "1px solid #dee2e6",
                                            borderTop: collectionFocus
                                              ? "1px solid #5e72e4"
                                              : "1px solid #dee2e6",
                                            borderBottom: collectionFocus
                                              ? "1px solid #5e72e4"
                                              : "1px solid #dee2e6",
                                            borderRight: collectionFocus
                                              ? "none"
                                              : "none",
                                            borderRadius:
                                              collectionFocus &&
                                              "0.25rem 0 0 0.25rem",
                                          }}
                                        >
                                          <i className="fas fa-search" />
                                        </InputGroupText>

                                        <Input
                                          readOnly
                                          placeholder={
                                            appliesTo === "collections"
                                              ? "Search Collections"
                                              : "Search Products"
                                          }
                                          style={{
                                            borderLeft: "none",
                                          }}
                                          onFocus={() => {
                                            setCollectionFocus(true);
                                          }}
                                          onBlur={() => {
                                            setCollectionFocus(false);
                                          }}
                                          value={""}
                                          // onChange={(e) => {
                                          //   handleAppliesModal();
                                          //   //setSearchStr(e.target.value);
                                          // }}
                                          // onClick={() => handleAppliesModal()}
                                          ref={appliesRef}
                                        />
                                      </InputGroup>
                                      {/* <Button
                                        onClick={() => {
                                          handleAppliesModal();
                                        }}
                                        type="button"
                                        color="secondary"
                                      >
                                        Browse
                                      </Button> */}
                                    </CardText>
                                    {appliesTo === "collections" ? (
                                      <CardText>
                                        {selectedCollectionList?.map((item) => (
                                          <div
                                            key={item.id}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <p>{item.title}</p>
                                            </div>

                                            <button
                                              type="button"
                                              className="close"
                                              aria-label="Close"
                                              // onClick={() =>
                                              //   unselectCollectionItem(item.id)
                                              // }
                                            >
                                              <span aria-hidden="true"
                                              style={{ lineHeight: "2px" }}>
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                        ))}
                                      </CardText>
                                    ) : (
                                      <CardText>
                                        {selectedProducts?.map((item) => (
                                          <div
                                            key={item.id}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <p>{item.title}</p>
                                            </div>

                                            <button
                                              type="button"
                                              className="close"
                                              aria-label="Close"
                                              // onClick={() =>
                                              //   unselectProductsItem(item.id)
                                              // }
                                            >
                                              <span aria-hidden="true"
                                              style={{ lineHeight: "2px" }}>
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                        ))}
                                      </CardText>
                                    )}
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">
                                      Minimum purchase requirements
                                    </CardTitle>
                                    <div>
                                      <FormGroup check>
                                        <Input
                                          name="mpr"
                                          type="radio"
                                          checked={mpr === "no_minimum"}
                                          // onChange={() => setMpr("no_minimum")}
                                        />
                                        <Label check>
                                          No minimum requirements
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                        readOnly
                                          name="mpr"
                                          type="radio"
                                          checked={mpr === "min_amount"}
                                          // onChange={() => setMpr("min_amount")}
                                        />
                                        <Label check>
                                          Minimum purchase amount ($)
                                        </Label>
                                      </FormGroup>
                                      {mpr === "min_amount" && (
                                        <FormGroup style={{ paddingLeft: 20 }}>
                                          <InputGroup
                                            style={{
                                              maxWidth: 150,
                                              height: 36,
                                              // margin: 10,
                                            }}
                                          >
                                            <InputGroupText
                                              style={{ borderRight: "none" }}
                                            >
                                              $
                                            </InputGroupText>

                                            <Input
                                              readOnly
                                              placeholder={"0.00"}
                                              style={{
                                                borderLeft: "none",
                                              }}
                                              // onChange={(e)=>setMinimumPurchaseAmount(e.target.value)}
                                                value={minPurchaseAmount}
                                            />
                                          </InputGroup>
                                          <CardText style={{ marginTop: 10 }}>
                                            Applies to all products.
                                          </CardText>
                                        </FormGroup>
                                      )}
                                      <FormGroup check>
                                        <Input
                                          readOnly
                                          name="mpr"
                                          type="radio"
                                          checked={mpr === "min_quantity"}
                                          // onChange={() =>
                                          //   setMpr("min_quantity")
                                          // }
                                        />
                                        <Label check>
                                          Minimum quantity of items
                                        </Label>
                                      </FormGroup>
                                      {mpr === "min_quantity" && (
                                        <FormGroup style={{ paddingLeft: 20 }}>
                                          <InputGroup
                                            style={{
                                              maxWidth: 150,
                                              height: 36,
                                            }}
                                          >
                                            <Input 
                                              readOnly
                                              // onChange={(e)=>setMinimumPurchaseQuantity(e.target.value)}
                                              value={minPurchaseQuantity}
                                            />
                                          </InputGroup>
                                          <CardText style={{ marginTop: 10 }}>
                                            Applies to all products.
                                          </CardText>
                                        </FormGroup>
                                      )}
                                    </div>
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <Card
                                className="my-2"
                                color="primary"
                                outline
                                style={{
                                  width: "650px",
                                }}
                              >
                                <CardHeader>
                                  <CardTitle tag="h4">
                                    Customer eligibility
                                  </CardTitle>
                                  <div>
                                    <FormGroup check>
                                      <Input
                                        name="customerEligibility"
                                        type="radio"
                                        checked={
                                          customerEligibility ===
                                          "all"
                                        }
                                        onChange={() =>
                                          setCustomerEligibility(
                                            "all"
                                          )
                                        }
                                      />
                                      <Label check>All customers</Label>
                                    </FormGroup>
                                    {/* <FormGroup check>
                                    <Input
                                      name="customerEligibility"
                                      type="radio"
                                      checked={
                                        customerEligibility ===
                                        "specific_customer_segments"
                                      }
                                      onChange={() =>
                                        setCustomerEligibility(
                                          "specific_customer_segments"
                                        )
                                      }
                                    />
                                    <Label check>
                                      Specific customer segments
                                    </Label>
                                  </FormGroup> */}

                                    {/* <FormGroup check>
                                    <Input
                                      name="customerEligibility"
                                      type="radio"
                                      checked={
                                        customerEligibility ===
                                        "specific_customers"
                                      }
                                      onChange={() =>
                                        setCustomerEligibility(
                                          "specific_customers"
                                        )
                                      }
                                    />
                                    <Label check>Specific customers</Label>
                                  </FormGroup> */}
                                    {/* {customerEligibility ===
                                    "specific_customer_segments" && (
                                    <CardText
                                      style={{
                                        display: "flex",
                                        gap: 8,
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputGroup>
                                        <InputGroupText
                                          style={{ borderRight: "none" }}
                                        >
                                          <i className="fas fa-search" />
                                        </InputGroupText>

                                        <Input
                                          placeholder={
                                            customerEligibility ===
                                            "specific_customer_segments"
                                              ? "Specific customer segments"
                                              : "Specific customers"
                                          }
                                          style={{
                                            borderLeft: "none",
                                          }}
                                        />
                                      </InputGroup>
                                      <Button type="button" color="secondary">
                                        Browse
                                      </Button>
                                    </CardText>
                                  )} */}
                                    {/* {customerEligibility ===
                                    "specific_customers" && (
                                    <CardText
                                      style={{
                                        display: "flex",
                                        gap: 8,
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputGroup>
                                        <InputGroupText
                                          style={{ borderRight: "none" }}
                                        >
                                          <i className="fas fa-search" />
                                        </InputGroupText>

                                        <Input
                                          placeholder={
                                            appliesTo === "collections"
                                              ? "Search Collections"
                                              : "Search Products"
                                          }
                                          style={{
                                            borderLeft: "none",
                                          }}
                                        />
                                      </InputGroup>
                                      <Button type="button" color="secondary">
                                        Browse
                                      </Button>
                                    </CardText>
                                  )} */}
                                  </div>
                                </CardHeader>
                              </Card>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">
                                      Maximum discount uses
                                    </CardTitle>
                                    <div>
                                      <FormGroup check>
                                        <Input
                                          readOnly
                                          name="maxDiscount"
                                          type="checkbox"
                                          checked={maxDiscount.includes(
                                            "limit_discount_used"
                                          )}
                                          // onChange={() => {
                                          //   let arr = [...maxDiscount];
                                          //   if (
                                          //     arr.includes(
                                          //       "limit_discount_used"
                                          //     )
                                          //   ) {
                                          //     let index = arr.findIndex(
                                          //       (elm) =>
                                          //         elm === "limit_discount_used"
                                          //     );
                                          //     arr.splice(index, 1);
                                          //     setMaxDiscount(arr);
                                          //   } else {
                                          //     setMaxDiscount([
                                          //       ...arr,
                                          //       "limit_discount_used",
                                          //     ]);
                                          //   }
                                          // }}
                                        />
                                        <Label check>
                                          Limit number of times this discount
                                          can be used in total
                                        </Label>
                                      </FormGroup>
                                      {maxDiscount.includes(
                                        "limit_discount_used"
                                      ) && (
                                        <CardText
                                          style={{
                                            display: "flex",
                                            gap: 8,
                                            alignItems: "center",
                                            maxWidth: 150,
                                          }}
                                        >
                                          <InputGroup>
                                          <Input
                                            readOnly
                                            // onChange={(e)=>setDiscountUse(e.target.value)}
                                            value={discountUse}
                                          />
                                          </InputGroup>
                                        </CardText>
                                      )}
                                      <FormGroup check>
                                        <Input
                                          readOnly
                                          name="maxDiscount"
                                          type="checkbox"
                                          checked={maxDiscount.includes(
                                            "discount_one"
                                          )}
                                          // onChange={() => {
                                          //   let arr = [...maxDiscount];
                                          //   if (arr.includes("discount_one")) {
                                          //     let index = arr.findIndex(
                                          //       (elm) => elm === "discount_one"
                                          //     );
                                          //     arr.splice(index, 1);
                                          //     setMaxDiscount(arr);
                                          //   } else {
                                          //     setMaxDiscount([
                                          //       ...arr,
                                          //       "discount_one",
                                          //     ]);
                                          //   }
                                          // }}
                                        />
                                        <Label check>
                                          Limit to one use per customer
                                        </Label>
                                      </FormGroup>
                                    </div>
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                            {/* <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">Combinations</CardTitle>
                                    <div>
                                      <FormGroup check>
                                        <Input
                                          name="combineDiscount"
                                          type="checkbox"
                                          checked={combineDiscount.includes(
                                            "other_product_discount"
                                          )}
                                          onChange={() => {
                                            let arr = [...combineDiscount];
                                            if (
                                              arr.includes(
                                                "other_product_discount"
                                              )
                                            ) {
                                              let index = arr.findIndex(
                                                (elm) =>
                                                  elm ===
                                                  "other_product_discount"
                                              );
                                              arr.splice(index, 1);
                                              setCombineDiscount(arr);
                                            } else {
                                              setCombineDiscount([
                                                ...arr,
                                                "other_product_discount",
                                              ]);
                                            }
                                          }}
                                        />
                                        <Label check>
                                          Other product discounts
                                        </Label>
                                      </FormGroup>
                                      {combineDiscount.includes(
                                        "other_product_discount"
                                      ) && (
                                        <CardText
                                          style={{
                                            paddingLeft: 20,
                                            lineHeight: "125%",
                                          }}
                                          tag="p"
                                        >
                                          No product discounts are currently set
                                          to combine. To let customers use more
                                          than one discount, set up at least one
                                          product discount that combines with
                                          product discounts.
                                        </CardText>
                                      )}
                                      <FormGroup check>
                                        <Input
                                          name="combineDiscount"
                                          type="checkbox"
                                          checked={combineDiscount.includes(
                                            "shipping_discount"
                                          )}
                                          onChange={() => {
                                            let arr = [...combineDiscount];
                                            if (
                                              arr.includes("shipping_discount")
                                            ) {
                                              let index = arr.findIndex(
                                                (elm) =>
                                                  elm === "shipping_discount"
                                              );
                                              arr.splice(index, 1);
                                              setCombineDiscount(arr);
                                            } else {
                                              setCombineDiscount([
                                                ...arr,
                                                "shipping_discount",
                                              ]);
                                            }
                                          }}
                                        />
                                        <Label check>Shipping discounts</Label>
                                      </FormGroup>
                                      {combineDiscount.includes(
                                        "shipping_discount"
                                      ) && (
                                        <CardText
                                          style={{
                                            paddingLeft: 20,
                                            lineHeight: "125%",
                                          }}
                                          tag="p"
                                        >
                                          No shipping discounts are currently
                                          set to combine. To let customers use
                                          more than one discount, set up at
                                          least one shipping discount that
                                          combines with product discounts.
                                        </CardText>
                                      )}
                                    </div>
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col> */}

                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">Active dates</CardTitle>
                                    <Row>
                                      <Col>
                                        <FormGroup>
                                          <Label for="exampleDate">
                                            Start date
                                          </Label>
                                          <Input
                                            readOnly
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            onChange={(e)=>setIsStartDate(e.target.value)}
                                            value={isStartDate}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label for="exampleTime">
                                            Start time (EDT)
                                          </Label>
                                          <Input
                                            readOnly
                                            id="exampleTime"
                                            name="time"
                                            placeholder="time placeholder"
                                            type="time"
                                            onChange={(e)=>setStartTime(e.target.value)}
                                            value={startTime}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <FormGroup check>
                                      <Input
                                        readOnly
                                        name="combineDiscount"
                                        type="checkbox"
                                        checked={isEndDate}
                                        // onChange={(e) =>
                                        //   setIsEndDate(e.target.checked)
                                        // }
                                      />
                                      <Label check>Set end date</Label>
                                    </FormGroup>
                                    {isEndDate && (
                                      <Row style={{ marginTop: 20 }}>
                                        <Col>
                                          <FormGroup>
                                            <Label for="exampleDate">
                                              End date
                                            </Label>
                                            <Input
                                              readOnly
                                              id="exampleDate"
                                              name="date"
                                              placeholder="date placeholder"
                                              type="date"
                                              onChange={(e) =>setIsEndDate(e.target.value)}
                                              value={isEndDate}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col>
                                          <FormGroup>
                                            <Label for="exampleTime">
                                              End time (EDT)
                                            </Label>
                                            <Input
                                              readOnly
                                              id="exampleTime"
                                              name="time"
                                              placeholder="time placeholder"
                                              type="time"
                                              onChange={(e)=>setIsEndTime(e.target.value)} 
                                              value={isEndTime}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    )}
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                          </Col>
                          <div>
                            <Col lg="9" style={{ width: "100%" }}>
                              <FormGroup>
                                <Card className="my-2" color="primary" outline style={{ width: "650px"}}>
                                  <CardHeader>
                                    <CardTitle tag="h4">Summary</CardTitle>
                                    <CardText tag="p">
                                    {discountCode ? `Discount code: ${discountCode}` : "No discount code yet."}
                                    </CardText>
                                    <CardTitle tag="h5">
                                      Type and Method
                                    </CardTitle>
                                    <CardText>
                                      <ul style={{ paddingLeft: 20 }}>
                                        <li>Amount off products</li>
                                        <li>Code</li>
                                      </ul>
                                    </CardText>
                                    <CardTitle tag="h5">Details</CardTitle>
                                    <CardText>
                                      <ul style={{ paddingLeft: 20 }}>
                                      {valueType === "percentage" && discountValue && (selectedCollectionList.length > 0 || selectedProducts.length > 0) ? (
                                          <li>
                                            {discountValue}% off : {appliesTo === "collections" ? selectedCollectionList.map(item => item.title).join(", ") :
                                             selectedProducts.map(item => item.title).join(", ")}
                                          </li>
                                            ) : null}
                                      {valueType === "fixed_amount" && discountValue && (selectedCollectionList.length > 0 || selectedProducts.length > 0) ? (
                                          <li>
                                            ${discountValue} off : {appliesTo === "collections" ? selectedCollectionList.map(item => item.title).join(", ") : 
                                             selectedProducts.map(item => item.title).join(", ")}
                                          </li>
                                        ) : null}
                                         {mpr === "no_minimum" ? (
                                            <li>No minimum requirements</li>
                                          ) : null}
                                          {mpr === "min_amount" && minPurchaseAmount ? (
                                            <li>Minimum purchase of ${minPurchaseAmount}</li>
                                          ) : null}
                                          {mpr === "min_quantity" && minPurchaseQuantity ? (
                                            <li>Minimum purchase of {minPurchaseQuantity} items</li>
                                          ) : null}
                                          {customerEligibility === "all" ? (
                                            <li>All customers</li>
                                          ) : null}
                                          {maxDiscount.includes("limit_discount_used") && discountUse ? (
                                            <li>
                                              Limit of {discountUse} uses
                                              {maxDiscount.includes("discount_one") ? ", one per customer" : null}
                                            </li>
                                          ) : (maxDiscount.includes("discount_one") ? (
                                            <li>One use per customer</li>
                                          ) : null)}
                                        <li>
                                          Active from  {moment(isStartDate).format("MM/DD/YYYY")}
                                        </li>
                                      </ul>
                                    </CardText>
                                  </CardHeader>
                                  <CardBody>
                                    <CardTitle tag="h4">Performance</CardTitle>
                                    <CardText tag="p">
                                    {status}
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col>

                            {/* <Col lg="9">
                              <FormGroup>
                                <Card className="my-2" color="primary" outline>
                                  <CardHeader
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CardTitle
                                      tag="h4"
                                      style={{ marginBottom: 0 }}
                                    >
                                      Sales channels
                                    </CardTitle>
                                    <CardText tag="h6">
                                      0 of 3 selected
                                    </CardText>
                                  </CardHeader>
                                  <CardBody>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      <input type="checkbox" />

                                      <CardText tag="h5">consumer_app</CardText>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      <input type="checkbox" />

                                      <CardText tag="h5">
                                        Shopify GraphiQL App
                                      </CardText>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      <input type="checkbox" />

                                      <CardText tag="h5">
                                        Facebook & Instagram
                                      </CardText>
                                    </div>
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col> */}
                          </div>
                        </Col>
                      </Row>
                      {/* <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={createDiscount}
                          >
                            Create Discount Coupon
                          </Button>
                        </FormGroup>
                      </Col> */}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default ViewCouponDetails;
