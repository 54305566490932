import React, { useMemo, useEffect, useState } from "react";
import Header from "../../ui/Header.js";
import "./notificationStyle.css";
import { useHistory } from "react-router-dom";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Button,
  Row,
  ButtonGroup,
  Media,
  UncontrolledTooltip,
  Badge,
  Col,
} from "reactstrap";
import moment from "moment";
const notificationsColumns = [
  {
    Header: "Location ",
  },
  {
    Header: "Screen Name",
  },
  {
    Header: "Start Date",
  },
  {
    Header: "End Date",
  },
  {
    Header: "Status",
  },
  {
    Header: "Actions",
  },
];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const options = [];
function NotificationList() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [useValue, setUseValue] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationArchieveData, setNotificationArchieveData] = useState([]);
  const [notificationALLData, setNotificationAllData] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const [nextKey, setNextKey] = useState(null);
  const history = useHistory();
  const token = getToken();
  const [filterBtnColor, setfilterBtnColor] = useState("Current");
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  // var filterBtnColor='Current';
  const config = {
    headers: { Authorization: token },
  };
  let LocationApi = `${ApiUrls.allLocationList}?size=100`;
  const getLocation = () => {
    fetch(LocationApi, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("datauash",data);
        const locations = data?.data;
        locations.map((element) => {
          console.log("45869",element);
          const found = options.some((el) => el.label === element?.title);
          if (!found) {
            let locationId = element.location_id.split("#");
            options.push({
              label: element?.title,
              value: locationId[locationId.length - 1],
            });
          }
        });
        setlocationD(options);
        console.log(options.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("notificationDatanotificationData==>", notificationData);
  const findingData = (item) => {
    let selectedLocationData = [];
    if (locationD?.length > 0) {
      for (let index = 0; index < item?.length; index++) {
        const element = locationD.find((el) => el.value === item[index]);

        if (typeof element !== "undefined") {
          selectedLocationData.push(element);
        }
      }
    }
    let singledata = [];
    selectedLocationData.forEach((element) => {
      singledata.push(element.label);
    });
    return singledata.toString();
  };
  const getNotificationList = () => {
    setIsLoading(true);
    let notificationApi = `${ApiUrls.notificationList}`;
    console.log("apicall166=>", notificationApi);
    fetch(notificationApi)
      .then((response) => response.json())
      .then((res) => {
        console.log("119==>", res);
        const notification = res?.data;
        console.log(res?.data);
        setfilterBtnColor("Current");
        let role = localStorage.getItem("userRole");

        if (role == "Admin") {
          let archievedData = [];
          let unArchivedData = [];
          notification.forEach((element) => {
            if (element.isArchive == true) {
              archievedData.push(element);
            } else {
              unArchivedData.push(element);
            }
          });

          setNotificationArchieveData(archievedData);
          // setNotificationData(unArchivedData);
          setNotificationAllData(unArchivedData);
          var date = Date.now();
          if (unArchivedData.length != 0) {
            let data = [];
            for (let i = 0; i < unArchivedData.length; i++) {
              if (
                date > unArchivedData[i].startDate &&
                date < unArchivedData[i].endDate
              ) {
                data.push(unArchivedData[i]);
              }
            }
            setNotificationData(data);
          } else {
            setNotificationData([]);
          }
        } else {
          let unArchievedData = [];
          notification.forEach((element) => {
            if (element.isArchive == true) {
              console.log(element);
            } else {
              unArchievedData.push(element);
            }
          });
          setNotificationData(unArchievedData);
          setNotificationAllData(unArchievedData);
          var date = Date.now();
          if (unArchievedData.length != 0) {
            let data = [];
            for (let i = 0; i < unArchievedData.length; i++) {
              if (
                date > unArchievedData[i].startDate &&
                date < unArchievedData[i].endDate
              ) {
                data.push(unArchievedData[i]);
              }
            }
            setNotificationData(data);
          }
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    setUseValue(1);
    // console.log(getNotificationList)
  };

  useEffect(() => {
    getLocation();
    getNotificationList();
  }, [useValue < 1]);

  const columns = useMemo(() => notificationsColumns, []);

  const handleAdd = () => {
    const viewPath = "/admin/notification-create";
    history.push(viewPath);
  };

  const handleView = (item) => {
    console.log("206666666", item);
    let data = {
      notificationId: item.notificationId,
      endDate: item.endDate,
      startDate: item.startDate,
      screenName: item.screenName,
      message: item.message,
      ca: item.ca,
      location: item.location,
      primary_message: item.primary_message,
      secondary_message: item.secondary_message,
      isBlocking: item.isBlocking,
      title: item.title,
      isEnabled: item.isEnabled,
      target: item.target,
    };
    const viewPath = `/admin/notification-show/${JSON.stringify(data)}`;
    console.log("Path 2222===>", viewPath);
    history.push(viewPath);
  };

  const handleEdit = (item) => {
    console.log("ITEIBSBDNA==>", item);
    const editPath = `/admin/notification-edit/${item.notificationId}/${item.location}/${item.message}/${item.screenName}/${item.isEnabled}/${item.startDate}/${item.endDate}/${item.primary_message}/${item.secondary_message}/${item.isBlocking}/${item.title}/${item.target}`;
    console.log("EDIT PATH TO SHOW ==>", editPath);
    history.push(editPath);
  };
  const handleArchive = async (item) => {
    if (
      window.confirm("Are you sure you want to Archive this Notification ?")
    ) {
      setIsLoading(true);
      let notificationArchiveApi = `${ApiUrls.notificationArchive}`;
      let archiveData = {
        locationsId: item.location,
        notificationId: item.notificationId,
      };
      console.log(archiveData);

      fetch(notificationArchiveApi, {
        method: "POST",
        body: JSON.stringify(archiveData),
      })
        .then((response) => response.json())
        .then(async(res) => {
          getNotificationList();
          setIsLoading(false);
          let action = "ops-message-archive"
            let type = "Info";
            let message = "OPS Message archived successfully.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
        })
        .catch(async(err) => {
          console.log(err);
          setIsLoading(false);
          let action = "ops-message-archive"
            let type = "Error";
            let message = "Error in OPS Message archive.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
        });
    }
  };
  const handleUnArchive = async (item) => {
    if (
      window.confirm("Are you sure you want to UnArchive this Notification ?")
    ) {
      let notificationArchiveApi = `${ApiUrls.notificationUnArchive}`;
      let UnArchiveData = {
        locationsId: item.location,
        notificationId: item.notificationId,
      };
      console.log(UnArchiveData);
      setIsLoading(true);
      fetch(notificationArchiveApi, {
        method: "POST",
        body: JSON.stringify(UnArchiveData),
      })
        .then((response) => response.json())
        .then(async(res) => {
          getNotificationList();
          setIsLoading(false);
          let action = "ops-message-unarchive"
          let type = "Info";
          let message = "OPS Message unarchived successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        })
        .catch(async(err) => {
          console.log(err);
          setIsLoading(false);
          let action = "ops-message-unarchive"
          let type = "Error";
          let message = "Error in OPS Message unarchive.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        });
    }
  };
  //var notifCopy = notificationData;

  //   let [notifCopy, setNotif] = useState([]);
  //   useEffect(() => {
  //     setNotif(notificationData);
  //   }, [setNotif]);

  //   console.log(notifCopy, "kishanannn");

  var handlePast = () => {
    console.log("pasttttttttttt");
    setfilterBtnColor("Past");
    console.log(filterBtnColor);
    var date = Date.now();
    console.log(date, "date");
    console.log(notificationALLData);

    if (notificationALLData.length != 0) {
      let data = [];
      for (let i = 0; i < notificationALLData.length; i++) {
        if (date > notificationALLData[i].endDate) {
          data.push(notificationALLData[i]);
          // if (i == 0) {
          //     setNotif([]);
          // }
          // setNotif(notificationData[i]);
          // console.log(notifCopy, "cto")
        }
      }
      setNotificationData(data);
    }
  };

  var handlePresent = () => {
    console.log("presentttttttttt");
    setfilterBtnColor("Current");
    var date = Date.now();
    // console.log(date, "date")
    if (notificationALLData.length != 0) {
      let data = [];
      for (let i = 0; i < notificationALLData.length; i++) {
        if (
          date > notificationALLData[i].startDate &&
          date < notificationALLData[i].endDate
        ) {
          data.push(notificationALLData[i]);
        }
      }
      setNotificationData(data);
    }
  };

  var handleFuture = () => {
    console.log("future");
    setfilterBtnColor("Future");
    var date = Date.now();
    // console.log(date, "date")
    if (notificationALLData.length != 0) {
      let data = [];
      for (let i = 0; i < notificationALLData.length; i++) {
        if (date < notificationALLData[i].startDate) {
          data.push(notificationALLData[i]);
        }
      }
      setNotificationData(data);
    }
  };

  var handleClearFilter = () => {
    setfilterBtnColor("clear");
    setNotificationData(notificationALLData);
  };

  // if (isLoading) {
  //     return (
  //       <>
  //         <FullPageLoader />
  //       </>
  //       )
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"All Messages"}
                    </h6>
                  </Col>

                  <Col className="mt-3 mt-md-0 text-md-right " lg="6" xs="5">
                    <Button
                      className={filterBtnColor == "Past" ? "activebtn" : ""}
                      size="sm"
                      onClick={() => handlePast()}
                    >
                      Past
                    </Button>
                    <Button
                      className={filterBtnColor == "Current" ? "activebtn" : ""}
                      size="sm"
                      onClick={() => handlePresent()}
                    >
                      Current
                    </Button>
                    <Button
                      className={filterBtnColor == "Future" ? "activebtn" : ""}
                      size="sm"
                      onClick={() => handleFuture()}
                    >
                      Future
                    </Button>
                    <Button
                      className={filterBtnColor == "clear" ? "activebtn" : ""}
                      size="sm"
                      onClick={() => handleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                    {(userRoles.includes("1") ||
                      roleActions.includes("message_create")) && (
                      <Button
                        color="default"
                        onClick={() => handleAdd()}
                        size="sm"
                      >
                        Add New Message
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {notificationsColumns.map((item) => (
                      <th scope="col">{item.Header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="list">
                  {notificationData && notificationData?.length ? (
                    notificationData
                      .sort((a, b) => b.ca - a.ca)
                      ?.map((item) => (
                        <tr>
                          <th className="budget">
                            {findingData(item?.location)}
                          </th>
                          <td className="budget">
                            {item?.screenName === "website"
                              ? "All pages"
                              : item?.screenName}
                          </td>
                          <td className="budget">
                            {moment(item?.startDate, "x").format("MM/DD/YYYY")}
                          </td>
                          <td className="budget">
                            {moment(item?.endDate, "x").format("MM/DD/YYYY")}
                          </td>
                          <td className="budget">
                            <Badge
                              className="badge-lg"
                              color={
                                item?.isEnabled === "true"
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {item?.isEnabled === "true"
                                ? "Enabled"
                                : "Disabled"}
                            </Badge>
                          </td>
                          <td>
                            <ButtonGroup>
                              <Button
                                className="btn-icon-only"
                                color="info"
                                onClick={() => handleView(item)}
                                id={"tooltipv" + item.userId}
                                type="button"
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltipv" + item.userId}
                              >
                                View Message
                              </UncontrolledTooltip>

                              {(userRoles.includes("1") ||
                                roleActions.includes("message_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only"
                                    color="success"
                                    onClick={() => handleEdit(item)}
                                    id={"tooltipe" + item.userId}
                                    type="button"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item.userId}
                                  >
                                    Edit Message
                                  </UncontrolledTooltip>
                                </>
                              )}

                              {(userRoles.includes("1") ||
                                roleActions.includes("message_delete")) && (
                                <>
                                  <Button
                                    className="btn-icon-only"
                                    color="danger"
                                    onClick={() => handleArchive(item)}
                                    id={"tooltipd" + item.userId}
                                    type="button"
                                  >
                                    <i className="fa fa-ban"></i>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipd" + item.userId}
                                  >
                                    Archive Message
                                  </UncontrolledTooltip>{" "}
                                </>
                              )}
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      {isLoading ? (
                        <td colSpan="8">Loading...</td>
                      ) : (
                        <td colSpan="8">No data found...</td>
                      )}
                    </tr>
                  )}
                </tbody>
                <th>
                  {" "}
                  {notificationArchieveData?.length ? "Archive Data" : ""}
                </th>
                <tbody className="list">
                  {notificationArchieveData && notificationArchieveData?.length
                    ? notificationArchieveData
                        .sort((a, b) => b.ca - a.ca)
                        ?.map((item) => (
                          <tr>
                            <th className="budget">
                              {findingData(item?.location)}
                            </th>
                            <td className="budget">
                              {item?.screenName === "website"
                                ? "All Pages"
                                : item?.screenName}
                            </td>
                            <td className="budget">
                              {moment(item?.startDate, "x").format(
                                "DD MMM YYYY hh:mm a"
                              )}
                            </td>
                            <td className="budget">
                              {moment(item?.endDate, "x").format(
                                "DD MMM YYYY hh:mm a"
                              )}
                            </td>
                            <td className="budget">
                              <Badge
                                className="badge-lg"
                                color={
                                  item?.isEnabled === "true"
                                    ? "success"
                                    : "danger"
                                }
                              >
                                {item?.isEnabled === "true"
                                  ? "Enabled"
                                  : "Disabled"}
                              </Badge>
                            </td>
                            <td>
                              <ButtonGroup>
                                <Button
                                  className="btn-icon-only"
                                  color="info"
                                  onClick={() => handleView(item)}
                                  id={"tooltipv" + item.userId}
                                  type="button"
                                >
                                  <i className="fa fa-eye"></i>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltipv" + item.userId}
                                >
                                  View Message
                                </UncontrolledTooltip>

                                <Button
                                  className="btn-icon-only"
                                  color="success"
                                  onClick={() => handleEdit(item)}
                                  id={"tooltipe" + item.userId}
                                  type="button"
                                >
                                  <i className="fa fa-edit"></i>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltipe" + item.userId}
                                >
                                  Edit Message
                                </UncontrolledTooltip>

                                <Button
                                  className="btn-icon-only"
                                  color="danger"
                                  onClick={() => handleUnArchive(item)}
                                  id={"tooltipd" + "un" + item.userId}
                                  type="button"
                                >
                                  <i className="fa fa-ban"></i>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltipd" + "un" + item.userId}
                                >
                                  UnArchive Message
                                </UncontrolledTooltip>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))
                    : ""}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                {nextKey !== null && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={() => getNotificationList()}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default NotificationList;
