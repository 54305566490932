import React, { useState, useEffect } from 'react';
import Header from "../../ui/Header.js";
import { Formik } from 'formik'
import { useHistory, useParams } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import {
    Form,
    FormGroup,
    Input,
    Card,
    CardHeader,
    CardFooter,
    Container,
    Button,
    Col,
    CardBody,
    UncontrolledAlert,
    Row,
} from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
const baseAWSAPIURL = require('../../config').baseAWSAPIURL;
const profileImgUUid = uuidv4();

function DriversEdit() {

    const [isLoading, setIsLoading] = useState(true);
    const [modifiedNonFormData, setModifiedNonFormData] = useState({})
    const [originalData, setOriginalData] = useState({})
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    let { userID } = useParams()
    const history = useHistory()

    useEffect(() => {
        fetch('https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com//dev/v1/user/' + userID + "?type=Driver", {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
        }).then(response => response.json())
            .then(response => {
                setOriginalData(response.data[0])
                setIsLoading(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false)
            });
    }, [userID])

    const update = async (data, setSubmitting) => {
        setSubmitting(true);
        delete data['ca']
        delete data['ua']

        data.type = "Driver";
        data.role = "Driver";
        data.signUpMode = "Email";

        if (modifiedNonFormData.profileImg !== undefined) {
            if (modifiedNonFormData.profileImg) {
                data['profileImg'] = modifiedNonFormData.profileImg
            } else {
                let urlArray = data.profileImg.split("/")
                let bucket = urlArray[3].split('?')
                data['profileImg'] = bucket[0];
            }

        } else {
            let urlArray = data.profileImg.split("/")
            let bucket = urlArray[3].split('?')
            data['profileImg'] = bucket[0];
        }

        if (modifiedNonFormData.drivingLicenseImg !== undefined) {
            if (modifiedNonFormData.drivingLicenseImg) {
                data['drivingLicenseImg'] = modifiedNonFormData.drivingLicenseImg
            } else {
                let urlArray = data.drivingLicenseImg.split("/")
                let bucket = urlArray[3].split('?')
                data['drivingLicenseImg'] = bucket[0];
            }

        } else {
            let urlArray = data.drivingLicenseImg.split("/")
            let bucket = urlArray[3].split('?')
            data['drivingLicenseImg'] = bucket[0];
        }

        if (modifiedNonFormData.insuranceImg !== undefined) {
            if (modifiedNonFormData.insuranceImg) {
                data['insuranceImg'] = modifiedNonFormData.insuranceImg
            } else {
                let urlArray = data.insuranceImg.split("/")
                let bucket = urlArray[3].split('?')
                data['insuranceImg'] = bucket[0];
            }

        } else {
            let urlArray = data.insuranceImg.split("/")
            let bucket = urlArray[3].split('?')
            data['insuranceImg'] = bucket[0];
        }

        if (data['isVerified'] == "yes") {
            data.isVerified = true
        } else if (data['isVerified'] == "no") {
            data.isVerified = false
        } else {
            data.isVerified = true
        }

        console.log(JSON.stringify({ ...data }));

        setIsLoading(true);

        fetch("https://x8x8qmh5o3.execute-api.us-east-1.amazonaws.com/dev/v1/profile/" + userID, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }) // body data type must match "Content-Type" header
        }).then(response => response.json())
            .then(res => {
                let seterros = []
                console.log(res)
                if (res.message == "") {
                    if (res.data.message == "SUCCESS") {
                        setErrorMsg("")
                        setSuccessMsg("Profile Updated successfully");
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)
                    }

                } else {
                    setSuccessMsg("")
                    res.message.map(item => (
                        seterros.push(item)
                    ))
                    setErrorMsg(seterros)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        setSubmitting(false);
        setIsLoading(false);
    }

    const handleView = () => {
        const viewPath = "/admin/drivers"
        history.push(viewPath)
    }

    const handleImageUpload = (event, fieldName) => {

        let selectedfiles = ""
        var newData = { ...modifiedNonFormData }
        const imageFile = event.target.files;
        setButtonDisabled(true);
        if (imageFile.length > 0) {
            selectedfiles = imageFile[0]

            const imagesKays = '[{ "key": "' + fieldName + profileImgUUid + '"}]';


            fetch(baseAWSAPIURL + "/profile/image", {
                method: 'POST',
                body: imagesKays,
            }).then(response => response.json())
                .then(response => {
                    //console.log(response);
                    const presignedUrl = response[0].presignedUrl
                    console.log(presignedUrl);

                    fetch(presignedUrl, {
                        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                        body: selectedfiles
                    }).then(res => {
                        setButtonDisabled(false);
                        newData[fieldName] = fieldName + profileImgUUid;
                        setModifiedNonFormData(newData)
                    }).catch((error) => {
                        console.log(error);
                    });

                })
                .catch(error => {
                    setButtonDisabled(false);
                    console.error(error)
                })


        } else {
            setButtonDisabled(false);
            newData[fieldName] = false;
            setModifiedNonFormData(newData)
        }
    }

    if (isLoading || !originalData) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <Container fluid>
                <Row>

                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                                            {"Edit Driver"}
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => handleView()}
                                            size="sm"
                                        >
                                            Back To All Drivers
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-icon">
                                            <i className="ni ni-like-2" />
                                        </span>
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )}
                                {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )}

                                <Formik
                                    enableReinitialize
                                    initialValues={originalData}
                                    validate={values => {
                                        values = { ...values, ...modifiedNonFormData };

                                        const errors = {};
                                        var pattern = new RegExp(/^[0-9\b]+$/);

                                        if (!values.firstName) {
                                            errors.firstName = 'Required';
                                        }
                                        if (!values.lastName) {
                                            errors.lastName = 'Required';
                                        }
                                        if (!values.email) {
                                            errors.email = 'Required';
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                            errors.email = 'Invalid email address';
                                        }
                                        if (!values.phoneNo) {
                                            errors.phoneNo = 'Required';
                                        } else if (!pattern.test(values.phoneNo)) {
                                            errors.phoneNo = 'Please enter only number.';
                                        } else if (values.phoneNo.length != 10) {
                                            errors.phoneNo = "Please enter valid phone number.";
                                        }

                                        if (!values.vehicleType) {
                                            errors.vehicleType = 'Required';
                                        }
                                        if (!values.vehicleDescription) {
                                            errors.vehicleDescription = 'Required';
                                        }

                                        if (!values.isVerified) {
                                            errors.isVerified = "Required";
                                        }

                                        if (!values.vehicleLicense) {
                                            errors.vehicleLicense = 'Required';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        update(values, setSubmitting)
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting
                                        /* and other goodies */
                                    }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">First Name</label>
                                                        <Input
                                                            placeholder="Enter First Name"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.firstName}
                                                            name="firstName"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.firstName && touched.firstName && errors.firstName}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">Last Name</label>
                                                        <Input
                                                            placeholder="Enter Last Name"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.lastName}
                                                            name="lastName"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.lastName && touched.lastName && errors.lastName}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="exampleFormControlInput1"
                                                        >
                                                            Email address
                                                        </label>
                                                        <Input
                                                            id="exampleFormControlInput1"
                                                            placeholder="Enter Email"
                                                            type="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            readonly="readonly"
                                                            name="email"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.email && touched.email && errors.email}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label"> Phone Number</label>
                                                        <Input
                                                            placeholder="Enter Phone Number"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.phoneNo}
                                                            name="phoneNo"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.phoneNo && touched.phoneNo && errors.phoneNo}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label"> Vehicle Type</label>
                                                        <Input
                                                            placeholder="Enter Vehicle Type"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vehicleType}
                                                            name="vehicleType"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.vehicleType && touched.vehicleType && errors.vehicleType}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label"> Vehicle License</label>
                                                        <Input
                                                            placeholder="Enter Vehicle License"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vehicleLicense}
                                                            name="vehicleLicense"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.vehicleLicense && touched.vehicleLicense && errors.vehicleLicense}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label"> Vehicle Description</label>
                                                        <Input
                                                            placeholder="Enter Vehicle Description"
                                                            type="textarea"
                                                            rows="3"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vehicleDescription}
                                                            name="vehicleDescription"
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.vehicleDescription && touched.vehicleDescription && errors.vehicleDescription}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">Is Verified</label>
                                                        <Input type="select" name="isVerified" onChange={handleChange} onBlur={handleBlur}>
                                                            <option value="">Select Is Verified</option>
                                                            <option value="yes" selected={values.isVerified ? "selected" : ""}>Yes</option>
                                                            <option value="no" selected={!values.isVerified ? "selected" : ""}>No</option>
                                                        </Input>

                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Profile Image</label>
                                                        <br />
                                                        <input
                                                            lang="en"
                                                            type="file"
                                                            name="profileImg"
                                                            onChange={(event) => {
                                                                handleImageUpload(event, "profileImg");
                                                            }}
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.profileImg && touched.profileImg && errors.profileImg}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Driving License Image</label>
                                                        <br />
                                                        <input
                                                            lang="en"
                                                            type="file"
                                                            name="drivingLicenseImg"
                                                            onChange={(event) => {
                                                                handleImageUpload(event, "drivingLicenseImg");
                                                            }}
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.drivingLicenseImg && touched.drivingLicenseImg && errors.drivingLicenseImg}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label">Insurance Image</label>
                                                        <br />
                                                        <input
                                                            lang="en"
                                                            type="file"
                                                            name="insuranceImg"
                                                            onChange={(event) => {
                                                                handleImageUpload(event, "insuranceImg");
                                                            }}
                                                        />
                                                        <p className="text-danger mb-0">
                                                            {errors.insuranceImg && touched.insuranceImg && errors.insuranceImg}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            disabled={isSubmitting || buttonDisabled}
                                                        >
                                                            Edit Account
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>

                        </Card>
                    </div>
                </Row>
            </Container>
        </>

    )
}

export default DriversEdit;
