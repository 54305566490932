import React, { useMemo, useEffect, useState, useRef } from "react";
import Header from "../../ui/Header.js";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import {
  Col,
  Card,
  Label,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  CardFooter,
  Table,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,
  Button,
  Row,
  ButtonGroup,
  Media,
  UncontrolledTooltip,
  Input,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import IMGetLocation from "../ui/IMGetLocation.js";
import "./style.css";
import { ExportToExcel } from "../../../components/ExportExcel/index.js";
import FullPageLoader from "../../../components/FullPageLoader";
import { logUserMetrics } from "../../../utils/file.js";
import { AlexaForBusiness } from "aws-sdk";
import { ApiEndpoints } from "../../constants/constant.js";
import moment from "moment";
import getToken from "../../../onboarding/getToken.js";
const awsSDKconfig = require("../../config/awsSDKConfig");
const AWS = require("aws-sdk");
AWS.config.update(awsSDKconfig);
const cognito = new AWS.CognitoIdentityServiceProvider();
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const inventoryControlsColumns = [
  {
    Header: "Title 1",
  },
  {
    Header: "Title 2",
  },
  {
    Header: "Title 3",
  },
  {
    Header: "Title 4",
  },
  {
    Header: "Title 5",
  },
  {
    Header: "Title 6",
  },
  {
    Header: "Title 7",
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function ControlsList() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("All");
  const modalToggle = () => setModal(!modal);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [inventoryControls, setInventoryControls] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const userLocation = localStorage.getItem("location");
  const [isImport, setIsImport] = useState(false);
  const [importStatus, setImportStatus] = useState("");
  const [isExcelData, setIsExcelData] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  //console.log("LOcation", userLocation);
  const history = useHistory();
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    //Product Api
    if (locationId) {
      setIsLoading(true);

      fetch(`${ApiEndpoints.listInventoryImport}?location_id=${locationId}`)
        .then((res) => res.json())
        .then((response) => {
          console.log("RESPONSE NEW", response);
          setInventoryControls(response.data);
          setOriginalData(response.data);
          setIsLoading(false);
        });
    }
  }, [locationId, isImport === true]);

  console.log("ISLOADING", isLoading);

  const exportData = async () => {
    if (locationId) {
      setIsLoading(true);
      fetch(`${ApiEndpoints.exportProducts}?locationId=${locationId}`)
        .then((response) => response.json())
        .then((json) => {
          console.log("json", json);
          setTimeout(() => {
            const data = [
              {
                category: "product",
                data: json.data,
              },
            ];
            setProductData(data);
            //setIsExcelData(true)
          }, 1000);
        });

      setIsLoading(false);
    }
  };

  const callReferesh = () => {
    fetch(`${ApiEndpoints.listInventoryImport}?location_id=${locationId}`)
      .then((res) => res.json())
      .then((response) => {
        console.log("RESPONSE NEW", response);
        setInventoryControls(response.data);
        setOriginalData(response.data);
        setApprovalStatus("All");
        setIsLoading(false);
      });
  };
  // console.log("Contr", inventoryControls);
  // console.log("PRODUCT DATa", productData);
  const columns = useMemo(() => inventoryControlsColumns, []);
  const handleView = () => {
    const viewPath = "/admin/inventory-controls-show/1";
    history.push(viewPath);
  };

  const handleDropdown = (e) => {
    console.log("E", e);
    let value = e.target.value;
    let location_id = value.split("#")[1];
    setLocationId(location_id);
    // callReferesh();
    setApprovalStatus("All");
  };

  useEffect(() => {
    let location = localStorage.getItem("selected_location_id");
    console.log("location 1234", location);
    if (location) {
      let loc = location.split("#")[1];
      setLocationId(loc);
    }
  }, []);

  const fileInputRef = useRef();

  const [file, setFile] = useState("");

  const updateApprovalStatus = (text) => {
    console.log("147---->", text);
    setApprovalStatus(text);
    let data = JSON.parse(JSON.stringify(originalData));
    if (text === "All") {
      setInventoryControls(data);
    } else {
      let inventoryControl = data.filter((item) => item.approved === text);
      setInventoryControls(inventoryControl);
    }
    // let inventoryData =
  };

  const handleFileUpload = async (e) => {
    // let file = e.target.files[0];
    // console.log("FILE", file);
    let alertRes = window.confirm(
      "Are you sure you want to upload the inventory data?"
    );
    if (!alertRes) {
      return;
    }

    setIsLoading(true);

    try {
      // let url = `${
      //   ApiEndpoints.importInventory
      // }?location_id=${locationId}&name=${localStorage.getItem("Name")}`;
      // let url = `/dev/v1/import-inventory?location_id=${locationId}&name=${localStorage.getItem(
      //   "Name"
      // )}`;

      // let test = `https://dev-api.liquorsplit.io/inventory/v1/import-inventory?location_id=d84d151a-c2c7-491c-b1f6-72933d80f13f&name=Nitesh`;

      // let res = await uploadFileToServer(
      //   url,
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   file
      // );

      let preSignedUrl = ApiEndpoints.preSignedUrl;

      let preSignedRes = await axios.get(preSignedUrl);
      if (preSignedRes.status === 200) {
        let ukey = preSignedRes?.data?.data?.key;
        let url = preSignedRes?.data?.data?.url;

        console.log("URL", url, ukey);

        let res = await uploadFile(url, file);
        console.log("RES1", res);

        if (res.status === 200) {
          let data = {
            key: ukey,
          };
          let importRes = await axios.post(
            `${
              ApiEndpoints.importInventory
            }?location_id=${locationId}&name=${localStorage.getItem("Name")}`,
            data
          );

          //console.log("FINAL", importRes);
          if (importRes.status === 200) {
            setIsLoading(false);
            setIsImport(true);
            setImportStatus("Successfull");
            setFile("");
            setModal(false);
            alert("Your Request is Successfully Submitted");
            let action = "import-products"
            let type = "Info";
            let message = "Products imported successfully.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          } else {
            setIsLoading(false);
            setIsImport(false);
            setFile("");
            setModal(false);
            setImportStatus("Failed");
            alert("Your Request is Failed");
          }
        }
      }
    } catch (error) {
      console.log("Err", error);
      if (error.status === 400) {
        setIsLoading(false);
        //alert(error.data.message);
        setModal(false);
        let action = "import-products"
            let type = "Error";
            let message = "Error in Products import.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
      }
    }
  };

  let uploadFileToServer = (uploadPreSignedUrl, contentType, requestBody) => {
    setIsLoading(true);

    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          setIsLoading(false);

          resolve(
            JSON.parse(
              JSON.stringify({ status: 200, data: "xhr.responseText" })
            )
          );
        } else {
          setIsLoading(false);

          reject(
            JSON.parse(
              JSON.stringify({ status: 400, data: "xhr.responseText" })
            )
          );
        }
      };

      xhr.open("PUT", uploadPreSignedUrl);
      // xhr.setRequestHeader("Content-Type", contentType);
      // xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.send(requestBody);
    });
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  const callXhr = async (url, data) => {
    // create an XHR object
    const xhr = new XMLHttpRequest();

    // listen for `onload` event
    xhr.onload = () => {
      // process response
      if (xhr.status == 200) {
        // parse JSON data
        console.log(JSON.parse(xhr.response));
      } else {
        console.error("Error!");
      }
    };

    // create a `GET` request
    xhr.open("PUT", url);

    // send request
    xhr.send(data);
  };

  const getLocationList = async () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiEndpoints.locationList}`, config)
      .then((response) => response.json())
      .then(async (data) => {
        const locations = data?.data?.Items;
        console.log("LOc", locations);

        let location = localStorage.getItem("selected_location_id");

        if (location && location !== "undefined") {
          console.log("SDFSDFSDFSDFSDFSDF");
          setLocationId(location);
        } else {
          localStorage.setItem("inventory_location", locations[0].pk);
          // setPageNum(0);
          // updateOrderStatus("All");
          setLocationId(locations[0].pk);
        }

        let role = localStorage.getItem("userRole");

        if (role == "Admin") {
          setLocationData(locations);
        }
        // else {
        //   let data0 = localStorage.getItem("currentLocation");
        //   let locationR = [];
        //   let data = data0.split(",");
        //   data.forEach((el) => {
        //     let data3 = locations.find((item) => item.pk == el);
        //     locationR.push(data3);
        //   });
        //   console.log("locationsss", locationR);
        //   setLocationData(locationR);
        //   // var location = userLocation.split("#")[1];
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  console.log("SDFASD", locationId);

  // useEffect(() => {
  //   getLocationList();
  // }, []);

  const handleChange = async (e) => {
    console.log("EEEE", e.target.value);

    let tempLocation = e.target.value;
    setLocationId(tempLocation);
    localStorage.setItem("inventory_location", tempLocation);
  };

  const toggleLoader = (value) => {
    console.log("SDsgdsag", value);
    setIsLoading(value);
    console.log("LO", isLoading);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Inventory Controls"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {/* <Button
                                            className="buttons-copy buttons-html5"
                                            size="sm"
                                            color="primary"
                                            id="copy-tooltip"
                                        >
                                            <span><i class="fas fa-download"></i> Export</span>
                                        </Button> */}

                    <Button
                      className="buttons-copy buttons-html5 btn-link"
                      // color="primary"
                      size="sm"
                      id="copy-tooltip"
                      onClick={() => {
                        callReferesh();
                      }}
                    >
                      <span>
                        {/* <i class="fas fa-upload"></i> */}
                        Refresh
                      </span>
                    </Button>
                    {(userRoles.includes("1") ||
                      roleActions.includes("manage_export")) && (
                      <ExportToExcel
                        //finalDataDetail={productData}
                        //exportData={exportData}
                        locationId={locationId}
                        setIsLoading={toggleLoader}
                        isLoading={isLoading}
                      />
                    )}

                    {(userRoles.includes("1") ||
                      roleActions.includes("manage_import")) && (
                      <Button
                        className="buttons-copy buttons-html5"
                        color="default"
                        size="sm"
                        id="copy-tooltip"
                        onClick={() => setModal(true)}
                      >
                        <span>
                          <i class="fas fa-upload"></i> Import
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {/* <Col xs={12} sm={5}>
                    <FormGroup className="row">
                      <Col md="10">
                        <Input
                          placeholder="Search"
                          id="example-text-input"
                          type="text"
                        />
                      </Col>
                      <Label>
                        <Button
                          className=""
                          color="primary"
                          id="copy-tooltip"
                          md="3"
                          style={{ marginTop: "0px" }}
                        >
                          <i className="fas fa-search"></i>
                        </Button>
                      </Label>
                    </FormGroup>
                  </Col> */}
                  <Col xs={12} sm={4}>
                    <Form>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="1"
                        ></Label>
                        <Col md="8" className="pull-right">
                          {/* {userLocation == null ? (
                            <Input
                              id="exampleFormControlSelect3"
                              type="select"
                              onChange={handleDropdown}
                            >
                              <IMGetLocation setLocationId={setLocationId} />
                            </Input>
                          ) : null} */}
                          {/* <div
                            style={{
                              display: "inline-block",
                            }}
                          >
                            {
                              <Input
                                id="exampleFormControlSelect1"
                                type="select"
                                onChange={handleChange}
                              >
                                {locationData.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item?.pk}
                                    selected={
                                      item?.pk === locationId ? "selected" : ""
                                    }
                                  >
                                    {item?.description}
                                  </option>
                                ))}
                               
                              </Input>
                              
                            }
                          </div> */}
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
              <ButtonGroup className="btngroup" responsive>
                <Button
                  className={approvalStatus === "All" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateApprovalStatus("All")}
                >
                  All
                </Button>
                <Button
                  className={
                    approvalStatus === "Pending For Approval" ? "active" : ""
                  }
                  color="link"
                  type="button"
                  onClick={() => updateApprovalStatus("Pending For Approval")}
                >
                  PENDING FOR APPROVAL
                </Button>
                <Button
                  className={approvalStatus === "Approved" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateApprovalStatus("Approved")}
                >
                  APPROVED
                </Button>
                <Button
                  className={approvalStatus === "Rejected" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateApprovalStatus("Rejected")}
                >
                  REJECTED
                </Button>
              </ButtonGroup>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Uploaded At</th>
                    <th>Name</th>
                    <th>No of Item</th>
                    <th>Processing Status</th>
                    <th>Approval Status</th>
                    <th>Import inventory</th>
                    <th>Error File</th>
                    {/* <th>ID</th> */}

                    {/* <th>Reason</th> */}

                    <th></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {inventoryControls?.map((item, i) => (
                    <tr key={i}>
                      <td>{moment(item.updated_at).format("MM/DD/YYYY")}</td>
                      <td>{item.name}</td>
                      <td>{item.no_of_item}</td>
                      <td>{item.status}</td>
                      <td>{item.approved}</td>
                      <td>
                        {item.uploaded_s3_url !== "" && (
                          <a
                            href={item.uploaded_s3_url}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className="buttons-copy buttons-html5 btn-link"
                              // color="default"
                              size="sm"
                            >
                              <span>
                                <i class="fas fa-download"></i>
                              </span>
                            </Button>
                          </a>
                        )}
                      </td>
                      {/* <td>{item.id}</td>
                      
                      

                      
                     
                     
                      <td>{item.reason}</td> */}

                      <td>
                        {item.error_s3_url !== "" && (
                          <a
                            href={item.error_s3_url}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className="buttons-copy buttons-html5 btn-link"
                              // color="primary"
                              size="sm"
                            >
                              <span>
                                <i class="fas fa-download"></i>
                              </span>
                            </Button>
                          </a>
                        )}
                      </td>
                      <td>
                        <ButtonGroup>
                          <Button
                            className="btn-icon-only"
                            color="info"
                            type="button"
                            onClick={() =>
                              history.push({
                                pathname: "/admin/inventory-controls-imports",
                                state: { item: item, permission: "view" },
                              })
                            }
                            id={"tooltipv" + "view"}
                          >
                            <span className="btn-inner--icon">
                              <i className="fa fa-eye"></i>
                            </span>
                          </Button>
                          {/* <UncontrolledTooltip
                            delay={0}
                            target={"tooltipv" + "view"}
                          >
                            View Request
                          </UncontrolledTooltip> */}
                        </ButtonGroup>
                        {localStorage.getItem("userRole") === "Admin" && (
                          <>
                            {item.approved === "Pending For Approval" &&
                              (userRoles.includes("1") ||
                                roleActions.includes("manage_approve")) && (
                                <Button
                                  className="btn-icon-only"
                                  color="success"
                                  type="button"
                                  onClick={() =>
                                    history.push({
                                      pathname:
                                        "/admin/inventory-controls-imports",
                                      state: { item: item, permission: "edit" },
                                    })
                                  }
                                  id={"tooltipv" + "edit"}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-edit"></i>
                                  </span>
                                </Button>
                              )}
                            {/* <UncontrolledTooltip
                              delay={0}
                              target={"tooltipv" + "edit"}
                            >
                              Edit Request
                            </UncontrolledTooltip> */}
                          </>
                        )}
                      </td>

                      {/* <td>Hello</td>
                    <td>DFDF</td>
                    <td>SDFS</td>
                    <td>SDFSF</td>
                    <td>sdfsf</td>
                    <td>
                      <Button
                        className="buttons-copy buttons-html5"
                        color="primary"
                        size="sm"
                      >
                        <span>
                          <i class="fas fa-download"></i> Download
                        </span>
                      </Button>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Import Inventory</ModalHeader>
        <ModalBody>
          <input
            type="file"
            hidden
            name="importBtn"
            id="importBtn"
            onChange={(e) => setFile(e.target.files[0])}
            ref={fileInputRef}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onClick={(e) => (e.target.value = null)}
          />
          {file ? (
            <>
              <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                File <i class="fal fa-file-spreadsheet"></i>
              </h6>
              <h4>{file.name}</h4>
              <Button color="primary" onClick={handleFileUpload}>
                Submit
              </Button>
              <Button color="secondary" onClick={() => setFile(null)}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <h6 className="fullcalendar-title h4 d-inline-block mb-0 mr-4">
                Please upload the file
              </h6>
              <Button
                color="primary"
                onClick={() => fileInputRef.current.click()}
              >
                Browse
              </Button>
            </>
          )}
        </ModalBody>

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ControlsList;
// uploadFileToServer(
//   "/dev/v1/import-inventory?location_id=d84d151a-c2c7-491c-b1f6-72933d80f13f&name=Nitesh",
//   "xlsx",
//   e.target.files[0]
// )
