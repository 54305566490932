import React, { useState, useEffect } from "react";
import Header from "../Header.js";
import { ClipLoader } from "react-spinners";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ApiEndpoints } from "../../constants/constant";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Row,
  Table,
  Badge,
  Col,
} from "reactstrap";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";

const options = [];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function DisputeshowDispute(props) {
  const classes = useStyles();
  let { locationId } = useParams();
  let { notificationId } = useParams();
  let { item } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [locationD, setlocationD] = useState([]);
  const location = useLocation();
  const { data, name } = location?.state;
  const [disputeObj, setDisputeOnject] = useState(data);
  console.log("Dispite", disputeObj, name);
  const [isStripe, setIsStripe] = useState(false);

  useEffect(() => {
    if (name && name === "Stripe") {
      setIsStripe(true);
    } else {
      setIsStripe(false);
    }
  }, []);

  const token = getToken();
  const config = {
    headers: { Authorization: token },
  };
  let LocationApi = `${ApiEndpoints.locationList}?size=10`;

  const findingData = (item) => {
    let selectedLocationData = [];
    if (locationD.length > 0) {
      console.log("daraa", item);
      console.log("daraa1", locationD.length);
      for (let index = 0; index < item.length; index++) {
        const element = locationD.find((el) => el.value === item[index]);

        if (typeof element !== "undefined") {
          selectedLocationData.push(element);
        }
      }
    }
    let singledata = [];
    selectedLocationData.forEach((element) => {
      singledata.push(element.label);
    });
    return singledata.toString();
  };
  const handleView = () => {
    const viewPath = "/admin/disputes";
    history.push({ pathname: viewPath, state: { path: name } });
  };
  useEffect(() => {
    setIsLoading(false);

    return () => {};
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Dispute Order Detail"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To Disputes
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table border="1" style={{ borderColor: "#e9ecef" }} responsive>
                  <tbody>
                    <tr>
                      <th>Date</th>
                      {isStripe ? (
                        <td>
                          {moment(disputeObj.created).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </td>
                      ) : (
                        <td>
                          {moment(disputeObj.initiated_at).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th>{isStripe ? "Dispute Id" : "Order Id"}</th>
                      {isStripe ? (
                        <td>
                          <a href="">View Order</a>
                        </td>
                      ) : (
                        <td>
                          <a
                            href={`https://qa-manage.liquorsplit.io/order/${disputeObj.order_id}/d84d151a-c2c7-491c-b1f6-72933d80f13f`}
                          >
                            View Order
                          </a>
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>{disputeObj?.amount}</td>
                    </tr>
                    <tr>
                      <th>Reason</th>
                      <td>{disputeObj?.reason}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{disputeObj?.status}</td>
                    </tr>
                    <tr>
                      <th>Evidence Due By</th>
                      {isStripe ? (
                        <td>
                          {" "}
                          {moment(disputeObj?.evidence_details.due_by).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </td>
                      ) : (
                        <td>
                          {moment(disputeObj.evidence_due_by).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </td>
                      )}
                    </tr>
                    <tr>
                      {isStripe ? <th>Charge</th> : <th>Evidence Sent On</th>}
                      {isStripe ? (
                        <td>{disputeObj?.charge}</td>
                      ) : (
                        <td>
                          {" "}
                          {moment(disputeObj.evidence_sent_on).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </td>
                      )}
                    </tr>
                    <tr>
                      {isStripe ? (
                        <th>Is Charge Refundable</th>
                      ) : (
                        <th>Finalized On</th>
                      )}
                      {isStripe ? (
                        <td>{disputeObj?.is_charge_refundable?.toString()}</td>
                      ) : (
                        <td>
                          {" "}
                          {moment(disputeObj.finalized_on).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            {/* {!isStripe && (
              <a href="">
                https://qa-manage.liquorsplit.io/order/{disputeObj.order_id}
                /d84d151a-c2c7-491c-b1f6-72933d80f13f
              </a>
            )} */}
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default DisputeshowDispute;
