import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, isEmptyArray } from "formik";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import { logUserMetrics } from "../../../utils/file";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
} from "reactstrap";
import { isEmpty } from "lodash";
import { getToken } from "../../../onboarding";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { Select } from "@material-ui/core";
import { toast } from "react-toastify";
import Loader from "../../../onboarding/components/Loader";
var resdata = require("../response.json");

let productArray = [];

function CreateOrderAdmin(props) {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [source, setSource] = useState("");
  const [exportProducts, setExportProducts] = useState([]);

  useEffect(() => {
    let roles = localStorage.getItem("role_actions");
    if (roles) {
      let allRoles = JSON.parse(roles);
      if (!allRoles.includes("inventory_admin_manage")) {
        history.push("/admin/inventory-update");
      }
    }
  }, []);

  const handlePage = () => {
    const viewPath = "/admin/inventory-update";
    history.push(viewPath);
  };

  const getExportProducts = async (locationId) => {
    setIsLoading(true);
    try {
      let res = await axios.get(
        `${ApiEndpoints.exportProducts}?locationId=${locationId.split("#")[1]}`
      );

      console.log("RESP", res);
      if (res.status === 200) {
        let products = [];
        let exportProductsData = JSON.parse(JSON.stringify(res.data.data));

        for (let i = 0; i < exportProductsData.length; i++) {
          exportProductsData[i]["New Price"] = exportProductsData[i]["Price"];
          exportProductsData[i]["Old UPC"] = exportProductsData[i]["UPC"];
          products.push({
            count: exportProductsData[i]["LS Qty"],
            old_upc: exportProductsData[i]["UPC"],
            upc: exportProductsData[i]["UPC"],
            id: exportProductsData[i]["Inventory Item ID"],
            inventory_item_id: exportProductsData[i]["Inventory Item ID"],
            location_id: exportProductsData[i]["Location ID"],
            name: exportProductsData[i]["Item Name"],
            price: exportProductsData[i]["Price"],
            quantity: exportProductsData[i]["New Qty"],
            new_price: exportProductsData[i]["New Price"],
          });
        }
        setProductData(products);
        productArray = exportProductsData;
        setExportProducts(exportProductsData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  console.log("EXPOT", exportProducts);

  useEffect(() => {
    // let locationId = localStorage.getItem("transaction_locationId");
    let locationId = localStorage.getItem("selected_location_id");
    if (locationId) {
      getExportProducts(locationId);
    }
  }, []);

  function onClear() {
    let products = [];
    for (let i = 0; i < exportProducts.length; i++) {
      products.push({
        count: exportProducts[i]["LS Qty"],
        upc: exportProducts[i]["UPC"],
        old_upc: exportProducts[i]["Old UPC"],
        id: exportProducts[i]["Inventory Item ID"],
        inventory_item_id: exportProducts[i]["Inventory Item ID"],
        location_id: exportProducts[i]["Location ID"],
        name: exportProducts[i]["Item Name"],
        price: exportProducts[i]["Price"],
        quantity: exportProducts[i]["New Qty"],
        new_price: exportProducts[i]["New Price"],
      });
    }
    setProductData(products);
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    return arr.filter((item) =>
      item["Item Name"].toLowerCase().includes(str.toLowerCase())
    );
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    console.log("STRING", string, results);

    let searchRes = handleSearch(productArray, string);
    console.log("SEra", searchRes);
    let products = [];

    for (let i = 0; i < searchRes.length; i++) {
      products.push({
        id: searchRes[i]["Inventory Item ID"],
        upc: searchRes[i].UPC,
        old_upc: searchRes[i]["Old UPC"],
        price: searchRes[i]["Price"],
        name: searchRes[i]["Item Name"],
        quantity: searchRes[i]["New Qty"],
        count: searchRes[i]["LS Qty"],
        inventory_item_id: searchRes[i]["Inventory Item ID"],
        location_id: searchRes[i]["Location ID"],
        new_price: searchRes[i]["New Price"],
      });
    }

    setProductData(products);

    var stringLength = string.length;
    if (stringLength === 0) {
      let products = [];
      for (let i = 0; i < exportProducts.length; i++) {
        products.push({
          count: exportProducts[i]["LS Qty"],
          upc: exportProducts[i]["UPC"],
          old_upc: exportProducts[i]["Old UPC"],
          id: exportProducts[i]["Inventory Item ID"],
          inventory_item_id: exportProducts[i]["Inventory Item ID"],
          location_id: exportProducts[i]["Location ID"],
          name: exportProducts[i]["Item Name"],
          price: exportProducts[i]["Price"],
          quantity: exportProducts[i]["New Qty"],
          new_price: exportProducts[i]["New Price"],
        });
      }
      setProductData(products);
      //setProductData(exportProducts);
    }

    // let filteredResults =

    //   const token = getToken();
    //   fetch(`${ApiEndpoints.productSearch}`, {
    //     method: "POST",
    //     headers: {
    //       Authorization: token,
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       search_keyword: string,
    //       locationId: "wh#aa2d8ad1-4ba2-427b-b78f-7da626a379df",
    //       sort_by: "vendor",
    //     }),
    //   })
    //     .then((response) => response.json())
    //     .then((res) => {
    //       const products = [];
    //       if (res.data.length > 0) {
    //         res.data.map((product) =>
    //           product.variants.map((variant) => {
    //             products.push({
    //               id: product.id,
    //               price: variant.price,
    //               name: product.title + " " + variant.title,
    //               quantity: 1,
    //               total_price: variant.price,
    //               count: variant.qty,
    //               variant_id: variant.id,
    //             });
    //           })
    //         );

    //         setItems(products);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  };

  console.log("ITEM", items);

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  };

  const handleOnSelect = (item) => {
    // the item selected
    const exists = productData.find((p) => p.id === item.id);
    if (exists) {
      alert("Item already selected.");
      return;
    }
    setProductData([...productData, item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  const handleChangeQnantity = (key, price, quantity) => {
    console.log("KYEEASDFSADF", key);
    let exportProductValue = JSON.parse(JSON.stringify(exportProducts));
    let productDataValue = JSON.parse(JSON.stringify(productData));
    if (quantity.trim() == "" || quantity <= 0) {
      let value = exportProductValue.find(
        (item) => item["Inventory Item ID"] == key
      );
      console.log("VALUE ", value);
      if (typeof value !== "undefined") {
        value["New Qty"] = 0;
      }
      let value1 = productDataValue.find((item) => item["id"] == key);
      if (typeof value1 !== "undefined") {
        value1.quantity = 0;
      }

      // productData[key].quantity = 1;
      // productData[key].total_price = price;
      setProductData([...productDataValue]);
      productArray = [...exportProductValue];
      setExportProducts([...exportProductValue]);
    } else {
      let value = exportProductValue.find(
        (item) => item["Inventory Item ID"] == key
      );
      console.log("VALUE1", value);
      if (typeof value !== "undefined") {
        value["New Qty"] = quantity;
      }
      let value1 = productDataValue.find((item) => item["id"] == key);
      console.log("VALUASDFASDF", value1);
      if (typeof value1 !== "undefined") {
        value1.quantity = quantity;
      }

      // productData[key].quantity = quantity;
      // productData[key].total_price = quantity * price;
      setProductData([...productDataValue]);
      productArray = [...exportProductValue];
      setExportProducts([...exportProductValue]);
    }
  };

  const handleChangePrice = (key, price, new_price) => {
    console.log("KYEEASDFSADF", key);
    let exportProductValue = JSON.parse(JSON.stringify(exportProducts));
    let productDataValue = JSON.parse(JSON.stringify(productData));
    // // if (quantity.trim() == "" || quantity <= 0) {
    // //   let value = exportProductValue.find((item) => item["Sku"] == key);
    // //   console.log("VALUE ", value);
    // //   if (typeof value !== "undefined") {
    // //     value["New Price"] = 1;
    // //   }
    // //   let value1 = productDataValue.find((item) => item["id"] == key);
    // //   if (typeof value1 !== "undefined") {
    // //     value1.quantity = 1;
    // //   }

    // //   // productData[key].quantity = 1;
    // //   // productData[key].total_price = price;
    // //   setProductData([...productDataValue]);
    // //   setExportProducts([...exportProductValue]);
    // // }
    // else {
    let value = exportProductValue.find(
      (item) => item["Inventory Item ID"] == key
    );
    console.log("VALUE1", value);
    if (typeof value !== "undefined") {
      value["New Price"] = new_price;
    }
    let value1 = productDataValue.find((item) => item["id"] == key);
    console.log("VALUASDFASDF", value1);
    if (typeof value1 !== "undefined") {
      value1.new_price = new_price;
    }

    // productData[key].quantity = quantity;
    // productData[key].total_price = quantity * price;
    setProductData([...productDataValue]);
    productArray = [...exportProductValue];
    setExportProducts([...exportProductValue]);
    //}
  };

  const handleChangeUPC = (key, upc) => {
    console.log("KYEEASDFSADF", key);
    let exportProductValue = JSON.parse(JSON.stringify(exportProducts));
    let productDataValue = JSON.parse(JSON.stringify(productData));
    let value = exportProductValue.find(
      (item) => item["Inventory Item ID"] == key
    );
    console.log("VALUE1", value);
    if (typeof value !== "undefined") {
      value["UPC"] = upc;
    }
    let value1 = productDataValue.find((item) => item["id"] == key);
    console.log("VALUASDFASDF", value1);
    if (typeof value1 !== "undefined") {
      value1.upc = upc;
    }

    setProductData([...productDataValue]);
    productArray = [...exportProductValue];
    setExportProducts([...exportProductValue]);
  };

  const removeItem = (key) => {
    if (window.confirm("Are you want to sure to remove this item ?")) {
      productData.splice(key, 1);
      setProductData([...productData]);
    }
  };

  console.log("PRODUCT DATA", productData);

  console.log("EXPORTTTTT", exportProducts);

  const create = async (values, setSubmitting) => {
    console.log("Submit data", productData);
    setIsLoading(true);

    let name = localStorage.getItem("fullname");

    let reqBody = {};
    reqBody.name = name;
    reqBody.updated_by = name;
    reqBody.location_id = localStorage
      .getItem("selected_location_id")
      .split("#")[1];
    reqBody.items = [];

    //   "items": [
    //     {
    //         "prod_id": "123",
    //         "prod_name": "Vodka",
    //         "var_id": "1213",
    //         "qty": 1,
    //         "old_qty": 10,
    //         "price_adjustment": 90,
    //         "old_price": 100,
    //         "source": "UE",
    //         "shopify_location_id": "65581973702",
    //         "inventory_item_id": "43380506230982"
    //     }
    // ]

    for (let i in exportProducts) {
      if (
        exportProducts[i]["LS Qty"] != exportProducts[i]["New Qty"] ||
        exportProducts[i]["Price"] != exportProducts[i]["New Price"] ||
        exportProducts[i]["Old UPC"] != exportProducts[i]["UPC"]
      ) {
        let body = {
          // prod_id: exportProducts[i]["Sku"],
          old_upc: exportProducts[i]["Old UPC"],
          upc: exportProducts[i]["UPC"],
          prod_name: exportProducts[i]["Item Name"],
          inventory_item_id: exportProducts[i]["Inventory Item ID"],
          shopify_location_id: exportProducts[i]["Location ID"],
          qty: exportProducts[i]["New Qty"],
          old_qty: exportProducts[i]["LS Qty"],
          price_adjustment: exportProducts[i]["New Price"],
          old_price: exportProducts[i]["Price"],
        };

        body.source = "LS";

        reqBody.items.push(body);
      }

      // if (typeof productData[i].source !== "undefined") {
      //   body.source = productData[i].source;
      // } else {
      //   body.source = "";
      // }
    }

    if (reqBody.items.length === 0) {
      toast.info("There is no change in quantity");
      setSubmitting(false);
      setIsLoading(false);

      return;
    }

    const config = {
      method: "POST",
      body: JSON.stringify(reqBody),
    };
    console.log("REQ", reqBody);
    let transactionApi = `${ApiEndpoints.transactionAdminApi}`;
    try {
      let res = await fetch(transactionApi, config);
      let data = await res.json();

      console.log("Data", data);
      if (data.data === "Transaction Successfully Created") {
        setIsLoading(false);
        toast.success("Transaction created successfully");
        handlePage();
        let action = "price-qty-transaction-create";
        let type = "Info";
        let message = "Price Qty Transaction completed successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Transaction failed");
      setIsLoading(false);
      let action = "price-qty-transaction-create";
      let type = "Error";
      let message = "Error in Price Qty Transaction.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }

    // var orderData = {
    //   id: 906462789785,
    //   note: null,
    //   email: "5ef73512-a07f-4ff8-93f9-35c2cb0bbda1@liquorsplit.com",
    //   taxes_included: false,
    //   currency: "USD",
    //   invoice_sent_at: null,
    //   created_at: "2021-12-29T17:42:45+05:30",
    //   updated_at: "2021-12-29T17:42:45+05:30",
    //   tax_exempt: false,
    //   completed_at: null,
    //   name: "#D17",
    //   status: "open",
    //   line_items: [
    //     {
    //       id: 57419242864793,
    //       variant_id: 41557181137049,
    //       product_id: 7207927480473,
    //       title: "Cerveza Aguila 11.2 Oz Bottle",
    //       variant_title: "6 pack",
    //       sku: "K-071",
    //       vendor: "GOLD COAST BEVERAGES",
    //       quantity: 2,
    //       requires_shipping: true,
    //       taxable: true,
    //       gift_card: false,
    //       fulfillment_service: "manual",
    //       grams: 0,
    //       tax_lines: [
    //         {
    //           rate: 0.06,
    //           title: "Florida State Tax",
    //           price: "1.19",
    //         },
    //         {
    //           rate: 0.01,
    //           title: "Miami Dade County Tax",
    //           price: "0.20",
    //         },
    //       ],
    //       applied_discount: null,
    //       name: "Cerveza Aguila 11.2 Oz Bottle - 6 pack",
    //       properties: [],
    //       custom: false,
    //       price: "9.95",
    //       admin_graphql_api_id:
    //         "gid://shopify/DraftOrderLineItem/57419242864793",
    //     },
    //     {
    //       id: 57419242897561,
    //       variant_id: 41557181169817,
    //       product_id: 7207927480473,
    //       title: "Cerveza Aguila 11.2 Oz Bottle",
    //       variant_title: "12 pack",
    //       sku: null,
    //       vendor: "GOLD COAST BEVERAGES",
    //       quantity: 1,
    //       requires_shipping: true,
    //       taxable: true,
    //       gift_card: false,
    //       fulfillment_service: "manual",
    //       grams: 0,
    //       tax_lines: [
    //         {
    //           rate: 0.06,
    //           title: "Florida State Tax",
    //           price: "1.02",
    //         },
    //         {
    //           rate: 0.01,
    //           title: "Miami Dade County Tax",
    //           price: "0.17",
    //         },
    //       ],
    //       applied_discount: null,
    //       name: "Cerveza Aguila 11.2 Oz Bottle - 12 pack",
    //       properties: [],
    //       custom: false,
    //       price: "16.99",
    //       admin_graphql_api_id:
    //         "gid://shopify/DraftOrderLineItem/57419242897561",
    //     },
    //   ],
    //   shipping_address: null,
    //   billing_address: null,
    //   invoice_url:
    //     "https://liquorsplit-development.myshopify.com/59525398681/invoices/161e598ba10f3c42a56f699c19bd08a8",
    //   applied_discount: null,
    //   order_id: null,
    //   shipping_line: null,
    //   tax_lines: [
    //     {
    //       rate: 0.06,
    //       title: "Florida State Tax",
    //       price: "1.19",
    //     },
    //     {
    //       rate: 0.01,
    //       title: "Miami Dade County Tax",
    //       price: "0.20",
    //     },
    //     {
    //       rate: 0.06,
    //       title: "Florida State Tax",
    //       price: "1.02",
    //     },
    //     {
    //       rate: 0.01,
    //       title: "Miami Dade County Tax",
    //       price: "0.17",
    //     },
    //   ],
    //   tags: "",
    //   note_attributes: [],
    //   total_price: "39.47",
    //   subtotal_price: "36.89",
    //   total_tax: "2.58",
    //   payment_terms: null,
    //   admin_graphql_api_id: "gid://shopify/DraftOrder/906462789785",
    //   customer: {
    //     id: 5869352255641,
    //     email: "5ef73512-a07f-4ff8-93f9-35c2cb0bbda1@liquorsplit.com",
    //     accepts_marketing: false,
    //     created_at: "2021-12-28T14:19:12+05:30",
    //     updated_at: "2021-12-28T15:51:32+05:30",
    //     first_name: "Guest",
    //     last_name: "User",
    //     orders_count: 2,
    //     state: "enabled",
    //     total_spent: "155.09",
    //     last_order_id: 4278717317273,
    //     note: null,
    //     verified_email: true,
    //     multipass_identifier: null,
    //     tax_exempt: false,
    //     phone: null,
    //     tags: "",
    //     last_order_name: "#1615",
    //     currency: "USD",
    //     accepts_marketing_updated_at: "2021-12-28T14:19:13+05:30",
    //     marketing_opt_in_level: null,
    //     tax_exemptions: [],
    //     sms_marketing_consent: null,
    //     admin_graphql_api_id: "gid://shopify/Customer/5869352255641",
    //     default_address: {
    //       id: 7097445154969,
    //       customer_id: 5869352255641,
    //       first_name: "Guest",
    //       last_name: "User",
    //       company: "",
    //       address1:
    //         "Azad Market Road, Mithai Pul, Teliwara, Sadar Bazaar, New Delhi, Delhi, India",
    //       address2: "",
    //       city: "New Delhi",
    //       province: null,
    //       country: "United States",
    //       zip: "33130",
    //       phone: "8584123689",
    //       name: "Guest User",
    //       province_code: null,
    //       country_code: "US",
    //       country_name: "United States",
    //       default: true,
    //     },
    //   },
    // };
    // resdata["draft_orders"].push(orderData);
    // console.log(resdata["draft_orders"]);

    console.log("1");
  };

  const onClickSourceSelection = (value, id) => {
    let products = [...productData];
    let item = products.find((item) => item.id == id);
    if (typeof item !== "undefined") {
      item.source = value;
    }
    setProductData(productData);
  };

  // useEffect(() => {
  //   setIsLoading(false);
  // });
  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"New Inventory Update"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            size='sm'
                                            color='primary'
                                            onClick={() => handlePage()}
                                        >List Quotation</Button>
                                    </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={formData}
                  validate={(values) => {
                    values = { ...values, ...productData };
                    const errors = {};
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    create(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                      {/* <Row>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="example1cols1Input"
                                                        >
                                                            Company Name
                                                        </label>
                                                        <Input
                                                            id="example1cols1Input"
                                                            placeholder="Company Name"
                                                            type="CompanyName"
                                                            name="company_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="example2cols1Input"
                                                        >
                                                            Firstname
                                                        </label>
                                                        <Input
                                                            id="example2cols1Input"
                                                            placeholder="Firstname"
                                                            type="Firstname"
                                                            name="first_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                      {/* <Row>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="example3cols1Input"
                                                        >
                                                            Lastname
                                                        </label>
                                                        <Input
                                                            id="example3cols1Input"
                                                            placeholder="Lastname"
                                                            type="Lastname"
                                                            name="last_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="example4cols1Input"
                                                        >
                                                            Email
                                                        </label>
                                                        <Input
                                                            id="example4cols1Input"
                                                            placeholder="Email"
                                                            type="email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}

                      {/* <Row>
                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="example11cols2Input"
                                                        >
                                                            Phone
                                                        </label>
                                                        <Input
                                                            id="example11cols2Input"
                                                            placeholder="Phone"
                                                            type="phone"
                                                            name="phone"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="6" sm="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="exampleFormControlTextarea2"
                                                        >
                                                            Address
                                                        </label>
                                                        <Input
                                                            id="exampleFormControlTextarea2"
                                                            rows="3"
                                                            type="textarea"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example12cols2Input"
                            >
                              Select Product
                            </label>
                            <ReactSearchAutocomplete
                              items={productData}
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              onFocus={handleOnFocus}
                              autoFocus
                              maxResults={0}
                              showClear={true}
                              onClear={onClear}
                              // formatResult={formatResult}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Table className="table" responsive>
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Product Name</th>
                              {/* <th>Sku</th> */}
                              {/* <th>Old UPC</th> */}
                              <th>UPC</th>
                              <th>Price</th>
                              <th>New Price</th>
                              <th>Current Stock</th>
                              <th>New Quantity</th>
                              {/* <th>Source</th> */}
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {productData.map((product, key) => (
                              <tr key={key} id={key}>
                                <td>{key + 1}</td>
                                <td style={{ whiteSpace: "initial" }}>
                                  {product.name}
                                </td>
                                {/* <td>{product.old_upc}</td> */}
                                <td>
                                  {product.upc}
                                  {/* <input 
                                    defaultValue={product.upc}
                                    onChange={(e) => 
                                      handleChangeUPC(
                                        product?.id,
                                        
                                        e.target.value
                                      )                                      
                                    }
                                    value={product.upc}
                                  /> */}
                                </td>
                                {/* <td>{product.id}</td> */}
                                <td>{product.price}</td>
                                <td>
                                  <input
                                    defaultValue={product.new_price}
                                    onChange={(e) =>
                                      handleChangePrice(
                                        product?.id,
                                        product.price,
                                        e.target.value
                                      )
                                    }
                                    value={product.new_price}
                                    min={0}
                                  />
                                </td>

                                <td>{product.count}</td>
                                <td>
                                  <input
                                    type="number"
                                    defaultValue={
                                      product.quantity < 0
                                        ? 0
                                        : product.quantity
                                    }
                                    onChange={(e) =>
                                      handleChangeQnantity(
                                        product?.id,
                                        product.price,
                                        e.target.value
                                      )
                                    }
                                    value={
                                      product.quantity < 0
                                        ? 0
                                        : product.quantity
                                    }
                                    min={0}
                                  />
                                </td>
                                {/* <td>
                                  <Input
                                    id="exampleFormControlSelect3"
                                    type="select"
                                    onChange={(e) =>
                                      onClickSourceSelection(
                                        e.target.value,
                                        product.id
                                      )
                                    }
                                  >
                                    <option value="">Select Source</option>
                                    <option value="DD">Drizly</option>
                                    <option value="UE">Uber Eats</option>
                                  </Input>
                                </td> */}
                                {/* <td>
                                  {parseFloat(product.total_price).toFixed(2)}
                                </td> */}
                                {/* <td>
                                  <Button
                                    className="btn-icon"
                                    size="sm"
                                    color="danger"
                                    type="button"
                                    onClick={() => removeItem(key)}
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </Button>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                      <Row>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Updating" : "Save"}
                          </Button>
                          {!isSubmitting ? (
                            <Button
                              color="secondary"
                              type="button"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>
                          ) : null}
                        </FormGroup>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}
export default CreateOrderAdmin;
