import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import styles from "../../styles/managesku.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";
import moment from "moment";
import { ApiEndpoints } from "../../constants/constant";
import MobileSearchBar from "../../components/SearchBarMobile";
import SearchBar from "../../components/SearchBar";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const headerData = [
  {
    header: "Date",
  },
  {
    header: "App",
  },
  {
    header: "Order Number",
  },
  {
    header: "Product Name",
  },
  {
    header: "Quantity",
  },
  {
    header: "Action",
  },
  {
    header: "Status",
  },
];

const ManageSkuTransaction = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [page, setPage] = useState(1);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState("updated_at");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [selectedPaName, setSelectedPaName] = useState("all");
  const [isEditable, setIsEditable] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [defaultItem, setDefaultItem] = useState();
  const [errors, setErrors] = useState({});
  const [deleteRecordId, setDeleteRecordId] = useState();

  const deleteModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  let location_name = localStorage.getItem("selected_location_name");
  location_name = encodeURIComponent(location_name);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const isMobile = useMediaQuery("(max-width: 500px)");

  const handleEditClick = (item) => {
    setEditMode(item?.record_id);
    setData(tempData);
  };

  const handleSaveClick = async (item) => {
    const isEmptyField =
      item?.date.trim() === "" ||
      item?.app.trim() === "" ||
      item?.order_number.trim() === "" ||
      item?.product_name.trim() === "" ||
      item?.qty.trim() === "";

    if (isEmptyField) {
      toast.error("Please fill in all fields before saving.");

      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    if (location_name === undefined) {
      console.error("location_name is undefined");
      setIsLoading(false);
      return;
    }

    let body = {
      ...item,
      locationName: location_name,
    };
    delete body?.delete_status;
    const apiUrl = ApiEndpoints.updateSKUTransaction;
    const apiKey = "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi";

    try {
      const response = await axios.patch(apiUrl, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const responseData = response?.data;
        toast.success("Updated successfully");
        let action = "manage-sku-transactions-update";
        let type = "Info";
        let message = "Manage sku transaction successfully updated.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("api-response-metrics", apiResponse);
        setLoadMoreEnabled(true);
        getTransactionsData(page, selectedPaName);
        setEditMode(null);
      } else {
        console.error("Update failed:", response?.status);
      }
    } catch (error) {
      console.error("API request failed:", error);
      let action = "manage-sku-transactions-update";
      let type = "Error";
      let message = "Error in Manage sku transaction update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClicks = () => {
    const updatedData =
      data &&
      data.map((item) =>
        item?.record_id === defaultItem?.record_id ? defaultItem : item
      );
    setData(updatedData);
    setEditMode(null);
  };

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    const updatedNewItems = newItems.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    setNewItems(updatedNewItems);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleAddNewInput = () => {
    setErrors({});
    const id = Date.now();
    setNewItems([
      ...newItems,
      {
        id,
        app: "",
        order_number: "",
        date: "",
        product_name: "",
        qty: "",
        locationName: location_name,
      },
    ]);
  };

  const handleAddNewItem = async (id) => {
    const isEmptyField = newItems.some(
      (item) =>
        item?.date.trim() === "" ||
        item?.app.trim() === "" ||
        item?.order_number.trim() === "" ||
        item?.product_name.trim() === "" ||
        item?.qty.trim() === ""
    );

    if (isEmptyField) {
      setErrors({
        date: newItems.some((item) => item?.date.trim() === "")
          ? "Date is required"
          : "",
        app: newItems.some((item) => item?.app.trim() === "")
          ? "App is required"
          : "",
        order_number: newItems.some((item) => item?.order_number.trim() === "")
          ? "Order Number is required"
          : "",
        product_name: newItems.some((item) => item?.product_name.trim() === "")
          ? "Product Name is required"
          : "",
        qty: newItems.some((item) => item?.qty.trim() === "")
          ? "Quantity is required"
          : "",
      });
      return;
    }
    setIsLoading(true);

    setNewItems(newItems.filter((item) => item?.id !== id));
    const requestBody = newItems.find((item) => item?.id === id);

    try {
      const apiUrl = ApiEndpoints.createSKUTransaction;
      const apiKey = "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi";

      const body = requestBody;
      delete body?.id;

      const response = await axios.post(apiUrl, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        toast.success("Transaction created successfully");
        setIsLoading(false);
        const responseData = response?.data;
        setLoadMoreEnabled(true);
        getTransactionsData(page, selectedPaName);
        let action = "manage-sku-transactions-create";
        let type = "Info";
        let message = "Manage sku transaction successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("api-response-metrics-created", apiResponse);
      } else {
        setIsLoading(false);
        console.log("Error: Transaction creation failed");
      }
    } catch (error) {
      setIsLoading(false);
      let action = "manage-sku-transactions-create";
      let type = "Error";
      let message = "Error in Manage sku transaction create.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.error("Error:", error);
    }
  };

  const handleDeleteNewItem = (id) => {
    setNewItems(newItems.filter((item) => item.id !== id));
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const apiUrl = `${ApiEndpoints.deleteSKUTransaction}/${deleteRecordId}`;
    const apiKey = "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi";

    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        toast.success("Transaction Deleted successfully");
        setIsLoading(false);
        const responseData = response?.data;
        setLoadMoreEnabled(true);
        setReasonModalCheck(false);
        getTransactionsData(page, selectedPaName);
        let action = "manage-sku-transactions-delete";
        let type = "Info";
        let message = "Manage sku transaction successfully deleted.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("api-response-metrics-deleted", apiResponse);
        // setNewItems(newItems.filter((item) => item.record_id !== id));
      } else {
        setIsLoading(false);
        console.log("Transaction deletion failed:", response?.status);
      }
    } catch (error) {
      setIsLoading(false);
      let action = "manage-sku-transactions-delete";
      let type = "Error";
      let message = "Error in Manage sku transaction delete.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("api-response-metrics-deleted-error", apiResponse);
      console.error("Error deleting transaction:", error);
    }
  };

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  // const deleteConfirmToggle = () => {
  //   setDeleteConfirmModal((p) => !p);
  // };

  const SortOptions = [
    { id: "product_name", text: "Name" },
    { id: "date", text: "Date" },
    { id: "updated_at", text: "Updated At" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const mergeAndSortTransactionList = (mergedTransactionList) => {
    if (selectedSortOption) {
      mergedTransactionList.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedTransactionList;
  };

  const getTransactionsData = (pageNum, selectedPaName) => {
    const config = {
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    setIsLoading(true);

    let url = `${ApiEndpoints.getManageSKUTransactions}?&paName=${selectedPaName}&locationName=${location_name}&pageNo=${pageNum}&pageSize=10`;

    if (selectedSortOption) {
      url = url + `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (searchKeyword.length > 0) {
      url = url + `&search=${searchKeyword}`;
    }

    axios
      .get(url, config)
      .then((response) => {
        console.log("API Response", response?.data);
        const responseData = response?.data?.body?.response;
        const responsePagination = response?.data?.body?.pagination;
        console.log(responsePagination?.nextPage, "responsePaginationnn");
        console.log(responseData, "responsedataa");

        if (responseData !== undefined && responseData !== "undefined") {
          console.log("transactionsData123", responseData, "11111");

          if (responseData.length > 0) {
            const updatedTransactionList =
              pageNum === 1 ? responseData : [...responseData];
            const mergedTransactionList = mergeAndSortTransactionList(
              updatedTransactionList
            );

            setData(mergedTransactionList);
            setTempData(mergedTransactionList);

            if (responsePagination?.nextPage == "true") {
              setLoadMoreEnabled(true);
            } else {
              setLoadMoreEnabled(false);
            }
          } else {
            if (pageNum === 1) {
              setData(responseData);
              setTempData(responseData);
            }
            if (responsePagination?.nextPage == "true") {
              setLoadMoreEnabled(true);
            } else {
              setLoadMoreEnabled(false);
            }
          }
        } else {
          const responseData = response?.data?.message;
          setData([]);
          setTempData([]);
          setLoadMoreEnabled(false);
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("API Error", err);
      });
  };

  useEffect(() => {
    if (selectedSortOption) {
      getTransactionsData(page, selectedPaName);
    }
  }, [selectedPaName, selectedSortOption, sortDirection]);

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getTransactionsData(pageNum, selectedPaName);
  };

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getTransactionsData(page, selectedPaName);
    } else if (searchKeyword === "") {
      getTransactionsData(page, selectedPaName);
    }
  }, [searchKeyword]);

  const selectStatus = (status) => {
    setPage(1);
    setSelectedStatus(status);
    setSelectedPaName(status.toLowerCase());
    setIsEditable(false);
    setEditMode(null);
    setNewItems([]);
  };

  let btns = [
    "All",
    "DoorDash",
    "GrubHub",
    "LiquorSplit",
    "UberEats",
    "InstaCart",
    "Drizly",
  ];

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyword("");
    setSortDirection("ASC");
    setSelectedSortOption("updated_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getTransactionsData(1, selectedPaName);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("ASC");
    setSelectedSortOption("updated_at");
    setClear(true);
    setShowCard(false);
  };

  const handleChange = (e, item) => {
    const { name, value } = e.target;
    console.log(name, value, "nameeeeeee");
    const formattedValue =
      name === "date"
        ? moment(value, "YYYY-MM-DD").format("MM/DD/YYYY")
        : value;
    setData((prevData) =>
      prevData.map((prevItem) =>
        prevItem.record_id === item.record_id
          ? { ...prevItem, [name]: formattedValue }
          : prevItem
      )
    );
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return moment(dateString, "MM/DD/YYYY").format("YYYY-MM-DD");
  };
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Manage SKU Transactions"}
                    </h6>
                  </Col>
                  <Col className="text-md-right" lg="6" xs="12">
                    {(userRoles.includes("1") ||
                      roleActions.includes("add_sku_transaction")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={handleAddNewInput}
                      >
                        Add SKU Transactions
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className={styles.productList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    // getDetail={getTransactionsData}
                    getDetail={() => getTransactionsData(1, selectedPaName)}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search"
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    // getDetail={getTransactionsData}
                    getDetail={() => getTransactionsData(1, selectedPaName)}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search"
                    dropDown={false}
                    orderList={false}
                    // fcm={true}
                    // fcmSearchBtn={fcmSearchBtn}
                    // fcmInputSearch={fcmInputSearch}
                    search={search}
                  />
                )}
              </CardBody>
              <div style={{ height: "550px", overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item, index) => (
                        <th key={index} scope="col">
                          {item.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {newItems &&
                      newItems.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Input
                                type="date"
                                name="date"
                                value={item?.date}
                                className={styles.input_sku}
                                placeholder="Date"
                                onChange={(e) => handleInputChange(e, item.id)}
                              />
                              {errors.date && (
                                <span className={styles.error}>
                                  {errors.date}
                                </span>
                              )}
                            </td>
                            <td>
                              <Input
                                type="text"
                                name="app"
                                value={item?.app}
                                placeholder="App"
                                className={styles.input_sku}
                                onChange={(e) => handleInputChange(e, item.id)}
                              />
                              {errors.app && (
                                <span className={styles.error}>
                                  {errors.app}
                                </span>
                              )}
                            </td>
                            <td>
                              <Input
                                type="text"
                                name="order_number"
                                value={item.order_number}
                                placeholder="Order Number"
                                className={styles.input_sku}
                                onChange={(e) => handleInputChange(e, item.id)}
                              />
                              {errors.order_number && (
                                <span className={styles.error}>
                                  {errors.order_number}
                                </span>
                              )}
                            </td>
                            <td>
                              <Input
                                type="text"
                                name="product_name"
                                value={item?.product_name}
                                placeholder="Product Name"
                                onChange={(e) => handleInputChange(e, item.id)}
                              />
                              {errors.product_name && (
                                <span className={styles.error}>
                                  {errors.product_name}
                                </span>
                              )}
                            </td>
                            <td>
                              <Input
                                type="number"
                                name="qty"
                                value={item.qty}
                                className={styles.input_sku}
                                placeholder="Quantity"
                                onChange={(e) => handleInputChange(e, item.id)}
                                min={0}
                              />
                              {errors.qty && (
                                <span className={styles.error}>
                                  {errors.qty}
                                </span>
                              )}
                            </td>
                            <td>
                              <ButtonGroup>
                                {(userRoles.includes("1") ||
                                  roleActions.includes(
                                    "add_sku_transaction"
                                  )) && (
                                  <>
                                    <Button
                                      className="btn-icon-only"
                                      color="success"
                                      id={"saveSkuAdd" + item?.id}
                                      onClick={() => handleAddNewItem(item?.id)}
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fa fa-save"></i>
                                      </span>
                                    </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"saveSkuAdd" + item?.id}
                                    >
                                      Save SKU
                                    </UncontrolledTooltip>

                                    <Button
                                      className="btn-icon-only"
                                      color="danger"
                                      id={"cancelSkuAdd" + item?.id}
                                      onClick={() => {
                                        handleDeleteNewItem(item?.id);
                                      }}
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fa fa-times"></i>
                                      </span>
                                    </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"cancelSkuAdd" + item?.id}
                                    >
                                      Cancel SKU
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>

                  <tbody className="list">
                    {data && data.length ? (
                      data.map((item, index) => {
                        console.log(item.date, "datee");
                        return (
                          <tr key={index}>
                            <td>
                              {editMode === item?.record_id ? (
                                <Input
                                  type="date"
                                  name="date"
                                  className={styles.input_sku}
                                  value={formatDate(item?.date)}
                                  onChange={(e) => handleChange(e, item)}
                                />
                              ) : item?.date?.length > 0 ? (
                                moment(item.date).format("DD/MM/YYYY")
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>
                              {editMode === item?.record_id ? (
                                <Input
                                  type="text"
                                  value={item?.app}
                                  name="app"
                                  className={styles.input_sku}
                                  onChange={(e) => handleChange(e, item)}
                                />
                              ) : item?.app?.length > 0 ? (
                                item.app
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {editMode === item.record_id ? (
                                <Input
                                  type="text"
                                  value={item?.order_number}
                                  name="order_number"
                                  className={styles.input_sku}
                                  onChange={(e) => handleChange(e, item)}
                                />
                              ) : item.order_number?.length > 0 ? (
                                item.order_number
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {editMode === item.record_id ? (
                                <Input
                                  type="text"
                                  value={item?.product_name}
                                  name="product_name"
                                  onChange={(e) => handleChange(e, item)}
                                />
                              ) : item.product_name?.length > 0 ? (
                                item.product_name
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {editMode === item.record_id ? (
                                <Input
                                  type="number"
                                  value={item?.qty}
                                  className={styles.input_sku}
                                  name="qty"
                                  onChange={(e) => handleChange(e, item)}
                                  min={0}
                                />
                              ) : item.qty?.length > 0 ? (
                                item.qty
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>
                              {editMode === item?.record_id ? (
                                <>
                                  <ButtonGroup>
                                    <Button
                                      className="btn-icon-only"
                                      color="success"
                                      id={"saveSku" + item?.record_id}
                                      onClick={() => handleSaveClick(item)}
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fa fa-save"></i>
                                      </span>
                                    </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"saveSku" + item?.record_id}
                                    >
                                      Save SKU
                                    </UncontrolledTooltip>
                                    <Button
                                      className="btn-icon-only"
                                      color="danger"
                                      id={"cancelSku" + item?.record_id}
                                      onClick={() => {
                                        handleCancelClicks(item);
                                      }}
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fa fa-times"></i>
                                      </span>
                                    </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"cancelSku" + item?.record_id}
                                    >
                                      Cancel SKU
                                    </UncontrolledTooltip>
                                  </ButtonGroup>
                                </>
                              ) : (
                                <ButtonGroup>
                                  {(userRoles.includes("1") ||
                                    roleActions.includes(
                                      "edit_sku_transaction"
                                    )) && (
                                    <>
                                      <Button
                                        className="btn-icon-only"
                                        color="success"
                                        id={"editSku" + item?.record_id}
                                        onClick={() => {
                                          setDefaultItem(item);
                                          handleEditClick(item);
                                        }}
                                        disabled={
                                          item?.delete_status ? true : false
                                        }
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="fa fa-edit"></i>
                                        </span>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={"editSku" + item?.record_id}
                                      >
                                        Edit SKU
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Modal
                                    isOpen={reasonModalCheck}
                                    fade={false}
                                    toggle={deleteModalToggle}
                                    size="sm"
                                    centered
                                    backdrop="static"
                                  >
                                    <ModalBody>
                                      Are you sure you want to delete?
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          handleDelete(item.record_id)
                                        }
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        color="secondary"
                                        onClick={deleteModalToggle}
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  {(userRoles.includes("1") ||
                                    roleActions.includes(
                                      "delete_sku_transaction"
                                    )) && (
                                    <>
                                      {item?.delete_status ? (
                                        <Button
                                          className="btn-icon-only"
                                          color="danger"
                                          disabled={true}
                                        >
                                          <span className="btn-inner--icon">
                                            <i className="fa fa-ban"></i>
                                          </span>
                                        </Button>
                                      ) : (
                                        <>
                                          <Button
                                            className="btn-icon-only"
                                            color="danger"
                                            id={"deleteSku" + item?.record_id}
                                            onClick={() => {
                                              deleteModalToggle();
                                              setDeleteRecordId(
                                                item?.record_id
                                              );
                                            }}
                                          >
                                            <span className="btn-inner--icon">
                                              <i className="fa fa-trash"></i>
                                            </span>
                                          </Button>
                                          <UncontrolledTooltip
                                            delay={0}
                                            target={
                                              "deleteSku" + item?.record_id
                                            }
                                          >
                                            Delete SKU
                                          </UncontrolledTooltip>
                                        </>
                                      )}
                                    </>
                                  )}
                                </ButtonGroup>
                              )}
                            </td>
                            {item?.delete_status ? (
                              <td>
                                <div style={{ color: "red" }}>Deleted</div>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ManageSkuTransaction;
