import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import styles from "../../styles/importEdit.module.css";
import { logUserMetrics } from "../../../utils/file";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const POSFileView = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { item } = location?.state;
  const [allFileData, setAllFileData] = useState([]);

  const getPosFileData = () => {
    setIsLoading(true);
    let getData = `${ApiEndpoints.posMapping}/${item.id}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("responsFile", res);
        setAllFileData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getPosFileData();
  }, []);

  console.log("allfiledata", allFileData);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"POS Mapping"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported By
                      </label>
                      <div>{allFileData?.searchItems?.updated_by}</div>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Created At
                      </label>
                      <div>
                        {moment(allFileData?.searchItems?.created_at).format(
                          "MM/DD/YYYY"
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Pos Name
                      </label>
                      <div>{allFileData?.searchItems?.pos_name}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Mapping Data
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Table
                    className="table"
                    bordered
                    hover
                    responsive
                    style={{ border: "1px solid #e9ecef", width: "50%" }}
                  >
                    <thead>
                      <tr>
                        <th>File Columns</th>
                        <th>Master Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allFileData?.mappingKey &&
                        allFileData?.mappingKey.map((item, key) => (
                          <tr key={key} id={key}>
                            <td>{item?.file_key}</td>
                            <td>{item?.mapping_key}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default POSFileView;
