import React, { useState, useEffect } from 'react';
import Header from "../Header.js";
import { ClipLoader } from 'react-spinners';
import { useHistory,useLocation,useParams } from "react-router-dom";
import { ApiEndpoints } from "../../constants/constant.js";
import Backdrop from '@material-ui/core/Backdrop';
import FullPageLoader from "../../../components/FullPageLoader";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import formatDateTimeInTimeZone from '../../helpers/formatDateTimeInTimeZone.js';
import moment from 'moment';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Button,
    Row,
    Table,
    Badge, Col
} from "reactstrap";

import { ApiUrls } from "../../../common/apiconstant.js";
import { getToken } from "../../../onboarding/index.js";
const options =[];
const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
function ViewSubCategoryDetails(props) {
    const classes = useStyles();
    let location = useLocation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [originalData, setOriginalData] = useState([])
    const [locationD, setlocationD]=useState([]);
    const token = getToken();
    const config = {
        headers: { Authorization: token },
      };

      let { sub_menu_id } = useParams();
      
    // console.log("LOOLOOLOO==>",location.state);
    // let {created_at,id,actions,permission,updated_at} = location.state.item;
    //   let LocationApi = `${ApiEndpoints.locationList}?size=10`;
    
    
    const getSubCategories = () => {
        setIsLoading(true);
        let getSubCategoryApi = `${ApiEndpoints.viewsubCategoryDetail}/${sub_menu_id}`;
        console.log("SCSCSCSC",getSubCategoryApi)
            fetch(
                getSubCategoryApi,
            )
                .then((response) => response.json())
                .then((res) => {
                  setIsLoading(false);
                  console.log(res)
                  const responseSubCategory = res?.data;

                   setOriginalData({
                    sub_category:responseSubCategory.name,
                    description:responseSubCategory.description,
                    id:responseSubCategory.id,
                    updated:responseSubCategory.updated_at,
                    updatedby:responseSubCategory.updated_by,
                    
                   });
        
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("989889898",err);
                });
    }

      const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
    const handleView = () => {
        history.goBack();
    }
    useEffect(() => {
        getSubCategories();
      }, []);


      if (isLoading) {
        return (
          <>
            <FullPageLoader />
          </>
        );
      }

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                    <span
                                       className="back-btn"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0"
                                         style={{ marginLeft: "10px" }}>
                                            {"Sub Category Detail"}
                                        </h6>
                                    </Col>
                                    {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => handleView()}
                                            size="sm"
                                        >
                                            Back To All Sub Category
                                        </Button>
                                    </Col> */}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                
                            <Table border="1" style={{ 'borderColor': "#e9ecef" }} responsive>
                                    <tbody>
                                       
                                        <tr>
                                            <th>Name</th>
                                            <td>{originalData.sub_category}</td>
                                        </tr>
                                        <tr>
                                            <th>Description</th>
                                            <td>{originalData.description}</td>
                                        </tr>
                                        <tr>
                                            <th>ID</th>
                                            <td>{originalData.id}</td>
                                        </tr>
                                        <tr>
                                            <th>Updated</th>
                                            <td>{moment(originalData.updated).format("MM/DD/YYYY hh:mm a")}</td>
                                        </tr>
                                        <tr>
                                            <th>Updated By</th>
                                            <td>{originalData.updatedby}</td>
                                        </tr>
                                       
                                     </tbody>
                                </Table>  
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* <Backdrop className={classes.backdrop} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </>

    )
}

export default ViewSubCategoryDetails;