import React, { useState, useEffect } from 'react';
import Header from "../Header.js";
import { ClipLoader } from 'react-spinners';
import { useHistory,useLocation } from "react-router-dom";
import { ApiEndpoints } from "../../constants/constant";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Button,
    Row,
    Table,
    Badge, Col
} from "reactstrap";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";
import formatDateTimeInTimeZone from '../../helpers/formatDateTimeInTimeZone.js';
const options =[];
const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
function ViewRoleDetails(props) {
    const classes = useStyles();
    let location = useLocation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [originalData, setOriginalData] = useState([])
    const [locationD, setlocationD]=useState([]);
    const token = getToken();

    console.log("LOOLOOLOO==>",location.state);
    let {created_at,id,permissions,role,updated_at} = location.state.item;
    const config = {
        headers: { Authorization: token },
      };
      let LocationApi = `${ApiEndpoints.locationList}?size=10`;

    const handleView = () => {
        const viewPath = "/admin/role"
        history.push(viewPath)
    }
    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
   useEffect(() => {
    setIsLoading(false);
   
     return () => {
       
     }
   }, [])
   


    

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                                            {"Role Detail"}
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => handleView()}
                                            size="sm"
                                        >
                                            Back To All Role
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                
                                <Table border="1" style={{ 'borderColor': "#e9ecef" }} responsive>
                                    <tbody>
                                            <tr>
                                            <th>Create</th>
                                            <td>{moment(created_at).format("MM/DD/YYYY")}</td>
                                        </tr>
                                       
                                        <tr>
                                            <th>Roll Name</th>
                                            <td>{capitalizeFirst(role)}</td>
                                        </tr>
                                        <tr>
                                            <th>Updated</th>
                                            <td>{moment(updated_at).format("MM/DD/YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <th>Permission</th>
                                            <td>{permissions.map(item=><h4>{capitalizeFirst(item).replace("_"," ")}</h4>)}</td>
                                        </tr>
                                       
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
            <Backdrop className={classes.backdrop} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>

    )
}

export default ViewRoleDetails;