import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const FullPageLoader = () => {
    const isLoading =true
    return (
        <div className="sweet-loading card">
            <div className="spinner-container">
                <ClipLoader
                    className="spinner"
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={isLoading}
                />
            </div>
        </div >
    )

}

export default FullPageLoader