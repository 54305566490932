import React, { useMemo, useEffect, useState, useRef } from "react";
import Header from "../../ui/Header.js";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file.js";
import { useHistory } from "react-router-dom";
import {
  Col,
  Card,
  Label,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  CardFooter,
  Table,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,
  Button,
  Row,
  ButtonGroup,
  Media,
  UncontrolledTooltip,
  Input,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import IMGetLocation from "../ui/IMGetLocation.js";
import "./style.css";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Loader from "../../../onboarding/components/Loader";

import { AlexaForBusiness } from "aws-sdk";
import { ApiEndpoints } from "../../constants/constant.js";
import moment from "moment";
import getToken from "../../../onboarding/getToken.js";
import { ExportToPriceExcel } from "../../../components/ExportExcel/priceIndex.js";
const awsSDKconfig = require("../../config/awsSDKConfig.js");
const AWS = require("aws-sdk");
AWS.config.update(awsSDKconfig);
const cognito = new AWS.CognitoIdentityServiceProvider();
const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

function ProductSummary() {
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("All");
  const modalToggle = () => setModal(!modal);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [inventoryControls, setInventoryControls] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [isImport, setIsImport] = useState(false);
  const [importStatus, setImportStatus] = useState("");
  const [isExcelData, setIsExcelData] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState([]);

  //console.log("LOcation", userLocation);
  const history = useHistory();

  const getTransactionData = () => {
    setIsLoading(true);
    fetch(`${ApiEndpoints.productTransaction}?page=${page}&limit=10`)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("res", res);
        const responseProductData = res?.data;
        console.log("Response Product Data", res);
        if (responseProductData.length > 0) {
          setProductData((prevList) => [...prevList, ...responseProductData]);

          if (responseProductData.length < 10) {
            setLoadMoreEnabled(false);
          }
        } else {
          setLoadMoreEnabled(false);
        }
        // setProductData(responseProductData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);
      });
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getTransactionData();
  }, [page]);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Product Details"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody></CardBody>
              <div style={{ height: "600px", overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      <th>Uploaded At</th>
                      {/* <th>Name</th> */}
                      <th>Product Name</th>
                      <th>Handle</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {productData?.map((item) => (
                      <tr key={item}>
                        <td>{moment(item.created_at).format("MM/DD/YY")}</td>
                        {/* <td>{item.name}</td> */}
                        <td>{item.product_title}</td>
                        <td>{item.handle}</td>
                        <td>{item.status}</td>
                        <td>
                          <Button
                            className="btn-icon-only"
                            color="info"
                            type="button"
                            onClick={() =>
                              history.push(
                                `/admin/transaction-details/${item.id}`,
                                {
                                  item: item,
                                  permission: "view",
                                }
                              )
                            }
                            id={"tooltipv" + "view"}
                          >
                            <span className="btn-inner--icon">
                              <i className="fa fa-eye"></i>
                            </span>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {loadMoreEnabled && (
                <CardFooter>
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default ProductSummary;
