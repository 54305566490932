import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDragListView from "react-drag-listview";
import ReactTags from "react-tag-autocomplete";
import "./style.css";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant";

const DragBar = ({ dragData, onDragEnd, onAddition, onDelete, tags }) => {
  console.log("DRAG DAT", dragData);
  //const [dragData, setDragData] = useState(data);

  // const onDragEnd = (fromIndex, toIndex) => {
  //   const newData = [...dragData];
  //   const item = newData.splice(fromIndex, 1)[0];
  //   newData.splice(toIndex, 0, item);
  //   setDragData(newData);
  //   setDraggedList(newData);
  // };

  const dragProps = {
    onDragEnd,
    nodeSelector: "li",
    handleSelector: "a",
  };

  const reactTags = useRef();

  const [suggestions, setSuggestions] = useState([
    // { id: 1, name: "Apples" },
    // { id: 2, name: "Pears" },
    // { id: 3, name: "Bananas" },
    // { id: 4, name: "Mangos" },
    // { id: 5, name: "Lemons" },
    // { id: 6, name: "Apricots" },
  ]);

  const getSubMenus = async () => {
    try {
      let res = await axios.get(
        ApiEndpoints.subCategoryList + "?page=1&limit=100"
      );
      console.log("RSPONSE", res);
      setSuggestions(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubMenus();
  }, []);

  return (
    <ReactDragListView {...dragProps}>
      <ol
        // className="dragbar"
        style={{ paddingLeft: 0 }}
      >
        {dragData.map((item, index) => (
          <li
            key={index}
            style={{
              padding: "10px 15px",
              display: "grid",
              margin: "5px 0",
              background: "rgba(246,249,252, 0.3)",
              alignItems: "center",
              cursor: "pointer",
              gap: 10,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <span style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <a href="#" className="drag-icon">
                <i className="fas fa-grip-vertical"></i>
              </a>
              {item.display_name}
            </span>

            {/* <TagsInput
              value={item.sub_category}
              //  onChange={setOptions}
              name="sub_category"
              placeHolder="Add Sub Category"
            /> */}
            <ReactTags
              ref={reactTags}
              tags={item.sub_menu ? item.sub_menu : []}
              suggestions={suggestions}
              onDelete={(tagIndex) => onDelete(tagIndex, item)}
              onAddition={(tag) => onAddition(tag, item)}
              placeholderText="Add sub category"
            />
          </li>
        ))}
      </ol>
    </ReactDragListView>
  );
};

export default DragBar;
