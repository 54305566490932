import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CreateOrderDiscount = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [discountType, setDiscountType] = useState("code");
  const [valueType, setValueType] = useState("percentage");
  const [mpr, setMpr] = useState("no_minimum");
  const [customerEligibility, setCustomerEligibility] =
    useState("all");
  const [maxDiscount, setMaxDiscount] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [combineDiscount, setCombineDiscount] = useState([]);
  const [isEndDate, setIsEndDate] = useState(false);
  const [isEndTime, setIsEndTime] = useState();
  const [isStartDate, setIsStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [discountCode, setDiscountCode] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [minPurchaseAmount, setMinimumPurchaseAmount] = useState('');
  
  const [minPurchaseQuantity, setMinimumPurchaseQuantity] = useState('');
  const [discountUse, setDiscountUse] = useState(''); 

  let initialValues = {
    discountCode: "",
    discountValue: "",
    isStartDate: "",
    startTime: "",
  };


  const createDiscount=async()=>{
    console.log(discountCode);
    let requestBody={}
    requestBody.price_rule = {}
    if(discountCode.length > 0){
      requestBody.price_rule.title = discountCode
    }else{
      //Error toast
    }
    if(valueType){
      requestBody.price_rule.value_type=valueType
    }
    if(valueType === "percentage"){
      requestBody.price_rule.value=  Number(discountValue)*-1
    }else{
      requestBody.price_rule.value=  Number(discountValue)*-1
    }
    requestBody.price_rule.customer_selection = customerEligibility
    requestBody.price_rule.target_type="line_item"
    requestBody.price_rule.target_selection= "all"
    requestBody.price_rule.allocation_method= "across"
    requestBody.price_rule.once_per_customer= maxDiscount.includes("discount_one")
    if(maxDiscount.includes("limit_discount_used")){
      requestBody.price_rule.usage_limit= Number(discountUse)
    }
  
    requestBody.price_rule.starts_at= moment(`${isStartDate}T${startTime}`).format();
    if(isEndDate){
      requestBody.price_rule.ends_at= moment(`${isEndDate}T${isEndTime}`).format(); 
    }
  
    if(mpr !== "no_minimum"){
      if(mpr =="min_amount"){
        requestBody.price_rule.prerequisite_subtotal_range ={"greater_than_or_equal_to":minPurchaseAmount}
      }else if(mpr =="min_quantity"){
        requestBody.price_rule.prerequisite_quantity_range ={"greater_than_or_equal_to":Number(minPurchaseQuantity)}
      }
    } 
  
    requestBody.created_by = localStorage.getItem("fullname");
    requestBody.updated_by = localStorage.getItem("fullname");
    requestBody.location_id = localStorage.getItem("selected_location_id").split("#")[1];
    requestBody.discount_type = "Order Discount"
  
    console.log("requestBody",JSON.stringify(requestBody))
    console.log("timee",startTime);
    console.log("endtimee",isEndTime);
  

    try {
      let res =await axios.post(`${ApiEndpoints.discountsList}`,requestBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        toast.success("Discount Created Successfully");
        history.push("/admin/discount");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

  }

  function generateCoupon(length, setFieldValue) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let coupon = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      coupon += characters.charAt(randomIndex);
    }
  setDiscountCode(coupon)
  setFieldValue("discountCode",coupon)
  }


  useEffect(() => {
    setDiscountValue("")
  },[valueType])

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>     
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Create Order discount"}
                    </h6>
                  </Col>                 
                </Row>
              </CardHeader>
              <CardBody>

              {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}

                <Formik
                  initialValues={initialValues}
                  validate={(values) => {
                    let value = values;
                    console.log("value", values);
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.discountCode) {
                      errors.discountCode = "Required";
                    }

                    if (!values.discountValue) {
                      errors.discountValue = "Required";
                    }

                    if (!values.isStartDate) {
                      errors.isStartDate = "Required";
                    }

                    if (!values.startTime) {
                      errors.startTime = "Required";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    createDiscount(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col
                          lg="12"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <Col lg="6">
                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4 style={{ marginBottom: 0 }}>
                                      Amount Off Order
                                    </h4>
                                    <h4 style={{ marginBottom: 0 }}>
                                      Order Discount
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                                    <CardTitle tag="h5">Method</CardTitle>
                                    <CardText>
                                      <FormGroup check>
                                        <Input
                                          name="discountType"
                                          type="radio"
                                          checked={discountType === "code"}
                                          onChange={() =>
                                            setDiscountType("code")
                                          }
                                        />
                                        <Label check>Discount code</Label>
                                      </FormGroup>
                                    </CardText>
                                    <CardText>
                                      <CardTitle
                                        tag="p"
                                        style={{ marginBottom: 0 }}
                                      >
                                        {discountType === "code"
                                          ? "Discount code"
                                          : "Title"}
                                      </CardTitle>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: 5,
                                          alignItems: "center",
                                        }}
                                      >
                                      <Input 
                                          onChange={(e)=>{
                                            setDiscountCode(e.target.value)
                                            setFieldValue("discountCode",e.target.value)
                                          }}
                                          value={discountCode}
                                      />
                                        {discountType === "code" && (
                                          <Button
                                            type="button"
                                            style={{ margin: 0 }}
                                            onClick={()=>generateCoupon(10,
                                              setFieldValue)} 
                                          >
                                            Generate
                                          </Button>
                                        )}
                                      </div>
                                      <p className="ErrorMessage">
                                        {errors.discountCode && touched.discountCode
                                          ? errors.discountCode
                                          : null}
                                      </p>
                                      <CardTitle
                                        tag="p"
                                        style={{ marginBottom: 0 }}
                                      >
                                        {discountType === "code"
                                          ? "Customers must enter this code at checkout."
                                          : "Customers will see this in their cart and at checkout."}
                                      </CardTitle>
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h5">Value</CardTitle>
                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        gridTemplateColumns: "auto 1fr",
                                        gap: 16,
                                      }}
                                    >
                                      <ButtonGroup
                                        size="sm"
                                        style={{ height: 46 }}
                                      >
                                        <Button
                                          onClick={() =>
                                            setValueType("percentage")
                                          }
                                          type="button"
                                          style={{
                                            margin: 0,
                                            background:
                                              valueType === "percentage"
                                                ? "#616a75"
                                                : "#f7fafc",
                                            color:
                                              valueType === "percentage"
                                                ? "#fff"
                                                : "#212529",
                                          }}
                                        >
                                          Percentage
                                        </Button>
                                        <Button
                                          onClick={() => setValueType("fixed_amount")}
                                          type="button"
                                          style={{
                                            margin: 0,
                                            background:
                                              valueType === "fixed_amount"
                                                ? "#616a75"
                                                : "#f7fafc",
                                            color:
                                              valueType === "fixed_amount"
                                                ? "#fff"
                                                : "#212529",
                                          }}
                                        >
                                          Fixed Amount
                                        </Button>
                                      </ButtonGroup>
                                      <InputGroup>
                                        {valueType === "fixed_amount" && (
                                          <InputGroupText
                                            style={{ borderRight: "none" }}
                                          >
                                            $
                                          </InputGroupText>
                                        )}

                                        <Input
                                          placeholder={
                                            valueType === "fixed_amount" ? "0.00" : "0"
                                          }
                                          style={{
                                            borderLeft:
                                              valueType === "fixed_amount" && "none",
                                            borderRight:
                                              valueType === "percentage" &&
                                              "none",
                                          }}
                                          onChange={(e)=>{
                                            setDiscountValue(e.target.value)
                                            setFieldValue("discountValue",e.target.value)
                                          }}
                                          value={discountValue}
                                          pattern={valueType === "percentage" && "[1-9][0-9]?|100"}
                                          title={valueType === "percentage" && "Please enter value between 1-100 without decimal"}
                                        />

                                        {valueType === "percentage" && (
                                          <InputGroupText
                                            style={{ borderLeft: "none" }}
                                          >
                                            %
                                          </InputGroupText>
                                        )}
                                      </InputGroup>
                                    </div>
                                    <p className="ErrorMessage">
                                        {errors.discountValue && touched.discountValue
                                          ? errors.discountValue
                                          : null}
                                      </p>
                                  </CardHeader>                                  
                                </Card>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">
                                      Minimum purchase requirements
                                    </CardTitle>
                                    <div>
                                      <FormGroup check>
                                        <Input
                                          name="mpr"
                                          type="radio"
                                          checked={mpr === "no_minimum"}
                                          onChange={() => setMpr("no_minimum")}
                                        />
                                        <Label check>
                                          No minimum requirements
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                          name="mpr"
                                          type="radio"
                                          checked={mpr === "min_amount"}
                                          onChange={() => setMpr("min_amount")}
                                        />
                                        <Label check>
                                          Minimum purchase amount ($)
                                        </Label>
                                      </FormGroup>
                                      {mpr === "min_amount" && (
                                        <FormGroup style={{ paddingLeft: 20 }}>
                                          <InputGroup
                                            style={{
                                              maxWidth: 150,
                                              height: 36,
                                              // margin: 10,
                                            }}
                                          >
                                            <InputGroupText
                                              style={{ borderRight: "none" }}
                                            >
                                              $
                                            </InputGroupText>

                                            <Input
                                              placeholder={"0.00"}
                                              style={{
                                                borderLeft: "none",
                                              }}
                                              onChange={(e)=>setMinimumPurchaseAmount(e.target.value)}
                                              value={minPurchaseAmount}
                                            />
                                          </InputGroup>
                                          <CardText style={{ marginTop: 10 }}>
                                            Applies to all products.
                                          </CardText>
                                        </FormGroup>
                                      )}
                                      <FormGroup check>
                                        <Input
                                          name="mpr"
                                          type="radio"
                                          checked={mpr === "min_quantity"}
                                          onChange={() =>
                                            setMpr("min_quantity")
                                          }
                                        />
                                        <Label check>
                                          Minimum quantity of items
                                        </Label>
                                      </FormGroup>
                                      {mpr === "min_quantity" && (
                                        <FormGroup style={{ paddingLeft: 20 }}>
                                          <InputGroup
                                            style={{
                                              maxWidth: 150,
                                              height: 36,
                                            }}
                                          >
                                            <Input 
                                              onChange={(e)=>setMinimumPurchaseQuantity(e.target.value)}
                                              value={minPurchaseQuantity}
                                            />
                                          </InputGroup>
                                          <CardText style={{ marginTop: 10 }}>
                                            Applies to all products.
                                          </CardText>
                                        </FormGroup>
                                      )}
                                    </div>
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <Card
                                className="my-2"
                                color="primary"
                                outline
                                style={{
                                  width: "650px",
                                }}
                              >
                                <CardHeader>
                                  <CardTitle tag="h4">
                                    Customer eligibility
                                  </CardTitle>
                                  <div>
                                    <FormGroup check>
                                      <Input
                                        name="customerEligibility"
                                        type="radio"
                                        checked={
                                          customerEligibility ===
                                          "all"
                                        }
                                        onChange={() =>
                                          setCustomerEligibility(
                                            "all"
                                          )
                                        }
                                      />
                                      <Label check>All customers</Label>
                                    </FormGroup>
                                  </div>
                                </CardHeader>
                              </Card>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">
                                      Maximum discount uses
                                    </CardTitle>
                                    <div>
                                      <FormGroup check>
                                        <Input
                                          name="maxDiscount"
                                          type="checkbox"
                                          checked={maxDiscount.includes(
                                            "limit_discount_used"
                                          )}
                                          onChange={() => {
                                            let arr = [...maxDiscount];
                                            if (
                                              arr.includes(
                                                "limit_discount_used"
                                              )
                                            ) {
                                              let index = arr.findIndex(
                                                (elm) =>
                                                  elm === "limit_discount_used"
                                              );
                                              arr.splice(index, 1);
                                              setMaxDiscount(arr);
                                            } else {
                                              setMaxDiscount([
                                                ...arr,
                                                "limit_discount_used",
                                              ]);
                                            }
                                          }}
                                        />
                                        <Label check>
                                          Limit number of times this discount
                                          can be used in total
                                        </Label>
                                      </FormGroup>
                                      {maxDiscount.includes(
                                        "limit_discount_used"
                                      ) && (
                                        <CardText
                                          style={{
                                            display: "flex",
                                            gap: 8,
                                            alignItems: "center",
                                            maxWidth: 150,
                                          }}
                                        >
                                          <InputGroup>
                                          <Input
                                            onChange={(e)=>setDiscountUse(e.target.value)}
                                            value={discountUse}
                                          />
                                          </InputGroup>
                                        </CardText>
                                      )}
                                      <FormGroup check>
                                        <Input
                                          name="maxDiscount"
                                          type="checkbox"
                                          checked={maxDiscount.includes(
                                            "discount_one"
                                          )}
                                          onChange={() => {
                                            let arr = [...maxDiscount];
                                            if (arr.includes("discount_one")) {
                                              let index = arr.findIndex(
                                                (elm) => elm === "discount_one"
                                              );
                                              arr.splice(index, 1);
                                              setMaxDiscount(arr);
                                            } else {
                                              setMaxDiscount([
                                                ...arr,
                                                "discount_one",
                                              ]);
                                            }
                                          }}
                                        />
                                        <Label check>
                                          Limit to one use per customer
                                        </Label>
                                      </FormGroup>
                                    </div>
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                          
                            <Col lg="6">
                              <FormGroup>
                                <Card
                                  className="my-2"
                                  color="primary"
                                  outline
                                  style={{
                                    width: "650px",
                                  }}
                                >
                                  <CardHeader>
                                    <CardTitle tag="h4">Active dates</CardTitle>
                                    <Row>
                                      <Col>
                                        <FormGroup>
                                          <Label for="exampleDate">
                                            Start date
                                          </Label>
                                          <Input
                                            id="exampleDate"
                                            name="date"
                                            placeholder="date placeholder"
                                            type="date"
                                            onChange={(e)=>{
                                              setIsStartDate(e.target.value) 
                                              setFieldValue("isStartDate",e.target.value)
                                            }}
                                            value={isStartDate}
                                          />
                                          <p className="ErrorMessage">
                                          {errors.isStartDate && touched.isStartDate
                                          ? errors.isStartDate
                                          : null}
                                      </p>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label for="exampleTime">
                                            Start time (EDT)
                                          </Label>
                                          <Input
                                            id="exampleTime"
                                            name="time"
                                            placeholder="time placeholder"
                                            type="time"
                                            onChange={(e)=>{
                                              setStartTime(e.target.value)
                                              setFieldValue("startTime",e.target.value)
                                            }}
                                            value={startTime}
                                          />
                                          <p className="ErrorMessage">
                                          {errors.startTime && touched.startTime
                                          ? errors.startTime
                                          : null}
                                      </p>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <FormGroup check>
                                      <Input
                                        name="combineDiscount"
                                        type="checkbox"
                                        checked={isEndDate}
                                        onChange={(e) =>
                                          setIsEndDate(e.target.checked)
                                        }
                                      />
                                      <Label check>Set end date</Label>
                                    </FormGroup>
                                    {isEndDate && (
                                      <Row style={{ marginTop: 20 }}>
                                        <Col>
                                          <FormGroup>
                                            <Label for="exampleDate">
                                              End date
                                            </Label>
                                            <Input
                                              id="exampleDate"
                                              name="date"
                                              placeholder="date placeholder"
                                              type="date"
                                              onChange={(e) =>setIsEndDate(e.target.value)}
                                              value={isEndDate}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col>
                                          <FormGroup>
                                            <Label for="exampleTime">
                                              End time (EDT)
                                            </Label>
                                            <Input
                                              id="exampleTime"
                                              name="time"
                                              placeholder="time placeholder"
                                              type="time"
                                              onChange={(e)=>setIsEndTime(e.target.value)} 
                                              value={isEndTime}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    )}
                                  </CardHeader>
                                </Card>
                              </FormGroup>
                            </Col>
                          </Col>
                          <div>
                            <Col lg="9">
                              <FormGroup>
                                <Card className="my-2" color="primary" outline style={{ width: "650px"}}>
                                  <CardHeader>
                                    <CardTitle tag="h4">Summary</CardTitle>
                                    <CardText tag="p">
                                      {discountCode ? `Discount code: ${discountCode}` : "No discount code yet."}
                                    </CardText>
                                    <CardTitle tag="h5">
                                      Type and Method
                                    </CardTitle>
                                    <CardText>
                                      <ul style={{ paddingLeft: 20 }}>
                                        <li>Amount off order</li>
                                        <li>Code</li>
                                      </ul>
                                    </CardText>
                                    <CardTitle tag="h5">Details</CardTitle>
                                    <CardText>
                                      <ul style={{ paddingLeft: 20 }}>
                                      {valueType === "percentage" && discountValue ? (
                                        <li>{discountValue}% off entire order</li>
                                      ) : null}
                                      {valueType === "fixed_amount" && discountValue ? (
                                        <li>${discountValue} off entire order</li>
                                      ) : null}
                                       {mpr === "no_minimum" ? (
                                          <li>No minimum requirements</li>
                                        ) : null}
                                       {mpr === "min_amount" && minPurchaseAmount ? (
                                          <li>Minimum purchase of ${minPurchaseAmount}</li>
                                        ) : null}
                                       {mpr === "min_quantity" && minPurchaseQuantity ? (
                                          <li>Minimum purchase of {minPurchaseQuantity} items</li>
                                        ) : null}
                                       {customerEligibility === "all" ? (
                                          <li>All customers</li>
                                        ) : null}
                                       {maxDiscount.includes("limit_discount_used") && discountUse ? (
                                        <li>
                                            Limit of {discountUse} uses
                                            {maxDiscount.includes("discount_one") ? ", one per customer" : null}
                                        </li>
                                        ) : (maxDiscount.includes("discount_one") ? (
                                            <li>One use per customer</li>
                                        ) : null)}
                                        <li>
                                        Active from  {moment(isStartDate).format("MM/DD/YYYY")}
                                        </li>
                                      </ul>
                                    </CardText>
                                  </CardHeader>
                                  <CardBody>
                                    <CardTitle tag="h4">Performance</CardTitle>
                                    <CardText tag="p">
                                      Discount is not active yet.
                                    </CardText>
                                  </CardBody>
                                </Card>
                              </FormGroup>
                            </Col>
                          
                          </div>
                        </Col>
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            // onClick={createDiscount}
                          >
                            Create Discount Coupon
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreateOrderDiscount;
