import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Col,
  Table,
  Container,
  Row,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import styles from "../../styles/shopifyProductsList.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ManuallyUpdatedData = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  console.log("locationitem", location);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [page, setPage] = useState(1);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const { item } = location?.state;
  const [itemData, setItemData] = useState(item);
  const [allFileData, setAllFileData] = useState([]);

  console.log("itemData", itemData);
  console.log("allitemdata", item);

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  let id = itemData?.id;

  let status = location?.state?.selectedKey;

  const getPosFileData = () => {
    setIsLoading(true);

    let getData = `${ApiEndpoints.posImportedViewByStatus}/${id}?status=${status}`;

    fetch(getData)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("responsFile", res);
        setAllFileData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getPosFileData();
  }, []);

  console.log("allFileData", allFileData);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="5">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                    >
                      {"File Data : "} {status}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>

              <div style={{ height: 680, overflowY: "scroll" }}>
                <Table
                  className="table"
                  bordered
                  hover
                  responsive
                  style={{ border: "1px solid #e9ecef" }}
                >
                  <thead>
                    <tr>
                      <th>UPC</th>
                      <th>Product Name</th>
                      <th>Selected Product Name</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allFileData.map((item, key) => (
                      <tr key={key} id={key}>
                        <td>{item?.upc?.length > 0 ? item?.upc : "-"}</td>
                        <td>
                          {item?.product_name?.length > 0
                            ? item?.product_name
                            : "-"}
                        </td>
                        <td>
                          {item?.master_matrixify_title?.length > 0
                            ? item?.master_matrixify_title
                            : "-"}
                        </td>
                        <td>
                          {item?.quantity?.length > 0 ? item?.quantity : "-"}
                        </td>
                        <td>{item?.price?.length > 0 ? item?.price : "-"}</td>
                        <td>{item?.status?.length > 0 ? item?.status : "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <CardFooter></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ManuallyUpdatedData;
