import React, { useMemo, useEffect, useState } from 'react';
import Header from "../../ui/Header.js";
import { ClipLoader } from 'react-spinners';
import { useHistory } from "react-router-dom";
import {
    Col,
    Card,
    CardHeader,
    Container,
    CardBody,
    Button,
    Row,
    ButtonGroup,
    Input,
    Form,
    Label,
    FormGroup,
} from "reactstrap";

import IMGetLocation from "../ui/IMGetLocation.js";

function ControlsList() {
    const [isLoading, setIsLoading] = useState(false);
    const [transferLocation, setTransferLocation] = useState(false);
    const userLocation = localStorage.getItem('location');
    const history = useHistory();

    const handleTransfer = () => {
        if (transferLocation) {
            setTransferLocation(false);
        } else {
            setTransferLocation(true);
        }
    }

    if (isLoading) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                                            {"Quick Scan"}
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} sm={3} lg="12" className="text-center">

                                        <Button
                                            className="buttons-copy buttons-html5"
                                            color="primary"
                                            id="copy-tooltip"
                                            style={{ marginTop: "0px" }}
                                        >
                                            <span><i class="fas fa-plus"></i> Increment</span>
                                        </Button>
                                        <Button
                                            className="buttons-copy buttons-html5"
                                            color="primary"
                                            id="copy-tooltip"
                                            style={{ marginTop: "0px" }}

                                        >
                                            <span><i class="fas fa-minus"></i> Decrement</span>
                                        </Button>
                                        <Button
                                            className="buttons-copy buttons-html5"
                                            color="primary"
                                            id="copy-tooltip"
                                            style={{ marginTop: "0px" }}

                                        >
                                            <span><i class="fas fa-eye"></i> Read Only</span>
                                        </Button>
                                        <Button
                                            className="buttons-copy buttons-html5"
                                            color="primary"
                                            id="copy-tooltip"
                                            style={{ marginTop: "0px" }}

                                        >
                                            <span><i class="fas fa-edit"></i> Set Quantity</span>
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => handleTransfer()}
                                            style={{ marginTop: "0px" }}
                                        >
                                            <span><i class="fas fa-random"></i> Transfer</span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "12px" }}>
                                    <Col xs={12} sm={12} lg="4">
                                        {transferLocation &&
                                            <div className="row">
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="example-text-input"
                                                    md="6"
                                                />
                                                <Col md="6" className="pull-right">
                                                    {userLocation == null ?
                                                        <Input id="exampleFormControlSelect3" type="select">
                                                            <option value="">From Location</option>
                                                            <IMGetLocation />
                                                        </Input>
                                                        : null
                                                    }
                                                </Col>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} sm={12} lg="4">

                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" style={{ height: "fit-content", backgroundColor: "#eee", color: "#000" }}>Quantity</span>
                                            </div>
                                            <Input type="number" defaultValue="1" />
                                            <div class="input-group-append">
                                                <ButtonGroup aria-label="Basic example" role="group">
                                                    <Button
                                                        className="btn-icon-only"
                                                        color="primary"
                                                        style={{ marginTop: "0px", height: "44px", borderRadius: "0px" }}
                                                    >
                                                        <span className="btn-inner--icon"><i class="fas fa-minus"></i></span>
                                                    </Button>
                                                    <Button
                                                        className="btn-icon-only"
                                                        color="primary"
                                                        style={{ marginTop: "0px", height: "44px", borderRadius: "0px" }}
                                                    >
                                                        <span className="btn-inner--icon"><i class="fas fa-plus"></i></span>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} lg="4">
                                        {transferLocation &&
                                            <div className="row">
                                                <Col md="6" className="pull-left">
                                                    {userLocation == null ?
                                                        <Input id="exampleFormControlSelect3" type="select">
                                                            <option value="">To Location</option>
                                                            <IMGetLocation />
                                                        </Input>
                                                        : null
                                                    }
                                                </Col>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="example-text-input"
                                                    md="6"
                                                />
                                            </div>
                                        }

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} lg="2"></Col>
                                    <Col xs={12} sm={12} lg="8">
                                        <div className="input-group mb-3">
                                            <select style={{ border: "1px solid #dee2e6", backgroundColor: "#eee", padding: "10px", height: "fit-content", paddingBottom: "12px" }}>
                                                <option value="BarCode">Barcode</option>
                                                <option value="SKU">SKU</option>
                                            </select>
                                            <Input type="text" placeholder="Scan Here" />
                                            <div class="input-group-append">
                                                <ButtonGroup aria-label="Basic example" role="group">
                                                    <Button
                                                        color="success"
                                                        style={{ marginTop: "0px", borderRadius: "0px", paddingBottom: "13px" }}
                                                    >
                                                        <span >Search</span>
                                                    </Button>

                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} lg="2"></Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>

    )
}

export default ControlsList;
