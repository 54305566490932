// VerticalLinearStepper.js
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
//import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import NotificationDetail from "./notificationComponent";
import NotificationTarget from "./targetComponent";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import AdditionalOptionsComponent from "./additionalOptionsComponent";
import NotificationScheduling from "./schedulingComponent";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { BsFileText } from "react-icons/bs";
import { FiTarget } from "react-icons/fi";
import { IoIosCalendar } from "react-icons/io";
import styles from "./firebaseNotification.module.css";
import "./style.css";
import { toast } from "react-toastify";
import { ApiUrls } from "../../../common/apiconstant";
import axios from "axios";
import FullPageLoader from "../../../components/FullPageLoader";
import Loader from "../../../onboarding/components/Loader";
import moment from "moment";
import { ApiEndpoints } from "../../constants/constant";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Notification", "Target", "Scheduling", "Additional Options"];
}

function getStepContent(
  step,
  values,
  setFieldValue,
  errors,
  touched,
  handleNext,
  setNotificationData,
  customData,
  setScheduling,
  handleChangeImage
) {
  switch (step) {
    case 0:
      return (
        <NotificationDetail
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setNotificationData={setNotificationData}
          handleNext={handleNext}
          handleChangeImage={handleChangeImage}
        />
      );
    case 1:
      return (
        <NotificationTarget
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setNotificationData={setNotificationData}
          handleNext={handleNext}
        />
      );
    case 2:
      return (
        <NotificationScheduling
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setNotificationData={setNotificationData}
          handleNext={handleNext}
          setScheduling={setScheduling}
        />
      );
    case 3:
      return (
        <AdditionalOptionsComponent
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setNotificationData={setNotificationData}
          handleNext={handleNext}
          customData={customData}
        />
      );
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [notificationData, setNotificationData] = React.useState({});
  const [customData, setCustomData] = useState([{ key: "", value: "" }]);
  const [open, setOpen] = useState(false);
  const [scheduling, setScheduling] = useState({});
  const [avatarKey, setAvatarKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const steps = getSteps();
  const history = useHistory();

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStepClick = (index) => {
    // validationSchema
    //   .validate(initialValues, { abortEarly: false })
    //   .then(() => setActiveStep(index))
    //   .catch((errors) => console.log(errors));
    setActiveStep(index);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  let initialValues = {
    title: "",
    message: "",
    img: "",
    name: "",
    topic: "",
    users: [],
    schedule: {
      selection: "now",
      date: "",
      time: "",
      timezone: "America/Los_Angeles",
    },
    customData: customData,
  };

  const validationSchema = Yup.object({
    message: Yup.string().required("Required"),
    // schedule: Yup.object().when("schedule.selection", {
    //   is: "scheduled",
    //   then: Yup.object().shape({
    //     date: Yup.string().required("Date is required"),
    //     time: Yup.string().required("Time is required"),
    //     timezone: Yup.string().required("Timezone is required"),
    //   }),
    // }),
    schedule: Yup.lazy((value) => {
      if (value && value.selection === "scheduled") {
        return Yup.object().shape({
          date: Yup.string().required("Required"),
          time: Yup.string().required("Required"),
          timezone: Yup.string().required("Required"),
        });
      }
      return Yup.object();
    }),
  });

  // const validationSchema = Yup.object({
  //   title: Yup.string().test("is-non-empty", "Title is required", (value) => {
  //     return typeof value === "string" ? value.trim() !== "" : true;
  //   }),
  //   // other fields...
  // });

  // const validationSchema = Yup.object({
  //   message: Yup.string().nullable().trim().required("Required"),
  //   // other fields...
  // });

  // const validationSchema = Yup.object().shape({
  //   title: Yup.string().when(["topic"], {
  //     is: (topic) => !topic,
  //     then: Yup.string().required("Title is required"),
  //   }),
  //   name: Yup.string().when(["title", "topic"], {
  //     is: (title, topic) => !title && !topic,
  //     then: Yup.string().required("Name is required"),
  //   }),
  //   topic: Yup.string().when(["title"], {
  //     is: (title) => title,
  //     then: Yup.string().required("Topic is required"),
  //   }),

  // });

  const handleChangeImage = async (e, fieldName) => {
    let toastId = toast.info("Image uploading is in processing", {
      autoClose: false,
    });
    //var newData = { ...modifiedNonFormData };
    //setButtonDisabled(true);
    if (e.target.files.length) {
      let selectedFile = e.target.files[0];
      let fileType = e.target.files[0].type;
      // setImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0],
      // });

      let preSingnedUrl = ApiUrls.avatarUploadPresigned;

      try {
        let preSignedRes = await axios.get(
          `${preSingnedUrl}?contentType=${fileType}`
        );
        console.log("D", preSignedRes);
        if (preSignedRes.status === 200) {
          let url = preSignedRes?.data?.data?.url;
          let ukey = preSignedRes?.data?.data?.key;
          let res = await uploadFile(url, selectedFile);
          console.log("RES1", res);
          console.log("ULR", url, ukey);
          if (res.status === 200) {
            // setButtonDisabled(false);
            setAvatarKey(ukey);
            // newData[fieldName] = ukey;
            // setModifiedNonFormData(newData);
            // console.log("MODI", modifiedNonFormData);
            // console.log("DATa", newData);
            toast.dismiss(toastId);
            toast.success("Image upload successfully");
          }
        }
      } catch (error) {
        //setButtonDisabled(false);
        console.error(error);
      }
    } else {
      // setButtonDisabled(false);
      // newData[fieldName] = false;
      // setModifiedNonFormData(newData);
    }
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  const handleSubmit = async (values) => {
    console.log("atlast", values);
    if (steps.length - 1 > activeStep) {
      try {
        console.log("Formik Values", values);
        console.log("1");

        await validationSchema.validate(values, { abortEarly: false });
        console.log("2");
        setActiveStep((p) => p + 1);
        console.log("3");
        //setIsLoading(false);
      } catch (errors) {
        console.log("ERR", errors);
        // setIsLoading(false);
      }
      console.log("atlast ret", values);
      return;
    }
    if (values.schedule.selection == "scheduled") {
      let isoTime = new Date(
        values.schedule.date + " " + values.schedule.time
      )?.toISOString();

      console.log("Iso time", isoTime);
    }

    // let url =
    //   "https://us-central1-liquorsplit-89663.cloudfunctions.net/fcmNotification";
    let url = ApiEndpoints.createNotification;
    // {
    //   "title":"sdasd",
    //   "text":"",
    //   "image":"",
    //   "target_type":"users",

    //   "target_segment":"Individual",
    //   "target_users":["dsfsf","fsdfsdf"],
    //   "scheduling_type":"Now"/"Schedule",
    //   "schedule_time":"2024-01-01T18:56:14Z",
    //   'additional_options':{
    //       "sdasd":"asfasdf"
    //   }
    //       }

    setIsLoading(true);
    let reqBody = {};
    reqBody.text = values.message;
    if (values.title) {
      reqBody.title = values.title;
    }
    if (values.name) {
      reqBody.name = values.name;
    }
    if (avatarKey) {
      reqBody.image = avatarKey;
    }
    //reqBody.target = {};
    if (values.topic) {
      reqBody.target_type = "topic";
      reqBody.target_segment = values.topic;
    } else {
      if (values.users == "all") {
        reqBody.target_type = "users";
        reqBody.target_segment = "All";
        reqBody.target_users = [];
      } else {
        let tempSelectedUsers = [...values.users];
        let selectedUsers = tempSelectedUsers.map((user) => user?.shopify_id);

        reqBody.target_type = "users";
        reqBody.target_segment = "Individual";
        reqBody.target_users = selectedUsers;
      }
    }

    if (values.schedule.selection == "now") {
      reqBody.scheduling_type = "Now";
    } else {
      // reqBody.schedule = {
      //   time: values?.schedule?.time,
      //   zone: values?.schedule?.zone,
      // };
      let isoTime = new Date(
        values.schedule.date + " " + values.schedule.time
      )?.toISOString();
      reqBody.scheduling_type = "Schedule";
      reqBody.schedule_time = isoTime;
      reqBody.time_zone = values?.schedule?.timezone;
      reqBody.date = values?.schedule?.date;
      reqBody.time = values?.schedule?.time;
    }

    let newData = values?.customData?.filter((item) => item.value !== "");
    console.log("Request body data", newData, customData);
    if (newData?.length > 0) {
      let transformedData = Object.fromEntries(
        newData.map((item) => [item.key, item.value])
      );
      console.log("Transformed", transformedData);
      reqBody.additional_options = {
        ...transformedData,
      };
    }

    reqBody.created_by = localStorage.getItem("fullname");

    console.log("Request body", reqBody);

    try {
      const response = await axios.post(url, reqBody);
      console.log("REsponse", response);
      if (response.status == 200) {
        // let obj = {
        //   campaign: values.title,
        //   start: "2023-03-15T10:00:00",
        //   end: "2023-03-31T23:59:59",
        //   status: "Sent",
        //   target: "All",
        //   last_updated: new Date().toISOString(),
        //   message: values.message,
        //   data: {
        //     type: "SingleItemDetail",
        //     handle: "kim-crawford-sauvignon-blanc-2018",
        //     title: "Kim Crawford Sauvignon Blanc",
        //     image:
        //       "https://cdn.shopify.com/s/files/1/0636/3853/6430/files/kim-crawford-sauvignon-blanc_600x600_bfc49056-8293-40a9-9e33-2292156623c2.jpg?v=1702532147",
        //   },
        // };
        // let tempFcm = localStorage.getItem("fcm_data");
        // if (tempFcm) {
        //   let fcmD = JSON.parse(tempFcm);
        //   fcmD.unshift(obj);
        //   localStorage.setItem("fcm_data", JSON.stringify(fcmD));
        // }
        console.log("valuesff", values);
        if (values.schedule.selection == "scheduled") {
          toast.success("Notification scheduled successfully");
        } else {
          toast.success("Notification sent successfully");
        }
        history.push("/admin/notifications");
        setIsLoading(false);
      } else {
        toast.error("Something went wrong");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fcm error", error);
      toast.error("Something went wrong");
      setIsLoading(false);
    }

    return;
    // temp
    if (values.message) {
      reqBody.message = values.message;
    }

    if (values.title) {
      reqBody.title = values.title;
    }
    if (values.name) {
      reqBody.name = values.name;
    }
    // let tk =
    //   "eMujS6ZxTtOphFjWouHgBY:APA91bHvewff41bVuWNfzoyyB4wBHVx3blU43J2GO6cmfki-VTwWpvEzuyYNH-4z0ojXhiurfaE4fNpEeokSb0ccd009GqAvGdfqjvfR8hcbfO9rNt_tGlr_ABnIBPiFFRbMZbfx6zAL"; // shilpi
    //(upama) ="fQzvvjrCSx6zm4epRVanXZ:APA91bEbL-eA6SNJSXIs9vT9nY-6xGsXREWpx-8dO0-fdQTeb6aj1s75qYSFCLBLPzSeT6ixVz-k_ha54HOjiQm6PEb-lrvYCUNr_CtTgs-tF7lOpfnT4VBdvekU4OvjoQZHvWO6wH_k";

    let tk =
      "dzyUWv56TVW49UCcBKqyk3:APA91bEwc8DoGVaDm_tE2jl5aLls0em4vOT2zj-7XNbuBYhd9jE95av6d5ghzjeYoIUHY3mBTloP2KmWOypFfH9ow_g1kKg3YT-Z1097Gc4e8xx8jTr0UFl61aAISGRfFDskWP8xmXnr";
    reqBody.token = tk;

    // let newData = values?.customData?.filter((item) => item.value !== "");
    // console.log("Request body data", newData, customData);
    // if (newData?.length > 0) {
    //   let transformedData = Object.fromEntries(
    //     newData.map((item) => [item.key, item.value])
    //   );
    //   console.log("Transformed", transformedData);
    //   reqBody.data = {
    //     ...transformedData,
    //   };
    // }
    console.log("Request body", reqBody);
    try {
      const response = await axios.post(url, reqBody);
      console.log("REsponse", response);
      if (response.status == 200) {
        let obj = {
          campaign: values.title,
          start: "2023-03-15T10:00:00",
          end: "2023-03-31T23:59:59",
          status: "Sent",
          target: "All",
          last_updated: new Date().toISOString(),
          message: values.message,
          data: {
            type: "SingleItemDetail",
            handle: "kim-crawford-sauvignon-blanc-2018",
            title: "Kim Crawford Sauvignon Blanc",
            image:
              "https://cdn.shopify.com/s/files/1/0636/3853/6430/files/kim-crawford-sauvignon-blanc_600x600_bfc49056-8293-40a9-9e33-2292156623c2.jpg?v=1702532147",
          },
        };
        let tempFcm = localStorage.getItem("fcm_data");
        if (tempFcm) {
          let fcmD = JSON.parse(tempFcm);
          fcmD.unshift(obj);
          localStorage.setItem("fcm_data", JSON.stringify(fcmD));
        }
        history.push("/admin/notifications");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fcm error", error);
      setIsLoading(false);
    }
  };

  const nextStep = async (formik) => {
    try {
      console.log("Formik Values", formik.values);
      console.log("1");
      // await validationSchema.validate(formik.values, { abortEarly: false });
      // console.log("2");
      setActiveStep((p) => p + 1);
      console.log("3");
    } catch (errors) {
      console.log("ERR", errors);
    }
  };

  console.log("Notification Data", notificationData);
  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Create Notification"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formikProps) => (
                  <Form>
                    {console.log("errors", formikProps.errors)}
                    <CardBody>
                      <div className={styles.cardSize}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {steps.map((label, index) => (
                            <Step key={label}>
                              <StepLabel onClick={() => handleStepClick(index)}>
                                {label}
                              </StepLabel>
                              <StepContent>
                                <Typography>
                                  {getStepContent(
                                    index,
                                    formikProps.values,
                                    formikProps.setFieldValue,
                                    formikProps.errors,
                                    formikProps.touched,
                                    "",
                                    setNotificationData,
                                    customData,
                                    setScheduling,
                                    handleChangeImage
                                  )}
                                </Typography>
                                <div>
                                  <div>
                                    {/* <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                              >
                                Back
                              </Button> */}
                                    {activeStep !== steps.length - 1 && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        // onClick={() =>
                                        //   //handleStepClick(activeStep + 1)
                                        //   nextStep(formikProps)
                                        // }
                                        disabled={
                                          Object.keys(formikProps.errors)
                                            ?.length ||
                                          formikProps.values.message.length == 0
                                        }
                                        type="submit"
                                      >
                                        Next
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                        <Col lg="8" className={styles.devicePreview}>
                          <FormGroup>
                            <>
                              <CardTitle tag="h4">Device Preview</CardTitle>
                              <CardText tag="h5">
                                This preview provides a general idea of how your
                                message will appear on a mobile device. Actual
                                message rendering will vary depending on the
                                device. Test with a real device for actual
                                results.
                              </CardText>
                              <hr />
                              {/* <Button
                          size="sm"
                          color="primary"
                          type="button"
                          onClick={() => setTestModal(true)}
                        >
                          Send Test Message
                        </Button> */}
                              {/* <ButtonGroup>
                                  <Button
                                    size="sm"
                                    // color={
                                    //   selectedTab === "InitialState"
                                    //     ? "primary"
                                    //     : "secondary"
                                    // }
                                    // onClick={() => handleTabChange("InitialState")}
                                  >
                                    Initial State
                                  </Button>
                                  <Button
                                    size="sm"
                                    // color={
                                    //   selectedTab === "ExpandView"
                                    //     ? "primary"
                                    //     : "secondary"
                                    // }
                                    // onClick={() => handleTabChange("ExpandView")}
                                  >
                                    Expand View
                                  </Button>
                                </ButtonGroup> */}

                              <div className="android-container">
                                <div className="phone">
                                  <div className="android-speaker" />
                                </div>
                                <div className="phone">
                                  <div className="android-camera" />
                                </div>
                                <div className="android-screen-initial">
                                  <>
                                    <div className="notification">
                                      <h5 className="notificationTitle">
                                        {formikProps?.values?.title?.length > 0
                                          ? formikProps?.values?.title
                                          : "Notification Title"}
                                      </h5>
                                      <h5 className="notificationText">
                                        {formikProps?.values?.message?.length >
                                        0
                                          ? formikProps?.values?.message
                                          : "Notification Text"}
                                      </h5>
                                    </div>
                                  </>
                                </div>
                              </div>
                              <br />
                              <div className="phone-name">Android</div>
                              <br />
                              <div className="apple-container">
                                <div className="phone">
                                  <div className="apple-notch" />
                                </div>
                                <div className="apple-design">
                                  <div className="apple-speaker" />
                                  <div className="apple-camera" />
                                </div>
                                <div className="apple-screen-initial">
                                  <>
                                    <div className="notification">
                                      <h5 className="notificationTitle">
                                        {formikProps?.values?.title?.length > 0
                                          ? formikProps?.values?.title
                                          : "Notification Title"}
                                      </h5>
                                      <h5 className="notificationText">
                                        {formikProps?.values?.message?.length >
                                        0
                                          ? formikProps?.values?.message
                                          : "Notification Text"}
                                      </h5>
                                    </div>
                                  </>
                                </div>
                              </div>
                              <br />
                              <div className="phone-name">Apple</div>
                              <br />
                            </>
                          </FormGroup>
                        </Col>
                        {activeStep === steps.length && (
                          <Paper square elevation={0}>
                            <Typography>
                              All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={handleReset}>Reset</Button>
                          </Paper>
                        )}
                      </div>
                    </CardBody>
                    <CardFooter
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <ButtonGroup className={styles.flexGap}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            setOpen(true);
                          }}
                          disabled={
                            Object.keys(formikProps.errors)?.length ||
                            formikProps.values.message.length == 0
                          }
                        >
                          Review
                        </Button>
                      </ButtonGroup>
                    </CardFooter>
                    <SubmitModal
                      open={open}
                      setOpen={setOpen}
                      data={formikProps.values}
                      formikProps={formikProps}
                    />
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </Row>
      </Container>
      {loader ? <Loader /> : null}
    </>
  );
}

const SubmitModal = ({ open, setOpen, data, formikProps }) => {
  //console.log("modal data", data, formikProps);
  const handleFormSubmit = () => {
    //setOpen(false);
    toggle();
    document.body.classList.remove("modal-open");
    formikProps.submitForm();
  };
  const toggle = () => {
    //Modal.openCount = 0;
    setOpen((p) => !p);
  };
  return (
    <Modal
      isOpen={open}
      //toggle={toggle}
      centered
      // style={{ padding: 0 }}
      backdrop="static"
      size="lg"
      //onClosed={() => setOpen(false)}
      //fade={false}
    >
      <ModalHeader>
        <Row>
          <Col>
            <h2 className={styles.mb}>Review message</h2>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Row className={styles.gap}>
              <Col>
                <h4 className={styles.mb}>Notification content</h4>
              </Col>
            </Row>
            <Row className={styles.alignCenter}>
              <Col lg="auto pr-0">
                <div className="circle">
                  <BsFileText size={"1.5em"} />
                </div>
              </Col>
              <Col>
                <h5>{data.message}</h5>
              </Col>
            </Row>
          </Col>
        </Row>

        <hr style={{ margin: "1rem" }} />

        <Row>
          <Col>
            <Row className={styles.gap}>
              <Col>
                <h4 className={styles.mb}>Target</h4>
              </Col>
            </Row>
            <Row className={styles.alignCenter}>
              <Col lg="auto pr-0">
                <div className="circle">
                  <FiTarget size={"1.5em"} />
                </div>
              </Col>
              <Col>
                <h5 style={{ marginBottom: "0" }}>
                  User segment matching one targeting criterion
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "1rem" }} />

        <Row>
          <Col>
            <Row className={styles.gap}>
              <Col>
                <h4 className={styles.mb}>Scheduling</h4>
              </Col>
            </Row>
            <Row className={styles.alignCenter}>
              <Col lg="auto pr-0">
                <div className="circle">
                  <IoIosCalendar size={"1.5em"} />
                </div>
              </Col>
              <Col>
                <h5 className={styles.mb}>
                  {formikProps.values.schedule.selection == "now"
                    ? "Send now"
                    : moment(
                        formikProps.values.schedule.date +
                          " " +
                          formikProps.values.schedule.time
                      ).format("lll")}
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr style={{ margin: "1rem" }} />
      </ModalBody>

      <ModalFooter>
        <Button size="sm" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          size="sm"
          color="primary"
          // className="btn btn-primary"
          //color="primary"
          type="submit"
          onClick={handleFormSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
