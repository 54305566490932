import React, { useEffect, useState } from "react";
// react library for routing
import {
  useLocation,
  NavLink as NavLinkRRD,
  Link,
  useParams,
} from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Input,
} from "reactstrap";
import Loader from "../../onboarding/components/Loader";
import Select from "react-select";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
// import { use } from "passport";

function Sidebar({
  toggleSidenav,
  sidenavOpen,
  routes,
  logo,
  rtlActive,
  userDetail,
}) {
  if (userDetail.user_role !== "" && userDetail.user_role !== undefined) {
    var role = userDetail.user_role;
  } else {
    var role = userDetail.role;
  }

  const [state, setState] = React.useState({});
  const [selectedLocation, setSelectedLocation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const params = useParams();
  console.log("PARAM", params);

  useEffect(() => {
    let location = localStorage.getItem("selected_location_id");
    if (location) {
      setSelectedLocation(location);
    }
  }, []);

  React.useEffect(() => {
    // let route = JSON.parse(JSON.stringify(routes));
    // console.log("ROUTE", route, routes);
    // route[1].views.push({
    //   path: "/locations",
    //   name: "Locations 1",
    //   miniName: "P",
    //   layout: "/admin",
    //   hasPermission: "locations",
    // });
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, []);

  const firebaseLoginDetail = async () => {
    let firebaseLogin = await signInWithEmailAndPassword(
      auth,
      "admin@liquorsplit.com",
      "Liquor@#1234"
    );
    console.log("firebaseLogin231541654165", firebaseLogin);
  };

  useEffect(() => {
    firebaseLoginDetail();
  }, []);

  const checkUserRole = (role) => {
    let menu = ["dashboard", "logout"];
    let d = localStorage.getItem("role_actions");
    let e = localStorage.getItem("user_detail");
    if (e) {
      console.log(e);
      e = JSON.parse(e);
    }
    console.log("userdetail====>123", e);
    let data = JSON.parse(d);
    console.log("SD", data);

    if (e?.roles?.includes("1")) {
      menu = [
        "dashboard",
        "logout",
        "locations",
        "dispatch",
        "orders",
        "inventory",
        "controls",
        "transaction",
        "quick-scan",
        "disputes",
        "roles",
        "drivers",
        "messages",
        "collections",
        "collection_list",
        "collectionaccordian",
        "manage",
        "tenants",
        "app_channels",
        "app_channel",
        "sub_category",
        "search",
        "disputes",
        "navigation_config",
        "navigation_mngt",
        "users",
        "archived_users",
        "role_permission",
        "archived_tenants",
        "discounts",
        "list_orders",
        "products",
        "product_transaction",
        "menu",
        "menu_import",
        "notifications",
        "blacklist",
        "pos_inventory",
        "pos_inventory_file",
        "pos_inventory_mapping",
        "reports",
        "master_products",
        "manage_sku_reports",
      ];
    } else {
      if (data?.includes("order_read")) {
        menu.push("orders");
        menu.push("dispatch");
      }

      if (data?.includes("inventory_manage")) {
        menu.push("inventory");
        menu.push("controls");
      }
      if (data?.includes("inventory_search")) {
        menu.push("inventory");
        menu.push("search");
      }

      if (data?.includes("inventory_transaction")) {
        menu.push("inventory");
        menu.push("transaction");
      }

      if (data?.includes("message_read")) {
        menu.push("messages");
      }

      if (data?.includes("user_read")) {
        menu.push("users");
      }

      if (data?.includes("archived_users_read")) {
        menu.push("archived_users");
        menu.push("users");
      }

      if (data?.includes("role_read")) {
        menu.push("roles");
        menu.push("users");
      }

      if (data?.includes("location_read")) {
        menu.push("locations");
      }

      if (data?.includes("dispute_read")) {
        menu.push("disputes");
      }
    }
    return menu;
    // switch (role) {
    //   case "Admin":
    //     return [
    //       "dashboard",
    //       "dispatch",
    //       "orders",
    //       "inventory",
    //       "controls",
    //       "transaction",
    //       "quick-scan",
    //       "locations",
    //       "disputes",
    //       "roles",
    //       "rolepermission",
    //       "admin",
    //       "employees",
    //       "drivers",
    //       "Messages",
    //       "logout",
    //     ];
    //     break;

    //   case "Location admin":
    //     return [
    //       "dashboard",
    //       "dispatch",
    //       "orders",
    //       "Messages",
    //       "inventory",
    //       "controls",
    //       "quick-scan",
    //       "transaction",
    //       "locations",
    //       "disputes",
    //       "roles",
    //       "rolepermission",
    //       "admin",
    //       "employees",
    //       "drivers",
    //       "logout",
    //     ];

    //   case "Curator":
    //     return [
    //       "dashboard",
    //       "inventory",
    //       "controls",
    //       "transaction",
    //       "Messages",
    //       "logout",
    //     ];
    //     break;

    //   case "Dispatcher":
    //     return ["dashboard", "dispatch", "orders", "Messages", "logout"];
    //     break;
    //   default:
    //     return [];
    //     break;
    // }
  };

  const getPermission = checkUserRole(role);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    console.log(location.pathname.indexOf(routeName), routeName, "ddd");
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (!getPermission.includes(prop.hasPermission)) {
        return null;
      }

      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        console.log("PROPSS", prop);
        return (
          <>
            {prop.type === "seprator" ? (
              <div></div>
            ) : (
              <NavItem key={key}>
                <NavLink
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={state[prop.state]}
                  className={classnames({
                    active: getCollapseInitialState(prop.views),
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    setState(st);
                  }}
                >
                  {prop.icon ? (
                    <>
                      <i className={prop.icon} />
                      <span className="nav-link-text">{prop.name}</span>
                    </>
                  ) : prop.miniName ? (
                    <>
                      <span className="sidenav-mini-icon">
                        {" "}
                        {/* {prop.miniName}{" "} */}
                      </span>
                      <span className="sidenav-normal"> {prop.name} </span>
                    </>
                  ) : null}
                </NavLink>
                <Collapse isOpen={state[prop.state]}>
                  {prop?.views && prop?.views?.length > 0 && (
                    <Nav className="nav-sm flex-column">
                      {createLinks(prop.views)}
                    </Nav>
                  )}
                  {prop?.locations && prop?.locations?.length > 0 && (
                    <Nav className="nav-sm flex-column">
                      {createLinksForLocation(prop.locations)}
                    </Nav>
                  )}
                </Collapse>
              </NavItem>
            )}
          </>
        );
      }
      console.log("PROPSS1", prop);
      return (
        <>
          {prop.type === "seprator" ? (
            <span
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
            ></span>
          ) : (
            <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
              <NavLink
                to={prop.layout + prop.path}
                activeClassName=""
                onClick={closeSidenav}
                tag={NavLinkRRD}
              >
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <span className="nav-link-text">{prop.name}</span>
                  </>
                ) : prop.miniName !== undefined ? (
                  <>
                    <span className="sidenav-mini-icon">
                      {/* {prop.miniName}  */}
                    </span>
                    <span className="sidenav-normal"> {prop.name} </span>
                  </>
                ) : (
                  prop.name
                )}
              </NavLink>
            </NavItem>
          )}
        </>
      );
    });
  };

  const createLinksForLocation = (routes) => {
    return routes.map((prop, key) => {
      if (!getPermission.includes(prop.hasPermission)) {
        return null;
      }

      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon">
                    {/* {prop.miniName} */}
                  </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            {/* <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse> */}
          </NavItem>
        );
      }

      return (
        <>
          <NavItem
            // onClick={() => handleClick(prop.locationId, prop.name)}
            className={activeRoute(prop.layout + prop.path)}
            key={key}
          >
            <div
              // to={prop.layout + prop.path}
              // activeClassName=""

              // tag={NavLinkRRD}
              style={{
                display: "grid",
                alignItems: "center",
                whiteSpace: "nowrap",
                gridTemplateColumns: "auto 1fr",
                padding: "0.675rem 2.5rem",
                gap: 8,
                cursor: "pointer",
              }}
              className="nav-link"
            >
              {prop.locationId !== undefined ? (
                <>
                  {/* <i className={prop.icon} /> */}
                  <label htmlFor="check" style={{ marginBottom: 0 }}>
                    <input
                      id="check"
                      type="checkbox"
                      checked={
                        prop.locationId ===
                        localStorage.getItem("selected_location_id")
                      }
                    />
                  </label>

                  <span
                    className="nav-link-text"
                    // style={{
                    //   background:
                    //     prop.locationId === selectedLocation ? "grey" : "white",
                    // }}
                  >
                    {prop.name}
                  </span>
                </>
              ) : prop.miniName !== undefined ? (
                <>
                  <span className="sidenav-mini-icon">
                    {/* {prop.miniName}  */}
                  </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : (
                prop.name
              )}
            </div>
          </NavItem>
          <Loader isLoading={isLoading} />
        </>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const handleClick = (obj) => {
    console.log("objaskjdbhasjjdk", obj);
    setIsLoading(true);
    localStorage.setItem("selected_location_id", obj.locationId);
    localStorage.setItem("selected_location_name", obj.name);
    localStorage.setItem("tenant_id", obj.tenant_id);

    window.location.reload();
  };

  console.log("SSSSSSSS", routes[0]);

  const [locationIndex, setLocationIndex] = useState(0);

  useEffect(() => {
    if (routes[0]?.locations?.length > 0) {
      let tempData = [...routes[0]?.locations];
      let selectedLocation = localStorage.getItem("selected_location_id");
      if (selectedLocation) {
        let index = tempData.findIndex(
          (elm) => elm.locationId == selectedLocation
        );
        setLocationIndex(index);
      } else {
        setLocationIndex(0);
      }
    }
  }, [routes[0]?.locations?.length]);

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          {/* <div>{localStorage.getItem("selected_location_name")}</div> */}
          <div
            style={{
              marginLeft: "-15px",
              marginRight: "-15px",
              marginBottom: 10,
            }}
          >
            {/* <Input
              type="select"
              name="locations"
              onChange={(e) => handleClick(e.target.value)}
              // onBlur={handleBlur}
              //multiple
            >
              <option>Select Location</option>
              {routes[0]?.locations?.map((location) => (
                <option
                  value={location.locationId}
                  selected={
                    localStorage.getItem("selected_location_id") ==
                    location.locationId
                      ? "selected"
                      : ""
                  }
                >
                  {location.name}
                </option>
              ))}
            </Input> */}
            {routes[0]?.locations && (
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={routes[0]?.locations[locationIndex]}
                isSearchable={true}
                name="locations"
                options={routes[0]?.locations}
                onChange={(e) => handleClick(e)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "0.875rem",
                    color: "rgba(0, 0, 0, 0.6)",
                  }),
                }}
              />
            )}
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
