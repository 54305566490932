import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrls } from "../../../common/apiconstant";

const SetLocation = () => {
  const [userData, setUserData] = useState();
  const [locationList, setLocationList] = useState([]);
  console.log("GASDFASDFASDF");

  useEffect(() => {
    let localUserData = localStorage.getItem("user_detail");

    if (localUserData) {
      setUserData(JSON.parse(localUserData));
    }
  }, []);

  const getLocationList = async () => {
    let res = await axios.get(`${ApiUrls.locationList}`);
    console.log("RESPONSE LO", res);
  };

  useEffect(() => {
    getLocationList();
  }, []);
  console.log("LOCALDATA", userData);

  return <div>SetLocation</div>;
};

export default SetLocation;
