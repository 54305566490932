
import React, { useEffect, useState } from 'react';
import { Card, Container, Row } from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader'
function HomePage() {
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() =>{
        setIsLoading(false)
    },[])
    if (isLoading) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div >
        )
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card className="border-2" style={{ padding: 20 }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12890415.881897787!2d-97.76960307667181!3d37.92869721720524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1631693912080!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                        </Card>
                    </div>
                </Row>

            </Container>

        </>

    )
}

export default HomePage;
