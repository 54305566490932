import React, { useEffect, useState } from "react";
import { ApiUrls } from "../../../common/apiconstant";

function IMGetLocation({ setLocationId }) {
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    fetch(`${ApiUrls.locationList}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        const locations = response?.data?.Items;
        setLocationData(locations);
        setLocationId(locations[0]?.pk.split("#")[1]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return locationData.map((location) => (
    <option value={location?.pk} key={location.pk}>
      {location.title}
    </option>
  ));
}

export default IMGetLocation;
