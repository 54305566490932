import React, { useMemo, useEffect, useState, useRef } from "react";
import Header from "../../ui/Header.js";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file.js";
import { useHistory } from "react-router-dom";
import {
  Col,
  Card,
  Label,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  CardFooter,
  Table,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,
  Button,
  Row,
  ButtonGroup,
  Media,
  UncontrolledTooltip,
  Input,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import IMGetLocation from "../ui/IMGetLocation.js";
import "./style.css";
import FullPageLoader from "../../../components/FullPageLoader/index.js";

import { AlexaForBusiness } from "aws-sdk";
import { ApiEndpoints } from "../../constants/constant.js";
import moment from "moment";
import { useMediaQuery } from "@material-ui/core";
import getToken from "../../../onboarding/getToken.js";
import { ExportToPriceExcel } from "../../../components/ExportExcel/priceIndex.js";
import SearchBar from "../../components/SearchBar.js";
import { toast } from "react-toastify";
import styles from "../../styles/productTransaction.module.css";
import MobileSearchBar from "../../components/SearchBarMobile.js";
const awsSDKconfig = require("../../config/awsSDKConfig.js");
const AWS = require("aws-sdk");
AWS.config.update(awsSDKconfig);
const cognito = new AWS.CognitoIdentityServiceProvider();
const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const inventoryControlsColumns = [
  {
    Header: "Title 1",
  },
  {
    Header: "Title 2",
  },
  {
    Header: "Title 3",
  },
  {
    Header: "Title 4",
  },
  {
    Header: "Title 5",
  },
  {
    Header: "Title 6",
  },
  {
    Header: "Title 7",
  },
];

const tableData = {
  headings: [
    "Uploaded At",
    "Name",
    "No of Item",
    "Processing Status",
    "Approval Status",
  ],
  body: [
    {
      "Uploaded At": "10/10/2023",
      Name: "Nitesh Jangir",
      "No of Item": 5,
      "Processing Status": "Completed",
      "Approval Status": "Pending For Approval",
    },
    {
      "Uploaded At": "11/10/2023",
      Name: "Nitesh Jangir",
      "No of Item": 8,
      "Processing Status": "Partially completed",
      "Approval Status": "Approved",
    },
    {
      "Uploaded At": "11/10/2023",
      Name: "Nitesh Jangir",
      "No of Item": 3,
      "Processing Status": "Completed",
      "Approval Status": "Pending For Approval",
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function ProductMenuTransaction() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("All");
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const modalToggle = () => setModal(!modal);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [inventoryControls, setInventoryControls] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const userLocation = localStorage.getItem("location");
  const [isImport, setIsImport] = useState(false);
  const [importStatus, setImportStatus] = useState("");
  const [isExcelData, setIsExcelData] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [statusFilter, setStatusFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedSource, setSelectedSource] = useState([]);
  const [sourceDropDown, setSourceDropDown] = useState(false);
  const toggleSourceDropDown = () =>
    setSourceDropDown((prevState) => !prevState);
  const [clear, setClear] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCheckedList, setIsCheckedList] = useState(false);

  const isMobile = useMediaQuery("(max-width: 500px)");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  //console.log("LOcation", userLocation);
  const history = useHistory();
  const [productData, setProductData] = useState([]);

  console.log("ISLOADING", isLoading);

  const SortOptions = [{ id: "created_at", text: "Date" }];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const sourceOptions = [
    { id: "Pending", text: "Pending" },
    { id: "Partially completed", text: "Partially completed" },
    { id: "Completed", text: "Completed" },
    { id: "Hold", text: "Hold" },
  ];

  const handleSourceOptions = (sourceId) => {
    setPage(1);
    if (selectedSource.includes(sourceId)) {
      setSelectedSource((prevSelected) =>
        prevSelected.filter((id) => id !== sourceId)
      );
    } else {
      setSelectedSource((prevSelected) => [...prevSelected, sourceId]);
    }
  };

  const getTransactionList = (pageNum) => {
    if (locationId) {
      let menuListTransaction = `${ApiEndpoints.productListLocationImport}?location_id=${locationId}&page=${pageNum}&limit=10`;

      if (selectedSortOption) {
        menuListTransaction =
          menuListTransaction +
          `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
      }

      if (selectedStatus !== "All") {
        // const status = selectedStatus.toLowerCase();
        menuListTransaction =
          menuListTransaction + `&approved=${selectedStatus}`;
      }

      if (selectedSource.length > 0) {
        menuListTransaction = menuListTransaction + `&status=${selectedSource}`;
      }

      if (searchKeyword.length > 0) {
        menuListTransaction =
          menuListTransaction + `&searchKeyword=${searchKeyword}`;
      }

      setIsLoading(true);
      console.log("menuListTransaction", menuListTransaction);
      fetch(menuListTransaction)
        .then((res) => res.json())
        .then((response) => {
          const menuTransaction = response.data;
          console.log("RESPONSE NEW", response);
          // setInventoryControls(menuTransaction);
          // setOriginalData(menuTransaction);
          if (menuTransaction.length > 0) {
            const updatedOrders =
              pageNum === 1
                ? menuTransaction
                : [...inventoryControls, ...menuTransaction];
            const mergedOrders = mergeAndSortOrders(updatedOrders);
            setInventoryControls(mergedOrders);
            // setOriginalData(mergedOrders);

            if (menuTransaction.length < 10) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          } else {
            console.log("page456", page);
            if (pageNum === 1) {
              setInventoryControls(menuTransaction);
              // setOriginalData(menuTransaction);

              if (menuTransaction.length < 10) {
                setLoadMoreEnabled(false);
              } else {
                setLoadMoreEnabled(true);
              }
            }
          }
          setIsLoading(false);
          setClear(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error", err);
        });
    }
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getTransactionList(pageNum);
  };

  useEffect(() => {
    setPage(1);
    if (selectedSortOption) {
      getTransactionList(page);
    }
  }, [
    selectedSortOption,
    sortDirection,
    selectedStatus,
    selectedSource,
    locationId,
    isImport === true,
  ]);

  console.log("inventoryconrrolss", inventoryControls);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getTransactionList(page);
    } else if (searchKeyword === "") {
      getTransactionList(page);
    }
  }, [searchKeyword.length]);

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const mergeAndSortOrders = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      getTransactionList(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });

      //   const status = value.toLowerCase();
      setSelectedStatus(value);
    }
  };

  let btns = [
    "All",
    "Pending For Approval",
    "Awaiting user response",
    "Approved",
    "Rejected",
  ];

  const handleClear = async () => {
    setPage(1);
    // setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    // setSelectedDeliveryMethod([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getTransactionList(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    // setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    // setSelectedDeliveryMethod([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  const handleCheckboxChange = () => {
    setIsCheckedList(!isCheckedList);
  };

  const exportData = async () => {
    if (locationId) {
      setIsLoading(true);
      fetch(`${ApiEndpoints.exportPrice}?locationId=${locationId}`)
        .then((response) => response.json())
        .then((json) => {
          console.log("json", json);
          setTimeout(() => {
            const data = [
              {
                category: "product",
                data: json.data,
              },
            ];
            setProductData(data);
            //setIsExcelData(true)
          }, 1000);
        });

      setIsLoading(false);
    }
  };

  const callReferesh = () => {
    fetch(`${ApiEndpoints.productListLocationImport}?location_id=${locationId}`)
      .then((res) => res.json())
      .then((response) => {
        console.log("RESPONSE NEW", response);
        setInventoryControls(response.data);
        setOriginalData(response.data);
        setApprovalStatus("All");
        setIsLoading(false);
      });
  };
  // console.log("Contr", inventoryControls);
  // console.log("PRODUCT DATa", productData);
  const columns = useMemo(() => inventoryControlsColumns, []);
  const handleView = () => {
    const viewPath = "/admin/import-export-show/1";
    history.push(viewPath);
  };

  const handleDropdown = (e) => {
    console.log("E", e);
    let value = e.target.value;
    let location_id = value.split("#")[1];
    setLocationId(location_id);
    // callReferesh();
    setApprovalStatus("All");
  };

  useEffect(() => {
    let location = localStorage.getItem("selected_location_id");
    console.log("location 1234", location);
    if (location) {
      let loc = location.split("#")[1];
      setLocationId(loc);
    }
  }, []);

  const fileInputRef = useRef();

  const [file, setFile] = useState("");

  const updateApprovalStatus = (text) => {
    console.log("147---->", text);
    setApprovalStatus(text);
    let data = JSON.parse(JSON.stringify(originalData));
    if (text === "All") {
      setInventoryControls(data);
    } else {
      let inventoryControl = data.filter((item) => item.approved === text);
      setInventoryControls(inventoryControl);
    }
  };

  const handleFileUpload = async (e) => {
    // let file = e.target.files[0];
    // console.log("FILE", file);
    // let alertRes = window.confirm(
    //   "Are you sure you want to upload the menu data?"
    // );
    // if (!alertRes) {
    //   return;
    // }

    setIsLoading(true);

    try {
      // let url = `${
      //   ApiEndpoints.importInventory
      // }?location_id=${locationId}&name=${localStorage.getItem("Name")}`;
      // let url = `/dev/v1/import-inventory?location_id=${locationId}&name=${localStorage.getItem(
      //   "Name"
      // )}`;

      // let test = `https://dev-api.liquorsplit.io/inventory/v1/import-inventory?location_id=d84d151a-c2c7-491c-b1f6-72933d80f13f&name=Nitesh`;

      // let res = await uploadFileToServer(
      //   url,
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   file
      // );

      let preSignedUrl = ApiEndpoints.menuPresignedUrl;

      let preSignedRes = await axios.get(preSignedUrl);
      if (preSignedRes.status === 200) {
        let ukey = preSignedRes?.data?.data?.key;
        let url = preSignedRes?.data?.data?.url;

        console.log("URL", url, ukey);

        let res = await uploadFile(url, file);
        console.log("RES1", res);

        if (res.status === 200) {
          let data = {
            key: ukey,
            // over_write: isCheckedList,
            file_name: file.name,
          };
          let importRes = await axios.post(
            `${
              ApiEndpoints.createTransactionProduct
            }?location_id=${locationId}&name=${localStorage.getItem("Name")}`,
            data
          );

          //console.log("FINAL", importRes);
          if (importRes.status === 200) {
            setIsLoading(false);
            setIsImport(true);
            setImportStatus("Successfull");
            setFile("");
            setModal(false);
            toast.success("Your Request is Successfully Submitted");
            let action = "import-products";
            let type = "Info";
            let message = "Menu Transaction imported successfully.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          } else {
            setIsLoading(false);
            setIsImport(false);
            setFile("");
            setModal(false);
            setImportStatus("Failed");
            toast.error("Your Request is Failed");
          }
        }
      }
    } catch (error) {
      console.log("Err", error, error.response?.data?.message);
      if (error.message) {
        setIsLoading(false);
        //alert(error.data.message);
        setModal(false);
        console.log("messagemessage", error.message);
        toast.error(error.response?.data?.message);
        let action = "import-products";
        let type = "Error";
        let message = "Error in Menu Transaction import.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    }
  };

  let uploadFileToServer = (uploadPreSignedUrl, contentType, requestBody) => {
    setIsLoading(true);

    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          setIsLoading(false);

          resolve(
            JSON.parse(
              JSON.stringify({ status: 200, data: "xhr.responseText" })
            )
          );
        } else {
          setIsLoading(false);

          reject(
            JSON.parse(
              JSON.stringify({ status: 400, data: "xhr.responseText" })
            )
          );
        }
      };

      xhr.open("PUT", uploadPreSignedUrl);
      // xhr.setRequestHeader("Content-Type", contentType);
      // xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.send(requestBody);
    });
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  const callXhr = async (url, data) => {
    // create an XHR object
    const xhr = new XMLHttpRequest();

    // listen for `onload` event
    xhr.onload = () => {
      // process response
      if (xhr.status == 200) {
        // parse JSON data
        console.log(JSON.parse(xhr.response));
      } else {
        console.error("Error!");
      }
    };

    // create a `GET` request
    xhr.open("PUT", url);

    // send request
    xhr.send(data);
  };

  const getLocationList = async () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiEndpoints.locationList}`, config)
      .then((response) => response.json())
      .then(async (data) => {
        const locations = data?.data?.Items;
        console.log("LOc", locations);

        let location = localStorage.getItem("selected_location_id");

        if (location && location !== "undefined") {
          console.log("SDFSDFSDFSDFSDFSDF");
          setLocationId(location);
        } else {
          localStorage.setItem("inventory_location", locations[0].pk);
          // setPageNum(0);
          // updateOrderStatus("All");
          setLocationId(locations[0].pk);
        }

        let role = localStorage.getItem("userRole");

        if (role == "Admin") {
          setLocationData(locations);
        }
        // else {
        //   let data0 = localStorage.getItem("currentLocation");
        //   let locationR = [];
        //   let data = data0.split(",");
        //   data.forEach((el) => {
        //     let data3 = locations.find((item) => item.pk == el);
        //     locationR.push(data3);
        //   });
        //   console.log("locationsss", locationR);
        //   setLocationData(locationR);
        //   // var location = userLocation.split("#")[1];
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  console.log("SDFASD", locationId);

  // useEffect(() => {
  //   getLocationList();
  // }, []);

  const handleChange = async (e) => {
    console.log("EEEE", e.target.value);

    let tempLocation = e.target.value;
    setLocationId(tempLocation);
    localStorage.setItem("inventory_location", tempLocation);
  };

  const toggleLoader = (value) => {
    console.log("SDsgdsag", value);
    setIsLoading(value);
    console.log("LO", isLoading);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={
                        isMobile
                          ? { fontSize: "18px", whiteSpace: "nowrap" }
                          : null
                      }
                    >
                      {"Menu Transactions"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("manage_import")) && (
                      <Button
                        // className="buttons-copy buttons-html5"
                        className={styles.buttonStyle}
                        color="default"
                        size="sm"
                        id="copy-tooltip"
                        onClick={() => setModal(true)}
                      >
                        <span>
                          <i class="fas fa-upload"></i> Import
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row></Row>
              </CardHeader>
              <CardBody className={styles.transactionList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getTransactionList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    handleSourceOptions={handleSourceOptions}
                    selectedSource={selectedSource}
                    sourceDropDown={sourceDropDown}
                    toggleSourceDropDown={toggleSourceDropDown}
                    sourceOptions={sourceOptions}
                    btns={btns}
                    placeHolderText="Search by Transaction Id"
                    transactionListDropDown={true}
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getTransactionList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    handleSourceOptions={handleSourceOptions}
                    selectedSource={selectedSource}
                    sourceDropDown={sourceDropDown}
                    toggleSourceDropDown={toggleSourceDropDown}
                    sourceOptions={sourceOptions}
                    btns={btns}
                    placeHolderText="Search by Transaction Id"
                    transactionListDropDown={true}
                    dropDown={false}
                    orderList={false}
                  />
                )}
              </CardBody>
              <div style={{ height: 560, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      <th>Uploaded At</th>
                      <th>Name</th>
                      <th>Processing Status</th>
                      <th>Approval Status</th>
                      {/* <th>Override</th> */}
                      <th>File Name</th>
                      <th>Imported</th>
                      <th>Error File</th>
                      <th>Actions</th>

                      {/* <th>Reason</th> */}

                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {inventoryControls && inventoryControls.length ? (
                      inventoryControls?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {moment(item.updated_at).format("MM/DD/YYYY")}
                          </td>
                          <td>{item.name}</td>
                          <td>{item.status}</td>
                          <td>{item.approved}</td>
                          {/* <td>{item.over_write ? "True" : "False"}</td> */}
                          <td>
                            {item.file_name && item.file_name.split(".xlsx")[0]}
                          </td>
                          <td>
                            {item.uploaded_s3_url !== "" && (
                              <a
                                href={item.uploaded_s3_url}
                                download="MyExampleDoc"
                                target="_blank"
                              >
                                <Button
                                  className="buttons-copy buttons-html5 btn-link"
                                  size="sm"
                                >
                                  <span>
                                    <i class="fas fa-download"></i>
                                  </span>
                                </Button>
                              </a>
                            )}
                          </td>
                          <td>
                            {item.error_s3_url !== "" && (
                              <a
                                href={item.error_s3_url}
                                download="MyExampleDoc"
                                target="_blank"
                              >
                                <Button
                                  className="buttons-copy buttons-html5 btn-link"
                                  size="sm"
                                >
                                  <span>
                                    <i class="fas fa-download"></i>
                                  </span>
                                </Button>
                              </a>
                            )}
                          </td>
                          <td>
                            <ButtonGroup>
                              <Button
                                className="btn-icon-only"
                                color="info"
                                type="button"
                                onClick={() =>
                                  history.push({
                                    pathname: `/admin/product/location-menu-imports-view/${item.id}`,
                                    state: {
                                      item: item,
                                      // , permission: "view"
                                    },
                                  })
                                }
                                id={"tooltipv" + "view"}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Button>
                            </ButtonGroup>
                            {localStorage.getItem("userRole") === "Admin" && (
                              <>
                                {(item.approved === "Pending For Approval" ||
                                  item.approved === "Awaiting user response") &&
                                  (userRoles.includes("1") ||
                                    roleActions.includes("manage_approve")) && (
                                    <Button
                                      className="btn-icon-only"
                                      color="success"
                                      type="button"
                                      onClick={() =>
                                        history.push({
                                          pathname: `/admin/product/location-menu-imports-edit/${item.id}`,
                                          state: {
                                            item: item,
                                            // permission: "edit",
                                          },
                                        })
                                      }
                                      id={"tooltipv" + "edit"}
                                    >
                                      <span className="btn-inner--icon">
                                        <i className="fa fa-edit"></i>
                                      </span>
                                    </Button>
                                  )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
        onClosed={() => setFile(null)}
      >
        <ModalHeader toggle={modalToggle}>Import</ModalHeader>
        <ModalBody style={{ marginTop: "-20px" }}>
          <input
            type="file"
            hidden
            name="importBtn"
            id="importBtn"
            onChange={(e) => setFile(e.target.files[0])}
            ref={fileInputRef}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onClick={(e) => (e.target.value = null)}
          />
          {file ? (
            <>
              <div>
                <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                  File <i class="fal fa-file-spreadsheet"></i>
                </h6>
                <h4>{file.name}</h4>
                {/* <div style={{ display: "flex", marginLeft: "20px" }}>
                  <Input
                    type="checkbox"
                    id="overwriteCheckbox"
                    checked={isCheckedList}
                    onChange={() => handleCheckboxChange()}
                  />
                  <h4>Override</h4>
                </div> */}
                <h5>
                  Products currently listed on the menu will not be included in
                  the import process
                </h5>
              </div>
              <Button color="primary" onClick={handleFileUpload}>
                Submit
              </Button>
              <Button color="secondary" onClick={() => setFile(null)}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <h6 className="fullcalendar-title h4 d-inline-block mb-0 mr-4">
                Please upload the file
              </h6>
              <Button
                color="primary"
                onClick={() => fileInputRef.current.click()}
              >
                Browse
              </Button>
            </>
          )}
        </ModalBody>

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ProductMenuTransaction;
