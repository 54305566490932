import React, { useEffect, useState } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";
import routes from "./Menu";
import Sidebar from "./Sidebar";
import { HomePage, NotificationView, EmailView, ChatView } from "./pages";
import { ApiUrls } from "../../common/apiconstant";
import { Role } from "../../common/helpers";

import {
  UsersListView,
  UpdateUserView,
  AddNewUserView,
  DetailedUserView,
} from "../generated/users";

import { InventoryView, QuickScan } from "../generated/inventory";

import { DispatchView } from "../generated/dispatch";

import {
  DriverList,
  DriverProfileShow,
  DriversCreate,
  DriversEdit,
} from "../generated/drivers";
import { ShowProfile, ChangePassword } from "../generated/profile";
import { ControlsList, ControlsShow } from "../generated/controls";
import { TransactionList, TransactionShow } from "../generated/transections";

import {
  EmployeesList,
  EmployeesCreate,
  EmployeesProfile,
  EmployeesProfileShow,
  EmployeesProfileEdit,
} from "../generated/employees";
import {
  TenantList,
  TenantCreate,
  TenantProfile,
  TenantProfileShow,
  TenantProfileEdit,
} from "../generated/tenant";

import { OrderList, CreateOrder, EditOrder } from "../generated/b2b";
import Dispute from "./dispute/Dispute";
import DisputeshowDispute from "./dispute/DisputeshowDispute";
import {
  TemplatesListView,
  UpdateTemplatesView,
  AddNewTemplatesView,
  DetailedTemplatesView,
} from "../generated/emailTemplates";
// Insert more CRUD imports here
import {
  RestaurantsListView,
  UpdateRestaurantsView,
  AddNewRestaurantsView,
  DetailedRestaurantsView,
} from "../generated/restaurants";

import {
  LocationsListView,
  AddNewLocationView,
  UpdateLocationView,
} from "../generated/locations";

import {
  ProductsListView,
  UpdateProductsView,
  AddNewProductsView,
  DetailedProductsView,
} from "../generated/products";

import {
  CategoriesListView,
  UpdateCategoriesView,
  AddNewCategoriesView,
  DetailedCategoriesView,
} from "../generated/categories";

import {
  OrdersListView,
  DetailedOrdersView,
  RefundOrder,
  AssignDriver,
} from "../generated/orders";

import {
  ReviewsListView,
  UpdateReviewsView,
  AddNewReviewsView,
  DetailedReviewsView,
} from "../generated/reviews";
import AdminNavbar from "./AdminNavbar";

import { LogoutView } from "../../onboarding";
import NotificationCreate from "../generated/notification/create";
import NotificationList from "../generated/notification/list";
import NotificationEdit from "../generated/notification/edit";
import NotificationShow from "../generated/notification/show";
//import { messaging } from "../firebase";
import PublicOrderDetail from "../generated/orders/publicOrderDetail";
import ImportsControl from "../generated/controls/imports";
import ApprovedImports from "../generated/controls/approvedImports";

import Permission from "./rolePremission/Permission";
import RolePermission from "./rolePremission/RolePermission";
import NewRoleCreate from "./rolePremission/NewRoleCreate";
import ViewRoleDetails from "./rolePremission/ViewRoleDetails";
import ViewPremissionDetails from "./rolePremission/ViewPremissionDetails";
import NewPermissionCreate from "./rolePremission/NewPermissionCreate";
import EditRole from "./rolePremission/EditRole";
import EditPermission from "./rolePremission/EditPermission";

import EditUserDetails from "../generated/employees/EditUserDetails";
import { locale } from "moment";
import ArchivedUserList from "../generated/archivedUsers/list";
import ArchivedUserProfile from "../generated/archivedUsers/show";
import EditArchivedUser from "../generated/archivedUsers/edit";
import { toast } from "react-toastify";
import CollectionsList from "../generated/collections/list";
// added 1-05-2023
import AccordianList from "../generated/accordianList/collectionaccordian";
import Manage from "../generated/managecollection/manage";

import CouponsList from "../generated/discount/list";
import ViewCouponDetails from "../generated/discount/view";
import NewCouponCreate from "../generated/discount/create";

import ViewOrder from "../generated/b2b/show";
import {
  CreateOrderAdmin,
  EditOrderAdmin,
  OrderListAdmin,
} from "../generated/b2bAdmin";
import ViewOrderAdmin from "../generated/b2bAdmin/show";
import SetLocation from "../generated/setLocation/set";
import axios from "axios";
import ManageCollections from "../generated/manageCollections/list";
import SearchInventoryProduts from "../generated/inventoryProducts/products";
import TenantsList from "../generated/tenantarchived/list";
import ArchivedTenantShow from "../generated/tenantarchived/show";
import EditArchivedTenant from "../generated/tenantarchived/edit";
import SubCategory from "./subCategory/list";
import ViewSubCategoryDetails from "./subCategory/view";
import NewSubcategoryCreate from "./subCategory/create";
import EditSubCategory from "./subCategory/edit";
import {
  CreateOrderAdjustment,
  EditOrderAdjustment,
  OrderListAdjustment,
} from "../generated/b2bAdjustments";
import ViewOrderAdjustment from "../generated/b2bAdjustments/show";
import CreateOrderDiscount from "../generated/discount/createOrderDiscount";
import ManageCollectionsTenant from "../generated/manageCollections/tenantList";
import CollectionAccordianTenant from "../generated/accordianList/tenantCollectionAccordian";
import EditCouponCreate from "../generated/discount/edit";
import ViewOrderCouponDetails from "../generated/discount/viewOrderDiscount";
import EditOrderCouponCreate from "../generated/discount/editOrderDiscount";
import ControlsListImport from "../generated/importExport/list";
import ControlsShowImport from "../generated/importExport/show";
import ImportsControlImport from "../generated/importExport/imports";
import ApprovedImportsImport from "../generated/importExport/approvedImports";
import OrdersList from "../generated/orderList/orderlist";
import ProductDetailsList from "../generated/productDetails/list";
// import CreateProduct from "../generated/productDetails/create";
import ProductList from "../generated/shopifyProducts/list";
import CreateProduct from "../generated/shopifyProducts/create";
import { logoutUser } from "../../app/redux/auth/actions";
import { useDispatch } from "react-redux";
import ProductSummary from "../generated/shopifyProducts/productSummary";
import ProductDetails from "../generated/shopifyProducts/productDetails";
import EditProduct from "../generated/shopifyProducts/edit";
import ViewProduct from "../generated/shopifyProducts/view";
import ProductMenu from "../generated/shopifyProducts/productMenu";
import ProductMenuTransaction from "../generated/shopifyProducts/productTransaction";
import ImportTransactionListEdit from "../generated/shopifyProducts/importsEdit";
import ImportTransactionListView from "../generated/shopifyProducts/importsView";
import ModifyOrder from "../generated/orders/modifyOrder";
import useDeviceSize from "../generated/hooks/useDeviceSize";
import ImportSuggestionApproval from "../generated/shopifyProducts/importsEditApproval";
import FirebaseNotificationList from "../generated/firebaseNotification/list";
import NewNotificationCreate from "../generated/firebaseNotification/create";
import ImportsControlImportEdit from "../generated/importExport/importEdit";
import BlackListUsersList from "../generated/blackList/list";
import BlackListUsersCreate from "../generated/blackList/create";
import BlackListUsersEdit from "../generated/blackList/edit";
import BlackListUsersView from "../generated/blackList/view";
import POSInventoryFile from "../generated/posInventory/inventoryList";
import InventoryFileEdit from "../generated/posInventory/editFile";
import DashBoardList from "../generated/dashboard/list";
import POSMappingFile from "../generated/posInventory/mappingList";
import InventoryFileView from "../generated/posInventory/viewFile";
import POSFileView from "../generated/posInventory/mappingView";
import POSFileEdit from "../generated/posInventory/mappingEdit";
import ImportFileData from "../generated/posInventory/importView";
import ReportingList from "../generated/reporting/list";
import SkuList from "../generated/reporting/skuList";
import TransactionsList from "../generated/reporting/transactionList";
import ProductSuggestionsData from "../generated/posInventory/productSuggestions";
import MasterProductList from "../generated/masterProducts/list";
import EditMasterProducts from "../generated/masterProducts/edit";
import MasterProductView from "../generated/masterProducts/view";
import ManuallyUpdatedData from "../generated/posInventory/manuallyUpdated";
import ManageSkuTransaction from "../generated/ManageSkuTransaction/manageSkuTransaction";

//mport app1 from "../firebase";
//import { messaging } from "firebase";

function AdminView() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [userDetail, setUserDetail] = React.useState({});
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const [newRoutes, setNewRoutes] = useState(routes);
  const [imageUpdate, setImageUpdate] = useState(false);

  const dispatch = useDispatch();
  const [width] = useDeviceSize();

  useEffect(() => {
    if (!localStorage.getItem("attributes")) {
      logoutUser(dispatch, history)(dispatch);
    }
  }, [dispatch, location.pathname]);

  console.log("LOCS", location, params);
  useEffect(() => {
    if (location.pathname === "/order/:orderId/:locationId") {
      console.log("ASDFADFASDASGDS SGAG");
      return null;
    }
  }, [location.pathname]);
  const mainContentRef = React.useRef(null);

  useEffect(() => {
    let route = JSON.parse(JSON.stringify(routes));
    console.log("ROUTE", route, routes);

    setNewRoutes(route);
  }, []);

  const getLocationList = async () => {
    let res = await axios.get(`${ApiUrls.allLocationList}`);
    console.log("RESPONSE LO", res);

    let locations = res?.data?.data;
    let userData = JSON.parse(localStorage.getItem("user_detail"));
    let route = JSON.parse(JSON.stringify(routes));
    route[0].locations = [];
    let location = [];
    let firstTime = true;
    let selectedLocationId = localStorage.getItem("selected_location_id");

    for (let i = 0; i < locations.length; i++) {
      let pk = locations[i].location_id;

      if (userData?.roles?.includes("1")) {
        if (firstTime && !selectedLocationId) {
          localStorage.setItem("selected_location_id", pk);
          localStorage.setItem("selected_location_name", locations[i].title);
          localStorage.setItem("tenant_id", locations[i].customer_id);
          firstTime = false;
        }

        route[0].locations.push({
          name: locations[i].title,
          miniName: "",
          locationId: pk,
          hasPermission: "locations",
          icon: "ni ni-shop text-primary",
          value: locations[i].title,
          label: locations[i].title,
          tenant_id: locations[i].customer_id,
        });
        console.log("SUPER ADMIN");
      } else {
        if (userData?.locations?.includes(pk)) {
          if (firstTime && !selectedLocationId) {
            localStorage.setItem("selected_location_id", pk);
            localStorage.setItem("selected_location_name", locations[i].title);
            localStorage.setItem("tenant_id", locations[i].customer_id);

            firstTime = false;
          }

          // route[0].views.push({
          //   path: "/locations",
          //   name: locations[i].title,
          //   miniName: "P",
          //   layout: "/admin",
          //   hasPermission: "locations",
          // });
          route[0].locations.push({
            name: locations[i].title,
            miniName: "",
            locationId: pk,
            hasPermission: "locations",
            icon: "ni ni-shop text-primary",
            value: locations[i].title,
            label: locations[i].title,
            tenant_id: locations[i].customer_id,
          });
        }
        console.log("SUPER ADMIN NOT");
      }
    }

    // let route = JSON.parse(JSON.stringify(routes));
    // console.log("ROUTE", route, routes);

    // route[1].views.push({
    //   path: "/locations",
    //   name: "Locations 1",
    //   miniName: "P",
    //   layout: "/admin",
    //   hasPermission: "locations",
    // });
    setNewRoutes(route);
  };

  useEffect(() => {
    getLocationList();
  }, []);

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   // console.log(process.env.REACT_APP_VAPID_KEY);
  //   if (permission === "granted") {
  //     let token = await messaging.getToken({
  //       vapidKey: process.env.REACT_APP_VAPID_KEY,
  //     });
  //     console.log(token);
  //     if (token) {
  //       localStorage.setItem("FCM_token", token);
  //     }
  //   } else if (permission === "denied") {
  //     console.log("Permission denied");
  //   }
  // }

  // useEffect(() => {
  //   requestPermission();
  // }, []);

  //userDetail
  var username = localStorage.getItem("username");

  React.useEffect(() => {
    fetch(`${ApiUrls.getLoginUser}?email=${username}`)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((res) => {
        console.log("NAMESSGGS", res);

        if (!res?.data?.user?.is_active) {
          console.log("IN IF ADMIN");
          toast.error(
            "Your account has been suspended, Please contact liquorsplit admin."
          );
          history.push("/admin/logout");

          return;
        }

        localStorage.setItem("userRole", "Admin");
        localStorage.setItem("Name", res?.data?.user?.firstname);
        localStorage.setItem(
          "fullname",
          res?.data?.user?.firstname + " " + res?.data?.user?.lastname
        );
        localStorage.setItem("userId", res?.data?.user?.email);
        // if (
        //   typeof res?.data[0]?.user_role !== undefined &&
        //   res?.data[0].user_role !== Role.Admin
        // ) {
        //   if (res?.data[0].defaultStore !== undefined) {
        //     localStorage.setItem("location", res?.data[0].defaultStore);
        //   }
        // }
        console.log(
          "res?.data?.rolesActionres?.data?.rolesAction=>",
          res?.data?.rolesAction
        );
        localStorage.setItem("user_detail", JSON.stringify(res?.data?.user));
        localStorage.setItem(
          "role_actions",
          JSON.stringify(res?.data?.rolesAction)
        );
        console.log("users", res?.data);
        if (res?.data?.user?.customer_id) {
          localStorage.setItem("tenant_id", res?.data?.user?.customer_id);
        } else {
          // localStorage.setItem("tenant_id","188299065656")
        }

        if (res?.data?.user && Object.keys(res?.data?.user)?.length) {
          let userDetails = {};
          userDetails = res?.data?.user;

          userDetails.user_role = "Admin";
          setUserDetail(userDetails);
        }
      });
  }, [imageUpdate]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  // toggles collapse between mini sidenav and normal

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  useEffect(() => {
    console.log("widthhh", width);
    if (width > 0 && width < 500) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      setSidenavOpen(!sidenavOpen);
    }
  }, [width]);

  console.log("sidenavOpen", sidenavOpen);

  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <Sidebar
        routes={newRoutes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        userDetail={userDetail}
        logo={{
          innerLink: "/",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
          userDetail={userDetail}
        />
        <Switch>
          <Route path="/about">
            <div>About</div>
          </Route>
          <Route path="/admin/dispatch">
            <DispatchView />
          </Route>
          {/* Personal Details */}
          <Route path="/admin/profile/:emailId">
            <ShowProfile setImageUpdate={setImageUpdate} />
          </Route>
          <Route path="/admin/change-password">
            <ChangePassword />
          </Route>
          <Route path="/admin/logout">
            <LogoutView />
          </Route>
          <Route exact path="/admin">
            <HomePage />
          </Route>
          <Route path="/order/:orderId/:locationId">
            <PublicOrderDetail />
          </Route>

          {/* Collection */}
          <Route exact path="/admin/channel-tenants/list-sub-category">
            <SubCategory />
          </Route>
          <Route
            exact
            path="/admin/channel-tenants/list-sub-category/view/:sub_menu_id"
          >
            <ViewSubCategoryDetails />
          </Route>
          <Route exact path="/admin/channel-tenants/list-sub-category/create">
            <NewSubcategoryCreate />
          </Route>
          <Route
            exact
            path="/admin/channel-tenants/list-sub-category/edit/:sub_menu_id"
          >
            <EditSubCategory />
          </Route>
          <Route path="/admin/channel-tenants/manage-collection-list">
            <ManageCollectionsTenant />
          </Route>
          <Route path="/admin/channel-tenants/collection-list">
            <CollectionsList />
          </Route>
          <Route path="/admin/channel-tenants/picked-collection-list">
            <CollectionAccordianTenant />
          </Route>

          <Route path="/admin/collections">
            <CollectionsList />
          </Route>

          {/* CollectionAccordian */}

          <Route path="/admin/picked-collections">
            <AccordianList />
          </Route>

          {/* Manage */}

          <Route path="/admin/managecollection">
            <Manage />
          </Route>

          <Route path="/admin/manage-collections">
            <ManageCollections />
          </Route>

          {/* Routes For Admin and location Admin */}

          {userDetail.user_role === Role.Admin ||
            userDetail.user_role === Role.LocationAdmin ? (
            <>
              {/* {inventory controls menus} */}
              <Route path="/admin/inventory-controls">
                <ControlsList />
              </Route>
              <Route path="/admin/inventory-controls-show/:id">
                <ControlsShow />
              </Route>
              <Route path="/admin/inventory-controls-imports">
                <ImportsControl />
              </Route>
              <Route path="/admin/import-approved">
                <ApprovedImports />
              </Route>

              <Route path="/admin/import-export">
                <ControlsListImport />
              </Route>
              <Route path="/admin/import-export-show/:id">
                <ControlsShowImport />
              </Route>
              <Route path="/admin/import-export-imports-view/:id">
                <ImportsControlImport />
              </Route>
              <Route path="/admin/import-export-imports-edit/:id">
                <ImportsControlImportEdit />
              </Route>
              <Route path="/admin/import-export-import-approved">
                <ApprovedImportsImport />
              </Route>

              {/* {inventory transactions menus} */}
              <Route path="/admin/inventory-transactions">
                <TransactionList />
              </Route>
              <Route path="/admin/inventory-transactions-show/:id">
                <TransactionShow />
              </Route>

              {/* {inventory Quick Scan} */}
              <Route path="/admin/inventory-quick-scan">
                <QuickScan />
              </Route>

              {/* {Driver menus} */}
              <Route path="/admin/drivers">
                <DriverList />
              </Route>
              <Route path="/admin/drivers-show/:userID">
                <DriverProfileShow />
              </Route>
              <Route path="/admin/drivers-create">
                <DriversCreate />
              </Route>
              <Route path="/admin/drivers-edit/:userID">
                <DriversEdit />
              </Route>
              {/* /locationId/notificationId */}
              {/* {Notification menus} */}
              <Route path="/admin/notification">
                <NotificationList />
              </Route>
              <Route path="/admin/notification-show/:item">
                <NotificationShow />
              </Route>
              <Route path="/admin/dispute">
                <DisputeshowDispute />
              </Route>
              <Route path="/admin/notification-create">
                <NotificationCreate />
              </Route>
              <Route path="/admin/notification-edit/:notificationId/:locationsId/:message/:screenName/:isEnabled/:startDate/:endDate/:primary_message/:secondary_message/:isBlocking/:title/:target">
                <NotificationEdit />
              </Route>

              {/* {Firebase notification} */}

              <Route path="/admin/notifications" exact>
                <FirebaseNotificationList />
              </Route>

              <Route path="/admin/notifications/create" exact>
                <NewNotificationCreate />
              </Route>

              {/* {BlackList Users} */}

              <Route path="/admin/blacklist">
                <BlackListUsersList />
              </Route>

              <Route path="/admin/blacklist-create">
                <BlackListUsersCreate />
              </Route>

              <Route path="/admin/blacklist-view/:id">
                <BlackListUsersView />
              </Route>

              <Route path="/admin/blacklist-edit/:id">
                <BlackListUsersEdit />
              </Route>

              {/* {Employees meuns} */}
              <Route exact path="/admin/users">
                <EmployeesList />
              </Route>
              <Route path="/admin/user-create">
                <EmployeesProfile />
              </Route>
              <Route path="/admin/employees-profile">
                <EmployeesProfile />
              </Route>
              <Route path="/admin/user-edit/:emailId">
                <EditUserDetails setImageUpdate={setImageUpdate} />
              </Route>
              <Route path="/admin/user-show/:userID">
                <EmployeesProfileShow />
              </Route>
              <Route path="/admin/employees-edit/:userID">
                <EmployeesProfileEdit />
              </Route>
              {/* tenant */}

              <Route exact path="/admin/tenant">
                <TenantList />
              </Route>
              <Route path="/admin/tenant-create">
                <TenantProfile />
              </Route>
              <Route path="/admin/tenant-profile">
                <TenantProfile />
              </Route>
              <Route path="/admin/tenant-show/:emailId">
                <TenantProfileShow />
              </Route>
              <Route path="/admin/tenant-edit/:emailId">
                <TenantProfileEdit />
              </Route>

              {/* Archived Tenants */}

              <Route exact path="/admin/archived-tenant">
                <TenantsList />
              </Route>
              <Route exact path="/admin/archived-tenant-show/:emailId">
                <ArchivedTenantShow />
              </Route>
              <Route exact path="/admin/archived-tenant-edit/:emailId">
                <EditArchivedTenant />
              </Route>

              {/* Archived Users */}
              <Route exact path="/admin/archived-users">
                <ArchivedUserList />
              </Route>
              <Route exact path="/admin/view-archived-user/:userID">
                <ArchivedUserProfile />
              </Route>
              <Route exact path="/admin/edit-archived-user/:userID">
                <EditArchivedUser />
              </Route>

              {/* B2B Admin Order */}

              <Route exact path="/admin/inventory-products">
                <SearchInventoryProduts />
              </Route>

              <Route exact path="/admin/inventory-update-admin">
                <OrderListAdmin />
              </Route>
              <Route exact path="/admin/inventory-update-admin/create">
                <CreateOrderAdmin />
              </Route>
              <Route exact path="/admin/inventory-update-admin/edit/:orderID">
                <EditOrderAdmin />
              </Route>
              <Route exact path="/admin/inventory-update-admin/view/:orderID">
                <ViewOrderAdmin />
              </Route>

              {/* B2B Orders */}
              <Route exact path="/admin/inventory-update">
                <OrderList />
              </Route>
              <Route exact path="/admin/inventory-update/create">
                <CreateOrder />
              </Route>
              <Route exact path="/admin/inventory-update/edit/:orderID">
                <EditOrder />
              </Route>
              <Route exact path="/admin/inventory-update/view/:orderID">
                <ViewOrder />
              </Route>
              <Route exact path="/admin/inventory-adjustment">
                <OrderListAdjustment />
              </Route>
              <Route exact path="/admin/inventory-adjustment/create">
                <CreateOrderAdjustment />
              </Route>
              <Route exact path="/admin/inventory-adjustment/edit/:orderID">
                <EditOrderAdjustment />
              </Route>
              <Route exact path="/admin/inventory-adjustment/view/:orderID">
                <ViewOrderAdjustment />
              </Route>
              <Route exact path="/admin/disputes">
                <Dispute />
              </Route>
              <Route exact path="/admin/Role-Premission">
                <Permission />
              </Route>
              <Route exact path="/admin/Role">
                <RolePermission />
              </Route>
              <Route exact path="/admin/role-premission/newRoleCreate">
                <NewRoleCreate />
              </Route>
              <Route exact path="/admin/role-premission/editPermission">
                <EditPermission />
              </Route>
              <Route exact path="/admin/role-premission/ViewRoleDetails">
                <ViewRoleDetails />
              </Route>
              <Route exact path="/admin/role-premission/viewPremissionDetails">
                <ViewPremissionDetails />
              </Route>
              <Route exact path="/admin/role-premission/NewPermissionCreate">
                <NewPermissionCreate />
              </Route>
              <Route exact path="/admin/role-premission/EditRole">
                <EditRole />
              </Route>
              {/* <Route exact path="/admin/role-premission/editPermission">
                <EditPermission />
              </Route> */}

              {/* SubCategory */}

              <Route exact path="/admin/sub-category">
                <SubCategory />
              </Route>
              <Route exact path="/admin/sub-category/view/:sub_menu_id">
                <ViewSubCategoryDetails />
              </Route>
              <Route exact path="/admin/sub-category/create">
                <NewSubcategoryCreate />
              </Route>
              <Route exact path="/admin/sub-category/edit/:sub_menu_id">
                <EditSubCategory />
              </Route>

              {/* Locations */}
              <Route path="/admin/locations">
                <LocationsListView />
              </Route>
              <Route path="/admin/set-location">
                <SetLocation />
              </Route>
              <Route exact path="/admin/location/update/:id">
                <UpdateLocationView />
              </Route>
              <Route path="/admin/location/add">
                <AddNewLocationView />
              </Route>

              {/* POS Inventory */}
              <Route path="/admin/pos-inventory" exact>
                <POSInventoryFile />
              </Route>

              <Route path="/admin/pos-inventory/edit/:id" exact>
                <InventoryFileEdit />
              </Route>

              <Route path="/admin/pos-inventory/view/:id" exact>
                <InventoryFileView />
              </Route>

              <Route path="/admin/pos/mapping" exact>
                <POSMappingFile />
              </Route>

              <Route path="/admin/pos/mapping/edit/:id" exact>
                <POSFileEdit />
              </Route>

              <Route path="/admin/pos/mapping/view/:id" exact>
                <POSFileView />
              </Route>

              <Route path="/admin/inventory/view/:id" exact>
                <ImportFileData />
              </Route>

              <Route path="/admin/inventory/manually-updated/:id" exact>
                <ManuallyUpdatedData />
              </Route>

              <Route path="/admin/inventory/product-suggestion/:id" exact>
                <ProductSuggestionsData />
              </Route>

              {/* Dashboard */}

              <Route path="/admin/dashboard" exact>
                <DashBoardList />
              </Route>

              {/* Reporting */}

              <Route path="/admin/reporting" exact>
                <ReportingList />
              </Route>

              <Route path="/admin/sku" exact>
                <SkuList />
              </Route>
              <Route path="/admin/manage-sku-transaction" exact>
                <ManageSkuTransaction />
              </Route>
              <Route path="/admin/reports/transaction" exact>
                <TransactionsList />
              </Route>

              {/* Products */}
              <Route path="/admin/products" exact>
                <ProductList />
              </Route>
              <Route path="/admin/products/create" exact>
                <CreateProduct />
              </Route>
              <Route path="/admin/products/edit/:id">
                <EditProduct />
              </Route>
              <Route path="/admin/products/view/:id">
                <ViewProduct />
              </Route>
              <Route path="/admin/product/menu">
                <ProductMenu />
              </Route>
              <Route path="/admin/menu-import">
                <ProductMenuTransaction />
              </Route>
              <Route path="/admin/product/location-menu-imports-edit/:id">
                <ImportTransactionListEdit />
              </Route>
              <Route path="/admin/location-menu-approval">
                <ImportSuggestionApproval />
              </Route>
              <Route path="/admin/product/location-menu-imports-view/:id">
                <ImportTransactionListView />
              </Route>
              <Route path="/admin/transaction">
                <ProductSummary />
              </Route>
              <Route path="/admin/transaction-details/:id">
                <ProductDetails />
              </Route>

              {/* Master Products */}

              <Route path="/admin/master-menu">
                <MasterProductList />
              </Route>

              <Route path="/admin/master-menu-edit">
                <EditMasterProducts />
              </Route>

              <Route path="/admin/master-menu-view/:id">
                <MasterProductView />
              </Route>

              {/* Discount */}
              {/* <Route path="/admin/product-detail">
                <ProductDetailsList />
              </Route>

              <Route path="/admin/create-product">
                <CreateProduct />
              </Route> */}

              {/* Discount */}
              <Route path="/admin/discount">
                <CouponsList />
              </Route>

              <Route path="/admin/discount-edit/:id">
                <EditCouponCreate />
              </Route>

              <Route path="/admin/discount-order-edit/:id">
                <EditOrderCouponCreate />
              </Route>

              <Route path="/admin/discount-view/:id">
                <ViewCouponDetails />
              </Route>

              <Route path="/admin/order-discount/:id">
                <ViewOrderCouponDetails />
              </Route>

              <Route path="/admin/create-product-discount">
                <NewCouponCreate />
              </Route>
              <Route path="/admin/create-order-discount">
                <CreateOrderDiscount />
              </Route>

              {/* Orders List */}
              <Route path="/admin/list-orders">
                <OrdersList />
              </Route>

              {/* Orders */}
              <Route path="/admin/orders">
                <OrdersListView />
              </Route>
              {/* <Route path="/admin/order/:orderId/update">
                <UpdateOrdersView />
                </Route> */}
              <Route path="/admin/order/:orderId/view">
                <DetailedOrdersView userDeatil={userDetail} />
              </Route>
              {/* <Route path="/admin/order/add">
                <AddNewOrdersView />
              </Route> */}
              <Route path="/admin/order/modify/:orderId">
                <ModifyOrder />
              </Route>
              <Route path="/admin/order/refund/:orderId">
                <RefundOrder />
              </Route>
              <Route path="/admin/order/assign-driver">
                <AssignDriver />
              </Route>
            </>
          ) : null}

          {/* {userDetail.user_role === Role.Admin ||
          userDetail.user_role === Role.ProductManager ? (
            <>
              <Route path="/admin/transaction">
                <ProductSummary />
              </Route>
              <Route path="/admin/transaction-details/:id">
                <ProductDetails />
              </Route>
            </>
          ) : null} */}

          {userDetail.user_role === Role.Curator && (
            <>
              {/* {inventory controls menus} */}
              {/* <Route path="/admin/inventory-controls">
                <ControlsList />
              </Route>
              <Route path="/admin/inventory-controls-show/:id">
                <ControlsShow />
              </Route> */}

              {/* {inventory transactions menus} */}
              <Route path="/admin/inventory-transactions">
                <TransactionList />
              </Route>
              <Route path="/admin/inventory-transactions-show/:id">
                <TransactionShow />
              </Route>
            </>
          )}
          {userDetail.user_role === Role.Dispatcher && (
            <>
              {/* {Driver menus} */}
              <Route path="/admin/drivers">
                <DriverList />
              </Route>
              <Route path="/admin/drivers-show/:userID">
                <DriverProfileShow />
              </Route>
              <Route path="/admin/drivers-create">
                <DriversCreate />
              </Route>
              <Route path="/admin/drivers-edit/:userID">
                <DriversEdit />
              </Route>

              {/* Orders */}
              <Route path="/admin/orders">
                <OrdersListView />
              </Route>
              {/* <Route path="/admin/order/:orderId/update">
                <UpdateOrdersView />
                </Route> */}
              <Route path="/admin/order/:orderId/view">
                <DetailedOrdersView />
              </Route>
              {/* <Route path="/admin/order/add">
                <AddNewOrdersView />
              </Route> */}
              <Route path="/admin/order/refund">
                <RefundOrder />
              </Route>
              <Route path="/admin/order/assign-driver">
                <AssignDriver />
              </Route>
              {/* {Notification menus} */}
              <Route path="/admin/notification">
                <NotificationList />
              </Route>
              <Route path="/admin/notification-show/:item">
                <NotificationShow />
              </Route>
              <Route path="/admin/notification-create">
                <NotificationCreate />
              </Route>
              <Route path="/admin/notification-edit/:notificationId/:locationsId/:message/:screenName/:isEnabled/:startDate/:endDate/:primary_message/:secondary_message/:isBlocking/:title">
                <NotificationEdit />
              </Route>
            </>
          )}

          <Route path="/admin/sendNotification">
            <NotificationView />
          </Route>
          <Route path="/admin/sendEmail">
            <EmailView />
          </Route>

          <Route path="/admin/user/:userId/update">
            <UpdateUserView />
          </Route>
          <Route path="/admin/user/:userId/view">
            <DetailedUserView />
          </Route>
          <Route path="/admin/user/add">
            <AddNewUserView />
          </Route>
          <Route path="/admin/chat/:userId">
            <ChatView />
          </Route>
          <Route path="/admin/templates">
            <TemplatesListView />
          </Route>
          <Route path="/admin/template/:templateId/update">
            <UpdateTemplatesView />
          </Route>
          <Route path="/admin/template/:templateId/view">
            <DetailedTemplatesView />
          </Route>
          <Route
            path="/admin/template/add"
            component={(props) => <AddNewTemplatesView {...props} />}
          />
          {/* Insert more CRUD routes here */}

          <Route path="/admin/restaurants">
            <RestaurantsListView />
          </Route>
          <Route path="/admin/restaurant/:restaurantId/update">
            <UpdateRestaurantsView />
          </Route>
          <Route path="/admin/restaurant/:restaurantId/view">
            <DetailedRestaurantsView />
          </Route>
          <Route path="/admin/restaurant/add">
            <AddNewRestaurantsView />
          </Route>

          {/* <Route path="/admin/products">
            <ProductsListView />
          </Route> */}
          <Route path="/admin/product/:productId/update">
            <UpdateProductsView />
          </Route>
          <Route path="/admin/product/:productId/view">
            <DetailedProductsView />
          </Route>
          <Route path="/admin/product/add">
            <AddNewProductsView />
          </Route>

          <Route path="/admin/categories">
            <CategoriesListView />
          </Route>
          <Route path="/admin/category/:categoryId/update">
            <UpdateCategoriesView />
          </Route>
          <Route path="/admin/category/:categoryId/view">
            <DetailedCategoriesView />
          </Route>
          <Route path="/admin/category/add">
            <AddNewCategoriesView />
          </Route>

          <Route path="/admin/reviews">
            <ReviewsListView />
          </Route>
          <Route path="/admin/review/:reviewId/update">
            <UpdateReviewsView />
          </Route>
          <Route path="/admin/review/:reviewId/view">
            <DetailedReviewsView />
          </Route>
          <Route path="/admin/review/add">
            <AddNewReviewsView />
          </Route>

          <Route
            path="/firebase/collection/:collectionId"
            render={(props) => <div>Collections</div>}
          />
          <Route path="/page/:pageid" />
        </Switch>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default AdminView;
