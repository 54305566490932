// for prod
const config = {
  identityPoolId: "us-east-1:49c27c52-c615-4baa-a682-33ec3955b8b5",
  region: "us-east-1",
  userPoolId: "us-east-1_29qeuETvH",
  oauth: {
    domain: "https://liquorsplit.auth.us-east-1.amazoncognito.com",
    scope: ["email", "family_name", "given_name", "name", "username"],
    // redirectSignIn: "http://localhost:3003/login",
    // redirectSignOut:
    //   "https://coggoogleee.auth.us-east-1.amazoncognito.com/oauth2/idpresponse",
    responseType: "token",
  },
  appleSignIn: {
    clientId: "com.liquorsplit.saas",
    teamId: "TY34D38WMU",
    keyId: "CW756CW2TS",
    scope: ["email", "family_name", "given_name", "name", "username"],
    redirectURI: "https://manage.liquorsplit.io",
  },
  userPoolWebClientId: "33q60t03iv489rugtmbvjiidk",
  // userPoolWebClientId: "mohhltqpepbm2ck12fcjhtcho",
  authenticationFlowType: "USER_SRP_AUTH",
};

module.exports = config;

//for Dev
// const config = {
//   identityPoolId: "us-east-1:86c0683d-8bdb-47c1-807e-974307928164",
//   region: "us-east-1",
//   userPoolId: "us-east-1_MalO0LnaD",
//   oauth: {
//     domain: "https://coggoogleee.auth.us-east-1.amazoncognito.com",
//     scope: ["email", "family_name", "given_name", "name", "username"],
//     // redirectSignIn: "http://localhost:3003/login",
//     // redirectSignOut:
//     //   "https://coggoogleee.auth.us-east-1.amazoncognito.com/oauth2/idpresponse",
//     responseType: "token",
//   },
//   appleSignIn: {
//     clientId: "com.liquorsplit.saas",
//     teamId: "TY34D38WMU",
//     keyId: "CW756CW2TS",
//     scope: ["email", "family_name", "given_name", "name", "username"],
//     redirectURI: "https://qa-manage.liquorsplit.io",
//   },
//   userPoolWebClientId: "26dsrv49ru1poarbt64fvnb6kf",
//   authenticationFlowType: "USER_SRP_AUTH",
// };

// module.exports = config;
