const Validator = require("validator");
const isEmpty = require("is-empty");
module.exports = function validateResetPasswordInput(data) {
  let errors = {};
// Convert empty fields to an empty string so we can use validator functions
  data.newPassword = !isEmpty(data.newPassword) ? data.newPassword : "";
  data.confirmPassword = !isEmpty(data.confirmPassword) ? data.confirmPassword : "";
// Email checks
  if (Validator.isEmpty(data.newPassword)) {
    errors.newPassword = "New Password field is required";
  }
// Password checks
  if (Validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = "Confirm Password field is required";
  }else if(data.newPassword != data.confirmPassword){
    errors.confirmPassword = "Passwords didn't match!";
  }
return {
    errors,
    isValid: isEmpty(errors)
  };
};