import React, { useState, useEffect, useRef } from "react";

import Multiselect from "multiselect-react-dropdown";
// import Header from "../Header.js";
import { FieldArray, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import "./style.css";
import DatePicker from "react-datepicker";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
import SubCategory from "./list.js";
import { toast } from "react-toastify";
import { element } from "prop-types";
import { Upload } from "react-bootstrap-icons";
import "./style.css";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function AdditionalOptionsComponent({
  values,
  setFieldValue,
  errors,
  touched,
  handleNext,
  customData,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  let appliesRef = useRef();

  // const handleInputChange = (index, keyOrValue, value) => {
  //   const updatedCustomData = [...customData];
  //   updatedCustomData[index][keyOrValue] = value;
  //   if (index === customData.length - 1 && value !== "") {
  //     updatedCustomData.push({ key: "", value: "" });
  //   }
  //   if (index < customData.length - 1 && value === "") {
  //     updatedCustomData.splice(index, 1);
  //   }

  //   setCustomData(updatedCustomData);
  // };

  // useEffect(() => {
  //   if (customData.length) {
  //     customData.map((item) => setFieldValue(item.key, item.value));
  //   }
  // }, [customData.length]);

  console.log("custom data", customData);

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            {/* <Card>             
              <CardBody> */}
            {/* {successMsg != "" && (
              <UncontrolledAlert color="success">
                <span className="alert-text ml-1">{successMsg}</span>
              </UncontrolledAlert>
            )}
            {errMsg != "" && (
              <UncontrolledAlert color="danger">
                <span className="alert-text ml-1">{errMsg}</span>
              </UncontrolledAlert>
            )}
            {errorMsg != "" && (
              <UncontrolledAlert color="danger">
                <span className="alert-text ml-1">
                  {errorMsg.map((itemEr) => (
                    <p>{itemEr.error}</p>
                  ))}
                </span>
              </UncontrolledAlert>
            )} */}

            <Row>
              <Col lg="12">
                <FormGroup>
                  <CardTitle tag="h5" className="additionalOptionsCustomData">
                    Custom Data
                  </CardTitle>
                  <Row>
                    <Col md="12" sm="12">
                      <FormGroup>
                        <label
                          className="additionalOptionsData"
                          htmlFor="example3cols1Input"
                        >
                          Add custom data for sending notifications about
                          discount codes and specific products to the target
                          audience.
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <CardText>
                    {/* {customData.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "10px",
                          gap: "10px",
                        }}
                      >
                        <Input
                          className="selectOptions"
                          type="text"
                          placeholder="Key"
                          value={data.key}
                          onChange={(e) =>
                            handleInputChange(index, "key", e.target.value)
                          }
                        />
                        <Input
                          className="selectOptions"
                          type="text"
                          placeholder="Value"
                          value={data.value}
                          onChange={(e) =>
                            handleInputChange(index, "value", e.target.value)
                          }
                        />
                      </div>
                    ))} */}
                    <FieldArray
                      name="customData"
                      render={(arrayHelpers) =>
                        values.customData.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                              gap: "10px",
                            }}
                          >
                            {console.log("arrayhelper", arrayHelpers)}
                            <Input
                              className="selectOptions"
                              type="text"
                              placeholder="Key"
                              value={data.key}
                              onChange={(e) => {
                                arrayHelpers.replace(index, {
                                  ...data,
                                  key: e.target.value,
                                });
                                if (
                                  index === values.customData.length - 1 &&
                                  e.target.value !== ""
                                ) {
                                  arrayHelpers.push({ key: "", value: "" });
                                }
                                if (
                                  e.target.value === "" &&
                                  values.customData.length > 1
                                ) {
                                  arrayHelpers.remove(index);
                                }
                              }}
                            />
                            <Input
                              className="selectOptions"
                              type="text"
                              placeholder="Value"
                              value={data.value}
                              onChange={(e) => {
                                arrayHelpers.replace(index, {
                                  ...data,
                                  value: e.target.value,
                                });
                              }}
                            />
                          </div>
                        ))
                      }
                    />
                  </CardText>
                </FormGroup>
              </Col>
            </Row>

            {/* </CardBody>
            </Card> */}
          </div>
        </Row>
      </Container>
    </>
  );
}
export default AdditionalOptionsComponent;
