import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { logUserMetrics } from "../../../utils/file";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ApiEndpoints, imageUrl } from "./../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Bottle from "../../../assets/img/bottle/Bottle.png";
import Loader from "../../../onboarding/components/Loader";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/masterProductList.module.css";
import { useParams } from "react-router-dom";
import { BsDashLg } from "react-icons/bs";
import Dropzone from "react-dropzone";
import { XSquare } from "react-bootstrap-icons";
import { Switch } from "antd";

let categoryOptions = ["Spirits", "Beer", "Wine", "Mixers", "Selters & RTD"];

const MasterProductList = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [masterProductData, setMasterProductData] = useState([]);
  const [page, setPage] = useState(1);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [editMode, setEditMode] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [originalValue, setOriginalValue] = useState("");
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [productImage, setProductImage] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [saveChanges, setSaveChanges] = useState(false);
  const [alcoholicChecked, setAlcoholicChecked] = useState({});
  const [initialImage, setInitialImage] = useState(null);
  const [previousData, setPreviousData] = useState({});
  const [previousId, setPreviousId] = useState("");
  const [imageProgress, setImageProgress] = useState("");
  const [imageSizeError, setImageSizeError] = useState("");
  const [errors, setErrors] = useState({
    title: "",
    option1_value: "",
    // variant_barcode: "",
    tags: "",
  });
  const [createErrors, setCreateErrors] = useState({
    image_src: "",
    title: "",
    option1_value: "",
    variant_barcode: "",
    tags: "",
    metafield_custom_alcohol_by_volume_single_line_text_field: "",
    metafield_custom_brand_single_line_text_field: "",
    metafield_custom_category_single_line_text_field: "",
    metafield_custom_package_type_single_line_text_field: "",
  });
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const [formData, setFormData] = useState({
    variant_barcode: "",
    title: "",
    option1_value: "",
    status: "Active",
    body_html: "",
    contentType: "",
    created_by: "",
    tags: "",
    variant_price: "",
    image_src: "",
    metafield_custom_category_single_line_text_field: "",
    metafield_custom_package_type_single_line_text_field: "",
    metafield_custom_brand_single_line_text_field: "",
    metafield_custom_alcoholic_single_line_text_field: "TRUE",
    metafield_custom_alcohol_by_volume_single_line_text_field: "",
  });
  const [addProductModal, setAddProductModal] = useState(false);
  const toggleAddProductModal = () => {
    setAddProductModal((prevState) => !prevState);
    setImage({
      preview: "",
      raw: "",
    });
    setEditedValue("");
    setEditMode(null);
    setFormData({
      variant_barcode: "",
      title: "",
      option1_value: "",
      status: "Active",
      body_html: "",
      contentType: "",
      created_by: "",
      tags: "",
      variant_price: "",
      image_src: "",
      metafield_custom_category_single_line_text_field: "",
      metafield_custom_package_type_single_line_text_field: "",
      metafield_custom_brand_single_line_text_field: "",
      metafield_custom_alcoholic_single_line_text_field: "TRUE",
      metafield_custom_alcohol_by_volume_single_line_text_field: "",
    });
    setImageProgress("");
    setImageSizeError("");
  };
  const [cancelProductModal, setCancelProductModal] = useState(false);
  const toggleCancelProductModal = () => {
    setCancelProductModal((prevState) => !prevState);
  };

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const SortOptions = [
    { id: "title", text: "Title" },
    { id: "created_at", text: "Created At" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const getProductDetails = (pageNum) => {
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        searchKeyword: searchKeyword,
      }),
    };

    let masterProductList = `${ApiEndpoints.masterProductsList}?page=${pageNum}&limit=50`;

    if (selectedSortOption) {
      masterProductList =
        masterProductList +
        `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (selectedStatus !== "All") {
      masterProductList =
        masterProductList +
        `&status=${
          selectedStatus === "In-active" ? "Archived" : selectedStatus
        }`;
    }

    setIsLoading(true);
    fetch(masterProductList, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("res", res);

        const responseMasterProductsData = res?.data?.data;
        console.log("Response Master Product Data", res);

        const initialCheckedValues = {};
        responseMasterProductsData.forEach((item) => {
          if (item?.metafield_custom_alcoholic_single_line_text_field) {
            initialCheckedValues[item.id] =
              item.metafield_custom_alcoholic_single_line_text_field === "TRUE";
          } else {
            initialCheckedValues[item.id] = false;
          }
        });
        console.log(initialCheckedValues, "initialCheckedValues");
        setAlcoholicChecked(initialCheckedValues);

        const prodImagesWithIds = responseMasterProductsData.map((item) => ({
          id: item.id,
          image_src: item.image_src,
        }));
        console.log("productImagesWithIds", prodImagesWithIds);

        setImages(prodImagesWithIds);
        setProductImage(prodImagesWithIds);

        setEditedValue("");
        setEditMode(null);

        if (responseMasterProductsData?.length > 0) {
          const updateMasterProducts =
            pageNum === 1
              ? responseMasterProductsData
              : [...masterProductData, ...responseMasterProductsData];
          const mergedMasterProductsData =
            mergeAndSortMasterProductsData(updateMasterProducts);
          setMasterProductData(mergedMasterProductsData);
          console.log("masterdata");

          if (responseMasterProductsData?.length < 50) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setMasterProductData(responseMasterProductsData);
            console.log("masterdata4");

            if (responseMasterProductsData?.length < 50) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          }
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);
      });
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getProductDetails(pageNum);
  };

  useEffect(() => {
    if (selectedSortOption) {
      getProductDetails(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getProductDetails(page);
    } else if (searchKeyword === "") {
      getProductDetails(page);
    }
  }, [searchKeyword]);

  const mergeAndSortMasterProductsData = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const selectStatus = (value) => {
    setPage(1);
    setEditedValue("");
    setEditMode(null);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });

      const Status =
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      setSelectedStatus(Status);
    }
  };

  let btns = ["All", "Active", "In-active"];

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getProductDetails(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  function refreshS3BucketURL() {
    // Replace the current URL with a new one to force a refresh
    window.location.href = `${ApiEndpoints.masterProductsListDownloadList}`;
  }

  const handleView = (id) => {
    const viewPath = `/admin/master-menu-view/${id}`;
    history.push(viewPath);
  };

  const handleEdit = (item) => {
    let itemId = item?.id;
    console.log("newvalue", itemId, item);
    setErrors({
      title: "",
      option1_value: "",
      tags: "",
    });
    setPreviousData(item);
    setEditMode(itemId);
    setPreviousId(itemId);

    // setEditingItemId(itemId);

    // setEditedValue();
    let tempData = [...masterProductData];

    console.log("tempdataedit", tempData);
    let prevData = tempData.map((elm) => {
      if (elm?.id === previousId) {
        console.log("tempdataedit2", previousData, elm);
        return previousData;
      } else {
        return elm;
      }
    });

    console.log("tempdataedit1", tempData, prevData, previousData);

    setMasterProductData(prevData);
  };

  console.log("previousdata", previousId, previousData, masterProductData);

  const handleEditSave = async (item) => {
    console.log("saveitemlog", item, item?.id);
    const id = item?.id;

    const newErrors = {};

    if (item?.title.trim() === "") {
      newErrors.title = "Title is required";
    }

    if (item?.option1_value.trim() === "") {
      newErrors.option1_value = "Value is required";
    }

    if (item?.tags.trim() === "") {
      newErrors.tags = "Tags is required";
    }

    // if (item?.variant_barcode.trim() === "") {
    //   newErrors.variant_barcode = "UPC is required";
    // }

    console.log("errores", newErrors);

    setErrors(newErrors);

    let reqBody = {
      title: item?.title.trim(),
      option1_value: item?.option1_value.trim(),
      variant_barcode: item?.variant_barcode,
      tags: item?.tags.trim(),
      metafield_custom_category_single_line_text_field:
        item?.metafield_custom_category_single_line_text_field.trim(),
      metafield_custom_package_type_single_line_text_field:
        item?.metafield_custom_package_type_single_line_text_field.trim(),
      metafield_custom_brand_single_line_text_field:
        item?.metafield_custom_brand_single_line_text_field.trim(),
      metafield_custom_alcoholic_single_line_text_field:
        alcoholicChecked[item.id] === true ? "TRUE" : "",
      metafield_custom_alcohol_by_volume_single_line_text_field:
        item?.metafield_custom_alcohol_by_volume_single_line_text_field,
      status: item?.status,
      updated_by: localStorage.getItem("fullname"),
    };
    console.log("reqBody", reqBody);

    if (Object.keys(newErrors).length === 0) {
      try {
        setIsLoading(true);
        let response = await axios.put(
          `${ApiEndpoints.editMasterProductDetail}/${id}`,
          reqBody
        );
        if (response.status === 200) {
          setPreviousData(item);
          toast.success("Product updated successfully");
          setEditMode(null);
          // setEditingItemId(null);
          // getProductDetails(page);
          setIsLoading(false);
          let action = "master-matrixify-product-edit";
          let type = "Info";
          let message = "Master Matrixify Product successfully Edited.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        }
      } catch (error) {
        console.log("err", error);
        toast.error("Something went wrong");
        setIsLoading(false);
        let action = "master-matrixify-product-edit";
        let type = "Error";
        let message = "Error in edit master matrixify product.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } else {
      // If there are errors, display a toast message
      toast.error("Please fill all required details");
    }
  };

  const handleEditCancel = (item) => {
    console.log("value3", item);
    // setEditingItemId(null);
    setErrors({
      title: "",
      option1_value: "",
      tags: "",
    });
    setEditedValue("");
    setEditMode(null);
    console.log("previous", previousData);
    let tempMasterProductData = JSON.parse(JSON.stringify(masterProductData));
    const newData = tempMasterProductData.map((elm) => {
      if (elm.id === previousData.id) {
        console.log(
          "previous1",
          previousData,
          previousData.metafield_custom_package_type_single_line_text_field,
          elm.metafield_custom_package_type_single_line_text_field,
          elm
        );
        return previousData;
      } else {
        return elm;
      }
    });
    console.log("tempMasterProductData", tempMasterProductData, newData);
    setMasterProductData(newData);
    console.log("masterdata1");
    // setMasterProductData(previousData)
  };

  const handleImageEdit = (item) => {
    // setEditingItemId(item?.id);
    setReasonModalCheck(true);
    setInitialImage(item?.image_src);
    setImage({
      preview: item?.image_src,
      raw: "",
    });
  };

  const handleModalClose = () => {
    setReasonModalCheck(false);
    setImage({
      preview: "",
      raw: "",
    });
    setSaveChanges(false);
  };

  const imageSelect = (e, fieldName) => {
    if (e.target.files.length) {
      const selectedFile = e.target.files[0];

      // Check if file size is greater than 2MB
      if (selectedFile.size > 2 * 1024 * 1024) {
        toast.info("File size should not exceed 2MB");
        return;
      }

      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      setSaveChanges(true);
    }
    console.log("iamgesize", e.target.files[0]);
  };
  console.log("imagepreview", image?.preview, image, initialImage);

  const handleClick = () => {
    document.getElementById("imageInputSelect").click();
  };

  const handleNewImageClick = () => {
    document.getElementById("newImageInputSelect").click();
    setImageProgress("");
    setImageSizeError("");
  };

  const handleChangeImage = async (item, event) => {
    console.log("imageitem", item, image, event);

    // var newData = { ...modifiedNonFormData };
    if (image?.raw) {
      setIsLoading(true);
      toast.info("Image upload in progress");
      let selectedFile = image?.raw;
      let fileType = image?.raw?.type;
      // setImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0],
      // });
      console.log("imageitem1", selectedFile, fileType);
      let preSingnedUrl = ApiEndpoints.presignedUrlForMasterProductImage;

      try {
        let preSignedRes = await axios.get(
          `${preSingnedUrl}?contentType=${fileType}`
        );
        console.log("Data", preSignedRes);
        if (preSignedRes.status === 200) {
          let url = preSignedRes?.data?.data?.url;
          let ukey = preSignedRes?.data?.data?.key;
          let uContentKey = preSignedRes?.data?.data?.contentType;
          let res = await uploadFile(url, selectedFile);
          console.log("RES1", res);
          console.log("ULR", url, ukey);
          if (res.status === 200) {
            // setButtonDisabled(false);
            const editImageUrl = `${ApiEndpoints.editMasterProductImage}/${item?.id}`;
            const res = await axios.put(editImageUrl, {
              image_src: ukey,
              contentType: uContentKey,
              updated_by: localStorage.getItem("fullname"),
            });
            console.log("editImageUrl", editImageUrl);
            if (res.status === 200) {
              let d = JSON.parse(JSON.stringify(masterProductData));
              let newData = d.map((elm) => {
                if (elm?.id === item?.id) {
                  if (
                    elm?.image_src?.length &&
                    elm?.image_src?.includes(imageUrl?.url)
                  ) {
                    let arr = elm?.image_src.split("/");
                    arr[arr.length - 1] = `${ukey}.${uContentKey}`;
                    let newUrl = arr.join("/");
                    // elm.image_src = newUrl;
                    console.log("newurl", newUrl, arr);
                    if (previousData?.id == item?.id) {
                      return { ...previousData, image_src: newUrl };
                    } else {
                      return { ...elm, image_src: newUrl };
                    }
                  } else {
                    let newImageUrl = imageUrl?.url;

                    // let arr = newImageUrl.split("/");
                    let newUrl = `${newImageUrl}${ukey}.${uContentKey}`;
                    // let newUrl = arr.join("/");
                    // elm.image_src = newUrl;
                    console.log("newurl1", newUrl);
                    if (previousData?.id == item?.id) {
                      return { ...previousData, image_src: newUrl };
                    } else {
                      return { ...elm, image_src: newUrl };
                    }
                  }
                } else {
                  return elm;
                }
              });
              let updatedItem = newData.find((elm) => elm.id === item.id);
              if (updatedItem) {
                setPreviousData(updatedItem);
              }
              console.log("newupdateditem", updatedItem);
              console.log("imaged", newData, d);
              setMasterProductData(newData);
              console.log("masterdata2");
              toast.success("Image uploaded successfully");
              setSaveChanges(false);
              setEditMode(null);
              // setEditingItemId(null);
              setReasonModalCheck(false);
              setIsLoading(false);
              let action = "master-matrixify-product-image-edit";
              let type = "Info";
              let message =
                "Master matrixify product image successfully edited.";
              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);
            } else {
              toast.error("Image can't uploaded");
              setIsLoading(false);
              let action = "master-matrixify-product-image-edit";
              let type = "Error";
              let message = "Error in edit master matrixify product image.";
              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);
            }
          }
        } else {
          console.log("error");
          setIsLoading(false);
        }
      } catch (error) {
        // setButtonDisabled(false);
        console.error(error);
        setIsLoading(false);
      }
    } else {
      toast.info("Something Went Wrong");
      setIsLoading(false);
    }
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  const handleInputChange = (e, item) => {
    // setEditedValue(e.target.value);
    console.log("value", e.target.value, item);
    const { name, value } = e.target;

    console.log("value1", name, value);

    let updatedItem = { ...item, [name]: value };
    if (name === "title" || name === "option1_value") {
      updatedItem = {
        ...updatedItem,
        full_name: `${updatedItem?.title} ${updatedItem?.option1_value}`,
      };
    }

    console.log("updatedItem", updatedItem);

    setMasterProductData((prevData) =>
      prevData.map((prevItem) =>
        prevItem.id === item.id ? updatedItem : prevItem
      )
    );
    console.log("masterdata3");

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSwitchChange = (checked, id) => {
    console.log("check", checked, id);
    setAlcoholicChecked((prevChecked) => ({
      ...prevChecked,
      [id]: checked,
    }));
  };

  console.log("alcholiccheck", alcoholicChecked);

  const handleCreateInput = (e) => {
    const { name, value } = e.target;
    console.log("insertvalue", name, value, formData);

    if (e.target.tagName.toLowerCase() === "select") {
      const selectedOption = e.target.options[e.target.selectedIndex].value;
      if (
        e.target.name === "metafield_custom_category_single_line_text_field"
      ) {
        if (selectedOption === "") {
          formData.metafield_custom_category_single_line_text_field = "";
          return;
        } else {
          let tempFormdata = { ...formData };
          if (tempFormdata.tags.length > 0) {
            let existTags = categoryOptions.find((elm) =>
              tempFormdata.tags.toLowerCase().includes(elm.toLowerCase())
            );
            console.log("tags", existTags, selectedOption);
            if (existTags) {
              tempFormdata.tags = tempFormdata.tags.replace(
                existTags.toLowerCase(),
                selectedOption.toLowerCase()
              );
              tempFormdata[name] = selectedOption;
            } else {
              tempFormdata.tags =
                tempFormdata.tags + `, ${selectedOption.toLowerCase()}`;
              tempFormdata[name] = selectedOption;
            }
          } else {
            tempFormdata.tags = selectedOption.toLowerCase();
            tempFormdata[name] = selectedOption;
          }
          setFormData(tempFormdata);
          setCreateErrors((p) => ({
            ...p,
            [name]: "",
          }));
          return;
        }
      }
      setFormData({ ...formData, [name]: selectedOption });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setCreateErrors((p) => ({
      ...p,
      [name]: "",
    }));
  };

  console.log("formData", formData);

  const handleProductCreateImage = async (e, fieldName) => {
    if (e.target.files.length) {
      const selectedFile = e.target.files[0];
      let fileType = e.target.files[0].type;

      if (selectedFile.size > 2 * 1024 * 1024) {
        // toast.info("File size should not exceed 2MB");
        setImageSizeError(
          "Selected file size is larger than 2 MB,File size should not exceed 2MB"
        );
        return;
      }

      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      setImageProgress("Upload in progress...");
      // }

      // var newData = { ...modifiedNonFormData };
      // if (image?.raw) {
      //   setIsLoading(true);
      // toast.info("Image upload in progress");
      //   let selectedFile = image?.raw;
      //   let fileType = image?.raw?.type;
      // setImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0],
      // });
      console.log("imageitem1", selectedFile, fileType);
      let preSingnedUrl = ApiEndpoints.presignedUrlForMasterProductImage;

      try {
        let preSignedRes = await axios.get(
          `${preSingnedUrl}?contentType=${fileType}`
        );
        console.log("Data", preSignedRes);
        if (preSignedRes.status === 200) {
          let url = preSignedRes?.data?.data?.url;
          let ukey = preSignedRes?.data?.data?.key;
          let uContentKey = preSignedRes?.data?.data?.contentType;
          let res = await uploadFile(url, selectedFile);
          console.log("RES1", res);
          console.log("ULR", url, ukey);
          if (res.status === 200) {
            setFormData({
              ...formData,
              image_src: ukey,
              contentType: uContentKey,
            });
            setCreateErrors({
              image_src: "",
            });
            setImageProgress("Image Uploaded...");
            // toast.success("Image uploaded successfully");
            setIsLoading(false);
            let action = "master-matrixify-product-image-create";
            let type = "Info";
            let message =
              "Master matrixify product image successfully created.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          } else {
            toast.error("Image can't uploaded");
            setIsLoading(false);
            let action = "master-matrixify-product-image-create";
            let type = "Error";
            let message = "Error in create master matrixify product image.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          }
        } else {
          console.log("error");
          setIsLoading(false);
        }
      } catch (error) {
        // setButtonDisabled(false);
        console.error(error);
        setIsLoading(false);
      }
    } else {
      // toast.info("Something Went Wrong");
      setIsLoading(false);
    }
  };

  const handleProductModalCancel = () => {
    setAddProductModal(false);
    setCancelProductModal(false);
    setFormData({
      variant_barcode: "",
      title: "",
      option1_value: "",
      body_html: "",
      contentType: "",
      created_by: "",
      tags: "",
      variant_price: "",
      image_src: "",
      metafield_custom_category_single_line_text_field: "",
      metafield_custom_package_type_single_line_text_field: "",
      metafield_custom_brand_single_line_text_field: "",
      metafield_custom_alcohol_by_volume_single_line_text_field: "",
    });
    setCreateErrors({
      variant_barcode: "",
      title: "",
      option1_value: "",
      contentType: "",
      created_by: "",
      tags: "",
      variant_price: "",
      image_src: "",
      metafield_custom_category_single_line_text_field: "",
      metafield_custom_package_type_single_line_text_field: "",
      metafield_custom_brand_single_line_text_field: "",
      metafield_custom_alcohol_by_volume_single_line_text_field: "",
    });
    setImage({
      preview: "",
      raw: "",
    });
    setImageProgress("");
    setImageSizeError("");
  };

  const handleCreateProduct = async () => {
    console.log("formData1", formData);

    const errorCreate = {};

    if (formData?.title.trim() === "") {
      errorCreate.title = "Title is required";
    }

    if (formData?.option1_value.trim() === "") {
      errorCreate.option1_value = "Value is required";
    }

    if (formData?.tags.trim() === "") {
      errorCreate.tags = "Tags is required";
    }
    if (formData?.variant_barcode.trim() === "") {
      errorCreate.variant_barcode = "UPC is required";
    }

    if (formData?.image_src.trim() === "") {
      errorCreate.image_src = "Image is required";
    }

    if (
      formData?.metafield_custom_category_single_line_text_field.trim() === ""
    ) {
      errorCreate.metafield_custom_category_single_line_text_field =
        "Category is required";
    }
    if (
      formData?.metafield_custom_package_type_single_line_text_field.trim() ===
      ""
    ) {
      errorCreate.metafield_custom_package_type_single_line_text_field =
        "Package Type is required";
    }

    if (formData?.metafield_custom_brand_single_line_text_field.trim() === "") {
      errorCreate.metafield_custom_brand_single_line_text_field =
        "Brand is required";
    }

    if (
      formData?.metafield_custom_alcohol_by_volume_single_line_text_field.trim() ===
      ""
    ) {
      errorCreate.metafield_custom_alcohol_by_volume_single_line_text_field =
        "Alcohol by volume is required";
    }

    setCreateErrors(errorCreate);

    console.log("errorcreate", errorCreate);

    const reqBody = {
      variant_barcode: formData.variant_barcode,
      title: formData.title,
      option1_value: formData.option1_value,
      body_html: formData.body_html,
      created_by: localStorage.getItem("fullname"),
      contentType: formData.contentType,
      tags: formData.tags,
      variant_price: formData.variant_price,
      image_src: formData.image_src,
      metafield_custom_category_single_line_text_field:
        formData.metafield_custom_category_single_line_text_field,
      metafield_custom_package_type_single_line_text_field:
        formData.metafield_custom_package_type_single_line_text_field,
      metafield_custom_brand_single_line_text_field:
        formData.metafield_custom_brand_single_line_text_field,
      metafield_custom_alcoholic_single_line_text_field:
        formData.metafield_custom_alcoholic_single_line_text_field,
      metafield_custom_alcohol_by_volume_single_line_text_field:
        formData.metafield_custom_alcohol_by_volume_single_line_text_field,
      status: formData.status,
    };

    console.log("reqbody", reqBody);
    if (Object.keys(errorCreate).length === 0) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${ApiEndpoints.createMasterProduct}`,
          formData
        );
        if (response.status === 200) {
          toast.success("Product created successfully");
          getProductDetails(1);
          setImageProgress("");
          setAddProductModal(false);
          // setMasterProductData();
          setIsLoading(false);
          let action = "master-matrixify-product-create";
          let type = "Info";
          let message = "Master Matrixify Product successfully created.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong");
          console.error("Failed to create product");
        }
      } catch (error) {
        console.error("Error", error.response);
        if (error?.response?.data?.message === "Product already exists") {
          toast.error("Product already exists");
        } else {
          toast.error("Something went wrong");
        }
        setIsLoading(false);
        let action = "master-matrixify-product-create";
        let type = "Error";
        let message = "Error in create master matrixify product.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } else {
      toast.error("Please fill all required details");
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6
                      className={`fullcalendar-title h2 d-inline-block mb-0 ${styles.heading}`}
                    >
                      {"Master Menu"}
                    </h6>
                  </Col>
                  <Col className={styles.dwlndBtn} lg="6" xs="6">
                    {(userRoles.includes("1") ||
                      roleActions.includes("master_product_download")) && (
                      <a
                        href={ApiEndpoints.masterProductsListDownloadList}
                        download="MyExampleDoc"
                        // target="_blank"
                        onClick={() => {
                          // Function to refresh S3 bucket URL
                          refreshS3BucketURL();
                        }}
                      >
                        <Button
                          className={styles.buttonStyle02}
                          type="button"
                          size="sm"
                          color="default"
                        >
                          Export
                        </Button>
                      </a>
                    )}
                    {(userRoles.includes("1") ||
                      roleActions.includes("master_product_create")) && (
                      <Button
                        className={styles.buttonStyle02}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={toggleAddProductModal}
                      >
                        Add Product
                      </Button>
                    )}
                  </Col>
                </Row>
                <Modal
                  size="lg"
                  isOpen={addProductModal}
                  toggle={toggleAddProductModal}
                  backdrop="static"
                  centered
                >
                  <ModalHeader toggle={toggleCancelProductModal}>
                    Add Product
                  </ModalHeader>
                  <ModalBody>
                    <Row className={styles.image}>
                      <Col lg="4" className="text-center">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="newImageInput"
                          >
                            {image?.preview ? (
                              <>
                                <div>
                                  <h6>Max size to upload image is 2MB...</h6>
                                </div>
                                <div>
                                  <h5>{imageProgress}</h5>
                                  <h5 className={styles.imgSize}>
                                    {imageSizeError}
                                  </h5>
                                </div>
                                <div className={styles.modalImg}>
                                  <img
                                    src={image?.preview}
                                    alt={Bottle}
                                    className={styles?.img}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <h6>Max size to upload image is 2MB...</h6>
                                </div>
                                <div>
                                  <h5 className={styles.imgSize}>
                                    {imageSizeError}
                                  </h5>
                                </div>
                                <div>
                                  <h5>Click browse to select image...</h5>
                                </div>
                              </>
                            )}
                          </label>
                          <br />
                          <input
                            type="file"
                            id="newImageInputSelect"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleProductCreateImage(e, "image_src")
                            }
                            accept=".jpg, .jpeg, .jfif, .png"
                          />
                          <div className={styles.errorTag}>
                            {createErrors.image_src}
                          </div>
                          <Button
                            color="primary"
                            onClick={handleNewImageClick}
                            size="sm"
                          >
                            Browse
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className={styles.titleGap}>
                      <Col md="6">
                        <Label>Title</Label>
                        <Input
                          type="text"
                          name="title"
                          value={formData?.title}
                          placeholder="Insert title here..."
                          onChange={(e) => handleCreateInput(e)}
                        />
                        <div className={styles.errorTag}>
                          {createErrors.title}
                        </div>
                      </Col>
                      <Col md="6">
                        <Label>Size</Label>
                        <Input
                          type="text"
                          name="option1_value"
                          value={formData?.option1_value}
                          placeholder="Insert size here..."
                          onChange={(e) => handleCreateInput(e)}
                        />
                        <div className={styles.errorTag}>
                          {createErrors.option1_value}
                        </div>
                      </Col>
                    </Row>
                    <Row className={styles.titleGap}>
                      <Col md="6">
                        <Label>UPC</Label>
                        <Input
                          type="text"
                          name="variant_barcode"
                          value={formData?.variant_barcode}
                          placeholder="Insert upc here..."
                          onChange={(e) => handleCreateInput(e)}
                        />
                        <div className={styles.errorTag}>
                          {createErrors.variant_barcode}
                        </div>
                      </Col>
                      <Col md="6">
                        <Label className={styles.tagsLabel}>
                          Tags
                          <h6 className={styles.tagsLabelInfo}>
                            (Please enter name of the sub category along with
                            other tags)
                          </h6>
                        </Label>
                        <Input
                          type="text"
                          name="tags"
                          value={formData.tags}
                          placeholder="Insert tags here..."
                          onChange={(e) => handleCreateInput(e)}
                        />
                        <div className={styles.errorTag}>
                          {createErrors.tags}
                        </div>
                      </Col>
                    </Row>
                    <Row className={styles.titleGap}>
                      <Col md="6">
                        <Label>Alcohol by volume</Label>
                        <Input
                          name="metafield_custom_alcohol_by_volume_single_line_text_field"
                          type="number"
                          value={
                            formData?.metafield_custom_alcohol_by_volume_single_line_text_field
                          }
                          placeholder="Insert alcohol by volume here..."
                          onChange={(e) => handleCreateInput(e)}
                          min={0}
                        />
                        <div className={styles.errorTag}>
                          {
                            createErrors.metafield_custom_alcohol_by_volume_single_line_text_field
                          }
                        </div>
                      </Col>
                      <Col md="6">
                        <Label>Alcoholic</Label>
                        <Input
                          name="metafield_custom_alcoholic_single_line_text_field"
                          type="select"
                          value={
                            formData?.metafield_custom_alcoholic_single_line_text_field
                          }
                          onChange={(e) => handleCreateInput(e)}
                        >
                          <option value="TRUE">Yes</option>
                          <option value="FALSE">No</option>
                        </Input>
                      </Col>
                    </Row>
                    <Row className={styles.titleGap}>
                      <Col md="6">
                        <Label>Brand</Label>
                        <Input
                          name="metafield_custom_brand_single_line_text_field"
                          type="text"
                          value={
                            formData?.metafield_custom_brand_single_line_text_field
                          }
                          placeholder="Insert brand here..."
                          onChange={(e) => handleCreateInput(e)}
                        />
                        <div className={styles.errorTag}>
                          {
                            createErrors.metafield_custom_brand_single_line_text_field
                          }
                        </div>
                      </Col>
                      <Col md="6">
                        <Label>Category</Label>
                        <Input
                          name="metafield_custom_category_single_line_text_field"
                          type="select"
                          // value={
                          //   formData?.metafield_custom_category_single_line_text_field
                          // }
                          onChange={(e) => handleCreateInput(e)}
                        >
                          <option value="">Select</option>
                          <option value="Spirits">Spirits</option>
                          <option value="Beer">Beer</option>
                          <option value="Wine">Wine</option>
                          <option value="Mixers">Mixers</option>
                          <option value="Selters & RTD">Selters & RTD</option>
                        </Input>
                        <div className={styles.errorTag}>
                          {
                            createErrors.metafield_custom_category_single_line_text_field
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row className={styles.titleGap}>
                      <Col md="6">
                        <Label>Package type</Label>
                        <Input
                          name="metafield_custom_package_type_single_line_text_field"
                          type="select"
                          value={
                            formData?.metafield_custom_package_type_single_line_text_field
                          }
                          onChange={(e) => handleCreateInput(e)}
                        >
                          <option value="">Select</option>
                          <option value="Bottle">Bottle</option>
                          <option value="Can">Can</option>
                        </Input>
                        <div className={styles.errorTag}>
                          {
                            createErrors.metafield_custom_package_type_single_line_text_field
                          }
                        </div>
                      </Col>
                      <Col md="6">
                        <Label>Price</Label>
                        <Input
                          name="variant_price"
                          type="text"
                          value={formData?.variant_price}
                          placeholder="Insert price here..."
                          onChange={(e) => handleCreateInput(e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Label>Description</Label>
                        <Input
                          type="textarea"
                          name="body_html"
                          value={formData?.body_html}
                          placeholder="Insert description here... (Optional)"
                          onChange={(e) => handleCreateInput(e)}
                        />
                      </Col>
                      <Col md="6">
                        <Label>Status</Label>
                        <Input
                          name="status"
                          type="select"
                          value={formData?.status}
                          onChange={(e) => handleCreateInput(e)}
                        >
                          <option value="Active">Active</option>
                          <option value="Archived">In-active</option>
                        </Input>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handleCreateProduct}>
                      Add
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={toggleCancelProductModal}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  size="sm"
                  isOpen={cancelProductModal}
                  toggle={toggleCancelProductModal}
                  backdrop="static"
                  centered
                >
                  <ModalBody>
                    <h3>Are you sure you want to cancel</h3>
                    <br />
                    <h5>All fields will be discarded</h5>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handleProductModalCancel}>
                      Confirm
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={toggleCancelProductModal}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardHeader>

              <CardBody className={styles.masterProductsDataList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getProductDetails}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search..."
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getProductDetails}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search..."
                    dropDown={false}
                    orderList={false}
                  />
                )}
              </CardBody>

              <div className={styles.tableHeight}>
                <Table className="align-items-center table-flush">
                  <thead className={`thead-light ${styles.tableHeader}`}>
                    <tr>
                      <th className={styles.image}>Image</th>
                      <th>Title</th>
                      <th>UPC</th>
                      <th>Tags</th>
                      <th>Alcohol by Volume</th>
                      <th>Alcoholic</th>
                      <th>Brand</th>
                      <th>Category</th>
                      <th>Package Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {masterProductData && masterProductData?.length ? (
                      masterProductData?.map((item) => (
                        <tr key={item?.id}>
                          <td>
                            <div className={styles.imageCard}>
                              <div className={styles.image}>
                                {item?.image_src ? (
                                  <div className={styles.prodImg}>
                                    <img
                                      src={item?.image_src}
                                      alt={Bottle}
                                      width="100%"
                                      height="100%"
                                    />
                                  </div>
                                ) : (
                                  <div className={styles.prodImg}>
                                    <img
                                      src={Bottle}
                                      alt={Bottle}
                                      width="100%"
                                      height="100%"
                                    />
                                  </div>
                                )}
                              </div>
                              <div className={styles.editImg}>
                                {editMode === item?.id ? (
                                  <span>
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                      onClick={() => handleImageEdit(item)}
                                      style={{
                                        cursor: "pointer",
                                        color: "blue",
                                      }}
                                    ></i>
                                    <Modal
                                      isOpen={reasonModalCheck}
                                      fade={false}
                                      size="sm"
                                      centered
                                      backdrop="static"
                                    >
                                      <ModalBody>
                                        <div className={styles.uploadedImage}>
                                          <label
                                            className="form-control-label"
                                            htmlFor="upload-button"
                                          >
                                            {image?.preview ? (
                                              <>
                                                <div>
                                                  <h6>
                                                    Max size to upload image is
                                                    2MB...
                                                  </h6>
                                                </div>
                                                <div
                                                  className={styles.modalImg}
                                                >
                                                  <img
                                                    src={image?.preview}
                                                    alt={Bottle}
                                                    className={styles?.img}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {item?.image_src === "" ? (
                                                  <div
                                                    className={styles.infoText}
                                                  >
                                                    <h2>
                                                      Select Browse to upload
                                                      image...
                                                    </h2>
                                                    <h6>
                                                      Max size to upload image
                                                      is 2MB...
                                                    </h6>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <div>
                                                      <h6>
                                                        Max size to upload image
                                                        is 2MB...
                                                      </h6>
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.modalImg
                                                      }
                                                    >
                                                      <img
                                                        src={item?.image_src}
                                                        alt={Bottle}
                                                        className={styles?.img}
                                                      />
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </label>
                                        </div>
                                      </ModalBody>
                                      <ModalFooter>
                                        <input
                                          type="file"
                                          id="imageInputSelect"
                                          style={{ display: "none" }}
                                          onChange={(e) =>
                                            imageSelect(e, "image_src")
                                          }
                                          accept=".jpg, .jpeg, .jfif, .png"
                                        />
                                        <Button
                                          color="primary"
                                          onClick={handleClick}
                                        >
                                          Browse
                                        </Button>
                                        {saveChanges && (
                                          <Button
                                            color="primary"
                                            onClick={(event) => {
                                              handleChangeImage(item, event);
                                            }}
                                          >
                                            Save
                                          </Button>
                                        )}
                                        <Button
                                          color="secondary"
                                          onClick={handleModalClose}
                                        >
                                          Cancel
                                        </Button>
                                      </ModalFooter>
                                    </Modal>
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </td>

                          <td>
                            {editMode === item?.id ? (
                              <div className={styles.titleInput}>
                                <div className={styles.required}>
                                  <div style={{ marginTop: "15px" }}>
                                    <Input
                                      className={styles.title}
                                      type="text"
                                      name="title"
                                      value={item?.title}
                                      onChange={(e) =>
                                        handleInputChange(e, item)
                                      }
                                      // onBlur={() =>
                                      //   handleEdit(item?.id, editedValue)
                                      // }
                                    />
                                  </div>
                                  <span>{""}</span>
                                  <span className={styles.req}>* Required</span>
                                  <div className={styles.errorTag}>
                                    {errors.title}
                                  </div>
                                </div>
                                <div className={styles.required}>
                                  <div style={{ marginTop: "15px" }}>
                                    <Input
                                      className={styles.optionInput}
                                      type="text"
                                      name="option1_value"
                                      value={item?.option1_value}
                                      onChange={(e) =>
                                        handleInputChange(e, item)
                                      }
                                      // onBlur={() =>
                                      //   handleEdit(item?.id, editedValue)
                                      // }
                                    />
                                  </div>
                                  <span>{""}</span>
                                  <span className={styles.req}>* Required</span>
                                  <div className={styles.errorTag}>
                                    {errors.option1_value}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              item?.full_name
                            )}
                          </td>
                          <td>
                            {editMode === item?.id ? (
                              <>
                                <Input
                                  className={styles.barcode}
                                  type="text"
                                  name="variant_barcode"
                                  value={item?.variant_barcode}
                                  onChange={(e) => handleInputChange(e, item)}
                                  // onBlur={() =>
                                  //   handleEdit(item?.id, editedValue)
                                  // }
                                />
                              </>
                            ) : (
                              <div>
                                {item?.variant_barcode ? (
                                  item?.variant_barcode
                                ) : (
                                  <BsDashLg />
                                )}
                              </div>
                            )}
                          </td>
                          <td>
                            {editMode === item?.id ? (
                              <div className={styles.required}>
                                <div style={{ marginTop: "15px" }}>
                                  <Input
                                    type="text"
                                    name="tags"
                                    value={item?.tags}
                                    onChange={(e) => handleInputChange(e, item)}
                                    // onBlur={() =>
                                    //   handleEdit(item?.id, editedValue)
                                    // }
                                  />
                                  <span>{""}</span>
                                  <span className={styles.req}>* Required</span>
                                </div>
                                <div className={styles.errorTag}>
                                  {errors.tags}
                                </div>
                              </div>
                            ) : (
                              <div>
                                {item?.tags ? (
                                  item?.tags.toUpperCase()
                                ) : (
                                  <div className={styles.container}>
                                    <BsDashLg />
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                          <>
                            <td>
                              {editMode === item?.id ? (
                                <Input
                                  type="number"
                                  name="metafield_custom_alcohol_by_volume_single_line_text_field"
                                  value={
                                    item?.metafield_custom_alcohol_by_volume_single_line_text_field
                                  }
                                  onChange={(e) => handleInputChange(e, item)}
                                  // onBlur={() =>
                                  //   handleEdit(item?.id, editedValue)
                                  // }
                                  min={0}
                                />
                              ) : (
                                <div
                                  className={
                                    styles.metafield_custom_alcohol_by_volume_single_line_text_field
                                  }
                                >
                                  {item?.metafield_custom_alcohol_by_volume_single_line_text_field ? (
                                    item?.metafield_custom_alcohol_by_volume_single_line_text_field
                                  ) : (
                                    <BsDashLg />
                                  )}
                                </div>
                              )}
                            </td>
                          </>

                          <td>
                            <div className={styles.alcholic}>
                              <Switch
                                size="small"
                                id={
                                  item?.metafield_custom_alcoholic_single_line_text_field
                                }
                                name={
                                  item?.metafield_custom_alcoholic_single_line_text_field
                                }
                                checked={alcoholicChecked[item?.id] || false}
                                onChange={(checked) =>
                                  handleSwitchChange(checked, item.id)
                                }
                                disabled={editMode !== item?.id}
                              />
                            </div>
                          </td>

                          <>
                            <td>
                              {editMode === item?.id ? (
                                <Input
                                  className={styles.inputBox}
                                  type="text"
                                  name="metafield_custom_brand_single_line_text_field"
                                  value={
                                    item?.metafield_custom_brand_single_line_text_field
                                  }
                                  onChange={(e) => handleInputChange(e, item)}
                                  // onBlur={() =>
                                  //   handleEdit(item?.id, editedValue)
                                  // }
                                />
                              ) : (
                                <div>
                                  {item?.metafield_custom_brand_single_line_text_field ? (
                                    item?.metafield_custom_brand_single_line_text_field
                                  ) : (
                                    <BsDashLg />
                                  )}
                                </div>
                              )}
                            </td>

                            <td>
                              {editMode === item?.id ? (
                                <Input
                                  className={styles.inputBox}
                                  type="text"
                                  value={
                                    item?.metafield_custom_category_single_line_text_field
                                  }
                                  name="metafield_custom_category_single_line_text_field"
                                  onChange={(e) => handleInputChange(e, item)}
                                  // onBlur={() =>
                                  //   handleEdit(item.id, editedValue)
                                  // }
                                />
                              ) : (
                                <div>
                                  {item?.metafield_custom_category_single_line_text_field ? (
                                    item?.metafield_custom_category_single_line_text_field
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    item?.metafield_custom_category_single_line_text_field
                                      ?.slice(1)
                                      .toLowerCase()
                                  ) : (
                                    <BsDashLg />
                                  )}
                                </div>
                              )}
                            </td>

                            <td>
                              {editMode === item?.id ? (
                                <Input
                                  className={styles.inputBox}
                                  type="text"
                                  value={
                                    item?.metafield_custom_package_type_single_line_text_field
                                  }
                                  onChange={(e) => handleInputChange(e, item)}
                                  name="metafield_custom_package_type_single_line_text_field"
                                  // onBlur={() =>
                                  //   handleEdit(item.id, editedValue)
                                  // }
                                />
                              ) : (
                                <div>
                                  {item?.metafield_custom_package_type_single_line_text_field ? (
                                    item?.metafield_custom_package_type_single_line_text_field
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    item?.metafield_custom_package_type_single_line_text_field
                                      ?.slice(1)
                                      .toLowerCase()
                                  ) : (
                                    <BsDashLg />
                                  )}
                                </div>
                              )}
                            </td>
                          </>
                          <td>
                            {/* {item?.status ? (
                              item.status.toLowerCase() === "archived" ? (
                                "In-active"
                              ) : (
                                item.status.charAt(0).toUpperCase() +
                                item.status.slice(1).toLowerCase()
                              )
                            ) : (
                              <BsDashLg />
                            )} */}

                            {editMode === item?.id ? (
                              <Input
                                className={styles.status}
                                type="select"
                                value={item?.status}
                                onChange={(e) => handleInputChange(e, item)}
                                name="status"
                                // onBlur={() =>
                                //   handleEdit(item.id, editedValue)
                                // }
                              >
                                <option value="Active">Active</option>
                                <option value="Archived">In-active</option>
                              </Input>
                            ) : (
                              <div>
                                {item?.status ? (
                                  item.status.toLowerCase() === "archived" ? (
                                    "In-active"
                                  ) : (
                                    item.status.charAt(0).toUpperCase() +
                                    item.status.slice(1).toLowerCase()
                                  )
                                ) : (
                                  <BsDashLg />
                                )}
                              </div>
                            )}
                          </td>
                          <td>
                            {/* {editMode === item?.id ? ( */}
                            <>
                              <ButtonGroup
                                style={{
                                  display:
                                    editMode == item?.id ? "block" : "none",
                                }}
                              >
                                <Button
                                  className="btn-icon-only"
                                  color="success"
                                  id={"saveChange" + item?.id}
                                  onClick={() => handleEditSave(item)}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-save"></i>
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  target={"saveChange" + item?.id}
                                >
                                  Save Changes
                                </UncontrolledTooltip>
                                <Button
                                  style={{ marginLeft: "5px" }}
                                  className="btn-icon-only"
                                  color="danger"
                                  id={"cancelChange" + item?.id}
                                  onClick={() => handleEditCancel(item)}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-times"></i>
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  target={"cancelChange" + item?.id}
                                >
                                  Cancel Changes
                                </UncontrolledTooltip>
                              </ButtonGroup>
                            </>
                            {/* ) : ( */}
                            <ButtonGroup
                              style={{
                                display:
                                  editMode == item?.id ? "none" : "block",
                              }}
                            >
                              <Button
                                className="btn-icon-only"
                                color="info"
                                onClick={() => handleView(item?.id)}
                                id={"tooltipv" + item?.id}
                                type="button"
                              >
                                <span className="btn-inner--icon">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Button>
                              <UncontrolledTooltip
                                // delay={0}
                                target={"tooltipv" + item?.id}
                              >
                                View Product
                              </UncontrolledTooltip>
                              {(userRoles.includes("1") ||
                                roleActions.includes("tenants_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only left-Padding"
                                    color="success"
                                    onClick={() => handleEdit(item)}
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit Product
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </ButtonGroup>
                            {/* )} */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default MasterProductList;
