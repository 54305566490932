import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { logUserMetrics } from "../../../utils/file";

const ImportsControl = () => {
  //const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { item, permission } = location?.state;
  //console.log("id", id);
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");

  const [dataItem, setDataItem] = useState(item);

  console.log("ITEM", item);

  async function getImportAsJson() {
    try {
      setIsLoading(true);
      let importApi = `${ApiEndpoints.jsonDataImport}?id=${item.id}`;
      let res = await axios.get(importApi);
      console.log("TES", res);
      setData(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (Object.keys(dataItem).length > 0) {
      getImportAsJson();
    } else {
      history.push("/admin/inventory-controls");
    }
  }, []);

  console.log("DATA", data);

  const updateAprrovalStatus = (value) => {
    setApprovalStatus(value);
    if (value === "approve") {
      setConfirmModal(true);
    } else if (value === "reject") {
      setModal(true);
    }
  };

  const hanldeUpdateApproval = async () => {
    if (approvalStatus === "approve") {
      console.log("Approved api call");
      let approvalApi = `${ApiEndpoints.importApproval}`;
      let body = {
        id: dataItem.id,
        approved: "Approved",
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          setConfirmModal(false);
          setIsLoading(false);
          setDataItem({ ...dataItem, approved: "Approved" });
          let action = "approve-products";
          let type = "Info";
          let message = "Products approved successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        let action = "approve-products";
        let type = "Error";
        let message = "Error in Products approve.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } else if (approvalStatus === "reject") {
      console.log("Reject api call");
      let approvalApi = `${ApiEndpoints.importApproval}`;
      let body = {
        id: dataItem.id,
        approved: "Rejected",
        reason: reason,
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          setModal(false);
          setReasonModalCheck(false);
          setIsLoading(false);
          setDataItem({
            ...dataItem,
            approved: "Reject",
            reason: reason,
          });
          setReason("");
        } else {
          setIsLoading(false);
        }
        let action = "approve-products";
        let type = "Info";
        let message = "Products rejected successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } catch (error) {
        console.log(error);
        let action = "approve-products";
        let type = "Error";
        let message = "Error in Products reject.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    }
  };

  // const dropdownArr = ["Pending For Approval", "Approve", "Reject"];
  // const [dropdown, setDropdown] = useState(dropdownArr);

  // const selectedValue = (value) => {
  //   let index = dropdownArr.findIndex((item) => item === value);
  //   dropdownArr.splice(index, 1);
  //   dropdownArr.unshift(value);
  //   setDropdown(dropdownArr);
  // };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Imports"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                    <Button
                                        size='sm'
                                        color='primary'
                                        onClick={() => handlePage()}
                                    >List Quotation</Button>
                                </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                      >
                        Imported By
                      </label>
                      {/* <Input
                          id="example1cols1Input"
                          placeholder="Company Name"
                          type="CompanyName"
                          name="company_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_name}
                        /> */}
                      <div>{dataItem?.name}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                      >
                        Status
                      </label>
                      {/* <Input
                          id="example1cols1Input"
                          placeholder="Company Name"
                          type="CompanyName"
                          name="company_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_name}
                        /> */}
                      <div>{dataItem?.status}</div>
                    </FormGroup>
                  </Col>
                  {/* {permission && permission === "edit" && (
                    <> */}
                  <Col md="4" sm="4">
                    <label
                      className="form-control-label"
                      htmlFor="example1cols1Input"
                    >
                      Approval Status
                    </label>
                    {dataItem?.approved !== "Approved" &&
                    dataItem?.approved !== "Rejected" ? (
                      <FormGroup>
                        <Input
                          id="exampleFormControlSelect3"
                          type="select"
                          onChange={(e) => {
                            updateAprrovalStatus(e.target.value);
                          }}
                        >
                          <option value="">Pending for Approval</option>
                          <option value="approve">Approve</option>
                          <option value="reject">Reject</option>
                        </Input>
                      </FormGroup>
                    ) : (
                      <div>{dataItem?.approved}</div>
                    )}
                  </Col>
                  <Modal
                    isOpen={modal}
                    fade={false}
                    toggle={modalToggle}
                    backdrop="static"
                    centered
                  >
                    <ModalHeader toggle={modalToggle}>
                      Reason For Rejection
                    </ModalHeader>
                    <ModalBody>
                      {/* <Input
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option value={""}>Select</option>
                            {dispatcherStatus?.map((status) => (
                              <option
                                value={status}
                                selected={
                                  status === orderStatus ? "selected" : ""
                                }
                              >
                                {status}
                              </option>
                            ))}
                          </Input>
                          <br /> */}
                      <label>Reason</label>
                      <Input
                        type="text"
                        name="reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => reasonModalToggle()}
                        disabled={!reason ? true : false}
                      >
                        Update
                      </Button>
                      <Button color="secondary" onClick={modalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={confirmModal}
                    fade={false}
                    toggle={confirmModalToggle}
                    size="sm"
                    centered
                    backdrop="static"
                  >
                    <ModalBody>Are you sure you want to approve?</ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => hanldeUpdateApproval()}
                      >
                        Yes
                      </Button>
                      <Button color="secondary" onClick={confirmModalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={reasonModalCheck}
                    fade={false}
                    toggle={reasonModalToggle}
                    size="sm"
                    centered
                    backdrop="static"
                  >
                    <ModalBody>Are you sure you want to reject?</ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => hanldeUpdateApproval()}
                      >
                        Yes
                      </Button>
                      <Button color="secondary" onClick={reasonModalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* </> */}
                  {/* )} */}
                </Row>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Imported On
                      </label>
                      {/* <Input
                          id="example3cols1Input"
                          placeholder="Lastname"
                          type="Lastname"
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                        /> */}
                      <div>
                        {moment(dataItem?.created_at).format("MM/DD/YYYY")}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        No of Item(s)
                      </label>
                      {/* <Input
                          id="example3cols1Input"
                          placeholder="Lastname"
                          type="Lastname"
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                        /> */}
                      <div>{dataItem?.no_of_item}</div>
                    </FormGroup>
                  </Col>
                  {/* <Col md="6" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example4cols1Input"
                        >
                          Email
                        </label>
                        <Input
                          id="example4cols1Input"
                          placeholder="Email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </FormGroup>
                    </Col> */}
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                      >
                        {dataItem?.reason ? "Reason" : ""}
                      </label>
                      {/* <Input
                          id="example1cols1Input"
                          placeholder="Company Name"
                          type="CompanyName"
                          name="company_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_name}
                        /> */}
                      <div>{dataItem?.reason}</div>
                    </FormGroup>
                  </div>
                </Row>

                {/* <Row>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example11cols2Input"
                        >
                          Phone
                        </label>
                        <Input
                          id="example11cols2Input"
                          placeholder="Phone"
                          type="phone"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlTextarea2"
                        >
                          Address
                        </label>
                        <Input
                          id="exampleFormControlTextarea2"
                          rows="3"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                {/* <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example12cols2Input"
                        >
                          Select Product
                        </label>
                        <ReactSearchAutocomplete
                          items={items}
                          onSearch={handleOnSearch}
                          onHover={handleOnHover}
                          onSelect={handleOnSelect}
                          onFocus={handleOnFocus}
                          autoFocus
                          formatResult={formatResult}
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                <Row>
                  <Table
                    className="table"
                    bordered
                    hover
                    responsive
                    style={{ border: "1px solid #e9ecef" }}
                  >
                    <thead>
                      <tr>
                        <th>Sku</th>
                        {/* <th>Inventory Id</th>
                        <th>Location Id</th> */}
                        <th>Name</th>
                        <th>LS Qty</th>
                        <th>New Qty</th>
                        <th>Price</th>
                        {/* <th>Reason</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <tr key={key} id={key}>
                            <td>{item["Sku"]}</td>

                            {/* <td>{item["Inventory Item ID"]}</td>
                            <td>{item["Location ID"]}</td> */}
                            <td>{item["Item Name"]}</td>
                            <td>{item["LS Qty"]}</td>
                            <td>{item["New Qty"]}</td>
                            <td>{item["Price"]}</td>
                            {/* <td></td> */}
                            {/* <td>{item["Sku"]}</td> */}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Row>
                {/* <Row>
                    <FormGroup>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>

                      <Button color="secondary" type="button">
                        Send Invoice
                      </Button>
                      <Button color="info" type="button">
                        Convert To Order
                      </Button>
                    </FormGroup>
                  </Row> */}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ImportsControl;
