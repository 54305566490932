import React, { useState, useEffect, useRef } from "react";
import { Field, Formik, useField } from "formik";
import { useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Upload } from "react-bootstrap-icons";
import "./style.css";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import CustomInput from "../../components/CustomInput.js";
import styles from "./firebaseNotification.module.css";
import { LuPlusCircle } from "react-icons/lu";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function NotificationDetail({
  values,
  setFieldValue,
  errors,
  touched,
  handleNext,
  setNotificationData,
  handleChangeImage,
}) {
  //   {
  //   notificationTitle,
  //   notificationText,
  //   notificationImage,
  //   setNotificationTitle,
  //   setNotificationText,
  //   setNotificationImage,
  // }
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationImage, setNotificationImage] = useState("");
  const [selectedTab, setSelectedTab] = useState("InitialState");
  const [notificationName, setNotificationName] = useState("");
  const [testModal, setTestModal] = useState(false);
  const fileInputRef = useRef(null);

  //   let appliesRef = useRef();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNotificationImage(file);
    }
    handleChangeImage(event, "img");
  };

  const handleUploadButtonClick = () => {
    console.log("button ci", fileInputRef);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  // const handleFileUpload = async (e) => {
  //   setIsLoading(true);

  //   try {
  //     let preSignedUrl = ApiEndpoints.menuPresignedUrl;

  //     let preSignedRes = await axios.get(preSignedUrl);
  //     if (preSignedRes.status === 200) {
  //       let ukey = preSignedRes?.data?.data?.key;
  //       let url = preSignedRes?.data?.data?.url;

  //       console.log("URL", url, ukey);

  //       let res = await uploadFile(url, file);
  //       console.log("RES1", res);

  //       if (res.status === 200) {
  //         let data = {
  //           key: ukey,
  //           // over_write: isCheckedList,
  //           // file_name: file.name,
  //         };
  //         let importRes = await axios.post(
  //           `${
  //             ApiEndpoints.createTransactionProduct
  //           }?location_id=${locationId}&name=${localStorage.getItem("Name")}`,
  //           data
  //         );

  //         if (importRes.status === 200) {
  //           setIsLoading(false);
  //           setIsImport(true);
  //           setImportStatus("Successfull");
  //           setFile("");
  //           setModal(false);
  //           toast.success("Your Request is Successfully Submitted");
  //           let action = "import-products";
  //           let type = "Info";
  //           let message = "Menu Transaction imported successfully.";
  //           const apiResponse = await logUserMetrics(action, type, message);
  //           console.log("apiirresponse", apiResponse);
  //         } else {
  //           setIsLoading(false);
  //           setIsImport(false);
  //           setFile("");
  //           setModal(false);
  //           setImportStatus("Failed");
  //           toast.error("Your Request is Failed");
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log("Err", error, error.response?.data?.message);
  //     if (error.message) {
  //       setIsLoading(false);
  //       alert(error.data.message);
  //       setModal(false);
  //       console.log("messagemessage", error.message);
  //       toast.error(error.response?.data?.message);
  //       let action = "import-products";
  //       let type = "Error";
  //       let message = "Error in Menu Transaction import.";
  //       const apiResponse = await logUserMetrics(action, type, message);
  //       console.log("apiirresponse", apiResponse);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (notificationTitle) {
  //     setNotificationData((p) => ({ ...p, title: notificationTitle }));
  //     //setFieldValue("title", notificationTitle);
  //   }
  //   if (notificationText) {
  //     setNotificationData((p) => ({ ...p, message: notificationText }));
  //     //setFieldValue("message", notificationText);
  //   }
  //   if (notificationImage) {
  //     setNotificationData((p) => ({ ...p, image: notificationImage }));
  //     // setFieldValue("img", notificationImage);
  //   }
  //   if (notificationName) {
  //     setNotificationData((p) => ({ ...p, name: notificationName }));
  //     //setFieldValue("name", notificationName);
  //   }
  // }, [
  //   notificationTitle,
  //   notificationText,
  //   notificationImage,
  //   notificationName,
  // ]);

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            {/* {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )} */}

            <Row>
              <Col lg="12" className="display">
                <Col lg="12">
                  <FormGroup>
                    {/* <Card color="primary" outline> */}

                    <Label>Notification Title</Label>

                    <CustomInput
                      name="title"
                      type="text"
                      placeholder="Enter notification title (optional)"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Notification Text</Label>
                    <CustomInput
                      name="message"
                      type="textarea"
                      placeholder="Enter notification text"
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>Notification Image (optional)</Label>
                    <InputGroup>
                      <Input
                        //className="input-box"
                        type="text"
                        value={notificationImage ? notificationImage.name : ""}
                        placeholder="Example: https://yourapp.com/image.png"
                        //name="img"
                      />
                      <Button
                        size="sm"
                        style={{
                          cursor: "pointer",
                          marginTop: "0px",
                          height: "calc(1.5em + 1.25rem + 5px)",
                        }}
                        onClick={handleUploadButtonClick}
                      >
                        <Upload />
                      </Button>

                      <input
                        type="file"
                        accept=".png"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        name="img"
                      />
                      
                    </InputGroup>
                  </FormGroup> */}
                  {/* <FormGroup>
                    <Label>Notification Name (optional)</Label>
                    <CustomInput
                      name="name"
                      type="textarea"
                      placeholder="Enter optional name"
                    /> */}
                  {/* <Input
                      //className="input-box"

                      value={notificationName}
                      onChange={(e) => setNotificationName(e.target.value)}
                    /> */}
                  {/* </FormGroup> */}

                  {/* </Card> */}
                </Col>
                {/* <Col lg="8">
                  <FormGroup>
                    <Card color="primary" outline>
                      <CardHeader>
                        <CardTitle tag="h4">Device Preview</CardTitle>
                        <CardText tag="h5">
                          This preview provides a general idea of how your
                          message will appear on a mobile device. Actual message
                          rendering will vary depending on the device. Test with
                          a real device for actual results.
                        </CardText>
                      
                      </CardHeader>
                      <CardBody>
                      

                        <div className="android-container">
                          <div className="phone">
                            <div className="android-speaker" />
                          </div>
                          <div className="phone">
                            <div className="android-camera" />
                          </div>
                          <div
                            className={
                              selectedTab === "InitialState"
                                ? "android-screen-initial"
                                : "android-screen-expand"
                            }
                          >
                            <div style={{ textAlign: "left" }}>
                              <h5>
                                {values.title
                                  ? values.title
                                  : notificationTitle || "Notification Title"}
                              </h5>
                              <h5>
                                {values.message
                                  ? values.message
                                  : notificationText || "Notification Text"}
                              </h5>
                            </div>
                           
                          </div>
                        </div>
                        <br />
                        <div className="phone-name">Android</div>
                        <br />
                        <div className="apple-container">
                          <div className="phone">
                            <div className="apple-notch" />
                          </div>
                          <div className="apple-design">
                            <div className="apple-speaker" />
                            <div className="apple-camera" />
                          </div>
                          <div
                            className={
                              selectedTab === "InitialState"
                                ? "apple-screen-initial"
                                : "apple-screen-expand"
                            }
                          >
                            {selectedTab === "InitialState" ? (
                              <>
                                <div>
                                  <h5>
                                    {values.title
                                      ? values.title
                                      : notificationTitle ||
                                        "Notification Title"}
                                  </h5>
                                  <h5>
                                    {values.message
                                      ? values.message
                                      : notificationText || "Notification Text"}
                                  </h5>
                                </div>
                                
                              </>
                            ) : (
                              <>
                              
                                <div>
                                  <h5>
                                    {values.title
                                      ? values.title
                                      : notificationTitle ||
                                        "Notification Title"}
                                  </h5>
                                  <h5>
                                    {values.message
                                      ? values.message
                                      : notificationText || "Notification Text"}
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <br />
                        <div className="phone-name">Apple</div>
                        <br />
                      </CardBody>
                    </Card>
                  </FormGroup>
                </Col> */}
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
      <TestModal testModal={testModal} setTestModal={setTestModal} />
    </>
  );
}
export default NotificationDetail;

// const CustomInput = ({ ...props }) => {
//   const [field, meta, helpers] = useField(props);
//   console.log("Fields", field, meta, helpers);
//   return (
//     <Input
//       // className="input-box"
//       // value={notificationTitle}
//       // onChange={(e) => {
//       //   setNotificationTitle(e.target.value);
//       //   setFieldValue("title", e.target.value);
//       // }}
//       {...field}
//       //onChange={(e) => form.setFieldValue("title", e.target.value)}
//       {...props}
//     />
//   );
// };

const TestModal = ({ testModal, setTestModal }) => {
  const [tokens, setTokens] = useState([]);
  //console.log("modal data", data, formikProps);
  // const handleFormSubmit = () => {
  //   formikProps.submitForm();
  //   setOpen(false);
  // };
  const handleAdd = () => {
    let tempArr = [...tokens];
  };
  return (
    <Modal
      isOpen={testModal}
      centered
      // style={{ padding: 0 }}
      backdrop="static"
      size="lg"
    >
      <ModalHeader>
        <Row>
          <Col>
            <h2 className={styles.mb}>Test on device</h2>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Row className={styles.gap}>
              <Col>
                <p className={styles.mb}>
                  You can test this campaign by entering or selecting the FCM
                  registration tokens of your development device below.
                </p>
              </Col>
            </Row>
            <Row className={styles.alignCenter}>
              <Col>
                <InputGroup>
                  <Input
                    style={{ border: "none", outline: "none" }}
                    placeholder="Add an FCM registration token"
                  />

                  <InputGroupText style={{ border: "none" }}>
                    <LuPlusCircle size={"1.5em"} color="#5e72e4" />
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <hr style={{ margin: "1rem", marginLeft: 0, marginTop: 0 }} />
        <div>
          {tokens?.length > 0 ? (
            tokens.map((token, i) => (
              <div
                key={i}
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: 10,
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  padding: "10px 15px 10px 15px",
                }}
              >
                <input
                  type="checkbox"
                  // checked={selectedListData.some(
                  //   (selectedItem) => selectedItem.id === item.id
                  // )}
                  // onChange={() => handleCheckboxChange(item)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ marginBottom: 0, fontSize: "13px" }}>{token}</p>
                  {/* {item?.price && (
                <p style={{ marginBottom: 0, fontSize: "13px" }}>
                  ${item?.price}
                </p>
              )} */}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">No test devices configured</div>
          )}
        </div>

        {/* <Row>
          <Col>
            <Row className={styles.gap}>
              <Col>
                <h4 className={styles.mb}>Target</h4>
              </Col>
            </Row>
            <Row className={styles.alignCenter}>
              <Col lg="auto pr-0">
                <div className="circle">
                  <FiTarget size={"1.5em"} />
                </div>
              </Col>
              <Col>
                <h5 style={{ marginBottom: "0" }}>
                  User segment matching one targeting criterion
                </h5>
              </Col>
            </Row>
          </Col>
        </Row> */}

        <hr style={{ margin: "1rem", marginLeft: 0 }} />

        {/* <Row>
          <Col>
            <Row className={styles.gap}>
              <Col>
                <h4 className={styles.mb}>Scheduling</h4>
              </Col>
            </Row>
            <Row className={styles.alignCenter}>
              <Col lg="auto pr-0">
                <div className="circle">
                  <IoIosCalendar size={"1.5em"} />
                </div>
              </Col>
              <Col>
                <h5 className={styles.mb}>Send now</h5>
              </Col>
            </Row>
          </Col>
        </Row> */}
        {/* <hr style={{ margin: "1rem" }} /> */}
      </ModalBody>

      <ModalFooter>
        <Button size="sm" onClick={() => setTestModal(false)}>
          Cancel
        </Button>
        <Button
          size="sm"
          color="primary"
          // className="btn btn-primary"
          //color="primary"
          type="submit"
          //onClick={handleFormSubmit}
        >
          Test
        </Button>
      </ModalFooter>
    </Modal>
  );
};
