import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  CustomInput,
} from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/shopifyProductsList.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file";
import TransactionData from "./transactionData";
import HeadersData from "./headerData";
import { ApiEndpoints } from "../../constants/constant";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const TransactionsList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);

  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState("order_date");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationList, setAllLocationList] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("DoorDash");
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isIncExcVisible, setIsIncExcVisible] = useState(false);
  // const [editedItem, setEditedItem] = useState(data);
  const [transactionsData, setTransactionsData] = useState([]);
  const [selectedPaName, setSelectedPaName] = useState("doordash");
  const [selectedPlatform, setSelectedPlatform] = useState("doordash");
  const [isEditable, setIsEditable] = useState(false);
  const [editedItem, setEditedItem] = useState({});
  const [initialItem, setInitialItem] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  let location_name = localStorage.getItem("selected_location_name");
  let encodedLocationName = encodeURIComponent(location_name);
  let encodedLocationNameForErenous =
    encodeURIComponent(location_name).toLowerCase();

  const editTransationData = async (item, paName) => {
    console.log("abcded", item);
    try {
      let requestBody = {};
      if (
        (paName.toLowerCase() == "doordash",
        "ubereats",
        "grubhub",
        "liquorsplit",
        "instacart",
        "drizly")
      ) {
        requestBody = {
          sourceTable: paName,
          paName,
          sourceRecordId: item.record_id,
          subtotal: item.subtotal,
          tax_subtotal: item.tax_subtotal,
          commission: item.commission,
          credit: item.credit,
          debit: item.debit,
          exclude: item.exclude,
          marketplace_fee: item.marketplace_fee,
          adjustments: item.adjustments,
        };
      }

      console.log("requestBody", requestBody);
      // console.log("requestBody", JSON.stringify(requestBody));
      setIsLoading(true);
      const response = await fetch(`${ApiEndpoints.editPAWiseTransactions}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi",
        },
        body: JSON.stringify(requestBody),
      });
      console.log("response", response);
      if (response?.status == 200) {
        toast.success("Updated Successfully");
        let action = "transactions-save";
        let type = "Info";
        let message = "transaction successfully save.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("api-response-metrics-transaction-save", apiResponse);
      }
      setIsLoading(false);
      getTransactionsData(page, startDate, endDate, selectedPaName);
      setIsEditable(false);
    } catch (error) {
      console.error("API Error", error);
      setIsLoading(false);
      let action = "transactions-save";
      let type = "Error";
      let message = "Error in transaction save.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("api-response-transaction-save-error-metrics", apiResponse);
    }
  };

  const handleEdit = (item) => {
    setIsEditable(true);
    setEditedItem({ ...item });
    setInitialItem({ ...item });
  };

  const handleInputChange = (key, value, record_id, isEditable) => {
    if (!isEditable) {
      return;
    }

    console.log("tempDAta item", key, value);
    // setEditedItem((prevValues) => ({
    //   ...prevValues,
    //   [key]: value,
    // }));
    value = value.replace(/[^0-9.+\\-]/g, "");
    // if (value.includes("$")) {
    //   value = value?.split("$")[1];
    // }

    const tempData = [...transactionsData];
    console.log("tempDAta", tempData);
    const res = tempData.map((item) => {
      if (item.record_id == record_id) {
        return { ...item, [key]: value };
      } else {
        return item;
      }
    });

    console.log("tempDAta res", res);
    setTransactionsData(res);
  };

  const saveChanges = async (item, paName) => {
    console.log("krishna 1", item, paName);
    setIsEditable(false);
    await editTransationData(item, paName);
  };

  const cancelChanges = (item) => {
    const tempTransactionData = JSON.parse(JSON.stringify(transactionsData));
    console.log("tempTransactionData", tempTransactionData, item, initialItem);
    tempTransactionData.map((ele) => {
      if (ele?.record_id === initialItem?.record_id) {
        ele.exclude = initialItem.exclude;
      }
    });

    const updatedTransactionData = tempTransactionData.map((item) =>
      item.record_id === initialItem.record_id ? initialItem : item
    );
    // Add logic here to cancel changes
    setIsEditable(false);
    setTransactionsData(updatedTransactionData);
    // setEditedItem({ ...initialItem });
  };

  console.log("iseditable", isEditable);

  console.log("initialitem", initialItem);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };
  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
  };

  const SortOptions = [{ id: "order_date", text: "Payout Date" }];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const getTransactionsData = (pageNum, startDate, endDate, selectedPaName) => {
    const config = {
      method: "GET",
      headers: { "x-api-key": "N8yXBdNkYC17gVxWDEGjU9ZQs7FAHY0Lw58YLVwi" },
    };
    setIsLoading(true);

    let url;
    if (isChecked === true) {
      url = `${ApiEndpoints.getErroneousPATransactions}?startDate=${startDate}&endDate=${endDate}&locationName=${encodedLocationNameForErenous}&paName=${selectedPaName}&pageNo=${pageNum}&pageSize=10`;
    } else {
      url = `${ApiEndpoints.getPAWiseTransactions}?startDate=${startDate}&endDate=${endDate}&locationName=${encodedLocationName}&paName=${selectedPaName}&pageNo=${pageNum}&pageSize=10`;
    }

    if (selectedSortOption) {
      url = url + `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (searchKeyword.length > 0) {
      url = url + `&search=${searchKeyword}`;
    }

    fetch(url, config)
      .then((response) => response.json())
      .then((res) => {
        console.log("API Response", res);
        const responseData = isChecked ? res?.body : res?.body?.response;
        const responsePagination = res?.body?.pagination;
        console.log(responsePagination?.nextPage, "responsePaginationnn");
        if (responseData !== undefined && responseData !== "undefined") {
          console.log("transactionsData123", responseData, "11111");

          if (responseData.length > 0) {
            const updatedTransactionList =
              pageNum === 1 ? responseData : [...responseData];
            const mergedTransactionList = mergeAndSortTransactionList(
              updatedTransactionList
            );
            setTransactionsData(mergedTransactionList);

            if (responsePagination?.nextPage == "true") {
              setLoadMoreEnabled(true);
            } else {
              setLoadMoreEnabled(false);
            }
          } else {
            console.log("page456", page);
            if (pageNum === 1) {
              setTransactionsData(responseData);
            }
            if (responsePagination?.nextPage == "true") {
              setLoadMoreEnabled(true);
            } else {
              setLoadMoreEnabled(false);
            }
          }
        } else {
          const responseData = res?.message;
          console.log("responseData456", responseData);
          console.log("1234567890");
          setTransactionsData(responseData);
          // setTransactionsData(responseData);
          setLoadMoreEnabled(false);
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("API Error", err);
      });
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null && selectedSortOption) {
      getTransactionsData(page, startDate, endDate, selectedPaName);
    }
  }, [
    startDate,
    endDate,
    selectedPaName,
    selectedSortOption,
    sortDirection,
    isChecked,
  ]);

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getTransactionsData(pageNum, startDate, endDate, selectedPaName);
  };

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getTransactionsData(page, startDate, endDate, selectedPaName);
    } else if (searchKeyword === "") {
      getTransactionsData(page, startDate, endDate, selectedPaName);
    }
  }, [searchKeyword]);

  const mergeAndSortTransactionList = (mergedBlackList) => {
    if (selectedSortOption) {
      mergedBlackList.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedBlackList;
  };

  const selectStatus = (status) => {
    setPage(1);
    setSelectedStatus(status);
    // Convert status to lowercase and set it as selectedPaName
    setSelectedPaName(status.toLowerCase());
    setIsEditable(false);
    // getTransactionsData(startDate, endDate, status.toLowerCase());
  };

  console.log("transactionsData", transactionsData);

  let btns = [
    "DoorDash",
    "GrubHub",
    "LiquorSplit",
    "UberEats",
    "InstaCart",
    "Drizly",
  ];

  const handleCreate = () => {
    history.push("/admin/blacklist-create");
  };

  // const handleEdit = (item) => {
  //   history.push(`/admin/blacklist-edit/${item.id}`);
  // };

  const handleView = (item) => {
    history.push(`/admin/blacklist-view/${item.id}`);
  };

  const handleClear = async () => {
    setPage(1);
    setSelectedPaName("doordash");
    setSearchKeyword("");
    setSortDirection("ASC");
    setSelectedSortOption("order_date");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getTransactionsData(1, startDate, endDate, selectedPaName);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("ASC");
    setSelectedSortOption("order_date");
    setClear(true);
    setShowCard(false);
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  useEffect(() => {
    const currentDate = moment(); // Get the current date
    const startOfPreviousWeek = currentDate
      .clone()
      .subtract(1, "week")
      .startOf("week"); // Start of the previous week (Sunday)
    const endOfPreviousWeek = startOfPreviousWeek.clone().endOf("week"); // End of the previous week (Saturday)

    setStartDate(startOfPreviousWeek.format("YYYY-MM-DD")); // Convert to string format
    // setStartDate("2024-02-18");
    setEndDate(endOfPreviousWeek.format("YYYY-MM-DD")); // Convert to string format
    // setEndDate("2024-02-24");
  }, []);

  console.log("startandenddate", startDate, endDate);

  const handleDatesChange = ({ startDate, endDate }) => {
    if (startDate) {
      setStartDate(startDate.format("YYYY-MM-DD")); // Convert to string format
    }
    if (endDate) {
      setEndDate(endDate.format("YYYY-MM-DD")); // Convert to string format
    }
  };

  const inclexcludeEdit = (e, id) => {
    console.log("A========>", e.target.checked);
    let tempTransactionData = JSON.parse(JSON.stringify(transactionsData));
    let value = tempTransactionData.find((x) => x.record_id == id);
    value.exclude = e.target.checked;

    setTransactionsData(tempTransactionData);
  };
  console.log(isChecked, "isChecked");
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6 className={styles.transaction_text}>
                      {"Transactions"}
                    </h6>
                  </Col>
                  <Col className={styles.transactions_calender} lg="6" xs="8">
                    <div className={styles.transactions_calender1}>
                      <DateRangePicker
                        startDate={moment(startDate)} // Convert to Moment.js object
                        startDateId="start_date_id"
                        endDate={moment(endDate)} // Convert to Moment.js object
                        endDateId="end_date_id"
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={setFocusedInput}
                        isOutsideRange={() => false} // Allow selecting dates in the past
                        numberOfMonths={1} // Show only one month
                        monthFormat="MMM YYYY" // Month format
                        displayFormat="MM/DD/YYYY" // Display format for the selected date
                        showClearDates={false} // Show the clear button
                        minimumNights={0} // Minimum number of nights required for a range
                        startDatePlaceholderText="Start Date" // Placeholder for start date input
                        endDatePlaceholderText="End Date" // Placeholder for end date input
                        showDefaultInputIcon={true}
                        enableOutsideDays={true}
                        small={true}
                      />
                    </div>
                    {/* {(userRoles.includes("1") ||
                      roleActions.includes("report_download")) && (
                      <a
                        // href="https://liquorsplit-dev.s3.amazonaws.com/partner-app-client-reports/12th%20&%20Pine%20Wine%20&%20Spirits%20&%20Cigars%20Cash%20Remittance%20thru%2002-24-2024.pdf"
                        href={reportDataPdf}
                        download="MyExampleDoc"
                        target="_blank"
                      >
                        <Button
                          className={styles.buttonStyle}
                          type="button"
                          size="sm"
                          color="default"
                        >
                          Download
                        </Button>
                      </a>
                    )} */}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className={styles.productList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    setIsChecked={setIsChecked}
                    isChecked={isChecked}
                    getDetail={() =>
                      getTransactionsData(1, startDate, endDate, selectedPaName)
                    }
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search..."
                    dropDown={false}
                    orderList={false}
                    screenName="Transaction"
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={() =>
                      getTransactionsData(1, startDate, endDate, selectedPaName)
                    }
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search..."
                    dropDown={false}
                    orderList={false}
                    screenName="Transaction"
                    setIsChecked={setIsChecked}
                    isChecked={isChecked}
                    // fcm={true}
                    // fcmSearchBtn={fcmSearchBtn}
                    // fcmInputSearch={fcmInputSearch}
                    search={search}
                  />
                )}
              </CardBody>

              <div style={{ height: "550px", overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <HeadersData
                    transactionsData={transactionsData}
                    selectedPaName={selectedPaName}
                  />
                  <tbody className="list">
                    {isLoading ? (
                      <tr>
                        <td colSpan="6">Loading...</td>
                      </tr>
                    ) : transactionsData &&
                      Array.isArray(transactionsData) &&
                      transactionsData.length > 0 ? (
                      transactionsData &&
                      transactionsData.map((item, index) => {
                        console.log(item, "item=======================>>>>>>>");
                        return (
                          <TransactionData
                            key={index}
                            item={item}
                            paName={selectedPaName}
                            isEditable={isEditable}
                            handleEdit={() => handleEdit(item)}
                            handleInputChange={handleInputChange}
                            saveChanges={saveChanges}
                            cancelChanges={cancelChanges}
                            editedItem={editedItem}
                            inclexcludeEdit={inclexcludeEdit}
                            isChecked={isChecked}
                            setIsLoading={setIsLoading}
                            getTransactionsData={getTransactionsData}
                            page={page}
                            startDate={startDate}
                            endDate={endDate}
                            userRoles={userRoles}
                            roleActions={roleActions}
                          />
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No data found...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              {/* <div style={{ height: 550, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item) => (
                        <th scope="col">{item.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {productData && productData?.length ? (
                      productData?.map((item) => (
                        <tr>
                          <td>
                            {item.images && item.images.length > 0 ? (
                              <img
                                src={item.images[0].src}
                                alt="Image Description"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            ) : (
                              <img
                                src={Logo}
                                alt="Placeholder Image"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            )}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.variants.length}</td>
                          <td>
                            {item.status && (
                              <span>
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1).toLowerCase()}
                              </span>
                            )}
                          </td>                         

                          <td>
                            <ButtonGroup>                            
                              {(userRoles.includes("1") ||
                                roleActions.includes("product_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only "
                                    color="success"
                                    onClick={() =>
                                      history.push(
                                        `/admin/products/edit/${item.id}`,
                                        {
                                          item: item,
                                          permission: "edit",
                                        }
                                      )
                                    }
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit Product
                                  </UncontrolledTooltip>
                                </>
                              )}                              
                            </ButtonGroup>
                           
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div> */}
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default TransactionsList;
