import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  UncontrolledAlert,
  Input,
  Container,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter, useParams } from "react-router-dom";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { logUserMetrics } from "../../../utils/file.js";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BlackListUsersView = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const [formData, setFormData] = React.useState({
  //   zip_codes: [
  //     {
  //       code: "",
  //       priority: "",
  //     },
  //   ],
  // });
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [userData, setUserData] = useState([]);

  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  let { id } = useParams();

  //   const getTenantsList = () => {
  //     setIsLoading(true);
  //     let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
  //     fetch(getTenantsApi)
  //       .then((response) => response.json())
  //       .then((res) => {
  //         console.log(res);
  //         const responseTenants = res?.data;
  //         console.log("ReSpinse 57", res);
  //         let sugg = [];
  //         for (let i in responseTenants) {
  //           sugg.push({
  //             value: responseTenants[i].id,
  //             label: responseTenants[i].business_name,
  //           });
  //         }
  //         setCustomers(sugg);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log("51954949", err);
  //       });
  //   };

  //   useEffect(() => {
  //     getTenantsList();
  //   }, []);

  const getBlackListUserDeatil = () => {
    setIsLoading(true);
    let getBlackListUser = `${ApiEndpoints.blackListUserDeatil}?id=${id}`;

    fetch(getBlackListUser)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("response", res);
        const responseBlackListUser = res?.data;
        setUserData(responseBlackListUser);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getBlackListUserDeatil();
  }, []);

  const handleDelete = async (item) => {
    console.log("itemss", item);
    setIsLoading(true);
    if (item.is_active) {
      toast.success("BlackList disabled successfully");
    } else {
      toast.success("BlackList enabled successfully");
    }
    let body = {
      id: item.id,
      is_active: !item.is_active,
      updated_by: localStorage.getItem("fullname"),
    };
    console.log("ITEM", body);
    try {
      const res = await axios.put(ApiEndpoints.blackListUserDelete, body);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setDeleteConfirmModal(false);
        setIsLoading(false);
        // toast.success("BlackList disabled successfully");
        history.push("/admin/blacklist");
        let action = "blacklist-delete";
        let type = "Info";
        let message = "BlackList disabled successfully";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log("ERR", error);
      setIsLoading(false);
      let action = "blacklist-delete";
      let type = "Error";
      let message = "Error in blacklist disabled";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"View BlackList"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table border="1" style={{ borderColor: "#e9ecef" }} responsive>
                  <tbody>
                    <tr>
                      <th>Order No</th>
                      <td>
                        {userData?.order_number?.length > 0
                          ? userData?.order_number
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Customer Id</th>
                      <td>
                        {userData?.customer_id?.length > 0
                          ? userData?.customer_id
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>
                        {userData?.name?.length > 0 ? userData?.name : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>
                        {userData?.email?.length > 0 ? userData?.email : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Order E-mail</th>
                      <td>
                        {userData?.order_email?.length > 0
                          ? userData?.order_email
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>
                        {userData?.phone?.length > 0 ? userData?.phone : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Delivery Address</th>
                      <td>
                        {userData?.address?.length > 0
                          ? userData?.address
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Billing Address</th>
                      <td>
                        {userData?.billing_address?.length > 0
                          ? userData?.billing_address
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Delivery Contact Name</th>
                      <td>
                        {userData?.delivery_address_name?.length > 0
                          ? userData?.delivery_address_name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Active</th>
                      <td>
                        <Badge
                          className="badge-lg"
                          color={userData?.is_active ? "success" : "danger"}
                        >
                          {userData?.is_active ? "Yes" : "No"}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th>Reason For BlackList</th>
                      <td>{userData?.reason}</td>
                    </tr>
                  </tbody>
                </Table>
                <Button
                  color={userData?.is_active === false ? "primary" : "danger"}
                  onClick={() => {
                    setDeleteConfirmModal(true);
                    setDeleteUserData(userData);
                  }}
                  type="button"
                >
                  {userData?.is_active === true
                    ? "Disable this entry"
                    : "Enable this entry"}
                </Button>
                <Modal
                  isOpen={deleteConfirmModal}
                  fade={false}
                  toggle={deleteConfirmToggle}
                  size="sm"
                  centered
                  backdrop="static"
                >
                  <ModalBody>
                    Are you sure you want to{" "}
                    {userData?.is_active == false ? "enable" : "disable"} this
                    entry?
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={() => handleDelete(deleteUserData)}
                    >
                      Yes
                    </Button>
                    <Button color="secondary" onClick={deleteConfirmToggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BlackListUsersView;
