import React, { useState, useEffect } from "react";
import "./notificationStyle.css";
import { ApiUrls } from "../../../common/apiconstant";
import { MultiSelect } from "react-multi-select-component";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { ApiEndpoints } from "../../constants/constant";
import { getToken } from "../../../onboarding";
import { logUserMetrics } from "../../../utils/file";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { toast } from "react-toastify";
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();
const options = [];
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function NotificationCreate() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [endDateData, setendDateData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const auth = useSelector((state) => state.auth);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [notificationData, setNotificationData] = useState();
  const [modal, setModal] = useState(false);
  const token = getToken();
  const config = {
    headers: { Authorization: token },
  };
  let LocationApi = `${ApiUrls.allLocationList}?size=50`;
  fetch(LocationApi, config)
    .then((response) => response.json())
    .then((data) => {
      console.log("1539",data.data);
      const locations = data?.data;

      locations.forEach((element) => {
        const found = options.some((el) => el.label === element?.title);
        if (!found) {
          let locationId = element.location_id.split("#");
          options.push({
            label: element?.title,
            value: locationId[locationId.length - 1],
          });
        }
      });
      console.log(options);
    })
    .catch((err) => {
      console.log(err);
    });
  const history = useHistory();
  const handleDateOnChange = (date) => {
    setStartDate(date);
  };
  const handleendDateOnChange = (date) => {
    setEndDate(date);
  };
  const create = async (data, setSubmitting) => {
    console.log("Datattta===>", data);
    let unselect = [];
    options.forEach((element) => {
      unselect.push(element.value);
    });

    let locationArr = [];
    selectedLocation.forEach((element) => {
      locationArr.push(element.value);
    });
    let unSelectLocation = unselect.filter((el) => {
      return locationArr.indexOf(el) === -1;
    });
    // var res = unselect.filter( function(n) { return !this.has(n) }, new Set(unselect) );
    let locationArrObj = { locationsId: locationArr };
    let unSelectLocationObj = { unselectedLocationId: unSelectLocation };
    // console.log(moment(startDate).format("x"))
    console.log(startDate, "startDate");
    // let startDate1=startDate.toLocaleString();
    // console.log(startDate1,'startDateTolocalString')
    // let endDate1=endDate.toLocaleString();
    // console.log(endDate,'startDate')
    // console.log(new Date(endDate1).getTime(),'endDateTolocalString')

    let dates = { startDate: startDate, endDate: endDate };
    console.log(dates, "datesss");
    let notificationApi = `${ApiUrls.notificationCreate}`;
    let checToCall = JSON.stringify({
      ...data,
      ...dates,
      ...locationArrObj,
      ...unSelectLocationObj,
    });
    console.log("Check To call===>", checToCall);
    console.log("Api call documentation==>", notificationApi);
    console.log(
      JSON.stringify({
        ...data,
        ...dates,
        ...locationArrObj,
        ...unSelectLocationObj,
      })
    );
    if (window.confirm("Are You Sure You want to add Message")) {
      setIsLoading(true);
      fetch(notificationApi, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          ...dates,
          ...locationArrObj,
          ...unSelectLocationObj,
        }),
      })
        .then((response) => response.json())
        .then(async(res) => {
          console.log("data3214", isLoading,res);
          if (res.data !== "Notification Already Exist") {
            setIsLoading(false);
            console.log("API Response Items", res?.data);
            const notification = res?.data;
            setNotificationData([...notification]);
            setSubmitting(false);
            handleView();
            // if (window.confirm('The Given notification is already Existing')) {
            // }
            // setSubmitting(false);
            // handleView();
            toast.success("OPS Message created successfully");
            let action = "ops-message-create"
            let type = "Info";
            let message = "OPS Message created successfully.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
          } else {
            setIsLoading(false);
            setModal(true);
          }

        })
        .catch(async(err) => {
          console.log(err);
          handleView();
          let action = "ops-message-create"
            let type = "Error";
            let message = "Error in OPS Message create.";
            const apiResponse = await logUserMetrics(action, type, message);
            console.log("apiirresponse", apiResponse);
        });
    }
  };
  const modalToggle = () => setModal(!modal);
  const handleView = () => {
    const viewPath = "/admin/notification";
    history.push(viewPath);
  };
  useEffect(() => {});

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        backdrop="static"
        centered
      >
        <ModalHeader
          className="paddingTop5 paddingBottom0"
          toggle={modalToggle}
        ></ModalHeader>
        <ModalBody className="paddingTop5 paddingBottom0 textcenter">
          <p>A similar message already exist in the system</p>
        </ModalBody>
        <ModalFooter className="justifyCenter">
          <Button color="secondary" onClick={modalToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Add Message"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Messages
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p key={itemEr.label}>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}

                <Formik
                  initialValues={{
                    isBlocking: "Non-Blocking",
                    isEnabled: "true",
                    screenName: "home",
                    target: "Website",
                  }}
                  validate={(values) => {
                    values = {
                      ...values,
                      ...modifiedNonFormData,
                      ...endDateData,
                    };
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.primary_message) {
                      errors.primary_message = "Required";
                    }

                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    // if (!values.isBlocking) {
                    //     errors.isBlocking = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    // if (!values.startDate) {
                    //     errors.startDate = 'Required';
                    // }
                    // if (!values.endDate) {
                    //     errors.endDate = 'Required';
                    // }
                    // if (!values.isEnabled) {
                    //     errors.isEnabled = 'Required';
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    create(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">Title</label>
                            <Input
                              placeholder="Enter Title"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                              name="title"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Screen Name&nbsp;*
                            </label>
                            <Input
                              type="select"
                              name="screenName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="home"
                                selected={
                                  values.screenName === "home" ? "selected" : ""
                                }
                              >
                                Home
                              </option>
                              <option
                                value="cart"
                                selected={
                                  values.screenName === "cart" ? "selected" : ""
                                }
                              >
                                Cart
                              </option>
                              <option
                                value="checkOut"
                                selected={
                                  values.screenName === "checkOut"
                                    ? "selected"
                                    : ""
                                }
                              >
                                CheckOut
                              </option>
                              <option
                                value="website"
                                selected={
                                  values.screenName === "website"
                                    ? "selected"
                                    : ""
                                }
                              >
                                All Pages
                              </option>
                            </Input>
                            <p className="text-danger mb-0">
                              {errors.screenName &&
                                touched.screenName &&
                                errors.screenName}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Primary Message&nbsp;*
                            </label>
                            &nbsp;
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipmessage"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipmessage"}
                            >
                              Main message to be displayed
                            </UncontrolledTooltip>
                            <Input
                              placeholder="Enter Primary Message"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.primary_message}
                              name="primary_message"
                            />
                            <p className="text-danger mb-0">
                              {errors.primary_message &&
                                touched.primary_message &&
                                errors.primary_message}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Secondary Message
                            </label>
                            &nbsp;
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipmessage2"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipmessage2"}
                            >
                              Secondary message to be diaplayed
                            </UncontrolledTooltip>
                            <Input
                              placeholder="Enter secondary_message"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secondary_message}
                              name="secondary_message"
                            />
                            <p className="text-danger mb-0">
                              {errors.secondary_message &&
                                touched.secondary_message &&
                                errors.secondary_message}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Location&nbsp;*
                            </label>
                            <MultiSelect
                              options={options}
                              value={selectedLocation}
                              onChange={setSelectedLocation}
                              labelledBy="Select"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Status&nbsp;*
                            </label>
                            <Input
                              type="select"
                              name="isEnabled"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="true"
                                selected={
                                  values.isEnabled === "true" ? "selected" : ""
                                }
                              >
                                Enable
                              </option>
                              <option
                                value="false"
                                selected={
                                  values.isEnabled === "false" ? "selected" : ""
                                }
                              >
                                Disable
                              </option>
                            </Input>
                            <p className="text-danger mb-0">
                              {errors.isEnabled &&
                                touched.isEnabled &&
                                errors.isEnabled}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Blocking Status&nbsp;*
                            </label>
                            &nbsp;{" "}
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipv3"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip delay={0} target={"tooltipv3"}>
                              message will block the user to enter the website
                            </UncontrolledTooltip>
                            <Input
                              type="select"
                              name="isBlocking"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="Blocking"
                                selected={
                                  values.isBlocking === "Blocking"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Blocking
                              </option>
                              <option
                                value="Non-Blocking"
                                selected={
                                  values.isBlocking === "Non-Blocking"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Non-Blocking
                              </option>
                            </Input>
                            <p className="text-danger mb-0">
                              {errors.isBlocking &&
                                touched.isBlocking &&
                                errors.isBlocking}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="3" className="textAlign">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              StartDate&nbsp;*
                            </label>
                            <DatePicker
                              selected={startDate}
                              selectsStart
                              startDate={startDate}
                              showTimeSelect
                              dateFormat="MM/dd/yyyy"
                              onChange={handleDateOnChange}
                            />

                            <p className="text-danger mb-0">
                              {errors.startDate &&
                                touched.startDate &&
                                errors.startDate}
                            </p>
                          </FormGroup>
                        </Col>                        
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Target&nbsp;*
                            </label>
                            &nbsp;{" "}
                            <Button
                              className="btn-icon-create"
                              color="info"
                              id={"tooltipv3"}
                              type="button"
                            >
                              {/* <i className="fa fa-eye"></i> */}?
                            </Button>
                            <UncontrolledTooltip delay={0} target={"tooltipv3"}>
                              message will block the user to enter the website
                            </UncontrolledTooltip>
                            <Input
                              type="select"
                              name="target"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                value="All"
                                selected={
                                  values.target === "All" ? "selected" : ""
                                }
                              >
                                All
                              </option>
                              <option
                                value="Website"
                                selected={
                                  values.target === "Website" ? "selected" : ""
                                }
                              >
                                Website
                              </option>
                              <option
                                value="Mobile App"
                                selected={
                                  values.target === "Mobile App"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Mobile App
                              </option>
                            </Input>
                            <p className="text-danger mb-0">
                              {errors.target && touched.target && errors.target}
                            </p>
                          </FormGroup>
                        </Col>   
                        <Col lg="3" className="textAlign">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              End Date&nbsp;*
                            </label>
                            <DatePicker
                              selected={endDate}
                              selectsStart
                              startDate={endDate}
                              showTimeSelect
                              dateFormat="MM/dd/yyyy"
                              onChange={handleendDateOnChange}
                            />
                            <p className="text-danger mb-0">
                              {errors.endDate &&
                                touched.endDate &&
                                errors.endDate}
                            </p>
                          </FormGroup>
                        </Col>   
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={
                                !values.primary_message ||
                                selectedLocation.length == 0
                              }
                            >
                              Create Message
                            </Button>
                          </FormGroup>
                        </Col>                 
                      </Row>                      
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default NotificationCreate;
