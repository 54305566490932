import React, { useMemo, useEffect, useState, useRef } from "react";
import Header from "../../ui/Header.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  CardFooter,
  Table,
  Container,
  Button,
  Row,
  Input,
  Media,
  ButtonGroup,
  UncontrolledTooltip,
  Badge,
  Col,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { ApiUrls } from "../../../common/apiconstant";
import { getToken } from "../../../onboarding";
import FullPageLoader from "../../../components/FullPageLoader";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Loader from "../../../onboarding/components/Loader.js";
import SearchBar from "../../components/SearchBar";
import ReactTags from "react-tag-autocomplete";
import MobileSearchBar from "../../components/SearchBarMobile.js";
import styles from "../../styles/list.module.css";
const employeesColumns = [
  {
    header: "Name",
  },
  {
    header: "Phone",
  },
  {
    header: "Email",
  },
  {
    header: "Tenant",
  },
  // {
  //   header: "Location",
  // },
  {
    header: "Role",
  },
  {
    header: "Is Verified",
  },
  //   {
  //     header: "Is Enable",
  //   },
  {
    header: "Actions",
  },
];

function EmployeesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [nextKey, setNextKey] = useState(null);
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [hasPermisssion, setHasPermission] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [roleActions, setRoleActions] = useState([]);
  const [page, setPage] = useState(1);
  const [userRoles, setUserRoles] = useState([]);
  const reactTags = useRef();
  const [suggestions, setSuggestions] = useState([
    // { id: 1, name: "Apples" },
    // { id: 2, name: "Pears" },
    // { id: 3, name: "Bananas" },
    // { id: 4, name: "Mangos" },
    // { id: 5, name: "Lemons" },
    // { id: 6, name: "Apricots" },
  ]);
  const [tags, setTags] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [statusFilter, setStatusFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [customerId, setCustomerId] = useState("");
  const [clear, setClear] = useState(false);
  const [tenantList, setTenantList] = useState([]);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    } else {
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  const userLocation = localStorage.getItem("location");

  const getShowRole = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getPermissionApi = `${ApiEndpoints.roleGetURL}?limit=500&offset=0`;

    fetch(getPermissionApi, config)
      .then((response) => response.json())
      .then((res) => {
        // setIsLoading(false);
        const permissionGet = res?.data;
        console.log("ReSpinse 59999999", res);
        console.log("permissionGetpermissionGet,==>", permissionGet);
        setRoles(permissionGet);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getShowRole();
  }, []);

  const getEmployeeList = (pageNum) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let EmployeeApi = `${ApiUrls.userLists}?limit=10&page=${pageNum}&customer_id=${customerId}`;

    if (selectedStatus !== "All") {
      EmployeeApi = EmployeeApi + `&type=${selectedStatus}`;
    }

    if (selectedSortOption) {
      EmployeeApi =
        EmployeeApi +
        `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (searchKeyword.length > 0) {
      EmployeeApi = EmployeeApi + `&searchKeyword=${searchKeyword}`;
    }

    fetch(EmployeeApi, config)
      .then((response) => response.json())
      .then((res) => {
        const employee = res?.data;
        console.log("SFD", employee);
        // if (employee.length > 0) {
        //   setNextKey(employee[employee.length - 1]);
        // } else {
        //   setNextKey(null);
        // }
        // if (page > 1) {
        //   setEmployees((prevList) => [...prevList, ...employee]);
        // } else {
        //   setEmployees(employee);
        // }

        // setIsLoading(false);

        if (employee.length > 0) {
          const updatedEmployeesList =
            pageNum === 1 ? employee : [...employees, ...employee];
          const mergedEmployees =
            mergeAndSortEmployeesList(updatedEmployeesList);
          setEmployees(mergedEmployees);

          if (employee.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setEmployees(employee);

            if (employee.length < 10) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          }
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mergeAndSortEmployeesList = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const getLocationList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiUrls.locationList}`, config)
      .then((response) => response.json())
      .then((data) => {
        const locations = data?.data?.Items;

        setLocationData(locations);
        if (userLocation !== null) {
          setLocationId(userLocation);
          getEmployeeList(userLocation);
        } else {
          setLocationId(locations[0]?.pk);
          getEmployeeList(locations[0]?.pk);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadMore = () => {
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getEmployeeList(pageNum);
  };

  // useEffect(() => {
  //   let location = localStorage.getItem("selected_location_id");
  //   if (location) {
  //     setLocationId(location);
  //     getEmployeeList(location);
  //   }
  // }, [page, customerId]);

  useEffect(() => {
    setPage(1);
    if (selectedSortOption) {
      console.log("ggh");
      getEmployeeList(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getEmployeeList(page);
    } else if (searchKeyword === "") {
      getEmployeeList(page);
    }
  }, [searchKeyword]);

  const getTenantsList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
    fetch(getTenantsApi, config)
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        const responseTenants = res?.data;
        console.log("ReSpinse 57", res);
        let sugg = [];
        for (let i in responseTenants) {
          sugg.push({
            id: responseTenants[i].id,
            name: responseTenants[i].business_name,
          });
        }
        setSuggestions(sugg);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("51954949", err);
      });
  };

  useEffect(() => {
    getTenantsList();
  }, []);

  // const handleChange = (e) => {
  //   if (e.target.value === "LS-Internal") {
  //     localStorage.setItem("user_tab", "LS-Internal");
  //     setFilterType("LS-Internal");
  //   } else if (e.target.value === "Tenant") {
  //     localStorage.setItem("user_tab", "Tenant");
  //     setFilterType("Tenant");
  //   }
  //   setLocationId(e.target.value);
  //   setPage(1);
  // };

  // useEffect(() => {
  //   let selected_tab = localStorage.getItem("user_tab");
  //   if (selected_tab === "LS-Internal") {
  //     setFilterType("LS-Internal");
  //   } else {
  //     setFilterType("Tenant");
  //   }
  // }, []);

  const columns = useMemo(() => employeesColumns, []);

  const handleAdd = () => {
    const viewPath = "/admin/user-create";
    history.push(viewPath);
  };

  const handleView = (item) => {
    const viewPath = "/admin/user-show/" + item.email;
    history.push(viewPath);
  };

  const handleEdit = (item) => {
    // const editPath = "/admin/employees-edit/" + item.userId;
    // history.push(editPath);
    console.log("EDIT174174174==>", item);

    const editPath = `/admin/user-edit/${item.email}`;
    history.push({
      pathname: editPath,
      // state: {
      //   item: item.id,
      //   permission: "edit",
      // },
    });
  };
  const onDelete = (i) => {
    const tags1 = tags.slice(0);
    tags1.splice(i, 1);
    setTags(tags1);
  };

  const onAddition = (tag) => {
    setPage(1);
    let tenantIds = [];
    console.log("taggggg", tag);
    // tenantIds.concat(tag.id);

    console.log("tenantIds", tenantIds);

    const tags1 = [].concat(tags, tag);
    console.log("tags1", tags1);
    setTags(tags1);
    for (let i in tags1) {
      if (tags1[i].id == 17) {
        tenantIds.push("188299065656");
      } else {
        tenantIds.push(tags1[i].id);
      }
    }
    setCustomerId(tenantIds.join());
    // getLocationList(1);
    // setSearchKeyWord("");
  };

  const handleSearch = () => {
    let customersId = [];

    for (let i in tags) {
      customersId.push(tags[i].id);
    }
    console.log("customersId", customersId.join());
    setCustomerId(customersId.join());
    setPage(1);
  };

  const handleDelete = async (item) => {
    setIsLoading(true);
    let body = {
      updated_by: item?.firstname + " " + item.lastname,
      id: item.id,
    };

    console.log("ITEM", body);

    try {
      const res = await axios.delete(ApiUrls.deleteUser, { data: body });
      console.log("RESPONSE", res);
      if (res.status === 200) {
        let users = JSON.parse(JSON.stringify(employees));
        let index = users.findIndex((user) => user.id == item.id);
        users.splice(index, 1);
        setEmployees(users);
        setDeleteConfirmModal(false);
        setIsLoading(false);
        toast.success("User Deleted Successfully");
        let action = "user-delete";
        let type = "Info";
        let message = "User successfully deleted.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log("ERR", error);
      setIsLoading(false);
      let action = "user-delete";
      let type = "Error";
      let message = "Error in user delete.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }

    // if (window.confirm("Are you sure you want to Disable this employee?")) {
    //   if (item.isEnabled) {
    //     var isEnable = false;
    //   } else {
    //     var isEnable = true;
    //   }
    //   fetch(`${ApiUrls.userBlock}` + item.userId, {
    //     method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    //     body: JSON.stringify({ type: "Staff", isEnabled: isEnable }), // body data type must match "Content-Type" header
    //   })
    //     .then((response) => response.json())
    //     .then((res) => {
    //       console.log(res);
    //       if (res.message == "") {
    //         if (res.data.message == "SUCCESS") {
    //           window.location.reload();
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     });
    // }
  };

  const tenantName = (tenant_id) => {
    let tenant = suggestions.find((e) => e.id == tenant_id);
    if (tenant) {
      return tenant.name;
    } else {
      return "LS-Internal";
    }
  };

  const SortOptions = [{ id: "created_at", text: "Date" }];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setSelectedStatus("All");
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });
      setSelectedStatus(value);
    }
  };

  let btns = ["All", "LS-Internal", "Tenant"];

  const handleClear = async () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setTenantList("");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getEmployeeList(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setTenantList("");
    setClear(true);
    setShowCard(false);
  };

  // useEffect(() => {
  //   getEmployeeList();
  // }, [filterType]);

  // useEffect(() => {
  //   if (page > 1) {
  //     let result = localStorage.getItem("active_tab");
  //     if (!result) {
  //       if (activeTab === "Users") {
  //         console.log("TRANSACTION");
  //         getEmployeeList(ApiUrls.userLists + "?type=LS-Internal", true);
  //       } else {
  //         console.log("ADMIN");
  //         getEmployeeList(ApiUrls.userLists + "&customer_id=" + customerId, true);
  //       }
  //     } else {
  //       if (result === "Users") {
  //         console.log("TRANSACTION");
  //         getEmployeeList(ApiUrls.userLists + "?type=LS-Internal", true);
  //       } else {
  //         console.log("ADMIN");
  //         getEmployeeList(ApiUrls.userLists + "&customer_id=" + customerId, true);
  //       }
  //     }
  //   }
  // }, [page]);

  // const updateUsersStatus = (status) => {
  //   if (status === "Users") {
  //     localStorage.setItem("active_tab", "Users");
  //     setActiveTab(status);
  //     setPage(1);
  //     setShowLoadMore(true);
  //   } else if (status === "Admin Users") {
  //     localStorage.setItem("active_tab", "Admin Users");
  //     setActiveTab(status);
  //     setPage(1);
  //     setShowLoadMore(true);
  //   }
  // };

  useEffect(() => {
    let roles = localStorage.getItem("role_actions");
    if (roles) {
      let allRoles = JSON.parse(roles);
      if (
        allRoles.includes(
          "user_read",
          "user_delete",
          "user_update",
          "user_create"
        )
      ) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    }
  }, []);

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  console.log("ASDFASDGAGSADGSdadsf", employeesColumns);

  return (
    <>
      <Container fluid className={styles.searchBarUsersList}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"All Users"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("user_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        color="default"
                        onClick={() => handleAdd()}
                        size="sm"
                      >
                        Add New User
                      </Button>
                    )}
                  </Col>
                </Row>

                {/* <Row  className="align-items-center">
                            <Card
                                className="my-2"
                                color="primary"
                                outline
                                style={{
                                  width: "450px",
                                }}
                              >
                               <CardBody>
                                  <CardText>
                                      <div
                                      style={{
                                        padding: "10px 10px",
                                        display: "grid",
                                        gridTemplateColumns: "1fr auto",
                                        background: "rgba(246,249,252, 0.3)",
                                        cursor: "pointer",
                                        justifyContent:  "space-between",
                                        gap: 20,
                                      }}
                                    >
                                  <ReactTags
                                      classNames="ReactTags__tagInputField "
                                      ref={reactTags}
                                      tags={tags}
                                      suggestions={suggestions}
                                      onDelete={onDelete}
                                      onAddition={onAddition}
                                      placeholderText="Search By Tenant"
                                      style= {{width:"100px"}}
                                    />
                                    <Button 
                                      color="primary" 
                                      onClick={()=>handleSearch()}
                                      >
                                        Search
                                    </Button>
                                    </div>
                                  </CardText>
                                </CardBody> 
                              </Card>
                         </Row> */}
              </CardHeader>

              <CardBody className={styles.usersList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getEmployeeList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search by email, first & last name"
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getEmployeeList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search user by email, first and last name"
                    // locationDropDown={true}
                    // tags={tags}
                    // suggestions={suggestions}
                    // onDelete={onDelete}
                    // onAddition={onAddition}
                    // tenantList={tenantList}
                    // setTenantList={setTenantList}
                  />
                )}
              </CardBody>

              {/* <ButtonGroup className="btngroup" responsive>
                <Button
                  className={filterType === "LS-Internal" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={handleChange}
                  value="LS-Internal"
                  active={filterType === "LS-Internal"}
                >
                  LS Internal
                </Button>
                {hasPermisssion && (
                  <Button
                    className={filterType === "Tenant" ? "active" : ""}
                    color="link"
                    type="button"
                    onClick={handleChange}
                    value="Tenant"
                    active={filterType === "Tenant"}
                  >
                    External
                  </Button>
                )}
              </ButtonGroup> */}
              <div className={styles.tableContent}>
                <Table className="align-items-center table-flush">
                  <thead
                    // className="thead-light"
                    // style={{ position: "sticky", top: 0 }}
                    className={`thead-light ${styles.tableHeaderContent}`}
                  >
                    <tr>
                      {employeesColumns.map((item) => (
                        <th scope="col">{item.header}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="list">
                    {employees && employees?.length ? (
                      employees?.map((item) => (
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={item?.avatar}
                                  style={{
                                    objectFit: "cover",
                                    width: 50,
                                    height: 50,
                                  }}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  {item?.firstname + " " + item?.lastname}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">{item?.phone}</td>
                          <td className="budget">{item?.email}</td>
                          <td className="budget">
                            {tenantName(item.customer_id)}
                          </td>
                          {/* <td className="budget">
                          {item?.locations?.map((location, i) => (
                            <React.Fragment key={i}>
                              <IMGetLocationTitle id={location} />                              
                            </React.Fragment>
                          ) )}
                        </td> */}

                          <td>
                            {roles.map((paragraph) => (
                              <>
                                {item?.roles?.includes(paragraph.id) ? (
                                  <h4>{paragraph.role.toUpperCase()}</h4>
                                ) : null}
                              </>
                            ))}
                          </td>

                          <td className="budget">
                            <Badge
                              className="badge-lg"
                              color={item?.is_verified ? "success" : "danger"}
                            >
                              {item?.is_verified ? "Yes" : "No"}
                            </Badge>
                          </td>
                          {/* <td className="budget">
                          <Badge
                            className="badge-lg"
                            color={item?.isEnabled ? "success" : "danger"}
                          >
                            {item?.isEnabled ? "Enabled" : "Disabled"}
                          </Badge>
                        </td> */}
                          <td>
                            <ButtonGroup>
                              <Button
                                className="btn-icon-only"
                                color="info"
                                onClick={() => handleView(item)}
                                id={"tooltipv" + item?.id}
                                type="button"
                              >
                                <span className="btn-inner--icon">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltipv" + item?.id}
                              >
                                View User
                              </UncontrolledTooltip>
                              {(userRoles.includes("1") ||
                                roleActions.includes("user_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only left-Padding"
                                    color="success"
                                    onClick={() => handleEdit(item)}
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit User
                                  </UncontrolledTooltip>
                                </>
                              )}
                              {(userRoles.includes("1") ||
                                roleActions.includes("user_delete")) && (
                                <>
                                  <Button
                                    className="btn-icon-only"
                                    color="danger"
                                    onClick={() => {
                                      setDeleteConfirmModal(true);
                                      setDeleteUserData(item);
                                    }}
                                    id={"tooltipd" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-ban"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipd" + item?.id}
                                  >
                                    Delete User
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </ButtonGroup>
                            <Modal
                              isOpen={deleteConfirmModal}
                              fade={false}
                              toggle={deleteConfirmToggle}
                              size="sm"
                              centered
                              backdrop="static"
                            >
                              <ModalBody>
                                Are you sure you want to delete this user?
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={() => handleDelete(deleteUserData)}
                                >
                                  Yes
                                </Button>
                                <Button
                                  color="secondary"
                                  onClick={deleteConfirmToggle}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default EmployeesList;
