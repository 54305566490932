import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  Table,
  Input,
  ButtonGroup,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";
import { getToken } from "../../../onboarding";
import { ApiEndpoints } from "../../constants/constant";
import "./style.css";
import { formatDateTimeInTimeZone } from "../../helpers";
import FullPageLoader from "../../../components/FullPageLoader";
import moment from "moment";
import Loader from "../../../onboarding/components/Loader";
var data = require("../response.json");

function OrderList(props) {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [locationId, setLocaitonId] = useState("");
  const [activeTab, setActiveTab] = useState("Transaction");
  const [hasPermisssion, setHasPermission] = useState(false);
  const [page, setPage] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const handlePage = () => {
    if (hasPermisssion) {
      const viewPath = "/admin/inventory-update-admin/create";
      history.push(viewPath);
    } else {
      const viewPath = "/admin/inventory-update/create";
      history.push(viewPath);
    }
  };

  useEffect(() => {
    let actTab = localStorage.getItem("active_tab");
    if (actTab) {
      setActiveTab(actTab);
      setPage(1);
      setShowLoadMore(true);
    }
  }, []);

  const handleEdit = (item) => {
    if (hasPermisssion && activeTab === "Admin Transaction") {
      const editPath = "/admin/inventory-update-admin/edit/" + item;
      history.push(editPath);
    } else {
      const editPath = "/admin/inventory-update/edit/" + item;
      history.push(editPath);
    }
  };

  const handleView = (item) => {
    if (hasPermisssion && activeTab === "Admin Transaction") {
      const viewPath = "/admin/inventory-update-admin/view/" + item;
      history.push(viewPath);
    } else {
      const viewPath = "/admin/inventory-update/view/" + item;
      history.push(viewPath);
    }
  };

  const handleChange = (e) => {
    console.log("ETAR", e.target.value);
    setLocaitonId(e.target.value);
    localStorage.setItem("transaction_locationId", e.target.value);
  };

  const getLocationList = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiEndpoints.locationList}`, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("DATUUUUU", data);
        const locations = data?.data?.Items;
        setLocationData(locations);
        //setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getOrderList = (url, pageChange) => {
    setIsLoading(true);
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(
      `${url}?locationId=${
        localStorage.getItem("selected_location_id").split("#")[1]
      }&limit=10&page=${page}`,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("VIEW DATA", data);
        if (pageChange) {
          if (data.data.length < 10) {
            setShowLoadMore(false);
            setIsLoading(false);
            return;
          }
          setOrders((p) => [...p, ...data.data]);
        } else {
          setOrders(data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    let result = localStorage.getItem("active_tab");
    if (!result) {
      if (activeTab === "Transaction") {
        console.log("TRANSACTION");

        getOrderList(ApiEndpoints.transactionApi);
      } else {
        console.log("ADMIN");
        getOrderList(ApiEndpoints.transactionAdminApi);
      }
    } else {
      if (result === "Transaction") {
        console.log("TRANSACTION");
        getOrderList(ApiEndpoints.transactionApi);
      } else {
        console.log("ADMIN");
        getOrderList(ApiEndpoints.transactionAdminApi);
      }
    }

    getLocationList();
  }, [activeTab]);

  useEffect(() => {
    if (orders.length < 10) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  }, [orders, activeTab]);

  useEffect(() => {
    if (page > 1) {
      let result = localStorage.getItem("active_tab");
      if (!result) {
        if (activeTab === "Transaction") {
          console.log("TRANSACTION");
          getOrderList(ApiEndpoints.transactionApi, true);
        } else {
          console.log("ADMIN");
          getOrderList(ApiEndpoints.transactionAdminApi, true);
        }
      } else {
        if (result === "Transaction") {
          console.log("TRANSACTION");
          getOrderList(ApiEndpoints.transactionApi, true);
        } else {
          console.log("ADMIN");
          getOrderList(ApiEndpoints.transactionAdminApi, true);
        }
      }
    }
  }, [page]);

  const updateTransactionStatus = (status) => {
    if (status === "Transaction") {
      localStorage.setItem("active_tab", "Transaction");
      setActiveTab(status);
      setPage(1);
      setShowLoadMore(true);
    } else if (status === "Admin Transaction") {
      localStorage.setItem("active_tab", "Admin Transaction");
      setActiveTab(status);
      setPage(1);
      setShowLoadMore(true);
      // history.push("/admin/inventory-update-admin");
    }
  };

  useEffect(() => {
    let roles = localStorage.getItem("role_actions");
    if (roles) {
      let allRoles = JSON.parse(roles);
      if (allRoles.includes("inventory_admin_manage")) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
        updateTransactionStatus("Transaction");
      }
    }
  }, []);
  console.log("ORDERSSS", orders);

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Inventory Update"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("inventory_transaction")) && (
                      <Button
                        size="sm"
                        color="default"
                        onClick={() => handlePage()}
                      >
                        Add New Inventory
                      </Button>
                    )}
                    {/* <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <Input
                        id="exampleFormControlSelect1"
                        type="select"
                        onChange={handleChange}
                      >
                        {locationData.map((item, index) => (
                          <option
                            key={index}
                            value={item?.pk}
                            selected={
                              item?.pk ===
                              "wh#aa2d8ad1-4ba2-427b-b78f-7da626a379df"
                                ? "selected"
                                : ""
                            }
                          >
                            {item?.title}
                          </option>
                        ))}
                      </Input>
                    </div> */}
                  </Col>
                </Row>
              </CardHeader>
              <ButtonGroup className="btngroup" responsive>
                <Button
                  className={activeTab === "Transaction" ? "active" : ""}
                  color="link"
                  type="button"
                  onClick={() => updateTransactionStatus("Transaction")}
                >
                  Inventory Update
                </Button>
                {hasPermisssion && (
                  <Button
                    className={
                      activeTab === "Admin Transaction" ? "active" : ""
                    }
                    color="link"
                    type="button"
                    onClick={() => updateTransactionStatus("Admin Transaction")}
                  >
                    Admin Inventory Update
                  </Button>
                )}
              </ButtonGroup>
              <CardBody className="orderlist">
                <Table className="table" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      {/* <th>Source</th> */}
                      <th>User Name</th>
                      <th>No. Of Items</th>
                      <th>Approved Status</th>
                      <th>Status</th>
                      {/* <th>Total Price</th>
                      <th>Status</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {orders?.map((order, key) => (
                      <tr key={key}>
                        <td>{moment(order.created_at).format("MM/DD/YYYY")}</td>
                        {/* <td>{order.source}</td> */}
                        <td>{order.name}</td>
                        <td>{order.no_of_item}</td>
                        {/* <td>${order.total_price}</td>
                        <td>{order.status}</td> */}
                        <td>{order?.is_approved}</td>
                        <td>{order?.status}</td>
                        <td>
                          <ButtonGroup style={{ marginTop: "-15px" }}>
                            <Button
                              className="btn-icon"
                              size="sm"
                              color="info"
                              type="button"
                              onClick={() => handleView(order.id)}
                              id={"Tooltip-" + "view"}
                            >
                              <i class="fa fa-eye"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"Tooltip-" + "view"}
                            >
                              View Transaction
                            </UncontrolledTooltip>
                            {((activeTab === "Transaction" &&
                              (userRoles.includes("1") ||
                                roleActions.includes(
                                  "inventory_transaction_approve"
                                ))) ||
                              (activeTab === "Admin Transaction" &&
                                (userRoles.includes("1") ||
                                  roleActions.includes(
                                    "invetory_admin_transaction_approve"
                                  )))) && (
                              <>
                                <Button
                                  className="btn-icon"
                                  size="sm"
                                  color="success"
                                  type="button"
                                  onClick={() => handleEdit(order.id)}
                                  id={"Tooltip-" + "edit"}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-edit"></i>
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"Tooltip-" + "edit"}
                                >
                                  Edit Transaction
                                </UncontrolledTooltip>
                              </>
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {showLoadMore && (
                <CardFooter className="py-4">
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={() => setPage((p) => p + 1)}
                  >
                    Load More...
                  </a>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default OrderList;
