import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import Header from "../Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant.js";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
import SubCategory from "./list.js";
import { toast } from "react-toastify";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function NewSubcategoryCreate() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [titleCreate, setTitleCreate] = useState("");
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({});
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [endDateData, setendDateData] = useState({});

  console.log("optiosn====>59", options);

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const auth = useSelector((state) => state.auth);
  const [permissionValue, setPermissionValue] = useState([]);
  const [notificationData, setNotificationData] = useState();

  const createSubCategory = async (data, setSubmitting) => {
    console.log("DATA", data);
    setIsLoading(true);

    let reqBody = {
      name: data.sub_category,
      description: data.description,
      updated_by: localStorage.getItem("fullname"),
    };

    try {
      let res = await axios.post(ApiEndpoints.createsubCategory, reqBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        toast.success("Sub Category Created Successfully");
        history.push("/admin/sub-category");
        setIsLoading(false);
        let action = "sub-category-create"
        let type = "Info";
        let message = "Sub Category successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      let action = "sub-category-create"
      let type = "error";
      let message = "Error in Sub Category create.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  let initialValues = {
    sub_category: "",
    description: "",
  };

  // let LocationApi = `${ApiEndpoints.locationList}?size=10`;

  const handleendDateOnChange = (date) => {
    setEndDate(date);
  };
  const create = async () => {
    // if (window.confirm("Are You Sure You want to add permission")) {
    setIsLoading(true);
    let name = localStorage.getItem("fullname");
    let sendToApi = JSON.stringify({
      permission: titleCreate,
      actions: options,
      updated_by: name,
    });
    console.log("sendToApisendToApi==>", sendToApi);
    fetch(ApiEndpoints.permissionGetURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: sendToApi,
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("data", res);
        if (res.data !== "") {
          setIsLoading(false);
          handleView();
        } else {
          setIsLoading(false);
          setModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
        handleView();
      });
    // }
  };
  const modalToggle = () => setModal(!modal);

  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };
  const titleCreateHandle = (e) => {
    console.log("ETETETETET===>", e.target.value);
    setTitleCreate(e.target.value);
  };
  const handleView = () => {
    // const viewPath = "/admin/Role/Role-Premission";
    // console.log("check 13555==>",viewPath);
    // history.push(viewPath)
    history.goBack();
  };
  useEffect(() => {});
  const openModalCheck = () => {
    console.log("titleCreatetitleCreate==>", titleCreate);
    console.log("132313113==", options);
    modalToggle();
  };

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Add Sub Category</ModalHeader>
        {/* <ModalBody>
        
              <h2 className="fullcalendar-title h2 d-inline-block mb-0">
                Are you sure you want to add the sub category <i class="fal fa-file-spreadsheet"></i>
              </h2>
              <Button color="primary" onClick={createSubCategory}>
                Submit
              </Button>
              <Button color="secondary" onClick={
                modalToggle
              }>
                Cancel
              </Button>
            
         
          
        </ModalBody> */}

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                  <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}>
                      {"Add Sub Category"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Sub Category
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  initialValues={initialValues}
                  validate={(values) => {
                    let value = values;
                    console.log("value", values);
                    // values = { ...values, ...modifiedNonFormData, ...endDateData };
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.sub_category) {
                      errors.sub_category = "Required";
                    }
                    // if (!values.description) {
                    //   errors.description = "Required";
                    // }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitted(true);

                    createSubCategory(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Sub Category
                            </label>
                            <Input
                              placeHolder="Sub Category"
                              type="text"
                              value={values.sub_category}
                              onChange={handleChange}
                              name="sub_category"
                            />
                            <p className="text-danger mb-0">
                              {errors.sub_category &&
                                touched.sub_category &&
                                errors.sub_category}
                            </p>
                          </FormGroup>
                          <p></p>
                        </Col>

                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                             Sub Category Title
                            </label>
                            <Input
                              placeholder="Enter SubCategory Title"
                              type="text"
                              onChange={titleCreateHandle}
                              onBlur={handleBlur}
                              value={titleCreate}
                              name="title"
                            />
                            <p className="text-danger mb-0">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col> */}
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Description
                            </label>
                            <Input
                              placeholder="Description"
                              type="text"
                              onChange={handleChange}
                              value={values.description}
                              name="description"
                            />
                            <p className="text-danger mb-0">
                              {errors.description &&
                                touched.description &&
                                errors.description}
                            </p>
                          </FormGroup>
                        </Col>
                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Create SubCategory&nbsp;*
                            </label> */}
                        {/* <Multiselect 
                      isObject={false}
                      onRemove={(event)=>{ 
                         console.log(event)
                         setPermissionValue(event)
                        } }
                      onSelect={ (event)=>{ 
                        console.log(event) 
                        setPermissionValue(event)
                    
                    }}
                      options={ options }                

                    //   showCheckbox
                      />      */}

                        {/* <TagsInput
        value={options}
        onChange={setOptions}
        name="fruits"
        placeHolder="Create SubCategory"
      /> */}
                        {/* <p className="text-danger mb-0">
                              {errors.screenName &&
                                touched.screenName &&
                                errors.screenName}
                            </p>
                          </FormGroup>
                          <p></p>
                        </Col> */}
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            // disabled={isSubmitting}
                            onClick={isSubmitting}
                            // disabled={
                            //   titleCreate ==="" || options.length === 0
                            //     ? true
                            //     : false
                            // }
                          >
                            Create SubCategory
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default NewSubcategoryCreate;
