import React, { useState, useEffect, useRef } from "react";
import { ErrorMessage, Formik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import DatePicker from "react-datepicker";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant.js";
// import { KeyboardDatePicker } from "@material-ui/pickers";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function NotificationScheduling({
  values,
  setFieldValue,
  errors,
  touched,
  handleNext,
  setScheduling,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState("now");
  // const [selectedDate, setSelectedDate] = React.useState(
  //   new Date("2014-08-18T21:11:54")
  // );
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  let appliesRef = useRef();

  // const handleDateChange = (date) => {
  //   setStartDate(date);
  //   console.log(date);
  // };
  // const handleEndDate = (date) => {
  //   console.log(date);
  //   setEndDate(date);
  // };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value == "now") {
      setFieldValue("schedule.selection", "now");
    } else {
      setFieldValue("schedule.selection", "scheduled");
    }
  };

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const getTimeZones = async () => {
    try {
      const response = await axios.get(ApiEndpoints.timezoneList);
      console.log("Response", response);
      let timezoneData = response?.data?.data;
      setTimezones(timezoneData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTimeZones();
  }, []);

  const handleTimzone = (e) => {
    console.log("Response E", e.target.value);
    setSelectedTimezone(e.target.value);
    setFieldValue("schedule.timezone", e.target.value);
  };

  // useEffect(() => {
  //   if (selectedOption == "now") {
  //     setFieldValue("schedule.selection", "now");
  //   } else {
  //     if (selectedDate && selectedTime) {
  //       setFieldValue("schedule", {
  //         time: new Date(selectedDate + " " + selectedTime)?.toISOString(),
  //         timezone: selectedTimezone ? selectedTimezone : timezones[0]?.timeZone,
  //       });
  //     }
  //   }
  // }, [selectedOption, selectedDate, selectedTime, selectedTimezone]);

  console.log(
    "selected data",
    selectedDate,
    selectedTime,
    selectedTimezone,
    values
  );

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            {/* <Card>
              <CardBody> */}
            {successMsg != "" && (
              <UncontrolledAlert color="success">
                <span className="alert-text ml-1">{successMsg}</span>
              </UncontrolledAlert>
            )}
            {errMsg != "" && (
              <UncontrolledAlert color="danger">
                <span className="alert-text ml-1">{errMsg}</span>
              </UncontrolledAlert>
            )}
            {errorMsg != "" && (
              <UncontrolledAlert color="danger">
                <span className="alert-text ml-1">
                  {errorMsg.map((itemEr) => (
                    <p>{itemEr.error}</p>
                  ))}
                </span>
              </UncontrolledAlert>
            )}

            {/* <Row>                       
                        <Col lg="6">
                          <FormGroup>
                           <Label>
                           <ButtonGroup>
                              <Button>User Segment</Button>
                              <Button>Topic</Button>
                            </ButtonGroup>
                           </Label>                            
                            <InputGroup>
                              <InputGroupText>Customer</InputGroupText>
                              <Input type="select" />
                            </InputGroup>                           
                          </FormGroup>
                        </Col>
                      </Row>*/}
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label tag="h5">Send to eligible users</Label>
                  <Input
                    //className="selectOptions"
                    type="select"
                    onChange={handleOptionChange}
                    value={values.schedule.selection}
                    style={{ width: "50%" }}
                    name="schedule.selection"
                  >
                    <option value="now">Now</option>
                    <option value="scheduled">Schedule</option>
                  </Input>
                </FormGroup>
                {values?.schedule?.selection === "scheduled" && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      marginTop: "20px",
                      width: "100%",
                      gap: "20px",
                    }}
                  >
                    {/* <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="Date picker inline"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            /> */}
                    <div>
                      <Input
                        id="date"
                        label="Send to eligible users"
                        type="date"
                        value={values.schedule.date}
                        onChange={(e) => {
                          setSelectedDate(e.target.value);
                          setFieldValue("schedule.date", e.target.value);
                        }}
                        name="schedule.date"
                      />
                      <ErrorMessage
                        component="span"
                        name="schedule.date"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Input
                        id="time"
                        label="Alarm clock"
                        type="time"
                        value={values.schedule.time}
                        onChange={(e) => {
                          setSelectedTime(e.target.value);
                          setFieldValue("schedule.time", e.target.value);
                        }}
                        name="schedule.time"
                      />
                      <ErrorMessage
                        component="span"
                        name="schedule.time"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <FormGroup>
                        <Input
                          id="exampleFormControlSelect1"
                          type="select"
                          defaultValue={timezones[0]?.timeZone}
                          //name="timezone"
                          onChange={handleTimzone}
                          //onBlur={handleBlur}
                          value={values.schedule.timezone}
                          name="schedule.timezone"
                        >
                          {timezones?.map((zones) => (
                            <option
                              value={zones.timeZone}
                              key={zones.timeZone}
                              selected={zones.timeZone === selectedTimezone}
                            >
                              {zones.name}
                            </option>
                          ))}
                        </Input>
                        <ErrorMessage
                          component="span"
                          name="schedule.timezone"
                          style={{ color: "red" }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            {/* </CardBody>
            </Card> */}
          </div>
        </Row>
      </Container>
    </>
  );
}
export default NotificationScheduling;
