const apiurlVariable = {
  ApiUrls: {
    locationList:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/getAll",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/location/getAll",
    allLocationList:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/locations-title",
      "https://4duv8k145h.execute-api.us-east-1.amazonaws.com/prod/v1/locations-title",
    userBlock:
      // "https://x8x8qmh5o3.execute-api.us-east-1.amazonaws.com/dev/v1/profile/status/",
      "https://dh0ri0kv64.execute-api.us-east-1.amazonaws.com/prod/v1/profile/status/*",
    // userLists:
    //   "https://x8x8qmh5o3.execute-api.us-east-1.amazonaws.com/dev/v1/profile",
    userLists:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/get-users",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/get-users",
    getUser:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/",
      "https://hmvx936sw7.execute-api.us-east-1.amazonaws.com/prod/v1/user/",
    // getLoginUser:
    //   "https://x8x8qmh5o3.execute-api.us-east-1.amazonaws.com/dev/v1/profile/user/",
    getLoginUser:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/get-user-details",
      // "https://hmvx936sw7.execute-api.us-east-1.amazonaws.com/prod/v1/user/get-user-details",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/get-user-details",
    addUser:
      // "https://x8x8qmh5o3.execute-api.us-east-1.amazonaws.com/dev/v1/profile/add",
      "https://dh0ri0kv64.execute-api.us-east-1.amazonaws.com/prod/v1/profile/add",
    updateUser:
      // "https://x8x8qmh5o3.execute-api.us-east-1.amazonaws.com/dev/v1/profile/",
      "https://dh0ri0kv64.execute-api.us-east-1.amazonaws.com/prod/v1/profile",
    editUser:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/update-user",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/update-user",
    allLocation:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/location/getAll",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/location/getAll",
    timeZones:
      // "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/timezone/all",
      "https://7plvpzb51g.execute-api.us-east-1.amazonaws.com/prod/v1/timezone/all",
    imageUpload:
      "https://bhagt8zwld.execute-api.us-east-1.amazonaws.com/dev/v1/curator/profile/image",
    notificationList:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/all-notification-dev",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/all-notifications",
    notificationCreate:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/add-notification",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/add-notification",
    notificationArchive:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/archive-notification",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/un-archive-notification",
    notificationDelete:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/delete-notification",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/delete-notification",
    notificationUnArchive:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/unArchive-notification",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/archive-notification",
    notificationUpdate:
      // "https://bjwjn3w8gl.execute-api.us-east-1.amazonaws.com/dev/v1/update-notification",
      "https://k3dde9ww19.execute-api.us-east-1.amazonaws.com/prod/v1/update-notification",
    avatarUploadPresigned:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/get-avatar-presigned-url",
      "https://hmvx936sw7.execute-api.us-east-1.amazonaws.com/prod/v1/user/get-avatar-presigned-url",
    addNewUser:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/create",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/create",
    deleteUser:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/delete-user",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/delete-user",
    archivedUserList:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/list-deleted-user",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/list-deleted-user",
    activateUser:
      // "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/user/activate-user",
      "https://qq1e8twnj9.execute-api.us-east-1.amazonaws.com/prod/v1/user/activate-user",
    // createTenant:
    //   "https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com/dev/v1/customers",
  },
};

module.exports = apiurlVariable;
