import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@material-ui/core";

export default function ModalSku({ children, stateModal, toggleDrawer }) {
  const isMobile = useMediaQuery("(max-width: 500px)");

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "right" && isMobile ? 350 : 700 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {children}
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={stateModal[anchor]}
            // onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
