import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { ApiUrls } from "../../../common/apiconstant";
import { ApiEndpoints } from "../../constants/constant.js";
import { toast } from "react-toastify";
import { Switch } from "antd";
import { TagsInput } from "react-tag-input-component";
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function EditSubCategory() {
  const classes = useStyles();

  const location = useLocation();

  // const { permission, actions ,id} = location?.state.item;

  // console.log("Location Permission to show==>",location.state.item);
  // console.log("39393939393===.",permission);
  // console.log("actions===>",actions);
  const [originalData, setOriginalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [options, setOptions] =useState(actions);
  const [toggle, setToggle] = useState(false);
  console.log("ORORORORO===>", originalData);
  const [endDate, setEndDate] = useState(new Date());
  const [submitted, setSubmitted] = useState(false);
  const [subCategoryDetail, setSubCategoryDetail] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  let { sub_menu_id } = useParams();

  console.log("sub_menu_id", sub_menu_id);
  const history = useHistory();

  const getSubCategories = () => {
    setIsLoading(true);
    let getSubCategoryApi = `${ApiEndpoints.viewsubCategoryDetail}/${sub_menu_id}`;
    console.log("SCSCSCSC", getSubCategoryApi);
    fetch(getSubCategoryApi)
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        const responseSubCategory = res?.data;
        console.log("ReSpinse 57", res);
        console.log("responseSubCategory,==>", responseSubCategory);

        setOriginalData({
          sub_category: responseSubCategory.name,
          description: responseSubCategory.description,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("989889898", err);
      });
  };
  // const update = async () => {
  //     console.log("Datattta===>",originalData.permission);
  //     // if (window.confirm('Are You Sure You want to edit permission')) {
  //         setIsLoading(true);
  //         let name = localStorage.getItem("fullname");
  //         let sendToApi = JSON.stringify({
  //             "id":id,
  //             "permission": originalData.permission,
  //             "actions": options,
  //             "updated_by": name
  //         });
  //         console.log("sendToApisendToApi==>",sendToApi);
  //         fetch(
  //             ApiEndpoints.permissionGetURL,
  //             {
  //                 method: 'PUT',
  //                 headers: {
  //                     'Accept': 'application/json',
  //                     'Content-Type': 'application/json',
  //                 },
  //                 body: sendToApi
  //             }
  //         )
  //             .then((response) => response.json())
  //             .then((res) => {
  //                 console.log('data', res);
  //                 if (res.data !== "") {
  //                     setIsLoading(false);
  //                     // setSubmitting(false);
  //                     handleView();
  //                 }
  //                 else {
  //                     setIsLoading(false);
  //                     setModal(true)
  //                 }
  //             })
  //             .catch((err) => {
  //                 console.log(err);
  //                 handleView();
  //             });

  // }
  const modalToggle = () => setModal(!modal);

  const handleView = () => {
    history.goBack();
  };
  useEffect(() => {
    getSubCategories();
  }, []);

  // const handlePermissionChange = (e) => {
  //     console.log("126126126126==>",e.target.value);
  //     let permission = JSON.parse(JSON.stringify(originalData));
  //     permission.permission = e.target.value;

  //         setOriginalData(permission)
  // };

  const editSubCategory = async (data, setSubmitting) => {
    setIsLoading(true);

    let reqBody = {
      id: sub_menu_id,
      name: data.sub_category,
      description: data.description,
      updated_by: localStorage.getItem("fullname"),
    };

    try {
      let res = await axios.put(ApiEndpoints.editsubCategory, reqBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        toast.success("Sub Category Edited Successfully");
        history.push("/admin/sub-category");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOriginalData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openModalCheck = () => {
    modalToggle();
  };

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={modalToggle}
        size="sm"
        centered
      >
        <ModalHeader toggle={modalToggle}>Edit Sub Category</ModalHeader>
        {/* <ModalBody>
        
              <h2 className="fullcalendar-title h2 d-inline-block mb-0">
                Are you sure you want to update the sub category <i class="fal fa-file-spreadsheet"></i>
              </h2>
              <Button color="primary" onClick={update}>
                Submit
              </Button>
              <Button color="secondary" onClick={
                modalToggle
              }>
                Cancel
              </Button>
            
         
          
        </ModalBody> */}

        {/* <ModalFooter></ModalFooter> */}
      </Modal>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                  <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}>
                      {"Edit Sub Category"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Sub Category
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                {/* {successMsg != "" && (
                                    <UncontrolledAlert color="success">
                                        <span className="alert-text ml-1">
                                            {successMsg}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}
                {/* {errorMsg != "" && (
                                    <UncontrolledAlert color="danger">
                                        <span className="alert-text ml-1">
                                            {errorMsg.map(itemEr => (
                                                <p key={itemEr.label}>{itemEr.error}</p>
                                            ))}
                                        </span>
                                    </UncontrolledAlert>
                                )} */}

                <Formik
                  enableReinitialize
                  initialValues={originalData}
                  validate={(values) => {
                    console.log("Values", values);
                    values = { ...values };
                    const errors = {};
                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.sub_category) {
                      errors.sub_category = "Required";
                    }
                    // if (!values.description) {
                    //   errors.description = "Required";
                    // }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    editSubCategory(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Edit Sub Category
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Sub Category"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.sub_category}
                              name="sub_category"
                            />
                            <p className="text-danger mb-0">
                              {errors.sub_category &&
                                touched.sub_category &&
                                errors.sub_category}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Edit Description
                            </label>
                            <Input
                              className="textCapitalize"
                              placeholder="Enter Description"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              name="description"
                            />
                            <p className="text-danger mb-0">
                              {errors.description &&
                                touched.description &&
                                errors.description}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Col>
                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Update Sub Category
                          </Button>
                        </FormGroup>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default EditSubCategory;
