import React, { useMemo, useEffect, useState } from 'react';
import Header from "../../ui/Header.js";
import { useHistory } from "react-router-dom";
import {
    Col,
    Card,
    Label,
    CardHeader,
    Form,
    FormGroup,
    CardFooter,
    Table,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardBody,
    Button,
    Row,
    ButtonGroup,
    Media,
    UncontrolledTooltip,
    Input,
} from "reactstrap";
import { useTable, usePagination } from "react-table";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import IMGetLocation from "../ui/IMGetLocation.js";

const awsSDKconfig = require('../../config/awsSDKConfig');
const AWS = require('aws-sdk');
AWS.config.update(awsSDKconfig);
const cognito = new AWS.CognitoIdentityServiceProvider();
const baseAWSAPIURL = require('../../config').baseAWSAPIURL;

const inventoryControlsColumns = [
    {
        Header: "Title 1",
    },
    {
        Header: "Title 2",
    },
    {
        Header: "Title 3",
    },
    {
        Header: "Title 4",
    },
    {
        Header: "Title 5",
    },
    {
        Header: "Title 6",
    },
    {
        Header: "Title 7",
    },

]




function ControlsList() {
    const [isLoading, setIsLoading] = useState(true);
    const [controlledPageCount, setControlledPageCount] = useState(0)
    const [inventoryControls, setInventoryControls] = useState([]);
    const [data, setData] = useState([]);
    const userLocation = localStorage.getItem('location');
    const history = useHistory();

    const columns = useMemo(
        () => inventoryControlsColumns,
        []
    )
    const handleView = () => {

        const viewPath = "/admin/inventory-transactions-show/1"
        history.push(viewPath)
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                                            {"Transactions"}
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <div
                                            style={{
                                                display: "inline-block",
                                            }}
                                        >
                                            {userLocation == null ?
                                                <Input id="exampleFormControlSelect3" type="select">
                                                    <IMGetLocation />
                                                </Input>
                                                : null
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs={12} sm={7}>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="example-text-input"
                                                md="3"
                                            >
                                                Transection ID
                                            </Label>
                                            <Col md="7">
                                                <Input
                                                    placeholder="Search"
                                                    id="example-text-input"
                                                    type="text"
                                                />
                                            </Col>
                                            <Label >
                                                <Button
                                                    className=""
                                                    color="primary"
                                                    id="copy-tooltip"
                                                    md="1"
                                                    style={{ marginTop: "0px" }}
                                                ><i className="fas fa-search"></i></Button>
                                            </Label>
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </CardBody>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th>Transaction ID</th>
                                        <th>Creation Time</th>
                                        <th>Created By</th>
                                        <th>Item Count</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>

    )
}

export default ControlsList;
