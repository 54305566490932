import React, { useCallback, useEffect, useState } from "react";
//import "./collectionaccordian.css";
import TenantDragBar from "./tenantDragbar";
import { logUserMetrics } from "../../../utils/file";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import Loader from "../../../onboarding/components/Loader";
import { ApiEndpoints } from "../../constants/constant";
import axios from "axios";
import { object } from "prop-types";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import FullPageLoader from "../../../components/FullPageLoader";

const accordionItems = [
  {
    id: 1,
    title: "Item 1",
    content: "This is a dummy data.",
    list: [
      { title: "First Item", id: 1 },
      { title: "Second Item", id: 2 },
      { title: "Third Item", id: 3 },
      { title: "Fourth Item", id: 4 },
      { title: "Fifth Item", id: 5 },
    ],
  },
  {
    id: 2,
    title: "Item 2",
    content: "This is a dummy data.",
    list: [
      { title: "First Item", id: 6 },
      { title: "Second Item", id: 7 },
      { title: "Third Item", id: 8 },
      { title: "Fourth Item", id: 9 },
      { title: "Fifth Item", id: 10 },
    ],
  },
  {
    id: 3,
    title: "Item 3",
    content: "This is a dummy data.",
    list: [
      { title: "First Item", id: 11 },
      { title: "Second Item", id: 12 },
      { title: "Third Item", id: 13 },
      { title: "Fourth Item", id: 14 },
      { title: "Fifth Item", id: 15 },
    ],
  },
];

let obj = {
  APP: [
    {
      collection_id: "123344555",
      menu: "APP",
      title: "Test 1",
      display_name: "display_name",
      updated_by: "Nitesh",
      created_at: 1683489051602,
      updated_at: 1683489051602,
      tenant_id: "1234512121",
      sort_order: "0",
    },
    {
      collection_id: "123344555212",
      menu: "APP",
      title: "Test 2",
      display_name: "display_name 2",
      updated_by: "Nitesh",
      created_at: 1683489051661,
      updated_at: 1683489051661,
      tenant_id: "1234512121",
      sort_order: "1",
    },
    {
      collection_id: "12334455521233",
      menu: "APP",
      title: "Test 3",
      display_name: "display_name 2",
      updated_by: "Nitesh",
      created_at: 1683489051781,
      updated_at: 1683489051781,
      tenant_id: "1234512121",
      sort_order: "2",
    },
  ],
  WEB: [
    {
      collection_id: "123344555",
      menu: "WEB",
      title: "Test 1",
      display_name: "display_name",
      updated_by: "Nitesh",
      created_at: 1683489051602,
      updated_at: 1683489051602,
      tenant_id: "1234512121",
      sort_order: "0",
    },
  ],
};

const CollectionAccordianTenant = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [isDragable, setIsDragable] = useState(false);

  const toggleActiveIndex = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [draggedList, setDraggedList] = useState({});
  let location = useLocation();
  let tenantId = location?.state?.tenantId;
  

  console.log("DRAGGLISt", JSON.stringify(draggedList), tenantId, location);

  const onDragEnd = (fromIndex, toIndex, obj) => {
    console.log("FR", fromIndex, toIndex, obj);
    let items = draggedList;
    let data = draggedList[obj[0].menu];
    console.log("DDS", data);
    const newData = [...data];
    let item = newData.splice(fromIndex, 1)[0];
    newData.splice(toIndex, 0, item);

    // setDragData(newData);

    let newObj = [...newData];
    // let foundIndex = items.findIndex((item) => item.id === obj.id);
    items[obj[0].menu] = newObj;
    console.log("ITEMS", items);
    // setDraggedList([...items]);
    setDraggedList(JSON.parse(JSON.stringify(items)));
  };

  // const renderedItems = accordionItems.map((item, index) => {
  //   const active = activeIndex === index ? 'active' : '';

  //   return (

  //    <div key={index}>
  //       <div
  //         className={`accordion-header ${active}`}
  //         onClick={() => toggleActiveIndex(index)}
  //       >
  //         <h2 className="accordion-title">{item.title}</h2>
  //       </div>
  //       <div className={`accordion-panel ${active}`}>
  //        <TenantDragBar data={item.list}/>
  //       </div>
  //     </div>
  //   );
  // });

  const getTenant = async (tenantId) => {
    setIsLoading(true);
    try {
      let res = await axios.get(
        ApiEndpoints.getCollectionByOrder +
          `?tenant_id=${localStorage.getItem("tenant_id")}` 
          // +
          // `&location_id=${
          //   localStorage.getItem("selected_location_id").split("#")[1]
          // }`
      );
      console.log("RREEå", res);
      if (res.status === 200) {
        setDraggedList(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTenant();
  }, [tenantId]);

  const handleClick = async () => {
    setIsLoading(true);
    let reqBody = {
      updated_by: localStorage.getItem("fullname"),
    };
    for (const key in draggedList) {
      reqBody[key] = [];

      for (let i = 0; i < draggedList[key].length; i++) {
        draggedList[key][i].sort_order = i;
        reqBody[key].push(draggedList[key][i]);
      }
    }

    console.log("REQBODy,", reqBody);

    try {
      let res = await axios.post(ApiEndpoints.updateCollectionByOrder, reqBody);

      console.log("RESPO", res);
      if (res.status === 200) {
        toast.success("App and Website Navigation Management Updated");
        setIsLoading(false);
        let action = "tenant-update-collections-sort-order"
        let type = "Info";
        let message = "Tenant Update Collection Sort Order successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error in App and Website Navigation Management Update");
      setIsLoading(false);
      let action = "tenant-update-collections-sort-order"
      let type = "Error";
      let message = "Error in Tenant Update Collection Sort Order.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const [tags, setTags] = useState([]);

  const onDelete = useCallback(
    (tagIndex, item, list) => {
      let obj = JSON.parse(JSON.stringify(list));
      console.log("OBJect", obj);
      let value = obj[item.menu].find(
        (elm) => elm.collection_id === item.collection_id
      );
      value.sub_menu.splice(tagIndex, 1);
      setDraggedList(obj);
      setTags(tags.filter((_, i) => i !== tagIndex));
    },
    [tags]
  );

  const onAddition = useCallback(
    (newTag, item, list) => {
      console.log("NEW TAG", newTag, item, list);
      let obj = JSON.parse(JSON.stringify(list));
      console.log("OBJect", obj, item, obj[item.menu]);
      let value = obj[item.menu].find(
        (elm) => elm.collection_id === item.collection_id
      );

      console.log("valuessss",value.sub_menu);
      if (!value.sub_menu  ) {
        value.sub_menu = [];
      }
      console.log("valuessss",value);
      console.log("newTag",newTag);
      
      
     let existingValue = value.sub_menu.find(
          (elm) => elm.id === newTag.id
        );

      
      if (typeof existingValue == "undefined") {
        value.sub_menu.push(newTag);
      }
      setDraggedList(obj);
      console.log("values",value.sub_menu);

      setTags([...tags, newTag]);
    },
    [tags]
  );

  if (isLoading) {
    return <FullPageLoader />;
  }

  const renderedItems =
    draggedList &&
    Object.keys(draggedList).length > 0 &&
    Object.keys(draggedList)?.map((item, index) => {
      const active = activeIndex === index ? "active" : "";

      console.log("ITEM", item, draggedList[item]);

      return (
        <div key={index}>
          <Row style={{ alignItems: "center" }}>
            <Button
              // className={`accordion-header ${active}`}
              onClick={() => {
                toggleActiveIndex(index);
              }}
              style={{
                // background: "#F6F9FC",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}
              block
            >
              {/* {draggedList[item]} */}
              {item?.split("_")?.join(" ")?.toUpperCase()}

              <span>
                {active ? (
                  <i
                    class="fas fa-chevron-circle-up"
                    style={{ color: "grey" }}
                  ></i>
                ) : (
                  <i
                    class="fas fa-chevron-circle-down"
                    style={{ color: "grey" }}
                  ></i>
                )}
              </span>
            </Button>
          </Row>
          <Row>
            {active ? (
              <Col
              // className={`accordion-panel ${active}`}
              >
                {/* <TenantDragBar data={item.list} setDraggedList={setDraggedList} /> */}
                <TenantDragBar
                  // dragData={item.list}
                  dragData={draggedList[item]}
                  onDragEnd={(a, b) => onDragEnd(a, b, draggedList[item])}
                  onAddition={(tag, collection) =>
                    onAddition(tag, collection, draggedList)
                  }
                  onDelete={(tagIndex, collection) =>
                    onDelete(tagIndex, collection, draggedList)
                  }
                />
              </Col>
            ) : null}
          </Row>
        </div>
      );
    });

  // if (draggedList && Object.keys(draggedList).length === 0) {
  //   return <div style={{ padding: 20 }}>No Collection Found</div>;
  // }

  return (
    // <div className="accordion">
    <>
      <Container fluid>
        {/* <div className="col"> */}
        <Card>
          <CardHeader className="">
            <Row className="align-items-center">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                  {"App and Website Navigation Management"}
                </h6>
              </Col>
            </Row>
          </CardHeader>

          <CardBody
          // className="orderlist"
          >
            {/* <Row>
                  <Col xs={12} sm={5}>
                    <FormGroup>
                      <Col md="10">
                        <Input
                          placeholder="Search"
                          id="example-text-input"
                          type="text"
                          //   onChange={(e) => setSearchKeyword(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row> */}
            {/* <Table className="table" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Collection Name</th>
                      <th>Display Name</th>
                      <th>Website Menu</th>
                      <th>App Categories</th>
                      <th>Brand Carousel</th>
                      <th>Featured Brands</th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {collections?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            verticalAlign: "center",
                          }}
                        >
                          {item.title}
                        </td>
                        <td>
                          <Input />
                        </td>
                        <td
                          style={{
                            verticalAlign: "center",
                          }}
                        >
                          <Input type="checkbox" />
                        </td>
                        <td
                          style={{
                            verticalAlign: "center",
                          }}
                        >
                          <Input type="checkbox" />
                        </td>
                        <td
                          style={{
                            verticalAlign: "center",
                          }}
                        >
                          <Input type="checkbox" />
                        </td>
                        <td
                          style={{
                            verticalAlign: "center",
                          }}
                        >
                          <Input type="checkbox" />
                        </td>
                      </tr>
                    ))}
                    <Button color="primary">Save</Button>
                  </tbody>
                </Table> */}
            {Object.keys(draggedList).length === 0 ? (
              <div>No collection found</div>
            ) : (
              renderedItems
            )}

            <Row>
              <Col>
                <Button
                  onClick={() => handleClick()}
                  color="primary"
                  className="btn btn-primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* </div> */}
      </Container>
      {/* <Loader isLoading={isLoading} /> */}
    </>
    // </div>
  );
};

export default CollectionAccordianTenant;
