import React, { useState, useEffect } from 'react';
import Header from "../../ui/Header.js";
import { ClipLoader } from 'react-spinners';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    Button,
    Row,
    Table,
    Badge,
} from "reactstrap";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";

const baseAWSAPIURL = require('../../config').baseAWSAPIURL;

function ControlsShow(props) {

    let { id } = useParams()
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [originalData, setOriginalData] = useState([])

    const handleView = () => {
        const viewPath = "/admin/inventory-transactions"
        history.push(viewPath)
    }



    if (isLoading || !originalData) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <Header name="Transaction" parentName="Dashboard" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0" style={{ display: 'inline' }}>Transaction Detail</h3>
                                <Button
                                    className="pull-right"
                                    color="default"
                                    onClick={() => handleView()}
                                    size="sm"
                                >
                                    View All
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <Table border="1" style={{ 'borderColor': "#e9ecef" }} responsive>
                                    <tbody>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                        <tr>
                                            <th>Column 1</th>
                                            <td>Title 1</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>

    )
}

export default ControlsShow;
