import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import Loader from "../../../onboarding/components/Loader";

const collectionColumns = [
  {
    header: "Collection Id",
  },
  {
    header: "Image",
  },
  {
    header: "Name",
  },
  {
    header: "Handle",
  },
  {
    header: "Sort Order",
  },
  {
    header: "Published Scope",
  },
];

const CollectionsList = () => {
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getCollectionList() {
    setIsLoading(true);
    try {
      const res = await axios.get(
        ApiEndpoints.collectionList + `?offset=0&limit=100`
      );

      console.log("Res", res);
      if (res.status === 200) {
        setCollections(res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCollectionList();
  }, []);

  //   if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Collection List"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {collectionColumns.map((item, i) => (
                      <th scope="col" key={i}>
                        {item.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="list">
                  {collections && collections?.length ? (
                    collections?.map((item) => (
                      <tr>
                        <td className="budget">{item?.collection_id}</td>
                        <td className="budget">
                          <img
                            src={item?.image?.src}
                            alt=""
                            style={{
                              objectFit: "cover",
                              width: 50,
                              height: 50,
                            }}
                          />
                        </td>
                        <td className="budget">{item?.title}</td>
                        <td className="budget">{item?.handle}</td>
                        <td className="budget">{item?.sort_order}</td>
                        <td className="budget">{item?.published_scope}</td>
                        {/* <td className="budget">
                          {item?.locations.map((location, i) => (
                            <React.Fragment key={i}>
                              <IMGetLocationTitle id={location} />
                              <br />
                            </React.Fragment>
                          ))}
                        </td> */}

                        {/* <td>
                          {roles.map((paragraph) => (
                            <>
                              {item.roles.includes(paragraph.id) ? (
                                <h4>{paragraph.role.toUpperCase()}</h4>
                              ) : null}
                            </>
                          ))} 
                        </td>
*/}
                        {/*  <td className="budget">
                           <Badge
                            className="badge-lg"
                            color={item?.is_verified ? "success" : "danger"}
                          >
                            {item?.is_verified ? "Yes" : "No"}
                          </Badge>
                        </td>
 */}
                        {/*     <td>
                          <ButtonGroup>
                            <Button
                              className="btn-icon-only"
                              color="info"
                              //   onClick={() => handleView(item)}
                              id={"tooltipv" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-eye"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipv" + item?.id}
                            >
                              View User
                            </UncontrolledTooltip>

                            <Button
                              className="btn-icon-only left-Padding"
                              color="success"
                              //   onClick={() => handleEdit(item)}
                              id={"tooltipe" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-edit"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipe" + item?.id}
                            >
                              Edit User
                            </UncontrolledTooltip>

                            <Button
                              className="btn-icon-only"
                              color="danger"
                              //   onClick={() => {
                              //     setDeleteConfirmModal(true);
                              //     setDeleteUserData(item);
                              //   }}
                              id={"tooltipd" + item?.id}
                              type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-ban"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipd" + item?.id}
                            >
                              Delete User
                            </UncontrolledTooltip>
                          </ButtonGroup> */}
                        {/* <Modal
                            isOpen={deleteConfirmModal}
                            fade={false}
                            toggle={deleteConfirmToggle}
                            size="sm"
                            centered
                            backdrop="static"
                          >
                            <ModalBody>
                              Are you sure you want to delete this user?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="primary"
                                onClick={() => handleDelete(deleteUserData)}
                              >
                                Yes
                              </Button>
                              <Button
                                color="secondary"
                                onClick={deleteConfirmToggle}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal> 
                        </td>*/}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {isLoading ? (
                        <td colSpan="8">Loading...</td>
                      ) : (
                        <td>No data found...</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* <CardFooter className="py-4">
               
                <a
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  className="Link"
                  //   onClick={() => getEmployeeList(locationId)}
                >
                  Load More...
                </a>
               
              </CardFooter> */}
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default CollectionsList;
