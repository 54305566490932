import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/shopifyProductsList.module.css";

const headerData = [
  {
    header: "Image",
  },
  {
    header: "Title",
  },
  {
    header: "No Of Variants",
  },
  {
    header: "Status",
  },
  {
    header: "Actions",
  },
];

// const productData = [
//   {
//     id: "1",
//     img: "",
//     title: "100 Anos Blanco Tequila",
//     is_active: true,
//     inventory: [
//       {
//         qty: 3,
//         variant: "750 mL",
//       },
//       {
//         qty: 5,
//         variant: "1 L",
//       },
//     ],
//     salesChannels: 8,
//     marketes: 2,
//     type: "Wine",
//     vendor: "Liquorsplit",
//   },
//   {
//     id: "2",
//     img: "",
//     title: "100 Anos Blanco Tequila",
//     is_active: true,
//     inventory: [
//       {
//         qty: 3,
//         variant: "750 mL",
//       },
//       {
//         qty: 5,
//         variant: "1 L",
//       },
//     ],
//     salesChannels: 8,
//     marketes: 2,
//     type: "Wine",
//     vendor: "Liquorsplit",
//   },
//   {
//     id: "3",
//     img: "",
//     title: "100 Anos Blanco Tequila",
//     is_active: true,
//     inventory: [
//       {
//         qty: 3,
//         variant: "750 mL",
//       },
//       {
//         qty: 5,
//         variant: "1 L",
//       },
//     ],
//     salesChannels: 8,
//     marketes: 2,
//     type: "Wine",
//     vendor: "Liquorsplit",
//   },
//   {
//     id: "4",
//     img: "",
//     title: "100 Anos Blanco Tequila",
//     is_active: true,
//     inventory: [
//       {
//         qty: 3,
//         variant: "750 mL",
//       },
//       {
//         qty: 5,
//         variant: "1 L",
//       },
//     ],
//     salesChannels: 8,
//     marketes: 2,
//     type: "Wine",
//     vendor: "Liquorsplit",
//   },
// ];

const ProductList = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);

  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationList, setAllLocationList] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const SortOptions = [
    { id: "title", text: "Title" },
    { id: "created_at", text: "Date" },

    // { id: "2", text: "2", access: "noOfItems" },
    // { id: "3", text: "3", access: "totalPrice" },
    // { id: "4", text: "4", access:"orderDate" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const getProductDetails = (pageNum) => {
    let detailProduct = `${ApiEndpoints.productSearch}?page=${pageNum}&limit=10`;

    if (selectedSortOption) {
      detailProduct =
        detailProduct +
        `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    // if (statusFilter.length > 0) {
    //   let UpperCaseStatusFilter = statusFilter.map(
    //     (status) =>
    //       status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
    //   );
    //   let StatusString = UpperCaseStatusFilter.join(",");
    //   detailProduct = detailProduct + `&status=${StatusString}`;
    // }

    // if (selectedStatus !== "All") {
    //   detailProduct = detailProduct + `&status=${selectedStatus}`;
    // }

    if (selectedStatus !== "All") {
      const Status = selectedStatus.toLowerCase();
      detailProduct = detailProduct + `&status=${Status},${selectedStatus}`;
    }

    if (searchKeyword.length > 0) {
      detailProduct = detailProduct + `&searchKeyword=${searchKeyword}`;
    }

    setIsLoading(true);
    fetch(detailProduct)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("res", res);
        const responseProductData = res?.data;
        console.log("Response Product Data", res);
        if (responseProductData.length > 0) {
          const updatedOrders =
            pageNum === 1
              ? responseProductData
              : [...productData, ...responseProductData];
          const mergedOrders = mergeAndSortOrders(updatedOrders);
          setProductData(mergedOrders);

          if (responseProductData.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          console.log("page456", page);
          if (pageNum === 1) {
            setProductData(responseProductData);

            if (responseProductData.length < 10) {
              setLoadMoreEnabled(false);
            } else {
              setLoadMoreEnabled(true);
            }
          }
        }
        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);
      });
  };

  const handleLoadMore = () => {
    // const nextPage = page + 1;
    // getProductDetails(nextPage);
    setPage((p) => p + 1);
    let pageNum = page + 1;
    getProductDetails(pageNum);
  };

  useEffect(() => {
    if (selectedSortOption) {
      getProductDetails(page);
    }
  }, [selectedSortOption, sortDirection, selectedStatus]);

  useEffect(() => {
    setPage(1);
    if (searchKeyword.length >= 2) {
      getProductDetails(page);
    } else if (searchKeyword === "") {
      getProductDetails(page);
    }
  }, [searchKeyword]);

  const mergeAndSortOrders = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  // const selectStatus = (value) => {
  //   value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  //   console.log("value", value);
  //   let status = statusFilter.find((x) => x == value);
  //   let tempStatusFilter = JSON.parse(JSON.stringify(statusFilter));
  //   if (status) {
  //     let index = tempStatusFilter.findIndex((x) => x == value);
  //     tempStatusFilter.splice(index, 1);
  //   } else {
  //     tempStatusFilter.push(value);
  //   }
  //   setStatusFilter(tempStatusFilter);

  //   getProductDetails();
  // };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      // getProductDetails(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });

      const Status =
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      setSelectedStatus(Status);
    }
  };

  let btns = ["All", "Active", "Draft", "Archived"];

  const handleCreate = () => {
    history.push("/admin/products/create");
  };

  const handleEdit = (item) => {
    history.push(`/admin/products/edit/${item.id}`);
  };

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getProductDetails(1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPage(1);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="6">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"All Products"}
                    </h6>
                  </Col>
                  <Col className="text-md-right" lg="6" xs="6">
                    {(userRoles.includes("1") ||
                      roleActions.includes("product_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={handleCreate}
                      >
                        Add New Product
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className={styles.productList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getProductDetails}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Searching All Products"
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getProductDetails}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Searching All Products"
                    dropDown={false}
                    orderList={false}
                  />
                )}
              </CardBody>

              <div style={{ height: 550, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item) => (
                        <th scope="col">{item.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {productData && productData?.length ? (
                      productData?.map((item) => (
                        <tr>
                          <td>
                            {item.images && item.images.length > 0 ? (
                              <img
                                src={item.images[0].src}
                                alt="Image Description"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            ) : (
                              <img
                                src={Logo}
                                alt="Placeholder Image"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            )}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.variants.length}</td>
                          <td>
                            {item.status && (
                              <span>
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1).toLowerCase()}
                              </span>
                            )}
                          </td>

                          {/* <td>
                      {item.status === "active" && (
                        <span>Active</span>
                      )}
                      {item.status === "draft" && (
                        <span>Draft</span>
                      )}
                      {item.status === "archived" && (
                        <span>Archived</span>
                      )}
                      </td> */}
                          {/* <td>
                        <Badge
                          className="badge-lg"
                          color={item?.is_active ? "success" : "danger"}
                        >
                          {item?.is_active ? "Yes" : "No"}
                        </Badge>
                      </td> */}
                          {/* <td>
                        {item.inventory[0].qty + item.inventory[1].qty} in stock
                        for {item.inventory.length} variant
                      </td> */}
                          {/* <td>{item.salesChannels}</td>
                      <td>{item.marketes}</td>
                      <td>{item.type}</td>

                      <td>{item.vendor}</td> */}

                          <td>
                            <ButtonGroup>
                              {/* <Button
                            className="btn-icon-only"
                            color="info"
                            onClick={() =>
                              history.push(`/admin/products/view/${item.id}`, {
                                item: item,
                                permission: "view",
                              })
                            }
                            id={"tooltipv" + item?.id}
                            type="button"
                          >
                            <span className="btn-inner--icon">
                              <i className="fa fa-eye"></i>
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltipv" + item?.id}
                          >
                            View Product
                          </UncontrolledTooltip> */}
                              {(userRoles.includes("1") ||
                                roleActions.includes("product_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only "
                                    color="success"
                                    onClick={() =>
                                      history.push(
                                        `/admin/products/edit/${item.id}`,
                                        {
                                          item: item,
                                          permission: "edit",
                                        }
                                      )
                                    }
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit Product
                                  </UncontrolledTooltip>
                                </>
                              )}
                              {/* <Button
                            className="btn-icon-only"
                            color="danger"
                            //onClick={() => disableCoupon(item)}
                            id={"tooltipd" + item?.id}
                            type="button"
                          >
                            <span className="btn-inner--icon">
                              <i className="fa fa-ban"></i>
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltipd" + item?.id}
                          >
                            Delete Product
                          </UncontrolledTooltip> */}
                            </ButtonGroup>

                            <Modal
                              //isOpen={modal}
                              fade={false}
                              //toggle={modalToggle}
                              size="sm"
                              centered
                            >
                              <ModalBody>
                                Are you sure you want to delete this discount?
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  //onClick={() => handleDelete(selectedCoupon)}
                                >
                                  Yes
                                </Button>{" "}
                                <Button
                                  color="secondary"
                                  //onClick={modalToggle}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default ProductList;
