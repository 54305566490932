import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  UncontrolledAlert,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import { logUserMetrics } from "../../../utils/file";
import { useHistory, withRouter } from "react-router-dom";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import styles from "../../styles/locationAdd.module.css";
import * as Yup from "yup";

function Elements(props) {
  const [isLoading, setIsLoading] = useState(true);
  // const [formData, setFormData] = React.useState({
  //   zip_codes: [
  //     {
  //       code: "",
  //       priority: "",
  //     },
  //   ],
  // });
  const [dam, setDam] = React.useState("Manual");
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const [timezones, setTimezones] = React.useState([]);
  const [options, setOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerId, setcustomerId] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    fetch(`${ApiEndpoints.timezoneList}`, { methd: "GET" })
      .then((response) => response.json())
      .then((data) => {
        const timezoneData = data.data;
        setTimezones(timezoneData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const getTenantsList = () => {
    setIsLoading(true);
    let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
    fetch(getTenantsApi)
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        const responseTenants = res?.data;
        console.log("ReSpinse 57", res);
        let sugg = [];
        for (let i in responseTenants) {
          sugg.push({
            value: responseTenants[i].id,
            label: responseTenants[i].business_name,
          });
        }
        setCustomers(sugg);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("51954949", err);
      });
  };

  useEffect(() => {
    getTenantsList();
  }, []);

  const addLocation = async (data, setSubmitting, setFieldValue) => {
    console.log("DATA", data);
    console.log("TSTSTS", options);

    setIsLoading(true);

    let reqBody = {
      customer_id: customerId,

      code: data.code,
      title: data.title.trim(),
      timezone: data.timezone,
      description: data.description.trim(),
      // is_active: true,
      address: {
        add1: data?.address?.add1.trim(),
        add2: data?.address?.add2.trim(),
        building: data?.address?.building.trim(),
        city: data?.address?.city.trim(),
        state: data?.address?.state.trim(),
        zip: data?.address?.zip.toString(),
        email: data?.address?.email,
        customer_contact: data?.address?.customer_contact,
        phone: data?.address?.phone,
      },
      support_email: data.support_email,
      support_phone: data.support_phone,
      slack_channel: data.slack_channel.trim(),
      dba: data.dba.trim(),
      min_order_value: data.min_order_value.trim(),
      delivery_charge: data.delivery_charge,
      firstname: data.firstname.trim(),
      lastname: data.lastname.trim(),
      admin_email: data.admin_email,
      admin_phone: data.admin_phone,
      // is_verified: true,
      pickup_enabled: true,
      pick_up_time: data.pick_up_time,
      operation_hours: {
        st: data?.operation_hours?.st,
        et: data?.operation_hours?.et,
      },
      operation_email: data.operation_email,
      zip_code: options,
      delivery_enabled: true,

      location: {
        lat: data?.location?.lat,
        lng: data?.location?.lng,
      },
      // fun_friend: data.fun_friend,
      // life_of_the_party: data.life_of_the_party,
      // onfleet_api_key: "111698b1935026fc8d",
      // savvy_socialite: data.savvy_socialite,
      // shopify_location_id: "65581973702",
      // voucherify_app_id: "0e66a34-9a38-4e91-bbd6-0d4d4e00c1c9",
      // voucherify_app_token: "e2745d31-d932-4e85-8591-282a1f28c8a7",
      type: "LS-Internal",
      updated_by: localStorage.getItem("fullname"),
    };

    console.log("REQ", reqBody);
    if (data.is_active === "yes") {
      reqBody.is_active = true;
    } else {
      reqBody.is_active = false;
    }
    if (data.is_verified === "yes") {
      reqBody.is_verified = true;
    } else {
      reqBody.is_verified = false;
    }

    try {
      let res = await axios.post(ApiEndpoints.locationAdd, reqBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setFieldValue("zip_code", []);
        setIsLoading(false);
        toast.success("Location created successfully");
        history.push("/admin/locations");
        let action = "location-create";
        let type = "Info";
        let message = "Location successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.log("error", error);
      console.log("error12", error.response);
      if (
        error?.response?.data?.message == "user with this email already exist"
      ) {
        toast.error(error?.response?.data?.message);
      } else if (error?.response?.data?.message?.length > 0) {
        error.response.data.message.map((msg) => toast.error(msg.error));
      }
      setIsLoading(false);
      let action = "location-create";
      let type = "Error";
      let message = "Error in location create.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const validationSchema = Yup.object({
    code: Yup.string().required("Required"),
    address: Yup.object().shape({
      add1: Yup.string().required("Required"),
      building: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      zip: Yup.string().required("Required"),
      email: Yup.string().required("Required").email("Invalid E-mail"),
      customer_contact: Yup.string()
        .required("Required")
        .matches(/^\d+$/, "Invalid phone number")
        .min(10, "Phone number must be at least 10 digits")
        .max(10, "Phone number must not exceed 10 digits"),
      phone: Yup.string()
        .required("Required")
        .matches(/^\d+$/, "Invalid phone number")
        .min(10, "Phone number must be at least 10 digits")
        .max(10, "Phone number must not exceed 10 digits"),
    }),
    // customer_id: Yup.string().required("Required"),
    delivery_enabled: Yup.boolean()
      .required("Required")
      .oneOf([true], "Required"),
    is_verified: Yup.string().required("Required"),
    delivery_charge: Yup.string().required("Required"),
    // fun_friend: Yup.string().required("Required"),
    is_active: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    firstname: Yup.string().required("Required"),
    dba: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    admin_email: Yup.string().required("Required").email("Invalid E-mail"),
    admin_phone: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid phone number")
      .min(10, "Phone number must be at least 10 digits")
      .max(10, "Phone number must not exceed 10 digits"),
    // life_of_the_party: Yup.string().required("Required"),
    location: Yup.object().shape({
      lat: Yup.string().required("Required"),
      lng: Yup.string().required("Required"),
    }),
    min_order_value: Yup.string().required("Required"),
    operation_email: Yup.string().required("Required").email("Invalid E-mail"),
    tenant: Yup.string()
      .required("Required")
      .transform((value) => value.value),
    operation_hours: Yup.object().shape({
      st: Yup.string().required("Required"),
      et: Yup.string().required("Required"),
    }),
    pickup_enabled: Yup.boolean()
      .transform((value) => value === "yes") // Convert "yes" to true, other values to false
      .required("Required")
      .oneOf([true], "Required"),
    pick_up_time: Yup.string().required("Required"),
    // savvy_socialite: Yup.string().required("Required"),
    support_email: Yup.string().required("Required").email("Invalid E-mail"),
    support_phone: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid phone number")
      .min(10, "Phone number must be at least 10 digits")
      .max(10, "Phone number must not exceed 10 digits"),
    slack_channel: Yup.string().trim().required("Required"),
    timezone: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    zip_code: Yup.array().required("Required").min(1, "Required"),
  });

  let initialValues = {
    customer_id: "",
    address: {
      add1: "",
      add2: "",
      building: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      customer_contact: "",
      phone: "",
    },
    code: "",
    dba: "",
    delivery_enabled: false,
    is_verified: "yes",
    description: "",
    fun_friend: "",
    is_active: "yes",

    delivery_charge: "",
    admin_email: "",
    firstname: "",
    lastname: "",
    admin_phone: "",
    life_of_the_party: "",
    location: {
      lat: "",
      lng: "",
    },
    // shopify_location_id: "7862137679",
    min_order_value: "",
    operation_email: "",
    tenant: "",
    operation_hours: {
      st: "",
      et: "",
    },
    pickup_enabled: false,
    pick_up_time: "",
    savvy_socialite: "",
    support_email: "",
    support_phone: "",
    slack_channel: "",
    timezone: "America/Los_Angeles",
    title: "",
    zip_code: [],
    updated_by: localStorage.getItem("fullname"),
  };

  const handleRadioChange = (e) => {
    setDam(e.target.value);
  };
  const handleClick = (e) => {
    console.log("454", e);
    setcustomerId(e.value);
  };

  if (isLoading) {
    return (
      <>
        <FullPageLoader />
      </>
    );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Add Location"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => props.history.goBack()}
                    >
                      List Locations
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  // validate={(values) => {
                  //   let value = values;
                  //   console.log(values);
                  //   const errors = {};

                  //   console.log("errors", errors);

                  //   var pattern = new RegExp(/^[0-9\b]+$/);

                  //   if (!values.code) {
                  //     errors.code = "Field Required!";
                  //   }

                  //   if (!values.title) {
                  //     errors.title = "Field Required!";
                  //   }

                  //   if (!values.timezone) {
                  //     errors.timezone = "Field Required!";
                  //   }

                  //   if (!values.description) {
                  //     errors.description = "Field Required!";
                  //   }

                  //   if (!values.is_active) {
                  //     errors.is_active = "Field Required!";
                  //   }

                  //   if (!values.tenant) {
                  //     errors.tenant = "Field Required!";
                  //   }

                  //   if (!values?.address?.add1) {
                  //     errors.address = {
                  //       add1: "Field Required!",
                  //     };
                  //   }

                  //   // if (!values?.address?.add2) {
                  //   //   errors.address = {
                  //   //     add2: "Field Required!",
                  //   //   };
                  //   // }

                  //   if (!values?.address?.building) {
                  //     errors.address = {
                  //       building: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.address?.city) {
                  //     errors.address = {
                  //       city: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.address?.state) {
                  //     errors.address = {
                  //       state: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.location?.lat) {
                  //     errors.location = {
                  //       lat: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.location?.lng) {
                  //     errors.location = {
                  //       lng: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.address?.zip) {
                  //     errors.address = {
                  //       zip: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.address?.phone) {
                  //     errors.address = {
                  //       phone: "Field Required",
                  //     };
                  //   } else if (!pattern.test(values?.address?.phone)) {
                  //     errors.address = {
                  //       phone: "Please enter only number.",
                  //     };
                  //   } else if (values?.address?.phone.length != 10) {
                  //     errors.address = {
                  //       phone: "Please enter valid phone number.",
                  //     };
                  //   }

                  //   if (!values?.address?.email) {
                  //     errors.address = {
                  //       email: "Field Required",
                  //     };
                  //   } else if (
                  //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  //       values?.address?.email
                  //     )
                  //   ) {
                  //     errors.address = {
                  //       email: "Invalid email address",
                  //     };
                  //   }

                  //   if (!values?.address?.customer_contact) {
                  //     errors.address = {
                  //       customer_contact: "Field Required",
                  //     };
                  //   } else if (
                  //     !pattern.test(values?.address?.customer_contact)
                  //   ) {
                  //     errors.address = {
                  //       customer_contact: "Please enter only number.",
                  //     };
                  //   } else if (values?.address?.customer_contact.length != 10) {
                  //     errors.address = {
                  //       customer_contact: "Please enter valid phone number.",
                  //     };
                  //   }

                  //   if (!values.support_email) {
                  //     errors.support_email = "Field Required";
                  //   } else if (
                  //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  //       values.support_email
                  //     )
                  //   ) {
                  //     errors.support_email = "Invalid email address";
                  //   }

                  //   if (!values.support_phone) {
                  //     errors.support_phone = "Field Required";
                  //   } else if (!pattern.test(values.support_phone)) {
                  //     errors.support_phone = "Please enter only number.";
                  //   } else if (values.support_phone.length != 10) {
                  //     errors.support_phone = "Please enter valid phone number.";
                  //   }

                  //   // if (!values.slack_channel) {
                  //   //   errors.slack_channel = "Field Required!";
                  //   // }

                  //   if (!values.slack_channel.trim()) {
                  //     errors.slack_channel = "Field Required!";
                  //   }

                  //   if (!values.min_order_value) {
                  //     errors.min_order_value = "Field Required!";
                  //   }

                  //   if (!values.delivery_charge) {
                  //     errors.delivery_charge = "Field Required!";
                  //   }

                  //   if (!values.firstname) {
                  //     errors.firstname = "Field Required!";
                  //   }

                  //   if (!values.lastname) {
                  //     errors.lastname = "Field Required!";
                  //   }

                  //   if (!values.admin_email) {
                  //     errors.admin_email = "Field Required";
                  //   } else if (
                  //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  //       values.admin_email
                  //     )
                  //   ) {
                  //     errors.admin_email = "Invalid email address";
                  //   }

                  //   if (!values.admin_phone) {
                  //     errors.admin_phone = "Field Required";
                  //   } else if (!pattern.test(values.admin_phone)) {
                  //     errors.admin_phone = "Please enter only number.";
                  //   } else if (values.admin_phone.length != 10) {
                  //     errors.admin_phone = "Please enter valid phone number.";
                  //   }

                  //   if (!values.is_verified) {
                  //     errors.is_verified = "Field Required!";
                  //   }

                  //   if (!values.pickup_enabled) {
                  //     errors.pickup_enabled = "Field Required!";
                  //   }

                  //   if (!values.pick_up_time) {
                  //     errors.pick_up_time = "Field Required!";
                  //   }

                  //   if (!values?.operation_hours?.st) {
                  //     errors.operation_hours = {
                  //       st: "Field Required!",
                  //     };
                  //   }

                  //   if (!values?.operation_hours?.et) {
                  //     errors.operation_hours = {
                  //       et: "Field Required!",
                  //     };
                  //   }

                  //   if (!values.operation_email) {
                  //     errors.operation_email = "Field Required!";
                  //   } else if (
                  //     !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  //       values.operation_email
                  //     )
                  //   ) {
                  //     errors.operation_email = "Invalid email address";
                  //   }

                  //   if (values.zip_code.length < 1) {
                  //     errors.zip_code = "Field Required!";
                  //   }

                  //   if (!values.delivery_enabled) {
                  //     errors.delivery_enabled = "Field Required!";
                  //   }

                  //   return errors;
                  // }}
                  onSubmit={(
                    values,
                    { setSubmitting, setTouched, setFieldValue }
                  ) => {
                    addLocation(values, setSubmitting, setFieldValue);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form>
                      <Row>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Code
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Code"
                              type="code"
                              value={values.code}
                              name="code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.code && touched.code && errors.code}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Store Name
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter Store Name"
                              type="title"
                              value={values.title}
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.title && touched.title && errors.title}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              DBA
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter DBA"
                              type="dba"
                              value={values.dba}
                              name="dba"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.dba && touched.dba && errors.dba}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Description
                            </label>
                            <Input
                              id="exampleFormControlTextarea1"
                              rows="3"
                              type="textarea"
                              placeholder="Enter Description"
                              value={values.description}
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.description &&
                                touched.description &&
                                errors.description}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols3Input"
                            >
                              Time Zone
                            </label>
                            <Input
                              id="exampleFormControlSelect1"
                              type="select"
                              value={values.timezone}
                              name="timezone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {timezones.map((zones) => (
                                <option value={zones.timeZone}>
                                  {zones.name}
                                </option>
                              ))}
                            </Input>
                            <p className="ErrorMessage">
                              {errors.timezone &&
                                touched.timezone &&
                                errors.timezone}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Is Active
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Is Active"
                              type="select"
                              value={values?.is_active}
                              name="is_active"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Is Active</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </Input>
                            <p className="ErrorMessage">
                              {errors.is_active &&
                                touched.is_active &&
                                errors.is_active}
                            </p>
                          </FormGroup>
                        </Col>

                        {/* <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example5cols3Input"
                            >
                              Driver Assignment Mode
                            </label>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                id="customRadio5"
                                defaultChecked
                                name="driver_assignment_mode"
                                type="radio"
                                value="Manual"
                                onChange={(e) => handleRadioChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio5"
                              >
                                Manual
                              </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                id="customRadio6"
                                name="driver_assignment_mode"
                                type="radio"
                                value="Automatic"
                                onChange={(e) => handleRadioChange(e)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio6"
                              >
                                Automatic
                              </label>
                            </div>
                          </FormGroup>
                        </Col> */}
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Tenant</h3>
                      </div>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Select Tenant"
                              isSearchable={true}
                              name="tenant"
                              options={customers}
                              onChange={(e) => {
                                handleClick(e);
                                setFieldValue("tenant", e);
                              }}
                            />
                            <p className="ErrorMessage">
                              {errors.tenant && touched.tenant && errors.tenant}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Address</h3>
                      </div>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Address1
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Address1"
                              type="text"
                              value={values?.address?.add1}
                              name="address.add1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.add1 &&
                                touched?.address?.add1 &&
                                errors?.address?.add1}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols3Input"
                            >
                              Address2
                            </label>
                            <Input
                              id="example3cols3Input"
                              placeholder="Enter Address2"
                              type="text"
                              value={values?.address?.add2}
                              name="address.add2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <p className="ErrorMessage">
                              {errors?.address?.add2 &&
                                touched?.address?.add2 &&
                                errors?.address?.add2}
                            </p> */}
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Building
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter Building"
                              type="building"
                              value={values.address.building}
                              name="address.building"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.building &&
                                touched?.address?.building &&
                                errors?.address?.building}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              City
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter City"
                              type="city"
                              value={values?.address?.city}
                              name="address.city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.city &&
                                touched?.address?.city &&
                                errors?.address?.city}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              State
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter State"
                              type="state"
                              value={values?.address?.state}
                              name="address.state"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.state &&
                                touched?.address?.state &&
                                errors?.address?.state}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols3Input"
                            >
                              Zip
                            </label>
                            <Input
                              className={styles.lat_input}
                              id="example3cols3Input"
                              placeholder="Enter Zip"
                              type="text"
                              value={values?.address?.zip}
                              name="address.zip"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "e" ||
                                  e.key === "." ||
                                  e.key === "+" ||
                                  e.key === "-"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              title="Only numeric characters are allowed"
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.zip &&
                                touched?.address?.zip &&
                                errors?.address?.zip}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Phone
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter Phone"
                              type="phone"
                              value={values?.address?.phone}
                              name="address.phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.phone &&
                                touched?.address?.phone &&
                                errors?.address?.phone}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Email
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Email"
                              type="email"
                              value={values?.address?.email}
                              name="address.email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.email &&
                                touched?.address?.email &&
                                errors?.address?.email}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Customer Contact
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Customer Contact"
                              type="contact"
                              value={values?.address?.customer_contact}
                              name="address.customer_contact"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.address?.customer_contact &&
                                touched?.address?.customer_contact &&
                                errors?.address?.customer_contact}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Support</h3>
                      </div>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Support Email
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Support Email"
                              type="support_email"
                              value={values?.support_email}
                              name="support_email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.support_email &&
                                touched.support_email &&
                                errors.support_email}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Support Phone
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Support Phone"
                              type="support_phone"
                              value={values?.support_phone}
                              name="support_phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.support_phone &&
                                touched.support_phone &&
                                errors.support_phone}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Slack Channel
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Enter Slack Channel"
                              type="slack_channel"
                              defaultValue={values?.slack_channel}
                              name="slack_channel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.slack_channel &&
                                touched?.slack_channel &&
                                errors?.slack_channel}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Order</h3>
                      </div>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Min Order Value
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Min Order Value"
                              type="min_order_value"
                              value={values?.min_order_value}
                              name="min_order_value"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.min_order_value &&
                                touched.min_order_value &&
                                errors.min_order_value}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Delivery Charges
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Delivery Charges"
                              type="number"
                              step="0.01"
                              value={values?.delivery_charge}
                              name="delivery_charge"
                              onChange={handleChange}
                              min={0}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (!/^\d*$/.test(value)) {
                                } else {
                                  setFieldValue("delivery_charge", value);
                                }
                              }}
                            />
                            <p className="ErrorMessage">
                              {errors.delivery_charge &&
                                touched.delivery_charge &&
                                errors.delivery_charge}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Admin User Info</h3>
                      </div>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              First Name
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter First Name"
                              type="firstname"
                              value={values?.firstname}
                              name="firstname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.firstname &&
                                touched.firstname &&
                                errors.firstname}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Last Name
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Last Name"
                              type="lastname"
                              value={values?.lastname}
                              name="lastname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.lastname &&
                                touched.lastname &&
                                errors.lastname}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Admin Email
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Admin Email"
                              type="admin_email"
                              value={values?.admin_email}
                              name="admin_email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.admin_email &&
                                touched.admin_email &&
                                errors.admin_email}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Admin Phone Number
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Admin Phone Number"
                              type="admin_phone"
                              value={values?.admin_phone}
                              name="admin_phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.admin_phone &&
                                touched.admin_phone &&
                                errors.admin_phone}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Is Verified
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Is Verified"
                              type="select"
                              value={values?.is_verified}
                              name="is_verified"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Is Verified</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </Input>
                            <p className="ErrorMessage">
                              {errors.is_verified &&
                                touched.is_verified &&
                                errors.is_verified}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Location</h3>
                      </div>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Latitude
                            </label>
                            <Input
                              className={styles.lat_input}
                              id="example3cols2Input"
                              placeholder="Enter Latitude"
                              type="number"
                              value={values?.location?.lat}
                              name="location.lat"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.location?.lat &&
                                touched.location?.lat &&
                                errors.location?.lat}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Longitude
                            </label>
                            <Input
                              className={styles.lat_input}
                              id="example3cols2Input"
                              placeholder="Enter Longitude"
                              type="number"
                              value={values?.location?.lng}
                              name="location.lng"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.location?.lng &&
                                touched.location?.lng &&
                                errors.location?.lng}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <h3 className="mb-4 mt-4">Pick Up</h3>
                      </div>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Pick Up Enabled
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Pick Up Enabled"
                              type="select"
                              value={values?.pickup_enabled}
                              name="pickup_enabled"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select an option</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </Input>
                            <p className="ErrorMessage">
                              {errors.pickup_enabled &&
                                touched.pickup_enabled &&
                                errors.pickup_enabled}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Pick Up Time
                            </label>
                            <div className="d-flex align-items-center">
                              <Input
                                id="example3cols2Input"
                                placeholder="Enter Pick Up Time"
                                type="number"
                                value={values?.pick_up_time}
                                name="pick_up_time"
                                onChange={handleChange}
                                min={0}
                                max={60}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  if (!/^\d*$/.test(value)) {
                                  } else {
                                    setFieldValue("pick_up_time", value);
                                  }
                                }}
                                onInput={(e) => {
                                  if (e.target.value > 60) {
                                    e.target.value = 60;
                                    alert("Value cannot be greater than 60");
                                  }

                                  if (e.target.value < 0) {
                                    e.target.value = 0;
                                    alert("Value cannot be less than 0");
                                  }

                                  if (e.target.value) {
                                    let inputVolume = e.target.value.toString();
                                    let pickUpTime = parseInt(inputVolume);
                                    e.target.value = pickUpTime;
                                  }
                                }}
                              />
                              <Input
                                type="select"
                                name="pick_up_time_unit"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="mins">mins</option>
                              </Input>
                            </div>
                            <p className="ErrorMessage">
                              {errors.pick_up_time &&
                                touched.pick_up_time &&
                                errors.pick_up_time}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* <Row>
                      <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Elastic url
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Elastic Url"
                              type="elastic_url"
                              value={values?.elastic_url}
                              name="elastic_url"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Shopify client store url
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Shopify Client Store Url"
                              type="shopify_client_store_url"
                              value={values?.shopify_client_store_url}                          
                              name="shopify_client_store_url"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Shopify admin store url
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Shopify Admin Store Url"
                              type="shopify_admin_store_url"
                              value={values?.shopify_admin_store_url}
                              name="shopify_admin_store_url"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Shopify client access token
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Shopify client Access Token"
                              type="shopify_client_accessToken"
                              value={values?.shopify_client_accessToken}
                              name="shopify_client_accessToken"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Shopify admin access token
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Shopify Admin Access Token"
                              type="shopify_admin_accessToken"
                              value={values?.shopify_admin_accessToken}
                              name="shopify_admin_accessToken"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        </Row> */}
                      <div>
                        <h3 className="mb-4 mt-4">Operation Hours</h3>
                      </div>
                      <Row>
                        <Col md="2" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Delivery Start Time
                            </label>
                            <Input
                              id="time"
                              type="time"
                              value={values?.operation_hours?.st}
                              name="operation_hours.st"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.operation_hours?.st &&
                                touched?.operation_hours?.st &&
                                errors?.operation_hours?.st}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="2" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Delivery End Time
                            </label>
                            <Input
                              id="example-datetime-local-input"
                              type="time"
                              value={values?.operation_hours?.et}
                              name="operation_hours.et"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors?.operation_hours?.et &&
                                touched?.operation_hours?.et &&
                                errors?.operation_hours?.et}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              <h3>Operation Email</h3>
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Enter Operation Email"
                              type="operation_email"
                              value={values?.operation_email}
                              name="operation_email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.operation_email &&
                                touched.operation_email &&
                                errors.operation_email}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* <div>
                        <h3 className="mb-4 mt-4">Delivery Area</h3>
                      </div>
                      <FieldArray
                        name="zip_codes"
                        render={(arrayHelpers) => (
                          <>
                            {values.zip_codes.map((friend, index) => (
                              <Row>
                                <Col md="2" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols1Input"
                                    >
                                      Zip Codes
                                    </label>
                                    <Input
                                      id="example3cols1Input"
                                      placeholder="Enter Zip Codes"
                                      type="zip_codes"
                                      // value={values.zip_codes}
                                      name={`zip_codes[${index}].code`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </FormGroup>
                                </Col> */}
                      {/* <Col md="2" sm="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="example3cols2Input"
                                    >
                                      Zip Priority
                                    </label>
                                    <Input
                                      id="exampleFormControlSelect1"
                                      type="select"
                                      value={values.priority}
                                      name={`zip_codes.${index}.priority`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value="1" name="1">
                                        1
                                      </option>
                                      <option value="2" name="2">
                                        2
                                      </option>
                                      <option value="3" name="3">
                                        3
                                      </option>
                                      <option value="4" name="4">
                                        4
                                      </option>
                                      <option value="5" name="5">
                                        5
                                      </option>
                                      <option value="6" name="6">
                                        6
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col> */}
                      {/* <button
                                  type="button"
                                  className="PrimaryButton"
                                  style={{
                                    width: "21px",
                                    height: "25px",
                                    marginTop: "40px",
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </button>
                              </Row>
                            ))}
                            <button
                              type="button"
                              className="PrimaryButton"
                              onClick={() =>
                                arrayHelpers.push({ code: "", priority: "" })
                              }
                            >
                              +
                            </button>
                          </>
                        )}
                      /> */}

                      {/* <div>
                        <h3 className="mb-6 mt-6"></h3>
                      </div> */}

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              <h3>Zip Codes</h3>
                            </label>
                            <TagsInput
                              value={options}
                              onChange={(e) => {
                                setOptions(e);
                                setFieldValue("zip_code", e);
                              }}
                              name="zip_code"
                              placeHolder="Enter Zip Codes"
                              type="zip_code"
                            />
                            <p className="ErrorMessage">
                              {errors.zip_code &&
                                touched.zip_code &&
                                errors.zip_code}
                            </p>
                          </FormGroup>
                          <p></p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="2" sm="6">
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="customCheck1"
                                type="checkbox"
                                value={values.delivery_enabled}
                                name="delivery_enabled"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck1"
                              >
                                Enable Delivery
                              </label>
                            </div>
                            <p className="ErrorMessage">
                              {errors.delivery_enabled &&
                                touched.delivery_enabled &&
                                errors.delivery_enabled}
                            </p>
                          </FormGroup>
                        </Col>

                        {/* <Col md="2" sm="6">
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="customCheck2"
                                type="checkbox"
                                value={values.is_shipping_enabled}
                                name="is_shipping_enabled"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck2"
                              >
                                Enable Shipping
                              </label>
                            </div>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      <div>
                        <Button
                          className="PrimaryButton"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit Form
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(Elements);
