import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  UncontrolledAlert,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import { logUserMetrics } from "../../../utils/file";
import { useHistory, withRouter, useParams } from "react-router-dom";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BlackListUsersEdit = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [orderNum, setOrderNum] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [orderDetail, setOrderDetail] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [userData, setUserData] = useState([]);
  const [initialData, setInitialData] = useState({
    customer_id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    reason: "",
    order_number: "",
  });

  let { id } = useParams();

  //   const getTenantsList = () => {
  //     setIsLoading(true);
  //     let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
  //     fetch(getTenantsApi)
  //       .then((response) => response.json())
  //       .then((res) => {
  //         console.log(res);
  //         const responseTenants = res?.data;
  //         console.log("ReSpinse 57", res);
  //         let sugg = [];
  //         for (let i in responseTenants) {
  //           sugg.push({
  //             value: responseTenants[i].id,
  //             label: responseTenants[i].business_name,
  //           });
  //         }
  //         setCustomers(sugg);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log("51954949", err);
  //       });
  //   };

  //   useEffect(() => {
  //     getTenantsList();
  //   }, []);

  const getBlackListUserDeatil = () => {
    setIsLoading(true);
    let getBlackListUser = `${ApiEndpoints.blackListUserDeatil}?id=${id}`;

    fetch(getBlackListUser)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("response", res);
        const responseBlackListUser = res?.data;
        // let data = responseBlackListUser?.data?.map((item) => ({
        //   ...item,
        //   value: item?.id,
        //   label: item.order_number,
        // }));
        // console.log("darsts", data);
        setOrderNum(responseBlackListUser?.order_number);
        setUserData(responseBlackListUser);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  console.log("orderNumber", orderNumber);
  useEffect(() => {
    getBlackListUserDeatil();
  }, []);

  const getOrder = () => {
    setIsLoading(true);
    let getBlackListUser = `${ApiEndpoints.priceOrderList}?page=1&limit=2000`;

    fetch(getBlackListUser)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log("response", res);
        const responseOrder = res?.data;
        console.log("responseorder", responseOrder);

        let updatedData = res?.data?.map((item) => ({
          ...item,
          value: item?.id,
          label: item.order_number,
        }));
        setOrderDetail(updatedData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getOrder();
  }, []);

  const handleOrderDetail = (item) => {
    console.log("ITEM SDD", item);

    let IdOrder = item?.pk.split("#")[1];
    console.log("ITEM ORDER NUMBER", IdOrder);
    setOrderId(IdOrder);

    let orderName = item?.order_number;
    setOrderNumber(orderName);
  };

  const getOrderById = (orderId) => {
    fetch(ApiEndpoints.orderGetById + orderId)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        console.log("orderdatabyid", data);
        let response = data?.data?.orderDetail;
        console.log("orderdetailsbyid", response);
        setInitialData({
          customer_id:
            response?.customer?.default_address?.customer_id.toString(),
          name: response?.customer?.default_address?.name,
          email: response?.customer?.email,
          phone: response?.customer?.default_address?.phone,
          address: "",
          reason: "",
          order_number: "",
        });
      });

    setIsLoading(false);
  };

  useEffect(() => {
    if (orderId) {
      getOrderById(orderId);
    } else if (orderId === undefined) {
      setInitialData({
        customer_id: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        reason: "",
        order_number: "",
      });
    }
  }, [orderId]);

  console.log("Initial Data", initialData);

  console.log("Order Id", orderId);

  const getAllUserList = async () => {
    setIsLoading(true);
    let url = `${ApiEndpoints.consumersList}?page=1&limit=30`;
    const res = await axios.get(url);
    console.log("REs", res);
    let updatedData = res?.data?.data;
    // ?.map((item) => ({
    //   ...item,
    //   value: item?.id,
    //   label: item.firebase_id,
    // }));
    let tempData = [...updatedData];
    let arr = [];
    tempData.map((item, i) =>
      arr.push({
        ...item,
        id: Number(item.id),
        //name: item?.first_name + " " + item?.last_name + " " + item?.email,
      })
    );

    console.log("All users", arr);
    setAllUser(arr);
  };

  console.log("AlluserList", allUser);

  useEffect(() => {
    getAllUserList();
  }, []);

  // const handleSearch = (arr, str) => {
  //   console.log("ARRR", arr);
  //   let res = arr.filter(
  //     (item) =>
  //       (item &&
  //         item["email"] &&
  //         item["email"]?.toLowerCase().includes(str.toLowerCase())) ||
  //       item["phone"]?.toLowerCase().includes(str.toLowerCase()) ||
  //       item["first_name"]?.toLowerCase().includes(str.toLowerCase()) ||
  //       item["last_name"]?.toLowerCase().includes(str.toLowerCase())
  //   );
  //   console.log("RESULT", res);
  //   return res;
  // };

  // const handleOnSearch = (string, results) => {
  //   // onSearch will have as the first callback parameter
  //   // the string searched and for the second the results.
  //   setSearchText(string);
  //   console.log("STRING", string, results);

  //   let searchRes = handleSearch(allUser, string);
  //   console.log("SEra", searchRes);
  //   setAllUser(searchRes);
  // };

  // // function onClear() {
  // //   let products = [];
  // //   for (let i = 0; i < exportProducts.length; i++) {
  // //     products.push({
  // //       sku: exportProducts[i]["sku"],
  // //       upc: exportProducts[i]["upc"],
  // //       price: exportProducts[i]["price"],
  // //       name: exportProducts[i]["name"],
  // //       ls_qty: exportProducts[i]["ls_qty"],
  // //       status: exportProducts[i]["status"],
  // //     });
  // //   }
  // //   console.log("asdsad4as84", products);
  // //   setData(products);
  // // }

  // const handleOnSelect = (item) => {
  //   const exists = allUser.find((p) => p.id === item.id);
  //   console.log("exists", exists, item);
  // };

  // const formatResult = (item) => {
  //   console.log("ITEMS ,it", item);
  //   return (
  //     <>
  //       {/* <span style={{ display: "block", textAlign: "left" }}>
  //         id: {item.id}
  //       </span> */}
  //       <span style={{ display: "block", textAlign: "left" }}>{item}</span>
  //     </>
  //   );
  // };

  const upadteBlackList = async (data) => {
    setIsLoading(true);

    let reqBody = {
      id: id,
      order_number: orderNum,
      customer_id: data.customer_id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      reason: data.reason,
      updated_by: localStorage.getItem("fullname"),
    };
    console.log("reqbody", reqBody);
    try {
      let res = await axios.put(ApiEndpoints.blackListUserUpdate, reqBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setIsLoading(false);
        toast.success("Blacklisted updated successfully");
        history.push("/admin/blacklist");
        let action = "blacklist-update";
        let type = "Info";
        let message = "BlackList successfully updated.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      let action = "blacklist-update";
      let type = "Error";
      let message = "Error in blackList update";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleInputChange = (event, setFieldValue) => {
    console.log("event for id", event.target.value);
    const userInput = event.target.value;
    setFieldValue("customer_id", userInput);
    const filteredData = allUser.filter(
      (user) =>
        user.shopify_id?.toLowerCase().includes(userInput) ||
        user.email?.toLowerCase().includes(userInput) ||
        user.first_name?.toLowerCase().includes(userInput) ||
        user.last_name?.toLowerCase().includes(userInput)
    );
    console.log("filtered Data", filteredData, userInput);
    setFilteredUser(filteredData);
    if (userInput) {
      setFilteredUser(filteredData);
    } else {
      setFilteredUser([]);
    }
    if (showSuggestion === false) {
      setShowSuggestion(true);
    }
  };

  const handleSelectUser = (selectedUserId, setFieldValue) => {
    const selectedUser = allUser.find((user) => user.id === selectedUserId);
    console.log("selectedUser", selectedUser, selectedUserId);
    setFieldValue("customer_id", selectedUser?.shopify_id);
    setShowSuggestion(false);
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Edit BlackList"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {orderNum ? (
                  <Row>
                    <Col md="4" sm="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols2Input"
                        >
                          Order No
                        </label>
                        {
                          orderNum ? (
                            <Input
                              id="order_number"
                              placeholder="Enter Order Number"
                              type="text"
                              readOnly
                              name="order_number"
                              value={orderNum}
                            />
                          ) : null
                          // (
                          //   <Select
                          //     placeholder="Enter Order Number"
                          //     className="basic-single"
                          //     classNamePrefix="select"
                          //     isSearchable={true}
                          //     name="order_number"
                          //     options={orderDetail}
                          //     isMulti={false}
                          //     isClearable
                          //     // value={[{ value: "hghg", label: "rdgdgfd" }]}
                          //     onChange={handleOrderDetail}
                          //     styles={{
                          //       control: (baseStyles, state) => ({
                          //         ...baseStyles,
                          //         height: "46px",
                          //         zIndex: 1000,
                          //       }),
                          //       container: (baseStyles) => ({
                          //         ...baseStyles,
                          //         zIndex: 1000,
                          //       }),
                          //     }}
                          //   />
                          // )
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}
                <Formik
                  initialValues={userData}
                  enableReinitialize={true}
                  validate={(values) => {
                    let value = values;
                    console.log(values);
                    const errors = {};

                    console.log("errors", errors);

                    var pattern = new RegExp(/^[0-9\b]+$/);

                    // if (!values.customer_id) {
                    //   errors.customer_id = "Field Required!";
                    // }

                    // if (!values.name) {
                    //   errors.name = "Field Required!";
                    // }

                    // if (!values.email) {
                    //   errors.email = "Field Required";
                    // } else if (
                    //   !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    //     values.email
                    //   )
                    // ) {
                    //   errors.email = "Invalid email address";
                    // }

                    // if (!values.phone) {
                    //   errors.phone = "Field Required";
                    // } else if (!pattern.test(values.phone)) {
                    //   errors.phone = "Please enter only number.";
                    // } else if (values.phone.length != 10) {
                    //   errors.phone = "Please enter valid phone number.";
                    // }

                    // if (!values.address) {
                    //   errors.address = "Field Required!";
                    // }

                    if (!values.reason) {
                      errors.reason = "Field Required!";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    upadteBlackList(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form>
                      <Row>
                        {/* <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example12cols2Input"
                              // style={{ fontWeight: 700 }}
                            >
                              Customer Id
                            </label>
                            <ReactSearchAutocomplete
                              items={allUser}
                              onSearch={handleOnSearch}
                              // onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              formatResult={formatResult}
                              // onFocus={handleOnFocus}
                              placeholder="Search Customer Id"
                              // autoFocus
                              // maxResults={10}
                              showItemsOnFocus={true}
                              showIcon={false}
                              // onClear={onClear}
                              styling={{
                                marginTop: "0px",
                                borderRadius: "5px",
                                zIndex: 100,
                              }}
                              resultStringKeyName={"shopify_id"}
                              fuseOptions={{
                                keys: ["first_name", "last_name", "email"],
                              }}
                              name="customer_id"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Customer ID
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="customer_id"
                              placeholder="Enter Customer Id"
                              type="text"
                              name="customer_id"
                              value={values.customer_id}
                              onChange={(e) =>
                                handleInputChange(e, setFieldValue)
                              }
                            />
                            {showSuggestion &&
                              filteredUser &&
                              filteredUser.length > 0 && (
                                <div
                                  style={{
                                    border: "1px solid #f1f1f1",
                                    height: "125px",
                                    width: "90%",
                                    overflowY: "scroll",
                                    position: "absolute",
                                    background: "white",
                                    zIndex: 100,
                                    cursor: "pointer",
                                  }}
                                >
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    {filteredUser.map((user) => (
                                      <li
                                        key={user.id}
                                        onClick={() =>
                                          handleSelectUser(
                                            user.id,
                                            setFieldValue
                                          )
                                        }
                                        style={{
                                          // margin: "5px 0px 5px 0px",
                                          fontSize: "13px",
                                          borderTop:
                                            "1px solid rgb(222, 226, 230)",
                                          padding: "10px",
                                        }}
                                      >
                                        {user.email}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              Name
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="example3cols2Input"
                              placeholder="Enter Name"
                              type="text"
                              value={values.name}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.name && touched.name && errors.name}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input"
                            >
                              E-mail
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="example3cols2Input"
                              placeholder="Enter E-mail"
                              type="email"
                              value={values.email}
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Phone
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Phone"
                              type="phone"
                              value={values.phone}
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.phone && touched.phone && errors.phone}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Address
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Address"
                              type="text"
                              value={values.address}
                              name="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.address &&
                                touched.address &&
                                errors.address}
                            </p>
                          </FormGroup>
                        </Col>
                        {/* <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Reason For BlackList
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Reason For BlackList"
                              type="textarea"
                              value={values.reason}
                              name="reason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.reason && touched.reason && errors.reason}
                            </p>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col md="4" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Reason For BlackList
                            </label>
                            <Input
                              readOnly={orderNum ? true : false}
                              id="example3cols1Input"
                              placeholder="Enter Reason For BlackList"
                              type="textarea"
                              value={values.reason}
                              name="reason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="ErrorMessage">
                              {errors.reason && touched.reason && errors.reason}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      {!orderNum ? (
                        <div>
                          <Button
                            className="PrimaryButton"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Update Blacklist
                          </Button>
                        </div>
                      ) : null}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BlackListUsersEdit;
