import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  UncontrolledAlert,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardGroup,
  CardTitle,
  CardText,
  InputGroupText,
  InputGroup,
  ButtonGroup,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import "./style.css";
import { ApiEndpoints } from "../../constants/constant.js";
import axios from "axios";
import CustomModal from "../../components/CustomModal.js";
import Loader from "../../../onboarding/components/Loader.js";

const baseAWSAPIURL = require("../../config.js").baseAWSAPIURL;

const profileImgUUid = uuidv4();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ddOptions = [
  { value: "email_user", label: "Email User" },
  { value: "guest_user", label: "Guest User" },
  { value: "phone_user", label: "Phone User" },
  { value: "gmail_user", label: "Gmail User" },
];

// const topicOptions = [
//   { value: "topic1", label: "Miami" },
//   { value: "topic2", label: "Florida" },
//   { value: "topic3", label: "Christmas" },
//   { value: "topic4", label: "Holi" },
//   { value: "topic5", label: "Diwali" },
//   { value: "topic6", label: "Miramar" },
//   { value: "topic7", label: "Tampa 1" },
//   { value: "topic8", label: "Tampa 2" },
//   { value: "topic9", label: "Jensen" },
// ];

function NotificationTarget({
  values,
  setFieldValue,
  errors,
  touched,
  handleNext,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedTab, setSelectedTab] = useState("UserSegment");
  const [selectedUserType, setSelectedUserType] = useState("all");
  const [allUser, setAllUser] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [filteredData, setFilteredData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [search, setSearch] = useState(false);
  const [selectedListData, setSelectedListData] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  let url = ApiEndpoints.consumersList + "?page=1" + "&limit=10";
  const handleSearch = async (searchQuery) => {
    // Perform the search logic here
    setIsLoading(true);
    let res = await axios.get(url + "&searchKeyword=" + searchQuery);
    console.log("Result user", res);
    let result = res?.data?.data;
    // let tempData = [...allUser];
    // console.log("TEMPDATA", tempData, searchQuery);
    // const result = tempData.filter(
    //   (item) =>
    //     item?.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item?.last_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    setFilteredData(result);
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      handleSearch(searchKeyword);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (!modal) {
      handleClearSearch();
    }
  }, [modal]);
  const handleSearchDropdown = async (selected) => {
    console.log("SElected", selected);
    setIsLoading(true);
    // Perform the search logic here
    // let tempData = [...allUser];

    // // If selected values exist, filter data based on user_type
    // if (selected.length > 0) {
    //   tempData = tempData.filter((user) =>
    //     selected.some(
    //       (selectedOption) => user.user_type === selectedOption.value
    //     )
    //   );
    // }

    const res = await axios.get(url + "&user_type=" + selected?.value);
    console.log("Rsp", res);
    let tempData = res?.data?.data;
    console.log("TEMPDATA", tempData, selected);
    setFilteredData(tempData);
    setIsLoading(false);
  };

  let appliesRef = useRef();

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  // const getTenantsList = () => {
  //   setIsLoading(true);
  //   let getTenantsApi = `${ApiEndpoints.tenantApi}?limit=500&offset=1`;
  //   fetch(getTenantsApi)
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log(res);
  //       const responseTenants = res?.data;
  //       console.log("ReSpinse 57", res);
  //       let sugg = [];
  //       responseTenants.map((item) => {
  //         return sugg.push({
  //           value: item.id,
  //           label: item.business_name,
  //         });
  //       });
  //       setSuggestions(sugg);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log("51954949", err);
  //     });
  // };

  // useEffect(() => {
  //   getTenantsList();
  // }, []);

  // const handleSelectChange = (selectedOptions) => {
  //   setSelectedValues(selectedOptions);
  //   const selectedValuesIds = selectedOptions.map((option) => option.value);
  //   setTenantList(selectedValuesIds);
  // };

  const getAllUserList = async () => {
    setIsLoading(true);
    const res = await axios.get(url);
    console.log("REs", res);
    let updatedData = res?.data?.data?.map((item) => ({
      ...item,
      value: item?.id,
      label: item.firebase_id,
    }));
    setAllUser(updatedData);
    setIsLoading(false);
  };

  useEffect(() => {
    console.log("Values users", values.users);
    if (values?.users?.length > 0 && Array.isArray(values.users)) {
      console.log("if sele");
      setSelectedUserType("individual");
      setSelectedListData(values?.users);
      getAllUserList();
    } else {
      console.log("if sele els");
      getAllUserList();
    }
  }, []);

  useEffect(() => {
    if (selectedUserType == "individual") {
      toggle();
    }
  }, [selectedUserType]);
  // useEffect(() => {
  //   if (search) {
  //     handleSearch(searchKeyword);
  //   }
  // }, [search]);

  const handleDropdown = (selectedValues) => {
    console.log("SElected values", selectedValues);
    let tempData = [...allUser];
  };

  const handleCheckboxChange = (item) => {
    // Check if the item is already selected
    const isSelected = selectedListData.some(
      (selectedItem) => selectedItem.id === item.id
    );

    // Update selectedListData based on the checkbox change
    if (isSelected) {
      // Item is already selected, remove it
      setSelectedListData((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      // Item is not selected, add it
      setSelectedListData((prevSelected) => [...prevSelected, item]);
    }
  };

  const handleDeleteSelected = (item) => {
    // Remove the selected item from selectedListData
    setSelectedListData((prevSelected) =>
      prevSelected.filter((selectedItem) => selectedItem.id !== item.id)
    );
  };

  const handleClearSearch = () => {
    setSearchKeyword("");
    //let tempData = [...allUser];
    setFilteredData([]);
  };

  const handleSelectChange = (selectedOption) => {
    console.log("VALUES", values, errors);
    console.log("Selected option", selectedOption);
    // Assuming the selected option has a 'value' property
    setFieldValue("topic", selectedOption.label);
    setFieldValue("users", []);
  };

  useEffect(() => {
    if (selectedListData.length) {
      let tempData = [...selectedListData];
      setFieldValue("users", tempData);
    } else {
      setFieldValue("users", "all");
    }
  }, [selectedListData.length]);

  const getTopics = async () => {
    setIsLoading(true);
    try {
      let url = ApiEndpoints.topicNotification;
      let reqBody = {};
      const topicRes = await axios.post(url, reqBody);

      console.log("Topic res", topicRes);

      if (topicRes.status == 200) {
        let items = topicRes?.data?.data;
        let tempData = [...items];
        let updatedData = tempData.map((item) => ({
          ...item,
          value: item.id,
          label: item.topic,
        }));

        setTopicOptions(updatedData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("topic error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab == "Topic") {
      getTopics();
    }
  }, [selectedTab]);

  console.log("Selected list", selectedListData);

  // if (isLoading) {
  //   return (
  //     <>
  //       <Loader />
  //     </>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            {/* <Card>
              <CardBody> */}
            {successMsg != "" && (
              <UncontrolledAlert color="success">
                <span className="alert-text ml-1">{successMsg}</span>
              </UncontrolledAlert>
            )}
            {errMsg != "" && (
              <UncontrolledAlert color="danger">
                <span className="alert-text ml-1">{errMsg}</span>
              </UncontrolledAlert>
            )}
            {errorMsg != "" && (
              <UncontrolledAlert color="danger">
                <span className="alert-text ml-1">
                  {errorMsg.map((itemEr) => (
                    <p>{itemEr.error}</p>
                  ))}
                </span>
              </UncontrolledAlert>
            )}

            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color={
                          selectedTab === "UserSegment"
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => handleTabChange("UserSegment")}
                      >
                        User Segment
                      </Button>
                      <Button
                        size="sm"
                        color={
                          selectedTab === "Topic" ? "primary" : "secondary"
                        }
                        onClick={() => handleTabChange("Topic")}
                      >
                        Topic
                      </Button>
                    </ButtonGroup>
                  </Label>
                  {selectedTab === "UserSegment" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <InputGroup>
                          <InputGroupText
                          //className="selectOptions"
                          >
                            Customer
                          </InputGroupText>
                          <Input
                            className="customer"
                            type="select"
                            value={selectedUserType}
                            onChange={(e) =>
                              setSelectedUserType(e.target.value)
                            }
                          >
                            <option value="all">All</option>
                            <option value="individual">Individual</option>
                          </Input>
                        </InputGroup>
                        {selectedUserType === "individual" ? (
                          <Button
                            size="md"
                            onClick={toggle}
                            color="primary"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {/* {selectedListData?.length > 0
                        ? "Add User"
                        : "Remove User"} */}
                            Edit User
                          </Button>
                        ) : null}
                        {/* <Button onClick={() => setModal(true)}>
                                Edit
                              </Button> */}
                        {/* {selectedUserType === "individual" && (
                              <InputGroup>
                                <Select
                                  placeholder="Search individual"
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isSearchable={true}
                                  name="individual"
                                  isMulti={true}
                                  options={allUser}
                                  // value={selectedValues}
                                  // onChange={handleSelectChange}
                                  styles={{
                                    container: (baseStyles) => ({
                                      ...baseStyles,
                                      height: "auto",
                                      width: "auto",
                                      minWidth: "60%",
                                      marginTop: "20px",
                                      // borderRadius: "5px",
                                      // border: "1px solid #9d9d9d",
                                    }),
                                    placeholder: (styles) => ({
                                      ...styles,
                                      fontSize: 15,
                                      color: "#adb5bd",
                                    }),
                                    menuList: (styles) => ({
                                      ...styles,
                                      fontSize: 13,
                                      fontWeight: 500,
                                    }),
                                    multiValue: (styles) => ({
                                      ...styles,
                                      fontSize: 15,
                                    }),
                                    groupHeading: (styles) => ({
                                      ...styles,
                                      fontWeight: 1000,
                                    }),
                                  }}
                                />
                              </InputGroup>
                            )} */}
                        <CustomModal
                          modal={modal}
                          //toggle={toggle}
                          setModal={setModal}
                          listData={allUser}
                          filteredListData={filteredData}
                          srcStr={searchKeyword}
                          setSrcStr={(e) => setSearchKeyword(e)}
                          setSearch={(e) => {
                            console.log(
                              "TEMPDATA E",
                              e,

                              searchKeyword
                            );
                            handleSearch(e);
                          }}
                          heading="Add Users"
                          ddOptions={ddOptions}
                          handleDropdown={handleSearchDropdown}
                          handleCheckboxChange={handleCheckboxChange}
                          selectedListData={selectedListData}
                          handleClearSearch={handleClearSearch}
                        />
                      </div>
                      {selectedListData?.length > 0 &&
                        Array.isArray(selectedListData) &&
                        selectedUserType == "individual" && (
                          <div>
                            <Row>
                              <Col lg="12">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      {/* <th>Firebase Id</th> */}
                                      <th>Email</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedListData?.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          {item?.first_name +
                                            " " +
                                            item?.last_name}
                                        </td>
                                        {/* <td>{item?.firebase_id}</td> */}
                                        <td>{item?.email}</td>
                                        <td>
                                          <span
                                            aria-hidden="true"
                                            style={{
                                              marginRight: "30px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                            // onClick={() =>
                                            //   // handleRemoveItem(
                                            //   //   items.variant_id
                                            //   // )
                                            // }
                                            onClick={() =>
                                              handleDeleteSelected(item)
                                            }
                                          >
                                            {selectedListData.length > 0 ? (
                                              <i className="fas fa-trash-alt"></i>
                                            ) : (
                                              "X"
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </div>
                        )}
                    </>
                  )}
                  {selectedTab === "Topic" && (
                    <FormGroup>
                      <Label tag="h5">Message Topic</Label>
                      {/* <Input
                              className="selectOptions"
                              type="select"
                            ></Input> */}
                      <Select
                        options={topicOptions}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "calc(1.5em + 1.25rem)",
                          }),
                        }}
                        value={topicOptions.find(
                          (option) => option.label === values.topic
                        )}
                        onChange={handleSelectChange}
                        // style={{ height: "calc(1.5em + 1.25rem + 5px)" }}
                      />
                    </FormGroup>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {/* </CardBody>
            </Card> */}
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default NotificationTarget;
