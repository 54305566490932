import React, { useEffect, useState } from "react";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Auth } from "aws-amplify";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  UncontrolledAlert,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  Row,
} from "reactstrap";

function ChangePassword({ userDeatil }) {
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  });

  const handleBack = () => {
    const viewPath = "/admin";
    history.push(viewPath);
  };

  const updatePassword = async (data, setSubmitting) => {
    console.log(data);
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        return Auth.changePassword(user, data.oldPassword, data.newPassword);
      })
      .then(async(data) => {
        if (data === "SUCCESS") {
          setSuccessMsg("Password changed successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        setIsLoading(false);
        let action = "password-update"
          let type = "Info";
          let message = "Password successfully updated.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
      })
      .catch(async(err) => {
        setIsLoading(false);
        setErrorMsg(err.message);
        let action = "password-update"
          let type = "Error";
          let message = "Error in Password update.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
      });
    setSubmitting(false);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Change Password"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => handleBack()}
                                            size="sm"
                                        >
                                            Back To Dashboard
                                        </Button>
                                    </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-text ml-1">{errorMsg}</span>
                  </UncontrolledAlert>
                )}
                <Formik
                  initialValues={{}}
                  validate={(values) => {
                    values = { ...values };
                    const errors = {};
                    {
                      /* Insert all form errors here */
                    }
                    if (!values.oldPassword) {
                      errors.oldPassword = "Required!";
                    }

                    if (!values.newPassword) {
                      errors.newPassword = "Required";
                    }

                    if (!values.confirmPassword) {
                      errors.confirmPassword = "Required";
                    } else if (values.confirmPassword != values.newPassword) {
                      errors.confirmPassword = "Passwords didn't match!";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    updatePassword(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <label className="form-control-label">
                          Old Password
                        </label>
                        <Input
                          className="FormTextField"
                          placeholder="Enter Old Password"
                          type="password"
                          name="oldPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.oldPassword}
                        />
                        <p className="ErrorMessage">
                          {errors.oldPassword &&
                            touched.oldPassword &&
                            errors.oldPassword}
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <label className="form-control-label">
                          New Password{" "}
                        </label>
                        <Input
                          className="FormTextField"
                          placeholder="Enter New Password"
                          type="password"
                          name="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                        />
                        <p className="ErrorMessage">
                          {errors.newPassword &&
                            touched.newPassword &&
                            errors.newPassword}
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <label className="form-control-label">
                          Confirm Password{" "}
                        </label>
                        <Input
                          className="FormTextField"
                          placeholder="Enter Confirm Password"
                          type="password"
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />
                        <p className="ErrorMessage">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </p>
                      </FormGroup>

                      <FormGroup>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting || buttonDisabled}
                        >
                          Change Password
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ChangePassword;
