import React, { useState } from "react";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../app/redux/auth/actions";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  UncontrolledAlert,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  Row,
} from "reactstrap";

function EmployeesCreate() {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const history = useHistory();

  const create = async (data, setSubmitting) => {
    // console.log(JSON.stringify(data));
    setFormData(data);
    setIsLoading(true);
    dispatch(registerUser(data, history, dispatch));
    setSubmitting(false);
    setIsLoading(false);
  };

  const handleView = () => {
    const viewPath = "/admin/users";
    history.push(viewPath);
  };

  return (
    <>
      <Header name="Employees" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="1"></Col>
          <Col lg="10">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0" style={{ display: "inline" }}>
                    Create Employee
                  </h3>
                  <Button
                    className="pull-right"
                    color="default"
                    onClick={() => handleView()}
                    size="sm"
                  >
                    Back To All Users
                  </Button>
                </CardHeader>
                <CardBody>
                  {auth && auth.errors && auth.errors.Catcherrors && (
                    <p className="text-danger mb-0">
                      <strong>
                        {auth && auth.errors && auth.errors.Catcherrors}
                      </strong>
                    </p>
                  )}

                  <Formik
                    initialValues={formData}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      if (!values.password) {
                        errors.password = "Required";
                      }
                      if (!values.confirmPassword) {
                        errors.confirmPassword = "Required";
                      } else if (values.confirmPassword != values.password) {
                        errors.confirmPassword = "Passwords didn't match!";
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      create(values, setSubmitting);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            Email address
                          </label>
                          <Input
                            id="exampleFormControlInput1"
                            placeholder="Enter Email"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            name="email"
                          />
                          <p className="ErrorMessage">
                            {errors.email && touched.email && errors.email}
                            {auth && auth.errors && auth.errors.email}
                          </p>
                        </FormGroup>

                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            Password
                          </label>
                          <Input
                            id="exampleFormControlInput1"
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p className="ErrorMessage">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                            {auth && auth.errors && auth.errors.password}
                          </p>
                        </FormGroup>

                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            Confirm Password
                          </label>
                          <Input
                            id="exampleFormControlInput1"
                            placeholder="Enter Confirm Password"
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                          />
                          <p className="ErrorMessage">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                            {auth && auth.errors && auth.errors.confirmPassword}
                          </p>
                        </FormGroup>

                        <FormGroup>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Create Account
                          </Button>
                        </FormGroup>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Col>
          <Col lg="1"></Col>
        </Row>
      </Container>
    </>
  );
}

export default EmployeesCreate;
