
import React from 'react';

function DispatchView() {
    return (
        <>
            <div>Dispatch Page.</div>
        </>

    )
}

export default DispatchView;
