import React, { useMemo, useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import "./style.css";
import { getToken } from "../../../onboarding";
import Header from "../../ui/Header.js";
import { toast } from "react-toastify";
import axios from "axios";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import PriceListTable from "./listTable";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import { number } from "prop-types";
import { set } from "lodash";
import styles from "../../styles/orderlist.module.css";
import {
  CaretUpFill,
  CaretDownFill,
  ArrowDownUp,
  Filter,
  Search,
} from "react-bootstrap-icons";
import { app1, database } from "../../firebase";
import {
  ref,
  query,
  orderByChild,
  limitToFirst,
  limitToLast,
  onValue,
} from "firebase/database";
import Select from "react-select";
import { ApiUrls } from "../../../common/apiconstant";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
let temp = undefined;
let ordersValue = [];
const ordersColumns = [
  {
    header: "Order Name",
    accessor: "id",
  },
  {
    header: "Location Title",
    accessor: "locationTitle",
  },
  {
    header: "Number Of Items",
    accessor: "noOfItems",
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Total Price $",
    accessor: "totalPrice",
  },
  {
    header: "Order Date",
    accessor: "orderDate",
  },
  {
    header: "Delivery Method",
    accessor: "deliveryMethod",
  },
  {
    header: "Source",
    accessor: "source_name",
  },
  {
    header: "Actions",
    accessor: "actions",
  },
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
var size = 50;
var currentDate = new Date();
var lastDate = currentDate.setDate(currentDate.getDate() - 5);
lastDate = new Date(lastDate);
currentDate = new Date();
console.log(currentDate);

function OrdersList(props) {
  // const database = getdatabase(app1);
  // console.log("database",database);
  const classes = useStyles();
  const listName = "orders";
  // const [size,setSize]= useState(10)
  const [isLoading, setIsLoading] = useState(false);
  const [useValue, setUseValue] = useState(0);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [orderLength, setOrderLength] = useState(1);
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sourceDropDown, setSourceDropDown] = useState(false);
  const [deliveryMethodDropDown, setDeliveryMethodDropDown] = useState(false);
  const [orderTypeDropDown, setOrderTypeDropDown] = useState(false);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [size, setSize] = useState(10);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const userLocation = localStorage.getItem("currentLocation");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [sortedPageData, setSortedPageData] = useState([]);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleSourceDropDown = () =>
    setSourceDropDown((prevState) => !prevState);
  const toggleDeliveryMethodDropDown = () =>
    setDeliveryMethodDropDown((prevState) => !prevState);
  const toggleOrderTypeDropDown = () =>
    setOrderTypeDropDown((prevState) => !prevState);
  const toggleFilterDropDown = () =>
    setFilterDropDown((prevState) => !prevState);
  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [tempElm, setTempElm] = useState();
  const [allLocationList, setAllLocationList] = useState();
  const [locationFilter, setLocationFilter] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);
  const [deliveryFilter, setDeliveryFilter] = useState([]);
  const [clear, setClear] = useState(false);

  const isMobile = useMediaQuery("(max-width: 500px)");

  console.log("location", userLocation);

  const history = useHistory();

  const { search } = useLocation();

  console.log("QUERY PARAMS", search);

  // useEffect(() => {
  //   const ordersRef = ref(database, "orders");
  //   const ordersQuery = query(
  //     ordersRef,
  //     // orderByChild("total_price"),
  //     limitToLast(1)
  //   );

  //   const initialOrdersList = onValue(
  //     ordersQuery,
  //     (snapshot) => {
  //       const ordersData = snapshot.val();
  //       const ordersArray = Object.values(ordersData);
  //       let prev = ordersArray[0];
  //       console.log("Sorted Orders Data:", ordersArray);
  //       console.log("tempElm789", temp, ordersArray[0]);
  //       console.log("asdfsdf", temp?.name, ordersArray[0].name);
  //       console.log("typeof", typeof temp?.name, typeof ordersArray[0].name);
  //       console.log("compare", temp?.name == ordersArray[0].name);
  //       if (temp && temp?.name != ordersArray[0].name) {
  //         console.log("inside");
  //         ordersValue.unshift(ordersArray[0]);
  //         setOrders(ordersValue);
  //         toast.success("Order arrived successfully");
  //       }

  //       // setOrders(ordersArray);
  //       setTempElm(ordersArray[0]);
  //       temp = ordersArray[0];
  //       setOrderLength(ordersArray.length);
  //     },
  //     (error) => {
  //       console.log("Error fetching data:", error);
  //     }
  //   );
  //   return () => {
  //     initialOrdersList();
  //   };
  // }, []);

  useEffect(() => {
    const ordersRef = ref(database, "orders");
    const ordersQuery = query(ordersRef, limitToLast(1));

    const initialOrdersList = onValue(
      ordersQuery,
      (snapshot) => {
        const ordersData = snapshot.val();
        const ordersArray = Object.values(ordersData);
        let prev = ordersArray[0];
        console.log("Sorted Orders Data:", ordersArray);
        console.log("tempElm789", temp, ordersArray[0]);
        console.log("asdfsdf", temp?.name, ordersArray[0].name);
        console.log("typeof", typeof temp?.name, typeof ordersArray[0].name);
        console.log("compare", temp?.name == ordersArray[0].name);
        if (temp && temp?.name != ordersArray[0].name) {
          console.log("inside");
          setOrders(ordersValue);
          if (ordersArray[0].order_type !== "test order") {
            ordersValue.unshift(ordersArray[0]);
            toast.success("Order arrived successfully");
          }
        }

        setTempElm(ordersArray[0]);
        temp = ordersArray[0];
        setOrderLength(ordersArray.length);
      },
      (error) => {
        console.log("Error fetching data:", error);
      }
    );
    return () => {
      initialOrdersList();
    };
  }, []);

  console.log("tempElm", tempElm);
  console.log("54561", orders);

  const SortOptions = [
    { id: "name", text: "Order Name", access: "id" },
    { id: "no_of_item", text: "Number Of Items", access: "noOfItems" },
    { id: "total_price", text: "Total Price", access: "totalPrice" },
    { id: "created_at", text: "Order Date", access: "orderDate" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPageNum(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const statusOptions = [
    { value: "Received", label: "Received" },
    { value: "In Progress", label: "In Progress" },
    { value: "Ready for delivery", label: "Ready for delivery" },
    { value: "Ready for pickup", label: "Ready for pickup" },
    { value: "Out for delivery", label: "Out for delivery" },
    { value: "Picked", label: "Picked" },
  ];

  const sourceOptions = [
    { id: "46035795969,47480569857,web", text: "LS Website" },
    { id: "41306324993,6919743", text: "LS Mobile App" },
  ];

  const handleSourceOptions = (sourceId) => {
    setPageNum(1);
    if (selectedSource.includes(sourceId)) {
      setSelectedSource((prevSelected) =>
        prevSelected.filter((id) => id !== sourceId)
      );
    } else {
      setSelectedSource((prevSelected) => [...prevSelected, sourceId]);
    }
  };

  const deliveryMethodOptions = [
    { id: "Local pickup", text: "Local pickup" },
    { id: "delivery", text: "Delivery" },
  ];

  const handleDeliveryMethodOptions = (deliveryMethodId) => {
    setPageNum(1);
    if (selectedDeliveryMethod.includes(deliveryMethodId)) {
      setSelectedDeliveryMethod((prevSelected) =>
        prevSelected.filter((id) => id !== deliveryMethodId)
      );
    } else {
      setSelectedDeliveryMethod((prevSelected) => [
        ...prevSelected,
        deliveryMethodId,
      ]);
    }
  };

  const orderTypeOptions = [{ id: "test order", text: "Show Test Orders" }];

  const handleOrderTypeOptions = (orderTypeId) => {
    setPageNum(1);
    if (selectedOrderType.includes(orderTypeId)) {
      setSelectedOrderType((prevSelected) =>
        prevSelected.filter((id) => id !== orderTypeId)
      );
    } else {
      setSelectedOrderType((prevSelected) => [...prevSelected, orderTypeId]);
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const handleView = (item) => {
    const id = item?.location_id.split("#")[1];
  };

  const columns = useMemo(() => ordersColumns, []);
  const handleChange = async (e) => {
    console.log("EEEE", e.target.value);

    let tempLocation = e.target.value;
    console.log("Before subscribe", tempLocation);
    // debugger;
    // let reqBodyUnsubscribe = {
    //   locationId: localStorage?.getItem("order_location")?.split("#")[1],
    //   token: localStorage?.getItem("FCM_token"),
    // };
    let reqBodySubscribe = {
      locationId: `${tempLocation}`.split("#")[1],
      token: localStorage?.getItem("FCM_token"),
      userId: localStorage?.getItem("userId"),
    };
    let configSubscribe = {
      method: "POST",
      body: JSON.stringify(reqBodySubscribe),
    };

    // let configUnsubscribe = {
    //   method: "POST",
    //   body: JSON.stringify(reqBodyUnsubscribe),
    // };
    //let unsubscribeApi = `${ApiEndpoints.unsubscribeFcm}`;
    let subscribeApi = `${ApiEndpoints.subscribeFcm}`;

    // let unsubscribeRes = await fetch(unsubscribeApi, configUnsubscribe);
    // console.log("UNSUB", unsubscribeRes);

    let subscribeRes = await fetch(subscribeApi, configSubscribe);
    console.log("SUBS", subscribeRes);
    console.log("Before locale storage", tempLocation);
    updateOrderStatus("All");
    setLocationId(tempLocation);
    localStorage.setItem("order_location", tempLocation);

    //console.log("handle change");
    //getOrderList(e?.target?.value, orderStatus, size, currentDate, lastDate);
  };

  const updateOrderStatus = (orderStatus) => {
    setOrders([]);
    setLastEvaluatedKey(null);
    setOrderStatus(orderStatus);
    setPageNum(1);
    setSearchKeyword("");
    currentDate = new Date();
    lastDate = currentDate.setDate(currentDate.getDate() - 5);
    lastDate = new Date(lastDate);
    currentDate = new Date();
    setTimeout(function () {
      getOrderList(locationId, orderStatus, size);
    }, 1000);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const allOrders = (orderStatus) => {
    setOrders([]);
    setLastEvaluatedKey(null);
    setOrderStatus(orderStatus);
    currentDate = new Date();
    lastDate = currentDate.setDate(currentDate.getDate() - 5);
    lastDate = new Date(lastDate);
    currentDate = new Date();
    console.log(currentDate);
    getOrderList(locationId, orderStatus, size, currentDate, lastDate);
  };

  const getOrderList = (
    locationId,
    orderStatus,
    size,
    currentDate,
    lastDate,
    pageNum
  ) => {
    console.log("locationidid", selectedLocation);
    let OrderApi = `${ApiEndpoints.priceOrderList}?page=${pageNum}&limit=10`;
    if (selectedLocation.length > 0) {
      let locationIds = "";
      for (let i in selectedLocation) {
        console.log(
          "vvvvvv",
          locationIds.length,
          i,
          i == 0 && locationIds.length == 0
        );
        if (i == 0 && locationIds.length == 0) {
          locationIds =
            locationIds + "wh%23" + selectedLocation[i].value.split("#")[1];
        } else {
          locationIds =
            locationIds +
            "," +
            "wh%23" +
            selectedLocation[i].value.split("#")[1];
        }
      }
      console.log("locationIds", locationIds);
      console.log("locationIds string", locationIds.toString());

      OrderApi = OrderApi + `&location_id=${locationIds.toString()}`;
    }

    // console.log("selectedStatus",selectedStatus);
    // console.log("selectedStatus.length",selectedStatus.length);
    // if (statusFilter.length > 0) {
    //   let orderStatusString = statusFilter.join(',');
    //   OrderApi = OrderApi + `&order_status=${orderStatusString}`;
    // }

    if (selectedStatus !== "All") {
      OrderApi = OrderApi + `&order_status=${selectedStatus}`;
    }

    if (selectedSource.length > 0) {
      OrderApi = OrderApi + `&source=${selectedSource}`;
    }

    if (selectedDeliveryMethod.length > 0) {
      OrderApi = OrderApi + `&delivery_method=${selectedDeliveryMethod}`;
    }

    if (selectedOrderType.length > 0) {
      OrderApi = OrderApi + `&order_type=${selectedOrderType}`;
    } else {
      OrderApi = OrderApi + "&order_type=null";
    }

    // if (sourceFilter.length > 0) {
    //   let sourceValues = sourceFilter.join(',');
    //   OrderApi = OrderApi + `&source=${sourceValues}`;
    // }

    // if (deliveryFilter.length > 0) {
    //   let deliveryMethodValues = deliveryFilter.join(',');
    //   OrderApi = OrderApi + `&delivery_method=${deliveryMethodValues}`;
    // }

    if (selectedSortOption) {
      OrderApi =
        OrderApi + `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    console.log("searchKeyword", searchKeyword);
    if (searchKeyword.length > 0) {
      OrderApi = OrderApi + `&searchKeyword=${searchKeyword}`;
    }

    setIsLoading(true);
    fetch(OrderApi)
      .then((response) => response.json())
      .then((data) => {
        const ordersRepsonse = data?.data;
        if (ordersRepsonse.length > 0) {
          const updatedOrders =
            pageNum === 1 ? ordersRepsonse : [...orders, ...ordersRepsonse];
          const mergedOrders = mergeAndSortOrders(updatedOrders);
          setOrders(mergedOrders);

          if (ordersRepsonse.length < 10) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        } else {
          if (pageNum == 1) {
            setOrders(ordersRepsonse);
          }
        }
        ordersValue = ordersRepsonse;
        if (pageNum == 1) {
          setPageNum(1);
        }

        setIsLoading(false);
        setClear(false);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        console.log(err);
      });
    console.log(isLoading);
  };

  const listTableData = () => {
    allOrders(orderStatus);
  };

  const mergeAndSortOrders = (mergedOrders) => {
    if (selectedSortOption) {
      mergedOrders.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedOrders;
  };

  const getAllLocationList = async () => {
    let response = await axios.get(ApiUrls.allLocationList);
    console.log("gsdayhg", response);
    let locationArray = [];
    const res = response?.data?.data;
    res.map((item) => {
      return locationArray.push({ label: item.title, value: item.location_id });
    });
    setAllLocationList(locationArray);
  };
  console.log("jkasgds", allLocationList);

  useEffect(() => {
    getAllLocationList();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: sortedPageData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    currentDate = new Date();
    lastDate = currentDate.setDate(currentDate.getDate() - 5);
    lastDate = new Date(lastDate);
    currentDate = new Date();
    //console.log(currentDate);
    //getLocationList();
  }, [useValue < 1]);

  const getLocationList = () => {
    let location = localStorage.getItem("selected_location_id");
    if (location) {
      setLocationId(location);
    }
  };

  useEffect(() => {
    getLocationList();
  }, []);

  const loadMore = () => {
    // currentDate = new Date(lastDate);
    // let date = new Date(lastDate);
    // lastDate = date.setDate(date.getDate() - 5);
    // lastDate = new Date(lastDate);
    setPageNum((p) => p + 1);

    // setTimeout(function () {
    //   getOrderList(locationId, orderStatus, size,"","",pageNum);
    // }, 3000);
  };

  useEffect(() => {
    setPageNum(1);
    if (locationId || selectedSortOption) {
      // setPageNum(1);
      // setOrders([]);
      console.log("ggh");
      getOrderList(locationId, orderStatus, size, currentDate, lastDate, 1);
    }
  }, [
    orderStatus,
    locationId,
    selectedSortOption,
    sortDirection,
    selectedStatus,
    selectedSource,
    selectedDeliveryMethod,
    selectedOrderType,
    selectedLocation,
  ]);

  useEffect(() => {
    setPageNum(1);
    if (searchKeyword.length >= 2) {
      getOrderList(locationId, orderStatus, size, currentDate, lastDate, 1);
    } else if (searchKeyword === "") {
      getOrderList(locationId, orderStatus, size, currentDate, lastDate, 1);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (pageNum > 1) {
      getOrderList(locationId, orderStatus, size, "", "", pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    let arr = [];
    if (selectedLocation?.length > 0) {
      selectedLocation.map((item) => {
        let locationName = item?.value?.split("#").join("%");
        return arr.push(locationName);
      });
      console.log("arr", arr);
      let locationStr = arr.join();
      setLocationFilter(locationStr);
    }
  }, [selectedLocation.length]);

  console.log("selectedLocation", selectedLocation);
  console.log("locationFilter", locationFilter);

  const handleClick = (clicked) => {
    console.log("CLICKED", clicked);

    let value = SortOptions.find((item) => item.access == clicked.accessor);
    setSelectedSortOption(value.id);
    if (sortDirection == "ASC") {
      setSortDirection("DESC");
    } else {
      setSortDirection("ASC");
    }
  };

  // const selectStatus =(value)=>{
  // let status = statusFilter.find(x=> x == value);
  // let tempStatusFilter = JSON.parse(JSON.stringify(statusFilter))
  // if(status){
  //   let index = tempStatusFilter.findIndex(x=> x == value);
  //   tempStatusFilter.splice(index,1)
  // }else{
  //   tempStatusFilter.push(value)
  // }
  // setStatusFilter(tempStatusFilter)
  // }

  const selectStatus = (value) => {
    setPageNum(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      getOrderList("", "", "", "", "", 1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });
      setSelectedStatus(value);
    }
  };

  let btns = [
    "All",
    "Received",
    "In Progress",
    "Ready for delivery",
    "Ready for pickup",
    "Out for delivery",
    "Picked",
    "Cancelled",
  ];

  const selectSource = (values) => {
    let tempSourceFilter = JSON.parse(JSON.stringify(sourceFilter));
    values.forEach((value) => {
      let sourceExists = tempSourceFilter.includes(value);
      if (sourceExists) {
        let index = tempSourceFilter.findIndex((x) => x === value);
        tempSourceFilter.splice(index, 1);
      } else {
        tempSourceFilter.push(value);
      }
    });

    setSourceFilter(tempSourceFilter);
  };

  const selectDelivery = (value) => {
    let delivery = deliveryFilter.find((x) => x == value);
    let tempDeliveryFilter = JSON.parse(JSON.stringify(deliveryFilter));
    if (delivery) {
      let index = tempDeliveryFilter.findIndex((x) => x == value);
      tempDeliveryFilter.splice(index, 1);
    } else {
      tempDeliveryFilter.push(value);
    }
    setDeliveryFilter(tempDeliveryFilter);
  };

  const handleClear = async () => {
    setPageNum(1);
    setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    setSelectedDeliveryMethod([]);
    setSelectedOrderType([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  useEffect(() => {
    if (clear) {
      getOrderList("", "", "", "", "", 1);
    }
  }, [clear]);

  const handleCancelClick = () => {
    setPageNum(1);
    setSelectedLocation([]);
    setStatusFilter([]);
    setSelectedSource([]);
    setSelectedDeliveryMethod([]);
    setSelectedOrderType([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
    setShowCard(false);
  };

  return (
    <>
      <Container fluid className={styles.searchBarOrderList}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="12">
                    {/* <div > */}
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Order"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className={styles.container}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getOrderList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    dropDown={true}
                    placeHolderText="Searching All Orders"
                    allLocationList={allLocationList}
                    setSelectedLocation={setSelectedLocation}
                    selectedLocation={selectedLocation}
                    handleSourceOptions={handleSourceOptions}
                    selectedSource={selectedSource}
                    sourceDropDown={sourceDropDown}
                    toggleSourceDropDown={toggleSourceDropDown}
                    sourceOptions={sourceOptions}
                    handleDeliveryMethodOptions={handleDeliveryMethodOptions}
                    deliveryMethodDropDown={deliveryMethodDropDown}
                    filterDropDown={filterDropDown}
                    deliveryMethodOptions={deliveryMethodOptions}
                    orderTypeDropDown={orderTypeDropDown}
                    toggleOrderTypeDropDown={toggleOrderTypeDropDown}
                    selectedOrderType={selectedOrderType}
                    handleOrderTypeOptions={handleOrderTypeOptions}
                    orderTypeOptions={orderTypeOptions}
                    selectedDeliveryMethod={selectedDeliveryMethod}
                    toggleDeliveryMethodDropDown={toggleDeliveryMethodDropDown}
                    toggleFilterDropDown={toggleFilterDropDown}
                    orderList={true}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={getOrderList}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    dropDown={true}
                    placeHolderText="Searching All Orders"
                    allLocationList={allLocationList}
                    setSelectedLocation={setSelectedLocation}
                    selectedLocation={selectedLocation}
                    handleSourceOptions={handleSourceOptions}
                    selectedSource={selectedSource}
                    sourceDropDown={sourceDropDown}
                    toggleSourceDropDown={toggleSourceDropDown}
                    sourceOptions={sourceOptions}
                    handleDeliveryMethodOptions={handleDeliveryMethodOptions}
                    deliveryMethodDropDown={deliveryMethodDropDown}
                    orderTypeDropDown={orderTypeDropDown}
                    toggleOrderTypeDropDown={toggleOrderTypeDropDown}
                    selectedOrderType={selectedOrderType}
                    handleOrderTypeOptions={handleOrderTypeOptions}
                    orderTypeOptions={orderTypeOptions}
                    filterDropDown={filterDropDown}
                    deliveryMethodOptions={deliveryMethodOptions}
                    selectedDeliveryMethod={selectedDeliveryMethod}
                    toggleDeliveryMethodDropDown={toggleDeliveryMethodDropDown}
                    toggleFilterDropDown={toggleFilterDropDown}
                    orderList={true}
                  />
                )}
              </CardBody>

              <PriceListTable
                ordersColumns={ordersColumns}
                orders={orders}
                handleView={handleView}
                history={history}
                handlestatus={listTableData}
                updateOrderStatus={updateOrderStatus}
                handleClick={handleClick}
                selectedSortOption={selectedSortOption}
              />
              <CardFooter className="py-4">
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={() => loadMore()}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default OrdersList;
