import React, { useEffect, useState } from "react";
import { useParams, withRouter, useHistory, Link } from "react-router-dom";
import { Steps } from "rsuite";
import "rsuite/dist/rsuite.css";
import { logUserMetrics } from "../../../utils/file";
import {
  Card,
  CardBody,
  Row,
  Container,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  CardHeader,
  Table,
  Col,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  CardTitle,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { format } from "date-fns";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { formatDateTimeInTimeZone } from "../../helpers";
import { Formik, Form } from "formik";
import axios from "axios";
import "./style.css";
import styles from "../../styles/view.module.css";
import FullPageLoader from "../../../components/FullPageLoader";
import ReactStars from "react-rating-stars-component";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import constantVariable, {
  ApiEndpoints,
  SourceName,
} from "../../constants/constant";
import ChatBox from "./popChat";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const stylesreSuits = {
  width: "200px",
  display: "inline-table",
  verticalAlign: "top",
};
const DetailedOrdersView = (props) => {
  const [addedTextMessage, setaddedTextMessage] = useState();
  const classes = useStyles();
  let { orderId } = useParams();
  const history = useHistory();
  const [customerSms, setCustomerSms] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [collapseKey, setCollapseKey] = useState("orderDetails");
  const [deliveryStatus, setDeliveryStatus] = useState([]);
  const [phoneModal, setPhoneModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [deliveryStatusActive, setDeliveryStatusActive] = useState();
  const [deliveryStatusActiveName, setDeliveryStatusActiveName] = useState();
  const [deliveryTrackingDes, setDeliveryTrackingDes] = useState([]);
  const [tabContentKey, setTabContentKey] = useState("generalDeatil");
  const [formKey, setFormKey] = useState("hide");
  const [refundKey, setRefundKey] = useState("hide");
  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [cancelOrderError, setCancelOrderError] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modifyOrderModal, setModifyOrderModal] = useState(false);
  const [prevModifyOrder, setPrevModifyOrder] = useState(null);
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [draftId, setDraftId] = useState("");
  const [userName, setUserName] = useState("");

  const moreToggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }

    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);
  const toggle = (key) => {
    setCollapseKey(key);
  };
  const tabToggle = (key) => {
    setTabContentKey(key);
  };

  const formToggle = (key) => {
    setFormKey(key);
  };

  const refundToggle = (key) => {
    setRefundKey(key);
  };

  const cancelOrderToggle = () => {
    setRefundReason("");
    setRefundModal(!refundModal);
    setCancelOrderError("");
  };

  const openModifyOrderModal = () => {
    setModifyOrderModal(true);
  };

  const closeModifyOrderModal = () => {
    setModifyOrderModal(false);
  };

  const handleApprove = () => {
    orderModify();
    closeModifyOrderModal();
  };

  const handleCancel = () => {
    // Handle cancel logic here
    closeModifyOrderModal();
  };

  // const [order_Id, setOrder_Id] = useState();
  const [originalData, setOriginalData] = useState(null);
  const [driverDetails, setDriverDetails] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderName, setOrderName] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState(false);
  const [unassignDriverModal, setUnassignDriverModal] = useState(false);
  const [messageDriverModal, setMessageDriverModal] = useState(false);
  const [reviewRatingModel, setReviewRatingModel] = useState(false);
  const [messageCustomerModal, setMessageCustomerModal] = useState(false);
  const [noteAttributeModal, setNoteAttributeModal] = useState(false);
  const [customerDetailModal, setCustomerDetailModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [tempOrderStatus, setTempOrderStatus] = useState("");
  const [orderCancellation, setOrderCancellation] = useState("");
  const [orderCancelledBy, setOrderCancelledBy] = useState("");
  const [orderCancelNote, setOrderCancelNote] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [tempOrderNote, setTempOrderNote] = useState("");
  const [error, setError] = useState(null);
  const [formData, setFormData] = React.useState({});
  const [locationName, setLocationName] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [tip, setTip] = useState("");
  const [dispatcherStatus, orderDispatcherStatus] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [refundReasons, setRefundReasons] = useState([]);
  const [notesAttributesRows, setNotesAttributesRows] = useState([]);
  const [ratings, setRatings] = useState(0);
  const [comments, setComments] = useState("");
  const [orderReviewRating, setOrderReviewRating] = useState([]);
  const [deliveryTracking, setDeliveryTracking] = useState();
  const [modifyOrder, setModifyOrder] = useState("");
  const [modifyOrderData, setModifyOrderData] = useState("");
  const [draftData, setDraftData] = useState(null);

  const handleDelivery = ($event) => {
    setDeliveryTracking($event.target.value);
  };
  const modalToggle = () => {
    setModal(!modal);
    //setDeliveryTracking("");
  };
  const unassignDriverModalToggle = () =>
    setUnassignDriverModal(!unassignDriverModal);
  const noteAttributModalToggle = () =>
    setNoteAttributeModal(!noteAttributeModal);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const confirmModalToggleStatus = () =>
    setConfirmModalStatus(!confirmModalStatus);
  const customerDetailModalToggle = () =>
    setCustomerDetailModal(!customerDetailModal);
  const messageCustomer = (event) => {
    if (event) {
      const apiUrl = `https://9gvt8etmw4.execute-api.us-east-1.amazonaws.com/dev/v1/order/sms-customer`;
      setIsLoading(true);
      console.log({
        message: event,
        mobileNumber: mobileNumber,
        orderName: originalData?.name,
      });

      fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: event,
          mobileNumber: mobileNumber,
          orderName: originalData?.name,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          setIsLoading(false);
        });
      setMessageCustomerModal(!messageCustomerModal);
    }
    setMessageCustomerModal(!messageCustomerModal);
  };
  const messageDriverModalToggle = () => {
    setMessageDriverModal(!messageDriverModal);
  };
  const messageCustomerModalToggle = () => {
    setMessageCustomerModal(!messageCustomerModal);
  };

  const reviewRatingModelToggle = () => {
    setReviewRatingModel(!reviewRatingModel);
  };

  const handleChangeRefundItems = async (item) => {
    const tempItemArr = [...lineItems, { id: item.id, title: item.name }];
    await setLineItems(tempItemArr);
  };

  const handleUpdateOrderNote = async () => {
    let orderNoteModification = ApiEndpoints.orderNote + orderId;

    let requestBody = {
      note: tempOrderNote,
    };
    try {
      setIsLoading(true);
      let res = await axios.put(orderNoteModification, requestBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        setOrderNote(tempOrderNote);
        confirmModalToggle();
        setNoteAttributeModal(false);
        setModal(false);
        toast.success("Order note updated successfully");
        // history.push("/admin/list-orders");
        let action = "order-note-modified";
        let type = "Info";
        let message = "Order note modified successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        setIsLoading(false);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.error("error", error);
      let action = "order-note-modified";
      let type = "Error";
      let message = "Error in order note modification.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      setIsLoading(false);
    }
  };

  const handleUpdateStatus = async () => {
    let orderStatusModification = ApiEndpoints.orderUpdateStatus + orderId;

    let body = {
      orderStatus: tempOrderStatus,
      deliveryTrackingData: deliveryTracking,
    };

    try {
      setIsLoading(true);
      let res = await axios.put(orderStatusModification, body);

      if (res.status === 200) {
        setOrderStatus(tempOrderStatus);
        confirmModalToggleStatus();
        setNoteAttributeModal(false);
        setModal(false);
        setIsLoading(false);
        toast.success("Order status updated successfully");
        // history.push("/admin/list-orders");
        let action = "order-status-modified";
        let type = "Info";
        let message = "Order status modified successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        setIsLoading(false);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      console.error("error", error);
      setError(error);
      let action = "order-status-modified";
      let type = "Error";
      let message = "Error in order status modification.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      setIsLoading(false);
    }
  };

  console.log("originalData", originalData);

  const editCustomerDetails = async (values, setSubmitting) => {
    setIsLoading(true);
    try {
      let data = {};
      if (originalData?.shipping_address?.first_name) {
        data = {
          shippingAddress: {
            ...formData,
            zip: values?.default_address?.zip
              ? values?.default_address?.zip
              : formData?.zip,
            country: values?.default_address?.country
              ? values?.default_address?.country
              : formData?.country,
            address2: values?.default_address?.address2
              ? values?.default_address?.address2
              : formData?.address2,
            city: values?.default_address?.city
              ? values?.default_address?.city
              : formData?.city,
            address1: values?.default_address?.address1
              ? values?.default_address?.address1
              : formData?.address1,
            province_code: formData?.province_code,
            country_code: formData?.country_code,
            province: values?.default_address?.province
              ? values?.default_address?.province
              : formData?.province,
            company: formData?.company,
            phone: values?.phone ? values.phone : formData?.phone,
            first_name: values?.first_name
              ? values.first_name
              : formData?.first_name,
            last_name: values?.last_name
              ? values.last_name
              : formData?.last_name,
            latitude: values?.latitude,
            longitude: values?.longitude,
            name: values?.name,
          },
        };
      } else {
        data = {
          customer: {
            ...formData,
            default_address: {
              zip: values?.default_address?.zip
                ? values?.default_address?.zip
                : formData?.default_address?.zip,
              country: values?.default_address?.country
                ? values?.default_address?.country
                : formData?.default_address?.country,
              address2: values?.default_address?.address2
                ? values?.default_address?.address2
                : formData?.default_address?.address2,
              city: values?.default_address?.city
                ? values?.default_address?.city
                : formData?.default_address?.city,
              address1: values?.default_address?.address1
                ? values?.default_address?.address1
                : formData?.default_address?.address1,
              province_code: formData?.default_address?.province_code,
              country_code: values?.default_address?.country_code
                ? values?.default_address?.country_code
                : formData?.default_address?.country_code,
              default: formData?.default_address?.default,
              province: formData?.default_address?.province,
              country_name: formData?.default_address?.country_name,
              company: formData?.default_address?.company,
              id: formData?.default_address?.id,
              customer_id: formData?.customer_id,
            },
            email: values?.email ? values.email : formData?.email,
            phone: values?.phone ? values.phone : formData?.phone,
            first_name: values?.first_name
              ? values.first_name
              : formData?.first_name,
            last_name: values?.last_name
              ? values.last_name
              : formData?.last_name,
          },
        };
      }

      await fetch(
        `${ApiEndpoints.orderUpdateCustomerDetails}${
          originalData?.pk?.split("#")[1]
        }`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      // customerDetailModalToggle();
      setSubmitting(false);
      setIsLoading(false);
      singleOrderDetail();
      setFormKey("hide");
      let action = "order-customer-details-update";
      let type = "Info";
      let message = "Order Customer Details successfully updated.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      // window.location.reload(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
      let action = "order-customer-details-update";
      let type = "Error";
      let message = "Error in Order Customer Details update.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const refundOrderApiHandle = async (values, setSubmitting) => {
    setIsLoading(true);
    try {
      if (values.refundType !== "CANCEL") {
        fetch(`${ApiEndpoints.orderRefund}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: originalData?.id,
            refundType: values.refundType,
            refundReason:
              values.reason === "Other" ? values.otherReason : values.reason,
            refundLineItems: values.refundType === "PARTIAL" ? lineItems : [],
          }),
        })
          .then((response) => response.json())
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          })
          .then((data) => {
            setIsLoading(false);
            if (data.message !== "Successfull") {
              setError(data.message);
            } else {
              console.log("successfull");
              // history.push({
              //   pathname:
              //     "/admin/order/" +
              //     props?.location?.state?.originalData?.id +
              //     "/view",
              //   state: { item: props?.location?.state?.originalData },
              // });
              setSubmitting(false);
            }
          });
      } else {
        fetch(`${ApiEndpoints.orderRefund}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: originalData?.id,
            refundType: values.refundType,
            refundReason:
              values.reason === "Other" ? values.otherReason : values.reason,
            refundLineItems: values.refundType === "PARTIAL" ? lineItems : [],
          }),
        })
          .then((response) => response.json())
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          })
          .then((data) => {
            if (data.message !== "Successfull") {
              setError(data.message);
            } else {
              console.log("successfull else");
              // history.push({
              //   pathname:
              //     "/admin/order/" +
              //     props?.location?.state?.originalData?.id +
              //     "/view",
              //   state: { item: props?.location?.state?.originalData },
              // });
              setSubmitting(false);
              setIsLoading(false);
            }
          });
      }
      setSubmitting(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "Error");
    }
  };

  // const cancelOrderToggle = () => {
  //   setRefundModal(!refundModal);
  // }

  const handleRefundReasonChange = (e) => {
    setRefundReason(e.target.value);
    setCancelOrderError("");
  };

  const cancelOrderApi = async () => {
    if (!refundReason) {
      setCancelOrderError("Reason is required");
      return;
    }
    setCancelOrderError("");

    let requestBody = {
      refundType: "ENTIRE_ORDER",
      orderId: originalData?.id,
      refundReason: refundReason,
      cancelled_by: localStorage.getItem("fullname"),
    };

    console.log("reqbody", requestBody);
    try {
      setIsLoading(true);
      let res = await axios.put(ApiEndpoints.refundOrder, requestBody);
      console.log("res12", res);
      if (res.status === 200) {
        toast.success("Order Cancelled Successfully");
        setRefundModal(false);
        setIsLoading(false);
        // window.location.reload();
        getOrder();
        let action = "order-cancel-status";
        let type = "Info";
        let message = "Order cancelled successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.error(error);
      toast.success("Error in order cancellation");
      setIsLoading(false);
      let action = "order-cancel-status";
      let type = "Error";
      let message = "Error in order cancellation.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const sendMessageToDriver = () => {};

  const handleChange = (event) => {
    setTempOrderStatus(event.target.value);
  };
  const handleDeliveryChange = (event) => {
    // setTempOrderStatus(event.target.value);
    console.log(event.target.value);
    let data = deliveryStatus?.find((x) => x.key == event?.target?.value);
    console.log(data?.state);

    setDeliveryStatusActive(data.state);
    setDeliveryStatusActiveName(event.target.value);
  };
  const changeMobilenumberSms = (data) => {
    if (data) {
      console.log(data);
    }
    setPhoneModal(false);
  };
  // const updateState = (data, name) => {
  //   console.log(name, data);
  //   let data2 = deliveryStatus.find(x => x.state == data && x.key == name)
  //   console.log(data2);
  //   setIsLoading(true)
  //   // let value = deliveryStatus.indexOf(data);
  //   let value;
  //   let id = originalData?.pk?.split("#")[1];
  //   let locId = originalData?.locationId.split("#")[1]
  //   let notes = `Order${orderName} .\nLocation#${locId} .\n OrderId#${id} .\n`;
  //   for (let i = 0; i < originalData?.line_items.length; i++) {
  //     notes =
  //       notes +
  //       '.\n ------------------- .\n ------------------- .\n ' +
  //       originalData?.line_items[i].quantity +
  //       ' X ' +
  //       originalData?.line_items[i].name
  //   }
  //   let deliveryTrackingData = []
  //   if (deliveryTrackingDes) {
  //     deliveryTrackingData = deliveryTrackingDes
  //   }

  //   // let name1 = JSON.parse(localStorage.getItem('CognitoIdentityServiceProvider.33q60t03iv489rugtmbvjiidk.464bd8cd-eb91-4fcd-b01e-a426ed3012b4.userData'))
  //   // console.log('rr', name1.UserAttributes[0].Name);
  //   let name1=localStorage.getItem('Name')
  //   // console.log(props?.userDeatil.firstName , 'dddddddddd');
  //   deliveryTrackingData.push({ userName: name1, UpdateTime: new Date(), state: data2.state, status: data2.key,stateChangeMessage:addedTextMessage })
  //   setDeliveryTrackingDes(deliveryTrackingData)

  //   let task = {
  //     task: {
  //       id: `Task#${id}#${locId}`, state: data2.state, notes: notes,stateChangeMessage:addedTextMessage, completionDetails: { success: data2.completionDetails.success }, deliveryTrac: deliveryTrackingData, shipping_address: originalData.shipping_address, customer: originalData.customer,
  //       current_total_tax_set: originalData.current_total_tax_set, billing_address: originalData.billing_address, source_name: originalData.source_name,
  //       subtotal_price: originalData.subtotal_price, subtotal_price_set: originalData.subtotal_price_set, tax_lines: originalData.tax_lines, total_discounts_set
  //         : originalData.total_discounts_set, total_discounts: originalData.total_discounts, total_price: originalData.total_price, total_price_set: originalData.total_price_set
  //       , user_id: originalData.user_id, zip_code: originalData.zip_code
  //     }
  //   }
  //   console.log(task);
  //   let api = `${ApiEndpoints.updateOrderState}`;
  //   fetch(api, {
  //     method: 'POST',
  //     body: JSON.stringify(task)
  //   }).then((response) => response.json()).then((res) => {
  //     setTimeout(() => {
  //       setIsLoading(false)
  //     }, 2000);
  //     console.log(res);
  //   }).catch((err) => {
  //     console.log(err);
  //   });
  //   setTimeout(() => {
  //     setIsLoading(false)
  //   }, 2000);
  // }
  const handleOrderNote = (event) => {
    setTempOrderNote(event.target.value);
  };

  const unassignDriver = async () => {
    await fetch(`${ApiEndpoints.assignDriver}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: originalData?.pk?.split("#")[1],
        locationId: originalData?.locationId,
      }),
    });
    unassignDriverModalToggle();
    window.location.reload(false);
  };
  // ratingReview
  const ratingReview = async (orderId, idLocation) => {
    console.log("locationIdlocationId", idLocation);
    await fetch(
      `${ApiEndpoints.rating_review}/${orderId}?location_id=${idLocation}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("ratingReview", data.data);
        setOrderReviewRating(data.data?.Items);
        setRatings([data.data?.Items[0]?.stars]);
        setComments([data.data?.Items[0]?.comment]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    ratingReview(orderId, idLocation);
  }, [orderId, idLocation]);
  // const getOrderState = () => {
  //   if (originalData) {
  //     let locId = originalData?.locationId.split("#")[1]
  //     let url = `${ApiEndpoints.getOrderState}${orderName.split("#")[1]}/${locId}`
  //     fetch(url).then((response) => response.json()).catch((err) => {
  //       console.log(err);
  //     }).then((data) => {
  //       console.log(data.data[0]?.state);
  //       console.log(data?.data[0]?.deliveryTrac);
  //       setDeliveryTrackingDes(data?.data[0]?.deliveryTrac)

  //       if (data.data[0]?.state == 3 && data.data[0]?.completionDetails.success == false) {
  //         let satusKey = deliveryStatus.find(x => x.state == data.data[0]?.state && x.completionDetails.success == false)
  //         setDeliveryStatusActive(data.data[0]?.state)
  //         setDeliveryStatusActiveName(satusKey?.key)
  //       } else if (data.data[0]?.state == 3 && data.data[0]?.completionDetails.success == true) {
  //         let satusKey = deliveryStatus.find(x => x.state == data.data[0]?.state && x.completionDetails.success == true)
  //         setDeliveryStatusActive(data.data[0]?.state)
  //         setDeliveryStatusActiveName(satusKey?.key)
  //       }
  //       else {
  //         let satusKey = deliveryStatus.find(x => x.state == data.data[0]?.state)
  //         setDeliveryStatusActive(data.data[0]?.state)
  //         setDeliveryStatusActiveName(satusKey?.key)
  //       }
  //     })
  //   }
  // }
  useEffect(() => {
    // getOrderState();
  }, [originalData]);

  const getLocation = (location) => {
    fetch(
      `${ApiEndpoints.getLocationByLocationId}/${
        location
        // props?.location?.state?.item?.locationId
        //   ? props?.location?.state?.item?.locationId?.split("#")[1]
        //   : localStorage.getItem("order_location").split("#")[1]
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        const locations = data.data;
        setLocationName(locations?.title);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrder = () => {
    let location = "";
    fetch(ApiEndpoints.orderGetById + orderId)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        const index = data?.data?.orderDetail.line_items.findIndex(
          (x) => x.name == "Tip/Gratuity"
        );
        if (index > 0) {
          let a = data?.data?.orderDetail.line_items.splice(index, 1);
          //console.log("A", a);
          setTip((a?.[0].price * a?.[0].quantity).toFixed(2));
        } else {
          setTip(0);
        }

        setOriginalData(data?.data?.orderDetail);

        setOrderName(data?.data?.orderDetail.name);

        let mail = data?.data?.orderDetail?.customer?.email;
        if (mail && mail.length >= 52) {
          const noteAttributes = data?.data?.orderDetail?.note_attributes;
          const emailFromNoteAttributes = noteAttributes.find(
            (item) => item.name === "email"
          )?.value;

          if (emailFromNoteAttributes && emailFromNoteAttributes.length <= 52) {
            mail = emailFromNoteAttributes;
          }
        }

        setCustomerEmail(mail);

        if (originalData?.checkout_method === "delivery") {
          const name = data?.data?.orderDetail?.shipping_address?.name;
          setUserName(name);
        } else {
          const customerName = data?.data?.orderDetail?.billing_address?.name;
          setUserName(customerName);
        }

        setOrderNote(data?.data?.orderDetail?.note);

        setMobileNumber(
          data?.data?.orderDetail &&
            data?.data?.orderDetail?.shipping_address &&
            data?.data?.orderDetail?.shipping_address?.phone
            ? data?.data?.orderDetail?.shipping_address?.phone
            : data?.data?.orderDetail?.customer?.phone
        );
        console.log("ddddd", data?.data?.orderDetail?.note_attributes);
        // let location = data?.data?.orderDetail?.note_attributes.find(
        //   (item) => item.name === "location"
        // );
        // if (location !== "undefined") {
        //   //localStorage.setItem("order_location", location.value);
        //   if (location.value !== localStorage.getItem("order_location")) {
        //     alert("Location does not match");
        //     history.push("/admin/orders");
        //   }
        // }
        let orderStatus = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "order_status"
        );

        if (orderStatus !== undefined) {
          setOrderStatus(orderStatus.value);
        }

        let modifyOrder = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "modified_to"
        );

        if (modifyOrder) {
          setModifyOrder(modifyOrder.value);
        }

        let prevModifyOrder = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "modified_against"
        );

        if (prevModifyOrder) {
          setPrevModifyOrder(prevModifyOrder.value);
        } else {
          setPrevModifyOrder(null);
        }

        let orderCancellation = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "order_cancelled_at"
        );

        if (orderCancellation) {
          setOrderCancellation(orderCancellation.value);
        }

        let orderCancelledBy = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "order_cancelled_by"
        );
        if (orderCancelledBy) {
          setOrderCancelledBy(orderCancelledBy.value);
        }

        if (data?.data?.orderDetail?.refunds.length > 0) {
          setOrderCancelNote(data?.data?.orderDetail?.refunds[0].note);
        }

        let deliveryTracking = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "deliveryTracking"
        );

        let locationDetail = data?.data?.orderDetail?.note_attributes.find(
          (item) => item.name === "location"
        );

        console.log("locationDetail", locationDetail);
        location = locationDetail.value.split("#")[1];
        console.log("22222222222222", location);
        getLocation(location);
        setIdLocation(location);
        if (deliveryTracking !== "undefined") {
          setDeliveryTracking(deliveryTracking?.value);
        }
        if (
          data?.data?.orderDetail?.shipping_address &&
          data?.data?.orderDetail?.shipping_address?.first_name
        ) {
          setFormData(data?.data?.orderDetail?.shipping_address);
        } else {
          setFormData(data?.data?.orderDetail?.customer);
        }
        // driver details
        if (data?.data?.orderDetail?.driver_id) {
          // locationList
          fetch(
            `${ApiEndpoints.getDriverById}/${data?.data?.orderDetail?.driver_id}?type=Driver`
          )
            .then((response) => response.json())
            .then((data) => {
              setDriverDetails(data?.data[0]);
              // setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (data?.data?.orderDetail.note_attributes.length > 0) {
          const rows = [
            ...Array(
              Math.ceil(data?.data?.orderDetail.note_attributes.length / 4)
            ),
          ];
          setNotesAttributesRows(
            rows.map((row, idx) =>
              data?.data?.orderDetail.note_attributes.slice(
                idx * 4,
                idx * 4 + 4
              )
            )
          );
        }
      });

    // locationList
    console.log("location 11111111", location);

    // order status
    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        setDeliveryStatus(data?.data?.config?.delivery_status);
        console.log(data?.data?.config?.delivery_status);
        orderDispatcherStatus(data?.data?.config?.Dispatcher_Status);
      });

    fetch(ApiEndpoints.config)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        setRefundReasons(data?.data?.config?.Dispacther_Refund_Reason);
      });

    ratingReview(
      orderId,
      props?.location?.state?.item?.locationId?.split("#")[1]
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  const getModifyOrder = () => {
    fetch(ApiEndpoints.orderGetById + modifyOrder)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        console.log("dddddasdjhsajd", data);
        setModifyOrderData(data);
        setIsLoading(false);
      });
  };

  console.log("modifyOrderData", modifyOrderData);

  useEffect(() => {
    getModifyOrder();
  }, [modifyOrder]);

  const getDraftOrder = () => {
    fetch(ApiEndpoints.draftOrder + orderId)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        // let draftOrderId = data?.data?.Items[0].id;
        // setDraftId(draftOrderId);/

        if (data?.data?.Items.length > 0) {
          console.log("draftOderkjk", data.data);

          setDraftData(data.data.Items[0]);
        }
        //
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDraftOrder();
  }, []);

  const orderModify = async () => {
    let orderModification = ApiEndpoints.modifyOrder;

    let requestBody = {
      orderId: orderId,
      draftOrderId: draftData.id.toString(),
      email: CustomerEmail,
    };
    try {
      setIsLoading(true);
      let res = await axios.post(orderModification, requestBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        toast.success("Order Modified Successfully");
        // history.push("/admin/list-orders");
        let action = "order-modified-status";
        let type = "Info";
        let message = "Order modified successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
        window.location.reload();
        setIsLoading(false);
      } else {
        console.log("resres", res);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("error", error?.response);
      let action = "order-modified-status";
      let type = "Error";
      let message = "Error in order modification.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
      setIsLoading(false);
    }
  };

  const handleMailChange = (event) => {
    const email = event.target.value;
    setCustomerEmail(email);
    if (!email) {
      setEmailError("Email is required");
    } else if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const singleOrderDetail = () => {
    fetch(ApiEndpoints.orderGetById + orderId)
      .then((response) => response.json())
      .catch((err) => {
        console.log(err);
      })
      .then((data) => {
        const index = data?.data?.orderDetail.line_items.findIndex(
          (x) => x.title == "Tip/Gratuity"
        );
        let a = data?.data?.orderDetail.line_items.splice(index, 1);
        setOrderName(data?.data?.orderDetail.name);
        setTip((a?.[0].price * a?.[0].quantity).toFixed(2));
        setOriginalData(data?.data?.orderDetail);

        console.log(data?.data?.orderDetail);
        // setMobileNumber(data?.data?.orderDetail &&
        //   data?.data?.orderDetail?.shipping_address &&
        //   data?.data?.orderDetail?.shipping_address?.phone
        //   ? data?.data?.orderDetail?.shipping_address?.phone
        //   : data?.data?.orderDetail?.customer?.phone)
        setOrderStatus(data?.data?.orderDetail?.order_status);
        if (
          data?.data?.orderDetail?.shipping_address &&
          data?.data?.orderDetail?.shipping_address?.first_name
        ) {
          setFormData(data?.data?.orderDetail?.shipping_address);
        } else {
          setFormData(data?.data?.orderDetail?.customer);
        }
        // driver details
        if (data?.data?.orderDetail?.driver_id) {
          // locationList
          fetch(
            `${ApiEndpoints.getDriverById}/${data?.data?.orderDetail?.driver_id}?type=Driver`
          )
            .then((response) => response.json())
            .then((data) => {
              setDriverDetails(data?.data[0]);
              // setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (data?.data?.orderDetail.note_attributes.length > 0) {
          const rows = [
            ...Array(
              Math.ceil(data?.data?.orderDetail.note_attributes.length / 4)
            ),
          ];
          setNotesAttributesRows(
            rows.map((row, idx) =>
              data?.data?.orderDetail.note_attributes.slice(
                idx * 4,
                idx * 4 + 4
              )
            )
          );
        }
      });
  };

  function containsNumbers(str) {
    return /[0-9]/.test(str);
  }

  const setSourceName = (sourceName) => {
    console.log(
      "sourceName ",
      sourceName == "580111",
      sourceName,
      typeof sourceName
    );
    if (sourceName == SourceName.LSWebsiteSourceName) {
      return "LS Website";
    } else {
      return "LS Mobile App";
    }
    //  else if (sourceName == "pos") {
    //   return "Point of sale";
    // }
    //  else {
    //   return sourceName;
    // }
  };

  const haddleSlected = (status, name) => {
    console.log("satt", status);
    if (name) {
      if (
        deliveryStatusActive == 3 &&
        status.key == name &&
        status.completionDetails.success == false
      ) {
        return "selected";
      } else if (status.state == deliveryStatusActive) {
        return "selected";
      }
    }
  };
  const getOrderStatusPageUrl = () => {
    return `order/${originalData.id}/$(originalData.locationId)`;
  };
  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   )
  // }
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const handleActiveStep = (data, name) => {
    if (data == 3) {
      let data1 = deliveryStatus.find((x) => x.state == data && x.key == name);
      if (data1?.completionDetails?.success == false && data1?.state == 3) {
        console.log(data1?.completionDetails?.success);
        console.log("r");
        return data1?.state;
      } else {
        return data1?.state - 1;
      }
    } else {
      let data1 = deliveryStatus.find((x) => x.state == data);
      return data1?.state - 1;
    }
  };
  // const handleDescription = (e) => {
  //   console.log(deliveryTrackingDes?.stateChangeMessage,'ddd');
  //   if (deliveryStatusActive && deliveryStatusActiveName && deliveryTrackingDes) {
  //     console.log(deliveryStatusActive, deliveryStatusActiveName);
  //     if (e?.key == deliveryStatusActiveName) {
  //       let data = deliveryTrackingDes[deliveryTrackingDes?.length - 1]?.userName + ' ' + formatDate(deliveryTrackingDes[deliveryTrackingDes?.length - 1]?.UpdateTime  )
  //       let data2=deliveryTrackingDes[deliveryTrackingDes?.length - 1]?.stateChangeMessage
  //       return data + ' '+ data2

  //     }
  //     else if (deliveryTrackingDes[deliveryTrackingDes?.length - 2]?.state == e?.state) {
  //       let data = deliveryTrackingDes[deliveryTrackingDes?.length - 2]?.userName + ' ' + formatDate(deliveryTrackingDes[deliveryTrackingDes?.length - 2]?.UpdateTime)
  //       let data2=deliveryTrackingDes[deliveryTrackingDes?.length - 2]?.stateChangeMessage
  //       return data + ' '+ data2
  //     } else if (deliveryTrackingDes[deliveryTrackingDes?.length - 3]?.state == e?.state) {
  //       let data = deliveryTrackingDes[deliveryTrackingDes?.length - 3]?.userName + ' ' + formatDate(deliveryTrackingDes[deliveryTrackingDes?.length - 3]?.UpdateTime)
  //       let data2=deliveryTrackingDes[deliveryTrackingDes?.length - 3]?.stateChangeMessage
  //       return data + ' '+ data2
  //     } else if (deliveryTrackingDes[deliveryTrackingDes?.length - 4]?.state == e?.state) {
  //       let data = deliveryTrackingDes[deliveryTrackingDes?.length - 4]?.userName + ' ' + formatDate(deliveryTrackingDes[deliveryTrackingDes?.length - 4]?.UpdateTime)
  //       let data2=deliveryTrackingDes[deliveryTrackingDes?.length - 4]?.stateChangeMessage
  //       return data + ' '+ data2
  //     } else {
  //       return ''
  //     }

  //   }

  // }
  return (
    <>
      <Container fluid className={styles.orderDetails}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/admin/list-orders")}>
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}>
                      {"Order Detail"}
                    </h6>
                    <h5
                      class="checklist-title mb-0"
                      style={{ marginLeft: "25px" }}>
                      {orderName}
                    </h5>
                    {/* {ratings > 0 && (
                      <ReactStars
                        count={5}
                        value={ratings}
                        size={24}
                        edit={false}
                        activeColor="#ffd700"
                      />
                    )} */}
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="8" xs="7">
                    <Button size="sm" onClick={() => reviewRatingModelToggle()}>
                      All reviews
                    </Button>                    
                    <Button
                      size="sm"
                      onClick={() => messageCustomerModalToggle()}
                    >
                      Message Customer
                    </Button>

                    {originalData?.financial_status === "paid" ? (
                      <Button size="sm" onClick={cancelOrderToggle}>
                        Cancel Order
                      </Button>
                    ) : null}

                    <Modal
                      isOpen={refundModal}
                      toggle={cancelOrderToggle}
                      centered
                    >
                      <ModalHeader toggle={cancelOrderToggle}>
                        Cancel Order
                      </ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <Label>Please enter reason for cancellation</Label>
                          <Input
                            name="reason"
                            type="text"
                            value={refundReason}
                            onChange={handleRefundReasonChange}
                          />
                          {cancelOrderError && (
                            <p style={{ color: "red", fontSize: 13 }}>
                              {cancelOrderError}
                            </p>
                          )}
                        </FormGroup>
                        <Button size="sm" onClick={cancelOrderApi}>
                          Submit
                        </Button>
                        <Button size="sm" onClick={cancelOrderToggle}>
                          Cancel
                        </Button>
                      </ModalBody>
                    </Modal>
                    {![
                      "In-Progress",
                      "Preparing",
                      "Preparing Order",
                      "Delivered",
                      "Cancelled",
                      "On-Hold",
                      "Shipped",
                    ].includes(orderStatus) && (
                      <>
                        {originalData?.financial_status !== "refunded" ? (
                          <Button
                            size="sm"
                            onClick={() => {
                              history.push({
                                pathname: `/admin/order/refund/${orderId}`,
                              });
                            }}
                          >
                            Refund
                          </Button>
                        ) : null}
                      </>
                    )}
                    <Link
                      to={`/order/${orderId}/${
                        localStorage
                          .getItem("selected_location_id")
                          .split("#")[1]
                      }`}
                    >
                      View Status Page
                    </Link>
                  </Col> */}
                  {!modifyOrder ? (
                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={moreToggle}
                        className={styles.orderOpitons}>
                        <DropdownToggle caret size="sm">
                          Actions
                        </DropdownToggle>
                        <DropdownMenu className={styles.orderDropDownOptions}>
                          {/* {orderReviewRating.length > 0 ? ( */}
                          <DropdownItem
                            size="sm"
                            onClick={() => reviewRatingModelToggle()}
                            className={styles.orderDeatilsButton}>
                            All reviews
                          </DropdownItem>
                          {/* ) : null} */}
                          <DropdownItem
                            onClick={() => messageCustomerModalToggle()}
                            className={styles.orderDeatilsButton}>
                            Message Customer
                          </DropdownItem>
                          {originalData?.financial_status === "paid" && (
                            <DropdownItem
                              onClick={cancelOrderToggle}
                              className={styles.orderDeatilsButton}>
                              Cancel Order
                            </DropdownItem>
                          )}

                          <Modal
                            isOpen={refundModal}
                            toggle={cancelOrderToggle}
                            centered>
                            <ModalHeader toggle={cancelOrderToggle}>
                              Cancel Order
                            </ModalHeader>
                            <ModalBody>
                              <FormGroup>
                                <Label>
                                  Please enter reason for cancellation
                                </Label>
                                <Input
                                  name="reason"
                                  type="text"
                                  value={refundReason}
                                  onChange={handleRefundReasonChange}
                                />
                                {cancelOrderError && (
                                  <p style={{ color: "red", fontSize: 13 }}>
                                    {cancelOrderError}
                                  </p>
                                )}
                              </FormGroup>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={cancelOrderApi}>
                                Submit
                              </Button>
                              <Button size="sm" onClick={cancelOrderToggle}>
                                Cancel
                              </Button>
                            </ModalBody>
                          </Modal>
                          {![
                            "In-Progress",
                            "Preparing",
                            "Preparing Order",
                            "Delivered",
                            "Cancelled",
                            "On-Hold",
                            "Shipped",
                          ].includes(orderStatus) &&
                            originalData?.financial_status !== "refunded" && (
                              <DropdownItem
                                onClick={() =>
                                  history.push(`/admin/order/refund/${orderId}`)
                                }
                                className={styles.orderDeatilsButton}>
                                Refund
                              </DropdownItem>
                            )}
                          {!draftData &&
                          originalData?.financial_status === "paid" &&
                          !prevModifyOrder ? (
                            <DropdownItem
                              onClick={() =>
                                history.push(`/admin/order/modify/${orderId}`)
                              }
                              className={styles.orderDeatilsButton}>
                              Modify Order
                            </DropdownItem>
                          ) : null}
                          <DropdownItem
                            onClick={() =>
                              history.push(
                                `/order/${orderId}/${
                                  localStorage
                                    .getItem("selected_location_id")
                                    .split("#")[1]
                                }`
                              )
                            }
                            className={styles.orderDeatilsButton}>
                            View Status Page
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  ) : null}
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="8" xs="8" className={styles.orderDateAndTime}>
                    {moment(originalData?.created_at).format("MM/DD/YYYY")}
                    &nbsp;from&nbsp;{setSourceName(originalData?.source_name)}
                  </Col>
                  <Col lg="4" xs="4" className={styles.orderLocationName}>
                    {locationName}
                  </Col>
                </Row>
                <Row>
                  <Col lg="8" xs="12">
                    <ButtonGroup
                      aria-label="Basic example"
                      role="group"
                      className={styles.orderDeatilsButtonGroup}>
                      <Button
                        color="secondary"
                        className={`${
                          tabContentKey === "generalDeatil" ? "active" : ""
                        } ${styles.orderDeatilsButton}`}
                        onClick={() => tabToggle("generalDeatil")}
                        type="button">
                        Order Information
                      </Button>
                      <Button
                        className={`${
                          tabContentKey === "productDeatil" ? "active" : ""
                        } ${styles.orderDeatilsButton}`}
                        onClick={() => tabToggle("productDeatil")}
                        color="secondary"
                        type="button">
                        Product Detail
                      </Button>
                      <Button
                        color="secondary"
                        className={`${
                          tabContentKey === "customerDeatil" ? "active" : ""
                        } ${styles.orderDeatilsButton}`}
                        onClick={() => tabToggle("customerDeatil")}
                        type="button">
                        Customer Detail
                      </Button>
                      {draftData ? (
                        <Button
                          color="secondary"
                          className={`${
                            tabContentKey === "substituteOrder" ? "active" : ""
                          } ${styles.orderDeatilsButton}`}
                          onClick={() => tabToggle("substituteOrder")}
                          type="button">
                          Substituted Order
                        </Button>
                      ) : null}
                      {/* <Button color="secondary" className={tabContentKey === "refundItems" ? "active" : ""} onClick={() => tabToggle("refundItems")} type="button">
                        Refund Items
                      </Button> */}
                      {/* <Button Trtton color="secondary" className={tabContentKey === "deliveryTracking" ? "active" : ""} onClick={() => tabToggle("deliveryTracking")} type="button">
                        acking
                      </Button> */}
                    </ButtonGroup>

                    <TabContent activeTab={tabContentKey}>
                      <TabPane tabId="generalDeatil">
                        <Row>
                          <Col sm="12">
                            <ListGroup data-toggle="checklist" flush>
                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Order Name
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {/* {originalData && originalData?.pk} */}
                                      {orderName}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>
                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Delivery Method
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {originalData?.checkout_method}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>

                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Order Status
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {orderStatus ||
                                        originalData?.order_status}
                                      {"    "}
                                      {(userRoles.includes("1") ||
                                        roleActions.includes("order_update")) &&
                                        orderStatus !== "Cancelled" &&
                                        !modifyOrder && (
                                          <i
                                            class="fa fa-pencil-square-o"
                                            aria-hidden="true"
                                            onClick={() => modalToggle()}
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                            }}></i>
                                        )}
                                      {modifyOrder ? "(Substituted)" : null}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>

                              {orderCancellation ? (
                                <ListGroupItem className="checklist-entry flex-column align-items-start">
                                  <div className="checklist-item">
                                    <div className="checklist-info">
                                      <h5 className="checklist-title mb-0">
                                        Order Cancel Date & Time
                                      </h5>
                                    </div>
                                    <div>
                                      <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                        {/* {orderCancellation} */}
                                        {moment(orderCancellation).format(
                                          "DD/MM/YYYY LT"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </ListGroupItem>
                              ) : null}

                              {orderCancelledBy ? (
                                <ListGroupItem className="checklist-entry flex-column align-items-start">
                                  <div className="checklist-item">
                                    <div className="checklist-info">
                                      <h5 className="checklist-title mb-0">
                                        Order Cancelled By
                                      </h5>
                                    </div>
                                    <div>
                                      <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                        {/* {orderCancellation} */}
                                        {orderCancelledBy}
                                      </div>
                                    </div>
                                  </div>
                                </ListGroupItem>
                              ) : null}

                              {orderCancellation && orderCancelledBy ? (
                                <ListGroupItem className="checklist-entry flex-column align-items-start">
                                  <div className="checklist-item">
                                    <div className="checklist-info">
                                      <h5 className="checklist-title mb-0">
                                        Order Cancellation Reason
                                      </h5>
                                    </div>
                                    <div>
                                      <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                        {/* {orderCancellation} */}
                                        {orderCancelNote}
                                      </div>
                                    </div>
                                  </div>
                                </ListGroupItem>
                              ) : null}

                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Location
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {locationName}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>

                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Financial Status
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {originalData &&
                                        originalData?.financial_status}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>

                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Order Note
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {orderNote || originalData?.note}
                                      {"    "}
                                      {(userRoles.includes("1") ||
                                        roleActions.includes(
                                          "order_update"
                                        )) && (
                                        <i
                                          class="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                          onClick={() =>
                                            noteAttributModalToggle()
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                          }}></i>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>

                              {/* <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">Creation Date</h5>
                                  </div>
                                  <div>
                                    <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                      {originalData?.ca &&
                                        formatDateTimeInTimeZone(
                                          originalData?.ca
                                        )?.split(",")[0]}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem> */}
                            </ListGroup>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="productDeatil">
                        <Card>
                          <CardHeader>
                            <div style={{ overflow: "auto" }}>
                              <Table className="align-items-center table-flush">
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                  </tr>
                                </thead>
                                <tbody className="list">
                                  {originalData?.line_items.map((product) => (
                                    <tr>
                                      <td>{product.title}</td>
                                      <td>$ {product.price}</td>
                                      <td>{product.quantity}</td>
                                      <td>
                                        ${" "}
                                        {(
                                          product.price * product.quantity
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </CardHeader>
                        </Card>

                        {originalData?.refunds.length > 0 && (
                          <>
                            {originalData.refunds.some(
                              (refund) => refund.refund_line_items.length > 0
                            ) && (
                              <Card>
                                <CardHeader>
                                  <h4>REFUNDED</h4>
                                </CardHeader>
                                <CardBody>
                                  <div style={{ overflow: "auto" }}>
                                    <Table className="align-items-center table-flush">
                                      <thead>
                                        <tr>
                                          <th>Item</th>
                                          <th>Price</th>
                                          <th>Quantity</th>
                                          <th>Tax</th>
                                          <th>Total Price</th>
                                          <th>Refund Reason</th>
                                        </tr>
                                      </thead>
                                      <tbody className="list">
                                        {originalData.refunds.map((refund) =>
                                          refund.refund_line_items.map(
                                            (lineItem) => (
                                              <tr key={lineItem.id}>
                                                <td>
                                                  {lineItem.line_item?.title}
                                                </td>
                                                <td>
                                                  $
                                                  {(lineItem?.subtotal).toFixed(
                                                    2
                                                  )}
                                                </td>
                                                <td>{lineItem?.quantity}</td>
                                                <td>$ {lineItem?.total_tax}</td>
                                                <td>
                                                  $
                                                  {(
                                                    lineItem?.subtotal +
                                                    lineItem?.total_tax
                                                  ).toFixed(2)}
                                                </td>
                                                <td>{refund.note}</td>
                                              </tr>
                                            )
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            )}

                            {originalData?.refunds.some((refund) =>
                              refund?.order_adjustments.some(
                                (adjustment) =>
                                  adjustment?.kind === "shipping_refund"
                              )
                            ) && (
                              <Card>
                                <CardHeader>
                                  <h4>Shipping</h4>
                                </CardHeader>
                                <CardBody>
                                  <div style={{ overflow: "auto" }}>
                                    <Table className="align-items-center table-flush">
                                      <thead>
                                        <tr>
                                          <th>REFUNDED SHIPPING</th>
                                          <th>REFUND REASON</th>
                                        </tr>
                                      </thead>
                                      <tbody className="list">
                                        {originalData?.refunds.map((refund) => {
                                          const shippingRefundAdjustments =
                                            refund?.order_adjustments.filter(
                                              (adjustment) =>
                                                adjustment?.kind ===
                                                "shipping_refund"
                                            );

                                          if (
                                            shippingRefundAdjustments.length > 0
                                          ) {
                                            const amount = parseFloat(
                                              shippingRefundAdjustments[0]
                                                ?.amount
                                            );
                                            const taxAmount = parseFloat(
                                              shippingRefundAdjustments[0]
                                                ?.tax_amount
                                            );
                                            const totalAmount = (
                                              amount + taxAmount
                                            ).toFixed(2);
                                            const totalNumberAmount = Math.abs(
                                              Number(totalAmount)
                                            ).toFixed(2);
                                            const note = refund?.note;

                                            return (
                                              <tr key={refund.id}>
                                                <td>${totalNumberAmount}</td>
                                                <td>{note}</td>
                                              </tr>
                                            );
                                          } else {
                                            return null;
                                          }
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            )}
                            {originalData?.refunds.some((refund) =>
                              refund?.order_adjustments.some(
                                (adjustment) =>
                                  adjustment?.kind === "refund_discrepancy"
                              )
                            ) && (
                              <Card>
                                <CardHeader>
                                  <h4>Shipping</h4>
                                </CardHeader>
                                <CardBody>
                                  <div style={{ overflow: "auto" }}>
                                    <Table className="align-items-center table-flush">
                                      <thead>
                                        <tr>
                                          <th>REFUND AMOUNT</th>
                                          <th>REFUND REASON</th>
                                        </tr>
                                      </thead>
                                      <tbody className="list">
                                        {originalData?.refunds.map((refund) => {
                                          const shippingRefundAdjustments =
                                            refund?.order_adjustments.filter(
                                              (adjustment) =>
                                                adjustment?.kind ===
                                                "refund_discrepancy"
                                            );

                                          if (
                                            shippingRefundAdjustments.length > 0
                                          ) {
                                            const amount = parseFloat(
                                              shippingRefundAdjustments[0]
                                                ?.amount
                                            );
                                            const taxAmount = parseFloat(
                                              shippingRefundAdjustments[0]
                                                ?.tax_amount
                                            );
                                            const totalAmount = (
                                              amount + taxAmount
                                            ).toFixed(2);
                                            const totalNumberAmount = Math.abs(
                                              Number(totalAmount)
                                            ).toFixed(2);
                                            const note = refund?.note;

                                            return (
                                              <tr key={refund.id}>
                                                <td>${totalNumberAmount}</td>
                                                <td>{note}</td>
                                              </tr>
                                            );
                                          } else {
                                            return null;
                                          }
                                        })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            )}
                          </>
                        )}
                      </TabPane>
                      <TabPane tabId="customerDeatil">
                        <Row>
                          <Card body>
                            <div
                              className={formKey === "hide" ? "show" : "hide"}>
                              <h5 className="h3">
                                {/* {originalData &&
                                  originalData?.customer?.first_name +
                                    " " +
                                    originalData?.customer?.last_name} */}
                                {originalData &&
                                originalData?.customer &&
                                originalData?.customer?.email &&
                                originalData?.customer?.email?.length >= 52
                                  ? userName
                                  : originalData?.customer?.first_name +
                                    " " +
                                    originalData?.customer?.last_name}{" "}
                                {formKey === "hide" ? (
                                  <i
                                    onClick={() => formToggle("show")}
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                    style={{ cursor: "pointer" }}></i>
                                ) : (
                                  <i
                                    onClick={() => formToggle("hide")}
                                    class="fa fa-times"
                                    aria-hidden="true"
                                    style={{ cursor: "pointer" }}></i>
                                )}
                              </h5>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.address1
                                  ? originalData?.shipping_address?.address1
                                  : originalData?.customer?.default_address
                                      ?.address1}
                              </div>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.address2
                                  ? originalData?.shipping_address?.address2
                                  : originalData?.customer?.default_address
                                      ?.address1}
                              </div>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.city
                                  ? originalData?.shipping_address?.city
                                  : originalData?.customer?.default_address
                                      ?.city}
                              </div>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.province
                                  ? originalData?.shipping_address?.province
                                  : originalData?.customer?.default_address
                                      ?.province}
                              </div>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.country
                                  ? originalData?.shipping_address?.country
                                  : originalData?.customer?.default_address
                                      ?.country}
                              </div>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.zip
                                  ? originalData?.shipping_address?.zip
                                  : originalData?.customer?.default_address
                                      ?.zip}
                              </div>
                              <div className="addess">
                                {originalData && originalData?.customer?.email}
                              </div>
                              <div className="addess">
                                {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.phone
                                  ? originalData?.shipping_address?.phone
                                  : originalData?.customer?.phone}
                              </div>
                            </div>

                            <div
                              className={formKey === "hide" ? "hide" : "show"}>
                              {formKey === "hide" ? (
                                <i
                                  onClick={() => formToggle("show")}
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                  style={{ cursor: "pointer" }}></i>
                              ) : (
                                <i
                                  onClick={() => formToggle("hide")}
                                  class="fa fa-times"
                                  aria-hidden="true"
                                  style={{ cursor: "pointer" }}></i>
                              )}
                              <Formik
                                initialValues={formData}
                                validate={(values) => {
                                  const errors = {};
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                  editCustomerDetails(values, setSubmitting);
                                }}>
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                }) => (
                                  <Form>
                                    <Row>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input">
                                            Firstname
                                          </label>
                                          <Input
                                            id="example3cols1Input"
                                            placeholder="Firstname"
                                            type="Firstname"
                                            defaultValue={formData?.first_name}
                                            name="first_name"
                                            onChange={handleChange}
                                            disabled
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input">
                                            Lastname
                                          </label>
                                          <Input
                                            id="example3cols1Input"
                                            placeholder="Lastname"
                                            type="Lastname"
                                            defaultValue={formData?.last_name}
                                            disabled
                                            name="last_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input">
                                            Email
                                          </label>
                                          <Input
                                            id="example3cols1Input"
                                            placeholder="Email"
                                            type="email"
                                            defaultValue={
                                              originalData &&
                                              originalData?.customer?.email
                                            }
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input">
                                            Address1
                                          </label>
                                          <Input
                                            id="example3cols2Input"
                                            placeholder="Address1"
                                            type="address1"
                                            defaultValue={
                                              formData?.address1
                                                ? formData?.address1
                                                : formData?.default_address
                                                    ?.address1
                                            }
                                            name={"default_address.address1"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input">
                                            Address2
                                          </label>
                                          <Input
                                            id="example3cols1Input"
                                            placeholder="Address2"
                                            type="address2"
                                            defaultValue={
                                              formData?.address2
                                                ? formData?.address2
                                                : formData?.default_address
                                                    ?.address2
                                            }
                                            name="default_address.address2"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input">
                                            Country
                                          </label>
                                          <Input
                                            id="example3cols2Input"
                                            placeholder="Country"
                                            type="country"
                                            defaultValue={
                                              formData?.country
                                                ? formData?.country
                                                : formData?.default_address
                                                    ?.country
                                            }
                                            name="default_address.country"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input">
                                            Zip
                                          </label>
                                          <Input
                                            id="example3cols1Input"
                                            placeholder="Zip"
                                            type="zip"
                                            defaultValue={
                                              formData?.zip
                                                ? formData?.zip
                                                : formData?.default_address?.zip
                                            }
                                            name="default_address.zip"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input">
                                            City
                                          </label>
                                          <Input
                                            id="example3cols2Input"
                                            placeholder="City"
                                            type="city"
                                            defaultValue={
                                              formData?.city
                                                ? formData?.city
                                                : formData?.default_address
                                                    ?.city
                                            }
                                            name="default_address.city"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input">
                                            Province
                                          </label>
                                          <Input
                                            id="example3cols1Input"
                                            placeholder="Province"
                                            type="province"
                                            defaultValue={
                                              formData?.province
                                                ? formData?.province
                                                : formData?.default_address
                                                    ?.province
                                            }
                                            name="default_address.province"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6" sm="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="example3cols2Input">
                                            Phone
                                          </label>
                                          <Input
                                            id="example3cols2Input"
                                            placeholder="Phone"
                                            type="phone"
                                            defaultValue={
                                              formData?.phone
                                                ? formData?.phone
                                                : formData?.phone
                                            }
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Button
                                      color="primary"
                                      className="PrimaryButton"
                                      type="submit"
                                      disabled={isSubmitting}>
                                      Update
                                    </Button>
                                    <Button
                                      color="secondary"
                                      onClick={() => formToggle("hide")}>
                                      Cancel
                                    </Button>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </Card>
                        </Row>
                      </TabPane>
                      <TabPane tabId="refundItems">
                        <Row>
                          <Col sm="12">
                            {refundKey === "hide" && (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => refundToggle("show")}
                                type="button">
                                Initiate Refund
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            sm="12"
                            className={refundKey === "hide" ? "show" : "hide"}>
                            <Card body>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {originalData?.refunds[0]?.refund_line_items?.map(
                                    (line_item) => (
                                      <tr>
                                        <td>{line_item?.line_item?.title}</td>
                                        <td>{line_item?.subtotal}</td>
                                        <td>{line_item?.quantity}</td>
                                        <td>
                                          {line_item?.subtotal * line_item.quantity}
                                        </td>
                                      </tr>
                                    )
                                  )} */}
                                  {originalData?.line_items.map((product) => (
                                    <tr>
                                      <td>{product.title}</td>
                                      <td>{product.price}</td>
                                      <td>{product.quantity}</td>
                                      <td>
                                        {product.price * product.quantity}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>

                              <ListGroup
                                data-toggle="checklist"
                                flush
                                className="mt-6">
                                <ListGroupItem className="checklist-entry flex-column align-items-start">
                                  <div className="checklist-item">
                                    <div className="checklist-info">
                                      <h5 className="checklist-title mb-0">
                                        Shipping Refunded:
                                      </h5>
                                    </div>
                                    <div>
                                      <div className="small-font custom-control custom-checkbox custom-checkbox-warning">
                                        {
                                          originalData?.refunds[0]
                                            ?.order_adjustments[0]?.amount
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </ListGroupItem>
                              </ListGroup>
                            </Card>
                          </Col>
                          <Col
                            sm="12"
                            className={refundKey === "hide" ? "hide" : "show"}>
                            <Card body>
                              <i
                                onClick={() => refundToggle("hide")}
                                class="fa fa-times"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}></i>

                              <Formik
                                initialValues={{}}
                                validate={(values) => {
                                  const errors = {};
                                  if (!values.refundType) {
                                    errors.refundType = "Field Required!";
                                  }
                                  if (!values.reason) {
                                    errors.refundReason = "Field Required!";
                                  }
                                  return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                  refundOrderApiHandle(values, setSubmitting);
                                }}>
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setValues,
                                }) => (
                                  <Form>
                                    <Row>
                                      <Col md="6">
                                        <label
                                          className="form-control-label"
                                          htmlFor="example5cols3Input">
                                          Refund Type
                                        </label>
                                        <div className="custom-control custom-radio mb-3">
                                          <input
                                            className="custom-control-input"
                                            id="ENTIRE_ORDER"
                                            name="refundType"
                                            type="radio"
                                            defaultValue="ENTIRE_ORDER"
                                            value="ENTIRE_ORDER"
                                            onChange={handleChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="ENTIRE_ORDER">
                                            Entire Order
                                          </label>
                                        </div>

                                        <div className="custom-control custom-radio mb-3">
                                          <input
                                            className="custom-control-input"
                                            id="PARTIAL"
                                            name="refundType"
                                            type="radio"
                                            value="PARTIAL"
                                            onChange={handleChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="PARTIAL">
                                            Refund Items
                                          </label>
                                        </div>

                                        <div className="custom-control custom-radio mb-3">
                                          <input
                                            className="custom-control-input"
                                            id="DELIVERY_FEE"
                                            name="refundType"
                                            type="radio"
                                            value="DELIVERY_FEE"
                                            onChange={handleChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="DELIVERY_FEE">
                                            Delivery Fee
                                          </label>
                                        </div>

                                        <div className="custom-control custom-radio mb-3">
                                          <input
                                            className="custom-control-input"
                                            id="CANCEL"
                                            name="refundType"
                                            type="radio"
                                            value="CANCEL"
                                            onChange={handleChange}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="CANCEL">
                                            Cancel Order
                                          </label>
                                        </div>

                                        <p
                                          className="ErrorMessage"
                                          style={{ paddingLeft: "12px" }}>
                                          {errors.refundType &&
                                            errors.refundType}
                                        </p>
                                      </Col>
                                    </Row>
                                    {values.refundType === "PARTIAL" && (
                                      <Row>
                                        <Col md="8">
                                          <label
                                            className="form-control-label"
                                            htmlFor="reasonInput">
                                            Items
                                          </label>
                                          <Table size="md">
                                            <thead>
                                              <tr>
                                                <th></th>
                                                <th>Item Name</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {originalData?.line_items.map(
                                                (items) => (
                                                  <tr>
                                                    <td>
                                                      <input
                                                        id="customCheck1"
                                                        type="checkbox"
                                                        name="itemId"
                                                        onClick={() =>
                                                          handleChangeRefundItems(
                                                            items
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                    <td>{items.name}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                      </Row>
                                    )}
                                    <Row>
                                      <Col md="6">
                                        <label
                                          className="form-control-label"
                                          htmlFor="reasonInput">
                                          Refund Reason
                                        </label>
                                        <Input
                                          id="reasonInput"
                                          type="select"
                                          name="reason"
                                          onChange={(e) => handleChange(e)}>
                                          <option value={"Select"}>
                                            Select
                                          </option>
                                          {refundReasons?.map((x) => (
                                            <option value={x}>{x}</option>
                                          ))}
                                        </Input>

                                        <p
                                          className="ErrorMessage"
                                          style={{ paddingLeft: "12px" }}>
                                          {errors.refundReason &&
                                            errors.refundReason}
                                        </p>
                                        {values.reason === "Other" && (
                                          <>
                                            <label
                                              className="form-control-label"
                                              htmlFor="otherreason">
                                              Mention Other Reason
                                            </label>
                                            <Input
                                              id="otherreason"
                                              placeholder="Other Reason"
                                              rows="3"
                                              type="textarea"
                                              name="otherReason"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                          </>
                                        )}

                                        {error && (
                                          <p
                                            className="ErrorMessage"
                                            style={{ paddingLeft: "12px" }}>
                                            {error[0]?.error
                                              ? error[0]?.error
                                              : error}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}>
                                        Submit
                                      </Button>
                                      <Button
                                        color="secondary"
                                        onClick={() => refundToggle("hide")}>
                                        Cancel
                                      </Button>
                                    </Row>
                                  </Form>
                                )}
                              </Formik>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="substituteOrder">
                        {draftData ? (
                          <div>
                            <Card>
                              <CardHeader>
                                <h4>Substituted Order</h4>
                              </CardHeader>
                              <CardBody>
                                <div style={{ overflow: "auto" }}>
                                  <Table className="align-items-center table-flush">
                                    <thead>
                                      <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total Price</th>
                                      </tr>
                                    </thead>
                                    <tbody className="list">
                                      {draftData.line_items.map((product) => (
                                        <tr>
                                          <td>{product.title}</td>
                                          <td>$ {product.price}</td>
                                          <td>{product.quantity}</td>
                                          <td>
                                            ${" "}
                                            {(
                                              product.price * product.quantity
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                            {draftData.status === "open" ? (
                              <div>
                                <FormGroup>
                                  <Card>
                                    <CardHeader>
                                      <h4>Customer E-mail</h4>
                                    </CardHeader>
                                    <CardBody>
                                      <Input
                                        id="customer_email"
                                        placeholder="Customer E-mail"
                                        type="email"
                                        name="customer_email"
                                        value={CustomerEmail}
                                        onChange={handleMailChange}
                                      />
                                      {emailError && (
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: 12,
                                          }}>
                                          {emailError}
                                        </span>
                                      )}
                                    </CardBody>
                                  </Card>
                                </FormGroup>

                                <Button
                                  color="primary"
                                  onClick={openModifyOrderModal}>
                                  Confirm
                                </Button>
                              </div>
                            ) : null}

                            <Modal
                              isOpen={modifyOrderModal}
                              fade={false}
                              // toggle={confirmModalToggle}
                              size="md"
                              centered
                              backdrop="static">
                              <ModalBody>
                                {/* <h2>Customer E-mail :- {CustomerEmail}</h2> */}
                                {/* <Input
                                  id="customer_email"
                                  placeholder="Customer E-mail"
                                  type="email"
                                  name="customer_email"
                                  value={CustomerEmail}
                                  onChange={handleMailChange}
                                /> */}
                                <p style={{ fontSize: "14px" }}>
                                  If the total amount of modified order exceeds
                                  the paid amount from the previous order, a
                                  payment link will be sent to the provided
                                  customer email{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {CustomerEmail}
                                  </span>
                                  . Otherwise, if it is less, a refund will be
                                  issued from the paid amount.
                                </p>
                              </ModalBody>
                              <ModalFooter style={{ marginTop: "-50px" }}>
                                <Button color="primary" onClick={handleApprove}>
                                  Okay
                                </Button>
                                <Button onClick={handleCancel}>Cancel</Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                        ) : null}
                      </TabPane>
                      <TabPane tabId="deliveryTracking">
                        <Row>
                          <Col sm="6">
                            {/* <Steps current={handleActiveStep(deliveryStatusActive, deliveryStatusActiveName)} vertical style={stylesreSuits}>
                              {deliveryStatus.map((e) => (
                                <Steps.Item title={e.key} description={handleDescription(e)} />
                              ))}

                            </Steps> */}
                          </Col>
                          {/* <Col sm="6 text-right" >
                            <Input
                              id="orderStatusInpur"
                              type="select"
                              onChange={(e) => handleDeliveryChange(e)}
                            >
                              <option value={""}>Select</option>
                              {deliveryStatus.map((status) => (
                                <option value={status.key} selected={status.key == deliveryStatusActiveName ? 'selected' : ''}>{status.key}</option>
                              ))}
                            </Input>
                            <br></br>
                            <Input name="text" placeholder='Enter some text if you want'  onChange={(e) => setaddedTextMessage(e.target.value)} />
                            <Button color="primary" onClick={() => updateState(deliveryStatusActive, deliveryStatusActiveName)}>
                              Update
                            </Button>
                          </Col> */}
                        </Row>
                        <Row>
                          <Col sm="12"></Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Col>
                  <Col lg="4" xs="12">
                    <h6 className="heading-small text-muted">
                      {modifyOrder
                        ? "Original Order Billing information"
                        : "Billing information"}
                    </h6>
                    <ListGroup data-toggle="checklist" flush>
                      <ListGroupItem className="checklist-entry flex-column align-items-start">
                        <div className="checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">Discount</h5>
                          </div>
                          <div>
                            <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                              - $
                              {originalData &&
                                originalData?.current_total_discounts}
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>

                      <ListGroupItem className="checklist-entry flex-column align-items-start">
                        <div className="checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">Sub Total</h5>
                          </div>
                          <div>
                            <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                              $
                              {originalData &&
                                parseFloat(
                                  originalData?.subtotal_price - tip
                                ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>

                      <ListGroupItem className="checklist-entry flex-column align-items-start">
                        <div className="checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">Tip</h5>
                          </div>
                          <div>
                            <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                              ${tip}
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>

                      {originalData?.shipping_lines[0]?.code && (
                        <ListGroupItem className="checklist-entry flex-column align-items-start">
                          <div className="checklist-item">
                            <div className="checklist-info">
                              <h5 className="checklist-title mb-0">
                                Shipping (
                                {originalData?.shipping_lines[0]?.code})
                              </h5>
                            </div>
                            <div>
                              <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                $
                                {originalData &&
                                  originalData?.shipping_lines[0]?.price}
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                      <ListGroupItem className="checklist-entry flex-column align-items-start">
                        <div className="checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">Tax</h5>
                          </div>
                          <div>
                            <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                              ${originalData && originalData?.total_tax}
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>

                      <ListGroupItem className="checklist-entry flex-column align-items-start">
                        <div className="checklist-item">
                          <div className="checklist-info">
                            <h5 className="checklist-title mb-0">
                              Total Price
                            </h5>
                          </div>
                          <div>
                            <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                              $
                              {originalData &&
                                originalData?.current_total_price}
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    </ListGroup>

                    {modifyOrder ? (
                      <div style={{ paddingTop: 40 }}>
                        <h6 className="heading-small text-muted">
                          Substituted Order Billing information{" "}
                        </h6>
                        <ListGroup data-toggle="checklist" flush>
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Sub Total
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $
                                  {modifyOrderData &&
                                    parseFloat(
                                      modifyOrderData?.data?.orderDetail
                                        ?.subtotal_price - tip
                                    ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                          {modifyOrderData?.data?.orderDetail?.shipping_lines[0]
                            ?.code && (
                            <ListGroupItem className="checklist-entry flex-column align-items-start">
                              <div className="checklist-item">
                                <div className="checklist-info">
                                  <h5 className="checklist-title mb-0">
                                    Shipping (
                                    {
                                      modifyOrderData?.data?.orderDetail
                                        ?.shipping_lines[0]?.code
                                    }
                                    )
                                  </h5>
                                </div>
                                <div>
                                  <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                    $
                                    {modifyOrderData &&
                                      modifyOrderData?.data?.orderDetail
                                        ?.shipping_lines[0]?.price}
                                  </div>
                                </div>
                              </div>
                            </ListGroupItem>
                          )}
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">Tax</h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $
                                  {modifyOrderData &&
                                    modifyOrderData?.data?.orderDetail
                                      ?.total_tax}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Total Price
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $
                                  {modifyOrderData &&
                                    modifyOrderData?.data?.orderDetail
                                      ?.current_total_price}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {Number(
                                    (
                                      modifyOrderData?.data?.orderDetail
                                        ?.current_total_price -
                                      originalData?.current_total_price
                                    ).toFixed(2)
                                  ) > 0
                                    ? "Price Difference"
                                    : "Refunded Amount"}
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $
                                  {(
                                    modifyOrderData?.data?.orderDetail
                                      ?.current_total_price -
                                    originalData?.current_total_price
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    ) : null}

                    {draftData && draftData.status === "open" ? (
                      <div style={{ paddingTop: 40 }}>
                        <h6 className="heading-small text-muted">
                          Substituted Order Billing information{" "}
                        </h6>
                        <ListGroup data-toggle="checklist" flush>
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Sub Total
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $
                                  {draftData &&
                                    parseFloat(
                                      draftData?.subtotal_price - tip
                                    ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                          {draftData?.shipping_lines &&
                            draftData?.shipping_lines[0]?.code && (
                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div className="checklist-info">
                                    <h5 className="checklist-title mb-0">
                                      Shipping (
                                      {draftData?.shipping_lines[0]?.code})
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                      $
                                      {draftData &&
                                        draftData?.shipping_lines[0]?.price}
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>
                            )}
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">Tax</h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  ${draftData && draftData?.total_tax}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Total Price
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  ${draftData?.total_price}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  {Number(
                                    (
                                      draftData?.total_price -
                                      originalData?.current_total_price
                                    ).toFixed(2)
                                  ) > 0
                                    ? "Price Difference"
                                    : "Applicable Refund"}
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $
                                  {Number(
                                    draftData?.total_price -
                                      originalData?.current_total_price
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    ) : null}

                    {originalData &&
                      originalData?.refunds.length > 0 &&
                      originalData?.refunds.some((refund) =>
                        refund?.order_adjustments.some(
                          (adjustment) =>
                            adjustment?.kind === "refund_discrepancy"
                        )
                      ) && (
                        <div style={{ paddingTop: 40 }}>
                          <h6 className="heading-small text-muted">
                            Refund Information{" "}
                          </h6>

                          {/* <ListGroup data-toggle="checklist" flush>
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Paid by customer
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  $ {originalData && originalData?.total_price}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                        <hr style={{ margin: 0 }} /> */}

                          {/* <ListGroup data-toggle="checklist" flush>
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Refunded
                                </h5>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup> */}

                          <ListGroup data-toggle="checklist" flush>
                            {originalData?.refunds?.map((item, index) => (
                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div>
                                    <h5>
                                      {/* Reason:{" "}
                                    {item?.note == ""
                                      ? "---"
                                      : `"${item?.note}"`} */}
                                      Refunded Amount
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                      <div
                                        key={index}
                                        className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                        -${" "}
                                        {item.transactions &&
                                          item.transactions.length > 0 &&
                                          item.transactions[0].amount}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                          {/* <hr style={{ margin: 0 }} /> */}

                          {/* <ListGroup data-toggle="checklist" flush>
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Net Payment
                                </h5>
                              </div>
                              <div>
                                <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                  ${" "}
                                  {originalData &&
                                    originalData?.current_total_price}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup> */}
                        </div>
                      )}

                    {originalData &&
                      originalData?.refunds.length > 0 &&
                      !modifyOrder && (
                        <div style={{ paddingTop: 40 }}>
                          <h6 className="heading-small text-muted">
                            Refund Information{" "}
                          </h6>

                          <ListGroup data-toggle="checklist" flush>
                            <ListGroupItem className="checklist-entry flex-column align-items-start">
                              <div className="checklist-item">
                                <div className="checklist-info">
                                  <h5 className="checklist-title mb-0">
                                    Paid by customer
                                  </h5>
                                </div>
                                <div>
                                  <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                    ${" "}
                                    {originalData && originalData?.total_price}
                                  </div>
                                </div>
                              </div>
                            </ListGroupItem>
                          </ListGroup>
                          <hr style={{ margin: 0 }} />

                          {/* <ListGroup data-toggle="checklist" flush>
                          <ListGroupItem className="checklist-entry flex-column align-items-start">
                            <div className="checklist-item">
                              <div className="checklist-info">
                                <h5 className="checklist-title mb-0">
                                  Refunded
                                </h5>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup> */}

                          <ListGroup data-toggle="checklist" flush>
                            {originalData?.refunds?.map((item, index) => (
                              <ListGroupItem className="checklist-entry flex-column align-items-start">
                                <div className="checklist-item">
                                  <div>
                                    <h5>
                                      {/* Reason:{" "}
                                    {item?.note == ""
                                      ? "---"
                                      : `"${item?.note}"`} */}
                                      Refunded Amount
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                      <div
                                        key={index}
                                        className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                        -${" "}
                                        {item.transactions &&
                                          item.transactions.length > 0 &&
                                          item.transactions[0].amount}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                          <hr style={{ margin: 0 }} />

                          <ListGroup data-toggle="checklist" flush>
                            <ListGroupItem className="checklist-entry flex-column align-items-start">
                              <div className="checklist-item">
                                <div className="checklist-info">
                                  <h5 className="checklist-title mb-0">
                                    Net Payment
                                  </h5>
                                </div>
                                <div>
                                  <div className="heading-small custom-control custom-checkbox custom-checkbox-warning">
                                    ${" "}
                                    {originalData &&
                                      originalData?.current_total_price}
                                  </div>
                                </div>
                              </div>
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                      )}
                  </Col>
                  {/* <Collapse isOpen={true}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col style={{ fontWeight: "800" }}>Order ID</Col>
                          <Col style={{ fontWeight: "800" }}>Status</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData && originalData?.pk}
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {orderStatus}
                              {"    "}
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                                onClick={() => modalToggle()}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </span>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col style={{ fontWeight: "800" }}>Location</Col>
                          <Col style={{ fontWeight: "800" }}>
                            Financial Status
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              {locationName}
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData && originalData?.financial_status}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col style={{ fontWeight: "800" }}>Order Note</Col>
                          <Col style={{ fontWeight: "800" }}>Creation Date</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.note_attributes.find(
                                  (x) => x.name === "order_note"
                                )?.value}
                              {"    "}
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                                onClick={() => noteAttributModalToggle()}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData?.ca &&
                                formatDateTimeInTimeZone(
                                  originalData?.ca
                                )?.split(",")[0]}
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse> */}
                </Row>

                <Row>
                  <Col md="12" className="mt-4">
                    <h6 className="heading-small text-muted">
                      Note Attributes
                    </h6>
                    {notesAttributesRows.length > 0 ? (
                      notesAttributesRows.map((row, idx) => (
                        <Row key={idx}>
                          {row.map((notesAttributesRow) =>
                            notesAttributesRow.name == "location" ? (
                              <Col md="3">
                                <h5 className="checklist-title mb-0">
                                  {notesAttributesRow.name}
                                </h5>
                                <div className="small-font">{locationName}</div>
                              </Col>
                            ) : (
                              <Col md="3">
                                <h5 className="checklist-title mb-0">
                                  {notesAttributesRow.name}
                                </h5>
                                <div className="small-font">
                                  {notesAttributesRow.value}
                                </div>
                              </Col>
                            )
                          )}
                        </Row>
                      ))
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>

                {/* <div>
                  <Button
                    color="primary"
                    onClick={() => toggle("productDetails")}
                    style={{
                      marginBottom: "1rem",
                      cursor: "pointer",
                      background: "white",
                      color: "black",
                    }}
                  >
                    Product Details
                    <span>
                      {collapseKey === "productDetails" ? (
                        <i
                          class="fa fa-minus"
                          style={{ fontSize: "10px", padding: "0 20px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa fa-plus"
                          style={{ fontSize: "10px", padding: "0 20px" }}
                        ></i>
                      )}
                    </span>
                  </Button>
                  <Collapse isOpen={collapseKey === "productDetails"}>
                    <Card>
                      <CardBody>
                        <div>
                          <Row>
                            <Col style={{ fontWeight: "800" }}>Item</Col>
                            <Col style={{ fontWeight: "800" }}>Price</Col>
                            <Col style={{ fontWeight: "800" }}>Quantity</Col>
                            <Col style={{ fontWeight: "800" }}>Total Price</Col>
                          </Row>
                          {originalData?.line_items.map((product) => (
                            <Row className="mt-4">
                              <Col>{product.title}</Col>
                              <Col>{product.price}</Col>
                              <Col>{product.quantity}</Col>
                              <Col>{product.price * product.quantity}</Col>
                            </Row>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div> */}

                {/* {originalData?.driver_id && (
                  <div>
                    <Button
                      color="primary"
                      onClick={() => toggle("driverDetails")}
                      style={{
                        marginBottom: "1rem",
                        cursor: "pointer",
                        background: "white",
                        color: "black",
                      }}
                    // className="btn"
                    >
                      Driver Details
                      <span>
                        {collapseKey === "driverDetails" ? (
                          <i
                            class="fa fa-minus"
                            style={{ fontSize: "10px", padding: "0 20px" }}
                          ></i>
                        ) : (
                          <i
                            class="fa fa-plus"
                            style={{ fontSize: "10px", padding: "0 20px" }}
                          ></i>
                        )}
                      </span>
                    </Button>
                    <Collapse isOpen={collapseKey === "driverDetails"}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col style={{ fontWeight: "800" }}>Name</Col>
                            <Col style={{ fontWeight: "800" }}>Email</Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="LockedFieldValue">
                                {driverDetails &&
                                  driverDetails?.firstName +
                                  " " +
                                  driverDetails?.lastName}
                              </span>
                            </Col>
                            <Col>
                              <span className="LockedFieldValue">
                                {driverDetails && driverDetails?.email}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col style={{ fontWeight: "800" }}>Phone</Col>
                            <Col style={{ fontWeight: "800" }}>
                              Vehicle Type
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="LockedFieldValue">
                                <span className="LockedFieldValue">
                                  {driverDetails?.phoneNo}
                                </span>
                              </span>
                            </Col>
                            <Col>
                              <span className="LockedFieldValue">
                                {driverDetails?.vehicleType}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col style={{ fontWeight: "800" }}>
                              Vehicle License
                            </Col>
                            
                          </Row>
                          <Row>
                            <Col>
                              <span className="LockedFieldValue">
                                {driverDetails?.vehicleLicense}
                              </span>
                            </Col>
                            
                          </Row>
                          <Row className="mt-4">
                            <Col style={{ fontWeight: "800" }}>
                              Vehicle Description
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span className="LockedFieldValue">
                                {driverDetails?.vehicleDescription}
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                )} */}

                <Modal
                  isOpen={reviewRatingModel}
                  fade={false}
                  toggle={() => reviewRatingModelToggle()}
                  backdrop="static"
                  centered>
                  <ModalHeader toggle={() => reviewRatingModelToggle()}>
                    Rating & Review
                    <h5 className="mt-4">
                      {orderReviewRating.length > 0 ? comments : ""}
                      {orderReviewRating.length > 0 ? (
                        <ReactStars
                          count={5}
                          value={ratings}
                          size={24}
                          edit={false}
                          activeColor="#ffd700"
                        />
                      ) : (
                        "No rating and review here."
                      )}
                    </h5>
                  </ModalHeader>
                </Modal>
                {/* <Modal
                  isOpen={messageDriverModal}
                  fade={false}
                  toggle={() => messageDriverModalToggle()}
                  backdrop="static"
                  centered
                >
                  <ModalHeader toggle={() => messageDriverModalToggle()}>
                    Leave a Message
                    <h5 className="mt-4">
                      Contact No: {driverDetails?.phoneNo}
                    </h5>
                  </ModalHeader>
                  <ModalBody>
                    <Input
                      id="orderStatusInpur"
                      type="text"
                      onChange={(e) => { setCustomerSms(e.target.value)}}
                    ></Input>
                    <p className="ErrorMessage">
                      {error !== null &&
                        "Unable to Edit, Please verify if order exist"}
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={
                        () => messageCustomer() // sendMessageToDriver()
                      }
                    >
                      Send Message
                    </Button>
                    <Button
                      color="secondary"
                      onClick={messageDriverModalToggle}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal> */}
                <Modal
                  isOpen={messageCustomerModal}
                  fade={false}
                  toggle={() => messageCustomerModalToggle()}
                  backdrop="static"
                  centered>
                  <ModalHeader toggle={() => messageCustomerModalToggle()}>
                    Leave a Message
                    <h5 className="mt-4">
                      Contact No: {mobileNumber} &nbsp;
                      <i
                        class="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => setPhoneModal(true)}
                        style={{ cursor: "pointer", color: "blue" }}></i>
                    </h5>
                  </ModalHeader>
                  <ModalBody>
                    <Input
                      id="orderStatusInpur"
                      type="text"
                      onChange={(e) => {
                        setCustomerSms(e.target.value);
                      }}
                      name="messageCustomer"
                      maxlength="255"></Input>
                    <p className="ErrorMessage">
                      {error !== null &&
                        "Unable to Edit, Please verify if order exist"}
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={
                        () => messageCustomer(customerSms) // sendMessageToDriver()
                      }>
                      Send Message
                    </Button>
                    <Button
                      color="secondary"
                      onClick={messageCustomerModalToggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  isOpen={phoneModal}
                  fade={false}
                  toggle={() => setPhoneModal(false)}
                  backdrop="static"
                  centered>
                  <ModalHeader toggle={() => setPhoneModal(false)}>
                    Edit mobile Number
                    {/* <h5 className="mt-4">
                      Contact No:{" "}
                      {originalData &&
                        originalData?.shipping_address &&
                        originalData?.shipping_address?.phone
                        ? originalData?.shipping_address?.phone
                        : originalData?.customer?.phone}
                    </h5> */}
                  </ModalHeader>
                  <ModalBody>
                    <Input
                      id="orderStatusInpur"
                      type="text"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      name="phoneNumber"
                      maxlength="12"></Input>
                    <p className="ErrorMessage">
                      {error !== null &&
                        "Unable to Edit, Please verify if order exist"}
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={
                        () => changeMobilenumberSms(mobileNumber) // sendMessageToDriver()
                      }>
                      Update Mobile Number
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setPhoneModal(false)}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                {/* <div>
                  <Button
                    color="primary"
                    onClick={() => toggle("customerDetails")}
                    style={{
                      marginBottom: "1rem",
                      cursor: "pointer",
                      background: "white",
                      color: "black",
                    }}
                  // className="btn"
                  >
                    Customer Details
                    <span>
                      {collapseKey === "customerDetails" ? (
                        <i
                          class="fa fa-minus"
                          style={{ fontSize: "10px", padding: "0 20px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa fa-plus"
                          style={{ fontSize: "10px", padding: "0 20px" }}
                        ></i>
                      )}
                    </span>
                  </Button>
                  <Collapse isOpen={collapseKey === "customerDetails"}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col style={{ fontWeight: "800" }}>Name</Col>
                          <Col style={{ fontWeight: "800" }}>Email</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.customer?.first_name +
                                " " +
                                originalData?.customer?.last_name}
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData && originalData?.customer?.email}
                            </span>
                          </Col>
                        </Row>
                        <br />
                        <hr />
                        <h2>Address Details </h2>
                        <Row className="mt-4">
                          <Col style={{ fontWeight: "800" }}>Phone</Col>
                          <Col style={{ fontWeight: "800" }}>Address1</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              <span className="LockedFieldValue">
                                {originalData &&
                                  originalData?.shipping_address &&
                                  originalData?.shipping_address?.phone
                                  ? originalData?.shipping_address?.phone
                                  : originalData?.customer?.phone}
                              </span>
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.address1
                                ? originalData?.shipping_address?.address1
                                : originalData?.customer?.default_address
                                  ?.address1}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col style={{ fontWeight: "800" }}>Address2</Col>
                          <Col style={{ fontWeight: "800" }}>City</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.address2
                                ? originalData?.shipping_address?.address2
                                : originalData?.customer?.default_address
                                  ?.address1}
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.city
                                ? originalData?.shipping_address?.city
                                : originalData?.customer?.default_address?.city}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col style={{ fontWeight: "800" }}>Province</Col>
                          <Col style={{ fontWeight: "800" }}>Country</Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.province
                                ? originalData?.shipping_address?.province
                                : originalData?.customer?.default_address
                                  ?.province}
                            </span>
                          </Col>
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.country
                                ? originalData?.shipping_address?.country
                                : originalData?.customer?.default_address
                                  ?.country}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col style={{ fontWeight: "800" }}>Zip</Col>
                        </Row>
                        <Row className="mb-4">
                          <Col>
                            <span className="LockedFieldValue">
                              {originalData &&
                                originalData?.shipping_address &&
                                originalData?.shipping_address?.zip
                                ? originalData?.shipping_address?.zip
                                : originalData?.customer?.default_address?.zip}
                            </span>
                          </Col>
                        </Row>
                        <Button
                          color="secondary"
                          onClick={() => customerDetailModalToggle()}
                          style={{
                            marginBottom: "1rem",
                            background: "#5e72e4",
                            color: "white",
                          }}
                        >
                          Edit Customer Details
                        </Button>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div> */}

                {/* <div>
                  <Button
                    color="primary"
                    onClick={() => toggle("billing")}
                    style={{
                      marginBottom: "1rem",
                      cursor: "pointer",
                      background: "white",
                      color: "black",
                    }}
                  >
                    Billing
                    <span>
                      {collapseKey === "billing" ? (
                        <i
                          class="fa fa-minus"
                          style={{ fontSize: "10px", padding: "0 20px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa fa-plus"
                          style={{ fontSize: "10px", padding: " 0 20px" }}
                        ></i>
                      )}
                    </span>
                  </Button>
                  <Collapse isOpen={collapseKey === "billing"}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>Discount</Col>
                          <Col>
                            <span className="LockedFieldValue">
                              - $
                              {originalData &&
                                originalData?.current_total_discounts}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col>Sub Total</Col>
                          <Col>
                            <span className="LockedFieldValue ml-2">
                              ${originalData && parseFloat(originalData?.subtotal_price - tip).toFixed(2)}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>Tip</Col>
                          <Col>
                            <span className="LockedFieldValue ml-2">
                              $
                              {tip}
                            </span>
                          </Col>
                        </Row>
                        {originalData?.shipping_lines[0]?.code && (
                          <Row>
                            <Col>
                              Shipping ({originalData?.shipping_lines[0]?.code})
                            </Col>
                            <Col>
                              <span className="LockedFieldValue ml-2">
                                $
                                {originalData &&
                                  originalData?.shipping_lines[0]?.price}
                              </span>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>Tax</Col>
                          <Col>
                            <span className="LockedFieldValue ml-2">
                              ${originalData && originalData?.total_tax}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ fontWeight: "800" }}>Total Price</Col>
                          <Col style={{ fontWeight: "800" }}>
                            <span className="LockedFieldValue ml-2">
                              $
                              {originalData &&
                                originalData?.current_total_price}
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div> */}

                {/* <div>
                  <Button
                    color="primary"
                    onClick={() => toggle("refundDetails")}
                    style={{
                      marginBottom: "1rem",
                      cursor: "pointer",
                      background: "white",
                      color: "black",
                    }}
                  >
                    Refund Details
                    <span>
                      {collapseKey === "refundDetails" ? (
                        <i
                          class="fa fa-minus"
                          style={{ fontSize: "10px", padding: "0 20px" }}
                        ></i>
                      ) : (
                        <i
                          class="fa fa-plus"
                          style={{ fontSize: "10px", padding: " 0 20px" }}
                        ></i>
                      )}
                    </span>
                  </Button>
                  <Collapse isOpen={collapseKey === "refundDetails"}>
                    <Card>
                      <CardBody>

                        <h2>Refund Items</h2>
                        <div className="mt-5">
                          <Row>
                            <Col style={{ fontWeight: "800" }}>Item</Col>
                            <Col style={{ fontWeight: "800" }}>Price</Col>
                            <Col style={{ fontWeight: "800" }}>Quantity</Col>
                            <Col style={{ fontWeight: "800" }}>
                              Total Amount
                            </Col>
                          </Row>
                          {originalData?.refunds[0]?.refund_line_items?.map(
                            (line_item) => (
                              <Row className="mt-4">
                                <Col>{line_item?.line_item?.title}</Col>
                                <Col>{line_item?.subtotal}</Col>
                                <Col>{line_item?.quantity}</Col>
                                <Col>
                                  {line_item?.subtotal * line_item.quantity}
                                </Col>
                              </Row>
                            )
                          )}
                        </div>
                        <br />
                        <hr />
                        <h3>
                          Shipping Refunded:{" "}
                          {
                            originalData?.refunds[0]?.order_adjustments[0]
                              ?.amount
                          }
                        </h3>

                      </CardBody>
                    </Card>
                  </Collapse>
                </div> */}
              </CardBody>
            </Card>
            <Modal
              isOpen={modal}
              fade={false}
              toggle={modalToggle}
              backdrop="static"
              centered>
              <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader>
              <ModalBody>
                <Input
                  id="orderStatusInpur"
                  type="select"
                  onChange={(e) => handleChange(e)}>
                  <option value={""}>Select</option>
                  {dispatcherStatus?.map((status) => (
                    <option
                      value={status}
                      selected={status === orderStatus ? "selected" : ""}>
                      {status}
                    </option>
                  ))}
                </Input>
                <p className="ErrorMessage">
                  {error !== null &&
                    "Unable to Edit, Please verify if order exist"}
                </p>
                <label>Enter Tracking Link</label>
                <Input
                  type="text"
                  name="Delivery"
                  value={deliveryTracking}
                  onChange={(e) => handleDelivery(e)}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => confirmModalToggleStatus()}>
                  Update
                </Button>
                <Button color="secondary" onClick={modalToggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={confirmModalStatus}
              fade={false}
              toggle={confirmModalToggleStatus}
              size="sm"
              centered
              backdrop="static">
              {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
              <ModalBody>Are you sure you want to update?</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => handleUpdateStatus()}>
                  Yes
                </Button>
                <Button color="secondary" onClick={confirmModalToggleStatus}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            {/* update Note attribut */}
            <Modal
              isOpen={noteAttributeModal}
              fade={false}
              toggle={noteAttributModalToggle}
              backdrop="static"
              centered>
              <ModalHeader toggle={noteAttributModalToggle}>
                Edit Order Note
              </ModalHeader>
              <ModalBody>
                <Input
                  id="orderNote"
                  placeholder="Order Note"
                  type="text"
                  defaultValue={orderNote}
                  name="orderNote.country"
                  onChange={(e) => handleOrderNote(e)}
                />
                <p className="ErrorMessage">
                  {error !== null &&
                    "Unable to Edit, Please verify if order exist"}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => confirmModalToggle()}>
                  Update
                </Button>
                <Button color="secondary" onClick={noteAttributModalToggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={confirmModal}
              fade={false}
              toggle={confirmModalToggle}
              size="sm"
              centered
              backdrop="static">
              {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
              <ModalBody>Are you sure you want to update?</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => handleUpdateOrderNote()}>
                  Yes
                </Button>
                <Button color="secondary" onClick={confirmModalToggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            {/* unassign driver modal */}
            <Modal
              isOpen={unassignDriverModal}
              fade={false}
              toggle={unassignDriverModalToggle}
              size="sm"
              centered
              backdrop="static">
              {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
              <ModalBody>Are you sure you want to unassign driver ?</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => unassignDriver()}>
                  Yes
                </Button>
                <Button color="secondary" onClick={unassignDriverModalToggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            {/* Customer Detail Modal */}
            <Modal
              isOpen={customerDetailModal}
              fade={false}
              toggle={customerDetailModalToggle}
              size="lg"
              centered
              backdrop="static">
              <ModalHeader toggle={customerDetailModalToggle}>
                Edit Customer Detail
              </ModalHeader>
              <ModalBody>
                <Formik
                  initialValues={formData}
                  validate={(values) => {
                    const errors = {};
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    editCustomerDetails(values, setSubmitting);
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form>
                      <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input">
                              Firstname
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Firstname"
                              type="Firstname"
                              defaultValue={formData?.first_name}
                              name="first_name"
                              onChange={handleChange}
                              disabled
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input">
                              Lastname
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Lastname"
                              type="Lastname"
                              defaultValue={formData?.last_name}
                              disabled
                              name="last_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input">
                              Email
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Email"
                              type="email"
                              defaultValue={
                                originalData && originalData?.customer?.email
                              }
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input">
                              Address1
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Address1"
                              type="address1"
                              defaultValue={
                                formData?.address1
                                  ? formData?.address1
                                  : formData?.default_address?.address1
                              }
                              name={"default_address.address1"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input">
                              Address2
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Address2"
                              type="address2"
                              defaultValue={
                                formData?.address2
                                  ? formData?.address2
                                  : formData?.default_address?.address2
                              }
                              name="default_address.address2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input">
                              Country
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Country"
                              type="country"
                              defaultValue={
                                formData?.country
                                  ? formData?.country
                                  : formData?.default_address?.country
                              }
                              name="default_address.country"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input">
                              Zip
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Zip"
                              type="zip"
                              defaultValue={
                                formData?.zip
                                  ? formData?.zip
                                  : formData?.default_address?.zip
                              }
                              name="default_address.zip"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input">
                              City
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="City"
                              type="city"
                              defaultValue={
                                formData?.city
                                  ? formData?.city
                                  : formData?.default_address?.city
                              }
                              name="default_address.city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input">
                              Province
                            </label>
                            <Input
                              id="example3cols1Input"
                              placeholder="Province"
                              type="province"
                              defaultValue={
                                formData?.province
                                  ? formData?.province
                                  : formData?.default_address?.province
                              }
                              name="default_address.province"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols2Input">
                              Phone
                            </label>
                            <Input
                              id="example3cols2Input"
                              placeholder="Phone"
                              type="phone"
                              defaultValue={
                                formData?.phone
                                  ? formData?.phone
                                  : formData?.phone
                              }
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        color="primary"
                        className="PrimaryButton"
                        type="submit"
                        disabled={isSubmitting}>
                        Edit
                      </Button>
                      <Button
                        color="secondary"
                        onClick={customerDetailModalToggle}>
                        Cancel
                      </Button>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
          </div>
          {/* <ChatBox chatWith="Customer" />
          {originalData?.driver_id && (
            <ChatBox style={{ paddingTop: "4%" }} chatWith="Driver" />
          )} */}
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default withRouter(DetailedOrdersView);
