import React, { useState, useEffect } from 'react';
import Header from "../../ui/Header.js";
import { ClipLoader } from 'react-spinners';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Button,
    Row,
    Table,
    Badge, Col
} from "reactstrap";


function DriverProfileShow(props) {

    let { userID } = useParams()
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [originalData, setOriginalData] = useState([])

    const handleView = () => {
        const viewPath = "/admin/drivers"
        history.push(viewPath)
    }

    useEffect(() => {
        fetch('https://1ldhzr0mid.execute-api.us-east-1.amazonaws.com//dev/v1/user/' + userID + "?type=Driver")
            .then(response => response.json())
            .then(res => {
                console.log(res)
                setOriginalData(res.data[0])
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })

    }, [userID]);

    if (isLoading || !originalData) {
        return (
            <div className="sweet-loading card">
                <div className="spinner-container">
                    <ClipLoader
                        className="spinner"
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col lg="6" xs="7">
                                        <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                                            {"Driver Detail"}
                                        </h6>
                                    </Col>
                                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => handleView()}
                                            size="sm"
                                        >
                                            Back To All Drivers
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                        src={originalData.profileImg ? originalData.profileImg : require("../../../assets/img/theme/driver.png")}
                                        style={{ width: "140px" }}
                                    />
                                </a>
                                <div className="pt-4 text-center">
                                    <h5 className="h3 title">
                                        <span className="d-block mb-1">{originalData.firstName + ' ' + originalData.lastName}</span>
                                        <small className="h4 font-weight-light text-muted">
                                            {originalData.role}
                                        </small>
                                    </h5>
                                </div>
                                <Table border="1" style={{ 'borderColor': "#e9ecef" }} responsive>
                                    <tbody>
                                        <tr>
                                            <th>Email</th>
                                            <td>{originalData.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone No.</th>
                                            <td>{originalData.phoneNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Is Verified</th>
                                            <td>
                                                <Badge className="badge-lg" color={originalData.isVerified ? 'success' : 'danger'}>
                                                    {originalData.isVerified ? 'Yes' : 'No'}
                                                </Badge>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Is Enable</th>
                                            <td>
                                                <Badge className="badge-lg" color={originalData.isEnabled ? 'success' : 'danger'}>
                                                    {originalData.isEnabled ? 'Enabled' : 'Disabled'}
                                                </Badge>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Vehicle Type</th>
                                            <td>{originalData.vehicleType}</td>
                                        </tr>
                                        <tr>
                                            <th>Vehicle License</th>
                                            <td>{originalData.vehicleLicense}</td>
                                        </tr>
                                        <tr>
                                            <th>Vehicle Description</th>
                                            <td>{originalData.vehicleDescription}</td>
                                        </tr>
                                        <tr>
                                            <th>Driving License Image</th>
                                            <td>
                                                <a href={originalData.drivingLicenseImg} target="_blank">
                                                    <img
                                                        alt="..."
                                                        src={originalData.drivingLicenseImg}
                                                        style={{ width: "140px" }}
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Insurance Image</th>
                                            <td>
                                                <a href={originalData.insuranceImg} target="_blank">
                                                    <img
                                                        alt="..."
                                                        src={originalData.insuranceImg}
                                                        style={{ width: "140px" }}
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>

    )
}

export default DriverProfileShow;
