import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../../../onboarding/components/Loader";
import SearchBar from "../../components/SearchBar";

const headerData = [
  {
    header: "Image",
  },
  {
    header: "Title",
  },
  {
    header: "No Of Variants",
  },
  {
    header: "Status",
  },
  {
    header: "Actions",
  },
];

const ProductMenu = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);

  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedOption, setSelectedOption] = useState("");
  const [vendorIdOptions, setVendorIdOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [fileData, setFileData] = useState({});

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  const locId = localStorage.getItem("selected_location_id").split("#")[1];

  const thirdPartyDeliveryOptions = () => {
    fetch(`${ApiEndpoints.getVendorsByLocationId}/${locId}`)
      .then((response) => response.json())
      .then((data) => {
        const thirdPartyDelivery = data?.data;
        console.log("thirdPartyDelivery", thirdPartyDelivery);
        const vendorIds = thirdPartyDelivery.map(
          (option) => option.vendor_name
        );

        // setSelectedOption(vendorIds);
        setVendorIdOptions(vendorIds);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    thirdPartyDeliveryOptions();
  }, []);

  const handleDownload = () => {
    if (selectedOption && fileData[selectedOption]) {
      const location = localStorage.getItem("selected_location_name");
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(fileData[selectedOption]);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${location}_${selectedOption}_data.xlsx`);
    }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    console.log("fileData", fileData);
    console.log("selectedValue", selectedValue);

    if (selectedValue) {
      setSelectedOption(selectedValue);
      setModal(true);
    } else {
      setSelectedOption("");
      setModal(false);
    }
  };

  const getExportProducts = async (locationId) => {
    try {
      setIsLoading(true);

      let res = await axios.get(
        `${ApiEndpoints.getAllProductsInventory}?locationId=${
          locationId.split("#")[1]
        }&page=1&limit=1000`
      );
      const exportProductsData = res.data.data;

      let fileData = {
        InstaCart: [],
        Drizly: [],
        DoorDash: [],
        GrubHub: [],
        UberEats: [],
        LSorders: [],
      };

      for (let i in exportProductsData) {
        fileData.Drizly.push({
          StoreId: exportProductsData[i].store_id,
          UPC: exportProductsData[i].bar_code,
          SKU: exportProductsData[i].sku,
          BrandName: exportProductsData[i].brand_name,
          ItemName: exportProductsData[i].item_name,
          Size: exportProductsData[i].size,
          Description: exportProductsData[i].description,
          Price: exportProductsData[i].price,
          Category: exportProductsData[i].category,
          QuantityOnHand: exportProductsData[i].ls_qty,
          PackageType: exportProductsData[i].package_type,
        });
        fileData.LSorders.push({
          StoreId: exportProductsData[i].store_id,
          UPC: exportProductsData[i].bar_code,
          SKU: exportProductsData[i].sku,
          BrandName: exportProductsData[i].brand_name,
          ItemName: exportProductsData[i].item_name,
          Size: exportProductsData[i].size,
          Description: exportProductsData[i].description,
          Price: exportProductsData[i].price,
          Category: exportProductsData[i].category,
          QuantityOnHand: exportProductsData[i].ls_qty,
          PackageType: exportProductsData[i].package_type,
        });
        fileData.UberEats.push({
          StoreId: exportProductsData[i].store_id,
          UPC: exportProductsData[i].bar_code,
          SKU: exportProductsData[i].sku,
          BrandName: exportProductsData[i].brand_name,
          ItemName: exportProductsData[i].item_name,
          Size: exportProductsData[i].size,
          Description: exportProductsData[i].description,
          Price: exportProductsData[i].price,
          Category: exportProductsData[i].category,
          QuantityOnHand: exportProductsData[i].ls_qty,
          PackageType: exportProductsData[i].package_type,
        });
        fileData.InstaCart.push({
          StoreId: exportProductsData[i].store_id,
          UPC: Number(exportProductsData[i].bar_code)
            .toString()
            .padStart(12, "0"),
          SKU: exportProductsData[i].sku,
          BrandName: exportProductsData[i].brand_name,
          ItemName: exportProductsData[i].item_name,
          Size: exportProductsData[i].size,
          Description: exportProductsData[i].description,
          Price: exportProductsData[i].price,
          Category: exportProductsData[i].category,
          QuantityOnHand: exportProductsData[i].ls_qty,
          PackageType: exportProductsData[i].package_type,
        });
        fileData.GrubHub.push({
          UPC: exportProductsData[i].bar_code,
          ItemName: exportProductsData[i].item_name,
          Price: exportProductsData[i].price,
          Category: exportProductsData[i].category,
          ImageURL: exportProductsData[i].image_url,
        });
        fileData.DoorDash.push({
          UPC: exportProductsData[i].bar_code,
          ItemName: exportProductsData[i].item_name,
          Price: exportProductsData[i].price,
        });
      }
      setProducts(exportProductsData);

      setFileData(fileData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  console.log("EXPOT", products);

  console.log("fileDatafileDatafileData", fileData);

  useEffect(() => {
    let locationId = localStorage.getItem("selected_location_id");
    if (locationId) {
      getExportProducts(locationId);
    }
  }, []);

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Menu"}
                    </h6>
                  </Col>
                  {vendorIdOptions.length > 0 && products?.length > 0 ? (
                    <Col
                      className="mt-3 mt-md-0 text-md-right"
                      lg="6"
                      xs="5"
                      style={{ marginBottom: -35 }}
                    >
                      {(userRoles.includes("1") ||
                        roleActions.includes("menu_read")) && (
                        <FormGroup>
                          <Input
                            type="select"
                            name="menuDropdown"
                            id="menuDropdown"
                            value={selectedOption}
                            onChange={handleSelectChange}
                            style={{
                              width: "30%",
                              marginLeft: "65%",
                              height: 40,
                              fontSize: "0.8125rem",
                            }}
                          >
                            <option value="">Download Third Party Menu</option>
                            {vendorIdOptions.map((vendorId) => (
                              <option key={vendorId} value={vendorId}>
                                {vendorId}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      )}
                    </Col>
                  ) : null}
                </Row>
              </CardHeader>

              <Modal
                isOpen={modal}
                fade={false}
                toggle={modalToggle}
                size="lg"
                centered
                style={{ maxWidth: "80%" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginTop: "30px",
                      marginLeft: "25px",
                    }}
                  >
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={modalToggle}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h3>Third Party Menu Data</h3>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      marginTop: "15px",
                      marginRight: "30px",
                    }}
                  >
                    {fileData[selectedOption] &&
                      fileData[selectedOption].length > 0 && (
                        <Button
                          color="primary"
                          size="sm"
                          onClick={handleDownload}
                        >
                          Download
                        </Button>
                      )}
                  </div>
                </div>
                <ModalBody>
                  {fileData[selectedOption] &&
                  fileData[selectedOption].length > 0 ? (
                    <div style={{ height: 560, overflowY: "scroll" }}>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            {fileData[selectedOption].length > 0 &&
                              Object.keys(fileData[selectedOption][0]).map(
                                (key) => <th key={key}>{key}</th>
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {fileData[selectedOption].map((item, index) => (
                            <tr key={index}>
                              {Object.values(item).map((value, index) => (
                                <td key={index}>{value}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <p>No data found for the selected option.</p>
                  )}
                </ModalBody>
              </Modal>

              <div style={{ height: 700, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      <th>upc</th>
                      <th>item name</th>
                      <th>category</th>
                      {/* <th>category l2</th> */}
                      <th>price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products && products?.length ? (
                      products.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{row.bar_code}</td>
                          <td>{row.item_name}</td>
                          <td>{row.category}</td>
                          <td>${row.price}</td>
                          <td></td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Data Found...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter>
                {/* {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )} */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default ProductMenu;
