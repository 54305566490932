import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import axios from "axios";
import setAuthToken from "../setAuthToken";
import { ApiUrls } from "../../common/apiconstant";
import { logUserMetrics } from "../../utils/file";
import { toast } from "react-toastify";
import { auth } from "../../admin/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../app/redux/auth/actions";
import FullPageLoader from "../../components/FullPageLoader";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const GoogleSignInButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  async function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("Test", JSON.parse(jsonPayload));
    return JSON.parse(jsonPayload);
  }

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  const responseGoogle = async (response) => {
    setIsLoading(true);
    try {
      console.log("response google", response);

      const { credential } = response;
      console.log("Decoded Google ID Token:", credential);

      try {
        const credentials = await Auth.federatedSignIn("google", {
          token: credential,
        });
        console.log("credentials", credentials);
        const user = await Auth.currentAuthenticatedUser();
        console.log("User attributes from Cognito:", user);

        let userResponse = await parseJwt(credential);
        console.log("userResponse", userResponse);
        // let awsAPI = "https://cognito-idp.us-east-1.amazonaws.com/";

        // let reqBody = {
        //   AccessToken: credential,
        // };

        // try {
        //   let api = await axios.post(awsAPI, reqBody, {
        //     headers: {
        //       "Content-Type": "application/x-amz-json-1.1",
        //       "X-Amz-Target": "CodeBuild_20161006.StartBuild",
        //     },
        //   });
        //   console.log("apisahidi", api);
        // } catch (err) {
        //   console.log("erorooror", err);
        // }

        // const JsonSignInUserSession = JSON.parse(JSON.stringify(user))
        // .signInUserSession.accessToken;

        try {
          const signInRes = await Auth.signIn({
            username: userResponse.email,
            oauth: {
              source: "google",
              token: credential,
            },
          });
          let signInResponse = JSON.parse(JSON.stringify(signInRes));

          console.log("SignInWithoutPassword response:", signInResponse);

          // Continue with the rest of your code (setting tokens, fetching data, etc.)
          // ...

          const JsonSignInUserSession =
            signInResponse.signInUserSession.accessToken;
          const bearerToken = "Bearer " + JsonSignInUserSession.jwtToken;
          let res123 = await parseJwt(JsonSignInUserSession.jwtToken);

          // let email = res123?.email;
          // console.log("emailasiodhi", email);

          console.log("JsonSignInUserSession", res123, bearerToken);
          localStorage.setItem("jwtToken", bearerToken);
          localStorage.setItem("username", signInResponse.username);
          // Set bearerToken to Auth header
          setAuthToken(bearerToken);

          const attributes = {
            email: signInResponse?.attributes?.email,
            email_verified: signInResponse?.attributes?.email_verified,
            sub: signInResponse?.attributes?.sub,
          };

          localStorage.setItem("attributes", JSON.stringify(attributes));

          console.log("attributes", attributes);

          fetch(`${ApiUrls.getLoginUser}?email=${signInResponse.username}`)
            .then((response) => response.json())
            .catch(async (err) => {
              console.log(err);
              let action = "google-login";
              let type = "Error";
              let message = "Error in user google login.";

              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);
            })
            .then(async (res) => {
              console.log("NAMESSGGS", res);

              if (!res?.data?.user?.is_active) {
                console.log("IN IF ADMIN");
                toast.error(
                  "Your account has been suspended, Please contact liquorsplit admin."
                );

                return;
              }

              localStorage.setItem("userRole", "Admin");
              localStorage.setItem("Name", res?.data?.user?.firstname);
              localStorage.setItem(
                "fullname",
                res?.data?.user?.firstname + " " + res?.data?.user?.lastname
              );
              localStorage.setItem("userId", res?.data?.user?.email);
              // // if (
              // //   typeof res?.data[0]?.user_role !== undefined &&
              // //   res?.data[0].user_role !== Role.Admin
              // // ) {
              // //   if (res?.data[0].defaultStore !== undefined) {
              // //     localStorage.setItem("location", res?.data[0].defaultStore);
              // //   }
              // // }
              console.log(
                "res?.data?.rolesActionres?.data?.rolesAction=>",
                res?.data?.rolesAction
              );
              localStorage.setItem(
                "user_detail",
                JSON.stringify(res?.data?.user)
              );
              localStorage.setItem(
                "role_actions",
                JSON.stringify(res?.data?.rolesAction)
              );

              console.log(JsonSignInUserSession, "Payload-");

              let action = "google-login";
              let type = "Info";
              let message = "User successfully logged in via google.";

              const apiResponse = await logUserMetrics(action, type, message);
              console.log("apiirresponse", apiResponse);
              console.log("pauload curren user", JsonSignInUserSession.payload);
              // dispatch(setCurrentUser(JsonSignInUserSession.payload));
              dispatch(setCurrentUser(JsonSignInUserSession.payload));
              let firebaseLogin = await signInWithEmailAndPassword(
                auth,
                "admin@liquorsplit.com",
                "Liquor@#1234"
              );
              console.log("firebaseLogin", firebaseLogin);
              setIsLoading(false);
              // // await signOut();
              history.push("/admin");
            });
        } catch (signInError) {
          setIsLoading(false);
          console.error("Error signing in without password:", signInError);
          if (signInError.message == "User is disabled.") {
            toast.error("User is disabled");
            return;
          } else {
            toast.error("User not found");
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error signing in google", error);
      }

      // ... rest of your code ...
    } catch (error) {
      setIsLoading(false);
      console.error("Error handling Google ID Token:", error);
    }
  };

  const responseErrorGoogle = (error) => {
    setIsLoading(false);
    console.log("errrrrr", error);
  };

  return (
    <>
      {/* <button onClick={() => login()}>Sign in with Google</button> */}
      <GoogleLogin
        // clientId="480518930655-onids1sr6oir6op0e2dmi0hceprf9em8.apps.googleusercontent.com"
        onSuccess={responseGoogle}
        onError={responseErrorGoogle}
        type="standard"
        text="continue_with"
        size="medium"
        logo_alignment="left"
        theme="filled_blue"
        width="100"
      />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default GoogleSignInButton;
