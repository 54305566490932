import React, { useState, useEffect } from "react";
import Header from "../../ui/Header.js";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Row,
  Table,
  Badge,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import { ApiUrls } from "../../../common/apiconstant.js";
import styles from "../../styles/showTenant.module.css";
import getToken from "../../../onboarding/getToken.js";
import { ApiEndpoints } from "../../constants/constant.js";
import Loader from "../../../onboarding/components/Loader.js";
import { useMediaQuery } from "@material-ui/core";

const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

function EmployeesProfileShow(props) {
  //console.log(params);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreToggle = () => setDropdownOpen((prevState) => !prevState);

  const isMobile = useMediaQuery("(max-width: 500px)");

  const handleView = () => {
    const viewPath = "/admin/tenant";
    history.push(viewPath);
  };

  let { emailId } = useParams();

  const getTenantDetail = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getTenantDetailApi = `${ApiEndpoints.viewtenantDetail}?email=${emailId}`;

    fetch(getTenantDetailApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        const responseTenantDetail = res?.data?.customer;
        setOriginalData(responseTenantDetail);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getTenantDetail();
  }, []);

  // useEffect(() => {
  //   if (userID) {
  //     fetch(`${ApiUrls.getLoginUser}?email=${userID}`)
  //       .then((response) => response.json())
  //       .catch((err) => {
  //         console.log(err);
  //         setIsLoading(false);
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         setOriginalData(res?.data?.user);
  //         setIsLoading(false);
  //       });
  //   }
  // }, [userID]);

  // if (isLoading || !originalData) {
  //   return (
  //     <div className="sweet-loading card">
  //       <div className="spinner-container">
  //         <ClipLoader
  //           className="spinner"
  //           sizeUnit={"px"}
  //           size={50}
  //           color={"#123abc"}
  //           loading={isLoading}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row
                  className="align-items-center"
                  // style={isMobile ? { fontSize: "13px" } : null}
                  // className={styles.header}
                >
                  <Col lg="6" xs="7" className={styles.column}>
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Tenant Detail"}
                    </h6>
                  </Col>
                  <Col
                    // className={`mt-3 mt-md-0 text-md-right ${styles.buttons}`}
                    className={styles.buttons}
                    lg="6"
                    xs="5"
                    style={
                      !isMobile
                        ? { display: "flex", justifyContent: "end" }
                        : {}
                    }
                  >
                    {/* <Button
                      color="default"
                      size="sm"
                      // className="ml-2"
                      onClick={() => history.push("/admin/locations")}
                    >
                      Location
                    </Button>
                    <Button
                      color="default"
                      size="sm"
                      // className="ml-2"
                      onClick={() => history.push("/admin/users")}
                    >
                      User
                    </Button> */}
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={moreToggle}
                      className={styles.orderOpitons}
                    >
                      <DropdownToggle caret size="sm">
                        Actions
                      </DropdownToggle>
                      <DropdownMenu className={styles.orderDropDownOptions}>
                        <DropdownItem
                          size="sm"
                          onClick={() => history.push("/admin/locations")}
                          className={styles.orderDeatilsButton}
                        >
                          Locations
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => history.push("/admin/users")}
                          className={styles.orderDeatilsButton}
                        >
                          User
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div style={{ marginBottom: 15 }}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="Logo"
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={originalData.logo}
                      style={{ width: 150, height: 150, objectFit: "cover" }}
                    />
                  </a>
                </div>

                {/* <div className="pt-4 text-center">
                  <h5 className="h3 title">
                    <span className="d-block mb-1">
                      {originalData.firstname + " " + originalData.lastname}
                    </span> */}
                {/* <small className="h4 font-weight-light text-muted">
                      {originalData.role}
                    </small> */}
                {/* </h5>
                </div> */}
                <Table border="1" style={{ borderColor: "#e9ecef" }} responsive>
                  <tbody>
                    <tr>
                      <th>Business-Name</th>
                      <td>{originalData.business_name}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{originalData.email}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{originalData.phone}</td>
                    </tr>
                    <tr>
                      <th>Contact Person First Name</th>
                      <td>{originalData.contact_person_firstname}</td>
                    </tr>
                    <tr>
                      <th>Contact Person Last Name</th>
                      <td>{originalData.contact_person_lastname}</td>
                    </tr>
                    <tr>
                      <th>Contact Person Email</th>
                      <td>{originalData.contact_person_email}</td>
                    </tr>
                    <tr>
                      <th>Contact Person Phone</th>
                      <td>{originalData.contact_person_phone}</td>
                    </tr>
                    {/* <tr>
                      <th>Location</th>
                      <td>{originalData.locations}</td>
                    </tr> */}
                    <tr>
                      <th>Billing Address 1</th>
                      <td>{originalData.billing_address1}</td>
                    </tr>
                    <tr>
                      <th>Billing Address 2</th>
                      <td>{originalData.billing_address2}</td>
                    </tr>
                    <tr>
                      <th>Billing City</th>
                      <td>{originalData.billing_city}</td>
                    </tr>
                    <tr>
                      <th>Billing State</th>
                      <td>{originalData.billing_state}</td>
                    </tr>
                    <tr>
                      <th>Billing Zip Code</th>
                      <td>{originalData.billing_zip_code}</td>
                    </tr>
                    <tr>
                      <th>Is active</th>
                      <td>
                        <Badge
                          className="badge-lg"
                          color={originalData.is_active ? "success" : "danger"}
                        >
                          {originalData.is_active ? "Yes" : "No"}
                        </Badge>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>Is Enable</th>
                      <td>
                        <Badge
                          className="badge-lg"
                          color={originalData.isEnabled ? "success" : "danger"}
                        >
                          {originalData.isEnabled ? "Enabled" : "Disabled"}
                        </Badge>
                      </td>
                    </tr> */}

                    {/* <tr>
                      <th style={{ verticalAlign: "middle" }}>Location</th>
                      <td>
                        {originalData?.locations?.map((location, i) => (
                          <td key={i}>
                            <IMGetLocationTitle id={location} />
                          </td>
                        ))}
                      </td>
                    </tr> */}
                    {/* <tr>
                      <th style={{ verticalAlign: "middle" }}>Roles</th>
                      <td> */}
                    {/* {roles?.map((item) =>
                          originalData?.roles?.map((roleId) => {
                            if (roleId == item.id) {
                              return item.role;
                            }
                          })
                        )} */}

                    {/* {roles?.map((paragraph) => (
                          <>
                            {originalData?.roles?.includes(paragraph.id) ? (
                              <td>{paragraph.role.toUpperCase()}</td>
                            ) : null}
                          </>
                        ))} */}
                    {/* </td>
                    </tr> */}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default EmployeesProfileShow;
