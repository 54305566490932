import React, { useState, useEffect } from "react";
import Header from "../../ui/Header.js";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Row,
  Table,
  Badge,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import IMGetLocationTitle from "../ui/IMGetLocationTitle.js";
import { ApiUrls } from "../../../common/apiconstant.js";
// import styles from "../../styles/showTenant.module.css";
import getToken from "../../../onboarding/getToken.js";
import { ApiEndpoints } from "../../constants/constant.js";
import Loader from "../../../onboarding/components/Loader.js";
import { useMediaQuery } from "@material-ui/core";
import Bottle from "../../../assets/img/bottle/Bottle.png";
import styles from "../../styles/masterProductView.module.css";

const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

const MasterProductView = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [roles, setRoles] = useState([]);
  const isMobile = useMediaQuery("(max-width: 500px)");

  const handleView = () => {
    const viewPath = "/admin/tenant";
    history.push(viewPath);
  };

  let { id } = useParams();
  console.log("IDdsdsdhgj", id);

  const getTenantDetail = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getTenantDetailApi = `${ApiEndpoints.viewMasterDetail}/${id}`;

    fetch(getTenantDetailApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        const responseTenantDetail = res?.data?.data;
        setOriginalData(responseTenantDetail);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };
  useEffect(() => {
    getTenantDetail();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row
                  className="align-items-center"
                  // style={isMobile ? { fontSize: "13px" } : null}
                  // className={styles.header}
                >
                  <Col lg="6" xs="7" className={styles.column}>
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}
                    >
                      {"Master Product Detail"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className={styles.imagePosition}>
                  {originalData[0]?.image_src !== "" ? (
                    <div className={styles.imageCard}>
                      <img
                        src={originalData[0]?.image_src}
                        alt={originalData[0]?.title}
                        // className={`img-center img-fluid shadow shadow-lg--hover ${styles.image}`}
                        className={styles.image}
                        // style={{
                        //   borderRadius: "50%",
                        //   height: 150,
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>
                  ) : (
                    <div className={styles.imageCard}>
                      <img
                        src={Bottle}
                        alt={originalData[0]?.title}
                        // className={`img-center img-fluid shadow shadow-lg--hover ${styles.image}`}
                        className={styles.image}
                        // style={{
                        //   borderRadius: "50%",
                        //   width: 150,
                        //   height: 150,
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>
                  )}
                </div>

                <Table border="1" style={{ borderColor: "#e9ecef" }} responsive>
                  <tbody>
                    <tr>
                      <th>Title</th>
                      <td>
                        {originalData[0]?.full_name?.length > 0
                          ? originalData[0]?.full_name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>
                        {originalData[0]?.body_html?.length > 0
                          ? originalData[0]?.body_html
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Handle</th>
                      <td>
                        {originalData[0]?.handle?.length > 0
                          ? originalData[0]?.handle
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>ID</th>
                      <td>
                        {originalData[0]?.id?.length > 0
                          ? originalData[0]?.id
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Alcohol By Volume</th>
                      <td>
                        {originalData[0]
                          ?.metafield_custom_alcohol_by_volume_single_line_text_field
                          ?.length > 0
                          ? originalData[0]
                              ?.metafield_custom_alcohol_by_volume_single_line_text_field
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Alcoholic</th>
                      <td>
                        {originalData[0]
                          ?.metafield_custom_alcoholic_single_line_text_field
                          ?.length > 0
                          ? originalData[0]
                              ?.metafield_custom_alcoholic_single_line_text_field
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Brand</th>
                      <td>
                        {originalData[0]
                          ?.metafield_custom_brand_single_line_text_field
                          ?.length > 0
                          ? originalData[0]
                              ?.metafield_custom_brand_single_line_text_field
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Category</th>
                      <td>
                        {originalData[0]
                          ?.metafield_custom_category_single_line_text_field
                          ?.length > 0
                          ? originalData[0]
                              ?.metafield_custom_category_single_line_text_field
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Package Type</th>
                      <td>
                        {originalData[0]
                          ?.metafield_custom_package_type_single_line_text_field
                          ?.length > 0
                          ? originalData[0]
                              ?.metafield_custom_package_type_single_line_text_field
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        {originalData[0]?.status?.length > 0
                          ? originalData[0]?.status
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Tags</th>
                      <td>
                        {originalData[0]?.tags?.length > 0
                          ? originalData[0]?.tags
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>
                        {originalData[0]?.type?.length > 0
                          ? originalData[0]?.type
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Variant Barcode</th>
                      <td>
                        {originalData[0]?.variant_barcode?.length > 0
                          ? originalData[0]?.variant_barcode
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Variant Price</th>
                      <td>
                        {originalData[0]?.variant_price?.length > 0
                          ? originalData[0]?.variant_price
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Variant SKU</th>
                      <td>
                        {originalData[0]?.variant_sku?.length > 0
                          ? originalData[0]?.variant_sku
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default MasterProductView;
