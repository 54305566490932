import React, { useDebugValue, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardBody,
  Form,
  FormGroup,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { ArrowDownUp, Search, ArrowClockwise } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ApiEndpoints } from "../../constants/constant";
import { useMediaQuery } from "@material-ui/core";
import FullPageLoader from "../../../components/FullPageLoader";
import Logo from "../../../assets/img/logo/Logo.png";
import Loader from "../../../onboarding/components/Loader";
import SearchBar from "../../components/SearchBar";
import MobileSearchBar from "../../components/SearchBarMobile";
import styles from "../../styles/shopifyProductsList.module.css";
import axios from "axios";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const headerData = [
  {
    header: "S.no",
  },
  {
    header: "Title",
  },
  {
    header: "Message",
  },
  {
    header: "Active",
  },
  {
    header: "target",
  },
  {
    header: "last_updated",
  },
  {
    header: "actions",
  },
];

// const headerData = [
//   {
//     header: "Image",
//   },
//   {
//     header: "Title",
//   },
//   {
//     header: "No Of Variants",
//   },
//   {
//     header: "Status",
//   },
//   {
//     header: "Actions",
//   },
// ];

const dataFcm = [
  {
    campaign: "Year-End Clearance",
    start: "2023-12-26T00:00:00",
    end: "2023-12-31T23:59:59",
    status: "Sent",
    target: "All",
    last_updated: "2023-12-25T18:00:00",
    message:
      "As we bid farewell to the year, enjoy our Year-End Clearance event!",
  },
  {
    campaign: "Spring Sale",
    start: "2023-03-15T10:00:00",
    end: "2023-03-31T23:59:59",
    status: "Sent",
    target: "All",
    last_updated: "2023-03-15T12:30:00",
    message: "Spring has arrived, and so has our special Spring Sale! ",
  },
  {
    campaign: "Holiday Special",
    start: "2023-12-01T08:00:00",
    end: "2023-12-25T23:59:59",
    status: "Sent",
    target: "All",
    last_updated: "2023-11-28T15:45:00",
    message:
      "Our Holiday Special is here to make your celebrations even brighter",
  },
];

const FirebaseNotificationList = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);

  const [roleActions, setRoleActions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [selectedSortOption, setSelectedSortOption] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationList, setAllLocationList] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [statusFilter, setStatusFilter] = useState([]);
  const [clear, setClear] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [notificationData, setNotificationData] = useState([]);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [search, setSearch] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [deleteId, setDeleteId] = useState(null);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const deleteConfirmToggle = () => {
    setDeleteConfirmModal((p) => !p);
  };

  const isMobile = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    let roles_actions = localStorage.getItem("role_actions");
    let user_detail = localStorage.getItem("user_detail");
    if (roles_actions) {
      roles_actions = JSON.parse(roles_actions);
      setRoleActions(roles_actions);
    }
    user_detail = JSON.parse(user_detail);
    setUserRoles(user_detail?.roles);
  }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   let fcmData = localStorage.getItem("fcm_data");
  //   if (fcmData) {
  //     let parsedDAta = JSON.parse(fcmData);
  //     setNotificationData(parsedDAta);
  //   } else {
  //     localStorage.setItem("fcm_data", JSON.stringify(dataFcm));
  //     setNotificationData(dataFcm);
  //   }
  //   console.log("notification len");
  //   setIsLoading(false);
  // }, []);

  const fetchData = async (reqBody, pageNumber) => {
    let url = `${ApiEndpoints.notificationList}?page=${pageNumber}&limit=${10}`;
    // "https://2ftpiulg9a.execute-api.us-east-1.amazonaws.com/v1/get-notification" +
    // `?page=${pageNumber}&limit=${10}&sortOrder=DESC`;

    if (selectedSortOption) {
      url = url + `&sortBy=${selectedSortOption}&sortOrder=${sortDirection}`;
    }

    if (selectedStatus !== "All") {
      let targetType = selectedStatus.toLowerCase();
      url = url + `&target_type=${targetType}`;
    }

    try {
      const res = await axios.post(url, reqBody);
      console.log("res get", res);
      if (res?.status == 200) {
        let response = res?.data?.data;
        //setNotificationData(response);
        // setIsLoading(false);

        if (response.length < 10) {
          setLoadMoreEnabled(false);
        } else {
          setLoadMoreEnabled(true);
        }
        // if (pageNum) {
        //   return response;
        // }
        if (pageNumber > 1) {
          return [...notificationData, ...response];
        } else {
          return response;
        }
      } else {
        setNotificationData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("fcm list error", error);
      setNotificationData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let reqBody = {
      //searchKeyword: "h",
    };
    const getNotification = async () => {
      setIsLoading(true);
      let response = await fetchData(reqBody, page);
      setNotificationData(response);
      setIsLoading(false);
    };
    getNotification();
  }, [page, selectedSortOption, sortDirection, selectedStatus]);

  // useEffect(() => {
  //   setPage(1);
  //   if (selectedSortOption) {
  //     getLocationList(page);
  //   }
  // }, [selectedSortOption, sortDirection, selectedStatus]);

  const handleInputChange = (event) => {
    // const value = event.target.value;
    // //setQuery(value);
    // // Filter locations based on user input
    // const filtered = notificationData?.filter((item) => {
    //   const fullAddress = `${item.campaign} ${item.message}`;
    //   return fullAddress.toLowerCase().includes(value.toLowerCase());
    // });
    // setNotificationData(filtered);
  };

  const handleCancelClick = async () => {
    setIsLoading(true);
    setSearch("");
    // let fcmData = localStorage.getItem("fcm_data");
    // if (fcmData) {
    //   let parsedDAta = JSON.parse(fcmData);
    //   setNotificationData(parsedDAta);
    // }
    //setShowCard(false);
    let reqBody = {};
    let res = await fetchData(reqBody, 1);
    //let response = res?.data?.data;
    setNotificationData(res);
    setShowCard(false);
    setIsLoading(false);
  };

  const SortOptions = [
    { id: "title", text: "Title" },
    { id: "created_at", text: "Date" },

    // { id: "2", text: "2", access: "noOfItems" },
    // { id: "3", text: "3", access: "totalPrice" },
    // { id: "4", text: "4", access:"orderDate" },
  ];

  const handleSortOptionSelect = (sortOption) => {
    setPage(1);
    if (selectedSortOption === sortOption) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSelectedSortOption(sortOption);
      setSortDirection("ASC");
    }
  };

  const handleSearchButtonClick = () => {
    setShowCard(true);
  };

  const handleLoadMore = () => {
    // const nextPage = page + 1;
    // getProductDetails(nextPage);
    if (search.length > 0) {
      fcmSearchBtn(pageNum + 1);
      //setPageNum((p) => p + 1);
    } else {
      setPage((p) => p + 1);
      let pageNum = page + 1;
    }

    // getProductDetails(pageNum);
  };

  //   useEffect(() => {
  //     if (selectedSortOption) {
  //       getProductDetails(page);
  //     }
  //   }, [selectedSortOption, sortDirection, selectedStatus]);

  //   useEffect(() => {
  //     setPage(1);
  //     if (searchKeyword.length >= 2) {
  //       getProductDetails(page);
  //     } else if (searchKeyword === "") {
  //       getProductDetails(page);
  //     }
  //   }, [searchKeyword]);

  const mergeAndSortNotificationList = (mergedNotificationList) => {
    if (selectedSortOption) {
      mergedNotificationList.sort((a, b) => {
        if (sortDirection === "ASC") {
          return a[selectedSortOption] - b[selectedSortOption];
        } else {
          return b[selectedSortOption] - a[selectedSortOption];
        }
      });
    }
    return mergedNotificationList;
  };

  // const selectStatus = (value) => {
  //   value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  //   console.log("value", value);
  //   let status = statusFilter.find((x) => x == value);
  //   let tempStatusFilter = JSON.parse(JSON.stringify(statusFilter));
  //   if (status) {
  //     let index = tempStatusFilter.findIndex((x) => x == value);
  //     tempStatusFilter.splice(index, 1);
  //   } else {
  //     tempStatusFilter.push(value);
  //   }
  //   setStatusFilter(tempStatusFilter);

  //   getProductDetails();
  // };

  const selectStatus = (value) => {
    setPage(1);
    console.log("statusvalue", value);
    if (value == "All") {
      setStatusFilter([]);
      setSelectedStatus("All");
      // getProductDetails(1);
    } else {
      setStatusFilter((prevStatusFilter) => {
        const index = prevStatusFilter.indexOf(value);
        if (index !== -1) {
          const StatusFilter = [...prevStatusFilter];
          StatusFilter.splice(index, 1);
          return StatusFilter;
        } else {
          return [...prevStatusFilter, value];
        }
      });

      // const Status =
      //   value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      setSelectedStatus(value);
    }
  };

  //let btns = ["All", "Active", "Draft", "Archived"];
  let btns = ["All", "Users", "Topic"];

  const handleCreate = () => {
    history.push("/admin/notifications/create");
  };

  const handleEdit = (item) => {
    history.push(`/admin/products/edit/${item.id}`);
  };

  const handleClear = async () => {
    setPage(1);
    setStatusFilter([]);
    setSearchKeyword("");
    setSortDirection("DESC");
    setSelectedSortOption("created_at");
    setClear(true);
  };
  //   useEffect(() => {
  //     if (clear) {
  //       getProductDetails(1);
  //     }
  //   }, [clear]);

  // const handleCancelClick = () => {
  //   setPage(1);
  //   setSearchKeyword("");
  //   setSortDirection("DESC");
  //   setSelectedSortOption("created_at");
  //   setClear(true);
  //   setShowCard(false);
  // };

  const getDetails = () => {};

  async function filterData(searchKeyword, pageNumber) {
    // return notificationData?.filter(
    //   (campaign) =>
    //     campaign.campaign.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    //     campaign.message.toLowerCase().includes(searchKeyword.toLowerCase())
    // );
    let reqBody = {
      searchKeyword: searchKeyword?.toLowerCase(),
    };
    let res = await fetchData(reqBody, pageNumber);
    console.log("filterd", res);
    return res;
  }

  const fcmSearchBtn = async (pageNumber) => {
    // const filtered = notificationData?.filter((item) => {
    //   const fullAddress = `${item.campaign}`;
    //   console.log("keuwo", fullAddress, search);
    //   return fullAddress.toLowerCase().includes(search.toLowerCase());
    // });
    setIsLoading(true);
    const filtered = await filterData(
      search,
      pageNumber ? pageNumber : pageNum
    );
    console.log("keuwo fil", filtered);
    // if (pageNumber) {
    //   setNotificationData((p) => [...p, ...filtered]);
    // } else {
    setNotificationData(filtered);
    // }
    setIsLoading(false);
  };

  console.log("notification data", notificationData);

  const fcmInputSearch = (value) => {
    console.log("Values", value);
    setSearch(value);
  };

  const handleDelete = async (id) => {
    console.log("delete id", id);
    setIsLoading(true);
    let url = ApiEndpoints.deactivateNotification + `/${deleteId}`;
    let reqBody = {};
    try {
      const deactivateRes = await axios.put(url, reqBody);

      console.log("Deactivate res", deactivateRes);
      if (deactivateRes.status == 200) {
        setDeleteConfirmModal(false);
        let response = await fetchData(reqBody, 1);
        setNotificationData(response);

        setIsLoading(false);
      }
    } catch (error) {
      console.log("notification deactivate", error);
      setDeleteConfirmModal(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {}, [search.length]);

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="5">
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Notifications"}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    {(userRoles.includes("1") ||
                      roleActions.includes("notification_create")) && (
                      <Button
                        className={styles.buttonStyle}
                        type="button"
                        size="sm"
                        color="default"
                        onClick={handleCreate}
                      >
                        Add New Notification
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className={styles.productList}>
                {isMobile ? (
                  <MobileSearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={async (e) => {
                      setIsLoading(true);
                      let data = await fetchData({}, e);
                      setNotificationData(data);
                      setIsLoading(false);
                    }}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Searching All Products"
                    dropDown={false}
                    orderList={false}
                  />
                ) : (
                  <SearchBar
                    showCard={showCard}
                    selectStatus={selectStatus}
                    sortDirection={sortDirection}
                    statusFilter={statusFilter}
                    searchKeyword={searchKeyword}
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    selectedStatus={selectedStatus}
                    SortOptions={SortOptions}
                    handleSearchButtonClick={handleSearchButtonClick}
                    setSearchKeyword={setSearchKeyword}
                    getDetail={async (e) => {
                      setIsLoading(true);
                      let data = await fetchData({}, e);
                      setNotificationData(data);
                      setIsLoading(false);
                    }}
                    handleClear={handleClear}
                    handleCancelClick={handleCancelClick}
                    handleSortOptionSelect={handleSortOptionSelect}
                    selectedSortOption={selectedSortOption}
                    btns={btns}
                    placeHolderText="Search"
                    dropDown={false}
                    orderList={false}
                    fcm={true}
                    fcmSearchBtn={fcmSearchBtn}
                    fcmInputSearch={fcmInputSearch}
                    search={search}
                  />
                )}
              </CardBody>
              <div style={{ height: "550px", overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item, index) => (
                        <th key={index} scope="col">
                          {item.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {notificationData && notificationData.length ? (
                      notificationData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.title}</td>
                          <td>{item?.text}</td>
                          <td>{item?.is_active == true ? "Yes" : "No"}</td>
                          <td>{item?.target_type}</td>
                          <td>{moment(item.updated_at).format("MM/DD/YY")}</td>
                          <td>
                            {(userRoles.includes("1") ||
                              roleActions.includes("notification_delete")) && (
                              <>
                                <Button
                                  className="btn-icon-only"
                                  color="danger"
                                  onClick={() => {
                                    setDeleteConfirmModal(true);
                                    // setDeleteUserData(item);
                                    setDeleteId(item.id);
                                  }}
                                  id={"tooltipd" + item?.id}
                                  type="button"
                                  disabled={item.is_active != true}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-ban"></i>
                                  </span>
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"tooltipd" + item?.id}
                                >
                                  {item?.is_active == true
                                    ? "Deactivate"
                                    : "Deactivated"}
                                </UncontrolledTooltip>
                              </>
                            )}

                            <Modal
                              isOpen={deleteConfirmModal}
                              fade={false}
                              toggle={deleteConfirmToggle}
                              size="sm"
                              centered
                              backdrop="static"
                            >
                              <ModalBody>
                                Are you sure you want to deactivate this
                                notification?
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={() => handleDelete()}
                                >
                                  Yes
                                </Button>
                                <Button
                                  color="secondary"
                                  onClick={() => {
                                    deleteConfirmToggle();
                                    setDeleteId(null);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="6">Loading...</td>
                        ) : (
                          <td colSpan="6">No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {/* <div style={{ height: 550, overflowY: "scroll" }}>
                <Table className="align-items-center table-flush">
                  <thead
                    className="thead-light"
                    style={{ position: "sticky", top: 0, zIndex: 100 }}
                  >
                    <tr>
                      {headerData.map((item) => (
                        <th scope="col">{item.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {productData && productData?.length ? (
                      productData?.map((item) => (
                        <tr>
                          <td>
                            {item.images && item.images.length > 0 ? (
                              <img
                                src={item.images[0].src}
                                alt="Image Description"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            ) : (
                              <img
                                src={Logo}
                                alt="Placeholder Image"
                                width="50"
                                height="50"
                                style={{ borderRadius: "25%" }}
                              />
                            )}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.variants.length}</td>
                          <td>
                            {item.status && (
                              <span>
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1).toLowerCase()}
                              </span>
                            )}
                          </td>                         

                          <td>
                            <ButtonGroup>                            
                              {(userRoles.includes("1") ||
                                roleActions.includes("product_update")) && (
                                <>
                                  <Button
                                    className="btn-icon-only "
                                    color="success"
                                    onClick={() =>
                                      history.push(
                                        `/admin/products/edit/${item.id}`,
                                        {
                                          item: item,
                                          permission: "edit",
                                        }
                                      )
                                    }
                                    id={"tooltipe" + item?.id}
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipe" + item?.id}
                                  >
                                    Edit Product
                                  </UncontrolledTooltip>
                                </>
                              )}                              
                            </ButtonGroup>
                           
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {isLoading ? (
                          <td colSpan="8">Loading...</td>
                        ) : (
                          <td>No data found...</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div> */}
              <CardFooter>
                {loadMoreEnabled && (
                  <a
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="Link"
                    onClick={handleLoadMore}
                  >
                    Load More...
                  </a>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Loader isLoading={isLoading} /> */}
    </>
  );
};

export default FirebaseNotificationList;
