import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Label,
  CardHeader,
  Modal,
  ModalBody,
  Table,
  Container,
  CardBody,
  Button,
  Row,
  ModalHeader,
} from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { ApiEndpoints } from "../../constants/constant";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const ApprovedImports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  async function getApprovedImports(locationId) {
    let approvedApi = `${ApiEndpoints.approvedImports}?location_id=${locationId}`;

    let res = await axios.get(approvedApi);
    console.log("RES", res);
  }

  useEffect(() => {
    let location = localStorage.getItem("selected_location_id").split("#")[1];
    getApprovedImports(location);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Approved Imports"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <ExportToExcel finalDataDetail={productData} />

                    <Button
                      className="buttons-copy buttons-html5"
                      color="primary"
                      size="sm"
                      id="copy-tooltip"
                      onClick={() => setModal(true)}
                    >
                      <span>
                        <i class="fas fa-upload"></i> Import
                      </span>
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} sm={4}>
                    {/* <Form>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="1"
                        ></Label>
                        <Col md="8" className="pull-right">
                          {userLocation == null ? (
                            <Input
                              id="exampleFormControlSelect3"
                              type="select"
                              onChange={handleDropdown}
                            >
                              <IMGetLocation setLocationId={setLocationId} />
                            </Input>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Form> */}
                  </Col>
                </Row>
              </CardBody>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>No of Item</th>
                    <th>Name</th>

                    <th>Uploaded At</th>
                    <th>Status</th>
                    <th>Approved</th>
                    <th>File</th>

                    <th>Error File</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {/* {inventoryControls?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.id}</td>
                      <td>{item.no_of_item}</td>
                      <td>{item.name}</td>

                      <td>{item.updated_at}</td>
                      <td>{item.status}</td>
                      <td>{item.approved}</td>
                      <td>
                        {item.uploaded_s3_url !== "" && (
                          <a
                            href={item.uploaded_s3_url}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className="buttons-copy buttons-html5"
                              color="primary"
                              size="sm"
                            >
                              <span>
                                <i class="fas fa-download"></i> Download
                              </span>
                            </Button>
                          </a>
                        )}
                      </td>
                      <td>
                        {item.status === "Error in File" && (
                          <a
                            href={item.error_s3_url}
                            download="MyExampleDoc"
                            target="_blank"
                          >
                            <Button
                              className="buttons-copy buttons-html5"
                              color="primary"
                              size="sm"
                            >
                              <span>
                                <i class="fas fa-download"></i> Download
                              </span>
                            </Button>
                          </a>
                        )}
                      </td>
                      <td>
                        {localStorage.getItem("userRole") === "Admin" && (
                          <>
                            <Button
                              className="btn-icon-only"
                              color="success"
                              type="button"
                              onClick={() =>
                                history.push({
                                  pathname: "/admin/inventory-controls-imports",
                                  state: { item: item, permission: "edit" },
                                })
                              }
                              id={"tooltipv" + "edit"}
                            >
                              <span className="btn-inner--icon">
                                <i className="fa fa-edit"></i>
                              </span>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipv" + "edit"}
                            >
                              Edit Location
                            </UncontrolledTooltip>
                          </>
                        )}
                        <ButtonGroup>
                          <Button
                            className="btn-icon-only"
                            color="info"
                            type="button"
                            onClick={() =>
                              history.push({
                                pathname: "/admin/inventory-controls-imports",
                                state: { item: item, permission: "view" },
                              })
                            }
                            id={"tooltipv" + "view"}
                          >
                            <span className="btn-inner--icon">
                              <i className="fa fa-eye"></i>
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltipv" + "view"}
                          >
                            View Import
                          </UncontrolledTooltip>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ApprovedImports;
