import React from "react";
import { useState } from "react";
import "./style.css";

const CollectionTable = () => {
  const [collections, setCollections] = useState([
    { id: 1, name: "", isPublic: true },
    { id: 2, name: "", isPublic: false },
    { id: 3, name: "", isPublic: true },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckbox = (id) => {
    setCollections((prevCollections) =>
      prevCollections.map((collection) => {
        if (collection.id === id) {
          return { ...collection, isPublic: !collection.isPublic };
        }
        return collection;
      })
    );
  };

  const filteredCollections = collections.filter((collection) =>
    collection.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event, id) => {
    const { value } = event.target;
    setCollections((prevCollections) =>
      prevCollections.map((collection) => {
        if (collection.id === id) {
          return { ...collection, name: value };
        }
        return collection;
      })
    );
  };

  return (
    <>
      <div>
        <h2>Manage collections</h2>
        <br />
        <input type="text" placeholder="Search" onChange={handleSearch} />
        <br />
        <table>
          <thead>
            <tr>
              <th>My list</th>
              <th>Collection name</th>
              <th>Public</th>
            </tr>
          </thead>
          <tbody>
            {filteredCollections.map((collection) => (
              <tr key={collection.id}>
                <td>{collection.id}</td>
                <td>
                  <input
                    type="text"
                    value={collection.name}
                    onChange={(event) => handleChange(event, collection.id)}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={collection.isPublic}
                    onChange={() => handleCheckbox(collection.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CollectionTable;
