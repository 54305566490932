import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppView from "./AppView";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppView />
        <ToastContainer />
      </Router>
    </Provider>
  );
}

export default App;
