import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import "./style.css";
// core components
import AuthHeader from "../components/headers/AuthHeader.js";
import { loginUser } from "../../app/redux/auth/actions";
import { GET_ERRORS } from "../../app/redux/auth/types";
import FullPageLoader from "../../components/FullPageLoader";
import GoogleSignInButton from "./googleLogin.js";
import AppleLoginButton from "./appleLogin.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Login() {
  const classes = useStyles();
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  const login = async (data, setSubmitting) => {
    console.log(setSubmitting);
    setFormData(data);
    setIsLoading(true);
    setIsSubmitting(true);
    dispatch({ type: GET_ERRORS, payload: {} });
    dispatch(loginUser(data, history, dispatch));
    setSubmitting(false);
    setIsLoading(false);
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <AuthHeader
        title="Welcome!"
        lead="Use these awesome forms to login or create new account in your project for free."
      />
      <Container className="pb-8">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card
              className="bg-secondary border-0 mb-0"
              style={{ borderRadius: "0px" }}
            >
              <CardBody className="px-lg-5 py-lg-5 pb-8">
                <p className="ErrorMessage mt-2">
                  {auth && auth.errors && auth.errors.server}
                </p>
                <div className="text-center text-muted mb-4"></div>
                <Formik
                  initialValues={formData}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }
                    if (!values.password) {
                      errors.password = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setIsSubmitting(true);
                    login(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form>
                      <div className="FormFieldContainer">
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="Email"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            name="email"
                          />
                        </InputGroup>
                        <p className="ErrorMessage">
                          {errors.email && touched.email && errors.email}
                          {auth && auth.errors && auth.errors.email}
                        </p>
                      </div>

                      <div className="FormFieldContainer">
                        {/* <label className="FormLabel">Password</label> */}

                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="FormTextField"
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                        </InputGroup>

                        <p className="ErrorMessage">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                          {auth && auth.errors && auth.errors.password}
                        </p>
                      </div>
                      <a
                        className="text-primary"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/forgot-password");
                        }}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <small className=" font-weight-bold">
                          Forgot password?
                        </small>
                      </a>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="info"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Log in
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: "10px",
                  }}
                >
                  <hr
                    style={{
                      flex: "1",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <span>or</span>
                  <hr
                    style={{
                      flex: "1",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: "20px",
                  }}
                >
                  <GoogleSignInButton />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <AppleLoginButton />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Login;
