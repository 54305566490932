import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

function AuthHeader({ title, lead }) {
  return (
    <>
      <div className="header">
        <Container>
          <div className="header-body text-center">
            <Row className="justify-content-center">
              <Col className="" lg="6" md="8" xl="5">
              <div className="mt-6">
                <img
                  alt="..."
                  className="rounded-circle border-secondary"
                  src={require("../../../assets/img/brand/logo.png")}
                />
              </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">

        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
