import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, isEmptyArray } from "formik";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { isEmpty } from "lodash";
import { getToken } from "../../../onboarding";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import Loader from "../../../onboarding/components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { logUserMetrics } from "../../../utils/file";
var resdata = require("../response.json");

function EditOrderAdmin(props) {
  const history = useHistory();
  const [originalData, setOriginalData] = useState({});
  const [productData, setProductData] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState({});
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");
  const [dataItem, setDataItem] = useState();
  let { orderID } = useParams();
  console.log("SDFSF", orderID);
  const handlePage = () => {
    const viewPath = "/admin/inventory-update";
    history.push(viewPath);
  };

  useEffect(() => {
    let roles = localStorage.getItem("role_actions");
    if (roles) {
      let allRoles = JSON.parse(roles);
      if (!allRoles.includes("inventory_admin_manage")) {
        history.push("/admin/inventory-update");
      }
    }
  }, []);
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    const token = getToken();
    fetch(`${ApiEndpoints.productSearch}`, {
      method: "POST",
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search_keyword: string,
        locationId: localStorage.getItem("selected_location_id"),
        sort_by: "vendor",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        const products = [];
        if (res.data.length > 0) {
          res.data.map((product) =>
            products.push({
              id: product.id,
              price: product.price,
              name: product.title,
              quantity: 1,
              total_price: product.price,
            })
          );

          setItems(products);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  };

  const handleOnSelect = (item) => {
    // the item selected
    const exists = productData.find((p) => p.id === item.id);
    if (exists) {
      alert("Item already selected.");
      return;
    }
    setProductData([...productData, item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  const handleChangeQnantity = (key, price, quantity) => {
    if (quantity.trim() == "" || quantity <= 0) {
      productData[key].quantity = 1;
      productData[key].total_price = price;
      setProductData([...productData]);
    } else {
      productData[key].quantity = quantity;
      productData[key].total_price = quantity * price;
      setProductData([...productData]);
    }
  };

  const removeItem = (key) => {
    if (window.confirm("Are you want to sure to remove this item ?")) {
      productData.splice(key, 1);
      setProductData([...productData]);
    }
  };

  const edit = async (data, setSubmitting) => {};

  const getOrder = (orderID) => {
    setIsLoading(true);
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    fetch(`${ApiEndpoints.transactionAdminApi}/${orderID}`, config)
      .then((response) => response.json())
      .then((data) => {
        console.log("DATA VIEW", data);
        setOrderData(data.data);
        // const index = data.message.draft_orders.findIndex(
        //   (x) => x.id == orderID
        // );
        // let a = data.message.draft_orders.splice(index, 1);
        // const editForm = {
        //   company_name: a[0].shipping_address?.company,
        //   transaction_id: a[0].id,
        //   created_at: a[0].created_at,
        //   first_name: a[0].customer?.first_name,
        //   last_name: a[0].customer?.last_name,
        //   email: a[0].customer?.email,
        //   phone: a[0].customer?.phone,
        // };
        // setOriginalData(editForm);
        // const orderItems = [];

        // a[0].line_items.map((ites) =>
        //   orderItems.push({
        //     id: ites.id,
        //     price: ites.price,
        //     name: ites.title,
        //     quantity: ites.quantity,
        //     total_price: ites.quantity * ites.price,
        //   })
        // );
        //setProductData(orderItems);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getOrder(orderID);
  }, []);

  const updateAprrovalStatus = (value) => {
    setApprovalStatus(value);
    if (value === "approve") {
      setConfirmModal(true);
    } else if (value === "reject") {
      setModal(true);
    }
  };

  const hanldeUpdateApproval = async () => {
    if (approvalStatus === "approve") {
      console.log("Approved api call");
      let approvalApi = `${ApiEndpoints.transactionAdminApi}`;
      let body = {
        id: orderData.id,
        approved: "Approved",
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          toast.success("Approved successful");
          setConfirmModal(false);
          setIsLoading(false);
          setOrderData({ ...orderData, is_approved: "Approved" });
          let action = "price-qty-transaction"
          let type = "Info";
          let message = "Price Qty Transaction completed successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        let action = "price-qty-transaction"
          let type = "Error";
          let message = "Error in Price Qty Transaction.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
      }
    } else if (approvalStatus === "reject") {
      console.log("Reject api call");
      let approvalApi = `${ApiEndpoints.transactionAdminApi}`;
      let body = {
        id: orderData.id,
        approved: "Rejected",
        reason: reason,
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          toast.success("Rejected successful");
          setModal(false);
          setReasonModalCheck(false);
          setIsLoading(false);
          setOrderData({
            ...orderData,
            is_approved: "Rejected",
            reason: reason,
          });
          setReason("");
        } else {
          setIsLoading(false);
        }
        let action = "price-qty-transaction"
        let type = "Info";
        let message = "Price Qty Transaction rejected successfully.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      } catch (error) {
        console.log(error);
        let action = "price-qty-transaction"
        let type = "Error";
        let message = "Error in Price Qty Transaction reject.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    }
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   );
  // }
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Transaction"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={originalData}
                  validate={(values) => {
                    values = { ...values, ...productData };
                    const errors = {};
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    edit(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md="4" sm="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example1cols1Input"
                            >
                              Transaction Id
                            </label>
                            {/* <Input
                              id="example1cols1Input"
                              placeholder="Company Name"
                              type="CompanyName"
                              name="company_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.company_name}
                            /> */}
                            <div>{orderData?.id}</div>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                            >
                              Username
                            </label>
                            {/* <Input
                              id="example2cols1Input"
                              placeholder="Firstname"
                              type="Firstname"
                              name="first_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_name}
                            /> */}
                            <div>{orderData?.name}</div>
                          </FormGroup>
                        </Col>
                        <Col md="4" sm="4">
                          <label
                            className="form-control-label"
                            htmlFor="example1cols1Input"
                          >
                            Approval Status
                          </label>
                          {orderData?.is_approved !== "Approved" &&
                          orderData?.is_approved !== "Rejected" ? (
                            <FormGroup>
                              <Input
                                id="exampleFormControlSelect3"
                                type="select"
                                onChange={(e) => {
                                  updateAprrovalStatus(e.target.value);
                                }}
                              >
                                <option value="">Pending for Approval</option>
                                <option value="approve">Approve</option>
                                <option value="reject">Reject</option>
                              </Input>
                            </FormGroup>
                          ) : (
                            <>
                              <div>{orderData?.is_approved}</div>

                              {/* {orderData?.is_approved === "Rejected" && (
                                <>
                                  <label
                                    className="form-control-label"
                                    htmlFor="example1cols1Input"
                                  >
                                    Reason
                                  </label>
                                  <p>{orderData?.reason}</p>
                                </>
                              )} */}
                            </>
                          )}
                        </Col>
                        <Modal
                          isOpen={modal}
                          fade={false}
                          toggle={modalToggle}
                          backdrop="static"
                          centered
                        >
                          <ModalHeader toggle={modalToggle}>
                            Reason For Rejection
                          </ModalHeader>
                          <ModalBody>
                            {/* <Input
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option value={""}>Select</option>
                            {dispatcherStatus?.map((status) => (
                              <option
                                value={status}
                                selected={
                                  status === orderStatus ? "selected" : ""
                                }
                              >
                                {status}
                              </option>
                            ))}
                          </Input>
                          <br /> */}
                            <label>Reason</label>
                            <Input
                              type="text"
                              name="reason"
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={() => reasonModalToggle()}
                              disabled={!reason ? true : false}
                            >
                              Update
                            </Button>
                            <Button color="secondary" onClick={modalToggle}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                        <Modal
                          isOpen={confirmModal}
                          fade={false}
                          toggle={confirmModalToggle}
                          size="sm"
                          centered
                          backdrop="static"
                        >
                          <ModalBody>
                            Are you sure you want to approve?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={() => hanldeUpdateApproval()}
                            >
                              Yes
                            </Button>
                            <Button
                              color="secondary"
                              onClick={confirmModalToggle}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                        <Modal
                          isOpen={reasonModalCheck}
                          fade={false}
                          toggle={reasonModalToggle}
                          size="sm"
                          centered
                          backdrop="static"
                        >
                          <ModalBody>
                            Are you sure you want to reject?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={() => hanldeUpdateApproval()}
                            >
                              Yes
                            </Button>
                            <Button
                              color="secondary"
                              onClick={reasonModalToggle}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Row>
                      <Row>
                        <Col md="8" sm="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              Date
                            </label>
                            {/* <Input
                              id="example3cols1Input"
                              placeholder="Lastname"
                              type="Lastname"
                              name="last_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.last_name}
                            /> */}
                            <div>
                              {" "}
                              {moment(orderData?.created_at).format("MM/DD/YY")}
                            </div>
                          </FormGroup>
                        </Col>
                        {orderData?.is_approved === "Rejected" && (
                          <Col md="4" sm="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="example3cols1Input"
                              >
                                Reason
                              </label>

                              <div>{orderData?.reason}</div>
                            </FormGroup>
                          </Col>
                        )}
                        {/* <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example4cols1Input"
                            >
                              Email
                            </label>
                            <Input
                              id="example4cols1Input"
                              placeholder="Email"
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                          </FormGroup>
                        </Col> */}
                      </Row>

                      {/* <Row>
                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example11cols2Input"
                            >
                              Phone
                            </label>
                            <Input
                              id="example11cols2Input"
                              placeholder="Phone"
                              type="phone"
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6" sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlTextarea2"
                            >
                              Address
                            </label>
                            <Input
                              id="exampleFormControlTextarea2"
                              rows="3"
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      {/* <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example12cols2Input"
                            >
                              Select Product
                            </label>
                            <ReactSearchAutocomplete
                              items={items}
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              onFocus={handleOnFocus}
                              autoFocus
                              formatResult={formatResult}
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <Row>
                        <Table className="table" responsive>
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Product Name</th>
                              {/* <th>Sku</th> */}
                              <th>Inventory Item Id</th>
                              <th>UPC</th>
                              <th>Shopify Location Id</th>
                              <th>Old Price</th>
                              <th>New Price</th>
                              <th>Old Stock</th>
                              <th>Adjustments</th>
                              <th>Error</th>
                              {/* <th>Total Price</th> */}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderData?.items?.map((product, key) => (
                              <tr key={key} id={key}>
                                <td>{key + 1}</td>
                                <td>{product.prod_name}</td>
                                {/* <td>{product.id}</td> */}
                                <td>{product.inventory_item_id}</td>
                                <td>{product.bar_code}</td>
                                <td>{product.shopify_location_id}</td>
                                <td>{product.old_price}</td>
                                <td>{product.price_adjustment}</td>
                                <td>{product.old_qty}</td>
                                <td>
                                  <div>{product.available_adjustment}</div>
                                </td>

                                <td>{product?.error}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loader isLoading={isLoading} />
    </>
  );
}
export default EditOrderAdmin;
