import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FullPageLoader from "../../../components/FullPageLoader";
import { ApiEndpoints } from "../../constants/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import styles from "../../styles/importEdit.module.css";
import { logUserMetrics } from "../../../utils/file";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let productArray = [];

const ImportTransactionListView = () => {
  //const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { item, permission } = location?.state;
  //console.log("id", id);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reasonModalCheck, setReasonModalCheck] = useState(false);
  const confirmModalToggle = () => setConfirmModal(!confirmModal);
  const reasonModalToggle = () => setReasonModalCheck(!reasonModalCheck);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reason, setReason] = useState("");
  const [exportProducts, setExportProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [errorUPCs, setErrorUPCs] = useState([
    "8248646746460",
    "87000201781",
    "88076179080",
    "80480301026",
    "88110955342",
  ]);

  const [dataItem, setDataItem] = useState(item);

  console.log("ITEM", item);

  const { id } = useParams();

  console.log("ididididi", id);

  async function getImportAsJson() {
    try {
      setIsLoading(true);
      let importApi = `${ApiEndpoints.jsonMenuDataImport}?transaction_id=${id}`;
      let res = await axios.get(importApi);
      console.log("TES", res);
      let response = JSON.parse(JSON.stringify(res.data.data));
      console.log("response4564646", response);
      setData(response);
      productArray = response;
      setExportProducts(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  console.log("expoertgayusgdyusa", exportProducts);

  useEffect(() => {
    if (Object.keys(dataItem).length > 0) {
      getImportAsJson();
    } else {
      history.push("/admin/menu-import");
    }
  }, []);

  console.log("DATA", data);

  const updateAprrovalStatus = (value) => {
    setApprovalStatus(value);
    if (value === "approve") {
      setConfirmModal(true);
    } else if (value === "reject") {
      setModal(true);
    }
  };

  const hanldeUpdateApproval = async () => {
    if (approvalStatus === "approve") {
      console.log("Approved api call");
      let approvalApi = `${ApiEndpoints.aaprovedMenuImports}`;
      let body = {
        id: dataItem.id,
        approved: "Approved",
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          setConfirmModal(false);
          setIsLoading(false);
          setDataItem({ ...dataItem, approved: "Approved" });
          toast.success("Transaction is successfully approved");
          let action = "menu-transaction";
          let type = "Info";
          let message = "Menu Transaction approved successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
          toast.error("Error in transaction approval");
          let action = "menu-transaction";
          let type = "Error";
          let message = "Error in Menu Transaction approval.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (approvalStatus === "reject") {
      console.log("Reject api call");
      let approvalApi = `${ApiEndpoints.aaprovedMenuImports}`;
      let body = {
        id: dataItem.id,
        approved: "Rejected",
        reason: reason,
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
      };
      try {
        setIsLoading(true);
        let res = await axios.put(approvalApi, body, { headers: headers });
        console.log("RESPONSE", res);
        if (res.status === 200) {
          setModal(false);
          setReasonModalCheck(false);
          setIsLoading(false);
          setDataItem({
            ...dataItem,
            approved: "Rejected",
            reason: reason,
          });
          setReason("");
          toast.success("Transaction is successfully rejected");
          let action = "menu-transaction";
          let type = "Info";
          let message = "Menu Transaction rejected successfully.";
          const apiResponse = await logUserMetrics(action, type, message);
          console.log("apiirresponse", apiResponse);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error in transaction approval");
        let action = "menu-transaction";
        let type = "Error";
        let message = "Error in Menu Transaction reject.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    }
  };

  // const dropdownArr = ["Pending For Approval", "Approve", "Reject"];
  // const [dropdown, setDropdown] = useState(dropdownArr);

  // const selectedValue = (value) => {
  //   let index = dropdownArr.findIndex((item) => item === value);
  //   dropdownArr.splice(index, 1);
  //   dropdownArr.unshift(value);
  //   setDropdown(dropdownArr);
  // };

  const handleSearch = (arr, str) => {
    console.log("ARRR", arr);
    let res = arr.filter(
      (item) =>
        (item &&
          item["upc"] &&
          item["upc"].toLowerCase().includes(str.toLowerCase())) ||
        item["name"].toLowerCase().includes(str.toLowerCase())
    );
    console.log("RESULT", res);
    return res;
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchText(string);
    console.log("STRING", string, results);

    let searchRes = handleSearch(productArray, string);
    console.log("SEra", searchRes);
    let products = [];

    for (let i = 0; i < searchRes.length; i++) {
      products.push({
        sku: searchRes[i]["sku"],
        upc: searchRes[i]["upc"],
        price: searchRes[i]["price"],
        name: searchRes[i]["name"],
        ls_qty: searchRes[i]["ls_qty"],
        status: searchRes[i]["status"],
      });
    }

    setData(products);

    var stringLength = string.length;
    if (stringLength <= 0) {
      let products = [];
      for (let i = 0; i < exportProducts.length; i++) {
        products.push({
          sku: exportProducts[i]["sku"],
          upc: exportProducts[i]["upc"],
          price: exportProducts[i]["price"],
          name: exportProducts[i]["name"],
          ls_qty: exportProducts[i]["ls_qty"],
          status: exportProducts[i]["status"],
        });
      }
      setData(products);
    }
  };

  function onClear() {
    let products = [];
    for (let i = 0; i < exportProducts.length; i++) {
      products.push({
        sku: exportProducts[i]["sku"],
        upc: exportProducts[i]["upc"],
        price: exportProducts[i]["price"],
        name: exportProducts[i]["name"],
        ls_qty: exportProducts[i]["ls_qty"],
        status: exportProducts[i]["status"],
      });
    }
    console.log("asdsad4as84", products);
    setData(products);
  }

  useEffect(() => {
    if (searchText.length === 0) {
      onClear();
    }
  }, [searchText.length]);

  const handleOnHover = (result) => {
    // the item hovered
    //console.log(result)
  };

  const handleOnSelect = (item) => {
    const exists = data.find((p) => p.id === item.id);
    if (exists) {
      alert("Item already selected.");
      return;
    }
    setData([...data, item]);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  // if (isLoading) {
  //   return <FullPageLoader />;
  // }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="">
                <Row className="align-items-center">
                  <Col lg="6" xs="7">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer", marginRight: 5 }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6 className="fullcalendar-title h2 d-inline-block mb-0">
                      {"Imports"}
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported By
                      </label>
                      <div>{dataItem?.name}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Status
                      </label>
                      <div>{dataItem?.status}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example1cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Approval Status
                      </label>
                      <div>{dataItem?.approved}</div>
                    </FormGroup>
                  </Col>
                  <Modal
                    isOpen={modal}
                    fade={false}
                    toggle={modalToggle}
                    backdrop="static"
                    centered
                  >
                    <ModalHeader toggle={modalToggle}>
                      Reason For Rejection
                    </ModalHeader>
                    <ModalBody>
                      <label>Reason</label>
                      <Input
                        type="text"
                        name="reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => reasonModalToggle()}
                        disabled={!reason ? true : false}
                      >
                        Update
                      </Button>
                      <Button color="secondary" onClick={modalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={confirmModal}
                    fade={false}
                    toggle={confirmModalToggle}
                    size="sm"
                    centered
                    backdrop="static"
                  >
                    <ModalBody>Are you sure you want to approve?</ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => hanldeUpdateApproval()}
                      >
                        Yes
                      </Button>
                      <Button color="secondary" onClick={confirmModalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={reasonModalCheck}
                    fade={false}
                    toggle={reasonModalToggle}
                    size="sm"
                    centered
                    backdrop="static"
                  >
                    <ModalBody>Are you sure you want to reject?</ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() => hanldeUpdateApproval()}
                      >
                        Yes
                      </Button>
                      <Button color="secondary" onClick={reasonModalToggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* </Row>
                <Row> */}
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Imported On
                      </label>
                      <div>
                        {moment(dataItem?.created_at).format("MM/DD/YYYY")}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        Transaction Id
                      </label>
                      <div>{dataItem?.id}</div>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                        style={{ fontWeight: 700 }}
                      >
                        File Name
                      </label>
                      <div>
                        {dataItem?.file_name &&
                          dataItem.file_name.split(".xlsx")[0]}
                      </div>
                    </FormGroup>
                  </Col>
                  {dataItem?.reason ? (
                    <Col md="4" sm="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example1cols1Input"
                          style={{ fontWeight: 700 }}
                        >
                          Reason
                        </label>
                        <div>{dataItem?.reason}</div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {/* </Row>
                <Row> */}
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup className={styles.searchBar}>
                      <label
                        className="form-control-label"
                        htmlFor="example12cols2Input"
                        style={{ fontWeight: 700 }}
                      >
                        Search
                      </label>
                      <ReactSearchAutocomplete
                        items={data}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        placeholder="Search by Name or UPC"
                        autoFocus
                        maxResults={0}
                        showIcon={true}
                        onClear={onClear}
                        styling={{ marginTop: "0px" }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Table
                    className="table"
                    bordered
                    hover
                    responsive
                    style={{ border: "1px solid #e9ecef" }}
                  >
                    <thead>
                      <tr>
                        <th>Sku</th>
                        <th>UPC</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        {((dataItem?.approved === "Approved" &&
                          dataItem?.status === "Partially Completed") ||
                          dataItem?.status === "Completed" ||
                          dataItem?.status === "Processing") && (
                          <th>Mapped Status</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {data.map((item, key) => (
                        <tr key={key} id={key}>
                          <td>{item?.sku}</td>
                          <td>{item?.upc}</td>
                          <td>{item?.name}</td>
                          <td>{item?.ls_qty}</td>
                          <td>{item?.price}</td>
                          {((dataItem?.approved === "Approved" &&
                            dataItem?.status === "Partially Completed") ||
                            dataItem?.status === "Completed" ||
                            dataItem?.status === "Processing") && (
                            <td>
                              {item?.mapped_status?.length > 0
                                ? item?.mapped_status
                                : "No match Found"}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ImportTransactionListView;
