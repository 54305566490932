import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  Button,
  Input,
  FormGroup,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import SimpleHeader from "../../../components/headers/simpleHeader";
import Header from "../../ui/Header.js";
import { withRouter, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { ApiEndpoints } from "../../constants/constant";
import FullPageLoader from "../../../components/FullPageLoader";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const AssignDriverToOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [driverList, setDriverList] = useState([]);
  const [driverId, setDriverId] = useState("");
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  const assignDriverApi = async (values, setSubmitting) => {
    setIsLoading(true);
    try {
      await fetch(`${ApiEndpoints.assignDriver}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: props?.location?.state?.originalData?.pk?.split("#")[1],
          locationId: props?.location?.state?.originalData?.locationId,
          driver_id: driverId,
        }),
      });

      history.push({
        pathname:
          "/admin/order/" + props?.location?.state?.originalData?.id + "/view",
        state: { item: props?.location?.state?.originalData },
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onlyOne = (driverId) => {
    setDriverId(driverId);
  };

  useEffect(() => {
    fetch(ApiEndpoints.driverList)
      .then((response) => response.json())
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
      .then((data) => {
        setDriverList(data?.data);
        setIsLoading(false);
      });
  }, []);

  // if (isLoading) {
  //   return (
  //     <>
  //       <FullPageLoader />
  //     </>
  //   )
  // }

  return (
    <>
      <Header name="Home" parentName="Dispatch / Assign Driver" />
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="Card FormCard">
              <span
                className="back-btn"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.goBack()}
              >
                <i class="fa fa-arrow-left " aria-hidden="true"></i>
              </span>
              <CardBody>
                <h1 className="mb-6">Assign Driver</h1>
                <Table responsive hover size="md">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Driver Name</th>
                      <th>Contact No.</th>
                      <th>Vehicle Type</th>
                      <th>Vehicle Description</th>
                      <th>Vehicle License</th>
                    </tr>
                  </thead>
                  <tbody>
                    {driverList?.map((driver) => (
                      <tr>
                        <td>
                          <input
                            id="customCheck1"
                            type="checkbox"
                            name="driverId"
                            checked={driverId === driver.userId}
                            onClick={() => onlyOne(driver.userId)}
                          />
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            props.history.push({
                              pathname: "/admin/driver-profile",
                            });
                          }}
                        >
                          {driver.firstName + " " + driver.lastName}
                        </td>
                        <td>{driver.phoneNo}</td>
                        <td>{driver.vehicleType}</td>
                        <td>{driver.vehicleDescription}</td>
                        <td>{driver.vehicleLicense}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  color="primary"
                  className="mt-5"
                  type="submit"
                  disabled={driverId.length == 0 && driverId == ""}
                  onClick={() => modalToggle()}
                >
                  Assign Driver
                </Button>
              </CardBody>
            </Card>
            <Modal
              isOpen={modal}
              fade={false}
              toggle={modalToggle}
              size="sm"
              centered
              backdrop="static"
            >
              {/* <ModalHeader toggle={modalToggle}>Edit Order Status</ModalHeader> */}
              <ModalBody>Are you sure you want to assign driver?</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => assignDriverApi()}>
                  Yes
                </Button>{" "}
                <Button color="secondary" onClick={modalToggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Row>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
    </>
  );
};

export default withRouter(AssignDriverToOrder);
