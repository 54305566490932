import React, { useState, useEffect } from "react";
import Header from "../../ui/Header.js";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { logUserMetrics } from "../../../utils/file.js";
import {
  Form,
  FormGroup,
  UncontrolledAlert,
  Input,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Button,
  Col,
  CardBody,
  Row,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { ApiUrls } from "../../../common/apiconstant";
import axios from "axios";
import { ApiEndpoints } from "../../constants/constant.js";
import getToken from "../../../onboarding/getToken.js";
import FullPageLoader from "../../../components/FullPageLoader/index.js";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import Logo from "../../../assets/img/logo/Logo.png";
const profileImgUUid = uuidv4();
const baseAWSAPIURL = require("../../config").baseAWSAPIURL;

let locationOptions = [];
let roleOptions = [];

function EmployeesProfile() {
  const history = useHistory();
  const [modifiedNonFormData, setModifiedNonFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [locations, setLocations] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [avatarKey, setAvatarKey] = useState("2024-01-15T16:19:32.998Z");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const handleSelectRole = () => {};

  const handleChangeImage = async (e, fieldName) => {
    let toastId = toast.info("Avatar uploading is in processing", {
      autoClose: false,
    });
    var newData = { ...modifiedNonFormData };
    setButtonDisabled(true);
    if (e.target.files.length) {
      let selectedFile = e.target.files[0];
      let fileType = e.target.files[0].type;
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });

      let preSingnedUrl = ApiUrls.avatarUploadPresigned;

      try {
        let preSignedRes = await axios.get(
          `${preSingnedUrl}?contentType=${fileType}`
        );
        console.log("D", preSignedRes);
        if (preSignedRes.status === 200) {
          let url = preSignedRes?.data?.data?.url;
          let ukey = preSignedRes?.data?.data?.key;
          let res = await uploadFile(url, selectedFile);
          console.log("RES1", res);
          console.log("ULR", url, ukey);
          if (res.status === 200) {
            setButtonDisabled(false);
            setAvatarKey(ukey);
            newData[fieldName] = ukey;
            setModifiedNonFormData(newData);
            console.log("MODI", modifiedNonFormData);
            console.log("DATa", newData);
            toast.dismiss(toastId);
            toast.success("Image upload successfully");
          }
        }
      } catch (error) {
        setButtonDisabled(false);
        console.error(error);
      }
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  const uploadFile = (url, data) => {
    console.log("UPLOAD");
    let promise = new Promise((resolve, reject) => {
      "in xhr";
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      //xhr.responseType = "json";
      //xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = () => {
        if (xhr.status >= 400) {
          console.log("REJECTED");
          reject({ status: 400, data: xhr.response });
        } else {
          console.log("RESOLVED");
          resolve({ status: 200, data: xhr.response });
        }
      };

      xhr.onerror = (e) => {
        console.log("RJECTED");
        reject({
          status: 400,
          data: xhr.response,
          msg: "Something went wrong",
        });
      };

      xhr.send(data);
    });

    return promise;
  };

  useEffect(() => {
    fetch(`${ApiUrls.locationList}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("LOC RED", response);
        setLocations(response.data.Items);
        const locations = response?.data?.Items;

        locationOptions = [];

        locations.map((element) => {
          locationOptions.push({
            label: element?.title,
            value: element?.pk,
          });
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(`${ApiUrls.timeZones}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);
        setTimeZones(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);

  const handleView = () => {
    const viewPath = "/admin/tenant";
    history.push(viewPath);
  };

  const createProfile = async (data, setSubmitting) => {
    console.log("DATA", data);
    setIsLoading(true);

    let reqBody = {
      business_name: data.businessname,
      business_email: data.businessemail,
      business_phone: data.businessphone,
      contact_person_firstname: data.contactpersonfirstname,
      contact_person_lastname: data.contactpersonlastname,
      contact_person_email: data.contactpersonemail,
      contact_person_phone: data.contactpersonphone,
      billing_address1: data.billing_address1,
      billing_address2: data.billing_address2,
      billing_city: data.billing_city,
      billing_state: data.billing_state,
      billing_zip_code: data.billing_zip_code,
      type: "Tenant",
      updated_by: localStorage.getItem("fullname"),
    };

    if (data.ischecked) {
      reqBody.firstname = data.contactpersonfirstname;
      reqBody.lastname = data.contactpersonlastname;
      reqBody.email = data.contactpersonemail;
      reqBody.phone = data.contactpersonphone;
      reqBody.timezone = "PDT";
      //reqBody.is_verified = true;
    } else {
      reqBody.firstname = data.firstname;
      reqBody.lastname = data.lastname;
      reqBody.email = data.email;
      reqBody.phone = data.phone;
      reqBody.timezone = data.timezone;
      //reqBody.is_verified = data.is_verified;
    }

    if (data.is_verified === "yes") {
      reqBody.is_verified = true;
    } else {
      reqBody.is_verified = false;
    }
    if (avatarKey) {
      reqBody.logo = avatarKey;
    }

    try {
      let res = await axios.post(ApiEndpoints.tenantApi, reqBody);
      console.log("RESPONSE", res);
      if (res.status === 200) {
        toast.success("Tenant Created Successfully");
        history.push("/admin/tenant");
        setIsLoading(false);
        let action = "tenant-create";
        let type = "Info";
        let message = "Tenant successfully created.";
        const apiResponse = await logUserMetrics(action, type, message);
        console.log("apiirresponse", apiResponse);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      let action = "tenant-create";
      let type = "Error";
      let message = "Error in Tenant create.";
      const apiResponse = await logUserMetrics(action, type, message);
      console.log("apiirresponse", apiResponse);
    }
  };

  const handleImageUpload = (event, fieldName) => {
    let selectedfiles = "";
    var newData = { ...modifiedNonFormData };
    const imageFile = event.target.files;
    setButtonDisabled(true);
    if (imageFile.length > 0) {
      selectedfiles = imageFile[0];

      const imagesKays = '[{ "key": "' + fieldName + profileImgUUid + '"}]';

      fetch(`${ApiUrls.imageUpload}`, {
        method: "POST",
        body: imagesKays,
      })
        .then((response) => response.json())
        .then((response) => {
          //console.log(response);
          const presignedUrl = response[0].presignedUrl;
          console.log(presignedUrl);

          fetch(presignedUrl, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            body: selectedfiles,
          })
            .then((res) => {
              setButtonDisabled(false);
              newData[fieldName] = fieldName + profileImgUUid;
              setModifiedNonFormData(newData);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setButtonDisabled(false);
          console.error(error);
        });
    } else {
      setButtonDisabled(false);
      newData[fieldName] = false;
      setModifiedNonFormData(newData);
    }
  };

  const getShowRole = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };

    setIsLoading(true);
    let getPermissionApi = `${ApiEndpoints.roleGetURL}?limit=500&offset=0`;

    fetch(getPermissionApi, config)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        const permissionGet = res?.data;
        console.log("ReSpinse 59999999", res);
        console.log("permissionGetpermissionGet,==>", permissionGet);
        roleOptions = [];
        permissionGet.map((elm) => {
          roleOptions.push({
            label: elm?.role,
            value: elm?.id,
          });
        });
        setRoles(permissionGet);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("61616166116", err);
      });
  };

  useEffect(() => {
    getShowRole();
  }, []);

  console.log("LOCATION OPTIONS", locationOptions);

  function getSelectedValues(event) {
    console.log(event);
    let opts = [],
      opt;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];

      if (opt.selected) {
        opts.push(opt);
      }
    }
    console.log("opts: ", opts);
    return opts;
  }

  let initialValues = {
    businessname: "",
    businessemail: "",
    businessphone: "",
    contactpersonfirstname: "",
    contactpersonlastname: "",
    contactpersonemail: "",
    contactpersonphone: "",
    billing_address1: "",
    billing_address2: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    firstname: "",
    lastname: "",
    timezone: "PDT",
    email: "",
    phone: "",
    ischecked: true,
    is_verified: "yes",
  };

  const handleChecked = (e, setFieldValue, values) => {
    if (e.target.checked) {
      setFieldValue("firstname", values.contactpersonfirstname);
      setFieldValue("lastname", values.contactpersonlastname);
      setFieldValue("phone", values.contactpersonphone);
      setFieldValue("email", values.contactpersonemail);
    } else {
      setFieldValue("firstname", "");
      setFieldValue("lastname", "");
      setFieldValue("phone", "");
      setFieldValue("email", "");
    }
  };
  if (isLoading) {
    return (
      <div className="sweet-loading card">
        <div className="spinner-container">
          <ClipLoader
            className="spinner"
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col lg="6" xs="8">
                    <span
                      className="back-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}>
                      <i class="fa fa-arrow-left " aria-hidden="true"></i>
                    </span>
                    <h6
                      className="fullcalendar-title h2 d-inline-block mb-0"
                      style={{ marginLeft: "10px" }}>
                      {"Create Tenant"}
                    </h6>
                  </Col>
                  {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button
                      color="primary"
                      onClick={() => handleView()}
                      size="sm"
                    >
                      Back To All Tenants
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                {successMsg != "" && (
                  <UncontrolledAlert color="success">
                    <span className="alert-text ml-1">{successMsg}</span>
                  </UncontrolledAlert>
                )}
                {errMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">{errMsg}</span>
                  </UncontrolledAlert>
                )}
                {errorMsg != "" && (
                  <UncontrolledAlert color="danger">
                    <span className="alert-text ml-1">
                      {errorMsg.map((itemEr) => (
                        <p>{itemEr.error}</p>
                      ))}
                    </span>
                  </UncontrolledAlert>
                )}

                <Formik
                  initialValues={initialValues}
                  // validateOnChange={true}
                  // validateOnBlur={true}
                  validate={(values) => {
                    let value = values;
                    const errors = {};

                    var pattern = new RegExp(/^[0-9\b]+$/);

                    if (!values.businessname) {
                      errors.businessname = "Required!";
                    }

                    if (!values.businessemail) {
                      errors.businessemail = "Required";
                    } else if (
                      !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.businessemail
                      )
                    ) {
                      errors.businessemail = "Invalid businessemail address";
                    }

                    if (!values.businessphone) {
                      errors.businessphone = "Required";
                    } else if (!pattern.test(values.businessphone)) {
                      errors.businessphone = "Please enter only number.";
                    } else if (values.businessphone.length != 10) {
                      errors.businessphone = "Please enter valid phone number.";
                    }

                    if (!values.contactpersonfirstname) {
                      errors.contactpersonfirstname = "Required!";
                    }

                    if (!values.contactpersonlastname) {
                      errors.contactpersonlastname = "Required!";
                    }

                    if (!values.contactpersonemail) {
                      errors.contactpersonemail = "Required";
                    } else if (
                      !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.contactpersonemail
                      )
                    ) {
                      errors.contactpersonemail =
                        "Invalid contact person email address";
                    }

                    if (!values.contactpersonphone) {
                      errors.contactpersonphone = "Required";
                    } else if (!pattern.test(values.contactpersonphone)) {
                      errors.contactpersonphone = "Please enter only number.";
                    } else if (values.contactpersonphone.length != 10) {
                      errors.contactpersonphone =
                        "Please enter valid phone number.";
                    }

                    if (!values.billing_address1) {
                      errors.billing_address1 = "Required!";
                    }

                    if (!values.billing_address2) {
                      errors.billing_address2 = "Required!";
                    }

                    if (!values.billing_city) {
                      errors.billing_city = "Required!";
                    }

                    if (!values.billing_state) {
                      errors.billing_state = "Required!";
                    }

                    if (!values.billing_zip_code) {
                      errors.billing_zip_code = "Required!";
                    }

                    if (!values.ischecked) {
                      if (!values.firstname) {
                        errors.firstname = "Required!";
                      }

                      if (!values.lastname) {
                        errors.lastname = "Required!";
                      }

                      if (!values.timezone) {
                        errors.timezone = "Required!";
                      }

                      if (!values.phone) {
                        errors.phone = "Required";
                      } else if (!pattern.test(values.phone)) {
                        errors.phone = "Please enter only number.";
                      } else if (values.phone.length != 10) {
                        errors.phone = "Please enter valid phone number.";
                      }

                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                    }

                    // if (!values.default_location) {
                    //   errors.default_location = "Required!";
                    // }

                    // if (selectedLocation.length < 1) {
                    //   errors.locations = "Required!";
                    // }

                    // if (!values.timezone) {
                    //   errors.timezone = "Required!";
                    // }
                    // //   if (!values.avatar) {
                    // //     errors.avatar = "Required";
                    // //   }
                    // if (!values.is_verified) {
                    //   errors.is_verified = "Required";
                    // }
                    // if (selectedRoles.length < 1) {
                    //   errors.roles = "Required";
                    // }

                    console.log("ERR", errors);

                    return errors;
                  }}
                  onSubmit={(
                    values,
                    { setSubmitting, validate, setTouched, errors }
                  ) => {
                    // validate();
                    setSubmitted(true);
                    setSubmitting(true);

                    createProfile(values, setSubmitting);
                  }}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,

                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row className="align-items-center">
                        <Col lg="6" className="text-center">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="upload-button">
                              {image.preview ? (
                                <img
                                  src={image.preview}
                                  alt="dummy"
                                  width="150"
                                  height="150"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <>
                                  <span className="mb-2 d-block">
                                    <i className="fas fa-user fa-6x" />
                                  </span>
                                  <h5>Upload your photo</h5>
                                </>
                              )}
                            </label>
                            <br />
                            <input
                              lang="en"
                              type="file"
                              name="avatar"
                              onChange={(event) => {
                                //handleImageUpload(event, "profileImg");
                                handleChangeImage(event, "logo");
                              }}
                              //value={image.raw}
                              id="upload-button"
                              style={{ display: "none" }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              <h3>Business Info</h3>
                              Business Name{" "}
                            </label>
                            <Input
                              placeholder="Enter Business Name"
                              type="text"
                              name="businessname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.businessname}
                            />
                            <p className="ErrorMessage">
                              {errors.businessname && touched.businessname
                                ? errors.businessname
                                : null}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Business Email{" "}
                            </label>
                            <Input
                              placeholder="Enter Business Email"
                              type="businessemail"
                              name="businessemail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.businessemail}
                            />
                            <p className="ErrorMessage">
                              {errors.businessemail &&
                                touched.businessemail &&
                                errors.businessemail}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Business Phone Number{" "}
                            </label>
                            <Input
                              type="tel"
                              placeholder="Enter Business Phone Number"
                              name="businessphone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.businessphone}
                            />
                            <p className="ErrorMessage">
                              {errors.businessphone &&
                                touched.businessphone &&
                                errors.businessphone}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <h3>Contact Info</h3>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Contact Person First Name
                            </label>
                            <Input
                              placeholder="Enter Contact Person First Name"
                              type="text"
                              name="contactpersonfirstname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contactpersonfirstname}
                            />
                            <p className="ErrorMessage">
                              {errors.contactpersonfirstname &&
                                touched.contactpersonfirstname &&
                                errors.contactpersonfirstname}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Contact Person Last Name
                            </label>
                            <Input
                              placeholder="Enter Contact Person Last Name"
                              type="text"
                              name="contactpersonlastname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contactpersonlastname}
                            />
                            <p className="ErrorMessage">
                              {errors.contactpersonlastname &&
                                touched.contactpersonlastname &&
                                errors.contactpersonlastname}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Contact Person Email{" "}
                            </label>
                            <Input
                              placeholder="Enter Contact Person Email"
                              type="contactpersonemail"
                              name="contactpersonemail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contactpersonemail}
                            />
                            <p className="ErrorMessage">
                              {errors.contactpersonemail &&
                                touched.contactpersonemail &&
                                errors.contactpersonemail}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Contact Person Number{" "}
                            </label>
                            <Input
                              type="text"
                              placeholder="Enter Contact Person Number"
                              name="contactpersonphone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contactpersonphone}
                            />
                            <p className="ErrorMessage">
                              {errors.contactpersonphone &&
                                touched.contactpersonphone &&
                                errors.contactpersonphone}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <h3>Address</h3>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Billing Address 1
                            </label>
                            <Input
                              placeholder="Enter Billing Address 1"
                              type="text"
                              name="billing_address1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_address1}
                            />
                            <p className="ErrorMessage">
                              {errors.billing_address1 &&
                                touched.billing_address1 &&
                                errors.billing_address1}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Billing Address 2
                            </label>
                            <Input
                              placeholder="Enter Billing Address 2"
                              type="text"
                              name="billing_address2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_address2}
                            />
                            <p className="ErrorMessage">
                              {errors.billing_address2 &&
                                touched.billing_address2 &&
                                errors.billing_address2}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Billing City
                            </label>
                            <Input
                              placeholder="Enter Billing City"
                              type="text"
                              name="billing_city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_city}
                            />
                            <p className="ErrorMessage">
                              {errors.billing_city &&
                                touched.billing_city &&
                                errors.billing_city}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              {" "}
                              Billing State
                            </label>
                            <Input
                              placeholder="Enter Billing State"
                              type="text"
                              name="billing_state"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_state}
                            />
                            <p className="ErrorMessage">
                              {errors.billing_state &&
                                touched.billing_state &&
                                errors.billing_state}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Billing Zip Code
                            </label>
                            <Input
                              placeholder="Enter Billing Zip Code"
                              type="text"
                              name="billing_zip_code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.billing_zip_code}
                            />
                            <p className="ErrorMessage">
                              {errors.billing_zip_code &&
                                touched.billing_zip_code &&
                                errors.billing_zip_code}
                            </p>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                            }}>
                            <input
                              name="ischecked"
                              type="checkbox"
                              onChange={(e) =>
                                // handleChecked(e, setFieldValue, values)
                                setFieldValue("ischecked", !values.ischecked)
                              }
                              checked={values.ischecked}
                              style={{ marginRight: 10 }}
                            />

                            <label>
                              Use contact person as default admin user
                            </label>
                          </FormGroup>
                        </Col>

                        {!values.ischecked && (
                          <>
                            {" "}
                            <Col lg="12">
                              <h3>Admin Info</h3>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  {" "}
                                  First Name
                                </label>
                                <Input
                                  placeholder="Enter First Name"
                                  type="text"
                                  name={
                                    values.ischecked
                                      ? "contactpersonfirstname"
                                      : "firstname"
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstname}
                                  // disabled={
                                  //   values.contactpersonfirstname ==
                                  //   values.firstname
                                  // }
                                />
                                <p className="ErrorMessage">
                                  {errors.firstname &&
                                    touched.firstname &&
                                    errors.firstname}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  {" "}
                                  Last Name
                                </label>
                                <Input
                                  placeholder="Enter Last Name"
                                  type="text"
                                  name="lastname"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastname}
                                  // disabled={
                                  //   values.contactpersonlastname ==
                                  //   values.lastname
                                  // }
                                />
                                <p className="ErrorMessage">
                                  {errors.lastname &&
                                    touched.lastname &&
                                    errors.lastname}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  {" "}
                                  Email{" "}
                                </label>
                                <Input
                                  placeholder="Enter Email"
                                  type="email"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  // disabled={
                                  //   values.contactpersonemail == values.email
                                  // }
                                />
                                <p className="ErrorMessage">
                                  {errors.email &&
                                    touched.email &&
                                    errors.email}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Phone Number{" "}
                                </label>
                                <Input
                                  type="tel"
                                  placeholder="Enter Phone Number"
                                  name="phone"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phone}
                                  // disabled={
                                  //   values.contactpersonphone == values.phone
                                  // }
                                />
                                <p className="ErrorMessage">
                                  {errors.phone &&
                                    touched.phone &&
                                    errors.phone}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Select Timezone
                                </label>
                                <Input
                                  type="select"
                                  name="timezone"
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <option value="">Select Timezone</option>
                                  {timeZones.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item?.abbreviation}
                                      selected={
                                        values.timezone == item?.abbreviation
                                          ? "selected"
                                          : ""
                                      }>
                                      {item?.name}
                                    </option>
                                  ))}
                                </Input>
                                <p className="ErrorMessage">
                                  {errors.timezone &&
                                    touched.timezone &&
                                    errors.timezone}
                                </p>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Is Verified
                                </label>
                                <Input
                                  type="select"
                                  name="is_verified"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // value={
                                  //   initialValues.is_verified ? "yes" : "no"
                                  // }
                                  value={values.is_verified}>
                                  <option value="">Select Is Verified</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </Input>
                                <p className="ErrorMessage">
                                  {errors.is_verified &&
                                    touched.is_verified &&
                                    errors.is_verified}
                                </p>
                              </FormGroup>
                            </Col>
                          </>
                        )}
                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Working Location
                            </label>
                            <Input
                              type="select"
                              name="default_location"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select Working Location</option>
                              {locations.map((location) => (
                                <option
                                  value={location.pk}
                                  selected={
                                    values.default_location == location.pk
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {location.title}
                                </option>
                              ))}
                            </Input>
                            <p className="ErrorMessage">
                              {errors.default_location &&
                                touched.default_location &&
                                errors.default_location}
                            </p>
                          </FormGroup>
                        </Col> */}

                        {/* <Col lg="6"></Col> */}
                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Location
                            </label>
                           
                            <MultiSelect
                              options={locationOptions}
                              value={selectedLocation}
                              onChange={setSelectedLocation}
                            />
                            <p className="ErrorMessage">
                              {selectedLocation.length < 1 && touched.locations
                                ? errors.locations
                                : ""}
                            </p>
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Select Role
                            </label>
                            
                            <MultiSelect
                              options={roleOptions}
                              value={selectedRoles}
                              onChange={setSelectedRoles}
                              hasSelectAll={false}
                              name="roles"
                            />
                           
                            <p className="ErrorMessage">
                              {selectedRoles.length < 1 && touched.roles
                                ? errors.roles
                                : ""}
                            </p>
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label">
                              Profile Image
                            </label>
                            <br />
                            <input
                              lang="en"
                              type="file"
                              name="profileImg"
                              onChange={(event) => {
                                handleImageUpload(event, "profileImg");
                              }}
                            />
                          </FormGroup>
                        </Col> */}

                        <Col>
                          <FormGroup>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              //className="d-block"
                            >
                              Submit
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default EmployeesProfile;
