import React from "react";

const HeadersData = ({ transactionsData, selectedPaName }) => {
  let selectedHeaders;

  if (
    transactionsData === "No data found" ||
    transactionsData === "No erroneous records found"
  ) {
    const dummyHeaders = {
      doordash: [
        "Record ID",
        "Order ID",
        "Payout Date",
        "Transaction Type",
        "Subtotal",
        "Tax Subtotal",
        "Commission",
        "Credit",
        "Debit",
        "Inc/Exc",
        "Notes",
        "Action",
      ],
      grubhub: [
        "Record ID",
        "Order ID",
        "Payout Date",
        "Transaction Type",
        "Subtotal",
        "Tax Subtotal",
        "Marketplace Fee",
        "Inc/Exc",
        "Notes",
        "Action",
      ],
      liquorsplit: [
        "Record ID",
        "Order ID",
        "Payout Date",
        "Transaction Type",
        "Subtotal",
        "Tax Subtotal",
        "Marketplace Fee",
        "Inc/Exc",
        "Notes",
        "Action",
      ],
      ubereats: [
        "Record ID",
        "Order ID",
        "Payout Date",
        "Transaction Type",
        "Subtotal",
        "Tax Subtotal",
        "Marketplace Fee",
        "Inc/Exc",
        "Notes",
        "Action",
      ],
      instacart: [
        "Record ID",
        "Order ID",
        "Payout Date",
        "Transaction Type",
        "Subtotal",
        "Tax Subtotal",
        "Marketplace Fee",
        "Inc/Exc",
        "Notes",
        "Action",
      ],
      drizly: [
        "Record ID",
        "Order ID",
        "Payout Date",
        "Transaction Type",
        "Subtotal",
        "Tax Subtotal",
        "Inc/Exc",
        "Notes",
        "Action",
      ],
    };

    selectedHeaders = dummyHeaders[selectedPaName.toLowerCase()] || [];
  } else {
    selectedHeaders = Object.keys(transactionsData[0] || {}).map((key) => {
      switch (key) {
        case "order_date":
          return "Payout Date";
        case "exclude":
          return "Inc/Exc";
        default:
          return key.replace(/_/g, " ");
      }
    });
    selectedHeaders.push("Action");
    if (
      Array.isArray(transactionsData) &&
      transactionsData.some((item) => item.edited === true)
    ) {
      selectedHeaders.push("Status");
    }
  }

  console.log("transactionsDataHeaders", transactionsData);

  return (
    <thead
      className="thead-light"
      style={{ position: "sticky", top: 0, zIndex: 100 }}
    >
      <tr>
        {selectedHeaders.map(
          (header, index) =>
            header !== "edited" && (
              <th key={index} scope="col">
                {header}
              </th>
            )
        )}
      </tr>
    </thead>
  );
};

export default HeadersData;
